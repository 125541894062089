import { useQuery } from '@tanstack/react-query';
import { get } from 'api/client';
const fetchRealEstateTaxCalculationInfo = (townshipId) => () => get(null, `legacy/${townshipId}/real-estate-tax-calculator-info`);
const useRealEstateTaxCalculationInfo = (townshipId) => {
    return useQuery({
        queryKey: ['real-estate-tax', { townshipId }],
        queryFn: fetchRealEstateTaxCalculationInfo(townshipId),
        enabled: !!townshipId,
    });
};
export default useRealEstateTaxCalculationInfo;
