import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormControl, FormErrorMessage, FormLabel, Select, } from '@chakra-ui/react';
import NotRequired from './NotRequired';
const SelectInput = ({ label, options, name, placeholder, required, optional, registerOption = {}, isLoading, widthFormControl = 'full', formControlDisplay = '', formLabelWidth = '', ...props }) => {
    const { register, getFieldState } = useFormContext();
    const { error } = getFieldState(name);
    return (<FormControl width={widthFormControl} isInvalid={Boolean(error)} isDisabled={isLoading} display={formControlDisplay}>
      {label && (<FormLabel width={formLabelWidth}>
          {`${label} ${required ? '*' : ''}`} {optional && <NotRequired />}
        </FormLabel>)}
      <Select placeholder={placeholder} style={{
            borderWidth: 0,
        }} {...register(name, registerOption)} {...props}>
        {options?.map((option, index) => (<option key={index} value={option?.value} style={{ display: `${option.hide ? 'none' : ''}` }}>
            {option?.label}
          </option>))}
      </Select>
      {Boolean(error) && <FormErrorMessage>{error?.message}</FormErrorMessage>}
    </FormControl>);
};
export default SelectInput;
