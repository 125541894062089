import * as React from 'react';
import { Text } from '@chakra-ui/react';
const OwnerDetail = ({ owner }) => {
    return (<>
      <Text textStyle="paragraph" fontWeight={600}>
        {owner.group}{' '}
        {owner.form && (<Text as="span" textStyle="small" fontWeight={500}>
            ({owner.form})
          </Text>)}
      </Text>
      {owner.ape && <Text textStyle="small">{owner.ape}</Text>}
    </>);
};
export default OwnerDetail;
