import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOUrbanSequenceProtectionLayerItem = IOLayerItem;
const fetchUrbanSequenceProtection = (townId) => () => get(iots.array(IOUrbanSequenceProtectionLayerItem), `legacy/${townId}/urban-sequence-protection`);
const useUrbanSequenceProtectionLayer = () => {
    createLayersHook({
        fetch: fetchUrbanSequenceProtection,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.URBAN_SEQUENCE_PROTECTION);
};
const UrbanSequenceProtectionLayer = () => {
    useUrbanSequenceProtectionLayer();
    return null;
};
export default UrbanSequenceProtectionLayer;
