import * as React from 'react';
import { Text } from '@chakra-ui/react';
import { SirenLink } from 'components/links/SirenLink';
import { formatAddress } from 'utils/formatText';
const OwnerIdentity = ({ owner }) => {
    return (<>
      <Text textStyle="paragraph" fontWeight={600}>
        {owner.name}
      </Text>
      {owner.siren && (<Text textStyle="paragraph">
          <SirenLink siren={owner.siren} analyticDetail="Pappers Propriétaires" showExternalLink/>
        </Text>)}

      <Text textStyle="small">{formatAddress(owner)}</Text>
    </>);
};
export default OwnerIdentity;
