import * as React from 'react';
import { Skeleton, VStack } from '@chakra-ui/react';
import { ANALYTICS_PLOT_TAB } from 'analytics/useAnalytics';
import CreateProjectButton from 'components/buttons/CreateProjectButton';
import ProjectButton from 'components/buttons/ProjectButton';
import usePlotProjectsList from 'hooks/crm/usePlotProjectsList';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { filtersKey } from 'utils/filters';
const PlotSummaryProjects = ({ plot, }) => {
    const { plotProjects, isLoading } = usePlotProjectsList(plot.id);
    const { hasDashboardItem } = useSiteConfiguration();
    if (!hasDashboardItem(filtersKey.USER_PROJECTS))
        return null;
    if (isLoading)
        return <Skeleton height="30px" width="100%"/>;
    return (<VStack alignItems="flex-start" width="100%" maxW="sm">
      {plotProjects?.length === 0 ? (<CreateProjectButton plotIds={[plot.id]} townId={plot?.townId} icon={true} analyticsAction={ANALYTICS_PLOT_TAB} analyticsCategory="Créer un projet via la légende" textAlign="left"/>) : (<VStack spacing={1} alignItems="flex-start">
          {plotProjects?.map((project) => {
                return (<ProjectButton key={project.id} projectId={project.id} projectLabel={project.label} projectTownIds={[plot.townId]} lat={plot.lat} lng={plot.lng} analyticOrigin={ANALYTICS_PLOT_TAB} analyticDetail="Ouvrir fiche projet via la légende" textAlign="left">
                {project.label}
              </ProjectButton>);
            })}
        </VStack>)}
    </VStack>);
};
export default PlotSummaryProjects;
