import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, HStack, Text, VStack } from '@chakra-ui/react';
import { getCivilityFromCode } from 'components/inputs/CivilityTitleSelect';
import { formatNumberToLocale } from 'utils/formatNumber';
import CourierValidationAddress from './CourierValidationAddress';
const CourierValidation = ({ handleCancel, handleConfirm, courierInfo, fileName, sender, loading, }) => {
    const { t } = useTranslation();
    const { civility, firstname, lastname, company, address, postalCode, town, addressBP, addressComplement, credits, } = courierInfo;
    const euro = formatNumberToLocale(credits, 'fr');
    return (<VStack justifyContent="space-between">
      <VStack spacing={4} alignItems="flex-start">
        <Text textStyle="paragraph">{t('courier.confirm_info')}</Text>

        <VStack width={'100%'} spacing={4}>
          <VStack alignItems="flex-start" width="100%">
            <Text textStyle="paragraph" fontWeight="bold">
              {t('courier.file')}
            </Text>
            <Text fontSize="14px">{fileName}</Text>
          </VStack>
          <VStack width={'100%'} spacing={2} alignItems="flex-start">
            <Text textStyle="paragraph" fontWeight="bold">
              {t('courier.recipient')}
            </Text>
            <VStack alignItems="flex-start" p={2} borderRadius="lg" border="1px solid lightgray" fontSize="14px" width={'100%'}>
              <Text>{`${getCivilityFromCode(civility)} ${firstname} ${lastname}`}</Text>
              {company && <Text>{`${company}`}</Text>}
              <Text>{address}</Text>
              <Text>{`${postalCode} ${town}`}</Text>
              {addressBP && <Text>{`${addressBP}`}</Text>}
              {addressComplement && <Text>{`${addressComplement} `}</Text>}
            </VStack>
          </VStack>

          <VStack width={'100%'} spacing={2} alignItems="flex-start">
            <Text textStyle="paragraph" fontWeight="bold">
              {t('courier.sender.label')}
            </Text>
            <VStack alignItems="flex-start" p={2} borderRadius="lg" border="1px solid lightgray" fontSize="14px" width={'100%'}>
              {sender && <CourierValidationAddress sender={sender}/>}
            </VStack>
          </VStack>
        </VStack>
      </VStack>

      <HStack width={'100%'} justifyContent="flex-end">
        <Button paddingX={10} variant="ghostBorder" onClick={handleCancel}>
          {t('global.actions.cancel')}
        </Button>
        <Button paddingX={10} variant="primary" type="button" loadingText={t('global.status.creating')} onClick={handleConfirm} isLoading={loading} data-cy="courier_form_submit_send">
          {t('courier.action.send_credit', { euro: euro })}
        </Button>
      </HStack>
    </VStack>);
};
export default CourierValidation;
