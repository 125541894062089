import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
const MultiSelectCreateOption = ({ label, isDisabled, name, formatCreateLabel, formatValues, ...rest }) => {
    const { setValue, watch, getFieldState, clearErrors } = useFormContext();
    const { error } = getFieldState(name);
    const fieldValue = watch(name);
    return (<FormControl isDisabled={isDisabled} isInvalid={Boolean(error)}>
      {label && (<FormLabel htmlFor={name} marginBottom={1}>
          {label}
        </FormLabel>)}
      {Boolean(error) && (<FormErrorMessage pb={2}>{error?.message}</FormErrorMessage>)}
      <CreatableSelect isMulti styles={{ placeholder: (base) => ({ ...base, fontSize: '11px' }) }} formatCreateLabel={formatCreateLabel} value={fieldValue
            ? fieldValue?.split(';').map((el) => ({ label: el, value: el }))
            : ''} onChange={(selectValues) => {
            const newValue = formatValues
                ? formatValues(selectValues)
                : selectValues?.map((el) => el?.value.split(' ').join('')).join(';');
            clearErrors();
            setValue(name, newValue);
        }} {...rest}/>
    </FormControl>);
};
export default MultiSelectCreateOption;
