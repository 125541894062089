import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
export const IOPPRFloodingOtherContingenciesLayerItem = IOLayerItem;
const fetchPPRFloodingOtherContingencies = (townId) => () => get(iots.array(IOPPRFloodingOtherContingenciesLayerItem), `legacy/${townId}/ppr-flooding-strong-hazard`);
const usePPRFloodingStrongHazardLayer = () => {
    createLayersHook({
        fetch: fetchPPRFloodingOtherContingencies,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.PPR_FLOODING_STRONG_HAZARD);
};
const PPRFloodingStrongHazardLayer = () => {
    usePPRFloodingStrongHazardLayer();
    return null;
};
export default PPRFloodingStrongHazardLayer;
