import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { CREDIT_BLACK_AND_WHITE_ADDITIONAL_PAGE, CREDIT_BLACK_AND_WHITE_FIRST_PAGE, CREDIT_COLOR_ADDITIONAL_PAGE, CREDIT_COLOR_FIRST_PAGE, CREDIT_OPTION_TREAT_UNDELIVERED_MAIL, } from './CourierFormUtils';
const CourierTotal = ({ courierInfo }) => {
    const { t } = useTranslation();
    const { watch, setValue } = useFormContext();
    const [filePages, setFilePages] = useState(courierInfo?.mailevaOptionNbPage ?? 1);
    const file = watch('file');
    // OPTIONS
    const mailevaOptionColor = watch('mailevaOptionColor');
    const mailevaOptionTreatUndeliveredMail = watch('mailevaOptionTreatUndeliveredMail');
    const getCreditBaseCost = () => {
        let creditOptionsCost = 0;
        if (mailevaOptionColor) {
            creditOptionsCost += CREDIT_COLOR_FIRST_PAGE;
        }
        else {
            creditOptionsCost += CREDIT_BLACK_AND_WHITE_FIRST_PAGE;
        }
        return creditOptionsCost;
    };
    const creditBaseCost = useMemo(getCreditBaseCost, [mailevaOptionColor]);
    const getCreditOptionsCost = () => {
        let creditOptionsCost = 0;
        if (mailevaOptionColor) {
            creditOptionsCost += (filePages - 1) * CREDIT_COLOR_ADDITIONAL_PAGE;
        }
        else {
            creditOptionsCost +=
                (filePages - 1) * CREDIT_BLACK_AND_WHITE_ADDITIONAL_PAGE;
        }
        if (mailevaOptionTreatUndeliveredMail) {
            creditOptionsCost += CREDIT_OPTION_TREAT_UNDELIVERED_MAIL;
        }
        return creditOptionsCost;
    };
    const creditOptionsCost = useMemo(getCreditOptionsCost, [
        mailevaOptionColor,
        mailevaOptionTreatUndeliveredMail,
        filePages,
    ]);
    // FILE PAGES
    useEffect(() => {
        if (file && file[0]?.name && file[0]?.name.match(/.pdf$/g)) {
            const reader = new FileReader();
            reader.readAsBinaryString(file[0]);
            reader.onloadend = function () {
                const result = reader.result;
                // Les PDF peuvent êter générer de différente manière
                // Il y a a donc différente méthode de calcul possible
                // Notamment la génération via .docx
                let nbPage;
                const match = result.match(/\/Count [0-9]+/g);
                if (match && match.length > 0) {
                    nbPage = parseInt(match[0].replace('/Count ', ''));
                }
                else {
                    nbPage = result.match(/\/Type[\s]*\/Page[^s]/g).length;
                }
                if (nbPage === 0) {
                    nbPage = 1;
                }
                return setFilePages(nbPage);
            };
        }
        else {
            setFilePages(courierInfo?.mailevaOptionNbPage ?? 1);
        }
    }, [file]);
    //TOTAL
    const totalCost = creditBaseCost + creditOptionsCost;
    useEffect(() => {
        setValue('credits', totalCost);
    }, [totalCost]);
    return (<Box width="100%" marginTop={2} alignItems="flex-start">
      <input type="hidden" name="credits"/>
      <Text fontWeight="bold">5. {t('courier.pricing')}</Text>
      <VStack width="100%" fontSize="13px" color="colorMode.font900">
        <HStack width="100%" paddingTop={1} textAlign="right">
          <Box width="80%" paddingRight={2}>
            {t('courier.rate.total')}
            <Text as="span" fontSize="11px">
              {' '}
              ({t('courier.rate.total_info', { count: filePages })})
            </Text>
          </Box>
          <Box width="20%">{totalCost.toFixed(2)} €</Box>
        </HStack>
      </VStack>
    </Box>);
};
export default CourierTotal;
