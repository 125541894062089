import * as React from 'react';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Table, TableContainer, Tbody } from '@chakra-ui/react';
import * as iots from 'io-ts';
import { IOPlotFeasibilityInfo, } from 'hooks/plot/usePlotFeasibility';
import { EXTRA_ROWS_LEVEL_KEY, EXTRA_ROWS_UNDERGROUND_LEVEL_KEY, getFeasibilityRows, plotFeasibilityExtraFields, plotFeasibilityFields, } from './calculator';
import FeasibilityTableHead from './FeasibilityTableHead';
import PlotFeasibilityRow from './FeasibilityTableRow';
const IOPlotFeasibilityFormData = iots.intersection([
    IOPlotFeasibilityInfo,
    iots.type({
        buildingLine: iots.number,
        computedFootprintSurface: iots.number,
        retainedFootprintSurface: iots.number,
        nbUndergroundLevels: iots.number,
        floorSpaceRatio: iots.number,
        livingSpaceRatio: iots.number,
        averageAccommodationSize: iots.number,
        aboveGroundAvailableParkingSurface: iots.number,
        aboveGroundAvailableParkingSpace: iots.number,
        parkingSpaceSize: iots.number,
        constructibleSpacePerUndergroundLevel: iots.number,
        undergroundParkingSize: iots.number,
        maxParkingSpacePerUndergroundLevel: iots.number,
        baseCalculationSelected: iots.string,
    }),
]);
const PlotFeasibilityTable = ({ feasibility, feasibilityPlots, closeOneFeasibilityPlot, isLoadingFeasibilityPlot, hasFeasibility, }) => {
    const { t } = useTranslation();
    const [showFeasibilityDetail, setShowFeasibilityDetail] = useState(true);
    const { register, watch, setValue } = useFormContext();
    const formData = watch();
    const rows = getFeasibilityRows(showFeasibilityDetail);
    // useEffect(() => {
    //   setValue(
    //     'aboveGroundAvailableParkingSurface',
    //     plotFeasibilityFields.aboveGroundAvailableParkingSurface.getValue({
    //       sourceData: formData,
    //     })
    //   )
    // }, [formData.baseCalculationSelected])
    // a voir si on peut faire un onchange dans le register
    useEffect(() => {
        setValue('constructibleSpacePerUndergroundLevel', plotFeasibilityFields.constructibleSpacePerUndergroundLevel.getValue({
            sourceData: formData,
        }));
    }, [formData.inconstructibleUndergroundSurface]);
    useEffect(() => {
        if (formData.nbLevels) {
            const levelRows = Object.keys(formData).filter((key) => key.startsWith(EXTRA_ROWS_LEVEL_KEY));
            levelRows.forEach((row) => {
                setValue(row, 0);
            });
            Array.from(Array(Math.ceil(formData.nbLevels)).keys()).forEach((row) => {
                const rowId = `${EXTRA_ROWS_LEVEL_KEY}-${row}`;
                const value = plotFeasibilityExtraFields[EXTRA_ROWS_LEVEL_KEY].getValue({
                    sourceData: formData,
                    formData,
                    id: rowId,
                });
                setValue(`${EXTRA_ROWS_LEVEL_KEY}-${row}`, value);
            });
        }
    }, [formData.nbLevels]);
    return (<TableContainer width="full" className="smooth-box-shadow" borderRadius={6}>
      <Table variant="feasibility">
        <FeasibilityTableHead showFeasibilityDetail={showFeasibilityDetail} setShowFeasibilityDetail={setShowFeasibilityDetail} feasibility={feasibility} feasibilityPlots={feasibilityPlots} isLoadingFeasibilityPlot={isLoadingFeasibilityPlot} closeOneFeasibilityPlot={closeOneFeasibilityPlot} hasFeasibility={hasFeasibility}/>
        {rows?.length && (<Tbody>
            {rows.map((key) => {
                const baseRow = (<PlotFeasibilityRow key={key} rowId={key} rowConfig={plotFeasibilityFields[key]} register={register} formData={formData} showFeasibilityDetail={showFeasibilityDetail} totalValue={formData[key]} feasibility={feasibility} feasibilityPlots={feasibilityPlots}/>);
                if (key === 'nbLevels' && showFeasibilityDetail) {
                    const nbRowsMax = Math.ceil(Math.max(formData.nbLevels, feasibility?.nbLevels || 0, ...Object.values(feasibilityPlots).map((plot) => plot?.nbLevels)));
                    // If 0 level
                    if (!nbRowsMax) {
                        return baseRow;
                    }
                    return [
                        baseRow,
                        ...Array.from(Array(nbRowsMax).keys()).map((row) => {
                            return (<PlotFeasibilityRow key={`${EXTRA_ROWS_LEVEL_KEY}-${row}`} rowId={`${EXTRA_ROWS_LEVEL_KEY}-${row}`} label={t('tabs.plots.section.feasibility.headers.level', {
                                    number: row + 1,
                                })} rowConfig={{
                                    ...plotFeasibilityExtraFields[EXTRA_ROWS_LEVEL_KEY],
                                    borderBottom: row === nbRowsMax - 1,
                                }} register={register} formData={formData} showFeasibilityDetail={showFeasibilityDetail} totalValue={0} feasibility={feasibility} feasibilityPlots={feasibilityPlots}/>);
                        }),
                    ];
                }
                else if (key === 'nbUndergroundLevels' &&
                    showFeasibilityDetail) {
                    const nbRowsMax = plotFeasibilityFields[key].getValue({
                        sourceData: formData,
                    });
                    // If 0 level
                    if (nbRowsMax <= 0 || isNaN(nbRowsMax)) {
                        return baseRow;
                    }
                    return [
                        baseRow,
                        ...Array.from(Array(nbRowsMax).keys()).map((row) => {
                            return (<PlotFeasibilityRow key={`${EXTRA_ROWS_UNDERGROUND_LEVEL_KEY}-${row}`} rowId={`${EXTRA_ROWS_UNDERGROUND_LEVEL_KEY}-${row}`} label={t('tabs.plots.section.feasibility.headers.undergroundLevel', {
                                    number: row + 1,
                                })} rowConfig={{
                                    ...plotFeasibilityExtraFields[EXTRA_ROWS_UNDERGROUND_LEVEL_KEY],
                                    borderBottom: row === nbRowsMax - 1,
                                    readOnly: true,
                                }} register={register} formData={formData} showFeasibilityDetail={showFeasibilityDetail} totalValue={0} feasibility={feasibility} feasibilityPlots={feasibilityPlots}/>);
                        }),
                    ];
                }
                return baseRow;
            })}
          </Tbody>)}
      </Table>
    </TableContainer>);
};
export default PlotFeasibilityTable;
