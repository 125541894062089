import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOPollutionRisksLayerItem = IOLayerItem;
const fetchPollutionRisks = (townId) => () => get(iots.array(IOPollutionRisksLayerItem), `legacy/${townId}/pollution-risks`);
const usePollutionRisksLayer = () => {
    createLayersHook({
        fetch: fetchPollutionRisks,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.POLLUTION_RISKS);
};
const PollutionRisksLayer = () => {
    usePollutionRisksLayer();
    return null;
};
export default PollutionRisksLayer;
