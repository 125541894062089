import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
const IOBounds = iots.type({
    north: iots.number,
    south: iots.number,
    west: iots.number,
    east: iots.number,
});
const QUERY_KEY = ['towns-bound'];
const fetchTownsBound = (townshipId, bounds) => () => get(iots.array(iots.string), `${townshipId}/towns/byBound`, {
    latMin: bounds.south,
    latMax: bounds.north,
    lngMin: bounds.west,
    lngMax: bounds.east,
});
const useTownBound = ({ bounds, townshipId, }) => {
    const { data: townIds, isInitialLoading: isLoading } = useQuery({
        queryKey: [QUERY_KEY, { townshipId, bounds }],
        queryFn: fetchTownsBound(townshipId, bounds),
        enabled: !!townshipId &&
            !!bounds?.south &&
            !!bounds?.north &&
            !!bounds?.west &&
            !!bounds?.east,
    });
    return {
        townIds,
        isLoading,
    };
};
export default useTownBound;
