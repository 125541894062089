import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';
import * as iots from 'io-ts';
import { get } from 'api/client';
import InfoWindowTable from 'components/infoWindow/InfoWindowTable';
import createLayersHook from 'hooks/layers/createLayersHook';
import orfel from 'images/orfel.png';
import { IOPolygonLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
const IOOrfelInfo = iots.type({
    commune: iots.string,
    address: iots.string,
    groundSurface: iots.number,
    footprintSurface: iots.number,
    zone: iots.string,
    liberationYear: iots.string,
    liberationOrigin: iots.string,
    lat: iots.string,
    lng: iots.string,
});
const fetchOrfel = () => () => get(iots.array(IOPolygonLayerItem), `orfel`);
const fetchOrfelInfo = (id) => {
    return get(iots.array(IOOrfelInfo), `orfel/${id}`);
};
const formatInfoWindowContent = (t, info) => {
    const url = `https://orfel.immobilier-etat.gouv.fr/sites?location=${info.lng},${info.lat}`;
    const fields = [
        {
            title: t('info_window.orfel.address'),
            data: info.address,
        },
        {
            title: t('info_window.orfel.ground_surface'),
            data: info.groundSurface ? info.groundSurface + ' m²' : null,
        },
        {
            title: t('info_window.orfel.footprint_surface'),
            data: info.footprintSurface ? info.footprintSurface + ' m²' : null,
        },
        {
            title: t('info_window.orfel.zone'),
            data: info.zone,
        },
        {
            title: t('info_window.orfel.liberation_year'),
            data: info.liberationYear,
        },
        {
            title: t('info_window.orfel.liberation_origin'),
            data: info.liberationOrigin,
        },
        {
            title: t('info_window.orfel.url'),
            data: (<a href={url} rel="noreferrer" target="_blank">
          {t('info_window.orfel.link')}
        </a>),
        },
    ];
    const content = (<Box>
      <InfoWindowTable content={fields} labelTableCellProps={{ style: { width: '180px' } }} valueTableCellProps={{ style: { maxWidth: '480px' } }}/>
    </Box>);
    return ReactDOMServer.renderToStaticMarkup(content);
};
const getInfoWindowContent = (t) => async (item) => {
    const orfelInfo = await fetchOrfelInfo(item.id);
    return formatInfoWindowContent(t, orfelInfo);
};
const LEGENDS = {
    orfel: {
        layerKey: filtersKey.ORFEL,
        label: 'orfel',
        needsTranslate: true,
        image: orfel,
    },
};
const useOrfelLayer = () => {
    const { t } = useTranslation();
    createLayersHook({
        fetch: fetchOrfel,
        markerImg: orfel,
        legends: LEGENDS,
        getInfoWindowContent: getInfoWindowContent(t),
    })(filtersKey.ORFEL);
};
const OrfelLayer = () => {
    useOrfelLayer();
    return null;
};
export default OrfelLayer;
