import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOPinelBretagneLayerItem = IOLayerItem;
const fetchPinelBretagneLayer = (townId) => () => get(iots.array(IOPinelBretagneLayerItem), `legacy/${townId}/pinel-bretagne`);
const usePinelBretagneLayer = () => {
    createLayersHook({
        fetch: fetchPinelBretagneLayer,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.PINEL_BRETAGNE);
};
const PinelBretagneLayer = () => {
    usePinelBretagneLayer();
    return null;
};
export default PinelBretagneLayer;
