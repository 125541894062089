import { capitalize, startCase } from 'lodash';
export const formatAddress = (address) => {
    const { streetNumber, street, postalCode, town } = address;
    return `${capitalize(streetNumber?.trim() ?? '')} ${street?.trim() ?? ''} ${postalCode?.trim() ?? ''} ${town ? `${startCase(town?.trim())}` : ''}`;
};
export const camelToSnakeCase = (str) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
export const yesOrNo = (t, value) => {
    if (value === 1) {
        return t('global.yes');
    }
    return t('global.no');
};
export const getTVA = (townId) => {
    return townId >= '96000' ? '2,1' : '5,5';
};
