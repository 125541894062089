import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, HStack, Text } from '@chakra-ui/react';
import useAnalytics from 'analytics/useAnalytics';
import usePlotsSearchExport from 'hooks/plotsSearch/usePlotsSearchExport';
import DownloadIcon from 'images/icons/Download';
const PlotsExportButton = ({ isDisabled, resultsLength, analyticOrigin, analyticDetail, filteredPlotsIds, ...props }) => {
    const { t } = useTranslation();
    const [loadingDownload, setLoadingDownload] = React.useState(false);
    const { trackEvent } = useAnalytics();
    const exportPlotsSearch = usePlotsSearchExport();
    const exportData = () => {
        setLoadingDownload(true);
        exportPlotsSearch(filteredPlotsIds).then(() => {
            trackEvent({
                category: analyticOrigin,
                action: analyticDetail,
            });
            setLoadingDownload(false);
        });
    };
    const downloadButtonText = resultsLength < 1000
        ? t('sidebar.search_plots.count_download_results', {
            count: resultsLength || 0,
        })
        : t('sidebar.search_plots.download_results_more_than_a_thousand');
    return (<Button size="sm" color="blue.500" backgroundColor="colorMode.base" onClick={exportData} isDisabled={isDisabled || !exportPlotsSearch} isLoading={loadingDownload} loadingText={downloadButtonText} whiteSpace="normal" textAlign="left" {...props}>
      <HStack alignItems="flex-start">
        <DownloadIcon />
        <Text color="inherit"> {downloadButtonText}</Text>
      </HStack>
    </Button>);
};
export default PlotsExportButton;
