import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Box, HStack, Text } from '@chakra-ui/react';
import { isUndefined } from 'lodash';
import { formatNumberToLocale } from 'utils/formatNumber';
const CalculatedData = ({ label, labelParams = {}, value = 0, currency = '€', children, tooltip, bold = true, }) => {
    const { t, i18n } = useTranslation();
    return (<HStack width="full" justifyContent="space-between">
      <Text flex={2} textStyle="paragraph" as="div" fontWeight={bold ? 700 : 400} display="flex">
        <Text as="span" dangerouslySetInnerHTML={{
            __html: t('modals.real_estate_tax.' + label, labelParams),
        }}/>
        {tooltip}
      </Text>

      <HStack flex={1} justifyContent="flex-end">
        <Badge textStyle="paragraph" color="colorMode.revertBase" fontWeight={700}>
          {isUndefined(value) || isNaN(value)
            ? '-'
            : formatNumberToLocale(value, i18n.language)}
        </Badge>
        <Box justifyContent="flex-start">
          <Text textStyle="paragraph" fontWeight={700}>
            {currency}
          </Text>
        </Box>
      </HStack>
      <HStack flex={1}>{children}</HStack>
    </HStack>);
};
export default CalculatedData;
