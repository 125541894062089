import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getBaseTileUrlHost3 } from 'utils/multiplexage';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = () => (coord, zoom) => {
    return (getBaseTileUrlHost3() +
        'cgi-bin/mm/mapserv.cgi?map=/var/data/public_maps/project_scot.map&LAYERS=scot&mode=tile&tilemode=gmap&tile=' +
        coord.x +
        '+' +
        coord.y +
        '+' +
        zoom +
        '&FORMAT=image%2Fpng&TRANSPARENT=true&TILED=true&CRS=EPSG:3857');
};
const getLegends = () => {
    return {};
};
const useScotLayer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.SCOT],
        getLegends,
    })(filtersKey.SCOT);
};
const ScotLayer = () => {
    useScotLayer();
    return null;
};
export default ScotLayer;
