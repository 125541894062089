import { useTranslation } from 'react-i18next';
import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import basiasImg from 'images/basias.png';
import { IOMarkerLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
const IOPotentiallyContaminatedSitesAndSoilsMarkerItem = iots.intersection([
    IOMarkerLayerItem,
    iots.type({
        name: iots.string,
    }),
    iots.partial({
        url: iots.string,
        activity: iots.string,
    }),
]);
const fetchPotentiallyContaminatedSitesAndSoils = (townId) => () => get(iots.array(IOPotentiallyContaminatedSitesAndSoilsMarkerItem), `legacy/${townId}/potentially-contaminated-sites-and-soils`);
const LEGENDS = {
    potentially_contaminated_sites_and_soils: {
        layerKey: filtersKey.POTENTIALLY_CONTAMINATED_SITES_AND_SOILS,
        label: 'potentially_contaminated_sites_and_soils',
        needsTranslate: true,
        image: basiasImg,
    },
};
const formatInfoWindowContent = (item, t) => {
    let content = '';
    if (item.name) {
        content += `<b>${item.name}</b>`;
    }
    if (item.activity) {
        content += `<br />${item.activity}`;
    }
    if (item.url) {
        content += `<br /><a href="${item.url}" target="_blank">${t('info_window.contaminated_sites_and_soils.url')}</a>`;
    }
    return content;
};
const usePotentiallyContaminatedSitesAndSoilsLayer = () => {
    const { t } = useTranslation();
    const getInfoWindowContent = (marker) => {
        return formatInfoWindowContent(marker, t);
    };
    createLayersHook({
        fetch: fetchPotentiallyContaminatedSitesAndSoils,
        markerImg: basiasImg,
        legends: LEGENDS,
        getInfoWindowContent,
    })(filtersKey.POTENTIALLY_CONTAMINATED_SITES_AND_SOILS);
};
const PotentiallyContaminatedSitesAndSoilsLayer = () => {
    usePotentiallyContaminatedSitesAndSoilsLayer();
    return null;
};
export default PotentiallyContaminatedSitesAndSoilsLayer;
