import { useMemo } from 'react';
import { useContactPlotsHighlights } from 'hooks/contexts/useSecondaryPlotsContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import useHighlightedPlots from 'hooks/useHighlightedPlots';
import { filtersKey } from 'utils/filters';
const useContactPlotsLayer = () => {
    const contactPlotsHighlights = useContactPlotsHighlights();
    const { data: highlightedPlots } = useHighlightedPlots(contactPlotsHighlights);
    const contactPlotsItems = useMemo(() => {
        const plots = highlightedPlots ? Object.values(highlightedPlots) : [];
        return plots.map((plot) => ({
            ...plot,
            layerKey: filtersKey.CONTACT_PLOTS,
        }));
    }, [highlightedPlots]);
    createLayersHook({
        providedData: contactPlotsItems,
    })(filtersKey.CONTACT_PLOTS);
};
const ContactPlotsLayer = () => {
    useContactPlotsLayer();
    return null;
};
export default ContactPlotsLayer;
