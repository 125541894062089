import * as React from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { useExtraFilters } from 'hooks/contexts/useFiltersContext';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import useCustomToast from 'hooks/useCustomToast';
import { IOPolygonLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import CompanyOwnedPlotsTable, { IOCompanyOwnedPlotsTableData, } from './CompanyOwnedPlotsTable';
export const IOCompanyOwnedPlotsData = iots.type({
    table: iots.array(IOCompanyOwnedPlotsTableData),
    layers: iots.record(iots.string, IOPolygonLayerItem),
    message: iots.string || null,
});
export const fetchCompanyOwnedPlots = (sirens, townId) => () => get(IOCompanyOwnedPlotsData, `company-owned-plots/${townId}/${sirens.join(';')}`);
const CompanyOwnedPlotsPanel = () => {
    const { getErrorToast } = useCustomToast();
    const { t } = useTranslation();
    const selectedTown = useSelectedTown();
    const extraFilters = useExtraFilters();
    const townId = selectedTown?.id ? selectedTown?.id : '0';
    const sirens = extraFilters[filtersKey.COMPANY_OWNED_PLOTS]?.sirens ?? [];
    const { data, isInitialLoading: isLoading } = useQuery({
        queryKey: ['company-owned-plots', { sirens, townId }],
        queryFn: fetchCompanyOwnedPlots(sirens, townId),
        enabled: sirens.length > 0,
        meta: {
            onSuccess: (data) => {
                if (data.message) {
                    getErrorToast({
                        title: t('toast.error_title'),
                        description: data.message,
                        id: 'company-owned-plots',
                    });
                }
            },
        },
    });
    return (<CompanyOwnedPlotsTable id="company-owned-plots" data={data?.table || []} message={data?.message} isLoading={isLoading} category="company_owned_plots" count={sirens.length}/>);
};
export default memo(CompanyOwnedPlotsPanel);
