import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, CloseButton, HStack, Spinner, Text, Th, Thead, Tr, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_FEASIBILITY } from 'analytics/useAnalytics';
import ZoomMoinsIcon from 'images/icons/ZoomMoins';
import ZoomPlusIcon from 'images/icons/ZoomPlus';
const PlotColumnTableHead = ({ plot, handleClose, loading, }) => {
    const { section, number, zone } = plot;
    const { t } = useTranslation();
    if (loading) {
        return (<Th borderLeft="1px solid">
        <Spinner />
      </Th>);
    }
    return (<Th borderLeft="1px solid">
      <HStack justify="space-between">
        <Box>
          <Text variant="h5" data-cy={'plot_feasibility_plot'}>
            {section} {number}
          </Text>

          {zone && (<Text fontWeight="400" textStyle="small">
              {`${t('tabs.plots.section.feasibility.area')} ${zone?.replaceAll(/;/g, '')}`}
            </Text>)}
        </Box>
        {handleClose && (<CloseButton color="colorMode.font700" variant="link" size="sm" aria-label={t('tabs.plots.section.feasibility.columns.close')} data-cy="plot_feasibility_plot_close" onClick={handleClose}/>)}
      </HStack>
    </Th>);
};
const ToggleDetailButton = ({ showFeasibilityDetail, handleToggle, }) => {
    const { t } = useTranslation();
    return (<Button variant="link" leftIcon={<Box as="span" color="blue.500" backgroundColor="blue.100" borderRadius="md" width="20px" display="flex" justifyContent="center">
          {showFeasibilityDetail ? (<ZoomMoinsIcon width="10" fill="currentColor"/>) : (<ZoomPlusIcon width="10" fill="currentColor"/>)}
        </Box>} onClick={handleToggle}>
      <Text color="colorMode.revertBase" as="span" textStyle="small">
        {showFeasibilityDetail
            ? t('tabs.plots.section.feasibility.columns.hide_feasibility_detail')
            : t('tabs.plots.section.feasibility.columns.show_feasibility_detail')}
      </Text>
    </Button>);
};
const FeasibilityTableHead = ({ showFeasibilityDetail, setShowFeasibilityDetail, feasibility, feasibilityPlots, isLoadingFeasibilityPlot, closeOneFeasibilityPlot, hasFeasibility, }) => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    return (<Thead>
      <Tr>
        <Th borderRight="1px solid">
          <ToggleDetailButton showFeasibilityDetail={showFeasibilityDetail} handleToggle={() => {
            trackEvent({
                category: ANALYTICS_FEASIBILITY,
                action: `Faisabilité tout afficher`,
            });
            setShowFeasibilityDetail(!showFeasibilityDetail);
        }}/>
        </Th>
        <Th data-cy="plot_feasibility_calculator">
          {' '}
          {isLoadingFeasibilityPlot ? (<Spinner />) : (<>
              <Text variant="h5" maxWidth="190px" whiteSpace="normal">
                {hasFeasibility
                ? t('tabs.plots.section.feasibility.columns.calculator_kelfoncier')
                : t('tabs.plots.section.feasibility.columns.calculator')}
              </Text>
            </>)}{' '}
        </Th>
        {feasibility && (<>
            {/* TOTAL */}
            {Object.keys(feasibilityPlots).length > 1 && (<Th backgroundColor="colorMode.background700" borderLeft="1px solid" fontWeight="700" data-cy="plot_feasibility_total">
                {t('tabs.plots.section.feasibility.columns.total')}
              </Th>)}
            {/* SECONDARY PLOTS */}
            {Object.values(feasibilityPlots).map((plot) => (<PlotColumnTableHead plot={plot} key={plot?.id} loading={isLoadingFeasibilityPlot} handleClose={closeOneFeasibilityPlot
                    ? () => closeOneFeasibilityPlot(feasibility.id, plot.id)
                    : null}/>))}
          </>)}
      </Tr>
    </Thead>);
};
export default FeasibilityTableHead;
