import { useCallback, useEffect, useState } from 'react';
import { PLOT_PLOT_TAB } from 'types/api';
import { SECTIONS } from 'utils/plots';
const getUrlHash = () => window.location.hash.split('#')?.[1];
const getCurrentSection = () => {
    const hash = getUrlHash();
    if (Object.keys(SECTIONS).includes(hash)) {
        return hash;
    }
    return null;
};
export default () => {
    const [hash, setHash] = useState(getCurrentSection() ?? PLOT_PLOT_TAB);
    const hashChangeHandler = useCallback(() => {
        setHash(getCurrentSection() ?? PLOT_PLOT_TAB);
    }, []);
    useEffect(() => {
        window.addEventListener('hashchange', hashChangeHandler);
        return () => {
            window.removeEventListener('hashchange', hashChangeHandler);
        };
    }, []);
    const setSectionHashToUrl = useCallback((section) => {
        if (section !== getCurrentSection()) {
            window.location.hash = section;
            if (document.getElementById('plot-panel-scrollable')) {
                document.getElementById('plot-panel-scrollable').scrollTo(0, 0);
            }
        }
    }, []);
    const clearSectionHash = useCallback(() => {
        window.location.hash = '';
    }, []);
    return {
        sectionHash: hash,
        setSectionHashToUrl,
        clearSectionHash,
    };
};
