import * as React from 'react';
const SurfaceIcon = (props) => (<svg width="10" height="10" viewBox="0 0 10 10" {...props}>
    <g id="layer1" transform="translate(-21.88321,-25.85939)">
      <path fill="none" stroke="currentColor" strokeWidth={1} strokeLinecap="butt" strokeLinejoin="round" strokeMiterlimit="4" strokeDasharray="none" d="m 22.015501,25.85939 v 9.877675 l 9.860487,-0.0037"/>
      <path fill="none" stroke="currentColor" strokeWidth={1} strokeLinecap="butt" strokeLinejoin="round" strokeMiterlimit="4" strokeDasharray="none" d="m 24.856905,26.005881 h 1.250199"/>
      <path fill="none" stroke="currentColor" strokeWidth={1} strokeLinecap="butt" strokeLinejoin="round" strokeMiterlimit="4" strokeDasharray="none" d="m 22.795442,26.005881 c 0.849184,-10e-7 0.87163,0 1.250199,0"/>
      <path fill="none" stroke="currentColor" strokeWidth={1} strokeLinecap="butt" strokeLinejoin="round" strokeMiterlimit="4" strokeDasharray="none" d="m 26.863903,26.005881 h 1.250199"/>
      <path fill="none" stroke="currentColor" strokeWidth={1} strokeLinecap="butt" strokeLinejoin="round" strokeMiterlimit="4" strokeDasharray="none" d="m 31.73769,27.25939 v 1.250198"/>
      <path fill="none" stroke="currentColor" strokeWidth={1} strokeLinecap="butt" strokeLinejoin="round" strokeMiterlimit="4" strokeDasharray="none" d="m 31.73769,29.35939 v 1.250198"/>
      <path fill="none" stroke="currentColor" strokeWidth={1} strokeLinecap="butt" strokeLinejoin="round" strokeMiterlimit="4" strokeDasharray="none" d="m 31.77269,31.45939 v 1.250198"/>
      <path fill="none" stroke="currentColor" strokeWidth={1} strokeLinecap="butt" strokeLinejoin="round" strokeMiterlimit="4" strokeDasharray="none" d="m 31.77269,33.59739 v 1.250198"/>
      <path fill="none" stroke="currentColor" strokeWidth={1} strokeLinecap="butt" strokeLinejoin="round" strokeMiterlimit="4" strokeDasharray="none" d="m 28.924013,25.996687 h 1.250199"/>
      <path fill="none" stroke="currentColor" strokeWidth={1} strokeLinecap="butt" strokeLinejoin="round" strokeMiterlimit="4" strokeDasharray="none" d="m 3.4253971e22,26.789922 v 0"/>
      <text x="24.236769" y="32.451626">
        <tspan fontSize="5px" fontWeight="bold" strokeWidth={0.265} x="24.236769" y="32.451626">
          m
        </tspan>
      </text>
      <text x="28.868193" y="31.736254">
        <tspan fontSize="4px" fontWeight="bold" strokeWidth={0.265} x="28.868193" y="31.736254">
          ²
        </tspan>
      </text>
      <rect stroke="none" fill="currentColor" width="1.1320336" height="0.6" x="30.743954" y="25.866789"/>
      <rect stroke="none" fill="currentColor" width="0.64547426" height="0.6" x="25.866789" y="-31.875988" transform="rotate(90)"/>
    </g>
  </svg>);
export default SurfaceIcon;
