import { useColorMode } from '@chakra-ui/react';
import useCurrentSite from 'hooks/useCurrentSite';
import LogoKelFoncier from 'images/logo_kelFoncier.png';
import LogoKelFoncierWhite from 'images/logo_kelFoncier_white.png';
export const getSiteImage = (image) => {
    if (!image) {
        return null;
    }
    // handle absolute urls
    if (image.startsWith('http')) {
        return image;
    }
    try {
        return require(`../images/sites/${image}`);
    }
    catch (e) {
        //eslint-disable-next-line
        console.error('Error loading site image', `images/sites/${image}`);
        return null;
    }
};
const useSiteLogo = (site) => {
    const { currentSite } = useCurrentSite();
    const { colorMode } = useColorMode();
    site = site || currentSite;
    const logo = getSiteImage(site?.logo);
    if (!logo) {
        return colorMode === 'light' ? LogoKelFoncier : LogoKelFoncierWhite;
    }
    return logo;
};
export default useSiteLogo;
