import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
import useOnError from 'hooks/useOnError';
import { IOProjectHistory } from 'types/api';
export const QUERY_KEY = 'team-project-history';
export const fetchProjectHistory = (projectId) => () => get(iots.array(IOProjectHistory), `project/${projectId}/history/team`);
export const getTeamProjectHistoryQueryKey = (projectId) => [
    QUERY_KEY,
    projectId,
];
const useTeamProjectHistory = (isEnabled = false, projectId) => {
    const onErrorCallback = useOnError();
    return useQuery({
        queryKey: getTeamProjectHistoryQueryKey(projectId),
        queryFn: fetchProjectHistory(projectId),
        enabled: isEnabled && !!projectId,
        meta: { onError: onErrorCallback },
        retry: false,
    });
};
export default useTeamProjectHistory;
