import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import landslideImg from 'images/landslide.png';
import { IOMarkerLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
const IOPPRLandslideMarkerItem = iots.intersection([
    IOMarkerLayerItem,
    iots.type({
        title: iots.string,
        reliability: iots.string,
        town: iots.string,
        locality: iots.string,
        url: iots.string,
    }),
]);
const fetchLandslide = (townId) => () => get(iots.array(IOPPRLandslideMarkerItem), `legacy/${townId}/ppr-landslide`);
const LEGENDS = {
    ppr_landslide: {
        layerKey: filtersKey.PPR_LANDSLIDE,
        label: 'ppr_landslide',
        needsTranslate: true,
        image: landslideImg,
    },
};
const usePPRLandslideLayer = () => {
    const { t } = useTranslation();
    const getInfoWindowContent = useCallback((item) => {
        return `<b>${item.title}</b><br />${item.id}<br />${item.reliability}<br />${item.town}<br />${item.locality}<br /><a href="${item.url}" target="_blank">${t('markers.open_url')}</a>`;
    }, [t]);
    createLayersHook({
        fetch: fetchLandslide,
        markerImg: landslideImg,
        legends: LEGENDS,
        getInfoWindowContent,
    })(filtersKey.PPR_LANDSLIDE);
};
const PPRLandslideLayer = () => {
    usePPRLandslideLayer();
    return null;
};
export default PPRLandslideLayer;
