import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOVolumetryLayerItem = IOLayerItem;
const fetchVolumetry = (townId) => () => get(iots.array(IOVolumetryLayerItem), `legacy/${townId}/volumetry`);
const useVolumetryLayer = () => {
    createLayersHook({
        fetch: fetchVolumetry,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.VOLUMETRY);
};
const VolumetryLayer = () => {
    useVolumetryLayer();
    return null;
};
export default VolumetryLayer;
