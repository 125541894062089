import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOABFDocUrbaLayerItem = IOLayerItem;
const fetchABFDocUrba = (townId) => () => get(iots.array(IOABFDocUrbaLayerItem), `legacy/${townId}/abf-doc-urba`);
const useABFDocUrbaLayer = () => {
    createLayersHook({
        fetch: fetchABFDocUrba,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.ABF_DOC_URBA);
};
const ABFDocUrbaLayer = () => {
    useABFDocUrbaLayer();
    return null;
};
export default ABFDocUrbaLayer;
