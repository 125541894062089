import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, FormControl, FormErrorMessage, FormLabel, Heading, Input, MenuItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, useDisclosure, } from '@chakra-ui/react';
import { useUserData } from 'hooks/contexts/useAuthContext';
import useUserChangePassword, { validatePassword, } from 'hooks/useUserChangePassword';
const ProfileModal = () => {
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { email } = useUserData();
    const { changePassword } = useUserChangePassword(() => {
        reset();
        onClose();
    });
    const resolver = async (values) => {
        const errors = {};
        const passwordError = validatePassword(values.password);
        if (passwordError) {
            errors.password = {
                type: 'required',
                message: t(passwordError),
            };
        }
        else if (values.password !== values.confirmPassword) {
            errors.confirmPassword = {
                type: 'required',
                message: t('reset_password.passwords_do_not_match'),
            };
        }
        if (values.oldPassword === '') {
            errors.oldPassword = {
                type: 'required',
                message: t('reset_password.old_password_empty'),
            };
        }
        return { values, errors };
    };
    const { register, handleSubmit, formState: { isSubmitting, errors }, reset, } = useForm({
        resolver,
        defaultValues: {
            email: email,
        },
    });
    const onSubmit = (data) => {
        changePassword({
            password: data.password,
            confirmPassword: data.confirmPassword,
            oldPassword: data.oldPassword,
        });
    };
    return (<MenuItem onClick={onOpen} paddingX={4} paddingY={3}>
      <Text textStyle="paragraph" color="colorMode.font900">
        {t('header.account.profile_data')}
      </Text>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center">
            <Heading as="h4" variant="h4">
              {t('profile.title')}
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody textStyle="paragraph" color="colorMode.font900" paddingTop={0} paddingBottom={4}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl marginBottom={3}>
                <FormLabel marginBottom={1}>
                  {t('profile.form.email')}
                </FormLabel>
                <Input size="sm" readOnly={true} disabled={true} {...register('email')}/>
              </FormControl>
              <FormControl isInvalid={!!errors?.oldPassword} marginBottom={3}>
                <FormLabel marginBottom={1}>
                  {t('profile.form.old_password')}
                </FormLabel>
                <Input size="sm" type="password" autoComplete="new-password" {...register('oldPassword')}/>
                <FormErrorMessage marginTop={1}>
                  {errors?.oldPassword?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors?.password} marginBottom={3}>
                <FormLabel marginBottom={1}>
                  {t('profile.form.password')}
                </FormLabel>
                <Input size="sm" type="password" autoComplete="new-password" {...register('password')}/>
                <FormErrorMessage marginTop={1}>
                  {errors?.password?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors?.confirmPassword} marginBottom={3}>
                <FormLabel marginBottom={1}>
                  {t('profile.form.confirmPassword')}
                </FormLabel>
                <Input size="sm" type="password" autoComplete="new-password" {...register('confirmPassword')}/>
                <FormErrorMessage marginTop={1}>
                  {errors?.confirmPassword?.message}
                </FormErrorMessage>
              </FormControl>

              <Button width="full" variant="primary" isDisabled={isSubmitting} type="submit">
                {t('profile.form.valid')}
              </Button>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </MenuItem>);
};
export default ProfileModal;
