import { useQuery } from '@tanstack/react-query';
import { get } from 'api/client';
import { IOUserPersonalization } from 'types/api';
const QUERY_KEY = 'user-personalization';
export const fetchPersonalization = () => {
    return get(IOUserPersonalization, `user/personalization`);
};
const useUserPersonalization = () => {
    const { data: userPersonalization, isInitialLoading: isLoading } = useQuery({
        queryKey: [QUERY_KEY],
        queryFn: () => fetchPersonalization(),
    });
    return {
        userPersonalization,
        isLoading,
    };
};
export default useUserPersonalization;
