import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Box, HStack, Spinner, VStack } from '@chakra-ui/react';
import useLandPoint from 'hooks/useLandPoint';
import { getProjectStatusFromCode } from 'utils/constants';
import LandPointProject from './LandPointProject';
const getCustomStyles = () => {
    return {
        control: (provided) => ({
            ...provided,
            borderRadius: 0,
            fontSize: '14px',
        }),
    };
};
const LandPointProjects = ({ landPoint, project, setSelectedProject, }) => {
    const { t } = useTranslation();
    const [participantOptions, setParticipantOptions] = useState([]);
    const [townOptions, setTownOptions] = useState([]);
    const [statusOptions, setStatusOptions] = useState([]);
    const { landPointProjects, isLoadingProjects, refetchProjects, isRefetchingProjects, } = useLandPoint({ landPointId: landPoint.id, toLoad: 'projects' });
    const [landPointProjectsFiltered, setLandPointProjectsFiltered] = useState(landPointProjects);
    const [filterParticipant, setFilterParticipant] = useState({
        label: t('land_point.all_participant'),
        value: '0',
    });
    const [filterTown, setFilterTown] = useState({
        label: t('land_point.all_towns'),
        value: '0',
    });
    const [filterStatus, setFilterStatus] = useState({
        label: t('land_point.all_status'),
        value: '0',
    });
    useEffect(() => {
        refetchProjects(); // Relancer les projets à chaque montage du composant
    }, []);
    useEffect(() => {
        if (!landPointProjects) {
            return;
        }
        const participants = [
            {
                label: t('land_point.all_participant'),
                value: '0',
            },
        ];
        landPoint.participants.forEach((participant) => {
            participants.push({
                label: participant,
                value: participant,
            });
        });
        const towns = [
            {
                label: t('land_point.all_towns'),
                value: '0',
            },
        ];
        const status = [
            {
                label: t('land_point.all_status'),
                value: '0',
            },
        ];
        landPointProjects.forEach((landPointProject) => {
            const haveAlreadyTown = towns.filter((town) => town.value === landPointProject.townId);
            const haveAlreadyStatus = status.filter((s) => s.value === landPointProject.status.toString());
            if (haveAlreadyTown.length === 0) {
                towns.push({
                    label: landPointProject.town,
                    value: landPointProject.townId,
                });
            }
            if (haveAlreadyStatus.length === 0) {
                status.push({
                    label: getProjectStatusFromCode(landPointProject.status),
                    value: landPointProject.status.toString(),
                });
            }
        });
        setParticipantOptions(participants);
        setTownOptions(towns);
        setStatusOptions(status);
        setLandPointProjectsFiltered(landPointProjects);
        if (!project) {
            setSelectedProject(landPointProjects[0]);
        }
        applyFilter(filterParticipant, filterTown, filterStatus);
    }, [landPointProjects]);
    const handleParticipantChange = (option) => {
        setFilterParticipant(option);
        applyFilter(option, filterTown, filterStatus);
    };
    const handleTownChange = (option) => {
        setFilterTown(option);
        applyFilter(filterParticipant, option, filterStatus);
    };
    const handleStatusChange = (option) => {
        setFilterStatus(option);
        applyFilter(filterParticipant, filterTown, option);
    };
    const applyFilter = (participant, town, status) => {
        let landPointProjectsFiltering = landPointProjects;
        if (participant.value !== '0') {
            landPointProjectsFiltering = landPointProjects.filter((landPointProject) => landPointProject.userName === participant.value);
        }
        if (town.value !== '0') {
            landPointProjectsFiltering = landPointProjects.filter((landPointProject) => landPointProject.townId === town.value);
        }
        if (status.value !== '0') {
            landPointProjectsFiltering = landPointProjectsFiltering.filter((landPointProject) => landPointProject.status.toString() === status.value);
        }
        setLandPointProjectsFiltered(landPointProjectsFiltering);
    };
    return (<>
      <HStack spacing={0}>
        {participantOptions.length > 2 && (<Box flex={1}>
            <Select placeholder={t('land_point.all_participant')} options={participantOptions} isLoading={isLoadingProjects} onChange={handleParticipantChange} value={filterParticipant} styles={getCustomStyles()}/>
          </Box>)}
        <Box flex={1}>
          <Select placeholder={t('land_point.all_towns')} options={townOptions} isLoading={isLoadingProjects} onChange={handleTownChange} value={filterTown} styles={getCustomStyles()}/>
        </Box>
        <Box flex={1}>
          <Select placeholder={t('land_point.all_status')} options={statusOptions} isLoading={isLoadingProjects} onChange={handleStatusChange} value={filterStatus} styles={getCustomStyles()}/>
        </Box>
      </HStack>
      {isLoadingProjects ||
            isRefetchingProjects ||
            !landPointProjectsFiltered ? (<Box display="flex" alignItems="center" justifyContent="center" height="100%">
          <Spinner />
        </Box>) : (<VStack height="calc(100% - 40px)" spacing={4} padding={2} overflowY="auto">
          {landPointProjectsFiltered.length === 0 ? (<Box display="flex" alignItems="center" textAlign="center">
              {t('land_point.no_results')}
            </Box>) : (landPointProjectsFiltered.map((landPointProject) => {
                return (<LandPointProject key={landPointProject.id} project={landPointProject} projectId={project?.id} setSelectedProject={setSelectedProject} nbParticipant={landPoint.participants.length}/>);
            }))}
        </VStack>)}
    </>);
};
export default LandPointProjects;
