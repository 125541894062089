import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Translation } from 'react-i18next';
import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
const IOCitiesOfTomorrowLayerItem = IOLayerItem;
const fetchCitiesOfTomorrow = (townId) => () => get(iots.array(IOCitiesOfTomorrowLayerItem), `legacy/${townId}/cities-of-tomorrow`);
const getInfoWindowContent = (layerInfo) => {
    const content = (<>
      {layerInfo?.infoTitle && (<>
          {layerInfo.infoTitle}
          <br />
        </>)}
      {layerInfo?.infoLine1 && (<>
          {layerInfo.infoLine1}
          <br />
        </>)}
      {layerInfo.infoLine2 &&
            (layerInfo.infoLine2.includes('http') ? (<a href={layerInfo.infoLine2} target="_blank" rel="noreferrer">
            <Translation>
              {(t) => t('info_window.cities_of_tomorrow.link')}
            </Translation>
          </a>) : (layerInfo.infoLine2))}
    </>);
    return ReactDOMServer.renderToString(content);
};
const useCitiesOfTomorrowLayer = () => {
    createLayersHook({
        fetch: fetchCitiesOfTomorrow,
        getInfoWindowContent,
    })(filtersKey.CITIES_OF_TOMORROW);
};
const CitiesOfTomorrowLayer = () => {
    useCitiesOfTomorrowLayer();
    return null;
};
export default CitiesOfTomorrowLayer;
