import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, HStack, Icon, Tab, TabList, TabPanel, TabPanels, Tabs, Text, } from '@chakra-ui/react';
import CalendarIcon from 'images/icons/Calendar';
import TooltipIcon from 'images/icons/Tooltip';
import DashboardTitle from './sidebar/DashboardSideBar/DashboardTitle';
import CustomTooltip from './tooltips/CustomTooltip';
const activeStyle = {
    color: 'white',
    bg: 'blue.600',
    fill: 'white',
};
const RoundedTabs = ({ tabsList, children, }) => {
    const { t } = useTranslation();
    return (<Tabs variant="unstyled" colorScheme="blue" width="100%" defaultIndex={0} isLazy height="100%" display="flex" flexDirection="column">
      <HStack justifyContent="space-between" spacing={0} flexWrap="wrap">
        <HStack alignItems="baseline">
          <DashboardTitle label={t(`tabs.dashboard.title_agenda`)} icon={CalendarIcon}/>
          <TabList>
            <Box backgroundColor="colorMode.background700" borderRadius="100px" display="flex">
              {tabsList.map((tab, key) => (<Tab _selected={activeStyle} _hover={activeStyle} key={key} fontSize="xs" fontWeight="bold" borderRadius="100px" color="gray.800" fill="gray.800">
                  <HStack>
                    {tab?.icon}
                    <Text color="inherit">{tab?.title}</Text>
                  </HStack>
                </Tab>))}
            </Box>
          </TabList>
          <CustomTooltip label={t('tabs.extra_tabs.activity.info')}>
            <Box borderRadius="full" boxShadow="md" display="flex" justifyContent="center" alignItems="center" width="16px" height="16px" backgroundColor="white" cursor="pointer">
              <Icon as={TooltipIcon}/>
            </Box>
          </CustomTooltip>
        </HStack>
        {children}
      </HStack>

      <TabPanels paddingTop={2} flex={1}>
        {tabsList.map((tab, key) => (<TabPanel key={key} padding={0} height="100%">
            {tab?.content}
          </TabPanel>))}
      </TabPanels>
    </Tabs>);
};
export default RoundedTabs;
