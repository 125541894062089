import { useMemo } from 'react';
import { useQueries } from '@tanstack/react-query';
import { getImage } from 'api/client';
const fetchProjectImage = (photo) => () => getImage(`read/${photo.url}`, photo.id.toString());
const useProjectImages = (projectId, photos) => {
    const results = useQueries({
        queries: photos.map((photo) => ({
            queryKey: ['photos', projectId, photo.id],
            queryFn: fetchProjectImage(photo),
            staleTime: Infinity,
            enabled: !!photo.id,
        })),
    });
    const isLoading = results.some((result) => result.isInitialLoading);
    const data = useMemo(() => {
        let photos = {};
        results.forEach((result) => {
            photos = {
                ...result.data,
                ...photos,
            };
        });
        return photos;
    }, [results]);
    return { data, isLoading };
};
export default useProjectImages;
