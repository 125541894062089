import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { VECTOR_TYPES } from 'types/types';
import { filtersKey } from 'utils/filters';
import { getLayerCoordTileUrl } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = () => (coord, zoom) => {
    return getLayerCoordTileUrl('ENR.PERIMETRE.VOIE.FERREE', 'PERIMETRE.VOIE.FERREE', zoom, coord);
};
const getLegends = () => {
    const legends = {};
    legends.wind_constraint_railway = {
        layerKey: filtersKey.WIND_CONSTRAINT_RAILWAY,
        label: 'wind_constraint_railway',
        needsTranslate: true,
        color: '#2F2F30',
        polygonType: VECTOR_TYPES.polyline,
    };
    return legends;
};
const useWindRailwayLayer = () => {
    const options = {
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.WIND_CONSTRAINT_RAILWAY],
        getLegends,
    };
    createTilesLayerHook(options)(filtersKey.WIND_CONSTRAINT_RAILWAY);
};
const WindRailwayLayer = () => {
    useWindRailwayLayer();
    return null;
};
export default WindRailwayLayer;
