import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Image, Link, Table, TableContainer, Tbody, Td, Tr, } from '@chakra-ui/react';
import * as iots from 'io-ts';
import { RETAIL_TYPE } from 'utils/constants';
const IOSchedule = iots.type({
    monday: iots.string,
    tuesday: iots.string,
    wednesday: iots.string,
    thursday: iots.string,
    friday: iots.string,
    saturday: iots.string,
    sunday: iots.string,
});
export const IORetailInfo = iots.type({
    id: iots.string,
    name: iots.string,
    sign: iots.string,
    type: iots.number,
    logo: iots.string,
    address: iots.string,
    postalCode: iots.string,
    town: iots.string,
    phone: iots.string,
    email: iots.string,
    url: iots.string,
    schedule: iots.union([IOSchedule, iots.null]),
    services: iots.string,
});
const scheduleFormat = (t, schedule) => {
    if (schedule === 'Fermé') {
        schedule = t('global.closed');
    }
    else {
        schedule = schedule.replace('-', ' ' + t('global.a') + ' ');
    }
    return schedule;
};
const RetailInfoWindow = ({ info }) => {
    const { t } = useTranslation();
    if (!info) {
        return null;
    }
    return (<Box maxHeight="400px">
      <table>
        {info.logo && (<tr>
            <td colSpan={2} align="center">
              <Image src={require('../../images/' + info.logo)} style={{
                maxWidth: '200px',
                maxHeight: '100px',
                marginBottom: '0.5em',
            }}/>
            </td>
          </tr>)}
        <tr>
          <td style={{ paddingRight: '1em' }}>{t('retail.sign')}</td>
          <td>{info.sign}</td>
        </tr>
        <tr>
          <td>{t('retail.name')}</td>
          <td>{info.name}</td>
        </tr>
        <tr>
          <td>{t('retail.type')}</td>
          <td>{t(RETAIL_TYPE[info.type].label)}</td>
        </tr>
        <tr>
          <td>{t('retail.address')}</td>
          <td>
            {info.address} {info.postalCode} {info.town}
          </td>
        </tr>
        {info.email && (<tr>
            <td>{t('retail.phone')}</td>
            <td>{info.phone}</td>
          </tr>)}
        {info.email && (<tr>
            <td>{t('retail.mail')}</td>
            <td>{info.email}</td>
          </tr>)}
        {info.services && (<tr>
            <td>{t('retail.services')}</td>
            <td>{info.services}</td>
          </tr>)}
        {info.url && (<tr>
            <td colSpan={2}>
              <Link href={info.url} isExternal variant="primary">
                {t('retail.open_url')}
              </Link>
            </td>
          </tr>)}
        {info.schedule && (<tr>
            <td colSpan={2}>
              <TableContainer width="100%">
                <Table>
                  <Tbody>
                    <Tr>
                      <Td>{t('global.week.monday')}</Td>
                      <Td>{scheduleFormat(t, info.schedule.monday)}</Td>
                    </Tr>
                    <Tr>
                      <Td>{t('global.week.tuesday')}</Td>
                      <Td>{scheduleFormat(t, info.schedule.tuesday)}</Td>
                    </Tr>
                    <Tr>
                      <Td>{t('global.week.wednesday')}</Td>
                      <Td>{scheduleFormat(t, info.schedule.wednesday)}</Td>
                    </Tr>
                    <Tr>
                      <Td>{t('global.week.thursday')}</Td>
                      <Td>{scheduleFormat(t, info.schedule.thursday)}</Td>
                    </Tr>
                    <Tr>
                      <Td>{t('global.week.friday')}</Td>
                      <Td>{scheduleFormat(t, info.schedule.friday)}</Td>
                    </Tr>
                    <Tr>
                      <Td>{t('global.week.saturday')}</Td>
                      <Td>{scheduleFormat(t, info.schedule.saturday)}</Td>
                    </Tr>
                    <Tr>
                      <Td style={{ paddingRight: '1em' }}>
                        {t('global.week.sunday')}
                      </Td>
                      <Td>{scheduleFormat(t, info.schedule.sunday)}</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </td>
          </tr>)}
      </table>
    </Box>);
};
export default RetailInfoWindow;
