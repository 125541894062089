import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { NO_IMPACT_RULE } from 'components/tabs/plots/PlotSections/rules/PlotLayers';
import LogoKelFoncier from 'images/logo_kelFoncier.png';
import { parseCells } from './utils';
const addPDFFooters = (doc) => {
    if (doc.internal.pages.length === 0) {
        return;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const pageCount = [...Array(doc.internal.getNumberOfPages())].map((el, index) => index + 1);
    const image = LogoKelFoncier;
    doc.setFont('Helvetica', 'normal');
    doc.setFontSize(8);
    pageCount.forEach((page) => {
        doc.setPage(page);
        const date = new Date();
        doc.text(`${date.toLocaleDateString('fr')} à ${date.toLocaleTimeString('fr')}`, 14, 287);
        doc.addImage(image, 'PNG', doc.internal.pageSize.width / 2 - 31 / 2, 284, 31, 8);
        doc.text('Page ' + String(page) + ' / ' + String(pageCount.length), doc.internal.pageSize.width - 14, 287, {
            align: 'right',
        });
    });
};
const getCellsFromRow = ({ row, headers, formatValue }) => {
    const cells = [];
    headers.forEach((header) => {
        cells.push(formatValue(row[header], header, row));
    });
    return cells;
};
export const addPDFPageFromData = (data, doc, pageTitle, formatHeader = (header) => header, formatValue = (value) => value, exportedFields, mode = 'horizontal') => {
    if (!data || !data.length) {
        return false;
    }
    const tableData = [];
    const headers = exportedFields || Object.keys(data[0]);
    doc.setFont('Helvetica', 'normal');
    doc.text(pageTitle, doc.internal.pageSize.width / 2, 15, {
        align: 'center',
        maxWidth: doc.internal.pageSize.width - 20,
    });
    if (mode === 'horizontal') {
        const columnStyles = {};
        data.map((row, number) => {
            const cells = getCellsFromRow({
                row,
                headers,
                formatValue,
            });
            if (number === 0) {
                // Alignement des nombres à droite
                headers.forEach((header, num) => {
                    if (!isNaN(formatValue(row[header], header, row))) {
                        columnStyles[num] = { halign: 'right' };
                    }
                });
            }
            tableData.push(cells);
        });
        // numberColumns.forEach((column) => {
        //   columnStyles[headers.findIndex((value) => column === value)] = {
        //     halign: 'right',
        //   }
        // })
        autoTable(doc, {
            startY: 25,
            theme: 'grid',
            headStyles: {
                fillColor: '#1469BE',
                halign: 'center',
            },
            styles: { fontSize: 9 },
            columnStyles,
            head: [headers.map((header) => formatHeader(header))],
            body: tableData,
            didParseCell: parseCells,
        });
    }
    if (mode === 'vertical') {
        const regexNoImpactRule = new RegExp(`.?${NO_IMPACT_RULE}.?`, 'gi');
        data.map((item) => {
            const itemData = [];
            headers.forEach((field) => {
                let values = formatValue(item[field], field, item);
                // Only for plotRules
                if (typeof values === 'string') {
                    // EPF, TVA, SCOT
                    if (values.includes(';')) {
                        const rules = values.split(';').filter((rule) => {
                            return !rule.match(regexNoImpactRule);
                        });
                        values = rules.join(';');
                    }
                    else if (values.match(regexNoImpactRule)) {
                        return;
                    }
                }
                if (!values) {
                    return;
                }
                if (Array.isArray(values)) {
                    itemData.push([formatHeader(field), ...values]);
                }
                else {
                    itemData.push([formatHeader(field), values]);
                }
            });
            tableData.push(itemData);
        });
        tableData.forEach((row, index) => {
            autoTable(doc, {
                startY: 25,
                body: row,
                columnStyles: {
                    0: { fillColor: [41, 128, 185], textColor: 255 },
                },
            });
            if (index < tableData.length - 1) {
                doc.addPage();
            }
        });
    }
};
export const exportDataToPdf = (fileName, sheets) => {
    if (!sheets || !sheets.length) {
        return false;
    }
    let doc = null;
    sheets.forEach((sheet) => {
        const data = sheet.getData();
        if (!data || !data.length) {
            return;
        }
        const horizontal = sheet.mode === 'horizontal';
        if (doc === null) {
            doc = new jsPDF(horizontal
                ? {
                    orientation: 'l',
                    unit: 'mm',
                    format: 'a3',
                }
                : {
                    orientation: 'p',
                    unit: 'mm',
                    format: 'a4',
                });
        }
        else {
            doc.addPage(horizontal ? 'a3' : 'a4', horizontal ? 'l' : 'p');
        }
        addPDFPageFromData(data, doc, sheet.sheetTitle, sheet.formatHeader, sheet.formatValue, sheet.exportedFields, sheet.mode);
    });
    addPDFFooters(doc);
    doc.save(`${fileName}.pdf`);
};
