import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Popover, PopoverAnchor, PopoverContent, Text, useDisclosure, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_TELECOMMANDE_ACTIONS, } from 'analytics/useAnalytics';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { useMenuItemSelected } from 'hooks/contexts/useMenuContext';
import { menuItems } from './sidebar/utils';
const SearchGoogleMapPopover = ({ showNoResult, showHelper, children, inputRef, }) => {
    const { trackEvent } = useAnalytics();
    const menuItemSelected = useMenuItemSelected();
    const selectedTown = useSelectedTown();
    const { t } = useTranslation();
    const { onOpen, onClose, isOpen } = useDisclosure();
    const hasAlreadyClosed = window.localStorage.getItem('kelfoncier-town-helper');
    React.useEffect(() => {
        if (isOpen && (menuItems.NEWS === menuItemSelected || selectedTown)) {
            onClose();
        }
        if (!selectedTown &&
            menuItems.NEWS !== menuItemSelected &&
            !isOpen &&
            !hasAlreadyClosed &&
            showHelper) {
            onOpen();
        }
    }, [selectedTown, menuItemSelected]);
    return (<Popover isOpen={isOpen || showNoResult} onOpen={onOpen} onClose={onClose} closeOnBlur={false} autoFocus={false}>
      <PopoverAnchor>{children}</PopoverAnchor>
      <PopoverContent data-cy="search_popover" p={5} width={inputRef?.current?.offsetWidth} padding="4" borderBottomRadius="md" backgroundColor="colorMode.background900" display={{
            base: 'none',
            md: 'flex',
        }} flexDirection="column" boxShadow="lg" top={-1} borderColor="transparent">
        {showNoResult && (<>
            <Text textStyle="paragraph" fontWeight="500">
              {t('header.autocomplete.no_result', {
                search: inputRef?.current?.value,
            })}
            </Text>
            <Text textStyle="paragraph">
              {t('header.autocomplete.no_result2')}
            </Text>
          </>)}
        {!showNoResult && !hasAlreadyClosed && showHelper && (<>
            <Text textStyle="paragraph" marginBottom="4" fontWeight="500">
              {t('header.help_overlay.content')}
            </Text>
            <Button variant="outline" size="sm" marginLeft="auto" alignSelf="end" onClick={() => {
                onClose();
                trackEvent({
                    category: ANALYTICS_TELECOMMANDE_ACTIONS,
                    action: 'J’ai compris',
                });
                window.localStorage.setItem('kelfoncier-town-helper', 'closed');
            }} data-cy="google_map_popover_close">
              {t('header.help_overlay.close')}
            </Button>
          </>)}
      </PopoverContent>
    </Popover>);
};
export default SearchGoogleMapPopover;
