import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IONaturalPeriUrbanAreaProtectionPerimeterLayerItem = IOLayerItem;
const fetchNaturalPeriUrbanAreaProtectionPerimeter = (townId) => () => get(iots.array(IONaturalPeriUrbanAreaProtectionPerimeterLayerItem), `legacy/${townId}/natural-peri-urban-area-protection-perimeter`);
const useNaturalPeriUrbanAreaProtectionPerimeterLayer = () => {
    createLayersHook({
        fetch: fetchNaturalPeriUrbanAreaProtectionPerimeter,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.NATURAL_PERI_URBAN_AREA_PROTECTION_PERIMETER);
};
const NaturalPeriUrbanAreaProtectionPerimeterLayer = () => {
    useNaturalPeriUrbanAreaProtectionPerimeterLayer();
    return null;
};
export default NaturalPeriUrbanAreaProtectionPerimeterLayer;
