import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOTramFootprintLayerItem = IOLayerItem;
const fetchTramFootprint = (townId) => () => get(iots.array(IOTramFootprintLayerItem), `legacy/${townId}/tram-footprint`);
const useTramFootprintLayer = () => {
    createLayersHook({
        fetch: fetchTramFootprint,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.TRAM_FOOTPRINT);
};
const TramFootprintLayer = () => {
    useTramFootprintLayer();
    return null;
};
export default TramFootprintLayer;
