import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Button } from '@chakra-ui/react';
import { useIsAllOpened, usetoggleAllOpened, } from 'hooks/contexts/useFiltersAccordionContext';
import { useFiltersSearchRegex } from 'hooks/contexts/useFiltersContext';
const OpenAllFiltersButton = () => {
    const toggleAllOpened = usetoggleAllOpened();
    const isAllOpened = useIsAllOpened();
    const { t } = useTranslation();
    const filtersSearchRegex = useFiltersSearchRegex();
    const isOpened = isAllOpened || filtersSearchRegex !== null;
    return (<Button size="sm" onClick={toggleAllOpened} paddingY={2} rightIcon={isOpened ? <ChevronUpIcon /> : <ChevronDownIcon />} width="full" data-cy="toggle_open_all">
      {isAllOpened
            ? t('sidebar.menu.toggle_all_close')
            : t('sidebar.menu.toggle_all_open')}
    </Button>);
};
export default OpenAllFiltersButton;
