import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
import { IOPPRFloodingOtherContingenciesLayerItem, } from './PPRFloodingStrongHazardLayer';
const fetchPPRFloodingOtherContingencies = (townId) => () => get(iots.array(IOPPRFloodingOtherContingenciesLayerItem), `legacy/${townId}/ppr-flooding-medium-hazard`);
const usePPRFloodingMediumHazardLayer = () => {
    createLayersHook({
        fetch: fetchPPRFloodingOtherContingencies,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.PPR_FLOODING_MEDIUM_HAZARD);
};
const PPRFloodingMediumHazardLayer = () => {
    usePPRFloodingMediumHazardLayer();
    return null;
};
export default PPRFloodingMediumHazardLayer;
