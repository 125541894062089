import * as React from 'react';
import { useEffect } from 'react';
import { createContext } from 'use-context-selector';
import { menuItems } from 'components/sidebar/utils';
import { useTownError } from 'hooks/contexts/useLocationContext';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
export const MenuContext = createContext(null);
export const MenuProvider = ({ children }) => {
    const { hasMenuItem } = useSiteConfiguration();
    const defaultMenuItem = hasMenuItem('dashboard') ? menuItems.DASHBOARD : null;
    const [menuOpened, setMenuOpened] = React.useState(!!defaultMenuItem);
    const [popoverOpenId, setPopoverOpenId] = React.useState(null);
    const [menuItemSelected, setMenuItemSelected] = React.useState(defaultMenuItem);
    const [newsSelected, setNewsSelected] = React.useState(null);
    const townError = useTownError();
    const resetMenu = () => {
        setMenuItemSelected(defaultMenuItem);
        setMenuOpened(!!defaultMenuItem);
    };
    useEffect(() => {
        resetMenu();
    }, [defaultMenuItem]);
    useEffect(() => {
        if (townError && menuOpened) {
            setMenuItemSelected(defaultMenuItem);
            setMenuOpened(false);
        }
    }, [townError]);
    const toggleMenu = (clickedMenuItem, value) => {
        const toggleValue = value !== undefined ? value : !menuOpened;
        if (clickedMenuItem) {
            // Only toggle if use clicks on already selected btn
            if (clickedMenuItem === menuItemSelected) {
                setMenuOpened(toggleValue);
                return;
            }
            // If we navigate inside menu just select new item don't toggle menu
            setMenuItemSelected(clickedMenuItem);
            if (!menuOpened)
                setMenuOpened(true);
            return;
        }
        //Else toggle menu only
        setMenuOpened(toggleValue);
    };
    return (<MenuContext.Provider value={{
            menuOpened,
            menuItemSelected,
            setMenuItemSelected,
            newsSelected,
            setNewsSelected,
            resetMenu,
            toggleMenu,
            popoverOpenId,
            setPopoverOpenId,
        }}>
      {children}
    </MenuContext.Provider>);
};
export default MenuContext;
