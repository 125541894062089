export function dataToSwkt(geometry, coordinates) {
    const type = geometry.toUpperCase();
    const points = [];
    if (type === 'POINT') {
        points.push(coordinates[0] + ' ' + coordinates[1]);
    }
    else {
        coordinates.forEach((coordinate) => {
            if (type === 'LINESTRING') {
                points.push(coordinate[0] + ' ' + coordinate[1]);
            }
            else {
                coordinate.forEach((coord) => {
                    points.push(coord[0] + ' ' + coord[1]);
                });
            }
        });
    }
    switch (type) {
        case 'LINESTRING':
        case 'POINT':
            return type + '(' + points.join(',') + ')';
        default:
            return type + '((' + points.join(',') + '))';
    }
}
