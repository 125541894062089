import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Menu, MenuItem, MenuList } from '@chakra-ui/react';
import useAnalytics from 'analytics/useAnalytics';
import { AGENDA_ACTIVITY_TYPES } from 'utils/constants';
const ActivityButton = ({ activityInfoDefault, children, setActivityInfo, analyticsCategory, }) => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const createActivity = (activityInfoDefault, agendaType) => {
        setActivityInfo({
            ...activityInfoDefault,
            agendaType,
        });
    };
    return (<Box>
      <Menu>
        {children}
        <MenuList>
          {Object.keys(AGENDA_ACTIVITY_TYPES).map((agendaType) => {
            return (<MenuItem key={agendaType} onClick={() => {
                    createActivity(activityInfoDefault, AGENDA_ACTIVITY_TYPES[agendaType]);
                    trackEvent({
                        category: analyticsCategory,
                        action: t(`activity.fields.common.agenda_type.${agendaType}`),
                    });
                }}>
                {t(`tabs.plots.section.my_crm.contact.add_activity.${agendaType}`)}
              </MenuItem>);
        })}
        </MenuList>
      </Menu>
    </Box>);
};
export default ActivityButton;
