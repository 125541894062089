import { filtersKey } from 'utils/filters';
import { getTileBoundingBox } from 'utils/geo';
import { CST_POSITIONS } from 'utils/tilesPositions';
import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
const getLayerTileUrl = (map, tileWidth, tileHeight) => (coord, zoom) => {
    const boundingBox = getTileBoundingBox(coord, zoom, map, tileWidth, tileHeight);
    return ('https://data.geopf.fr/wms-r/wms?' +
        'LAYERS=PRAIRIES.SENSIBLES.BCAE' +
        '&SERVICE=WMS' +
        '&TRANSPARENT=true' +
        '&VERSION=1.3.0&REQUEST=GetMap' +
        '&FORMAT=image/png' +
        '&TILED=true&WIDTH=' +
        tileWidth +
        '&HEIGHT=' +
        tileHeight +
        '&CRS=EPSG:4326&STYLES=&BBOX=' +
        boundingBox);
};
const getLegends = () => {
    const legends = {};
    legends.photovoltaic_not_prone_area_prairie = {
        layerKey: filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_PRAIRIE,
        label: 'photovoltaic_not_prone_area_prairie',
        needsTranslate: true,
        color: '#F08E3C',
        opacity: 0.8,
    };
    return legends;
};
const usePhotovoltaicNotProneAreaPrairieLayer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_PRAIRIE],
        getLegends,
        opacity: 0.8,
    })(filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_PRAIRIE);
};
const PhotovoltaicNotProneAreaPrairieLayer = () => {
    usePhotovoltaicNotProneAreaPrairieLayer();
    return null;
};
export default PhotovoltaicNotProneAreaPrairieLayer;
