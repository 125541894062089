import moment from 'moment';
export const STANDARD_DATE_FORMAT = 'YYYY-MM-DD';
export const LETTER_DATE_FORMAT = 'DD MMMM YYYY';
export const STANDARD_HOURS_FORMAT = 'HH:mm:SS';
export const DATE_DISPLAY_FORMAT = 'DD/MM/YYYY';
export const HOURS_DISPLAY_FORMAT = 'HH[h]mm';
// almost ok natively but missing the h
// return new Date(info.getValue())?.toLocaleString('fr-FR', {
//   year: 'numeric',
//   month: 'short',
//   day: 'numeric',
// }) => "26 sept. 2022, 16:44"
export const formatDate = (date, type = 'short', lang = 'fr') => {
    if (!date)
        return '';
    moment.locale(lang);
    let momentDate;
    switch (type) {
        case 'extended':
            momentDate = moment(date, `${STANDARD_DATE_FORMAT} ${STANDARD_HOURS_FORMAT}`);
            break;
        case 'extendedToShort':
            momentDate = moment(date, `${STANDARD_DATE_FORMAT} ${STANDARD_HOURS_FORMAT}`);
            break;
        case 'shortLetter':
        case 'short':
        default:
            momentDate = moment(date, STANDARD_DATE_FORMAT);
            momentDate = moment(date, STANDARD_DATE_FORMAT);
            break;
    }
    if (momentDate.isValid()) {
        switch (type) {
            case 'extended':
                return momentDate.format(`${DATE_DISPLAY_FORMAT} ${HOURS_DISPLAY_FORMAT}`);
            case 'shortLetter': {
                return momentDate.format(LETTER_DATE_FORMAT);
            }
            case 'YYYY-MM':
                if (lang === 'fr') {
                    return momentDate.format('MM/YYYY');
                }
                return momentDate.format(type);
            case 'short':
            case 'extendedToShort':
            default:
                return momentDate.format(DATE_DISPLAY_FORMAT);
        }
    }
    return '';
};
export const calculateAndGetElapsedTime = (t, startDate, endDate, withHours = false) => {
    const start = moment(startDate);
    const end = moment(endDate);
    const years = end.diff(start, 'years');
    start.add(years, 'years');
    const months = end.diff(start, 'months');
    start.add(months, 'months');
    const days = end.diff(start, 'days');
    start.add(days, 'days');
    const hours = end.diff(start, 'hours');
    start.add(hours, 'hours');
    const minutes = end.diff(start, 'minutes');
    start.add(minutes, 'minutes');
    let elaspedTimeText = t('global.there_is') + ' ';
    if (years > 0) {
        elaspedTimeText += t('global.year', { count: years }) + ' ';
        if (months > 0) {
            elaspedTimeText += t('global.and') + ' ';
        }
    }
    if (months > 0) {
        elaspedTimeText += t('global.month', { count: months }) + ' ';
    }
    if (withHours && days < 1) {
        if (hours > 0) {
            elaspedTimeText += t('global.hour', { count: hours }) + ' ';
        }
        else if (minutes > 0) {
            elaspedTimeText += t('global.minute', { count: minutes });
        }
        else {
            elaspedTimeText += t('global.less_than_minute', { count: minutes });
        }
    }
    else if (years === 0 && months === 0) {
        if (days === 0) {
            elaspedTimeText = t('global.today');
        }
        else {
            elaspedTimeText += t('global.day', { count: days });
        }
    }
    return elaspedTimeText;
};
