import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOPreservedLandscapeLayerItem = IOLayerItem;
const fetchPreservedLandscape = (townId) => () => get(iots.array(IOPreservedLandscapeLayerItem), `legacy/${townId}/preserved-landscape`);
const usePreservedLandscapeLayer = () => {
    createLayersHook({
        fetch: fetchPreservedLandscape,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.PRESERVED_LANDSCAPE);
};
const PreservedLandscapeLayer = () => {
    usePreservedLandscapeLayer();
    return null;
};
export default PreservedLandscapeLayer;
