import { useCallback, useState } from 'react';
import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
import * as iots from 'io-ts';
import { get } from 'api/client';
import MRAEInfoWindow, { IOMRAEInfo, } from 'components/infoWindow/MRAEInfoWindow';
import { useExtraFilters } from 'hooks/contexts/useFiltersContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import MRAEImg from 'images/POIImages/Pin_MRAE_wind.png';
import { filtersKey } from 'utils/filters';
import { IOMRAEMarkerItem } from './MRAELayer';
import { isTimePeriodAllowed } from './MRAELayer';
const fetchMRAE = () => () => get(iots.array(IOMRAEMarkerItem), `mrae/wind`);
const fetchMRAEInfo = (item) => {
    return get(IOMRAEInfo, `mrae-info/${item.id}`);
};
export const formatInfoWindowContent = (info) => {
    const content = <MRAEInfoWindow info={info}/>;
    return ReactDOMServer.renderToStaticMarkup(content);
};
const LEGENDS = {
    wind_mrae: {
        label: filtersKey.WIND_MRAE,
        layerKey: filtersKey.WIND_MRAE,
        needsTranslate: true,
        image: MRAEImg,
    },
};
const useWindMRAELayer = () => {
    const extraFilters = useExtraFilters();
    const [markerInfo, setMarkerInfo] = useState({});
    const allowMarker = useCallback((marker) => {
        const date = extraFilters?.[filtersKey.WIND_MRAE]?.timePeriod ?? null;
        return isTimePeriodAllowed(marker, date);
    }, [extraFilters]);
    const getInfoWindowContent = async (marker) => {
        if (markerInfo[marker.id]) {
            return markerInfo[marker.id];
        }
        const mraeInfo = await fetchMRAEInfo(marker);
        const content = formatInfoWindowContent(mraeInfo);
        setMarkerInfo({
            ...markerInfo,
            [marker.id]: content,
        });
        return content;
    };
    createLayersHook({
        fetch: fetchMRAE,
        markerImg: MRAEImg,
        getInfoWindowContent,
        legends: LEGENDS,
        allowLayer: allowMarker,
    })(filtersKey.WIND_MRAE);
};
const WindMRAELayer = () => {
    useWindMRAELayer();
    return null;
};
export default WindMRAELayer;
