import * as React from 'react';
import { Stack } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { FiltersAccordionProvider } from 'context/FiltersAccordionContext';
import { useIsLoadingLayersTree, useMainLayers, useSecondaryLayers, } from 'hooks/contexts/useLayersTreeContext';
import FiltersHeader from './FiltersHeader';
import FiltersSearchInput from './FiltersSearchInput';
import MainLayersFilters from './MainLayersFilters';
import SecondaryLayersFilters from './SecondaryLayersFilters';
const FiltersSideBar = ({ isOpen }) => {
    const mainLayers = useMainLayers();
    const secondaryLayers = useSecondaryLayers();
    const isLoadingLayersTree = useIsLoadingLayersTree();
    const isLoading = isLoadingLayersTree || isEmpty(secondaryLayers);
    const isMainLayersLoading = !mainLayers?.layers?.length;
    if (isOpen)
        return (<FiltersAccordionProvider>
        <Stack height="100%" spacing={4} paddingTop={4} data-cy="layertree_panel">
          <Stack paddingX={4} spacing={2}>
            <FiltersHeader />
            <MainLayersFilters mainLayers={mainLayers} isFilterSideBarLoading={isLoading} isMainLayersLoading={isMainLayersLoading}/>
            <FiltersSearchInput isFilterSideBarLoading={isLoading} isMainLayersLoading={isLoadingLayersTree}/>
          </Stack>
          <SecondaryLayersFilters isFilterSideBarLoading={isLoading}/>
        </Stack>
      </FiltersAccordionProvider>);
    return null;
};
export default FiltersSideBar;
