import { useTranslation } from 'react-i18next';
import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import undergroundCavitiesImg from 'images/underground_cavities.png';
import { IOMarkerLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
const IOUndergroundCavitiesMarkerItem = iots.intersection([
    IOMarkerLayerItem,
    iots.partial({
        cavityId: iots.string,
        title: iots.string,
        date: iots.string,
        type: iots.string,
        url: iots.string,
    }),
]);
const fetchUndergroundCavities = (townId) => () => get(iots.array(IOUndergroundCavitiesMarkerItem), `legacy/${townId}/underground-cavities`);
const LEGENDS = {
    underground_cavities: {
        layerKey: filtersKey.UNDERGROUND_CAVITIES,
        label: 'underground_cavities',
        needsTranslate: true,
        image: undergroundCavitiesImg,
    },
};
const formatInfoWindowContent = (item, t) => {
    let content = `<b>${t('info_window.underground_cavities.id')} : ${item.cavityId}</b>`;
    if (item.title) {
        content += `<br />${t('info_window.underground_cavities.name')} : ${item.title}`;
    }
    if (item.date) {
        content += `<br />${t('info_window.underground_cavities.date')} : ${item.date}`;
    }
    if (item.type) {
        content += `<br />${t('info_window.underground_cavities.type')} : ${item.type}`;
    }
    if (item.url) {
        content += `<br /><a href="${item.url}" target="_blank" rel="nofollow noreferrer noopener">${t('info_window.underground_cavities.url')}</a>`;
    }
    return content;
};
const useUndergroundCavitiesLayer = () => {
    const { t } = useTranslation();
    const getInfoWindowContent = (marker) => {
        return formatInfoWindowContent(marker, t);
    };
    createLayersHook({
        fetch: fetchUndergroundCavities,
        markerImg: undergroundCavitiesImg,
        legends: LEGENDS,
        getInfoWindowContent,
    })(filtersKey.UNDERGROUND_CAVITIES);
};
const UndergroundCavitiesLayer = () => {
    useUndergroundCavitiesLayer();
    return null;
};
export default UndergroundCavitiesLayer;
