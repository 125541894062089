import * as React from 'react';
import usePlotStatus from 'hooks/crm/usePlotStatus';
import { getPlotStatusOption } from 'utils/personalization';
import StatusSelect from './StatusSelect';
const PlotStatusSelect = ({ plotId, status, onChange, }) => {
    const { changePlotStatus, isUpdating } = usePlotStatus();
    return (<StatusSelect data={getPlotStatusOption()} onChange={(value) => {
            changePlotStatus({
                plotId,
                status: value.value,
            });
            onChange && onChange();
        }} value={status} isLoading={isUpdating}/>);
};
export default PlotStatusSelect;
