import * as React from 'react';
import { memo } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { Link, Stack, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import * as iots from 'io-ts';
import snakeCase from 'lodash/snakeCase';
import { get } from 'api/client';
import Table from 'components/table/Table';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { inDateRange } from 'utils/search';
export const IOCNACTableData = iots.type({
    date: iots.string,
    town: iots.string,
    department: iots.string,
    decision: iots.string,
    retail: iots.string,
    directoryNature: iots.string,
    surface: iots.number,
    title: iots.string,
    urlDecision: iots.string,
});
export const getColumnHeader = (column) => (<Text align="center">
    <Translation>
      {(t) => t(`tabs.extra_tabs.cnac.columns.${column}`)}
    </Translation>
  </Text>);
const columns = [
    {
        id: 'columns',
        columns: [
            {
                accessorKey: 'date',
                header: () => getColumnHeader('date'),
                cell: (info) => info.getValue(),
                filterFn: inDateRange,
                enableGlobalFilter: false,
            },
            {
                accessorKey: 'town',
                header: () => getColumnHeader('town'),
                accessorFn: (row) => {
                    if (!row.town)
                        return t('table.empty_column');
                    return row.town;
                },
                cell: ({ row }) => row.original.town,
                filterFn: 'arrIncludesSome',
            },
            {
                accessorKey: 'decision',
                header: () => getColumnHeader('decision'),
                accessorFn: (row) => {
                    if (!row.decision)
                        return t('table.empty_column');
                    return row.decision;
                },
                cell: ({ row }) => row.original.decision,
                filterFn: 'arrIncludesSome',
            },
            {
                accessorKey: 'retail',
                header: () => getColumnHeader('retail'),
                accessorFn: (row) => {
                    if (!row.retail)
                        return t('table.empty_column');
                    return row.retail;
                },
                cell: ({ row }) => row.original.retail,
                filterFn: 'arrIncludesSome',
            },
            {
                accessorKey: 'directoryNature',
                header: () => getColumnHeader('directory_nature'),
                accessorFn: (row) => {
                    if (!row.directoryNature)
                        return t('table.empty_column');
                    return row.directoryNature;
                },
                cell: ({ row }) => row.original.directoryNature,
                filterFn: 'arrIncludesSome',
            },
            {
                accessorKey: 'surface',
                header: () => getColumnHeader('surface'),
                accessorFn: (row) => {
                    if (!row.surface)
                        return t('table.empty_column');
                    return row.surface;
                },
                cell: ({ row }) => row.original.surface,
                filterFn: 'arrIncludesSome',
            },
            {
                accessorKey: 'title',
                header: () => getColumnHeader('title'),
                accessorFn: (row) => {
                    if (!row.title)
                        return t('table.empty_column');
                    return row.title;
                },
                cell: ({ row }) => row.original.title,
                filterFn: 'arrIncludesSome',
            },
            {
                accessorKey: 'urlDecision',
                header: () => getColumnHeader('url_decision'),
                accessorFn: (row) => {
                    if (!row.urlDecision)
                        return t('table.empty_column');
                    return row.urlDecision;
                },
                cell: ({ row }) => {
                    const url = row.original.urlDecision;
                    return !url ? null : (<Link variant="secondary" isExternal href={url}>
              <Translation>
                {(t) => t('tabs.extra_tabs.cnac.url_decision')}
              </Translation>
            </Link>);
                },
                filterFn: 'arrIncludesSome',
            },
        ],
    },
];
const EXPORTED_FIELDS = [
    'date',
    'town',
    'department',
    'decision',
    'retail',
    'directoryNature',
    'surface',
    'title',
    'urlDecision',
];
const formatExportHeader = (t) => (header) => t(`tabs.extra_tabs.cnac.columns.${snakeCase(header)}`);
const fetchCNAC = (department) => () => get(iots.array(IOCNACTableData), `${department}/cnac`);
const CNACPanel = () => {
    const { t } = useTranslation();
    const selectedTown = useSelectedTown();
    const department = selectedTown?.department;
    const { data, isInitialLoading: isLoading } = useQuery({
        queryKey: ['cnac', department],
        queryFn: fetchCNAC(department),
        enabled: !!department,
    });
    return (<Stack paddingX={4} height="100%">
      <Table variant="striped" id="cnac" columns={columns} data={data || []} isLoading={isLoading} exportTitle={t('tabs.extra_tabs.cnac.title')} exportedFields={EXPORTED_FIELDS} formatExportHeader={formatExportHeader(t)}/>
    </Stack>);
};
export default memo(CNACPanel);
