import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Link } from '@chakra-ui/react';
import { useSize } from '@chakra-ui/react-use-size';
import useAnalytics from 'analytics/useAnalytics';
import { TABS_VIEW_HALF_VERTICAL } from 'context/LayoutContext';
import { useTabsView, useToggleLayout } from 'hooks/contexts/useLayoutContext';
import { useSectionHash } from 'hooks/contexts/useTabsContext';
import TabsSeeMore from './TabsSeeMore';
const PanelNavigation = ({ allowedSections, handleChangeSection, isLoading, trackEventCategory, panelTitle, section = null, }) => {
    const { t } = useTranslation();
    const tabsView = useTabsView();
    const toggleLayout = useToggleLayout();
    const selectedSection = section ?? useSectionHash();
    const { trackEvent } = useAnalytics();
    const selectTab = (isSelected, section) => {
        if (isSelected) {
            toggleLayout();
        }
        trackEvent({
            category: trackEventCategory,
            action: `Onglet ${t(`tabs.${panelTitle}.section.${section}.title`)}`,
        });
        handleChangeSection(section);
    };
    // Push navigation elements in see more menu to have 2 lines max
    const widthAndSlices = [
        { stepWidth: 130, slice: 1 },
        { stepWidth: 170, slice: 2 },
        { stepWidth: 210, slice: 3 },
        { stepWidth: 230, slice: 4 },
        { stepWidth: 290, slice: 5 },
        { stepWidth: 340, slice: 6 },
        { stepWidth: 380, slice: 7 },
        { stepWidth: 430, slice: 8 },
    ];
    const elementRef = React.useRef();
    const dimensions = useSize(elementRef);
    const displayedSections = useMemo(() => {
        let displayedSections = [...allowedSections];
        const width = dimensions?.width;
        if (width === 0) {
            return displayedSections;
        }
        if (tabsView === TABS_VIEW_HALF_VERTICAL) {
            widthAndSlices.every(({ stepWidth, slice }) => {
                if (width < stepWidth) {
                    displayedSections = displayedSections.slice(0, slice);
                    return false;
                }
                return true;
            });
        }
        return displayedSections;
    }, [dimensions?.width, tabsView, allowedSections]);
    const seeMoreSections = allowedSections.slice(displayedSections?.length);
    return (<Box ref={elementRef} width="80%">
      {displayedSections?.map((section) => {
            const isSelected = selectedSection === section;
            return (<Link key={section} as={Button} display="inline" flexShrink={0} _hover={{
                    backgroundColor: 'blue.500',
                    opacity: 0.8,
                    color: 'white',
                    _disabled: {
                        color: 'inherit',
                    },
                }} bgColor={isSelected ? 'colorMode.revertBase' : 'colorMode.base'} color={isSelected ? 'colorMode.base' : 'colorMode.font800'} paddingX={2} height="32px" alignItems="center" fontWeight={700} textStyle="paragraph" borderRadius="none" onClick={() => selectTab(isSelected, section)} disabled={isLoading} data-cy={`${panelTitle}_section_${section}${isSelected ? '_selected' : ''}`}>
            {t(`tabs.${panelTitle}.section.${section}.title`)}
          </Link>);
        })}
      {!!seeMoreSections?.length && (<TabsSeeMore sections={seeMoreSections} handleClick={(isSelected, section) => selectTab(isSelected, section)} disabled={isLoading} data-cy={`${panelTitle}_tabs_see_more`} panelTitle={panelTitle}/>)}
    </Box>);
};
export default PanelNavigation;
