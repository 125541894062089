import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOFloodingRisksLayerItem = IOLayerItem;
const fetchFloodingRisks = (townId) => () => get(iots.array(IOFloodingRisksLayerItem), `legacy/${townId}/flooding-risks`);
const useFloodingRisksLayer = () => {
    createLayersHook({
        fetch: fetchFloodingRisks,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.FLOODING_RISKS);
};
const FloodingRisksLayer = () => {
    useFloodingRisksLayer();
    return null;
};
export default FloodingRisksLayer;
