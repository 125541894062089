import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { LOGIN_URL } from 'app';
import { get, post } from 'api/client';
import useCustomToast from './useCustomToast';
const getResetPasswordData = (id, token) => get(null, `legacy/reset-password/${id}/${token}`);
const resetPassword = (id, token) => (data) => post(null, `legacy/reset-password/${id}/${token}`, {
    password: data.password,
    confirmPassword: data.confirmPassword,
});
const useResetPassword = (successCallback = null) => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');
    const token = searchParams.get('token');
    const { getErrorToast, getSuccessToast } = useCustomToast();
    const navigate = useNavigate();
    const onErrorCallback = (error) => {
        getErrorToast({
            title: t('reset_password.error'),
            description: error.response?.data.detail,
        });
    };
    const onSuccessCallback = () => {
        getSuccessToast({
            title: t('reset_password.success'),
            id: 'password_reset_success',
        });
        successCallback && successCallback();
        navigate(LOGIN_URL);
    };
    const { data, isInitialLoading: isLoading } = useQuery({
        queryKey: ['getResetPasswordData', { id, token }],
        queryFn: () => getResetPasswordData(id, token),
        enabled: !!id && !!token,
        staleTime: Infinity,
    });
    const { mutate } = useMutation({
        mutationFn: resetPassword(id, token),
        onError: (err) => onErrorCallback(err),
        onSuccess: () => onSuccessCallback(),
    });
    return { data, isLoading, resetPassword: mutate };
};
export default useResetPassword;
