import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IORegionalPlanWaterAreaLayerItem = IOLayerItem;
const fetchRegionalPlanWaterArea = (townId) => () => get(iots.array(IORegionalPlanWaterAreaLayerItem), `legacy/${townId}/regional-plan-water-area`);
const useRegionalPlanWaterAreaLayer = () => {
    createLayersHook({
        fetch: fetchRegionalPlanWaterArea,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.REGIONAL_PLAN_WATER_AREA);
};
const RegionalPlanWaterAreaLayer = () => {
    useRegionalPlanWaterAreaLayer();
    return null;
};
export default RegionalPlanWaterAreaLayer;
