import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
export const IODeathTableData = iots.type({
    dateDeath: iots.string,
    name: iots.string,
    firstname: iots.string,
    sex: iots.number,
    residenceTown: iots.string,
    age: iots.number,
});
const QUERY_KEY = 'death';
const QUERY_SEARCH_KEY = 'death-search';
const fetchDeath = (townId) => () => get(iots.array(IODeathTableData), `${townId}/death`);
const fetchSearchDeath = (name, townId) => () => get(iots.array(IODeathTableData), `death/search`, { name, townId });
const useDeath = (townId, contact) => {
    const { data: deathTown, isInitialLoading: isLoadingDeathTown } = useQuery({
        queryKey: [QUERY_KEY, { townId }],
        queryFn: fetchDeath(townId),
        enabled: !!townId,
    });
    const { data: deathContact, isInitialLoading: isLoadingDeathContact } = useQuery({
        queryKey: [
            QUERY_SEARCH_KEY,
            { name: contact?.lastname, townId: contact?.townId },
        ],
        queryFn: fetchSearchDeath(contact?.lastname, contact?.townId),
        enabled: !!contact,
    });
    return {
        deathTown,
        isLoadingDeathTown,
        deathContact,
        isLoadingDeathContact,
    };
};
export default useDeath;
