import * as React from 'react';
import { useCallback, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
const IOZAELayerItem = IOLayerItem;
const IOZAEInfo = iots.intersection([
    IOLayerItem,
    iots.partial({
        name: iots.string,
        town: iots.string,
        area: iots.string,
    }),
]);
const formatInfoWindowContent = (item, t) => {
    const content = (<Box>
      <p>
        <b>{`${t('info_window.zae.name')} : ${item?.name}`}</b>
      </p>
      <p>{`${t('info_window.zae.town')} : ${item?.town}`}</p>
      <p>{`${t('info_window.zae.area')} : ${item?.area}`}</p>
    </Box>);
    return ReactDOMServer.renderToStaticMarkup(content);
};
const fetchZae = (townId) => () => get(iots.array(IOZAELayerItem), `legacy/${townId}/zae`);
const useZAELayer = () => {
    const selectedTown = useSelectedTown();
    const { t } = useTranslation();
    const [itemInfo, setItemInfo] = useState({});
    const getInfoWindowContent = useCallback((item) => {
        const content = formatInfoWindowContent(item, t);
        setItemInfo({
            ...itemInfo,
            [item.id]: content,
        });
        return content;
    }, [selectedTown]);
    createLayersHook({
        fetch: fetchZae,
        getInfoWindowContent,
    })(filtersKey.ZAE);
};
const ZAELayer = () => {
    useZAELayer();
    return null;
};
export default ZAELayer;
