import * as React from 'react';
import { Button, Text } from '@chakra-ui/react';
import { useMap } from 'hooks/contexts/useMapContext';
import { PLOT_MIN_ZOOM } from 'utils/map';
const BuildingPermitPermitNumberButton = ({ data }) => {
    const map = useMap();
    const { lat, lng, buildingPermitId } = data;
    const handleClick = () => {
        const center = new google.maps.LatLng(parseFloat(lat), parseFloat(lng));
        // We change zoom and center on marker
        if (map.getZoom() < PLOT_MIN_ZOOM) {
            map.setZoom(PLOT_MIN_ZOOM);
        }
        map.setCenter(center);
    };
    if (!buildingPermitId) {
        return null;
    }
    return (<Text align="center">
      <Button size="xs" variant="link-secondary" onClick={handleClick}>
        {buildingPermitId}
      </Button>
    </Text>);
};
export default BuildingPermitPermitNumberButton;
