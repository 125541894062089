import { filtersKey } from 'utils/filters';
export const CST_POSITIONS = {
    [filtersKey.OLD_CADASTRE]: 1,
    [filtersKey.PLU_GPU]: 2,
    PLU: 3,
    LABELS: 4,
    [filtersKey.THEMATIC_MAPS]: 5,
    [filtersKey.PLOTS]: 7,
    [filtersKey.TOWNS]: 8,
    [filtersKey.EPCI]: 10,
    [filtersKey.NEIGHBORHOODS]: 12,
    [filtersKey.TOWNS_LIMITS]: 13,
    [filtersKey.SCOT]: 14,
    ABF_FILTERS: 15,
    [filtersKey.PPR_EARTHQUAKE_HAZARD]: 16,
    [filtersKey.TERMITES]: 17,
    ROAD_RAILWAY_FILTERS: 18,
    [filtersKey.WAREHOUSES_AND_DENSE_LOGISTICS_AREAS]: 20,
    RAILWAY_NETWORK_FILTERS: 22,
    COMMERCIAL_BUILDINGS_FILTERS: 23,
    [filtersKey.HAZMAT_TRANSPORT_PIPELINES]: 24,
    [filtersKey.RADIOACTIVE_GAS]: 25,
    [filtersKey.THEMATIC_MAP_RENT_APARTMENT]: 26,
    [filtersKey.THEMATIC_MAP_RENT_HOUSE]: 27,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2013]: 28,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2014]: 29,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2015]: 30,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2016]: 31,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2017]: 32,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2018]: 33,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2019]: 34,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2020]: 35,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2021]: 36,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2022]: 37,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2023]: 38,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_LAST]: 50,
    [filtersKey.WIND_TOWER]: 51,
    [filtersKey.WIND_POTENTIAL]: 52,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_ABANDONED_MOTORWAYS]: 53,
    [filtersKey.AVERAGE_SOLAR_IRRADIATION]: 55,
    [filtersKey.BIOTOP_PROTECTION]: 56,
    [filtersKey.BIOLOGICAL_RESERVE]: 57,
    [filtersKey.HUNTING_RESERVE]: 58,
    [filtersKey.NATIONAL_RESERVE]: 59,
    [filtersKey.NATURA_2000_SIC]: 60,
    [filtersKey.NATURA_2000_ZPS]: 61,
    [filtersKey.NATIONAL_PARK]: 62,
    [filtersKey.MARIN_PARK]: 63,
    [filtersKey.COASTLINE_CONSERVATOIRE]: 64,
    [filtersKey.REGIONAL_NATURAL_SPACE_CONSERVATOIRE]: 65,
    [filtersKey.BIOSPHERE_RESERVE]: 66,
    [filtersKey.REGIONAL_RESERVE]: 67,
    [filtersKey.REGIONAL_PARK]: 68,
    [filtersKey.ZNIEFF_TYPE1]: 69,
    [filtersKey.ZNIEFF_TYPE2]: 70,
    [filtersKey.PUBLIC_FOREST]: 71,
    [filtersKey.COMPENSATORY_MEASURES_FOR_BIODIVERSITY_DAMAGE]: 72,
    [filtersKey.LEVEL_CURVE]: 73,
    [filtersKey.INCLINE_10]: 74,
    [filtersKey.INCLINE_30]: 75,
    [filtersKey.WIND_POTENTIAL_140]: 76,
    [filtersKey.WIND_POTENTIAL_160]: 77,
    [filtersKey.WIND_CONSTRAINT_ROAD]: 78,
    [filtersKey.WIND_CONSTRAINT_INCLINE]: 79,
    [filtersKey.ELECTRICAL_NETWORKS_HTB_AERIAL]: 80,
    [filtersKey.ELECTRICAL_NETWORKS_HTB_UNDERGROUND]: 81,
    [filtersKey.ELECTRICAL_NETWORKS_ELECTRICITY_PYLON]: 82,
    [filtersKey.RADAR]: 83,
    [filtersKey.WETLAND_AREA_POTENTIALLY]: 84,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_LAND_UNIT_AREA_PARKING_SPACE_500]: 85,
    [filtersKey.WIND_LARGE_FRENCH_SITE]: 86,
    [filtersKey.WIND_ZAER_EXCLUSION]: 87,
    [filtersKey.WIND_CONSTRAINT_HOUSING]: 88,
    [filtersKey.WIND_CONSTRAINT_RAILWAY]: 89,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_PRAIRIE]: 90,
    [filtersKey.ELECTRICAL_NETWORKS_CAPARESEAU]: 91,
    ZAER_FILTERS: 92,
    WIND_BIRD_FILTERS: 93,
    AERONAUTIC_FILTERS: 94,
    [filtersKey.WIND_UNESCO_NATURAL]: 95,
    [filtersKey.SRCE]: 96,
    [filtersKey.DRINKING_WATER_CAPTURE]: 97,
    [filtersKey.AERONAUTIC_SERVITUDE]: 98,
    [filtersKey.AERONAUTIC_SERVITUDE + '_2']: 99,
    // Position 100 - 160 réservé aux PLU adjacents
    [filtersKey.OPTICAL_FIBER]: 161,
    [filtersKey.OPTICAL_FIBER_CENTER]: 162,
    [filtersKey.WIND_POTENTIAL_50]: 163,
    [filtersKey.WIND_POTENTIAL_100]: 164,
    [filtersKey.PARKING_SPACE_500_750]: 165,
    [filtersKey.PARKING_SPACE_750_1000]: 166,
    [filtersKey.PARKING_SPACE_1000_1500]: 167,
    [filtersKey.PARKING_SPACE_1500_5000]: 168,
    [filtersKey.PARKING_SPACE_5000_sup]: 169,
    [filtersKey.OLD_MARKET_DVF]: 170,
};
// Certain filtre sont regroupés (ZAER_FILTERS, WIND_BIRD_FILTERS, AERONAUTIC_FILTERS)
export const LAYERS_WITH_OPACITY = {
    ...CST_POSITIONS,
    [filtersKey.OPTICAL_FIBER]: false,
    [filtersKey.ABF_NO_BUILDING_ZONE]: 15,
    [filtersKey.ABF_STRONGLY_REGULATED_AREA]: 15,
    [filtersKey.ABF_ADVICE_REQUIRED_IF_VISIBLE]: 15,
    [filtersKey.ABF_ADVICE_REQUIRED]: 15,
    [filtersKey.ABF_REGIONAL_PREFECT_ADVICE_REQUIRED]: 15,
    [filtersKey.ROAD_NOISE]: 18,
    [filtersKey.RAILWAY_NOISE]: 18,
    [filtersKey.RAILWAY_NETWORKS_FRANCE_RAILWAY_NETWORK]: 22,
    [filtersKey.RAILWAY_NETWORKS_IDF_RAILWAY_NETWORK]: 22,
    [filtersKey.COMMERCIAL_LINEAR]: 23,
    [filtersKey.URBAN_INTENSITY_PLACES]: 23,
    [filtersKey.ZAER_SOLAR_PV_GROUND]: 92,
    [filtersKey.ZAER_SOLAR_PV_ROOF]: 92,
    [filtersKey.ZAER_SOLAR_PV_SHADOW_PARKING]: 92,
    [filtersKey.ZAER_SOLAR_THERMIC]: 92,
    [filtersKey.ZAER_WIND]: 92,
    [filtersKey.ZAER_GEOTHERMAL]: 92,
    [filtersKey.ZAER_BIOMETHANE]: 92,
    [filtersKey.ZAER_BIOMASS]: 92,
    [filtersKey.ZAER_HYDROELECTRICITY]: 92,
    [filtersKey.WIND_BIRD_AVIFAUNA]: 93,
    [filtersKey.WIND_BIRD_BAT]: 93,
    [filtersKey.AERONAUTIC_ZONE_CRT]: 94,
    [filtersKey.AERONAUTIC_ZONE_P]: 94,
    [filtersKey.AERONAUTIC_ZONE_ZMT]: 94,
};
