import { memo } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Heading, Stack, useDisclosure, } from '@chakra-ui/react';
import ImportPublipostageForm from './ImportPublipostageForm';
const PublipostageDrawer = ({ handleClose, contactsIds, triggerItem, }) => {
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const closeDrawer = () => {
        if (handleClose)
            handleClose();
        onClose();
    };
    return (<>
      {triggerItem && triggerItem(onOpen)}
      <Drawer isOpen={isOpen} onClose={() => closeDrawer()} size="md">
        <DrawerOverlay />
        <DrawerContent backgroundColor="colorMode.background900" overflowY="auto">
          <DrawerCloseButton />
          <DrawerHeader>
            <Heading as="h4" variant="h4">
              {t(`contact.import_publipostage`, {
            count: contactsIds?.length ?? 0,
        })}
            </Heading>
          </DrawerHeader>
          <DrawerBody paddingY={1}>
            <Stack width="full" spacing={2}>
              <ImportPublipostageForm closeDrawer={closeDrawer} contactsIds={contactsIds}/>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>);
};
export default memo(PublipostageDrawer);
