import { useEffect, useState } from 'react';
import { debounce } from 'lodash';
export function useOverflow(ref) {
    const [refXOverflowing, setRefXOverflowing] = useState(false);
    const [refXScrollBegin, setRefXScrollBegin] = useState(true);
    const [refXScrollEnd, setRefXScrollEnd] = useState(false);
    const [dimensions, setDimensions] = useState(null);
    const resizeObserver = new ResizeObserver(([entry]) => {
        setDimensions(entry.contentRect);
    });
    useEffect(() => {
        const current = ref.current;
        if (current) {
            resizeObserver.observe(current);
            return () => resizeObserver.unobserve(current);
        }
    }, [ref.current]);
    useEffect(() => {
        if (!ref?.current) {
            return;
        }
        const isXOverflowing = ref.current.scrollWidth > ref.current.clientWidth;
        if (refXOverflowing !== isXOverflowing) {
            setRefXOverflowing(isXOverflowing);
        }
        const handleScroll = debounce(() => {
            const offsetRight = ref?.current?.scrollWidth -
                ref?.current?.scrollLeft -
                ref?.current?.clientWidth;
            if (offsetRight < 1 && refXScrollEnd === false) {
                setRefXScrollEnd(true);
            }
            else {
                setRefXScrollEnd(false);
            }
            if (ref?.current?.scrollLeft === 0) {
                setRefXScrollBegin(true);
            }
            else {
                setRefXScrollBegin(false);
            }
        }, 200);
        window.addEventListener('resize', handleScroll);
        ref.current.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('resize', handleScroll);
            ref.current?.removeEventListener('scroll', handleScroll);
        };
    }, [ref, dimensions]);
    return {
        refXOverflowing,
        refXScrollBegin,
        refXScrollEnd,
    };
}
