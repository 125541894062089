import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Divider, Heading, HStack, Switch, Text, VStack, } from '@chakra-ui/react';
import DeleteButton from 'components/buttons/DeleteButton';
import { accordionBoxShadow } from 'utils/accordionArrowStyle';
const SavedFiltersList = ({ savedFilters, appliedFilterId, handleDelete, handleToggle, canSaveFilters = true, title, }) => {
    const { t } = useTranslation();
    return (<>
      {canSaveFilters && !!savedFilters && savedFilters.length > 0 && (<>
          <Accordion width="full" allowToggle marginTop={0} id="save_filters_list">
            <AccordionItem border={0}>
              <Heading boxShadow={accordionBoxShadow}>
                <AccordionButton width="full" alignItems="center" height="42px" data-cy="saved_filters_list">
                  <Box flex="1">
                    <Text textStyle="paragraph" fontWeight={700} textAlign="left">
                      {title ?? t('sidebar.search_plots.saved_filters')}
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </Heading>
              <AccordionPanel padding={2} maxHeight={40} overflowY="scroll">
                {!!savedFilters &&
                savedFilters.map((savedFilter, index) => (<VStack key={savedFilter.id}>
                      {index !== 0 && <Divider />}
                      <HStack flex={1} width="full" paddingLeft={2} paddingY={2} data-cy={savedFilter?.name?.split(' ').join('_') + '_filter'}>
                        <Box flex={1}>
                          <Text textStyle="paragraph" data-cy="saved_filter_name">
                            {savedFilter.name}
                          </Text>
                        </Box>
                        <Switch data-cy={savedFilter.name.split(' ').join('_') + '_switch'} isChecked={appliedFilterId === savedFilter.id} onChange={() => handleToggle(savedFilter.id)}/>
                        <DeleteButton id={savedFilter.name.split(' ').join('_') + '_delete'} mode="icon" confirmText={t('sidebar.search_plots.delete_filter_modal.content', {
                        filter: savedFilter?.name,
                    })} handleDelete={() => {
                        handleDelete(savedFilter?.id);
                    }} size="sm"/>
                      </HStack>
                    </VStack>))}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </>)}
    </>);
};
export default SavedFiltersList;
