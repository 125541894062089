import * as React from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, Center, FormControl, FormErrorMessage, HStack, Icon, Input, Stack, Text, VStack, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_CRM } from 'analytics/useAnalytics';
import { getDocumentAndOpen } from 'api/client';
import DeleteButton from 'components/buttons/DeleteButton';
import { useSectionHash } from 'hooks/contexts/useTabsContext';
import DownloadIcon from 'images/icons/Download';
import FileIcon from 'images/icons/File';
import PwdIcon from 'images/icons/Pwd';
const ACCEPTED_FILE_FORMATS = '.txt,.doc,.docx,.ppt,.pptx,.pdf,.xls,.xlsx';
const validateForm = (values) => {
    const errors = {};
    if (values?.file[0]?.size > 31457280) {
        errors.file = {
            type: 'required',
            message: 'tabs.document.errors.file_size',
        };
    }
    return errors;
};
export const resolver = (values) => {
    return {
        values,
        errors: validateForm(values),
    };
};
const DocumentList = ({ projectId, plotId, documents, addDocument, deleteDocument, isUserOwner = true, }) => {
    const sectionHash = useSectionHash();
    const { trackEvent } = useAnalytics();
    const { t } = useTranslation();
    const defaultValues = { plotId: plotId, projectId: projectId, file: null };
    const { register, handleSubmit, formState: { errors }, watch, } = useForm({
        mode: 'onChange',
        defaultValues: defaultValues,
        resolver,
    });
    const onSubmit = (data) => addDocument(data);
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (name === 'file' && type === 'change') {
                return handleSubmit(onSubmit)();
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);
    return (<Stack width="full" spacing={3} data-cy="document_list">
      {isUserOwner ? (<Box as="form">
          <FormControl isInvalid={!!errors?.file}>
            <Box borderRadius="6px" border="1px dashed" borderColor={!errors?.file ? 'colorMode.font700' : 'red.500'} _hover={{ borderColor: 'colorMode.font900' }}>
              <Input {...register('file')} type="file" height="100px" accept={ACCEPTED_FILE_FORMATS} opacity="0" cursor="pointer" position="absolute" data-cy="plot_crm_document_upload"/>

              <VStack justifyContent="center" alignItems="center" padding={6}>
                <Center backgroundColor="colorMode.base" width="40px" height="40px" borderRadius="32px">
                  <Icon as={DownloadIcon} width={5} height={5} fill="currentColor" alignSelf="center"/>
                </Center>

                <Text textStyle="paragraph" color="colorMode.font700" fontWeight={500} onClick={() => {
                trackEvent({
                    category: ANALYTICS_CRM,
                    action: 'Télécharger un document' + sectionHash === 'my_crm'
                        ? ''
                        : ' fiche projet',
                });
            }}>
                  <Text as="span" color="red.500" marginRight={1}>
                    {t('tabs.document.download_file')}
                  </Text>
                  {t('tabs.document.or_dnd_files')}
                </Text>
                <FormErrorMessage>{t(errors?.file?.message)}</FormErrorMessage>
              </VStack>
            </Box>
          </FormControl>
        </Box>) : (documents?.length === 0 && (<Box textAlign="center">{t('global.no_document')}</Box>))}

      {documents?.length > 0 && (<VStack>
          {documents.map((document) => {
                return (<HStack key={document.id} width="full" justifyContent="space-between" backgroundColor="colorMode.base" borderRadius="md" padding={4} marginTop={2} data-cy="plot_crm_document_file">
                <HStack alignItems="center">
                  <Icon as={FileIcon} fill="red.500"/>
                  <Text textStyle="paragraph" fontWeight={500} color="colorMode.font900" data-cy="document_plot_name">
                    {document.name}
                  </Text>
                  {projectId && document.plotId != 0 && document.plotNumber && (<Text textStyle="paragraph" fontWeight={500} color="colorMode.font900">
                      ({document.plotNumber})
                    </Text>)}
                </HStack>
                <HStack>
                  <Button size="sm" color="colorMode.font800" leftIcon={<PwdIcon fill="currentColor"/>} onClick={() => getDocumentAndOpen(document.url, document.name)}>
                    {t('tabs.document.view_btn')}
                  </Button>
                  <DeleteButton variant="link" id="plot_document_delete" confirmText={document.plotId
                        ? t('tabs.document.delete_plot_confirm')
                        : t('tabs.document.delete_confirm')} handleDelete={() => {
                        deleteDocument({ document: document });
                        trackEvent({
                            category: ANALYTICS_CRM,
                            action: 'Supprimer un document' + sectionHash === 'my_crm'
                                ? ''
                                : ' fiche projet',
                        });
                    }} size="sm" color="blue.500" showIcon fontWeight="bold"/>
                </HStack>
              </HStack>);
            })}
        </VStack>)}
    </Stack>);
};
export default DocumentList;
