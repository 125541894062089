import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOCoastalProtectionLayerItem = IOLayerItem;
const fetchCoastalProtection = (townId) => () => get(iots.array(IOCoastalProtectionLayerItem), `legacy/${townId}/coastal-protection`);
const useCoastalProtectionLayer = () => {
    createLayersHook({
        fetch: fetchCoastalProtection,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.COASTAL_PROTECTION);
};
const CoastalProtectionLayer = () => {
    useCoastalProtectionLayer();
    return null;
};
export default CoastalProtectionLayer;
