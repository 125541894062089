import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Center, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Spinner, Text, useDisclosure, } from '@chakra-ui/react';
import MapContainer from 'components/mapModal/MapContainer';
import ProjectPanel from 'components/tabs/crmTabs/project/ProjectPanel';
import { useUserData } from 'hooks/contexts/useAuthContext';
import { useToggleFilter } from 'hooks/contexts/useFiltersContext';
import useLandPoint from 'hooks/useLandPoint';
import { filtersKey } from 'utils/filters';
import { centerAndZoomOnCoord } from 'utils/map';
import LandPointProjects from './landPointModal/LandPointProjects';
import LandPointReport from './landPointModal/LandPointReport';
const LandPointModal = ({ triggerItem, landPoint, }) => {
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toggleFilter = useToggleFilter();
    const [map, setMap] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null);
    const userData = useUserData();
    const { encloseLandPoint } = useLandPoint({ landPointId: landPoint.id });
    const isManager = userData.email === landPoint.manager;
    const enclose = () => {
        encloseLandPoint({ landPointId: landPoint.id });
        onClose();
        setSelectedProject(null);
        toggleFilter(filtersKey.PROJECT_PLOTS, false);
        toggleFilter(filtersKey.MAP_PLOTS, false);
    };
    const clickOnClose = () => {
        setSelectedProject(null);
        toggleFilter(filtersKey.PROJECT_PLOTS, false);
        toggleFilter(filtersKey.MAP_PLOTS, false);
    };
    useEffect(() => {
        if (!selectedProject) {
            return;
        }
        const latLng = new google.maps.LatLng(parseFloat(selectedProject?.lat), parseFloat(selectedProject?.lng));
        centerAndZoomOnCoord(map, latLng, 1);
        toggleFilter(filtersKey.PROJECT_PLOTS, true);
        toggleFilter(filtersKey.MAP_PLOTS, true);
    }, [selectedProject]);
    return (<>
      {triggerItem(onOpen)}
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalContent height="100%" overflow="hidden">
          <ModalHeader flex="none" textAlign={landPoint.closedAt ? 'center' : 'right'} paddingY={2} paddingRight="55px" backgroundColor={landPoint.closedAt ? 'red.500' : ''} fontSize={landPoint.closedAt ? '14px' : 'xl'}>
            {landPoint.closedAt ? (<Text as="span" color="white" fontWeight="bold">
                {t('land_point.enclose')}
              </Text>) : (isManager && (<Button variant="ghostBorder" size="sm" onClick={enclose}>
                  {t('land_point.button_enclose')}
                </Button>))}
          </ModalHeader>
          <ModalCloseButton top={landPoint.closedAt ? 0 : 2} color={landPoint.closedAt ? 'white' : 'currentcolor'} onClick={clickOnClose}/>
          <ModalBody padding={0} height="calc(100% - 56px)">
            <HStack height="100%" spacing={0} alignItems="flex-start">
              <Box height="100%" width="25%" borderRight="1px solid #CACACA">
                <LandPointProjects landPoint={landPoint} project={selectedProject} setSelectedProject={setSelectedProject}/>
              </Box>
              <Box height="100%" width="75%">
                <LandPointReport projectId={selectedProject?.id} landPoint={landPoint}/>
                <HStack height="75%" spacing={0} alignItems="flex-start">
                  <Box width="66.66%" height="100%" borderRight="1px solid #CACACA">
                    {!selectedProject ? (<Center display="flex" width="full" height="100%" alignItems="center" justifyContent="center">
                        <Spinner />
                      </Center>) : (<ProjectPanel projectId={selectedProject?.id} projectTownId={selectedProject?.townId} landPointId={landPoint.id}/>)}
                  </Box>
                  <Box width="33.34%" height="100%">
                    <MapContainer selectedProject={selectedProject} map={map} setMap={setMap}/>
                  </Box>
                </HStack>
              </Box>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>);
};
export default LandPointModal;
