import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getLayerCoordTileUrl } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = () => (coord, zoom) => {
    return getLayerCoordTileUrl('PROTECTEDAREAS.PNR', 'PROTECTEDAREAS.PNR', zoom, coord);
};
const getLegends = () => {
    const legends = {};
    legends.regional_park = {
        layerKey: filtersKey.REGIONAL_PARK,
        label: 'regional_park',
        needsTranslate: true,
        color: '#B0C558',
        opacity: 1,
    };
    return legends;
};
const useRegionalParkLayer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.REGIONAL_PARK],
        getLegends,
    })(filtersKey.REGIONAL_PARK);
};
const RegionalParkLayer = () => {
    useRegionalParkLayer();
    return null;
};
export default RegionalParkLayer;
