import { useQuery } from '@tanstack/react-query';
import { get } from 'api/client';
export const QUERY_KEY = 'plot-projects';
export const getPlotProjectsQueryKey = (plotId) => [QUERY_KEY, plotId];
const fetchPlotProjects = (plotId) => () => get(null, `legacy/${plotId}/projects`);
const usePlotProjectsList = (plotId) => {
    const { data: plotProjects, isInitialLoading: isLoading } = useQuery({
        queryKey: getPlotProjectsQueryKey(plotId),
        queryFn: fetchPlotProjects(plotId),
        enabled: !!plotId,
    });
    return { plotProjects, isLoading };
};
export default usePlotProjectsList;
