import * as React from 'react';
import { memo, useEffect } from 'react';
import { Box, HStack, VStack } from '@chakra-ui/react';
import { ANALYTICS_MAP_BUTTONS } from 'analytics/useAnalytics';
import { STREET_VIEW_MODE_FULL } from 'context/LayoutContext';
import { useStreetViewMode } from 'hooks/contexts/useLayoutContext';
import { useMap } from 'hooks/contexts/useMapContext';
import useCenterCross from 'hooks/map/useCenterCross';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import LayersButtons from './LayersButtons';
import MeasureTool from './MeasureTool';
import OtherTool from './OtherTool';
import TownPlotCenterButton from './TownPlotCenterButton';
import ZoomControl from './ZoomControl';
const ToolbarWrapper = () => {
    const streetViewMode = useStreetViewMode();
    const map = useMap();
    const toggleCenterCross = useCenterCross();
    const { hasMapControl } = useSiteConfiguration();
    useEffect(() => {
        // clean center cross when unmouting component
        return () => toggleCenterCross(false);
    }, []);
    return (<Box className="map-toolbar-container" ref={(el) => {
            map?.controls[google.maps.ControlPosition.RIGHT_TOP].push(el);
        }}>
      {streetViewMode !== STREET_VIEW_MODE_FULL && (<>
          <Box className="no-print map-toolbar">
            <LayersButtons hasMapControl={hasMapControl}/>
          </Box>
          <VStack alignItems="end" className="no-print map-toolbar map-toolbar-tools">
            {(hasMapControl('measure') || hasMapControl('drawing')) && (<MeasureTool />)}
            <OtherTool trackerPosition={ANALYTICS_MAP_BUTTONS}/>
            <HStack width="100%" spacing="52px" justifyContent="flex-end" alignItems="flex-start">
              <TownPlotCenterButton />
              <ZoomControl />
            </HStack>
          </VStack>
        </>)}
    </Box>);
};
export default memo(ToolbarWrapper);
