import * as React from 'react';
import { useMemo } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { Box, Button, Heading, HStack, MenuButton, Stack, Text, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_CRM } from 'analytics/useAnalytics';
import ContactButton from 'components/buttons/ContactButton';
import DeleteButton from 'components/buttons/DeleteButton';
import EditButton from 'components/buttons/EditButton';
import ModalButton from 'components/buttons/ModalButton';
import PlotButton from 'components/buttons/PlotButton';
import Table from 'components/table/Table';
import ActivityButton from 'components/tabs/activities/ActivityButton';
import ActivityDrawer from 'components/tabs/activities/ActivityDrawer';
import ActionStatus from 'components/tabs/activities/ActivityTable/ActionStatus';
import ActivityStatusInput from 'components/tabs/activities/ActivityTable/ActivityStatusInput';
import { useOpenPlotModal } from 'hooks/contexts/useModalContext';
import useActivity from 'hooks/useActivity';
import { IOActivity, IOContactActivity, IOPlotActivity, } from 'types/api';
import { AGENDA_ACTIVITY_TYPE_EVENT, getActivityStatusLabel, getActivityStatusLabelFromDelay, getAgendaActivityTypeFromCode, } from 'utils/constants';
import { formatDate } from 'utils/formatDate';
const getColumnHeader = (column) => (<Translation>
    {(t) => (<Text align="center">{t(`tabs.activities.columns.${column}`)}</Text>)}
  </Translation>);
const ActivityList = ({ title, activities, isLoading, activityInfoDefault, analyticsCategory, analyticsDetail, landPointId, isUserOwner = true, }) => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const [activityInfo, setActivityInfo] = React.useState();
    const openPlotModal = useOpenPlotModal();
    const { deleteActivity, updateActivity, isDeleting, isUpdating } = useActivity({
        plotId: activityInfoDefault?.plotId,
        contactId: activityInfoDefault?.contactId,
        projectId: activityInfoDefault?.projectId,
        handleSuccess: () => setActivityInfo(undefined),
    });
    const columns = useMemo(() => {
        return [
            {
                id: 'columns',
                columns: [
                    {
                        accessorKey: 'date',
                        header: () => getColumnHeader('date'),
                        cell: (info) => formatDate(info.getValue(), 'short'),
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'title',
                        header: () => getColumnHeader('title'),
                        cell: ({ getValue }) => getValue(),
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'comment',
                        header: () => getColumnHeader('comment'),
                        cell: (info) => info.getValue(),
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        id: 'contact',
                        accessorFn: (row) => {
                            return row.contact?.firstname + ' ' + row.contact?.lastname;
                        },
                        header: () => getColumnHeader('contact'),
                        cell: (info) => {
                            const contact = info.row.original.contact;
                            if (contact !== null && contact !== undefined) {
                                return (<ContactButton contactId={contact.id} size="sm" height="auto" padding={0} verticalAlign="baseline"/>);
                            }
                            return;
                        },
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'plotId',
                        header: () => getColumnHeader('plot_id'),
                        cell: (info) => {
                            const userPlot = info.row.original;
                            if (userPlot !== null) {
                                return (<Box textAlign="center">
                    {!landPointId ? (<PlotButton lat={userPlot.plotLat} lng={userPlot.plotLng} townId={userPlot.townId} variant="link-primary" plotId={userPlot.plotId} analyticOrigin={ANALYTICS_CRM} analyticDetail={analyticsDetail}>
                        {userPlot.cadastreId}
                      </PlotButton>) : (<ModalButton size="sm" onClick={() => {
                                            openPlotModal({
                                                townshipId: userPlot.townId,
                                                plotId: userPlot.id,
                                            });
                                        }}>
                        {userPlot.cadastreId}
                      </ModalButton>)}
                  </Box>);
                            }
                            return <Text>{t('tabs.activities.no_plot')}</Text>;
                        },
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'agendaType',
                        accessorFn: (row) => t(getAgendaActivityTypeFromCode(row.agendaType)),
                        header: () => getColumnHeader('agendaType'),
                        cell: (info) => info.getValue(),
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'location',
                        header: () => getColumnHeader('location'),
                        cell: (info) => info.getValue(),
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        id: 'delay',
                        accessorFn: (row) => getActivityStatusLabelFromDelay(row.delay),
                        header: () => t('activity.fields.common.delay.label'),
                        cell: (info) => (<Box textAlign="center">
                <ActionStatus label={info.getValue()} disabled={isDeleting || isUpdating}/>
              </Box>),
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        id: 'status',
                        header: () => getColumnHeader('status'),
                        accessorFn: (row) => row.agendaType !== AGENDA_ACTIVITY_TYPE_EVENT
                            ? t(getActivityStatusLabel(row.status))
                            : t(getActivityStatusLabel(0)),
                        cell: ({ row }) => {
                            if (isUserOwner) {
                                if (row.original.agendaType !== AGENDA_ACTIVITY_TYPE_EVENT) {
                                    return (<ActivityStatusInput activity={row.original} updateActivity={updateActivity} isLoading={isUpdating || isDeleting}/>);
                                }
                            }
                            else {
                                return t(getActivityStatusLabel(row.original.status));
                            }
                        },
                        filterFn: 'arrIncludesSome',
                        meta: {
                            minW: '130px',
                        },
                    },
                    {
                        accessorKey: 'id',
                        header: () => '',
                        cell: (info) => {
                            const disabled = info.row.original.status === 1 || isUpdating || isDeleting;
                            return (<HStack justifyContent="center">
                  <DeleteButton mode="icon" handleDelete={() => deleteActivity({
                                    activityId: info.row.original.id,
                                }, {
                                    onSuccess: () => {
                                        trackEvent({
                                            category: ANALYTICS_CRM,
                                            action: 'Supprimer une activité',
                                        });
                                    },
                                })} disabled={isDeleting || isUpdating}/>
                  <EditButton mode="icon" handleEdit={() => setActivityInfo(info.row.original)} disabled={disabled}/>
                </HStack>);
                        },
                        enableGlobalFilter: false,
                        enableColumnFilter: false,
                        enableSorting: false,
                    },
                ],
            },
        ];
    }, []);
    let initialColumnFilters;
    if (activities && !IOContactActivity.is(activities[0])) {
        initialColumnFilters = [
            {
                id: 'status',
                value: [t(getActivityStatusLabel(0))],
            },
        ];
    }
    return (<Stack width="full" spacing={3}>
      <HStack>
        <Heading as="h4" variant="h4">
          {t(title)}
        </Heading>
        {isUserOwner && (<ActivityButton activityInfoDefault={activityInfoDefault} analyticsCategory={analyticsCategory} setActivityInfo={setActivityInfo}>
            <MenuButton as={Button} aria-label={t('tabs.activities.create')} variant="outline" size="sm">
              {t('tabs.activities.create')}
            </MenuButton>
          </ActivityButton>)}
      </HStack>
      <Table id={`activities`} columns={columns} data={activities || []} isLoading={isLoading} initialColumnFilters={initialColumnFilters} initialColumnVisibility={activities
            ? {
                contact: IOActivity.is(activities[0]) ||
                    IOPlotActivity.is(activities[0]),
                plotId: IOActivity.is(activities[0]) ||
                    IOContactActivity.is(activities[0]),
                id: isUserOwner,
            }
            : {}} withExport={false}/>
      <ActivityDrawer activityInfo={activityInfo} handleClose={() => setActivityInfo(undefined)}/>
    </Stack>);
};
export default ActivityList;
