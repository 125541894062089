import * as React from 'react';
import { useCallback, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Heading } from '@chakra-ui/react';
import * as iots from 'io-ts';
import { get } from 'api/client';
import InfoWindowTable from 'components/infoWindow/InfoWindowTable';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
const IOLandscapingProjectsLayerItem = IOLayerItem;
const formatInfoWindowContent = (item, t) => {
    const content = (<Box>
      <InfoWindowTable title={`${t('info_window.landscaping_projects.project')} [${item.name}]`} content={[
            {
                title: t('info_window.landscaping_projects.update_date'),
                data: item.date4,
            },
            {
                title: t('info_window.landscaping_projects.name'),
                data: item.name,
            },
            {
                title: t('info_window.landscaping_projects.main_calling'),
                data: item.mainCalling,
            },
            {
                title: t('info_window.landscaping_projects.secondary_calling'),
                data: item.secondaryCalling,
            },
            {
                title: t('info_window.landscaping_projects.progression_step'),
                data: item.progressionStep,
            },
            {
                title: t('info_window.landscaping_projects.pru'),
                data: item.pru,
            },
            {
                title: t('info_window.landscaping_projects.extention_renew'),
                data: item.extentionRenew,
            },
            {
                title: t('info_window.landscaping_projects.gestion'),
                data: item.gestion,
            },
            {
                title: t('info_window.landscaping_projects.developer'),
                data: item.developer,
            },
            {
                title: t('info_window.landscaping_projects.start_date'),
                data: item.date1,
            },
            {
                title: t('info_window.landscaping_projects.end_date'),
                data: item.date2,
            },
            {
                title: t('info_window.landscaping_projects.delivery_date'),
                data: item.date3,
            },
            {
                title: t('info_window.landscaping_projects.source'),
                data: item.source,
            },
        ]}/>
      <Divider height={2} backgroundColor="gray" marginBottom="15px"/>
      <InfoWindowTable title={t('info_window.landscaping_projects.programmation')} content={[
            {
                title: t('info_window.landscaping_projects.total_program_size'),
                data: `${item.totalProgramSize} m²`,
                align: 'right',
            },
            {
                title: t('info_window.landscaping_projects.estate_program_size'),
                data: `${item.estateProgramSize} m²`,
                align: 'right',
            },
            {
                title: t('info_window.landscaping_projects.activity_program_size'),
                data: `${item.activityProgramSize} m²`,
                align: 'right',
            },
            {
                title: t('info_window.landscaping_projects.office_program_size'),
                data: `${item.officeProgramSize} m²`,
                align: 'right',
            },
            {
                title: t('info_window.landscaping_projects.commerce_program_size'),
                data: `${item.commerceProgramSize} m²`,
                align: 'right',
            },
            {
                title: t('info_window.landscaping_projects.other_activity_program_size'),
                data: `${item.otherActivityProgramSize} m²`,
                align: 'right',
            },
            {
                title: t('info_window.landscaping_projects.landscaping_program_size'),
                data: `${item.equipmentProgramSize} m²`,
                align: 'right',
            },
            {
                title: t('info_window.landscaping_projects.green_space_size'),
                data: `${item.greenSpaceSize} m²`,
                align: 'right',
            },
            {
                title: t('info_window.landscaping_projects.housing_quantity'),
                data: `${item.housingQuantity}`,
                align: 'right',
            },
            {
                title: t('info_window.landscaping_projects.jobs_quantity'),
                data: `${item.jobsQuantity}`,
                align: 'right',
            },
            {
                title: t('info_window.landscaping_projects.inhabitants_quantity'),
                data: `${item.inhabitantsQuantity}`,
                align: 'right',
            },
        ]}/>
      <Divider height={2} backgroundColor="gray" marginBottom="15px"/>
      <Box marginBottom="5px">
        <Heading fontSize="20px" fontWeight={900} paddingBottom="10px">
          {t('info_window.landscaping_projects.comments')}
        </Heading>
        <Box>
          <Box>{item.comment}</Box>
        </Box>
      </Box>
      <Divider height={2} backgroundColor="gray" marginBottom="15px"/>
      <InfoWindowTable title={t('info_window.landscaping_projects.other')} content={[
            {
                title: t('info_window.landscaping_projects.perimeter_scan_quality'),
                data: item.perimeterScanQuality,
            },
            {
                title: t('info_window.landscaping_projects.main_town'),
                data: item.mainTown,
            },
            {
                title: t('info_window.landscaping_projects.secondary_town'),
                data: item.secondTown,
            },
            {
                title: t('info_window.landscaping_projects.tertiary_town'),
                data: item.thirdTown,
            },
            {
                title: t('info_window.landscaping_projects.fourth_town'),
                data: item.fourthTown,
            },
        ]}/>
    </Box>);
    return ReactDOMServer.renderToStaticMarkup(content);
};
const fetchLandscapingProjectsLayer = (townId) => () => get(iots.array(IOLandscapingProjectsLayerItem), `legacy/${townId}/landscaping-projects`);
const useLandscapingProjectsLayer = () => {
    const selectedTown = useSelectedTown();
    const { t } = useTranslation();
    const [itemInfo, setItemInfo] = useState({});
    const getInfoWindowContent = useCallback((item) => {
        const content = formatInfoWindowContent(item, t);
        setItemInfo({
            ...itemInfo,
            [item.id]: content,
        });
        return content;
    }, [selectedTown]);
    createLayersHook({
        fetch: fetchLandscapingProjectsLayer,
        getInfoWindowContent,
    })(filtersKey.LANDSCAPING_PROJECTS);
};
const LandscapingProjectsLayer = () => {
    useLandscapingProjectsLayer();
    return null;
};
export default LandscapingProjectsLayer;
