import { useNavigate } from 'react-router-dom';
import qs from 'qs';
import { usePrecision } from 'hooks/contexts/useMapContext';
export default () => {
    const navigate = useNavigate();
    const currentPrecision = usePrecision();
    return ({ lat, lng, precision, ...rest }) => {
        navigate({
            pathname: `/ville/${lat}/${lng}`,
            search: qs.stringify({
                precision: precision || currentPrecision,
                ...rest,
            }),
        });
    };
};
