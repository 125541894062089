import { useMemo, useState } from 'react';
import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import useCurrentSite from 'hooks/useCurrentSite';
import { getSiteImage } from 'hooks/useSiteLogo';
import customMarkerImg from 'images/custom_marker.png';
import { IOMarkerLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import UserPOIInfoWindow, { IOUserPOIInfo, } from '../infoWindow/UserPOIInfoWindow';
const IOUserPOIMarkerItem = iots.intersection([
    IOMarkerLayerItem,
    iots.type({
        legend: iots.union([iots.string, iots.null]),
    }),
], 'IOUserPOIMarkerItem');
const getMarkerImg = (marker) => {
    if (!marker.legend)
        return null;
    try {
        return require(`../../images/UserPOIs/${marker.legend}`);
    }
    catch (e) {
        if (e instanceof Error) {
            //eslint-disable-next-line
            console.error("Can't load ", marker.legend);
        }
        else
            throw e;
    }
    return customMarkerImg;
};
const formatInfoWindowContent = (info) => {
    const content = <UserPOIInfoWindow info={info}/>;
    return ReactDOMServer.renderToStaticMarkup(content);
};
const fetchUserPOIs = (townId, townScot, selectedTown, siteId) => () => get(iots.array(IOUserPOIMarkerItem), `user-pois/${siteId}`);
const fetchUserPOI = (item) => {
    return get(IOUserPOIInfo, `user-poi/${item.id}`);
};
const useUserPOIsLayer = () => {
    const { currentSite } = useCurrentSite();
    const [markerInfo, setMarkerInfo] = useState({});
    const getInfoWindowContent = async (marker) => {
        if (markerInfo[marker.id]) {
            return markerInfo[marker.id];
        }
        const poiInfo = await fetchUserPOI(marker);
        const content = formatInfoWindowContent(poiInfo);
        setMarkerInfo({
            ...markerInfo,
            [marker.id]: content,
        });
        return content;
    };
    const legends = useMemo(() => ({
        [filtersKey.USER_POIS]: {
            layerKey: filtersKey.USER_POIS,
            label: filtersKey.USER_POIS,
            needsTranslate: true,
            image: getSiteImage(currentSite.favicon),
        },
    }), [currentSite]);
    createLayersHook({
        fetch: fetchUserPOIs,
        markerImg: getMarkerImg,
        legends,
        getInfoWindowContent,
        extraParams: [currentSite?.legacyId],
        disableQuery: !currentSite,
    })(filtersKey.USER_POIS);
};
const UserPOIsLayers = () => {
    useUserPOIsLayer();
    return null;
};
export default UserPOIsLayers;
