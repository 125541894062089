import * as React from 'react';
import { useCallback, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
export const IOEpfLandControlLayerItem = IOLayerItem;
export const IOEPFInfo = iots.intersection([
    IOLayerItem,
    iots.partial({
        convention: iots.string,
        endDate: iots.string,
        signatureDate: iots.string,
        duration: iots.string,
        area: iots.string,
        programType: iots.string,
        typology: iots.string,
    }),
]);
export const formatInfoWindowContent = (item, t) => {
    const content = (<Box>
      <p>
        <b>{`${t('info_window.epf.typology')} : ${item.typology}`}</b>
      </p>
      <p>{`${t('info_window.epf.program_type')} : ${item.programType}`}</p>
      <p>{`${t('info_window.epf.convention')} : ${item.convention}`}</p>
      <p>{`${t('info_window.epf.duration')} : ${item.duration}`}</p>
      <p>{`${t('info_window.epf.signature_date')} : ${item.signatureDate}`}</p>
      <p>{`${t('info_window.epf.end_date')} : ${item.endDate}`}</p>
      <p>{`${t('info_window.epf.area')} : ${item.area}`}</p>
    </Box>);
    return ReactDOMServer.renderToStaticMarkup(content);
};
const fetchEPFLandControl = (townId) => () => get(iots.array(IOEpfLandControlLayerItem), `legacy/${townId}/epf-land-control`);
const useEPFLandControlLayer = () => {
    const selectedTown = useSelectedTown();
    const { t } = useTranslation();
    const [itemInfo, setItemInfo] = useState({});
    const getInfoWindowContent = useCallback((item) => {
        const content = formatInfoWindowContent(item, t);
        setItemInfo({
            ...itemInfo,
            [item.id]: content,
        });
        return content;
    }, [selectedTown]);
    createLayersHook({
        fetch: fetchEPFLandControl,
        getInfoWindowContent,
    })(filtersKey.EPF_LAND_CONTROL);
};
const EPFLandControlLayer = () => {
    useEPFLandControlLayer();
    return null;
};
export default EPFLandControlLayer;
