import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, CloseButton, StackDivider, VStack, } from '@chakra-ui/react';
import { CONTEXT_MENU_ID, useContextMenu } from 'hooks/map/useContextMenu';
import { useMap, usePanorama } from 'hooks/contexts/useMapContext';
import useCustomToast from 'hooks/useCustomToast';
import customMarkerImg from 'images/custom_marker.png';
import { addMarkerToMapAndPanorama } from 'utils/map';
import { removeDiacritics } from 'utils/search';
const ContextMenuPortal = ({ children }) => {
    const contextMenuDiv = document.getElementById(CONTEXT_MENU_ID);
    if (!contextMenuDiv) {
        return null;
    }
    return ReactDOM.createPortal(children, contextMenuDiv);
};
const replaceRoutes = (route) => {
    const ROUTES_REGEX = [
        { re: 'Avenue', ch: 'AV' },
        { re: 'Impasse', ch: 'IMP' },
        { re: 'Villa', ch: 'VLA' },
        { re: 'Boulevard', ch: 'BD' },
    ];
    ROUTES_REGEX.forEach((r) => {
        route = route.replace(r.re, r.ch);
    });
    return route;
};
const getFormattedAddressFromGeocoderResult = (result) => {
    const address = {};
    const addressComponents = result.address_components;
    addressComponents.forEach((component) => {
        switch (component.types[0]) {
            case 'street_number':
                address.streetNumber = component.long_name;
                if (address.streetNumber.includes('-')) {
                    const streetNumberParts = address.streetNumber.split('-');
                    address.streetNumber =
                        parseInt(streetNumberParts[1]) - parseInt(streetNumberParts[0]);
                }
                break;
            case 'route':
                address.route = replaceRoutes(removeDiacritics(component.long_name));
                break;
            case 'postal_code':
                address.postalCode = component.long_name;
                address.department = address.postalCode.substring(0, 2).padStart(3, '0');
                break;
        }
    });
    return address;
};
const getCadastreUrlFromGeocoderResult = (result) => {
    const address = getFormattedAddressFromGeocoderResult(result);
    return `https://www.cadastre.gouv.fr/scpc/rechercherPlan.do?numeroVoie=${address.streetNumber}&indiceRepetition=&nomVoie=${address.route}
&lieuDit=&ville=&codePostal=${address.postalCode}&codeDepartement=${address.department}&nbResultatParPage=10`;
};
const ContextMenu = () => {
    const { t } = useTranslation();
    const { getErrorToast } = useCustomToast();
    const map = useMap();
    const panorama = usePanorama();
    const { isOpen, latLng, close } = useContextMenu();
    if (!isOpen || !latLng) {
        return null;
    }
    const goToCadastre = () => {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ location: latLng }, (results, status) => {
            if (status !== google.maps.GeocoderStatus.OK) {
                getErrorToast({
                    title: t('context_menu.geocoder_error'),
                    description: process.env.NODE_ENV === 'development' ? status : undefined,
                });
            }
            if (results.length >= 0) {
                const url = getCadastreUrlFromGeocoderResult(results[0]);
                window.open(url, '_blank');
            }
            close();
        });
    };
    const addMarker = () => {
        addMarkerToMapAndPanorama(panorama, map, latLng, customMarkerImg);
        close();
    };
    return (<ContextMenuPortal>
      <Box width="100%" boxShadow="lg" backgroundColor="colorMode.background900" fontSize="sm" borderRadius="md" position="relative">
        <CloseButton color="colorMode.font700" variant="link" size="sm" onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            close();
        }} position="absolute" zIndex={1} top={1} right={1}/>
        <VStack divider={<StackDivider />} padding="0" spacing="0">
          <Button onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            addMarker();
        }} variant="link-secondary" width="100%">
            {t('context_menu.add_marker')}
          </Button>
          <Button onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            goToCadastre();
        }} variant="link-secondary" width="100%">
            {t('context_menu.go_to_cadastre')}
          </Button>
        </VStack>
      </Box>
    </ContextMenuPortal>);
};
export default ContextMenu;
