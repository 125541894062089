import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, HStack, Skeleton, Text, VStack } from '@chakra-ui/react';
import { CREDIT_BLACK_AND_WHITE_ADDITIONAL_PAGE, CREDIT_BLACK_AND_WHITE_FIRST_PAGE, CREDIT_COLOR_ADDITIONAL_PAGE, CREDIT_COLOR_FIRST_PAGE, CREDIT_OPTION_TREAT_UNDELIVERED_MAIL, } from 'components/tabs/couriers/courierForm/CourierFormUtils';
import useCourier from 'hooks/courier/useCourier';
import { formatDate } from 'utils/formatDate';
import { formatNumberToLocale } from 'utils/formatNumber';
import CreditHelp from './CreditHelp';
export const canSendCourier = (statsCouriersUser, courierInfo) => {
    const { dateLimit, nbCredit } = statsCouriersUser ?? {};
    const date = new Date(dateLimit);
    const now = new Date();
    if (date <= now) {
        return false;
    }
    let totalCost = 0;
    let nbPage = 1;
    if (courierInfo.mailevaOptionNbPage) {
        nbPage = courierInfo.mailevaOptionNbPage;
    }
    if (courierInfo.mailevaOptionColor) {
        totalCost += CREDIT_COLOR_FIRST_PAGE;
        totalCost += (nbPage - 1) * CREDIT_COLOR_ADDITIONAL_PAGE;
    }
    else {
        totalCost += CREDIT_BLACK_AND_WHITE_FIRST_PAGE;
        totalCost += (nbPage - 1) * CREDIT_BLACK_AND_WHITE_ADDITIONAL_PAGE;
    }
    if (courierInfo.mailevaOptionTreatUndeliveredMail) {
        totalCost += CREDIT_OPTION_TREAT_UNDELIVERED_MAIL;
    }
    return totalCost <= nbCredit;
};
const CourierCredits = () => {
    const { t } = useTranslation();
    const { statsCouriersUser, isLoadingStatsCouriersUser } = useCourier({
        enabledList: true,
    });
    const { dateLimit, nbCredit, nbCourierRemaining } = statsCouriersUser ?? {};
    if (isLoadingStatsCouriersUser)
        return <Skeleton height="45px" width="245px"/>;
    return (<HStack>
      <VStack spacing={0} alignItems="stretch">
        <Text as="span" fontSize={12} fontWeight="bold">
          {t('credit.nb_credit', {
            euro: formatNumberToLocale(nbCredit ?? 0, 'fr'),
        })}
        </Text>

        <Box>
          {nbCourierRemaining > 0 ? (<Text as="span" fontSize={12} fontWeight="bold">
              {t('courier.stats.date_limit', {
                count: nbCourierRemaining,
            })}
              {formatDate(dateLimit, 'short')}
            </Text>) : (<CreditHelp />)}
        </Box>
      </VStack>
    </HStack>);
};
export default CourierCredits;
