import * as React from 'react';
import { useState } from 'react';
import * as iots from 'io-ts';
import { createContext } from 'use-context-selector';
export const IOPlotModal = iots.type({
    townshipId: iots.string,
    plotId: iots.string,
});
export const ModalContext = createContext(null);
export const ModalProvider = ({ children }) => {
    const [isPlotModalOpen, setPlotModalOpen] = useState(false);
    const [plotData, setPlotData] = useState({
        townshipId: null,
        plotId: null,
    });
    const openPlotModal = (plotModal) => {
        setPlotData(plotModal);
        setPlotModalOpen(true);
    };
    const closePlotModal = () => setPlotModalOpen(false);
    return (<ModalContext.Provider value={{
            isPlotModalOpen,
            openPlotModal,
            closePlotModal,
            plotData,
        }}>
      {children}
    </ModalContext.Provider>);
};
export default ModalContext;
