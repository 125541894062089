import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import StationIcon from 'images/station_icon.png';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOPhotovoltaicProneAreaStationsLayerItem = IOLayerItem;
const fetchPhotovoltaicProneAreaStations = (townId) => () => get(iots.array(IOPhotovoltaicProneAreaStationsLayerItem), `${townId}/photovoltaic-prone-area/gare`);
const usePhotovoltaicProneAreaStationsLayer = () => {
    createLayersHook({
        fetch: fetchPhotovoltaicProneAreaStations,
        markerImg: StationIcon,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.PHOTOVOLTAIC_PRONE_AREA_STATIONS);
};
const PhotovoltaicProneAreaStationsLayer = () => {
    usePhotovoltaicProneAreaStationsLayer();
    return null;
};
export default PhotovoltaicProneAreaStationsLayer;
