import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { IOProjectContact } from 'types/api';
export const QUERY_KEY = 'project-contacts';
export const getProjectContactsQueryKey = (projectId) => [
    QUERY_KEY,
    projectId,
];
const fetchProjectContacts = (projectId, landPointId) => () => get(iots.array(IOProjectContact), `project/${projectId}/contacts`, {
    landPointId,
});
const useProjectContacts = (projectId, landPointId) => {
    const { data: projectContacts, isInitialLoading } = useQuery({
        queryKey: getProjectContactsQueryKey(projectId),
        queryFn: fetchProjectContacts(projectId, landPointId),
        enabled: !!projectId,
    });
    return {
        projectContacts,
        isInitialLoading,
    };
};
export default useProjectContacts;
