import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOPVRAreasLayerItem = IOLayerItem;
const fetchPVRAreas = (townId) => () => get(iots.array(IOPVRAreasLayerItem), `legacy/${townId}/pvr-areas`);
const usePVRAreasLayer = () => {
    createLayersHook({
        fetch: fetchPVRAreas,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.PVR_AREAS);
};
const PVRAreasLayer = () => {
    usePVRAreasLayer();
    return null;
};
export default PVRAreasLayer;
