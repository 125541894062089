import { menuItems } from 'components/sidebar/utils';
import { useMap, useMapCenter, usePanorama, useSetPrecision, } from 'hooks/contexts/useMapContext';
import customMarkerImg from 'images/custom_marker.png';
import { addMarkerToMapAndPanorama, cleanAllMarkerFromMapAndPanorama, } from 'utils/map';
import { useSetFormattedAddress, useSetShouldAddTownToHistory, useSetTownCoordinates, } from './contexts/useLocationContext';
import { useToggleMenu } from './contexts/useMenuContext';
export const ADDRESS_FROM_CLICK = 'address_from_click';
export default () => {
    const setPrecision = useSetPrecision();
    const mapCenter = useMapCenter();
    const map = useMap();
    const panorama = usePanorama();
    const setTownCoordinates = useSetTownCoordinates();
    const setShouldAddTownToHistory = useSetShouldAddTownToHistory();
    const setFormattedAddress = useSetFormattedAddress();
    const toggleMenu = useToggleMenu();
    return ({ townCoordinates, plotToSelect, formattedAddress = ADDRESS_FROM_CLICK, precision, centerMapOn = null, addTownMarker = false, }) => {
        // Manage lateral menu display
        toggleMenu(menuItems?.LAYERS_TREE, true);
        // Set map params
        mapCenter.current = centerMapOn;
        setTownCoordinates(townCoordinates);
        setShouldAddTownToHistory(!plotToSelect);
        setPrecision(precision);
        setFormattedAddress(formattedAddress);
        if (addTownMarker) {
            cleanAllMarkerFromMapAndPanorama();
            addMarkerToMapAndPanorama(panorama, map, townCoordinates, customMarkerImg);
        }
    };
};
