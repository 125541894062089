import { useContextSelector } from 'use-context-selector';
import SearchContext from 'context/PlotSearchContext';
export const usePlotSearchParams = () => {
    return useContextSelector(SearchContext, (state) => state.plotSearchParams);
};
export const useSetPlotSearchParams = () => {
    return useContextSelector(SearchContext, (state) => state.setPlotSearchParams);
};
export const useCurrentSearchTownId = () => {
    return useContextSelector(SearchContext, (state) => state.currentSearchTownId);
};
export const useSearchResults = () => {
    return useContextSelector(SearchContext, (state) => state.searchResults);
};
export const useSearchResultsCount = () => {
    return useContextSelector(SearchContext, (state) => state.searchResultsCount);
};
export const useGetPlotsIds = () => {
    return useContextSelector(SearchContext, (state) => state.getPlotsIds);
};
export const useIsFetching = () => {
    return useContextSelector(SearchContext, (state) => state.isFetching);
};
export const useIsLoading = () => {
    return useContextSelector(SearchContext, (state) => state.isLoading);
};
export const useIsNewResult = () => {
    return useContextSelector(SearchContext, (state) => state.isNewResult);
};
