import * as React from 'react';
import { useState } from 'react';
import { Box } from '@chakra-ui/react';
import frLocale from '@fullcalendar/core/locales/fr';
import FullCalendar from '@fullcalendar/react';
import moment from 'moment';
import { TABS_VIEW_FULL } from 'context/LayoutContext';
import { useTabsView } from 'hooks/contexts/useLayoutContext';
import CustomEvent from './CustomEvent';
import CustomHeader from './CustomHeader';
import { views } from './Views';
const formatActivityData = (activities) => activities?.map((activity) => ({
    ...activity,
    activityId: activity?.id,
    activityTitle: activity?.title,
    title: activity?.title,
    start: new Date(activity.date),
    end: new Date(activity.date),
}));
const ActivityCalendar = ({ activities, setEditedActivity, }) => {
    const tabsView = useTabsView();
    const calendarRef = React.useRef();
    const calendarApi = calendarRef.current?.getApi();
    const [viewTitle, setViewTitle] = useState(calendarApi?.view.title);
    const [viewType, setViewType] = useState(calendarApi?.view.type);
    return (<Box paddingBottom={4} height="100%">
      <CustomHeader calendarApi={calendarApi} viewTitle={viewTitle} viewType={viewType}/>
      <FullCalendar ref={calendarRef} locale={frLocale} plugins={views} headerToolbar={false} initialView="dayGridMonth" initialDate={new Date()} selectMirror={true} datesSet={({ view }) => {
            setViewTitle(view.title);
            setViewType(view.type);
        }} dayMaxEvents={viewType === 'dayGridMonth'
            ? tabsView === TABS_VIEW_FULL
                ? 8
                : 3
            : true} weekends={false} height="calc(100% - 40px)" views={{
            month: {
                dayHeaderFormat: {
                    weekday: 'long',
                },
            },
            day: {
                dayHeaderFormat: {
                    weekday: 'long',
                    month: 'numeric',
                    day: 'numeric',
                    omitCommas: true,
                },
            },
        }} moreLinkClick={() => {
            calendarApi?.changeView('dayGridDay');
            return 'day';
        }} events={formatActivityData(activities || [])} eventContent={(eventInfo) => (<CustomEvent {...eventInfo} setEditedActivity={setEditedActivity} eventDetail={{
                ...eventInfo?.event._def.extendedProps,
                title: eventInfo?.event?.title,
                date: moment(eventInfo?.event?.start).format('yyyy-MM-DD HH:mm:ss'),
            }}/>)}/>
    </Box>);
};
export default ActivityCalendar;
