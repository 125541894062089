import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOLimitedBuildingZoneResourcesAndFacilitiesLayerItem = IOLayerItem;
const fetchLimitedBuildingZoneResourcesAndFacilities = (townId) => () => get(iots.array(IOLimitedBuildingZoneResourcesAndFacilitiesLayerItem), `legacy/${townId}/limited-building-zone-resources-and-facilities`);
const useLimitedBuildingZoneResourcesAndFacilitiesLayer = () => {
    createLayersHook({
        fetch: fetchLimitedBuildingZoneResourcesAndFacilities,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.LIMITED_BUILDING_ZONE_RESOURCES_AND_FACILITIES);
};
const LimitedBuildingZoneResourcesAndFacilitiesLayer = () => {
    useLimitedBuildingZoneResourcesAndFacilitiesLayer();
    return null;
};
export default LimitedBuildingZoneResourcesAndFacilitiesLayer;
