import * as React from 'react';
import { Box, HStack, Spinner } from '@chakra-ui/react';
import useLandPoint from 'hooks/useLandPoint';
import LandPointActivity from './LandPointActivity';
import LandPointPrevReports from './LandPointPrevReports';
import LandPointReportForm from './LandPointReportForm';
const LandPointReport = ({ landPoint, projectId, }) => {
    const { landPointReports, isLoadingReports } = useLandPoint({
        landPointId: landPoint.id,
    });
    return (<HStack spacing={0} width="100%" height="25%" minHeight="150px" borderBottom="1px solid #CACACA" borderTop="1px solid #CACACA" alignItems="flex-start">
      <Box width="33.33%" height="100%" borderRight="1px solid #CACACA">
        <LandPointPrevReports landPointId={landPoint.id} projectId={projectId}/>
      </Box>
      <Box width="33.33%" height="100%" borderRight="1px solid #CACACA">
        <LandPointActivity landPointId={landPoint.id} projectId={projectId}/>
      </Box>
      <Box width="33.33%" height="100%">
        {isLoadingReports ? (<Box display="flex" height="100%" alignItems="center" justifyContent="center">
            <Spinner />
          </Box>) : (<LandPointReportForm landPointReports={landPointReports} landPoint={landPoint} projectId={projectId}/>)}
      </Box>
    </HStack>);
};
export default LandPointReport;
