import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOTerritorialDevelopmentAgreementLayerItem = IOLayerItem;
const fetchTerritorialDevelopmentAgreement = (townId) => () => get(iots.array(IOTerritorialDevelopmentAgreementLayerItem), `legacy/${townId}/territorial-development-agreement`);
const useTerritorialDevelopmentAgreementLayer = () => {
    createLayersHook({
        fetch: fetchTerritorialDevelopmentAgreement,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.TERRITORIAL_DEVELOPMENT_AGREEMENT);
};
const TerritorialDevelopmentAgreementLayer = () => {
    useTerritorialDevelopmentAgreementLayer();
    return null;
};
export default TerritorialDevelopmentAgreementLayer;
