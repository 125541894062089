import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getTileBoundingBox } from 'utils/geo';
import { getBaseTileUrlHost3 } from 'utils/multiplexage';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = (map, tileWidth, tileHeight) => (coord, zoom) => {
    const boundingBox = getTileBoundingBox(coord, zoom, map, tileWidth, tileHeight);
    return (getBaseTileUrlHost3() +
        'cgi-bin/mm/mapserv.cgi?map=/var/data/public_maps/project_brgm.map&SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=irsn_radon&TILED=true&WIDTH=' +
        tileWidth +
        '&HEIGHT=' +
        tileHeight +
        '&CRS=EPSG:4326&STYLES=&BBOX=' +
        boundingBox);
};
const getLegends = (enabledFilters) => {
    const legends = {};
    if (enabledFilters.includes(filtersKey.RADIOACTIVE_GAS)) {
        legends.radioactive_gaz_area_1 = {
            layerKey: filtersKey.RADIOACTIVE_GAS,
            label: 'radioactive_gaz_area_1',
            needsTranslate: true,
            color: '#FFFF00',
            opacity: 0.6,
        };
        legends.radioactive_gaz_area_2 = {
            layerKey: filtersKey.RADIOACTIVE_GAS,
            label: 'radioactive_gaz_area_2',
            needsTranslate: true,
            color: '#ff9e45',
            opacity: 0.6,
        };
        legends.radioactive_gaz_area_3 = {
            layerKey: filtersKey.RADIOACTIVE_GAS,
            label: 'radioactive_gaz_area_3',
            needsTranslate: true,
            color: '#FF0000',
            opacity: 0.6,
        };
    }
    return legends;
};
const useRadioactiveGasLayer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.RADIOACTIVE_GAS],
        getLegends,
    })(filtersKey.RADIOACTIVE_GAS);
};
const RadioactiveGasLayer = () => {
    useRadioactiveGasLayer();
    return null;
};
export default RadioactiveGasLayer;
