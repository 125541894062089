import * as React from 'react';
import { memo, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Box, HStack, Stack } from '@chakra-ui/react';
import { FormControl } from '@chakra-ui/react';
import moment from 'moment';
import DateTimePickerInput from 'components/inputs/DateTimePickerInput';
import { multiSelectStyles } from 'components/inputs/MultiSelectInput';
import useTeamStatisticsCRM from 'hooks/crm/useTeamStatisticsCRM';
import TeamStatisticsActivitiesPanel from './teamStatisticsCRM/TeamStatisticsActivitiesPanel';
import TeamStatisticsContactsPanel from './teamStatisticsCRM/TeamStatisticsContactsPanel';
import TeamStatisticsPlotsByUserPanel from './teamStatisticsCRM/TeamStatisticsPlotsByUserPanel';
import TeamStatisticsPlotsPanel from './teamStatisticsCRM/TeamStatisticsPlotsPanel';
import TeamStatisticsProjectsByUsersPanel from './teamStatisticsCRM/TeamStatisticsProjectsByUsersPanel';
import TeamStatisticsProjectsPanel from './teamStatisticsCRM/TeamStatisticsProjectsPanel';
export function isAvailable({ filters, date = null, dateStart = null, dateEnd = null, group = null, email = null, status = null, }) {
    if (email && filters.users?.length > 0 && !filters.users.includes(email)) {
        return false;
    }
    if (group && filters.groups?.length > 0 && !filters.groups.includes(group)) {
        return false;
    }
    if (status && filters.status && filters.status !== status) {
        return false;
    }
    if (date instanceof Date) {
        if (filters.dateStart && date.getTime() < filters.dateStart.getTime()) {
            return false;
        }
        if (filters.dateEnd && date.getTime() > filters.dateEnd.getTime()) {
            return false;
        }
    }
    if (dateStart instanceof Date && dateEnd instanceof Date) {
        if (filters.dateStart && dateEnd.getTime() < filters.dateStart.getTime()) {
            return false;
        }
        if (filters.dateEnd && dateStart.getTime() > filters.dateEnd.getTime()) {
            return false;
        }
    }
    return true;
}
const TeamStatisticsCRMPanel = () => {
    const { t } = useTranslation();
    const currentDate = moment();
    const pastDate = currentDate.subtract(90, 'days');
    const [filters, setFilters] = useState({
        users: [],
        groups: [],
        dateStart: new Date(pastDate.format('YYYY-MM-DD')),
        dateEnd: null,
    });
    const formRef = useRef(null);
    const { statsFilters, isLoadingStatsFilters, refetchFilters } = useTeamStatisticsCRM({ toLoad: 'filters' });
    const methods = useForm({
        defaultValues: {
            users: [],
            groups: [],
            dateStart: new Date(pastDate.format('YYYY-MM-DD')),
            dateEnd: null,
        },
    });
    const { setValue, handleSubmit, getValues, register, watch } = methods;
    useEffect(() => {
        refetchFilters();
    }, []);
    const handleChange = () => {
        onSubmit();
    };
    const onSubmit = () => {
        const formData = getValues();
        const currentFilters = {
            users: formData.users
                ? formData.users.map((option) => {
                    return option.value;
                })
                : null,
            groups: formData.groups
                ? formData.groups.map((option) => {
                    return option.value;
                })
                : null,
            dateStart: formData.dateStart ?? null,
            dateEnd: formData.dateEnd ?? null,
        };
        setFilters(currentFilters);
    };
    return (<Stack paddingX={5} height="100%">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
          <HStack>
            <FormControl width="200px">
              <Select {...register('users')} isLoading={isLoadingStatsFilters} placeholder={t('tabs.team_statistics_crm.all_user')} closeMenuOnSelect={false} options={statsFilters?.users
            .map((user) => {
            return {
                label: user.name,
                value: user.email,
            };
        })
            .sort()} isMulti onChange={(selectValues) => {
            setValue('users', selectValues);
            handleChange();
        }} value={watch('users')} styles={multiSelectStyles}/>
            </FormControl>
            <FormControl width="200px">
              <Select name="group" isLoading={isLoadingStatsFilters} placeholder={t('tabs.team_statistics_crm.all_group')} closeMenuOnSelect={false} options={statsFilters?.groups
            .map((group) => {
            return {
                label: group,
                value: group,
            };
        })
            .sort()} isMulti onChange={(selectValues) => {
            setValue('groups', selectValues);
            handleChange();
        }} value={watch('groups')} styles={multiSelectStyles}/>
            </FormControl>
            <Box>
              <DateTimePickerInput name="dateStart" label={t('tabs.team_statistics_crm.date_start')} placeholder={t('global.beginning')} onChange={handleChange} required withTime={false} isClearable={true} direction="row" minDate={null} size="sm"/>
            </Box>
            <Box>
              <DateTimePickerInput name="dateEnd" label={t('tabs.team_statistics_crm.date_end')} placeholder={t('global.today')} onChange={handleChange} required withTime={false} isClearable={true} direction="row" minDate={null} size="sm"/>
            </Box>
          </HStack>
        </form>
      </FormProvider>
      <HStack width="100%" alignItems="flex-start" paddingBottom={3}>
        <TeamStatisticsProjectsPanel filters={filters}/>
        <TeamStatisticsPlotsPanel filters={filters}/>
      </HStack>
      <HStack width="100%" alignItems="flex-start" paddingBottom={3}>
        <TeamStatisticsProjectsByUsersPanel filters={filters} statsFilters={statsFilters}/>
        <TeamStatisticsPlotsByUserPanel filters={filters} statsFilters={statsFilters}/>
      </HStack>
      <HStack width="100%" alignItems="flex-start" paddingBottom={2}>
        <TeamStatisticsContactsPanel filters={filters}/>
        <TeamStatisticsActivitiesPanel filters={filters}/>
      </HStack>
    </Stack>);
};
export default memo(TeamStatisticsCRMPanel);
