import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner, Switch, Text } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_MAP_DISPLAY } from 'analytics/useAnalytics';
import get from 'lodash/get';
import { UNKNOWN_LAYER } from 'components/Filter';
import { useIsLoadingLayersTree, useLayersTree, } from 'hooks/contexts/useLayersTreeContext';
import { useSetLayoutHalf } from 'hooks/contexts/useLayoutContext';
import { useIsLoadingTown } from 'hooks/contexts/useLocationContext';
import { useFilterCategoriesCheckbox } from 'hooks/useFilterCategoryCheckbox';
import { useFiltersCheckbox } from 'hooks/useFilterCheckbox';
import { filtersKey, getFilterKey, IOFilterCategory, } from 'utils/filters';
export const LayerSwitch = ({ filters, trackAction, dataCy, onChange, }) => {
    const setLayoutHalf = useSetLayoutHalf();
    const { isAllChecked: categoriesChecked, onCategoriesCheck } = useFilterCategoriesCheckbox(filters.categories || {});
    const { isChecked: layersChecked, onCheck: onLayersCheck } = useFiltersCheckbox(filters.layers, true);
    const { trackEvent } = useAnalytics();
    const checked = layersChecked && categoriesChecked;
    return (<Switch size="md" isChecked={checked} onChange={(e) => {
            onCategoriesCheck.forEach((onCheck) => {
                onCheck(e);
            });
            if (trackAction && e.target.checked) {
                trackEvent({
                    category: ANALYTICS_MAP_DISPLAY,
                    action: trackAction,
                });
            }
            onLayersCheck(e.target.checked);
            setLayoutHalf();
            onChange && onChange(e.target.checked);
        }} data-cy={dataCy}/>);
};
const NoMatch = ({ filterKeys }) => {
    const { t } = useTranslation();
    if (filterKeys.includes(filtersKey.HEART_OF_TOWN) ||
        filterKeys.includes(filtersKey.CITIES_OF_TOMORROW)) {
        return null;
    }
    return <Text size="sm">{t('tabs.plots.section.plot.no_match')}</Text>;
};
const PlotLayerSwitch = ({ plotLayer, }) => {
    const { t } = useTranslation();
    const isLoadingTown = useIsLoadingTown();
    const isLoadingLayersTree = useIsLoadingLayersTree();
    const layersTree = useLayersTree();
    if (isLoadingTown || isLoadingLayersTree) {
        return <Spinner />;
    }
    if (!layersTree) {
        return (<Text size="sm">
        {t('tabs.plots.section.plot.select_town_to_toggle_filters')}
      </Text>);
    }
    const filterPaths = [...plotLayer.filterPaths];
    if (filterPaths.length > 0 &&
        filterPaths[0].length > 0 &&
        filterPaths[0][0] === UNKNOWN_LAYER) {
        return (<Text color="colorMode.font700" textStyle="paragraph">
        {t('tabs.plots.section.plot.filter_not_implemented')}
      </Text>);
    }
    const filters = {
        layers: {},
        categories: {},
        noMatch: {},
    };
    filterPaths.forEach((filterPath) => {
        const filterKey = filterPath[filterPath.length - 1];
        // We need to display one or more layers
        if (filterPath.includes('layers')) {
            const categoryLayers = get(layersTree, filterPath.slice(0, -1).join('.'));
            // We need to display several layers
            if (filterKey.endsWith('*')) {
                const keyPrefix = filterKey.slice(0, -1);
                categoryLayers
                    .filter((layer) => {
                    const layerKey = getFilterKey(layer);
                    return layerKey.startsWith(keyPrefix);
                })
                    .forEach((layer) => {
                    const filterKey = getFilterKey(layer);
                    filters.layers[filterKey] = layer;
                });
            }
            else {
                // We need to display one layer
                const filter = categoryLayers?.find((layer) => {
                    const layerKey = getFilterKey(layer);
                    return layerKey === filterKey;
                });
                if (filter) {
                    const filterKey = getFilterKey(filter);
                    filters.layers[filterKey] = filter;
                }
            }
            if (!Object.keys(filters.layers).length) {
                filters.noMatch[filterKey] = true;
            }
            return;
        }
        // We need to display all layers of one or more categories
        const filterCategory = get(layersTree, filterPath.join('.'));
        if (!filterCategory ||
            (IOFilterCategory.is(filterCategory) &&
                filterCategory.layers?.length === 0)) {
            filters.noMatch[filterKey] = true;
        }
        filters.categories[filterKey] = filterCategory;
    });
    const hasNoMatch = Object.keys(filters.noMatch).length > 0;
    const hasLayers = Object.keys(filters.layers).length > 0;
    const hasCategories = Object.keys(filters.categories).length > 0;
    return (<>
      {hasNoMatch ? (<NoMatch filterKeys={Object.keys(filters.noMatch)}/>) : ((hasLayers || hasCategories) && (<LayerSwitch filters={filters} trackAction={`${plotLayer.category} ${plotLayer.name} onglet`} dataCy={plotLayer.name
                .toLowerCase()
                .split(' ')
                .filter((el) => !!el && el !== '-')
                .join('-') + '_switch'}/>))}
    </>);
};
export default PlotLayerSwitch;
