import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOEconomicalScotLayerItem = IOLayerItem;
const fetchEconomicalScot = (townId, townScot) => () => get(iots.array(IOEconomicalScotLayerItem), `legacy/${townId}/economical-scott/${townScot}`);
const useEconomicalScotLayer = () => {
    createLayersHook({
        fetch: fetchEconomicalScot,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.ECONOMICAL_SCOT);
};
const EconomicalScotLayer = () => {
    useEconomicalScotLayer();
    return null;
};
export default EconomicalScotLayer;
