import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Heading, useDisclosure, } from '@chakra-ui/react';
import { FROM_CONTACT, FROM_COURIER } from 'utils/constants';
import CourierForm from './CourierForm';
const CourierDrawer = ({ courierInfo, triggerItem, handleClose, fromPanel = FROM_COURIER, }) => {
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    React.useEffect(() => {
        if (courierInfo && !isOpen) {
            onOpen();
        }
    }, [courierInfo]);
    return (<>
      {triggerItem && triggerItem(onOpen)}
      <Drawer isOpen={isOpen} onClose={() => {
            onClose();
            handleClose();
        }} size="md" returnFocusOnClose={false}>
        <DrawerOverlay />
        <DrawerContent backgroundColor="colorMode.background900">
          <DrawerCloseButton />
          <DrawerHeader>
            <Heading as="h4" variant="h4">
              {fromPanel === FROM_CONTACT
            ? t(`courier.send_courier_contact`)
            : courierInfo?.id
                ? t(`courier.edit_courier`)
                : t(`courier.send_courier`)}
            </Heading>
          </DrawerHeader>
          <DrawerBody>
            <CourierForm courierInfo={courierInfo} onClose={() => {
            handleClose();
            onClose();
        }} fromPanel={fromPanel}/>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>);
};
export default CourierDrawer;
