import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Stack, Text } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_PLOT_TAB } from 'analytics/useAnalytics';
import { ManagerLink } from 'components/links/ManagerLink';
import { useUpdateExtraFilter } from 'hooks/contexts/useFiltersContext';
import { useToggleLayerTab } from 'hooks/contexts/useTabsContext';
import { filtersKey } from 'utils/filters';
import { formatDate } from 'utils/formatDate';
const StaffItem = ({ staff, index, onClick = () => { }, }) => {
    const { i18n } = useTranslation();
    const updateExtraFilter = useUpdateExtraFilter();
    const toggleLayerTab = useToggleLayerTab();
    const { trackEvent } = useAnalytics();
    return (<Stack width="100%" key={`owner-staff-${index}`} paddingY={1} spacing={0} direction="column">
      <Text textStyle="paragraph" fontWeight="bold">
        <Link variant="secondary" marginRight={2} onClick={() => {
            onClick();
            updateExtraFilter(`${filtersKey.COMPANY_OWNED_MANAGER}.manager`, staff);
            toggleLayerTab({
                tabId: filtersKey.COMPANY_OWNED_MANAGER,
            });
            trackEvent({
                category: ANALYTICS_PLOT_TAB,
                action: 'Ouverture sociétés du dirigeant',
            });
        }}>
          {staff.firstname} {staff.name}
        </Link>
        {staff.dateBirth && (<ManagerLink name={staff.name} firstname={staff.firstname} date={staff.dateBirth} analyticDetail="Pappers - Nom du dirigeant"/>)}
      </Text>
      <Text textStyle="paragraph">{staff.role}</Text>
      {staff.age && (<Text textStyle="paragraph">
          {staff.age} ans -{' '}
          {formatDate(staff.dateBirth, 'YYYY-MM', i18n.language)}
        </Text>)}
    </Stack>);
};
export default StaffItem;
