import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IODocUrbaLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOSensitiveNaturalSpaceLayerItem = IODocUrbaLayerItem;
const fetchSensitiveNaturalSpace = (townId) => () => get(iots.array(IOSensitiveNaturalSpaceLayerItem), `${townId}/sensitive-natural-space`);
const useSensitiveNaturalSpaceLayer = () => {
    createLayersHook({
        fetch: fetchSensitiveNaturalSpace,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.SENSITIVE_NATURAL_SPACE);
};
const SensitiveNaturalSpaceLayer = () => {
    useSensitiveNaturalSpaceLayer();
    return null;
};
export default SensitiveNaturalSpaceLayer;
