import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get, post } from 'api/client';
import useCustomToast from 'hooks/useCustomToast';
import useOnError from 'hooks/useOnError';
import { IOUserPlotsNews } from 'types/api';
const IOUserPlotsNewsMailSubscription = iots.type({
    subscribe: iots.boolean,
});
export const QUERY_KEY = ['user-plot-news'];
export const SUBSCRIPTION_QUERY_KEY = ['user-subscription-plot-news'];
const fetchUserPlotsNews = () => () => get(iots.array(IOUserPlotsNews), 'user-plots-news');
const fetchUserMailPlotsNewsSubscription = () => () => get(IOUserPlotsNewsMailSubscription, 'user/subscription/mail/plots-news');
const setUserMailPlotsNewsSubscriptionRequest = ({ subscribe }) => {
    const formData = new FormData();
    formData.append('subscribe', subscribe);
    return post(null, 'user/subscription/mail/plots-news', formData);
};
const useUserPlotsNews = () => {
    const { t } = useTranslation();
    const { getSuccessToast } = useCustomToast();
    const onErrorCallback = useOnError();
    const { data: userPlotsNews, isInitialLoading: isLoading } = useQuery({
        queryKey: QUERY_KEY,
        queryFn: fetchUserPlotsNews(),
    });
    const { data: userMailPlotsNewsSubscription, isLoading: isLoadingSubscription, } = useQuery({
        queryKey: SUBSCRIPTION_QUERY_KEY,
        queryFn: fetchUserMailPlotsNewsSubscription(),
    });
    const { mutate: setUserMailPlotsNewsSubscription } = useMutation({
        mutationFn: setUserMailPlotsNewsSubscriptionRequest,
        onError: (err) => onErrorCallback(err),
        onSuccess: (data, variables) => {
            getSuccessToast({
                title: variables.subscribe
                    ? t('tabs.plots_news.subscribe_confirm')
                    : t('tabs.plots_news.unsubscribe_confirm'),
                id: variables.subscribe
                    ? 'news_subscription_confirm'
                    : 'news_subscription_cancel_confirm',
            });
        },
    });
    return {
        userPlotsNews,
        isLoading,
        userMailPlotsNewsSubscription,
        isLoadingSubscription,
        setUserMailPlotsNewsSubscription,
    };
};
export default useUserPlotsNews;
