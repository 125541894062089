import * as React from 'react';
import { useTranslation } from 'react-i18next';
import useAnalytics, { ANALYTICS_OPEN } from 'analytics/useAnalytics';
import { ExternalLink } from 'components/links/ExternalLink';
const getLinkWhitePage = (address) => {
    if (address) {
        return `https://www.pagesjaunes.fr/pagesblanches/recherche?ou=${address}&univers=pagesblanches`;
    }
    else {
        return `https://www.pagesjaunes.fr/pagesblanches`;
    }
};
const PlotOwnerPhysicalDetail = ({ plot, }) => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    return (<ExternalLink url={getLinkWhitePage(encodeURI(plot.address[0]))} text={t('tabs.plots.section.owner.link_white_page')} onClick={() => {
            trackEvent({
                category: ANALYTICS_OPEN,
                action: 'Pages blanches',
            });
        }}/>);
};
export default PlotOwnerPhysicalDetail;
