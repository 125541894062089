import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { del, get, post } from 'api/client';
import { useUserData } from 'hooks/contexts/useAuthContext';
import useCustomToast from 'hooks/useCustomToast';
const fetchPlotsSirenSearch = () => get(null, 'user/siren');
const deletePlotsSirenSearch = (id) => del(null, `user/siren/${id}/delete`);
const addPlotsSirenSearch = (filter) => post(null, 'user/siren/create', filter);
const usePlotsSirenSearch = () => {
    const { t } = useTranslation();
    const userData = useUserData();
    const queryClient = useQueryClient();
    const { getSuccessToast, getErrorToast } = useCustomToast();
    const queryKey = ['plots-siren-search', userData?.email];
    const { data: savedSirens } = useQuery({
        queryKey,
        queryFn: fetchPlotsSirenSearch,
        enabled: !!userData && !!userData.email,
    });
    const { mutate: deleteSirens } = useMutation({
        mutationFn: deletePlotsSirenSearch,
        onError: () => {
            getErrorToast({
                title: t('global.error'),
                id: 'saved_search',
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKey });
            getSuccessToast({
                title: t('header.search_plot.delete_search_success'),
                id: 'delete_search_success',
            });
        },
    });
    const { mutate: addSirens } = useMutation({
        mutationFn: addPlotsSirenSearch,
        onError: () => {
            getErrorToast({
                title: t('global.error'),
                id: 'saved_search',
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKey });
            getSuccessToast({
                title: t('header.search_plot.save_search_success'),
                id: 'saved_search',
            });
        },
    });
    return { savedSirens, deleteSirens, addSirens };
};
export default usePlotsSirenSearch;
