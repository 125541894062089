import { useMutation, useQueryClient } from '@tanstack/react-query';
import { get } from 'api/client';
import { QUERY_KEY as NEWS_QUERY_KEY } from 'hooks/news/useNews';
import { QUERY_KEY as NEWS_COUNT_QUERY_KEY } from 'hooks/news/useNewsCount';
const useNewsAllRead = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: () => {
            return get(null, 'news/all-read');
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: NEWS_QUERY_KEY });
            queryClient.invalidateQueries({ queryKey: NEWS_COUNT_QUERY_KEY });
        },
    });
};
export default useNewsAllRead;
