import { useState } from 'react';
import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
import * as iots from 'io-ts';
import { get } from 'api/client';
import WasteInfoWindow, { IOWasteInfo, } from 'components/infoWindow/WasteInfoWindow';
import createLayersHook from 'hooks/layers/createLayersHook';
import DMAImg from 'images/POIImages/waste_dma.png';
import ISDDImg from 'images/POIImages/waste_isdd.png';
import ISDIImg from 'images/POIImages/waste_isdi.png';
import ISDNDImg from 'images/POIImages/waste_isdnd.png';
import { IOMarkerLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
const IOWasteMarkerItem = IOMarkerLayerItem;
const fetchWasteDMA = () => () => get(iots.array(IOWasteMarkerItem), `waste-dma`);
const fetchWasteISDD = () => () => get(iots.array(IOWasteMarkerItem), `waste-isdd`);
const fetchWasteISDI = () => () => get(iots.array(IOWasteMarkerItem), `waste-isdi`);
const fetchWasteISDND = () => () => get(iots.array(IOWasteMarkerItem), `waste-isdnd`);
const fetchWasteDMAInfo = (item) => {
    return get(IOWasteInfo, `waste-dma-info/${item.id}`);
};
const fetchWasteISDDInfo = (item) => {
    return get(IOWasteInfo, `waste-isdd-info/${item.id}`);
};
const fetchWasteISDIInfo = (item) => {
    return get(IOWasteInfo, `waste-isdi-info/${item.id}`);
};
const fetchWasteISDNDInfo = (item) => {
    return get(IOWasteInfo, `waste-isdnd-info/${item.id}`);
};
const LEGENDS_DMA = {
    waste_dma: {
        label: 'waste_dma',
        layerKey: filtersKey.WASTE_DMA,
        needsTranslate: true,
        image: DMAImg,
    },
};
const LEGENDS_ISDD = {
    waste_isdd: {
        label: 'waste_isdd',
        layerKey: filtersKey.WASTE_ISDD,
        needsTranslate: true,
        image: ISDDImg,
    },
};
const LEGENDS_ISDI = {
    waste_isdi: {
        label: 'waste_isdi',
        layerKey: filtersKey.WASTE_ISDI,
        needsTranslate: true,
        image: ISDIImg,
    },
};
const LEGENDS_ISDND = {
    waste_isdnd: {
        label: 'waste_isdnd',
        layerKey: filtersKey.WASTE_ISDND,
        needsTranslate: true,
        image: ISDNDImg,
    },
};
const formatInfoWindowContent = (info, filterKey) => {
    const content = <WasteInfoWindow info={info} filterKey={filterKey}/>;
    return ReactDOMServer.renderToStaticMarkup(content);
};
const useWasteLayer = (filterKey) => {
    const [markerInfo, setMarkerInfo] = useState({});
    const getWasteDMAInfoWindowContent = async (marker) => {
        if (markerInfo[marker.id]) {
            return markerInfo[marker.id];
        }
        const WasteInfo = await fetchWasteDMAInfo(marker);
        const content = formatInfoWindowContent(WasteInfo, filterKey);
        setMarkerInfo({
            ...markerInfo,
            [marker.id]: content,
        });
        return content;
    };
    const getWasteISDDInfoWindowContent = async (marker) => {
        if (markerInfo[marker.id]) {
            return markerInfo[marker.id];
        }
        const WasteInfo = await fetchWasteISDDInfo(marker);
        const content = formatInfoWindowContent(WasteInfo, filterKey);
        setMarkerInfo({
            ...markerInfo,
            [marker.id]: content,
        });
        return content;
    };
    const getWasteISDIInfoWindowContent = async (marker) => {
        if (markerInfo[marker.id]) {
            return markerInfo[marker.id];
        }
        const WasteInfo = await fetchWasteISDIInfo(marker);
        const content = formatInfoWindowContent(WasteInfo, filterKey);
        setMarkerInfo({
            ...markerInfo,
            [marker.id]: content,
        });
        return content;
    };
    const getWasteISDNDInfoWindowContent = async (marker) => {
        if (markerInfo[marker.id]) {
            return markerInfo[marker.id];
        }
        const WasteInfo = await fetchWasteISDNDInfo(marker);
        const content = formatInfoWindowContent(WasteInfo, filterKey);
        setMarkerInfo({
            ...markerInfo,
            [marker.id]: content,
        });
        return content;
    };
    switch (filterKey) {
        case filtersKey.WASTE_DMA:
            createLayersHook({
                fetch: fetchWasteDMA,
                markerImg: DMAImg,
                legends: LEGENDS_DMA,
                getInfoWindowContent: getWasteDMAInfoWindowContent,
            })(filterKey);
            break;
        case filtersKey.WASTE_ISDD:
            createLayersHook({
                fetch: fetchWasteISDD,
                markerImg: ISDDImg,
                legends: LEGENDS_ISDD,
                getInfoWindowContent: getWasteISDDInfoWindowContent,
            })(filterKey);
            break;
        case filtersKey.WASTE_ISDI:
            createLayersHook({
                fetch: fetchWasteISDI,
                markerImg: ISDIImg,
                legends: LEGENDS_ISDI,
                getInfoWindowContent: getWasteISDIInfoWindowContent,
            })(filterKey);
            break;
        case filtersKey.WASTE_ISDND:
            createLayersHook({
                fetch: fetchWasteISDND,
                markerImg: ISDNDImg,
                legends: LEGENDS_ISDND,
                getInfoWindowContent: getWasteISDNDInfoWindowContent,
            })(filterKey);
    }
};
const WasteLayer = ({ filterKey }) => {
    useWasteLayer(filterKey);
    return null;
};
export default WasteLayer;
