import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_CATEGORY_DOWNLOAD, ANALYTICS_MAP_BUTTONS, } from 'analytics/useAnalytics';
import { useIsFilterActive, useToggleFilter, } from 'hooks/contexts/useFiltersContext';
import { useSelectedTown, useTowns } from 'hooks/contexts/useLocationContext';
import { usePlots, useSelectedTab } from 'hooks/contexts/useTabsContext';
import { filtersKey } from 'utils/filters';
import { getPlotExportName, getTownExportName } from 'utils/plots';
import PopoverMenu from './PopoverMenu';
const OtherTool = ({ trackerPosition }) => {
    const { t } = useTranslation();
    const towns = useTowns();
    const selectedTown = useSelectedTown();
    const plots = usePlots();
    const selectedTab = useSelectedTab();
    const { trackEvent } = useAnalytics();
    const toggleFilter = useToggleFilter();
    const isFilterActive = useIsFilterActive();
    const getTitle = () => {
        let title = t('toolbar.print_title');
        if (selectedTab && plots[selectedTab]) {
            const plot = { ...plots[selectedTab] };
            title = getPlotExportName(plot, towns[plot.townId]?.name);
        }
        else if (selectedTown) {
            title = getTownExportName(selectedTown.name);
        }
        return title;
    };
    const print = () => {
        const titleTmp = document.title;
        document.title = getTitle();
        window.print();
        trackEvent({
            category: trackerPosition ?? ANALYTICS_CATEGORY_DOWNLOAD,
            action: 'Imprimer',
        });
        setTimeout(() => {
            document.title = titleTmp;
        }, 200);
    };
    return (<Box width="100%">
      <PopoverMenu id="otherTool" label={t('toolbar.other.title')} showCheck={true} width="100%" menuItems={[
            {
                label: t('toolbar.other.print'),
                handleClick: print,
            },
            {
                label: t('toolbar.other.level_curve'),
                isActive: isFilterActive(filtersKey.LEVEL_CURVE),
                handleClick: () => {
                    trackEvent({
                        category: ANALYTICS_MAP_BUTTONS,
                        action: 'Courbes  de niveau',
                    });
                    toggleFilter(filtersKey.LEVEL_CURVE);
                },
            },
        ]}/>
    </Box>);
};
export default OtherTool;
