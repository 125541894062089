import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOAdvertisingAreaLayerItem = IOLayerItem;
const fetchAdvertisingArea = (townId) => () => get(iots.array(IOAdvertisingAreaLayerItem), `legacy/${townId}/advertising-area`);
const useAdvertisingAreaLayer = () => {
    createLayersHook({
        fetch: fetchAdvertisingArea,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.ADVERTISING_AREA);
};
const AdvertisingAreaLayer = () => {
    useAdvertisingAreaLayer();
    return null;
};
export default AdvertisingAreaLayer;
