import * as React from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, Divider, Heading, HStack, Input, NumberInput, NumberInputField, Select, Spinner, Text, VStack, } from '@chakra-ui/react';
import { isUndefined } from 'lodash';
import InfoTooltip from 'components/tooltips/InfoTooltip';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import useRealEstateTaxCalculationInfo from 'hooks/useRealEstateTaxCalculationInfo';
import CheckIcon from 'images/icons/Check';
import { formatNumberToLocale } from 'utils/formatNumber';
import CalculatedData from './CalculatedData';
import InputWrapper from './InputWrapper';
const headingProps = {
    variant: 'h4',
    width: 'full',
    paddingTop: 5,
    fontWeight: 800,
};
const resolver = async (values) => {
    return {
        values: values,
        errors: {},
    };
};
const initialDefaultValues = {
    valueFlatRateParking: 3000,
    numberOutdoorParkings: null,
    communalTax: null,
    departmentalTax: null,
    regionalTax: null,
    IDFValue: null,
    mainResidenceSurface: null,
    socialHousingExceptPLAISurface: null,
    PLAIAndLLTSHousingSurface: null,
    secondResidenceSurface: null,
    numberMainResidence: null,
    numberCampingSites: null,
    numberLightweightHousing: null,
    swimmingPoolSurface: null,
    photovoltaicPanelsSurface: null,
    numberWindTurbines: null,
    officeSurface: null,
    commercialSurface: null,
    industrialPremisesSurface: null,
    storeSurface: null,
    priceNaturalGaz: 0, //1213.04,
    selectPFACType: null,
    amountPFACHousing: null,
    amountPFACOffices: null,
    zoneZAC_PUP_OIN: [],
};
const Form = ({ defaultValues, getDataToExport, }) => {
    const { t, i18n } = useTranslation();
    const selectedTown = useSelectedTown();
    const { data, isInitialLoading: isLoading } = useRealEstateTaxCalculationInfo(selectedTown?.id);
    const creatingOfficeTax = data?.creatingOfficeTax;
    const creatingCommerceTax = data?.creatingCommerceTax;
    const creatingWarehouseTax = data?.creatingWarehouseTax;
    const townIDF = selectedTown?.id.match('^(75|77|78|91|92|93|94|95)');
    const { register, watch, reset, setValue } = useForm({
        defaultValues: initialDefaultValues,
        resolver,
    });
    useEffect(() => {
        if (data) {
            const initialCommunalTax = data?.communalTaxesByTown && data?.communalTaxesByTown.length > 0
                ? data?.communalTaxesByTown[0].tax
                : data?.communalTax;
            reset({
                ...initialDefaultValues,
                ...defaultValues,
                IDFValue: data?.IDFValue,
                communalTax: initialCommunalTax,
                departmentalTax: data?.departmentTax,
                regionalTax: data?.regionalTax || null,
                amountPFACHousing: data?.PFACHousingValue,
                amountPFACOffices: data?.PFACOfficesValue,
                selectPFACType: data?.PFACTypeDefaultValue,
            });
        }
    }, [data]);
    const { valueFlatRateParking, numberOutdoorParkings, communalTax, departmentalTax, regionalTax, IDFValue, mainResidenceSurface, socialHousingExceptPLAISurface, PLAIAndLLTSHousingSurface, secondResidenceSurface, numberMainResidence, numberCampingSites, numberLightweightHousing, swimmingPoolSurface, photovoltaicPanelsSurface, numberWindTurbines, officeSurface, commercialSurface, industrialPremisesSurface, storeSurface, priceNaturalGaz, selectPFACType, amountPFACHousing, amountPFACOffices, zoneZAC_PUP_OIN, } = watch();
    const formatNumber = (value) => {
        const roundedValue = Math.round(value);
        return isUndefined(roundedValue) || isNaN(roundedValue)
            ? '-'
            : formatNumberToLocale(roundedValue, i18n.language);
    };
    const CAMPING_SITES_FORFAIT = 3000;
    const LIGHTWEIGHT_HOUSING_FORFAIT = 10000;
    const SWIMMING_POOL_FORFAIT = 250;
    const PHOTOVOLTAIC_PANELS_FORFAIT = 10;
    const WIND_TURBINES_FORFAIT = 3000;
    // ZAC PUP OIN
    const withCommunalTax = zoneZAC_PUP_OIN.length > 0 ? 0 : 1;
    // Taxe
    const totalDevelopmentTax = (communalTax * withCommunalTax || 0) +
        (departmentalTax || 0) +
        (regionalTax || 0);
    // PARKING TAXES CALCULATIONS
    const flatRateParking = valueFlatRateParking * (numberOutdoorParkings || 0);
    const communalSubtotalParkings = flatRateParking * ((communalTax * withCommunalTax || 0) / 100);
    const departmentalSubtotalParkings = flatRateParking * ((departmentalTax || 0) / 100);
    const regionalSubtotalParkings = flatRateParking * ((regionalTax || 0) / 100);
    const totalTaxOutdoorParkings = communalSubtotalParkings +
        departmentalSubtotalParkings +
        regionalSubtotalParkings;
    // ADMINISTRATIVE TAXABLE AREA CALCULATIONS
    const administrativeTaxableSurface = (mainResidenceSurface || 0) +
        (socialHousingExceptPLAISurface || 0) +
        (PLAIAndLLTSHousingSurface || 0) +
        (secondResidenceSurface || 0);
    // AVERAGE HOUSING SIZE CALCULATIONS
    const averageHousingSize = numberMainResidence
        ? (mainResidenceSurface || 0) / (numberMainResidence || 0)
        : 0;
    const getWeighting = (weightBase) => {
        return weightBase > 100 ? weightBase - 50 : weightBase / 2;
    };
    const getSubtotalHousing = (weighting, realEstateTax) => {
        return Math.round(((weighting * realEstateTax) / 100) *
            (numberMainResidence || 0) *
            IDFValue);
    };
    // COMMUNAL HOUSING SUBTOTAL CALCULATIONS
    const weightingCommunal = getWeighting(numberMainResidence
        ? (mainResidenceSurface || 0) / (numberMainResidence || 0)
        : 0);
    const communalSubtotalHousing = getSubtotalHousing(weightingCommunal, communalTax * withCommunalTax || 0);
    // DEPT HOUSING SUBTOTAL CALCULATIONS
    const departmentalSubtotalHousing = getSubtotalHousing(getWeighting(averageHousingSize), departmentalTax || 0);
    // REGIONAL HOUSING SUBTOTAL CALCULATIONS
    const regionalSubtotalHousing = getSubtotalHousing(getWeighting(averageHousingSize), regionalTax || 0);
    // TOTAL TAX MAIN RESIDENCE CALCULATIONS
    const totalTaxMainResidence = communalSubtotalHousing +
        departmentalSubtotalHousing +
        regionalSubtotalHousing;
    // TOTAL TAX SOCIAL HOUSING CALCULATIONS
    const totalTaxSocialHousing = Math.round((socialHousingExceptPLAISurface || 0) *
        0.5 *
        (totalDevelopmentTax / 100) *
        IDFValue);
    // TOTAL TAX SECONDARY RESIDENCE CALCULATIONS
    const totalTaxSecondResidence = Math.round((secondResidenceSurface || 0) * (totalDevelopmentTax / 100) * IDFValue);
    // TOTAL TAX CALCULATIONS
    const totalTax = totalTaxMainResidence + totalTaxSocialHousing + totalTaxSecondResidence;
    //  FACILITIES TAX CALCULATIONS
    const campingSitesTax = (numberCampingSites || 0) * CAMPING_SITES_FORFAIT;
    const lightweightTax = (numberLightweightHousing || 0) * LIGHTWEIGHT_HOUSING_FORFAIT;
    const swimmingPoolTax = (swimmingPoolSurface || 0) * SWIMMING_POOL_FORFAIT;
    const photovoltaicPanelsTax = (photovoltaicPanelsSurface || 0) * PHOTOVOLTAIC_PANELS_FORFAIT;
    const windTurbinesTax = (numberWindTurbines || 0) * WIND_TURBINES_FORFAIT;
    const getFacilitiesTotalTax = (tax) => {
        return Math.round((campingSitesTax +
            lightweightTax +
            swimmingPoolTax +
            photovoltaicPanelsTax +
            windTurbinesTax) *
            (tax / 100));
    };
    const communalSubtotalFacilities = getFacilitiesTotalTax(communalTax * withCommunalTax || 0);
    const departmentalSubtotalFacilities = getFacilitiesTotalTax(departmentalTax || 0);
    const regionalSubtotalFacilities = getFacilitiesTotalTax(regionalTax || 0);
    const totalRealEstateTaxFacilities = communalSubtotalFacilities +
        departmentalSubtotalFacilities +
        regionalSubtotalFacilities;
    // ARCHEOLOGICAL FEES CALCULATIONS
    const archaeologicalFeeMainResidence = Math.round(getWeighting(averageHousingSize) *
        0.004 *
        (numberMainResidence || 0) *
        IDFValue);
    const archaeologicalFeeSocialHousing = Math.round((socialHousingExceptPLAISurface || 0) * 0.5 * 0.004 * IDFValue);
    const archaeologicalFeeSecondResidence = Math.round((secondResidenceSurface || 0) * 0.004 * IDFValue);
    const totalArchaeologicalFeeHousing = (archaeologicalFeeMainResidence || 0) +
        (archaeologicalFeeSocialHousing || 0) +
        (archaeologicalFeeSecondResidence || 0);
    const archaeologicalFeeOffices = Math.round((officeSurface || 0) * 0.004 * IDFValue);
    const archaeologicalFeeCommercial = Math.round((commercialSurface || 0) * 0.004 * IDFValue);
    const archaeologicalFeeIndustrial = Math.round((industrialPremisesSurface || 0) * 0.004 * IDFValue * 0.5);
    const archaeologicalFeeStore = Math.round((storeSurface || 0) * 0.004 * IDFValue * 0.5);
    const archaeologicalFeeOfficesCommercial = archaeologicalFeeOffices +
        archaeologicalFeeCommercial +
        archaeologicalFeeIndustrial +
        archaeologicalFeeStore;
    // OFFICES SUBTOTALS CALCULATIONS
    const getOfficesSubtotal = (tax) => {
        return Math.round((((officeSurface || 0) * tax) / 100) * IDFValue);
    };
    const communalSubtotalOffices = getOfficesSubtotal(communalTax * withCommunalTax || 0);
    const departmentalSubtotalOffices = getOfficesSubtotal(departmentalTax || 0);
    const regionalSubtotalOffices = getOfficesSubtotal(regionalTax || 0);
    const totalOfficeRealEstateTax = communalSubtotalOffices +
        departmentalSubtotalOffices +
        regionalSubtotalOffices;
    // RETAIL SUBTOTALS CALCULATIONS
    const getRetailSubtotal = (tax) => {
        return Math.round((((commercialSurface || 0) * tax) / 100) * IDFValue);
    };
    const communalSubtotalRetail = getRetailSubtotal(communalTax * withCommunalTax || 0);
    const departmentalSubtotalRetail = getRetailSubtotal(departmentalTax || 0);
    const regionalSubtotalRetail = getRetailSubtotal(regionalTax || 0);
    const totalRetailRealEstateTax = communalSubtotalRetail + departmentalSubtotalRetail + regionalSubtotalRetail;
    // OTHER SUBTOTALS CALCULATIONS
    const totalIndustrialPremises = Math.round((industrialPremisesSurface || 0) *
        0.5 *
        (totalDevelopmentTax / 100) *
        IDFValue);
    // STORE SUBTOTALS CALCULATIONS
    const totalStore = Math.round((storeSurface || 0) * 0.5 * (totalDevelopmentTax / 100) * IDFValue);
    const totalOfficeCommercialOtherRealEstateTax = totalOfficeRealEstateTax +
        totalRetailRealEstateTax +
        totalIndustrialPremises +
        totalStore;
    // TCB-IDF CALCULATIONS
    const taxForOfficesCreation = () => {
        if (townIDF) {
            return (Math.round((officeSurface || 0) * creatingOfficeTax) +
                Math.round((commercialSurface || 0) * creatingCommerceTax) +
                Math.round((storeSurface || 0) * creatingWarehouseTax));
        }
        else {
            return 0;
        }
    };
    // PRICE NATURAL GAZ CALCULATIONS
    const totalPriceNaturalGaz = (mainResidenceSurface || 0) +
        (socialHousingExceptPLAISurface || 0) +
        (secondResidenceSurface || 0) +
        (PLAIAndLLTSHousingSurface || 0)
        ? 0
        : priceNaturalGaz;
    // PFAC CALCULATIONS
    const totalPFACHousing = (communalTax * withCommunalTax || 0) > 5
        ? 0
        : selectPFACType === 1
            ? Math.round((amountPFACHousing || 0) * (numberMainResidence || 0))
            : Math.round((amountPFACHousing || 0) * administrativeTaxableSurface);
    const totalPFACOfficesTradeCraft = (communalTax * withCommunalTax || 0) > 5
        ? 0
        : Math.round((amountPFACOffices || 0) *
            ((storeSurface || 0) +
                (commercialSurface || 0) +
                (industrialPremisesSurface || 0)));
    // TOTALS CALCULATIONS
    const projectTotalPrice = totalTax +
        totalTaxOutdoorParkings +
        totalRealEstateTaxFacilities +
        totalArchaeologicalFeeHousing +
        archaeologicalFeeOffices +
        archaeologicalFeeCommercial +
        totalOfficeRealEstateTax +
        totalRetailRealEstateTax +
        totalIndustrialPremises +
        totalStore +
        taxForOfficesCreation() +
        totalPriceNaturalGaz +
        totalPFACHousing +
        totalPFACOfficesTradeCraft;
    const allSurface = Math.round((mainResidenceSurface || 0) +
        (socialHousingExceptPLAISurface || 0) +
        (storeSurface || 0) +
        (commercialSurface || 0) +
        (industrialPremisesSurface || 0));
    const totalPerSquareMeters = allSurface !== 0 && Math.round(projectTotalPrice / allSurface);
    useEffect(() => {
        getDataToExport({
            project_total_price: `${formatNumber(projectTotalPrice)} €`,
            total_per_square_meters: `${formatNumber(totalPerSquareMeters)} €/m²`,
            number_main_residence: numberMainResidence || 0,
            main_residence_surface: `${mainResidenceSurface || 0} m²`,
            social_housing_except_PLAI_surface: `${socialHousingExceptPLAISurface || 0} m²`,
            PLAI_and_LLTS_housing_surface: `${PLAIAndLLTSHousingSurface || 0} m²`,
            second_residence_surface: `${secondResidenceSurface || 0} m²`,
            zone_zac_pup_oin: !withCommunalTax ? t('global.yes') : t('global.no'),
            total_tax: `${totalTax || 0} €`,
            total_archaeological_fee_housing: `${totalArchaeologicalFeeHousing || 0} €`,
            number_outdoor_parkings: numberOutdoorParkings || 0,
            value_flat_rate_parking: `${valueFlatRateParking || 0} €`,
            total_tax_integrate_parkings: `0 €`,
            total_tax_outdoor_parkings: `${totalTaxOutdoorParkings || 0} €`,
            communal_tax: `${communalTax || 0} %`,
            departmental_tax: `${departmentalTax || 0} %`,
            regional_tax: `${regionalTax || 0} %`,
            IDF_value: `${IDFValue || 0} €`,
            office_surface: `${officeSurface || 0} m²`,
            commercial_surface: `${commercialSurface || 0} m²`,
            industrial_premises_surface: `${industrialPremisesSurface || 0} m²`,
            store_surface: `${storeSurface || 0} m²`,
            zone_zac_pup_oin2: !withCommunalTax ? t('global.yes') : t('global.no'),
            total_real_estate_tax: `${formatNumber(totalOfficeCommercialOtherRealEstateTax)} €`,
            archaeological_fee: `${formatNumber(archaeologicalFeeOfficesCommercial)} €`,
            tax_for_offices_creation: `${formatNumber(taxForOfficesCreation())} €`,
            swimming_pool_surface: `${swimmingPoolSurface || 0} m²`,
            photovoltaic_panels_surface: `${photovoltaicPanelsSurface || 0} m²`,
            number_camping_sites: numberCampingSites || 0,
            number_lightweight_housing: numberLightweightHousing || 0,
            number_wind_turbines: numberWindTurbines || 0,
            total_real_estate_tax_facilities: `${formatNumber(totalRealEstateTaxFacilities)} €`,
            amount_PFAC_housing: `${amountPFACHousing || 0} € ${selectPFACType === 1
                ? t('modals.real_estate_tax.by_housing')
                : t('modals.real_estate_tax.by_squared_meter')}`,
            total_PFAC_housing: `${formatNumber(totalPFACHousing)} €`,
            amount_PFAC_offices_trade_craft_per_squared_meters: `${amountPFACOffices || 0} €`,
            total_PFAC_offices_trade_craft: `${formatNumber(totalPFACOfficesTradeCraft)} €`,
        });
    }, [JSON.stringify(watch())]);
    if (isLoading) {
        return (<Box>
        <Spinner />
      </Box>);
    }
    return (<form>
      <VStack paddingBottom={5} alignItems="flex-start">
        <>
          {/* calc_final_total */}
          <CalculatedData label="project_total_price" value={Math.round(projectTotalPrice)}/>
          {/* calc_final_total_m2 */}
          <CalculatedData label="total_per_square_meters" currency="€/m²" value={Math.round(totalPerSquareMeters)}/>
        </>

        {/* Logements (principal, social, secondaire) */}
        <>
          <Divider />
          <Heading as="h4" {...headingProps}>
            {t('modals.real_estate_tax.main_residence')}
          </Heading>
          {/* in_nb_logement_res_princ */}
          <InputWrapper label="number_main_residence" measureUnit="">
            <Input type="number" textAlign="right" placeholder="0" {...register('numberMainResidence', {
        valueAsNumber: true,
    })}/>
          </InputWrapper>
          <HStack width="full">
            <Text as="div" textStyle="paragraph" fontWeight={700}>
              {t('modals.real_estate_tax.taxable_surface_housing')}
            </Text>
          </HStack>
          {/* in_surf_logement_res_princ */}
          <InputWrapper label="main_residence_surface">
            <Input type="number" textAlign="right" placeholder="0" {...register('mainResidenceSurface', {
        valueAsNumber: true,
    })}/>
          </InputWrapper>
          {/* in_surf_logement_soc */}
          <InputWrapper label="social_housing_except_PLAI_surface">
            <Input textAlign="right" placeholder="0" type="number" {...register('socialHousingExceptPLAISurface', {
        valueAsNumber: true,
    })}/>
          </InputWrapper>
          {/* in_surf_logement_plai */}
          <InputWrapper label="PLAI_and_LLTS_housing_surface" tooltip={<InfoTooltip label={'modals.real_estate_tax.tooltip_PLAI_LLTS'}/>}>
            <Input textAlign="right" placeholder="0" type="number" {...register('PLAIAndLLTSHousingSurface', {
        valueAsNumber: true,
    })}/>
          </InputWrapper>
          {/* in_surf_logement_res_sec */}
          <InputWrapper label="second_residence_surface">
            <Input textAlign="right" placeholder="0" type="number" {...register('secondResidenceSurface', {
        valueAsNumber: true,
    })}/>
          </InputWrapper>

          {/* Zone ZAC, PUP ou OIN */}
          <InputWrapper label="zone_zac_pup_oin" measureUnit="">
            <Box display="flex" justifyContent="flex-end" width="full">
              <Checkbox icon={<CheckIcon />} size="lg" value={1} {...register('zoneZAC_PUP_OIN')}/>
            </Box>
          </InputWrapper>

          {/* calc_tax_logement */}
          <CalculatedData label="total_tax" value={Math.round(totalTax)}/>
          {/* calc_redarcheo_logement_total */}
          <CalculatedData label="total_archaeological_fee_housing" value={Math.round(totalArchaeologicalFeeHousing)}/>
          <HStack width="full">
            <Text textStyle="small">
              {t('modals.real_estate_tax.taxable_surface_info')}
            </Text>
          </HStack>
          <HStack width="full">
            <Text textStyle="small">
              {t('modals.real_estate_tax.exoneration_housing')}
            </Text>
          </HStack>
          <HStack width="full">
            <Text textStyle="small">
              {t('modals.real_estate_tax.zone_zac_pup_oin_info')}
            </Text>
          </HStack>
        </>

        {/* Parkings en extérieur non intégrés au bâtis* */}
        <>
          <Divider />
          <Heading as="h4" {...headingProps}>
            {t('modals.real_estate_tax.outdoor_parkings')}
          </Heading>
          {/* in_nb_park_ss_sol */}
          <InputWrapper label="number_outdoor_parkings" measureUnit="">
            <Input textAlign="right" placeholder="0" type="number" {...register('numberOutdoorParkings', { valueAsNumber: true })}/>
          </InputWrapper>
          {/* value_flat_rate_parking */}
          <InputWrapper label="value_flat_rate_parking" measureUnit="€">
            <NumberInput min={2500} max={6000}>
              <NumberInputField textAlign="right" color="colorMode.font900" backgroundColor="colorMode.base" {...register('valueFlatRateParking', { valueAsNumber: true })}/>
            </NumberInput>
          </InputWrapper>
          {/* calc_ta_logement_parking_integrate_built */}
          <CalculatedData label="total_tax_integrate_parkings" value={0} bold={false}/>
          {/* calc_ta_logement_parking */}
          <CalculatedData label="total_tax_outdoor_parkings" value={Math.round(totalTaxOutdoorParkings)}/>
          <HStack>
            <Text textStyle="small">
              {t('modals.real_estate_tax.info_tax')}
            </Text>
          </HStack>
        </>

        {/* Taux officiels en vigueur */}
        <>
          <Divider />
          <HStack width="full" paddingTop={5} justifyContent="space-between">
            <Heading as="h4" variant="h4" fontWeight={800}>
              {t('modals.real_estate_tax.official_rates')}
            </Heading>
            {data?.communalTaxesByTown && (<Text textStyle="paragraph">
                {t('modals.real_estate_tax.type_rate')}
              </Text>)}
          </HStack>
          {/* param_ta_comm */}
          <InputWrapper label="communal_tax" measureUnit="%" extraInput={data?.communalTaxesByTown && (<Select size="sm" value={communalTax || ''} onChange={(event) => setValue('communalTax', parseInt(event.target.value))}>
                  {data?.communalTaxesByTown &&
                data.communalTaxesByTown.map(({ name, tax }) => (<option key={name} value={tax}>
                        {name}
                      </option>))}
                </Select>)}>
            <Input textAlign="right" placeholder="0" type="number" {...register('communalTax', { valueAsNumber: true })}/>
          </InputWrapper>
          {/* param_ta_dept */}
          <InputWrapper label="departmental_tax" measureUnit="%">
            <Input textAlign="right" placeholder="0" type="number" {...register('departmentalTax', { valueAsNumber: true })}/>
          </InputWrapper>
          {/* param_ta_reg */}
          <InputWrapper label="regional_tax" measureUnit="%">
            <Input textAlign="right" placeholder="0" type="number" {...register('regionalTax', { valueAsNumber: true })}/>
          </InputWrapper>
          {/* param_val_forfait */}
          <InputWrapper label="IDF_value" labelParams={{
            link: 'https://entreprendre.service-public.fr/vosdroits/F23263',
        }} measureUnit="€">
            <Input textAlign="right" placeholder="0" type="number" {...register('IDFValue', { valueAsNumber: true })}/>
          </InputWrapper>
        </>

        {/* Bureaux, commerce et tertiaire */}
        <>
          <Divider />
          <Heading as="h4" {...headingProps}>
            {t('modals.real_estate_tax.offices_tertiary')}
          </Heading>
          <HStack width="full">
            <Text as="div" textStyle="paragraph">
              {t('modals.real_estate_tax.offices_tertiary_tax')}
            </Text>
          </HStack>
          {/* in_surf_bureau */}
          <InputWrapper label="office_surface">
            <Input textAlign="right" placeholder="0" type="number" {...register('officeSurface', { valueAsNumber: true })}/>
          </InputWrapper>
          {/* in_surf_comm */}
          <InputWrapper label="commercial_surface">
            <Input textAlign="right" placeholder="0" type="number" {...register('commercialSurface', { valueAsNumber: true })}/>
          </InputWrapper>
          {/* in_surf_autre */}
          <InputWrapper label="industrial_premises_surface">
            <Input textAlign="right" placeholder="0" type="number" {...register('industrialPremisesSurface', {
        valueAsNumber: true,
    })}/>
          </InputWrapper>
          {/* in_surf_store */}
          <InputWrapper label="store_surface">
            <Input textAlign="right" placeholder="0" type="number" {...register('storeSurface', {
        valueAsNumber: true,
    })}/>
          </InputWrapper>
          {/* Zone ZAC, PUP ou OIN */}
          <InputWrapper label="zone_zac_pup_oin2" measureUnit="">
            <Box display="flex" justifyContent="flex-end" width="full">
              <Checkbox icon={<CheckIcon />} size="lg" justifyContent="flex-end" value={1} {...register('zoneZAC_PUP_OIN')}/>
            </Box>
          </InputWrapper>
          {/* calc_ta_bureau_total */}
          <CalculatedData label="total_real_estate_tax" value={Math.round(totalOfficeCommercialOtherRealEstateTax)}/>
          {/* calc_redarcheo_bureau_total */}
          <CalculatedData label="archaeological_fee" value={Math.round(archaeologicalFeeOfficesCommercial)}/>
          {/* calc_tcb_idf_total */}
          <CalculatedData label="tax_for_offices_creation" labelParams={{
            link: 'https://entreprendre.service-public.fr/vosdroits/F23258',
        }} value={Math.round(taxForOfficesCreation())}/>
          <HStack width="full">
            <Text textStyle="small">
              {t('modals.real_estate_tax.taxable_surface_info')}
            </Text>
          </HStack>
          <HStack width="full">
            <Text textStyle="small">
              {t('modals.real_estate_tax.zone_zac_pup_oin2_info')}
            </Text>
          </HStack>
        </>

        <>
          <Divider />
          <Heading as="h4" {...headingProps}>
            {t('modals.real_estate_tax.add_facilities')}
          </Heading>
          {/* in_surf_piscine */}
          <InputWrapper label="swimming_pool_surface">
            <Input textAlign="right" placeholder="0" type="number" {...register('swimmingPoolSurface', { valueAsNumber: true })}/>
          </InputWrapper>
          {/* in_surf_photovolta */}
          <InputWrapper label="photovoltaic_panels_surface">
            <Input textAlign="right" placeholder="0" type="number" {...register('photovoltaicPanelsSurface', {
        valueAsNumber: true,
    })}/>
          </InputWrapper>
          {/* in_nb_emplacement_camp */}
          <InputWrapper label="number_camping_sites" measureUnit="">
            <Input textAlign="right" placeholder="0" type="number" {...register('numberCampingSites', { valueAsNumber: true })}/>
          </InputWrapper>
          {/* in_nb_emplacement_hableglois */}
          <InputWrapper label="number_lightweight_housing" measureUnit="">
            <Input textAlign="right" placeholder="0" type="number" {...register('numberLightweightHousing', { valueAsNumber: true })}/>
          </InputWrapper>
          {/* calc_nb_eol */}
          <InputWrapper label="number_wind_turbines" measureUnit="">
            <Input textAlign="right" placeholder="0" type="number" {...register('numberWindTurbines', { valueAsNumber: true })}/>
          </InputWrapper>
          {/* calc_ta_installation_total */}
          <CalculatedData label="total_real_estate_tax_facilities" value={Math.round(totalRealEstateTaxFacilities)}/>
          {/* calc_gaz_total
        <CalculatedData
          label="total_price_natural_gaz"
          value={Math.round(totalPriceNaturalGaz)}
        >
          <Select
            size="sm"
            {...register('priceNaturalGaz', { valueAsNumber: true })}
          >
            {[
              { label: 'forfait 1 : 822,25', value: 822.25 },
              { label: 'forfait 2 : 365,46', value: 365.46 },
              { label: 'forfait 3 : 1213,04', value: 1213.04 },
            ].map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        </CalculatedData>
        */}
        </>

        <>
          <Divider />
          <Heading as="h4" {...headingProps}>
            {t('modals.real_estate_tax.collective_sanitation_financing_participation')}
          </Heading>
          {/* param_pfac_hab */}
          <InputWrapper label="amount_PFAC_housing" measureUnit="€" extraInput={<Select size="sm" {...register('selectPFACType', { valueAsNumber: true })}>
                {[
                { key: 'by_housing', value: 1 },
                { key: 'by_squared_meter', value: 2 },
            ].map(({ key, value }) => (<option key={key} value={value}>
                    {t('modals.real_estate_tax.' + key)}
                  </option>))}
              </Select>}>
            <Input textAlign="right" placeholder="0" type="number" {...register('amountPFACHousing', { valueAsNumber: true })}/>
          </InputWrapper>
          {/* calc_pfac_log_total */}
          <CalculatedData label="total_PFAC_housing" value={Math.round(totalPFACHousing)}/>
          {/* param_pfac_asm */}
          <InputWrapper label="amount_PFAC_offices_trade_craft_per_squared_meters" measureUnit="€">
            <Input textAlign="right" placeholder="0" type="number" {...register('amountPFACOffices', { valueAsNumber: true })}/>
          </InputWrapper>
          {/* calc_pfac_asm_total */}
          <CalculatedData label="total_PFAC_offices_trade_craft" value={Math.round(totalPFACOfficesTradeCraft)}/>
          <HStack width="full">
            <Text textStyle="small">
              {t('modals.real_estate_tax.PFAC_not_due')}
            </Text>
          </HStack>
        </>
      </VStack>
    </form>);
};
export default Form;
