import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOWoodlandStripLayerItem = IOLayerItem;
const fetchWoodlandStrip = (townId) => () => get(iots.array(IOWoodlandStripLayerItem), `legacy/${townId}/woodland-strip`);
const useWoodlandStripLayer = () => {
    createLayersHook({
        fetch: fetchWoodlandStrip,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.WOODLAND_STRIP);
};
const WoodlandStripLayer = () => {
    useWoodlandStripLayer();
    return null;
};
export default WoodlandStripLayer;
