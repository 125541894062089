import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
import useCurrentSite from 'hooks/useCurrentSite';
import { IOPlotLayer } from 'types/api';
const fetchPlotLayersById = (townshipId, plotId, signal, extraParams = {}) => get(iots.array(IOPlotLayer), `plot-layers/${townshipId}/${plotId}`, extraParams, false, { signal }, (error) => error?.response?.status !== 404);
const usePlotLayersById = ({ plot, enabled, selectedTown, successCallback, }) => {
    const { currentSite } = useCurrentSite();
    const siteId = currentSite?.legacyId;
    const currentTown = selectedTown ? selectedTown : null;
    const { data, error, ...rest } = useQuery({
        queryKey: ['plot-layers-by-id', plot?.id, siteId],
        queryFn: ({ signal }) => fetchPlotLayersById(currentTown?.id, plot?.id, signal, {
            siteId,
        }),
        enabled,
        retry: false,
        meta: {
            onSuccess: (data) => {
                if (successCallback) {
                    successCallback(data);
                }
            },
        },
    });
    return { data, error, ...rest };
};
export default usePlotLayersById;
