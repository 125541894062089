import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOANRUExtendsLayerItem = IOLayerItem;
const fetchANRUExtends = (townId) => () => get(iots.array(IOANRUExtendsLayerItem), `legacy/${townId}/anru-extends`);
const useANRUExtendsLayer = () => {
    createLayersHook({
        fetch: fetchANRUExtends,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.ANRU_EXTENDS);
};
const ANRUExtendsLayer = () => {
    useANRUExtendsLayer();
    return null;
};
export default ANRUExtendsLayer;
