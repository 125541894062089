import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getLayerCoordTileUrl } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = () => (coord, zoom) => {
    return getLayerCoordTileUrl('ELEVATION.CONTOUR.LINE', 'normal', zoom, coord);
};
const useLevelCurveLayer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.LEVEL_CURVE],
    })(filtersKey.LEVEL_CURVE);
};
const LevelCurveLayer = () => {
    useLevelCurveLayer();
    return null;
};
export default LevelCurveLayer;
