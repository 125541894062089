import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Text, Wrap, WrapItem } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_COURIER, ANALYTICS_IMPORT_PUBLIPOSTAGE, } from 'analytics/useAnalytics';
import DeleteButton from 'components/buttons/DeleteButton';
import PublipostageDrawer from 'components/modals/publipostageModal/PublipostageDrawer';
import CourierCreateButton from 'components/tabs/couriers/CourierCreateButton';
import { useTabsView } from 'hooks/contexts/useLayoutContext';
import useCourier from 'hooks/courier/useCourier';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { FROM_COURIER } from 'utils/constants';
const CourierHeaderButtons = ({ courierInfoDefault = null, analyticsDetail, setCourierInfo, couriersInfo, setCouriersCredits, couriersIds, setCouriersIds, setInitialSelect, noCouriersSelected, fromPanel = FROM_COURIER, }) => {
    const tabsView = useTabsView();
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const { hasFeature } = useSiteConfiguration();
    const { deleteCouriers, isDeleteCouriers, getCourierFiles, isDownloadingFiles, } = useCourier({
        enabledList: true,
    });
    const handleSendCouriers = useCallback(() => {
        setCouriersCredits(couriersInfo.reduce((acc, curr) => acc + curr.credits, 0));
    }, [couriersInfo]);
    return (<Wrap spacing={2}>
      {courierInfoDefault && (<WrapItem>
          <CourierCreateButton size="sm" courierInfoDefault={courierInfoDefault} setCourierInfo={setCourierInfo} analyticsCategory={ANALYTICS_COURIER} analyticsDetail={analyticsDetail} fromPanel={fromPanel}/>
        </WrapItem>)}
      {hasFeature('publipostage') && fromPanel === FROM_COURIER && (<WrapItem>
          <PublipostageDrawer triggerItem={(onOpen) => (<Button variant="outline" size="sm" onClick={() => {
                    onOpen();
                    trackEvent({
                        category: ANALYTICS_IMPORT_PUBLIPOSTAGE,
                        action: 'Courrier importer publipostage',
                    });
                }}>
                {t(`tabs.courier.publipostage`)}
              </Button>)}/>
        </WrapItem>)}
      <WrapItem>
        <Button size="sm" disabled={noCouriersSelected || isDownloadingFiles} variant="outline" onClick={() => getCourierFiles({ courierIds: couriersIds })}>
          <Text as="span" color="inherit">
            {tabsView === 'half_vertical'
            ? t('tabs.couriers.download', {
                count: !noCouriersSelected ? couriersIds.length : 0,
            })
            : t('tabs.couriers.download_files', {
                count: !noCouriersSelected ? couriersIds.length : 0,
            })}
          </Text>
        </Button>
      </WrapItem>
      <WrapItem>
        <Button size="sm" variant="outline" onClick={handleSendCouriers} disabled={noCouriersSelected || couriersInfo?.length <= 0}>
          <Text as="span" color="inherit">
            {tabsView === 'half_vertical'
            ? t('tabs.couriers.send', {
                count: !noCouriersSelected ? couriersIds.length : 0,
            })
            : t('tabs.couriers.send_button', {
                count: !noCouriersSelected ? couriersIds.length : 0,
            })}
          </Text>
        </Button>
      </WrapItem>
      <WrapItem>
        <DeleteButton id="my_couriers_delete_button" size="sm" variant="outlineDelete" handleDelete={() => deleteCouriers({ courierIds: couriersIds }, {
            onSuccess: () => {
                setCouriersIds([]);
                setInitialSelect(true);
            },
        })} disabled={noCouriersSelected || isDeleteCouriers} showIcon confirmText={t('tabs.couriers.delete_selection_confirm', {
            count: couriersIds?.length,
        })} label={tabsView === 'half_vertical'
            ? `(${!noCouriersSelected ? couriersIds.length : 0})`
            : t('tabs.couriers.delete_selection', {
                count: !noCouriersSelected ? couriersIds.length : 0,
            })}/>
      </WrapItem>
    </Wrap>);
};
export default CourierHeaderButtons;
