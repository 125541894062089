import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOReservedAreaLayerItem = IOLayerItem;
const fetchReservedArea = (townId) => () => get(iots.array(IOReservedAreaLayerItem), `legacy/${townId}/reserved-area`);
const useReservedAreaLayer = () => {
    createLayersHook({
        fetch: fetchReservedArea,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.RESERVED_AREA);
};
const ReservedAreaLayer = () => {
    useReservedAreaLayer();
    return null;
};
export default ReservedAreaLayer;
