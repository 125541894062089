import * as yup from 'yup';
import { checkFileSize } from 'utils/files';
export const CREDIT_BLACK_AND_WHITE_FIRST_PAGE = 0.92;
export const CREDIT_BLACK_AND_WHITE_ADDITIONAL_PAGE = 0.17;
export const CREDIT_COLOR_FIRST_PAGE = 1.19;
export const CREDIT_COLOR_ADDITIONAL_PAGE = 0.33;
export const CREDIT_OPTION_TREAT_UNDELIVERED_MAIL = 0.3;
export const getRecipientDefaultValues = (courierInfo) => {
    return {
        civility: courierInfo?.civility,
        firstname: courierInfo?.firstname,
        lastname: courierInfo?.lastname,
        company: courierInfo?.company ?? null,
        address: courierInfo?.address,
        addressBP: courierInfo?.addressBP,
        addressComplement: courierInfo?.addressComplement,
        postalCode: courierInfo?.postalCode,
        town: courierInfo?.town ?? '',
        country: courierInfo?.country ?? '',
    };
};
export const getCourierDefaultValues = (courierInfo) => {
    return {
        id: courierInfo?.id ?? null,
        plotId: courierInfo?.plotId ?? null,
        contactId: courierInfo?.contactId ?? null,
        senderId: '',
        file: null,
        send: 'now',
        confirmMode: false,
        mailevaOptionColor: courierInfo?.mailevaOptionColor ?? 1,
        mailevaOptionNbPage: courierInfo?.mailevaOptionNbPage ?? 1,
        mailevaOptionTreatUndeliveredMail: courierInfo?.mailevaOptionTreatUndeliveredMail ?? 0,
        ...getRecipientDefaultValues(courierInfo),
    };
};
export const getCourierValidationSchema = (t) => {
    const properties = {
        file: yup
            .mixed()
            .test('has-uploaded-file', t('global.fields.error.required'), (fileList, context) => context.parent.id || Boolean(Object.values(fileList ?? {})?.length))
            .test('is-valid-size', t('global.fields.error.file_size'), (fileList) => checkFileSize({ fileList, maxSize: 10000000 })),
        lastname: yup
            .string()
            .nullable()
            .transform((v, o) => (o?.trim() === '' ? null : v))
            .required(t('global.fields.error.required'))
            .min(1, t('global.fields.error.required')),
        address: yup
            .string()
            .nullable()
            .transform((v, o) => (o?.trim() === '' ? null : v))
            .required(t('global.fields.error.required'))
            .min(1, t('global.fields.error.required')),
        postalCode: yup
            .string()
            .nullable()
            .transform((v, o) => (o?.trim() === '' ? null : v))
            .required(t('global.fields.error.required'))
            .min(4, t('global.fields.error.required')),
        town: yup
            .string()
            .nullable()
            .required(t('global.fields.error.required'))
            .min(1, t('global.fields.error.required')),
        country: yup
            .string()
            .nullable()
            .required(t('global.fields.error.required'))
            .min(1, t('global.fields.error.required')),
        senderId: yup
            .string()
            .nullable()
            .transform((v, o) => (o?.trim() === '' ? null : v))
            .required(t('global.fields.error.required'))
            .min(1, t('global.fields.error.required')),
    };
    return yup.object().shape(properties);
};
