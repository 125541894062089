import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { IOMarkerLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { getTileBoundingBox } from 'utils/geo';
import { getBaseTileUrlHost3 } from 'utils/multiplexage';
import { CST_POSITIONS } from 'utils/tilesPositions';
const IORadarMarkerLayerItem = iots.intersection([
    IOMarkerLayerItem,
    iots.type({
        image: iots.string,
    }),
], 'IORadarMarkerLayerItem');
const fetchRadar = () => () => get(iots.array(IORadarMarkerLayerItem), `radar`);
const getLayerTileUrl = (map, tileWidth, tileHeight) => (coord, zoom) => {
    const boundingBox = getTileBoundingBox(coord, zoom, map, tileWidth, tileHeight);
    return (getBaseTileUrlHost3() +
        'cgi-bin/mm/mapserv.cgi?map=/var/data/public_maps/project_radars_aeriens_v2.map&SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true' +
        '&LAYERS=radar_aeriens_full' +
        '&TILED=true&WIDTH=' +
        tileWidth +
        '&HEIGHT=' +
        tileHeight +
        '&CRS=EPSG:4326&STYLES=&BBOX=' +
        boundingBox);
};
const getMarkerImg = (marker) => {
    try {
        return require(`../../images/radar/${marker.image}`);
    }
    catch (e) {
        if (e instanceof Error) {
            //eslint-disable-next-line
            console.error("Can't load ", marker.image);
        }
        else
            throw e;
    }
};
const useRadarLayers = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.RADAR],
    })(filtersKey.RADAR);
    createLayersHook({
        fetch: fetchRadar,
        markerImg: getMarkerImg,
    })(filtersKey.RADAR);
};
const RadarLayers = () => {
    useRadarLayers();
    return null;
};
export default RadarLayers;
