import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@chakra-ui/react';
import { useFiltersSearchRegex } from 'hooks/contexts/useFiltersContext';
import { useFilteredSecondaryLayers } from 'hooks/contexts/useLayersTreeContext';
const NoFiltersResults = () => {
    const { t } = useTranslation();
    const filteredSecondaryLayers = useFilteredSecondaryLayers();
    const filtersSearchRegex = useFiltersSearchRegex();
    if (!Object.keys(filteredSecondaryLayers).length && filtersSearchRegex) {
        return (<Box padding={5}>
        <Text textStyle="paragraph">
          {t('sidebar.search_layers.no_result')}
        </Text>
      </Box>);
    }
    return null;
};
export default NoFiltersResults;
