import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOPrincipleOfAccessLayerItem = IOLayerItem;
const fetchPrincipleOfAccess = (townId) => () => get(iots.array(IOPrincipleOfAccessLayerItem), `legacy/${townId}/principle-of-access`);
const usePrincipleOfAccessLayer = () => {
    createLayersHook({
        fetch: fetchPrincipleOfAccess,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.PRINCIPLE_OF_ACCESS);
};
const PrincipleOfAccessLayer = () => {
    usePrincipleOfAccessLayer();
    return null;
};
export default PrincipleOfAccessLayer;
