import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOOtherEasementLayerItem = IOLayerItem;
const fetchOtherEasement = (townId) => () => get(iots.array(IOOtherEasementLayerItem), `legacy/${townId}/other-easement`);
const useOtherEasementLayer = () => {
    createLayersHook({
        fetch: fetchOtherEasement,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.OTHER_EASEMENT);
};
const OtherEasementLayer = () => {
    useOtherEasementLayer();
    return null;
};
export default OtherEasementLayer;
