import * as React from 'react';
import { Button, Spinner, Text } from '@chakra-ui/react';
import useAnalytics from 'analytics/useAnalytics';
import { useToggleContact } from 'hooks/contexts/useTabsContext';
import useContactList from 'hooks/crm/useContactList';
const ContactButton = ({ contactId, variant = 'link-primary', analyticOrigin, analyticDetail, children, ...rest }) => {
    const { trackEvent } = useAnalytics();
    const { contacts, isInitialLoading: isLoadingContactList } = useContactList();
    const toggleContact = useToggleContact();
    const contact = contacts?.find((contact) => {
        return contact.id === contactId.toString();
    });
    if (isLoadingContactList) {
        return <Spinner />;
    }
    const handleClick = () => {
        toggleContact({ resource: contact });
        if (analyticOrigin && analyticDetail) {
            trackEvent({
                category: analyticOrigin,
                action: analyticDetail,
            });
        }
    };
    if (contact)
        return (<Button variant={variant} onClick={handleClick} data-cy={rest?.id} {...rest}>
        <Text as={rest.disabled ? 'del' : 'span'} opacity={rest.disableText ? '0.3' : '1'} color="inherit">
          {!children ? `${contact?.firstname} ${contact?.lastname}` : children}
        </Text>
      </Button>);
    return null;
};
export default ContactButton;
