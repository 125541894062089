import * as React from 'react';
import { memo, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Heading, HStack, Stack, useDisclosure, } from '@chakra-ui/react';
import { selectSetValue } from 'components/inputs/MultiSelectInput';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import useContact from 'hooks/crm/useContact';
import { ACTION_CREATE, ACTION_EDIT, CONTACT_TYPE_OWNER, CONTACT_TYPES_OPTIONS, } from 'utils/constants';
import ContactForm from './ContactForm';
export const CREATE_CONTACT_DEFAULT_VALUES = {
    id: null,
    title: 0,
    titleStr: '',
    lastname: '',
    firstname: '',
    company: '',
    mobile: '',
    landline: '',
    primaryEmail: '',
    address: '',
    addressBP: '',
    addressComplement: '',
    postalCode: '',
    town: '',
    country: 'FR',
    comment: '',
    addressesPlots: '',
    type: [1], // owner
    consent: 0,
    note: '',
    plots: [],
    notes: [],
    projects: [],
    activities: [],
    createdAt: '',
};
const validateForm = (values) => {
    const errors = {};
    if (!values?.lastname || values?.lastname.trim() === '') {
        errors.lastname = {
            type: 'required',
            message: 'global.fields.error.required',
        };
    }
    return errors;
};
const resolver = (values) => {
    return {
        values,
        errors: validateForm(values),
    };
};
const formatContactValues = (contact) => {
    if (contact)
        return {
            ...contact,
            type: contact?.type.length > 0
                ? selectSetValue(CONTACT_TYPES_OPTIONS, contact?.type)
                : selectSetValue(CONTACT_TYPES_OPTIONS, [CONTACT_TYPE_OWNER]),
        };
    return {};
};
const ContactDrawer = ({ contact, action = '', triggerItem, handleClose, button, }) => {
    const { t } = useTranslation();
    if (!triggerItem) {
        triggerItem = (onOpen) => (<Button variant="primary" onClick={onOpen}>
        {t('global.actions.modify')}
      </Button>);
    }
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { updateContact, addContact } = useContact();
    const selectedTown = useSelectedTown();
    const methods = useForm({
        resolver,
        defaultValues: formatContactValues(contact
            ? {
                lastname: contact.lastname,
                firstname: contact?.firstname ?? '',
                titleStr: contact?.titleStr ?? '',
                company: contact?.company ?? '',
                siren: contact?.siren ?? '',
                mobile: contact?.mobile ?? '',
                landline: contact?.landline ?? '',
                primaryEmail: contact?.primaryEmail ?? '',
                address: contact?.address ?? '',
                addressBP: contact?.addressBP ?? '',
                addressComplement: contact?.addressComplement ?? '',
                postalCode: contact?.postalCode ?? '',
                town: contact?.town ?? '',
                country: contact?.country ?? '',
                addressesPlots: contact?.addressesPlots ?? '',
                consent: contact?.consent,
                type: contact?.type,
                vendor: contact?.vendor,
                npr: contact?.npr,
                npai: contact?.npai,
                ownerId: contact?.ownerId ?? '', // create on owner (not occupant because not ownerId (numero_majic))
            }
            : CREATE_CONTACT_DEFAULT_VALUES),
    });
    const { handleSubmit, formState: { isValid, isDirty, isSubmitting }, reset, } = methods;
    useEffect(() => {
        if (action === ACTION_CREATE && contact && !isOpen) {
            reset(formatContactValues(contact));
            onOpen();
        }
    }, [contact]);
    const onSubmit = (data) => {
        const onSuccess = () => {
            reset();
            onClose();
            if (handleClose) {
                handleClose();
            }
        };
        const dataFormated = {
            ...data,
            type: data.type
                .map((option) => {
                return option.value;
            })
                .join(','),
        };
        if (action === ACTION_EDIT) {
            updateContact({
                id: contact?.id,
                ...dataFormated,
            }, {
                onSuccess,
            });
        }
        if (action === ACTION_CREATE) {
            const dataContact = {
                ...dataFormated,
                button,
            };
            addContact({
                data: dataContact,
                townshipId: selectedTown?.id,
            }, {
                onSuccess,
            });
        }
    };
    return (<>
      {triggerItem && triggerItem(onOpen)}
      <Drawer isOpen={isOpen} onClose={() => {
            onClose();
            if (handleClose) {
                handleClose();
            }
            reset();
        }} size="lg">
        <DrawerOverlay />
        <DrawerContent backgroundColor="colorMode.background900" overflowY="auto">
          <DrawerCloseButton />
          <DrawerHeader>
            <Heading as="h4" variant="h4">
              {action === ACTION_EDIT
            ? t('contact.actions.update')
            : t('contact.actions.create')}
            </Heading>
          </DrawerHeader>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <DrawerBody paddingY={1}>
                <Stack width="full" spacing={2}>
                  <ContactForm action={action}/>
                </Stack>
              </DrawerBody>
              <DrawerFooter>
                <HStack justifyContent="flex-end">
                  <Button paddingX={10} variant="ghostBorder" onClick={() => {
            onClose();
            if (handleClose) {
                handleClose();
            }
            reset();
        }}>
                    {t('global.actions.cancel')}
                  </Button>
                  <Button paddingX={10} variant="primary" type="submit" isLoading={isSubmitting} loadingText={t('global.status.creating')} isDisabled={!isValid && !isDirty} data-cy="contact_form_submit">
                    {t('global.actions.validate')}
                  </Button>
                </HStack>
              </DrawerFooter>
            </form>
          </FormProvider>
        </DrawerContent>
      </Drawer>
    </>);
};
export default memo(ContactDrawer);
