import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, } from '@chakra-ui/react';
import 'react-color-palette/css';
const PersonalizationConfirmModal = ({ isUpdated, onSubmit, content, }) => {
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (<>
      <Button isDisabled={isUpdated} variant="primary" onClick={onOpen}>
        {t('tabs.personalization.section.plot_status.save')}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center">
            <Heading as="h4" variant="h4">
              {t('tabs.personalization.modal.title')}
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody textStyle="paragraph" color="colorMode.font900">
            <Box>{content}</Box>
          </ModalBody>

          <ModalFooter>
            <Button variant="primary" type="submit" disabled={isUpdated} mr={3} onClick={() => {
            onSubmit();
            onClose();
        }}>
              {t('tabs.personalization.modal.save')}
            </Button>
            <Button variant="ghost" onClick={onClose}>
              {t('tabs.personalization.modal.cancel')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>);
};
export default PersonalizationConfirmModal;
