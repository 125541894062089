import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { VStack } from '@chakra-ui/react';
import DashboardButton from 'components/buttons/DashboardButton';
import { CRM_TABS } from 'context/TabsContext';
import { useToggleCRMTab, useToggleLayerTab, } from 'hooks/contexts/useTabsContext';
import CalendarIcon from 'images/icons/Calendar';
import ContactsIcon from 'images/icons/Contacts';
import EnvelopeIcon from 'images/icons/Envelope';
import { useChangeLayout } from '../../../hooks/contexts/useLayoutContext';
import DashboardTitle from './DashboardTitle';
const Prospect = ({ isItemVisible }) => {
    const { t } = useTranslation();
    const toggleCRMTab = useToggleCRMTab();
    const toggleLayerTab = useToggleLayerTab();
    const { deployLayout } = useChangeLayout();
    if (!isItemVisible('myContact') &&
        !isItemVisible('agenda') &&
        !isItemVisible('courier')) {
        return null;
    }
    return (<VStack spacing={0} alignItems="stretch">
      <DashboardTitle label={t(`tabs.dashboard.title_prospect`)} icon={ContactsIcon}/>

      <VStack display="flex">
        {isItemVisible('myContact') && (<DashboardButton onClick={() => {
                deployLayout();
                toggleLayerTab({
                    tabId: CRM_TABS.CONTACT_LIST,
                });
            }} icon={ContactsIcon} label={t(`tabs.dashboard.btn_contacts`)} id="dashboard_button_contacts"/>)}
        {isItemVisible('agenda') && (<DashboardButton onClick={() => {
                deployLayout();
                toggleCRMTab({ tabId: CRM_TABS.ACTIVITY });
            }} icon={CalendarIcon} label={t(`tabs.dashboard.title_agenda`)} id="dashboard_button_agenda"/>)}
        {isItemVisible('courier') && (<DashboardButton onClick={() => {
                deployLayout();
                toggleCRMTab({ tabId: CRM_TABS.COURIER });
            }} icon={EnvelopeIcon} label={t(`tabs.dashboard.title_courier`)} id="dashboard_button_courier"/>)}
      </VStack>
    </VStack>);
};
export default Prospect;
