import * as React from 'react';
import { useCallback, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Text } from '@chakra-ui/react';
import * as iots from 'io-ts';
import { get } from 'api/client';
import InfoWindowTable from 'components/infoWindow/InfoWindowTable';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOMarkerLayerItem } from 'types/api';
const IOOfficeBuildingsMarkerItem = iots.intersection([
    IOMarkerLayerItem,
    iots.type({
        count: iots.number,
        legacyId: iots.string,
        category: iots.string,
    }),
]);
const IOOfficeBuildingsInfo = iots.partial({
    name: iots.string,
    address: iots.string,
    sector: iots.string,
    sir: iots.string,
    nic: iots.string,
    legalStatus: iots.string,
    creationDate: iots.string,
    workforceBracket: iots.string,
});
const getLegends = (filterName) => ({
    [`office_buildings_${filterName}`]: {
        layerKey: filterName,
        label: filterName,
        needsTranslate: true,
        image: getMarkerImg({ count: 'X' }),
    },
});
const FETCH_URLS = {
    office_buildings_without_employee: 'office-buildings-without-employee',
    office_buildings_one_to_ten_employees: 'office-buildings-one-to-ten-employees',
    office_buildings_ten_to_fifty_employees: 'office-buildings-ten-to-fifty-employees',
    office_buildings_fifty_to_one_hundred_employees: 'office-buildings-fifty-to-one-hundred-employees',
    office_buildings_one_hundred_to_two_hundred_and_fifty_employees: 'office-buildings-one-hundred-to-two-hundred-and-fifty-employees',
    office_buildings_two_hundred_and_fifty_to_one_thousand_employees: 'office-buildings-two-hundred-and-fifty-to-one-thousand-employees',
    office_buildings_one_thousand_to_five_thousand_employees: 'office-buildings-one-thousand-to-five-thousand-employees',
    office_buildings_more_than_five_thousand_employees: 'office-buildings-more-than-five-thousand-employees',
    office_buildings_workforce_not_disclosed: 'office-buildings-workforce-not-disclosed',
};
const YELLOW_PAGES_URL = 'https://www.pagesjaunes.fr/pagesblanches/recherche?ou=';
const fetchOfficeBuildingsInfo = (townId, item) => {
    return get(iots.array(IOOfficeBuildingsInfo), `legacy/${townId}/office-buildings-info/${encodeURIComponent(item.legacyId)}`);
};
const formatInfoWindowContent = (info, t) => {
    const content = (<>
      {info.map((item) => (<Box key={item.name}>
          <InfoWindowTable title={`${t('info_window.office_buildings.address')} : ${item.address}`} content={[
                {
                    title: t('info_window.office_buildings.name'),
                    data: item.name,
                },
                {
                    title: t('info_window.office_buildings.sector'),
                    data: item.sector,
                },
                {
                    title: t('info_window.office_buildings.siret'),
                    data: (<a target="_blank" rel="nofollow noreferrer noopener" href={`https://www.societe.com/societe/xx-${item.sir}.html`}>
                    {`${item.sir}${item.nic}`}
                  </a>),
                },
                {
                    title: t('info_window.office_buildings.legal_status'),
                    data: item.legalStatus,
                },
                {
                    title: t('info_window.office_buildings.creation_date'),
                    data: item.creationDate,
                },
                {
                    title: t('info_window.office_buildings.workforce_bracket'),
                    data: item.workforceBracket,
                },
            ]}/>
          <Text paddingY="5px">
            {t('info_window.office_buildings.name_of_individuals')}&nbsp;
            <a target="_blank" rel="nofollow noreferrer noopener" href={`${YELLOW_PAGES_URL}${item.address}`}>
              {item.address}
            </a>
            &nbsp;({t('info_window.office_buildings.source')})
          </Text>
          <Divider height={1} backgroundColor="black" marginY="5px"/>
        </Box>))}
    </>);
    return ReactDOMServer.renderToStaticMarkup(content);
};
const getMarkerImg = (marker) => {
    return `/api/public/legacy/get-office-buildings-image/${marker.count}`;
};
const useOfficeBuildingsLayers = (filterName) => {
    const selectedTown = useSelectedTown();
    const { t } = useTranslation();
    const [markerInfo, setMarkerInfo] = useState({});
    const getInfoWindowContent = useCallback(async (marker) => {
        const markerKey = `${marker.lat}/${marker.lng}`;
        if (markerInfo[markerKey]) {
            return markerInfo[markerKey];
        }
        const officeBuildingsInfo = await fetchOfficeBuildingsInfo(selectedTown?.id, marker);
        const content = formatInfoWindowContent(officeBuildingsInfo, t);
        setMarkerInfo({
            ...markerInfo,
            [markerKey]: content,
        });
        return content;
    }, [selectedTown]);
    const fetchOfficeBuildingsLayer = (townId) => () => get(iots.array(IOOfficeBuildingsMarkerItem), `legacy/${townId}/${FETCH_URLS[filterName]}`);
    createLayersHook({
        fetch: fetchOfficeBuildingsLayer,
        markerImg: getMarkerImg,
        legends: getLegends(filterName),
        getInfoWindowContent,
    })(filterName);
};
const OfficeBuildingsLayers = ({ filterName, }) => {
    useOfficeBuildingsLayers(filterName);
    return null;
};
export default OfficeBuildingsLayers;
