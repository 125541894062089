import * as React from 'react';
import { ButtonGroup } from '@chakra-ui/react';
import MapDisplayButtons from './MapDisplayButtons';
export const toolbarButtonsStyle = {
    variant: 'ghost',
    size: 'sm',
    boxShadow: 'lg',
};
const LayersButtons = ({ map }) => {
    return (<ButtonGroup spacing="0">
      <MapDisplayButtons map={map}/>
    </ButtonGroup>);
};
export default LayersButtons;
