import * as React from 'react';
import { memo } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { Heading, Stack, Text } from '@chakra-ui/react';
import { t } from 'i18next';
import { snakeCase } from 'lodash';
import Table from 'components/table/Table';
import { formatDate } from 'utils/formatDate';
import { inDateRange } from 'utils/search';
const getColumnHeader = (column) => (<Translation>
    {(t) => (<Text align="center">{t(`tabs.statistic_team.columns.${column}`)}</Text>)}
  </Translation>);
const COLUMNS = [
    {
        id: 'columns',
        columns: [
            {
                accessorKey: 'name',
                header: () => getColumnHeader('name'),
                accessorFn: (row) => {
                    if (!row.name)
                        return t('table.empty_column');
                    return row.name;
                },
                cell: ({ row }) => row.original.name,
                filterFn: 'arrIncludesSome',
            },
            {
                accessorKey: 'email',
                header: () => getColumnHeader('email'),
                accessorFn: (row) => {
                    if (!row.email)
                        return t('table.empty_column');
                    return row.email;
                },
                cell: ({ row }) => row.original.email,
                filterFn: 'arrIncludesSome',
            },
            {
                accessorKey: 'lastOnline',
                header: () => getColumnHeader('last_online'),
                cell: (info) => formatDate(info.getValue()),
                enableGlobalFilter: false,
                filterFn: inDateRange,
            },
            {
                accessorKey: 'townsSeen',
                header: () => getColumnHeader('towns_seen'),
                cell: (info) => info.getValue(),
                filterFn: 'inNumberRange',
            },
            {
                accessorKey: 'addressLooked',
                header: () => getColumnHeader('address_looked'),
                cell: (info) => info.getValue(),
                filterFn: 'inNumberRange',
            },
            {
                accessorKey: 'favoritesCreated',
                header: () => getColumnHeader('favorites_created'),
                cell: (info) => info.getValue(),
                filterFn: 'inNumberRange',
            },
            {
                accessorKey: 'projectsCreated',
                header: () => getColumnHeader('projects_created'),
                cell: (info) => info.getValue(),
                filterFn: 'inNumberRange',
            },
        ],
    },
];
const EXPORTED_FIELDS = [
    'name',
    'email',
    'lastOnline',
    'townsSeen',
    'addressLooked',
    'favoritesCreated',
    'projectsCreated',
];
const formatExportHeader = (t) => (header) => t(`tabs.statistic_team.columns.${snakeCase(header)}`);
const formatExportValue = (value, field) => {
    if (field === 'lastOnline') {
        return formatDate(value);
    }
    return value;
};
const StatisticUtilisationList = ({ data, title, analyticsAction, id, isLoading, }) => {
    const { t } = useTranslation();
    return (<Stack height="100%" minHeight={250}>
      <Table headerTitle={<Heading as="h4" variant="h4" color="colorMode.font800">
            {t(title)}
          </Heading>} id={id} columns={COLUMNS} data={data} isLoading={isLoading} emptyMessage={t(`tabs.statistic_team.no_result`)} exportTitle={title} analyticsAction={analyticsAction} exportedFields={EXPORTED_FIELDS} formatExportHeader={formatExportHeader(t)} formatExportValue={formatExportValue}/>
    </Stack>);
};
export default memo(StatisticUtilisationList);
