import moment from 'moment';
const translations = {
    ape: 'ape_code',
    companyActivity: 'activity',
    category: 'society_type',
    decreeDate: 'create_date',
    tefen: 'workforce',
    companyName: 'company_name',
};
export const getOccupantFieldName = ({ fieldName, t }) => {
    return t(`tabs.plots.section.occupant.columns.` +
        (translations[fieldName] ?? fieldName));
};
export const formatOccupantCellValue = ({ cellValue, fieldName }) => {
    if (fieldName === 'decreeDate') {
        return moment(cellValue).format('DD/MM/YYYY');
    }
    return cellValue;
};
