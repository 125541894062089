import * as React from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import { Box, Button, IconButton, Menu, MenuButton, MenuList, Text, } from '@chakra-ui/react';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import useComputedZoom from 'hooks/map/useComputedZoom';
import useNavigateToTown from 'hooks/navigation/useNavigateToTown';
import useClearSelectedTown from 'hooks/useClearSelectedTown';
import useDebug from 'hooks/useDebug';
import DropdownIcon from 'images/icons/Dropdown';
import EarthIcon from 'images/icons/Earth';
const CITIES = [
    {
        color: 'red.500',
        lat: '44.84053080946083',
        lng: '-0.5829059364013522',
        name: 'Bordeaux',
    },
    {
        color: 'blue.500',
        lat: '48.86279',
        lng: '2.363026',
        name: 'Paris',
    },
    {
        color: 'yellow.500',
        lat: '43.7101728',
        lng: '7.261953200000001',
        name: 'Nice',
    },
    {
        color: 'green.500',
        lat: '49.49437',
        lng: '0.107929',
        name: 'Le Havre',
    },
];
const TownQuickSelect = () => {
    const { isDebug } = useDebug();
    const navigateToTown = useNavigateToTown();
    const clearSelectedTown = useClearSelectedTown();
    const computedZoom = useComputedZoom();
    const selectedTown = useSelectedTown();
    if (!isDebug) {
        return null;
    }
    return (<Menu>
      {({ isOpen }) => (<>
          <MenuButton as={Button} leftIcon={<EarthIcon fill="var(--colorModeFont-800)"/>} rightIcon={<Box transform={isOpen ? 'rotateX(180deg)' : 'rotateX(0deg)'} transition="transform 0.5s">
                <DropdownIcon fill="var(--colorModeFont-800)"/>
              </Box>} variant="ghost" padding={2}>
            <Text textStyle="paragraph" display={{
                base: 'none',
                lg: 'inline-block',
            }} textOverflow="ellipsis" overflow="hidden" width="100%">
              Quick select (dev only)
            </Text>
          </MenuButton>
          <MenuList padding={0} backgroundColor="colorMode.background900" borderRadius="md" zIndex="21">
            <Box className="no-print" boxShadow="sm" position="absolute" top={5} left={0} right={0} margin="auto" width="250px" backgroundColor="colorMode.background900" padding={5} zIndex="20">
              {selectedTown && (<Text fontWeight="bold">
                  {selectedTown.name}
                  <IconButton bgColor="red.500" aria-label="Remove town" icon={<CloseIcon />} size="xs" onClick={clearSelectedTown} marginLeft={2}/>
                </Text>)}
              {computedZoom && <Text>{`Zoom X${computedZoom}`}</Text>}
              <>
                {CITIES.map((city) => (<Button key={city.name} size="xs" bgColor={city.color} color="gray.100" onClick={() => navigateToTown({
                    lat: city.lat,
                    lng: city.lng,
                    centerMapOnLat: city.lat,
                    centerMapOnLng: city.lng,
                    precision: 1,
                })}>
                    {city.name}
                  </Button>))}
              </>
            </Box>
          </MenuList>
        </>)}
    </Menu>);
};
export default TownQuickSelect;
