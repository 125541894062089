import * as React from 'react';
import { Alert, IconButton } from '@chakra-ui/react';
import useNavigateToTown from 'hooks/navigation/useNavigateToTown';
import Geoloc from 'images/icons/Geoloc';
const GeoLocationButton = ({ clearInput }) => {
    const navigateToTown = useNavigateToTown();
    const geolocateUser = () => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                clearInput();
                const lat = position.coords.latitude.toString();
                const lng = position.coords.longitude.toString();
                navigateToTown({
                    lat,
                    lng,
                });
            });
        }
        else {
            Alert.call('Geolocation is not supported by this browser.');
        }
    };
    return (<>
      <IconButton size="sm" onClick={geolocateUser} variant="ghost" aria-label="geolocalization">
        <Geoloc fill="var(--chakra-colors-red-500)"/>
      </IconButton>
    </>);
};
export default GeoLocationButton;
