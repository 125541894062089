import * as React from 'react';
import { HStack, Icon, Text } from '@chakra-ui/react';
import DownloadIcon from 'images/icons/Download';
export const DownloadLinkWrapper = ({ children, textStyle = 'paragraph' }) => {
    return (<HStack alignItems="center">
      <Icon as={DownloadIcon} width={4} height={4} color="blue.500"/>
      <Text as="div" textStyle={textStyle} color="blue.500" fontWeight="500" _hover={{
            color: 'blue.600',
            textDecoration: 'underline',
        }}>
        {children}
      </Text>
    </HStack>);
};
export default DownloadLinkWrapper;
