import * as React from 'react';
import { useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as iots from 'io-ts';
import { get } from 'api/client';
import RetailInfoWindow, { IORetailInfo, } from 'components/infoWindow/RetailInfoWindow';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOMarkerLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
const IORetailMarkerItem = iots.intersection([
    IOMarkerLayerItem,
    iots.type({
        picto: iots.string,
    }),
], 'IORetailMarkerItem');
export const RETAIL_FILTERS = [
    filtersKey.RETAIL_HYPERMARKET,
    filtersKey.RETAIL_SUPERMARKET,
    filtersKey.RETAIL_MINIMARKET,
    filtersKey.RETAIL_HARD_DISCOUNT,
    filtersKey.RETAIL_BIO,
    filtersKey.RETAIL_DEEP_FREEZE,
    filtersKey.RETAIL_DRIVE,
];
const fetchRetail = (townId) => () => get(iots.array(IORetailMarkerItem), `${townId}/retail`);
const fetchRetailInfo = (item) => {
    return get(IORetailInfo, `retail/${item.id}`);
};
const getMarkerImage = (marker) => {
    try {
        return require('../../images/' + marker.picto);
    }
    catch (e) {
        if (e instanceof Error) {
            //eslint-disable-next-line
            console.warn("Can't load ", marker.picto);
        }
        else
            throw e;
    }
};
const formatInfoWindowContent = (info) => {
    const content = <RetailInfoWindow info={info}/>;
    return ReactDOMServer.renderToStaticMarkup(content);
};
const useRetailLayers = () => {
    const [markerInfo, setMarkerInfo] = useState({});
    const getInfoWindowContent = async (marker) => {
        if (markerInfo[marker.id]) {
            return markerInfo[marker.id];
        }
        const retailInfo = await fetchRetailInfo(marker);
        const content = formatInfoWindowContent(retailInfo);
        setMarkerInfo({
            ...markerInfo,
            [marker.id]: content,
        });
        return content;
    };
    createLayersHook({
        fetch: fetchRetail,
        markerImg: getMarkerImage,
        getInfoWindowContent,
    })(RETAIL_FILTERS);
};
const RetailLayer = () => {
    useRetailLayers();
    return null;
};
export default RetailLayer;
