import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, VStack } from '@chakra-ui/react';
import SearchByPlot from 'components/SearchByPlot';
const AddPlotForm = ({ withLabel, submitText, mode, }) => {
    const { t } = useTranslation();
    return (<VStack backgroundColor="colorMode.base" alignItems="flex-start" borderRadius="lg">
      <Heading as="h5" variant="h5">
        {t('tabs.plots.section.feasibility.add_plot')}
      </Heading>
      <SearchByPlot mode={mode} withLabel={withLabel} submitText={submitText}/>
    </VStack>);
};
export default AddPlotForm;
