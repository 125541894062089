import * as React from 'react';
import { useCallback, useState } from 'react';
import { createContext } from 'use-context-selector';
import { useFetchPlotData } from 'hooks/useFetchPlotData';
import { FROM_CLICK } from 'utils/constants';
import { filtersKey } from 'utils/filters';
export const LEGEND_FEASIBILITY_BUILDING = 'selected_plots_buildings';
export const legendsToKeep = [
    'user_projects',
    'user_plots',
    'contact_list',
    'team_projects',
    'team_plots',
];
export const getLegendLayerKey = (legend) => {
    return legend.parentLayerKey ?? legend.layerKey;
};
export const LegendContext = createContext(null);
export const LegendProvider = ({ children }) => {
    const [legends, setLegends] = useState({});
    const [plotSummary, setPlotSummary] = useState();
    // Plot Summary Legend Item
    const { getPlotData, isLoading: isLoadingPlotSummary } = useFetchPlotData({
        from: FROM_CLICK,
        successCallback: (plot) => setPlotSummary(plot),
        errorCallback: () => {
            clearPlotSummary();
        },
    });
    const fetchPlotSummary = (coordinates) => {
        getPlotData({ coordinates });
    };
    const clearPlotSummary = () => {
        setPlotSummary(undefined);
    };
    // Other Legends items
    const addLegend = useCallback((key, legend) => {
        setLegends((prevLegends) => ({
            ...prevLegends,
            [getLegendLayerKey(legend)]: {
                ...prevLegends[getLegendLayerKey(legend)],
                [key]: legend,
            },
        }));
    }, [setLegends]);
    const removeLegend = useCallback((layerKey, key = null) => {
        if (layerKey === filtersKey.PLOTS) {
            setPlotSummary(null);
        }
        setLegends((prevLegends) => {
            const newLegends = { ...prevLegends };
            if (newLegends[layerKey]) {
                if (key) {
                    if (newLegends[layerKey][key]) {
                        delete newLegends[layerKey][key];
                        if (Object.keys(newLegends[layerKey]).length === 0) {
                            delete newLegends[layerKey];
                        }
                    }
                    else {
                        // Suppression de la legende qui contient le layerKey à supprimer (cas POI)
                        // (voir pour poi_transport_rer...)
                        for (const property in prevLegends[layerKey]) {
                            if (prevLegends[layerKey][property].layerKey === key) {
                                delete newLegends[layerKey][property];
                            }
                        }
                        if (Object.keys(newLegends[layerKey]).length === 0) {
                            delete newLegends[layerKey];
                        }
                    }
                }
                else {
                    delete newLegends[layerKey];
                }
            }
            return newLegends;
        });
    }, [setLegends]);
    const resetLegends = useCallback(() => {
        const keptLegends = {};
        Object.entries(legends).map((legend) => {
            if (legendsToKeep?.includes(legend[0])) {
                keptLegends[legend[0]] = legend[1];
            }
        });
        setLegends(keptLegends);
    }, [legends]);
    return (<LegendContext.Provider value={{
            legends,
            addLegend,
            removeLegend,
            resetLegends,
            plotSummary,
            setPlotSummary,
            clearPlotSummary,
            fetchPlotSummary,
            isLoadingPlotSummary,
        }}>
      {children}
    </LegendContext.Provider>);
};
export default LegendContext;
