import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, VStack } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_MASQUER } from 'analytics/useAnalytics';
import useUserHistory from 'hooks/crm/useUserHistory';
import HistoryIcon from 'images/icons/History';
import DashboardTitle from '../DashboardTitle';
import LoadMoreHistoryList from './LoadMoreHistoryList';
const DashboardHistoryWrapper = () => {
    const { t } = useTranslation();
    const { data: history, isLoading } = useUserHistory();
    const { trackEvent } = useAnalytics();
    return (<Box data-cy="dashboard_history">
      <DashboardTitle icon={HistoryIcon} label={t(`tabs.dashboard.title_historic`)}/>

      <VStack spacing={2}>
        <LoadMoreHistoryList items={history?.addresses} title={t(`tabs.dashboard.last_addresses`)} type="address" isLoading={isLoading} handleClick={() => trackEvent({
            category: ANALYTICS_MASQUER,
            action: 'Dernière adresse 100 adresses',
        })} id="address_history_list"/>
        <LoadMoreHistoryList items={history?.plots} title={t(`tabs.dashboard.last_plots`)} type="plot" isLoading={isLoading} handleClick={() => trackEvent({
            category: ANALYTICS_MASQUER,
            action: 'Dernière parcelle 100 parcelles',
        })} id="plot_history_list"/>
      </VStack>
    </Box>);
};
export default DashboardHistoryWrapper;
