import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { useExtraFilters } from 'hooks/contexts/useFiltersContext';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOPolygonLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { get } from '../../api/client';
const QUERY_KEY = 'agriculture-rearing-company-layer';
const fetchAgricultureRearingCompany = (departmentId, codeAPE) => () => get(iots.array(IOPolygonLayerItem), `${departmentId}/agriculture-rearing-company/layer`, { codeAPE: codeAPE });
const AgricultureRearingCompanyLayer = () => {
    const extraFilters = useExtraFilters();
    const selectedTown = useSelectedTown();
    const departmentId = selectedTown?.department;
    const codeAPE = extraFilters[filtersKey.AGRICULTURE_REARING_COMPANY]?.codeAPE || '011';
    const { data } = useQuery({
        queryKey: [QUERY_KEY, departmentId, codeAPE],
        queryFn: fetchAgricultureRearingCompany(departmentId, codeAPE),
        enabled: !!departmentId && !!codeAPE,
    });
    const layers = useMemo(() => (data ? Object.values(data) : null), [data]);
    createLayersHook({
        providedData: layers,
    })(filtersKey.AGRICULTURE_REARING_COMPANY);
    return null;
};
export default AgricultureRearingCompanyLayer;
