import { useState } from 'react';
import { FROM_CLICK } from 'utils/constants';
import { useSelectedTown } from './contexts/useLocationContext';
import usePlotByCoordinates from './plot/usePlotByCoordinates';
export const useFetchPlotData = ({ from = FROM_CLICK, successCallback, errorCallback, }) => {
    const selectedTown = useSelectedTown();
    const [coordinates, setCoordinates] = useState();
    const [townId, setTownId] = useState(selectedTown?.id);
    const { isInitialLoading: isLoading, data: plotData, error: plotFetchError, } = usePlotByCoordinates({
        coordinates,
        enabled: !!coordinates && !!townId,
        townId,
        from,
        errorCallback: (error) => {
            setCoordinates(undefined);
            setTownId(undefined);
            errorCallback && errorCallback(error);
        },
        successCallback: (data) => {
            setCoordinates(undefined);
            setTownId(undefined);
            successCallback && successCallback(data);
        },
    });
    const getPlotData = ({ coordinates, townId, }) => {
        setTownId(townId ?? selectedTown?.id);
        setCoordinates(coordinates);
    };
    return { getPlotData, isLoading, plotData, plotFetchError };
};
