import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOPlotUpdatedBuildingPotentialLayerItem = IOLayerItem;
const fetchPlotUpdatedBuildingPotential = (townId) => () => get(iots.array(IOPlotUpdatedBuildingPotentialLayerItem), `legacy/${townId}/plot-updated-building-potential`);
const usePlotUpdatedBuildingPotentialLayer = () => {
    createLayersHook({
        fetch: fetchPlotUpdatedBuildingPotential,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.PLOT_UPDATED_BUILDING_POTENTIAL);
};
const PlotUpdatedBuildingPotentialLayer = () => {
    usePlotUpdatedBuildingPotentialLayer();
    return null;
};
export default PlotUpdatedBuildingPotentialLayer;
