import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import PostIcon from 'images/post_icon.png';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOElectricalNetworksSourcePostLayerItem = IOLayerItem;
const fetchElectricalNetworksSourcePost = (townId) => () => get(iots.array(IOElectricalNetworksSourcePostLayerItem), `${townId}/source-post`);
const useElectricalNetworksSourcePostLayer = () => {
    createLayersHook({
        fetch: fetchElectricalNetworksSourcePost,
        markerImg: PostIcon,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.ELECTRICAL_NETWORKS_SOURCE_POSTS);
};
const ElectricalNetworksSourcePostLayer = () => {
    useElectricalNetworksSourcePostLayer();
    return null;
};
export default ElectricalNetworksSourcePostLayer;
