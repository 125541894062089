import isEmpty from 'lodash/isEmpty';
export const isCompletelyEmpty = (data) => {
    if (typeof data === 'object') {
        for (const property in data) {
            if (typeof data[property] === 'object') {
                if (!isCompletelyEmpty(data[property])) {
                    return false;
                }
            }
            else {
                if (!isEmpty(data)) {
                    return false;
                }
            }
        }
        return true;
    }
    return isEmpty(data);
};
