import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIsFetching } from '@tanstack/react-query';
import { getFilterKey, getFlattenedFilters, isFilterChecked, isRadioFilter, } from 'utils/filters';
import { useFilters, useSetFilters } from './contexts/useFiltersContext';
const useFilterCategoryCheckbox = (tree) => {
    const filters = useFilters();
    const setFilters = useSetFilters();
    const [isChecked, setIsChecked] = useState(false);
    const isLoadingLayers = useIsFetching({ queryKey: ['legend'] }) > 0;
    const flattenedFilters = useMemo(() => {
        return getFlattenedFilters(tree).filter((filter) => !isRadioFilter(filter));
    }, [tree, isLoadingLayers]);
    const hasCheckbox = flattenedFilters.length > 0;
    const hasAllChildrenChecked = useMemo(() => {
        if (!hasCheckbox) {
            return false;
        }
        return flattenedFilters.every((filter) => {
            return isFilterChecked(filter, filters);
        });
    }, [filters, flattenedFilters]);
    const onCheck = useCallback((e) => {
        const checked = e.target.checked;
        setIsChecked(checked);
        const newFilters = { ...filters };
        const childFilters = getFlattenedFilters(tree).filter((filter) => !isRadioFilter(filter));
        childFilters.forEach((filter) => {
            newFilters[getFilterKey(filter)] = checked;
        });
        setFilters(newFilters);
    }, [filters, flattenedFilters]);
    useEffect(() => {
        if (isChecked && !hasAllChildrenChecked) {
            setIsChecked(false);
        }
    }, [hasAllChildrenChecked]);
    return { isChecked: isChecked || hasAllChildrenChecked, onCheck, hasCheckbox };
};
export const useFilterCategoriesCheckbox = (categories) => {
    let isAllChecked = true;
    const onCategoriesCheck = [];
    Object.values(categories).forEach((category) => {
        const { isChecked, onCheck } = useFilterCategoryCheckbox(category);
        if (!isChecked) {
            isAllChecked = false;
        }
        onCategoriesCheck.push(onCheck);
    });
    return { isAllChecked, onCategoriesCheck };
};
export default useFilterCategoryCheckbox;
