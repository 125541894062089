import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Center, FormControl, FormErrorMessage, FormLabel, Spinner, } from '@chakra-ui/react';
import { multiSelectStyles } from './MultiSelectInput';
import NotRequired from './NotRequired';
const getCustomStyles = (error) => !error
    ? {
        ...multiSelectStyles,
        control: (provided) => ({
            ...provided,
            minHeight: '40px',
            fontSize: '12px',
        }),
        placeholder: (provided) => {
            return {
                ...provided,
                fontSize: '12px',
            };
        },
    }
    : {
        ...multiSelectStyles,
        control: (provided) => ({
            ...provided,
            color: 'var(--colorModeFont-900)',
            backgroundColor: 'var(--colorModeBase)',
            borderRadius: '8px',
            width: '100%',
            borderColor: '#E62641',
            borderWidth: 2,
            fontSize: '12px',
        }),
    };
const AdvancedSelectInput = ({ name, isDisabled, required, onChange, isLoading, options, label, placeholder, isClearable, blurInputOnSelect, noOptionsMessage, id, optional, selectRef, isDefaultSelected, }) => {
    const { t } = useTranslation();
    const { getValues, setValue, getFieldState, watch } = useFormContext();
    const { error } = getFieldState(name);
    const hasValue = Boolean(getValues(name));
    const fieldValue = watch(name);
    const getSelectInput = () => {
        if (isLoading && !hasValue)
            return (<Center width="full">
          <Spinner size="sm"/>
        </Center>);
        else {
            return (<>
          {label && (<FormLabel>
              {`${label} ${required ? '*' : ''}`} {optional && <NotRequired />}
            </FormLabel>)}
          <Select ref={selectRef} name={name} id={id ?? name} options={options} defaultValue={isDefaultSelected
                    ? options.length > 0 && options[0]
                    : options.filter((option) => option.value === getValues(name))} value={options.find((option) => option.value === fieldValue)} placeholder={placeholder} noOptionsMessage={() => noOptionsMessage ?? t('global.no_result')} styles={getCustomStyles(error)} isLoading={isLoading} isDisabled={isDisabled} isClearable={isClearable} blurInputOnSelect={blurInputOnSelect} onChange={(option) => {
                    let value = null;
                    if (option) {
                        value = option.value;
                    }
                    if (onChange) {
                        onChange(value);
                    }
                    setValue(name, value, { shouldValidate: true });
                }}/>
          {Boolean(error) && (<FormErrorMessage>{error?.message}</FormErrorMessage>)}
        </>);
        }
    };
    return (<FormControl isInvalid={Boolean(error)} fontSize={13} width="full">
      {getSelectInput()}
    </FormControl>);
};
export default AdvancedSelectInput;
