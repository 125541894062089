import * as React from 'react';
import { Translation } from 'react-i18next';
import { Box, HStack, Text } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_CRM } from 'analytics/useAnalytics';
import DeleteButton from 'components/buttons/DeleteButton';
import { HighlightPlotsSwitch } from 'components/buttons/HighlightPlotsSwitch';
import ModalButton from 'components/buttons/ModalButton';
import PlotButton from 'components/buttons/PlotButton';
import PlotStatusSelect from 'components/inputs/PlotStatusSelect';
import Table from 'components/table/Table';
import CustomTooltip from 'components/tooltips/CustomTooltip';
import { useOpenPlotModal } from 'hooks/contexts/useModalContext';
import MapIcon from 'images/icons/Map';
import { getPlotStatusFromCode } from 'utils/constants';
import { formatDate } from 'utils/formatDate';
const getColumnHeader = (column) => (<Translation>
    {(t) => (<Text align="center">{t(`tabs.project.columns.plots.${column}`)}</Text>)}
  </Translation>);
const ProjectPlotsTable = ({ projectId, projectPlots, isLoading, removePlot, isDeletingPlot, currentDeletedProjectPlot, landPointId, isUserOwner = true, }) => {
    const { trackEvent } = useAnalytics();
    const openPlotModal = useOpenPlotModal();
    const columns = React.useMemo(() => {
        return [
            {
                id: 'columns',
                columns: [
                    {
                        accessorKey: 'addedAt',
                        header: () => getColumnHeader('added_at'),
                        cell: ({ getValue }) => formatDate(getValue(), 'extended'),
                    },
                    {
                        accessorKey: 'plotNumber',
                        header: () => getColumnHeader('plots'),
                        cell: ({ getValue, row }) => {
                            const plot = row.original;
                            return !landPointId ? (<PlotButton variant="link-primary" lat={plot.lat} lng={plot.lng} townId={plot.townId} analyticOrigin={ANALYTICS_CRM} analyticDetail="Ouvrir fiche parcelle fiche projet" plotId={plot?.id} id="project">
                  {getValue()}
                </PlotButton>) : (<ModalButton onClick={() => {
                                    openPlotModal({
                                        townshipId: plot.townId,
                                        plotId: plot.id,
                                    });
                                }}>
                  {plot.plotNumber}
                </ModalButton>);
                        },
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'address',
                        header: () => getColumnHeader('address'),
                        cell: ({ getValue }) => {
                            return getValue();
                        },
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'surface',
                        header: () => getColumnHeader('surface'),
                        cell: ({ getValue }) => {
                            return getValue();
                        },
                        enableGlobalFilter: false,
                        filterFn: 'inNumberRange',
                    },
                    {
                        accessorKey: 'zone',
                        header: () => getColumnHeader('zone'),
                        cell: ({ getValue }) => {
                            return getValue();
                        },
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        id: 'plotStatus',
                        accessorFn: (row) => getPlotStatusFromCode(row.status),
                        header: () => getColumnHeader('status'),
                        cell: (info) => {
                            return isUserOwner ? (<PlotStatusSelect plotId={info.row.original.id} status={info.row.original.status} onChange={() => {
                                    trackEvent({
                                        category: ANALYTICS_CRM,
                                        action: 'Statut de la parcelle mes parcelles',
                                    });
                                }}/>) : (getPlotStatusFromCode(info.row.original.status));
                        },
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'id',
                        header: () => (<Translation>
                {(t) => (<CustomTooltip label={t('global.actions.highlight_plots')}>
                    <Box color="colorMode.font900">
                      <MapIcon width="30px" height="30px" fill="currentColor"/>
                    </Box>
                  </CustomTooltip>)}
              </Translation>),
                        cell: ({ row }) => {
                            return (<Box textAlign="center">
                  <HighlightPlotsSwitch plotsInfo={[row.original]}/>
                </Box>);
                        },
                        enableGlobalFilter: false,
                        enableColumnFilter: false,
                        enableSorting: false,
                    },
                    {
                        accessorKey: 'projectPlotId',
                        header: () => '',
                        cell: ({ table, row }) => {
                            const dataLength = table.getPaginationRowModel().rows.length;
                            return (<HStack justifyContent="center">
                  <Translation>
                    {(t) => {
                                    return (<DeleteButton size="sm" mode="icon" handleDelete={() => {
                                            removePlot(row.original, dataLength > 1 ? false : true);
                                            trackEvent({
                                                category: ANALYTICS_CRM,
                                                action: 'Supprimer une parcelle fiche projet',
                                            });
                                        }} confirmText={dataLength > 1
                                            ? t('tabs.project.delete_plot.confirm')
                                            : t('tabs.project.delete_plot.confirm_last_one')} isLoading={currentDeletedProjectPlot ===
                                            row.original.projectPlotId} id="delete_plot"/>);
                                }}
                  </Translation>
                </HStack>);
                        },
                        enableGlobalFilter: false,
                        enableColumnFilter: false,
                        enableSorting: false,
                    },
                ],
            },
        ];
    }, [projectPlots, isDeletingPlot, currentDeletedProjectPlot]);
    return (<Box width="full">
      {projectPlots && (<Table id={`project-${projectId}-plots`} columns={columns} data={projectPlots || []} isLoading={isLoading} initialSort={[{ id: 'addedAt', desc: true }]} disabled={!!isDeletingPlot} withExport={false} initialColumnVisibility={!isUserOwner
                ? {
                    id: !landPointId,
                    projectPlotId: false,
                    addedAt: false,
                }
                : {
                    id: !landPointId,
                    addedAt: false,
                }}/>)}
    </Box>);
};
export default ProjectPlotsTable;
