import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IONoiseExposurePlanLayerItem = IOLayerItem;
const fetchNoiseExposurePlan = (townId) => () => get(iots.array(IONoiseExposurePlanLayerItem), `legacy/${townId}/noise-exposure-plan`);
const useNoiseExposurePlanLayer = () => {
    createLayersHook({
        fetch: fetchNoiseExposurePlan,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.NOISE_EXPOSURE_PLAN);
};
const NoiseExposurePlanLayer = () => {
    useNoiseExposurePlanLayer();
    return null;
};
export default NoiseExposurePlanLayer;
