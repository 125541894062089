import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import TextInput from 'components/inputs/TextInput';
import UploadInput from 'components/inputs/UploadInput';
import TemplateFilePublipostage from 'components/modals/publipostageModal/importPublipostageSteps/TemplateFilePublipostage';
import CustomTooltip from 'components/tooltips/CustomTooltip';
import useCourier from 'hooks/courier/useCourier';
import useImportPublipostage from 'hooks/import/useImportPublipostage';
import { FROM_COURIER } from 'utils/constants';
import CourierOptions from './CourierOptions';
import CourierTotal from './CourierTotal';
import RecipientFields from './RecipientFields';
import SenderFields from './SenderFields';
const ACCEPTED_FILE_FORMATS = '.doc,.docx,.pdf';
const CourierFields = ({ courierInfo, onClose, canSendCourier, fromPanel, }) => {
    const { t } = useTranslation();
    const { setValue, handleSubmit, trigger, getValues, formState: { isValid, errors }, } = useFormContext();
    const { createCourier, isCreatingCourier, updateCourier, isUpdatingCourier } = useCourier({
        enabledList: false,
        handleSuccess: onClose,
    });
    const { templatesPublipostage, isFetchingTemplate } = useImportPublipostage();
    const country = getValues('country');
    return (<>
      <Box>
        <TextInput name="plotId" type="hidden"/>
        <VStack width="full" spacing={4} alignItems="flex-start">
          {!isEmpty(courierInfo.id) && (<Box>
              <Text fontWeight="bold">{t('courier.current_file')} :</Text>
              <Box fontSize="sm" marginTop={1} padding={2} borderRadius="6px" backgroundColor="colorMode.base">
                {courierInfo.documentName}
              </Box>
            </Box>)}
          <VStack width="full" spacing={1} alignItems="flex-start">
            <Text fontWeight="bold">
              {isEmpty(courierInfo.id)
            ? '1. ' + t('courier.download_file')
            : '1. ' + t('courier.download_update_file')}
            </Text>
            <UploadInput name="file" acceptedFileFormat={ACCEPTED_FILE_FORMATS}/>
            <Box w="full">
              <TemplateFilePublipostage templates={templatesPublipostage} isFetching={isFetchingTemplate} showButtons={false} fromPanel={FROM_COURIER}/>
            </Box>
          </VStack>

          <RecipientFields fromPanel={fromPanel}/>

          <SenderFields title={'3. ' + t('courier.fill_data_sender')} senderId={courierInfo?.senderId}/>

          <CourierOptions title={`4. ` + t('courier.option')}/>

          <CourierTotal courierInfo={courierInfo}/>
        </VStack>
      </Box>

      <HStack paddingY={2} justifyContent="flex-end">
        {!isCreatingCourier && (<Button paddingX={10} variant="ghostBorder" onClick={onClose}>
            {t('global.actions.cancel')}
          </Button>)}
        {courierInfo?.id && (<Button paddingX={10} variant="primary" type="submit" isLoading={isUpdatingCourier} loadingText={t('global.status.updating')} isDisabled={!isValid && !isEmpty(errors)} onClick={handleSubmit((data) => {
                updateCourier(data);
            })} data-cy="courier_form_update">
            {t('courier.action.update')}
          </Button>)}
        {!isCreatingCourier && !courierInfo?.id && (<Button paddingX={10} variant="primary" type="submit" isLoading={isCreatingCourier} isDisabled={(!isValid && !isEmpty(errors)) || country !== 'FR'} loadingText={t('global.status.creating')} onClick={handleSubmit((data) => {
                data.send = 'later';
                createCourier(data);
            })} data-cy="courier_form_submit">
            {t('courier.action.send_later')}
          </Button>)}
        {!courierInfo?.id && (<CustomTooltip label={!canSendCourier && t('courier.no_credits')}>
            <Box>
              <Button paddingX={10} variant="primary" isLoading={isCreatingCourier} loadingText={t('global.status.creating')} isDisabled={!canSendCourier ||
                (!isValid && !isEmpty(errors)) ||
                country !== 'FR'} onClick={() => {
                isValid ? setValue('confirmMode', true) : trigger();
            }} data-cy="courier_form_submit_send">
                {t('courier.action.send')}
              </Button>
            </Box>
          </CustomTooltip>)}
      </HStack>
    </>);
};
export default CourierFields;
