import * as React from 'react';
import { memo } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { useExtraFilters } from 'hooks/contexts/useFiltersContext';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { IOCoordinate } from 'types/types';
import { filtersKey } from 'utils/filters';
import AgricultureRearingCompanyTable from './AgricultureRearingCompanyTable';
const IOAgricultureRearingCompanyItem = iots.type({
    id: iots.string,
    town: iots.string,
    townId: iots.string,
    plotId: iots.string,
    plotNumber: iots.string,
    plotStatus: iots.number,
    company: iots.string,
    siren: iots.string,
    surface: iots.number,
    surfaceTotal: iots.number,
    codeAPE: iots.string,
    lat: IOCoordinate,
    lng: IOCoordinate,
});
const QUERY_KEY = 'agriculture-rearing-company';
const fetchAgricultureRearingCompany = (departmentId, codeAPE) => () => get(iots.array(IOAgricultureRearingCompanyItem), `${departmentId}/agriculture-rearing-company`, { codeAPE: codeAPE });
const AgricultureRearingCompanyPanel = () => {
    const selectedTown = useSelectedTown();
    const extraFilters = useExtraFilters();
    const departmentId = selectedTown?.department;
    const codeAPE = extraFilters[filtersKey.AGRICULTURE_REARING_COMPANY]?.codeAPE || '011';
    const { data, isInitialLoading: isLoading } = useQuery({
        queryKey: [QUERY_KEY, { departmentId, codeAPE }],
        queryFn: fetchAgricultureRearingCompany(departmentId, codeAPE),
        enabled: !!departmentId && !!codeAPE,
    });
    return (<AgricultureRearingCompanyTable id="agriculture-rearing-company" data={data || []} isLoading={isLoading}/>);
};
export default memo(AgricultureRearingCompanyPanel);
