import { useEffect, useRef } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, FormControl, FormErrorMessage, FormLabel, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, Textarea, useDisclosure, } from '@chakra-ui/react';
const EditNoteModal = ({ id, note, triggerItem, handleUpdate, }) => {
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { register, handleSubmit, formState: { isSubmitting, errors }, reset, } = useForm({
        defaultValues: { id, note: note },
    });
    useEffect(() => {
        reset({ id, note });
    }, [id, note]);
    const { ref, ...rest } = register('note', {
        required: t('modals.add_note_modal.invalid_note'),
    });
    const initialRef = useRef(null);
    return (<>
      {triggerItem(onOpen)}
      <Modal isOpen={isOpen} initialFocusRef={initialRef} onClose={onClose} size="md" isCentered>
        <ModalOverlay />
        <ModalContent padding={6} alignItems="center" data-cy="add_note_modal">
          <Box as="form" onSubmit={handleSubmit((data) => handleUpdate(data, onClose))} width="full">
            <Heading as="h4" variant="h4" textAlign="center" paddingY={3}>
              {t('modals.add_note_modal.title_update')}
            </Heading>
            <ModalCloseButton onClick={() => reset()}/>
            <ModalBody width="full">
              <FormControl isInvalid={!!errors?.note}>
                <FormLabel>{t('modals.add_note_modal.note')}</FormLabel>
                <Textarea {...rest} ref={(e) => {
            ref(e);
            initialRef.current = e;
        }} placeholder={t('modals.add_note_modal.note_placeholder')} data-cy="add_note_textarea"/>
                <FormErrorMessage>{errors?.note?.message}</FormErrorMessage>
              </FormControl>
            </ModalBody>

            <ModalFooter alignSelf="end">
              <Button variant="ghost" onClick={onClose} marginRight={2}>
                {t('modals.add_note_modal.cancel')}
              </Button>
              <Button isDisabled={isSubmitting} type="submit" variant="primary" data-cy="add_note_modal_submit">
                {t('modals.add_note_modal.update')}
              </Button>
            </ModalFooter>
          </Box>
        </ModalContent>
      </Modal>
    </>);
};
export default EditNoteModal;
