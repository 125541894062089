import * as React from 'react';
import { Center, Spinner, Stack } from '@chakra-ui/react';
import PlotFeasibilityTable from 'components/tabs/plots/PlotSections/feasibility/FeasibilityTable';
import { useSelectedTown } from '../../../../hooks/contexts/useLocationContext';
const ProjectFeasibilityTable = ({ feasibilityPlots, feasibilityMainPlot, secondaryPlots, isInitialLoading, }) => {
    const selectedTown = useSelectedTown();
    if ((!feasibilityMainPlot || !feasibilityPlots) && isInitialLoading) {
        return (<Center height="100%" p={10}>
        <Spinner size="lg"/>
      </Center>);
    }
    if (!!feasibilityMainPlot &&
        Object.keys(feasibilityPlots).length > 0 &&
        !isInitialLoading)
        return (<Stack spacing={3} width="100%">
        <PlotFeasibilityTable feasibility={feasibilityMainPlot} feasibilityPlots={secondaryPlots} isLoadingFeasibilityPlot={isInitialLoading} hasFeasibility={selectedTown?.hasFeasibility}/>
      </Stack>);
    return null;
};
export default ProjectFeasibilityTable;
