import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Table, Td, Text, Th, Tr } from '@chakra-ui/react';
import * as iots from 'io-ts';
import { CELL_TOWER_GENERATION_VALUES, CELL_TOWER_OPERATOR_VALUES, CELL_TOWER_STATUS_VALUES, CELL_TOWER_SYSTEM_VALUES, } from 'utils/constants';
export const IOCellTowerInfoOperatorDetail = iots.type({
    id: iots.string,
    systemId: iots.number,
    generationId: iots.number,
    statusId: iots.number,
});
export const IOCellTowerInfoOperator = iots.type({
    id: iots.number,
    details: iots.array(IOCellTowerInfoOperatorDetail),
});
export const IOCellTowerInfo = iots.type({
    height: iots.number,
    operators: iots.array(IOCellTowerInfoOperator),
});
const CellTowerInfoWindow = ({ info, }) => {
    const { t } = useTranslation();
    if (!info) {
        return null;
    }
    return (<Box maxHeight="400px">
      {info.height && (<Box>
          {t('cell_tower.height')} : {info.height} m
        </Box>)}
      {info.operators.map((operator) => {
            return (<Table key={operator.id} width="100%" marginBottom={1}>
            <Tr>
              <Th colSpan={3}>
                {operator.id &&
                    CELL_TOWER_OPERATOR_VALUES[operator.id] &&
                    t(CELL_TOWER_OPERATOR_VALUES[operator.id].label)}
              </Th>
            </Tr>
            <Tr>
              <Th>{t('cell_tower.generation.label')}</Th>
              <Th>{t('cell_tower.system.label')}</Th>
              <Th>{t('cell_tower.status.label')}</Th>
            </Tr>
            {operator.details.map((item) => {
                    return (<Tr key={item.id}>
                  <Td>
                    <Text>
                      {item.generationId &&
                            CELL_TOWER_GENERATION_VALUES[item.generationId] &&
                            t(CELL_TOWER_GENERATION_VALUES[item.generationId].label)}
                    </Text>
                  </Td>
                  <Td>
                    <Text>
                      {item.systemId &&
                            CELL_TOWER_SYSTEM_VALUES[item.systemId] &&
                            t(CELL_TOWER_SYSTEM_VALUES[item.systemId].label)}
                    </Text>
                  </Td>
                  <Td>
                    <Text>
                      {item.statusId &&
                            CELL_TOWER_STATUS_VALUES[item.statusId] &&
                            t(CELL_TOWER_STATUS_VALUES[item.statusId].label)}
                    </Text>
                  </Td>
                </Tr>);
                })}
            <Tr>
              <Td colSpan={3}>&nbsp;</Td>
            </Tr>
          </Table>);
        })}
    </Box>);
};
export default CellTowerInfoWindow;
