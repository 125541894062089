import { useCallback, useMemo, useState } from 'react';
import { useFilters } from 'hooks/contexts/useFiltersContext';
import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getNormalizedCoordinates } from 'utils/map';
import { getBaseTileUrlHosts3 } from 'utils/multiplexage';
import { CST_POSITIONS } from 'utils/tilesPositions';
const MAP_LINK_APARTMENT = 'project_prix_location_appart';
const MAP_LINK_HOUSE = 'project_prix_location_maison';
const getLayerTileUrl = (map, tileWidth, tileHeight, enabledFilter, layerFilterKey) => (coord, zoom) => {
    if (enabledFilter !== layerFilterKey) {
        return null;
    }
    const normCoord = getNormalizedCoordinates(coord, zoom);
    if (!normCoord) {
        return null;
    }
    const link = layerFilterKey === filtersKey.THEMATIC_MAP_RENT_APARTMENT
        ? MAP_LINK_APARTMENT
        : MAP_LINK_HOUSE;
    return (getBaseTileUrlHosts3() +
        'cgi-bin/mm/mapserv.cgi?map=/var/data/public_maps/' +
        link +
        '.map&LAYERS=communes_prix_location' +
        '&mode=tile&tilemode=gmap&tile=' +
        coord.x +
        '+' +
        coord.y +
        '+' +
        zoom +
        '&FORMAT=image%2Fpng&TRANSPARENT=true&TILED=true&CRS=EPSG:3857');
};
const useThematicMapRentLayers = (layerFilterKey) => {
    const filters = useFilters();
    const [legends, setLegends] = useState({});
    const enabledMap = useMemo(() => filters[filtersKey.THEMATIC_MAPS] || null, [filters]);
    const getTileUrl = useCallback((map, tileWidth, tileHeight) => getLayerTileUrl(map, tileWidth, tileHeight, enabledMap, layerFilterKey), [enabledMap]);
    const getLegend = useCallback(() => getLegends(enabledMap, layerFilterKey), [enabledMap]);
    const getLegends = (enabledFilter, layerFilterKey) => {
        const newLegends = { ...legends };
        if (newLegends[enabledMap]) {
            return newLegends[enabledMap];
        }
        const color = {
            1: '#0000FF',
            2: '#3030D0',
            3: '#5050B0',
            4: '#808080',
            5: '#B0B050',
            6: '#D0D030',
            7: '#FFD000',
            8: '#FFB000',
            9: '#FF8000',
            10: '#FF5000',
            11: '#FF3000',
            12: '#FF0000',
            13: '#C0C0C0',
        };
        const enabledLegends = {};
        const label = layerFilterKey === filtersKey.THEMATIC_MAP_RENT_APARTMENT
            ? 'thematic_map_rent_apartment'
            : 'thematic_map_rent_house';
        if (enabledFilter === layerFilterKey) {
            for (let i = 1; i <= 13; i++) {
                enabledLegends[`${label}_${i}`] = {
                    layerKey: layerFilterKey,
                    label: `${label}_${i}`,
                    needsTranslate: true,
                    color: color[i],
                    opacity: 0.6,
                };
            }
        }
        newLegends[enabledMap] = enabledLegends;
        setLegends(newLegends);
        return enabledLegends;
    };
    createTilesLayerHook({
        getLayerTileUrl: getTileUrl,
        position: CST_POSITIONS[layerFilterKey],
        getLegends: getLegend,
        refreshParam: enabledMap,
    })(filtersKey.THEMATIC_MAPS);
};
const ThematicMapRentLayers = ({ layerFilterKey, }) => {
    useThematicMapRentLayers(layerFilterKey);
    return null;
};
export default ThematicMapRentLayers;
