import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { string } from 'io-ts';
import { get } from 'api/client';
const QUERY_KEY = ['user-countries'];
const fetchUserCountries = () => () => get(iots.array(string), `user/countriesIso2`);
const useUserCountry = () => {
    const { data: countriesIso2, isLoading } = useQuery({
        queryKey: QUERY_KEY,
        queryFn: fetchUserCountries(),
    });
    return {
        countriesIso2,
        isLoading,
    };
};
export default useUserCountry;
