import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useUserProjectsList from 'hooks/crm/useUserProjectsList';
import createLayersHook from 'hooks/layers/createLayersHook';
import { PROJECT_STATUS_VALUES } from 'utils/constants';
import { filtersKey } from 'utils/filters';
const useUserProjectsLayer = () => {
    const { t } = useTranslation();
    const { userProjects } = useUserProjectsList();
    const getLegends = () => {
        const uniqueStatus = [];
        const legends = {};
        userProjects?.map((el) => {
            if (!uniqueStatus?.includes(el?.status)) {
                uniqueStatus.push(el?.status);
            }
        });
        uniqueStatus.map((status) => {
            legends[status] = {
                ...PROJECT_STATUS_VALUES[status],
                layerKey: filtersKey.USER_PROJECTS,
                label: t(PROJECT_STATUS_VALUES[status]?.label),
                borderColor: '#003483',
                borderWidth: 1,
                needsTranslate: false,
            };
        });
        return legends;
    };
    const LEGENDS = getLegends();
    const projectLayerItems = useMemo(() => {
        if (!userProjects) {
            return [];
        }
        const items = [];
        userProjects?.forEach((project) => {
            project.plots.forEach((plot) => {
                items.push({
                    id: project.id + '-' + plot.id,
                    layerKey: filtersKey.USER_PROJECTS,
                    swkt: plot.swkt,
                    type: plot.type,
                    color: plot.color,
                    opacity: plot.opacity,
                    borderColor: plot.borderColor,
                    borderWidth: plot.borderWidth,
                    borderOpacity: plot.borderOpacity,
                });
            });
        });
        return items;
    }, [userProjects]);
    createLayersHook({
        providedData: projectLayerItems,
        legends: LEGENDS,
    })(filtersKey.USER_PROJECTS);
};
const UserProjectsLayer = () => {
    useUserProjectsLayer();
    return null;
};
export default UserProjectsLayer;
