import * as React from 'react';
import { Accordion, Box } from '@chakra-ui/react';
import FiltersTree from 'components/sidebar/FiltersSideBar/FiltersTree';
import { getOpenedIndices } from 'context/FiltersAccordionContext';
import { useOpenedAccordions } from 'hooks/contexts/useFiltersAccordionContext';
import { hideCategory } from 'utils/filters';
const FiltersTreeWrapper = ({ tree, }) => {
    if (!tree) {
        return null;
    }
    const openedAccordions = useOpenedAccordions();
    const openedIndices = React.useMemo(() => getOpenedIndices(tree, openedAccordions), [openedAccordions, tree]);
    return (<Box paddingX={0} paddingY={0} bgColor="colorMode.sidebar500">
      <Accordion id="tree" allowMultiple index={openedIndices} width="full">
        {Object.keys(tree).map((category) => {
            if (hideCategory(tree[category])) {
                return null;
            }
            return (<FiltersTree key={`${category}-0`} tree={tree[category]} level={1} title={category} path={category}/>);
        })}
      </Accordion>
    </Box>);
};
export default FiltersTreeWrapper;
