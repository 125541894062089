import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOTenYearsOldHousingWithRetainedRegulationLayerItem = IOLayerItem;
const fetchTenYearsOldHousingWithRetainedRegulation = (townId) => () => get(iots.array(IOTenYearsOldHousingWithRetainedRegulationLayerItem), `legacy/${townId}/ten-years-old-housing-with-retained-regulation`);
const useTenYearsOldHousingWithRetainedRegulationLayer = () => {
    createLayersHook({
        fetch: fetchTenYearsOldHousingWithRetainedRegulation,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.TEN_YEARS_OLD_HOUSING_WITH_RETAINED_REGULATION);
};
const TenYearsOldHousingWithRetainedRegulationLayer = () => {
    useTenYearsOldHousingWithRetainedRegulationLayer();
    return null;
};
export default TenYearsOldHousingWithRetainedRegulationLayer;
