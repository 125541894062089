import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { del, get, getFile, post } from 'api/client';
import { QUERY_KEY as CONTACT_LIST_QUERY_KEY } from 'hooks/crm/useContactList';
import { COURIER_QUERY_KEY as USER_STATS_CRM_COURIER_QUERY_KEY } from 'hooks/crm/useUserStatisticsCRM';
import useCustomToast from 'hooks/useCustomToast';
import { IOCourier, IOEmpty, IOStatsCourier, } from 'types/api';
export const QUERY_KEY = ['couriers'];
const PLOT_QUERY_KEY = ['couriers_plot'];
export const CONTACT_COURIER_QUERY_KEY = ['couriers_contact'];
export const PROJECT_COURIER_QUERY_KEY = ['couriers_project'];
export const STATS_QUERY_KEY = ['courier_stats'];
const fetchCouriers = () => () => get(iots.array(IOCourier), `couriers`);
const fetchPlotCouriers = (plotId) => () => get(iots.array(IOCourier), `plot/${plotId}/couriers`);
const fetchContactCouriers = (contactId) => () => get(iots.array(IOCourier), `contact/${contactId}/couriers`);
const fetchProjectCouriers = (projectId, landPointId) => () => get(iots.array(IOCourier), `project/${projectId}/couriers`, { landPointId });
const fetchStatsCouriersUser = () => () => get(IOStatsCourier, `courier/stats`);
const createCourierRequest = (courier) => {
    const formData = new FormData();
    for (const property in courier) {
        if (property === 'file') {
            formData.append('file', courier.file[0], courier.file[0]?.name);
        }
        else if (courier[property] !== null) {
            // On n'envoi pas les null car formData transforme null en string "null" :(
            formData.append(property, courier[property]);
        }
    }
    return post(null, `courier/create`, formData, null, false, {
        headers: { 'Content-Type': 'multipart/form-data;' },
    });
};
const downloadCourierFiles = ({ courierIds }) => getFile(`courier/documents`, null, null, 'post', { courierIds });
const sendCourierRequest = ({ courierId }) => post(null, `courier/${courierId}/send`);
const sendMultipleCouriersRequest = ({ couriersIds, }) => post(IOEmpty, `courier/send`, { couriersIds });
const deleteCourierRequest = ({ courierId }) => del(null, `courier/${courierId}/delete`);
const deleteCouriersRequest = ({ courierIds }) => post(null, `courier/delete`, { courierIds });
const updateCourierRequest = (courier) => {
    const formData = new FormData();
    for (const property in courier) {
        if (property === 'file' && courier.file) {
            formData.append('file', courier.file[0], courier.file[0]?.name);
        }
        else if (courier[property] !== null) {
            // On n'envoi pas les null car formData transforme null en string "null" :(
            formData.append(property, courier[property]);
        }
    }
    return post(null, `courier/${courier.id}`, formData, null, false, {
        headers: { 'Content-Type': 'multipart/form-data;' },
    });
};
const useCourier = ({ enabledList, plotId, contactId, projectId, landPointId, handleSuccess, }) => {
    const { getSuccessToast } = useCustomToast();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const invalidateCouriers = () => {
        queryClient.invalidateQueries({ queryKey: QUERY_KEY });
        queryClient.invalidateQueries({ queryKey: STATS_QUERY_KEY });
        queryClient.invalidateQueries({
            queryKey: [USER_STATS_CRM_COURIER_QUERY_KEY],
        });
    };
    const { data: couriers, isFetching: isLoadingCouriers } = useQuery({
        queryKey: QUERY_KEY,
        queryFn: fetchCouriers(),
        enabled: !plotId && !contactId && enabledList,
    });
    const { data: statsCouriersUser, isInitialLoading: isLoadingStatsCouriersUser, } = useQuery({
        queryKey: STATS_QUERY_KEY,
        queryFn: fetchStatsCouriersUser(),
        enabled: enabledList,
    });
    const { data: plotCouriers, isInitialLoading: isLoadingPlotCouriers } = useQuery({
        queryKey: [PLOT_QUERY_KEY, { plotId }],
        queryFn: fetchPlotCouriers(plotId),
        enabled: !!plotId && enabledList,
    });
    const { data: contactCouriers, isInitialLoading: isLoadingContactCouriers } = useQuery({
        queryKey: [CONTACT_COURIER_QUERY_KEY, { contactId }],
        queryFn: fetchContactCouriers(contactId),
        enabled: !!contactId && enabledList,
    });
    const { data: projectCouriers, isInitialLoading: isLoadingProjectCouriers } = useQuery({
        queryKey: [PROJECT_COURIER_QUERY_KEY, { projectId }],
        queryFn: fetchProjectCouriers(projectId, landPointId),
        enabled: !!projectId && enabledList,
    });
    const { mutate: createCourier, isPending: isCreatingCourier } = useMutation({
        mutationFn: createCourierRequest,
        onSuccess: (_data, variables) => {
            invalidateCouriers();
            queryClient.invalidateQueries({ queryKey: CONTACT_LIST_QUERY_KEY });
            if (variables.plotId) {
                queryClient.invalidateQueries({
                    queryKey: [PLOT_QUERY_KEY, { plotId: variables.plotId }],
                });
            }
            if (variables.contactId) {
                queryClient.invalidateQueries({
                    queryKey: [
                        CONTACT_COURIER_QUERY_KEY,
                        { contactId: variables.contactId },
                    ],
                });
            }
            getSuccessToast({
                title: t('courier.toast.courier_created'),
                id: 'create_courier',
            });
            handleSuccess && handleSuccess();
        },
    });
    const { mutate: getCourierFiles, isPending: isDownloadingFiles } = useMutation({
        mutationFn: downloadCourierFiles,
    });
    const { mutate: sendCourier, isPending: isSendingCourier } = useMutation({
        mutationFn: sendCourierRequest,
        onSuccess: (_data) => {
            invalidateCouriers();
            if (_data.plotId) {
                queryClient.invalidateQueries({
                    queryKey: [PLOT_QUERY_KEY, { plotId: _data.plotId }],
                });
            }
            if (_data.contactId) {
                queryClient.invalidateQueries({
                    queryKey: [CONTACT_COURIER_QUERY_KEY, { contactId: _data.contactId }],
                });
            }
            getSuccessToast({
                title: t('courier.toast.courier_send'),
            });
            handleSuccess && handleSuccess();
        },
    });
    const { mutate: sendMultipleCouriers, isPending: isSendingMultipleCouriers } = useMutation({
        mutationFn: sendMultipleCouriersRequest,
        onSuccess: () => {
            invalidateCouriers();
            getSuccessToast({
                title: t('courier.toast.multiple_couriers_send'),
            });
            handleSuccess && handleSuccess();
        },
    });
    const { mutate: deleteCourier, isPending: isDeleteCourier } = useMutation({
        mutationFn: deleteCourierRequest,
        onSuccess: () => {
            invalidateCouriers();
            getSuccessToast({
                title: t('courier.toast.courier_delete'),
                id: 'delete_courier',
            });
            queryClient.invalidateQueries({ queryKey: [PLOT_QUERY_KEY] });
            queryClient.invalidateQueries({ queryKey: [CONTACT_COURIER_QUERY_KEY] });
            handleSuccess && handleSuccess();
        },
    });
    const { mutate: deleteCouriers, isPending: isDeleteCouriers } = useMutation({
        mutationFn: deleteCouriersRequest,
        onSuccess: () => {
            invalidateCouriers();
            queryClient.invalidateQueries({ queryKey: [PLOT_QUERY_KEY] });
            queryClient.invalidateQueries({ queryKey: [CONTACT_COURIER_QUERY_KEY] });
        },
    });
    const { mutate: updateCourier, isPending: isUpdatingCourier } = useMutation({
        mutationFn: updateCourierRequest,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: QUERY_KEY });
            handleSuccess && handleSuccess();
        },
    });
    return {
        couriers,
        isLoadingCouriers,
        createCourier,
        isCreatingCourier,
        plotCouriers,
        isLoadingPlotCouriers,
        statsCouriersUser,
        isLoadingStatsCouriersUser,
        contactCouriers,
        isLoadingContactCouriers,
        projectCouriers,
        isLoadingProjectCouriers,
        sendCourier,
        isSendingCourier,
        sendMultipleCouriers,
        isSendingMultipleCouriers,
        deleteCourier,
        isDeleteCourier,
        deleteCouriers,
        isDeleteCouriers,
        getCourierFiles,
        isDownloadingFiles,
        updateCourier,
        isUpdatingCourier,
    };
};
export default useCourier;
