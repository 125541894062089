import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { VECTOR_TYPES } from 'types/types';
import { filtersKey } from 'utils/filters';
import { getLayerCoordTileUrl } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = () => (coord, zoom) => {
    return getLayerCoordTileUrl('LOCALISATION.MATS.EOLIEN', 'LOCALISATION.MATS.EOLIEN', zoom, coord);
};
const getLegends = () => {
    const legends = {};
    legends.wind_tower_service = {
        layerKey: filtersKey.WIND_TOWER,
        label: 'wind_tower_service',
        needsTranslate: true,
        color: '#67D059',
        opacity: 1,
        borderColor: '#333333',
        borderWidth: 1,
        height: 10,
        polygonType: VECTOR_TYPES.point,
    };
    legends.wind_tower_allowed_no_service = {
        layerKey: filtersKey.WIND_TOWER,
        label: 'wind_tower_allowed_no_service',
        needsTranslate: true,
        color: '#1E8D3A',
        opacity: 1,
        borderColor: '#333333',
        borderWidth: 1,
        height: 10,
        polygonType: VECTOR_TYPES.point,
    };
    legends.wind_tower_instruction = {
        layerKey: filtersKey.WIND_TOWER,
        label: 'wind_tower_instruction',
        needsTranslate: true,
        color: '#FFA600',
        opacity: 1,
        borderColor: '#333333',
        borderWidth: 1,
        height: 10,
        polygonType: VECTOR_TYPES.point,
    };
    legends.wind_tower_refused = {
        layerKey: filtersKey.WIND_TOWER,
        label: 'wind_tower_refused',
        needsTranslate: true,
        color: '#CD2D2D',
        opacity: 1,
        borderColor: '#333333',
        borderWidth: 1,
        height: 10,
        polygonType: VECTOR_TYPES.point,
    };
    legends.wind_tower_abandoned = {
        layerKey: filtersKey.WIND_TOWER,
        label: 'wind_tower_abandoned',
        needsTranslate: true,
        color: '#74382D',
        opacity: 1,
        borderColor: '#333333',
        borderWidth: 1,
        height: 10,
        polygonType: VECTOR_TYPES.point,
    };
    legends.wind_tower_unknown = {
        layerKey: filtersKey.WIND_TOWER,
        label: 'wind_tower_unknown',
        needsTranslate: true,
        color: '#BDC3C6',
        opacity: 1,
        borderColor: '#333333',
        borderWidth: 1,
        height: 10,
        polygonType: VECTOR_TYPES.point,
    };
    return legends;
};
const useWindTowerLayer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.WIND_TOWER],
        getLegends,
    })(filtersKey.WIND_TOWER);
};
const WindTowerLayer = () => {
    useWindTowerLayer();
    return null;
};
export default WindTowerLayer;
