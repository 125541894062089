import { useContextSelector } from 'use-context-selector';
import AuthContext from 'context/AuthContext';
export const useUserData = () => {
    return useContextSelector(AuthContext, (state) => {
        return state.userData;
    });
};
export const useUpdateUserData = () => {
    return useContextSelector(AuthContext, (state) => state.updateUserData);
};
export const useIsAuthenticated = () => {
    return useContextSelector(AuthContext, (state) => state.isAuthenticated);
};
export const useToken = () => {
    return useContextSelector(AuthContext, (state) => state.token);
};
export const useLogin = () => {
    return useContextSelector(AuthContext, (state) => ({
        login: state.login,
        loginError: state.loginError,
    }));
};
export const useLogout = () => {
    return useContextSelector(AuthContext, (state) => state.logout);
};
export const useRefreshHandler = () => {
    return useContextSelector(AuthContext, (state) => state.refreshHandler);
};
export const useTwoFactor = () => {
    return useContextSelector(AuthContext, (state) => ({
        sendTwoFactorCode: state.sendTwoFactorCode,
        changeTwoFactorMethod: state.changeTwoFactorMethod,
        resendTwoFactorCode: state.resendTwoFactorCode,
    }));
};
