import * as React from 'react';
import { Stack } from '@chakra-ui/react';
import Filter, { UNKNOWN_LAYER } from 'components/Filter';
import { getFilterKey } from 'utils/filters';
export const MainLayersFiltersSkeleton = () => {
    const filters = [
        'plu',
        {
            id: 'plots',
            title: 'plots',
            needsTranslate: true,
            count: 1,
        },
        'cadastre',
        {
            id: 'user_plots',
            title: 'user_plots',
            needsTranslate: true,
            count: 0,
        },
        {
            id: 'user_projects',
            title: 'user_projects',
            needsTranslate: true,
            count: 0,
        },
    ];
    return filters.map((filter, index) => {
        const filterKey = getFilterKey(filter);
        return (<Filter filterKey={filterKey || UNKNOWN_LAYER} filter={filter} id="main_layers" key={index} disabled={true}/>);
    });
};
const MainLayersFilters = ({ mainLayers, isFilterSideBarLoading, isMainLayersLoading, }) => {
    return (<Stack alignItems="start" spacing={1} id="FiltersTreeHeader" data-cy="main_layers">
      {isFilterSideBarLoading || isMainLayersLoading ? (<MainLayersFiltersSkeleton />) : (mainLayers?.layers?.map((filter, index) => {
            const filterKey = getFilterKey(filter);
            return (<Filter filterKey={filterKey || UNKNOWN_LAYER} filter={filter} id="main_layers" key={index}/>);
        }))}
    </Stack>);
};
export default MainLayersFilters;
