import * as React from 'react';
import { Box, HStack, Stack, Text } from '@chakra-ui/react';
import { TABS_VIEW_HALF_VERTICAL } from 'context/LayoutContext';
import { useTabsView } from 'hooks/contexts/useLayoutContext';
import { flat } from './PlotOwnerDetailWrapper';
const OwnerType = ({ owner }) => {
    const tabsView = useTabsView();
    return (<>
      {flat(owner.info).map((ownerType, index) => {
            if (ownerType.length !== 0) {
                return (<Stack width="100%" key={`owner-type-${index}`} spacing={1} direction={tabsView === TABS_VIEW_HALF_VERTICAL ? 'column' : 'row'}>
              <Box>
                <Text textStyle="paragraph" minWidth="140px" maxWidth="160px">
                  {ownerType.label}
                </Text>
              </Box>

              <Box flex="1">
                {typeof ownerType.value !== 'string' &&
                        typeof ownerType.value !== 'number' ? (Object.values(ownerType.value).map((buildingType, indexBuilding) => {
                        return (<HStack key={`building-type-${index}-${indexBuilding}`} width="100%">
                          {typeof buildingType.value !== 'string' &&
                                typeof buildingType.value !== 'number' ? (Object.values(buildingType.value).map((categoryType, indexCategory) => {
                                return (<HStack key={`category-type-${index}-${indexBuilding}-${indexCategory}`}>
                                    <Text textStyle="paragraph" minWidth="160px">
                                      <Text as="span" textStyle="paragraph" fontWeight={600}>{`${categoryType.value} m²`}</Text>
                                      {` ${categoryType.label}`}
                                    </Text>
                                    {indexCategory === 0 && (<Text as="span" textStyle="paragraph" fontWeight={600}>
                                        {buildingType.label}
                                      </Text>)}
                                  </HStack>);
                            })) : (<Text textStyle="paragraph">
                              <Text as="span" textStyle="paragraph" fontWeight={600}>{`${buildingType.value} m²`}</Text>
                              {` ${buildingType.label}`}
                            </Text>)}
                        </HStack>);
                    })) : (<Text textStyle="paragraph" key={`building-type-${index}`} minWidth="120px">
                    <Text as="span" textStyle="paragraph" fontWeight={600}>{`${ownerType.value} m²`}</Text>
                    {` ${ownerType.label}`}
                  </Text>)}
              </Box>
            </Stack>);
            }
        })}
    </>);
};
export default OwnerType;
