import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { PLOT_URL, useCurrentPath } from 'app';
import qs from 'qs';
import useOpenPlot from 'hooks/plot/useOpenPlot';
const usePlotOpener = () => {
    const currentPath = useCurrentPath();
    const { townId, lat, lng, plotId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const { analyticDetail, analyticOrigin, from } = qs.parse(searchParams.toString(), {
        ignoreQueryPrefix: true,
    });
    const openPlot = useOpenPlot({
        townId,
        lat,
        lng,
        plotId,
        analyticDetail: analyticDetail,
        analyticOrigin: analyticOrigin,
        from: from,
    });
    useEffect(() => {
        if (currentPath !== PLOT_URL) {
            return;
        }
        if (!townId || !lat || !lng || !plotId) {
            return;
        }
        openPlot();
        setSearchParams({}, { replace: true });
    }, [townId, lat, lng, plotId]);
};
export default usePlotOpener;
