import { getFile } from 'api/client';
import { useGetPlotsIds } from 'hooks/contexts/usePlotSearchContext';
const XLSX_URL = 'legacy/plots-excel-export';
const usePlotsSearchExport = () => {
    const getPlotsIds = useGetPlotsIds();
    const plotsIds = getPlotsIds();
    if (!plotsIds) {
        return null;
    }
    return (filteredPlotsIds) => getFile(XLSX_URL, 'xlsx', {}, 'post', {
        plotsIds: filteredPlotsIds?.length
            ? filteredPlotsIds.join(',')
            : plotsIds.join(','),
    });
};
export default usePlotsSearchExport;
