import { useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import { filtersKey } from 'utils/filters';
const BankruptCompaniesPlotsLayer = () => {
    const selectedTown = useSelectedTown();
    const department = selectedTown?.department;
    const queryClient = useQueryClient();
    const data = queryClient.getQueryData([
        'bankrupt-companies-plots',
        department,
    ]);
    const layersData = useMemo(() => {
        return data?.layers ? Object.values(data?.layers) : null;
    }, [data]);
    createLayersHook({
        providedData: layersData,
    })(filtersKey.BANKRUPT_COMPANIES_PLOTS);
    return null;
};
export default BankruptCompaniesPlotsLayer;
