import { useContextSelector } from 'use-context-selector';
import MenuContext from 'context/MenuContext';
export const useMenuItemSelected = () => {
    return useContextSelector(MenuContext, (state) => state?.menuItemSelected);
};
export const useNewsSelected = () => {
    return useContextSelector(MenuContext, (state) => state?.newsSelected);
};
export const useSetNewsSelected = () => {
    return useContextSelector(MenuContext, (state) => state?.setNewsSelected);
};
export const useMenuOpened = () => {
    return useContextSelector(MenuContext, (state) => state?.menuOpened);
};
export const useResetMenu = () => {
    return useContextSelector(MenuContext, (state) => state?.resetMenu);
};
export const useToggleMenu = () => {
    return useContextSelector(MenuContext, (state) => state?.toggleMenu);
};
export const usePopoverOpenId = () => {
    return useContextSelector(MenuContext, (state) => state?.popoverOpenId);
};
export const useSetPopoverOpenId = () => {
    return useContextSelector(MenuContext, (state) => state?.setPopoverOpenId);
};
