import pick from 'lodash/pick';
import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getTileBoundingBox } from 'utils/geo';
import { getBaseTileUrlHost3 } from 'utils/multiplexage';
import { CST_POSITIONS } from 'utils/tilesPositions';
const URL_KEYS = {
    [filtersKey.ABF_NO_BUILDING_ZONE]: 'abf_2020_inconstr',
    [filtersKey.ABF_REGIONAL_PREFECT_ADVICE_REQUIRED]: 'abf_2020_avis_oblig_prefet',
    [filtersKey.ABF_ADVICE_REQUIRED]: 'abf_2020_avis_oblig_abf',
    [filtersKey.ABF_ADVICE_REQUIRED_IF_VISIBLE]: 'abf_2020_avis_oblig_abf_visibilite',
    [filtersKey.ABF_STRONGLY_REGULATED_AREA]: 'abf_2020_fortement_regl',
};
const getLayerTileUrl = (map, tileWidth, tileHeight, enabledFilters) => (coord, zoom) => {
    const boundingBox = getTileBoundingBox(coord, zoom, map, tileWidth, tileHeight);
    const urlParts = Object.values(pick(URL_KEYS, enabledFilters));
    return (getBaseTileUrlHost3() +
        'cgi-bin/mm/mapserv.cgi?map=/var/data/public_maps/project_zones_abf.map&SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=' +
        urlParts.join(',') +
        '&TILED=true&WIDTH=' +
        tileWidth +
        '&HEIGHT=' +
        tileHeight +
        '&CRS=EPSG:4326&STYLES=&BBOX=' +
        boundingBox);
};
const getLegends = (enabledFilters) => {
    const legends = {};
    if (enabledFilters.includes(filtersKey.ABF_NO_BUILDING_ZONE)) {
        legends.abf_protected_site_or_building = {
            layerKey: filtersKey.ABF_NO_BUILDING_ZONE,
            label: 'abf_protected_site_or_building',
            needsTranslate: true,
            color: '#FF0000',
            opacity: 0.6,
        };
    }
    if (enabledFilters.includes(filtersKey.ABF_STRONGLY_REGULATED_AREA)) {
        legends.abf_remarkable_heritage_site_and_psmv = {
            layerKey: filtersKey.ABF_STRONGLY_REGULATED_AREA,
            label: 'abf_remarkable_heritage_site_and_psmv',
            needsTranslate: true,
            color: '#783f04',
            opacity: 0.6,
        };
    }
    if (enabledFilters.includes(filtersKey.ABF_ADVICE_REQUIRED)) {
        legends.abf_listed_site = {
            layerKey: filtersKey.ABF_ADVICE_REQUIRED,
            label: 'abf_listed_site',
            needsTranslate: true,
            color: '#3d8562',
            opacity: 0.6,
        };
    }
    if (enabledFilters.includes(filtersKey.ABF_ADVICE_REQUIRED_IF_VISIBLE)) {
        legends.abf_historical_buildings_protection_area = {
            layerKey: filtersKey.ABF_ADVICE_REQUIRED_IF_VISIBLE,
            label: 'abf_historical_buildings_protection_area',
            needsTranslate: true,
            color: '#073763',
            opacity: 0.6,
        };
    }
    if (enabledFilters.includes(filtersKey.ABF_REGIONAL_PREFECT_ADVICE_REQUIRED)) {
        legends.abf_remarkable_contemporary_architecture = {
            layerKey: filtersKey.ABF_REGIONAL_PREFECT_ADVICE_REQUIRED,
            label: 'abf_remarkable_contemporary_architecture',
            needsTranslate: true,
            color: '#274e13',
            opacity: 0.6,
        };
    }
    return legends;
};
export const ABF_FILTERS = [
    filtersKey.ABF_NO_BUILDING_ZONE,
    filtersKey.ABF_STRONGLY_REGULATED_AREA,
    filtersKey.ABF_ADVICE_REQUIRED_IF_VISIBLE,
    filtersKey.ABF_ADVICE_REQUIRED,
    filtersKey.ABF_REGIONAL_PREFECT_ADVICE_REQUIRED,
];
const useABFLayers = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS.ABF_FILTERS,
        getLegends,
    })(ABF_FILTERS);
};
const ABFLayers = () => {
    useABFLayers();
    return null;
};
export default ABFLayers;
