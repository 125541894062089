import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormControl, FormErrorMessage, FormLabel, Input, } from '@chakra-ui/react';
import { numberWithSpaces } from 'utils/text';
import NotRequired from './NotRequired';
const FormattedNumberInput = ({ label, name, placeholder, disabled, required, isLoading, handleBlur, ...rest }) => {
    const { register, getFieldState } = useFormContext();
    const { error } = getFieldState(name);
    const onKeyUp = (e) => {
        e.target.value = numberWithSpaces(e.target.value.replace(/[^0-9]/g, ''));
    };
    return (<FormControl width="full" isInvalid={Boolean(error)}>
      {label && (<FormLabel>
          {label}
          {!required && <NotRequired />}
        </FormLabel>)}
      <>
        <Input {...register(name, {
        valueAsNumber: true,
        onBlur: (e) => {
            handleBlur(e.target.value?.split(' ').join(''));
        },
    })} placeholder={placeholder} size="md" disabled={disabled || isLoading} isInvalid={Boolean(error)} type="text" onKeyUp={onKeyUp} {...rest}/>
        {Boolean(error) && (<FormErrorMessage>{error?.message}</FormErrorMessage>)}
      </>
    </FormControl>);
};
export default FormattedNumberInput;
