import { useQuery } from '@tanstack/react-query';
import { get } from 'api/client';
const fetchBuildingPermitList = (townshipId) => () => get(null, `legacy/${townshipId}/building-permit-list`);
const useBuildingPermitList = (townshipId) => {
    return useQuery({
        queryKey: ['building-permit-list', { townshipId }],
        queryFn: fetchBuildingPermitList(townshipId),
        enabled: !!townshipId,
    });
};
export default useBuildingPermitList;
