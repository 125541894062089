import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { CRM_TABS } from 'context/TabsContext';
import { useIsTabOpened } from 'hooks/contexts/useTabsContext';
import { IOCoordinate } from 'types/types';
export const IOTeamProject = iots.type({
    userEmail: iots.string,
});
const IOTeamProjectPlots = iots.type({
    id: iots.string,
    color: iots.string,
    swkt: iots.string,
});
const IOProject = iots.type({
    id: iots.string,
    town: iots.string,
    townId: iots.string,
    projectId: iots.string,
    label: iots.string,
    department: iots.string,
    status: iots.number,
    lng: IOCoordinate,
    lat: IOCoordinate,
    surface: iots.number,
    turnover: iots.number,
    rightToBuild: iots.union([iots.number, iots.string]),
    lots: iots.union([iots.number, iots.string]),
    updatedAt: iots.string,
    userEmail: iots.string,
    plots: iots.array(IOTeamProjectPlots),
});
export const QUERY_KEY = ['team-projects'];
const fetchTeamProjectsList = () => get(iots.array(IOProject), 'legacy/team-projects');
const useTeamProjects = () => {
    const isTabOpened = useIsTabOpened();
    const { data: teamProjects, isInitialLoading: isLoading, error, } = useQuery({
        queryKey: QUERY_KEY,
        queryFn: fetchTeamProjectsList,
        enabled: isTabOpened(CRM_TABS.TEAM_PROJECTS),
    });
    return {
        teamProjects,
        isLoading,
        error,
    };
};
export default useTeamProjects;
