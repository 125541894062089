import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IODevelopmentAreaLayerItem = IOLayerItem;
const fetchDevelopmentArea = (townId) => () => get(iots.array(IODevelopmentAreaLayerItem), `legacy/${townId}/development-area`);
const useDevelopmentAreaLayer = () => {
    createLayersHook({
        fetch: fetchDevelopmentArea,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.DEVELOPMENT_AREA);
};
const DevelopmentAreaLayer = () => {
    useDevelopmentAreaLayer();
    return null;
};
export default DevelopmentAreaLayer;
