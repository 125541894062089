import * as React from 'react';
import { useState } from 'react';
import { SettingsIcon } from '@chakra-ui/icons';
import { IconButton, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Slider, SliderFilledTrack, SliderMark, SliderThumb, SliderTrack, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_MAP_BUTTONS } from 'analytics/useAnalytics';
import { useMap } from 'hooks/contexts/useMapContext';
import { useUpdateFilterConfig } from 'hooks/contexts/useFiltersContext';
const SetOpacityButton = ({ tilesPosition, filterKey, filtersConfig, }) => {
    const map = useMap();
    const { trackEvent } = useAnalytics();
    const updateFilterConfig = useUpdateFilterConfig();
    const tile = map.overlayMapTypes?.getAt(tilesPosition);
    const defaultOpacity = (filtersConfig[filterKey]?.opacity ?? 1) * 100;
    //console.log(filtersConfig[filterKey]?.opacity)
    const [sliderValue, setSliderValue] = useState(Number(defaultOpacity.toFixed(2)));
    return (<Popover>
      <PopoverTrigger>
        <IconButton variant="link" icon={<SettingsIcon width="12px" color="currentcolor"/>} aria-label="settings-layer" color="colorMode.font700" height="2.4rem" minWidth={6} _hover={{
            color: 'colorMode.font900',
        }} borderRadius={0} onClick={(e) => {
            e.stopPropagation();
            trackEvent({
                category: ANALYTICS_MAP_BUTTONS,
                action: 'Changement opacité',
            });
        }}/>
      </PopoverTrigger>
      <PopoverContent w={150}>
        <PopoverArrow />
        <PopoverBody>
          <Slider defaultValue={defaultOpacity} min={0} max={100} colorScheme="red.500" onChange={(v) => {
            tile?.setOpacity(v / 100);
            setSliderValue(v);
            updateFilterConfig([filterKey], { opacity: v / 100 });
        }} onClick={(e) => {
            e.stopPropagation();
        }}>
            <SliderMark value={sliderValue} textAlign="center" bg="red.500" color="white" mt="-6" ml="-6" w="10" fontSize="12">
              {sliderValue}%
            </SliderMark>
            <SliderTrack>
              <SliderFilledTrack bgColor="red.500"/>
            </SliderTrack>
            <SliderThumb />
          </Slider>
        </PopoverBody>
      </PopoverContent>
    </Popover>);
};
export default SetOpacityButton;
