import * as React from 'react';
import { useState } from 'react';
import { Button } from '@chakra-ui/react';
import { t } from 'i18next';
import FourthStep from 'components/modals/contactModal/importContactsSteps/FourthStep';
import useImportContactSteps from 'components/modals/contactModal/importContactsSteps/useImportContactSteps';
import FirstStep from './importContactsSteps/FirstStep';
import SecondStep from './importContactsSteps/SecondStep';
import ThirdStep from './importContactsSteps/ThirdStep';
const ImportContactsForm = ({ closeDrawer }) => {
    const [step, setStep] = useState(1);
    const goToNextStep = () => setStep((prevStep) => prevStep + 1);
    const { handleFileData, handleSelectedTab, handleFieldsMapping, fileData, selectedTab, importData, } = useImportContactSteps(goToNextStep);
    const PreviousButton = (<Button size="sm" variant="outline" isLoading={false} loadingText={t('global.status.creating')} onClick={() => setStep((prevStep) => prevStep - 1)} isDisabled={step === 1} data-cy="import_previous_button">
      {t('contact.actions.previous')}
    </Button>);
    return (<>
      {step === 1 && (<FirstStep handleFileData={handleFileData}>{PreviousButton}</FirstStep>)}
      {step === 2 && (<SecondStep handleSelectedTab={handleSelectedTab} fileData={fileData}>
          {PreviousButton}
        </SecondStep>)}
      {step === 3 && (<ThirdStep handleNextStep={handleFieldsMapping} handleLastStep={closeDrawer} fileData={fileData} selectedTab={selectedTab} importData={importData}>
          {PreviousButton}
        </ThirdStep>)}
      {step === 4 && (<FourthStep handleLastStep={closeDrawer} importData={importData}>
          {PreviousButton}
        </FourthStep>)}
    </>);
};
export default ImportContactsForm;
