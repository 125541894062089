import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Circle, Heading, HStack, Spinner, Stack, Text, VStack, } from '@chakra-ui/react';
import { useNewsSelected, useSetNewsSelected, } from 'hooks/contexts/useMenuContext';
import useNews from 'hooks/news/useNews';
import useNewsAllRead from 'hooks/news/useNewsAllRead';
import useNewsCount from 'hooks/news/useNewsCount';
import { formatDate } from 'utils/formatDate';
const NewsList = () => {
    const setNewsSelected = useSetNewsSelected();
    const newsSelected = useNewsSelected();
    const { t } = useTranslation();
    const { isLoading, data: news } = useNews();
    const { data: newsCount } = useNewsCount();
    const mutation = useNewsAllRead();
    useEffect(() => {
        if (newsCount?.freshNews > 0) {
            mutation.mutate();
        }
    }, []);
    useEffect(() => {
        if (!isLoading && !newsSelected && news && news.length > 0) {
            setNewsSelected(news[0].id);
        }
    }, [isLoading, news]);
    return (<Stack height="100%" spacing={0}>
      <Heading as="h4" variant="h4" color="colorMode.font900" padding={6}>
        {t('news.title')}
      </Heading>
      {isLoading && <Spinner />}
      {!isLoading &&
            news &&
            news.map((news) => {
                return (<Box key={news.id} onClick={() => setNewsSelected(news.id)} backgroundColor={newsSelected === news.id
                        ? 'colorMode.background700'
                        : 'transparent'} cursor="pointer" _hover={{
                        backgroundColor: 'colorMode.background700',
                    }}>
              <VStack alignItems="flex-start" padding={6}>
                <HStack>
                  <Text textStyle="small" color="colorMode.font800">
                    {formatDate(news.date, 'shortLetter')}
                  </Text>
                  {news.unread && (<Circle zIndex={50} backgroundColor="blue.500" size={1.5} marginLeft={3}/>)}
                </HStack>
                <Text textStyle="paragraph" color="colorMode.font900" fontWeight={500} dangerouslySetInnerHTML={{ __html: news.title }}/>
              </VStack>
            </Box>);
            })}
    </Stack>);
};
export default NewsList;
