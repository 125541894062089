import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOPolygonLayerItem } from 'types/api';
import { VECTOR_TYPES } from 'types/types';
import { filtersKey } from 'utils/filters';
const IOElectricalNetworksHTBAerialLayerItem = IOPolygonLayerItem;
const getLegends = () => {
    const legends = {};
    legends.electrical_networks_htb_zero = {
        layerKey: filtersKey.ELECTRICAL_NETWORKS_HTB_AERIAL,
        label: 'electrical_networks_htb_zero',
        needsTranslate: true,
        polygonType: VECTOR_TYPES.polyline,
        color: '#888888',
        borderWidth: 5,
        opacity: 1,
    };
    legends.electrical_networks_htb_45 = {
        layerKey: filtersKey.ELECTRICAL_NETWORKS_HTB_AERIAL,
        label: 'electrical_networks_htb_45',
        needsTranslate: true,
        polygonType: VECTOR_TYPES.polyline,
        color: '#000000',
        borderWidth: 5,
        opacity: 1,
    };
    legends.electrical_networks_htb_63 = {
        layerKey: filtersKey.ELECTRICAL_NETWORKS_HTB_AERIAL,
        label: 'electrical_networks_htb_63',
        needsTranslate: true,
        polygonType: VECTOR_TYPES.polyline,
        color: '#BF0054',
        borderWidth: 5,
        opacity: 1,
    };
    legends.electrical_networks_htb_90 = {
        layerKey: filtersKey.ELECTRICAL_NETWORKS_HTB_AERIAL,
        label: 'electrical_networks_htb_90',
        needsTranslate: true,
        polygonType: VECTOR_TYPES.polyline,
        color: '#FF8000',
        borderWidth: 5,
        opacity: 1,
    };
    legends.electrical_networks_htb_150 = {
        layerKey: filtersKey.ELECTRICAL_NETWORKS_HTB_AERIAL,
        label: 'electrical_networks_htb_150',
        needsTranslate: true,
        polygonType: VECTOR_TYPES.polyline,
        color: '#003DB0',
        borderWidth: 5,
        opacity: 1,
    };
    legends.electrical_networks_htb_200 = {
        layerKey: filtersKey.ELECTRICAL_NETWORKS_HTB_AERIAL,
        label: 'electrical_networks_htb_200',
        needsTranslate: true,
        polygonType: VECTOR_TYPES.polyline,
        color: '#69e1e5',
        borderWidth: 5,
        opacity: 1,
    };
    legends.electrical_networks_htb_225 = {
        layerKey: filtersKey.ELECTRICAL_NETWORKS_HTB_AERIAL,
        label: 'electrical_networks_htb_225',
        needsTranslate: true,
        polygonType: VECTOR_TYPES.polyline,
        color: '#009414',
        borderWidth: 5,
        opacity: 1,
    };
    legends.electrical_networks_htb_400 = {
        layerKey: filtersKey.ELECTRICAL_NETWORKS_HTB_AERIAL,
        label: 'electrical_networks_htb_400',
        needsTranslate: true,
        polygonType: VECTOR_TYPES.polyline,
        color: '#FF0000',
        borderWidth: 5,
        opacity: 1,
    };
    return legends;
};
const fetchElectricalNetworksHTBAerial = (townId) => () => get(iots.array(IOElectricalNetworksHTBAerialLayerItem), `${townId}/electrical-networks/HTB-aerial`);
const useElectricalNetworksHTBAerialLayer = () => {
    createLayersHook({
        fetch: fetchElectricalNetworksHTBAerial,
        legends: getLegends(),
    })(filtersKey.ELECTRICAL_NETWORKS_HTB_AERIAL);
};
const ElectricalNetworksHTBAerialLayer = () => {
    useElectricalNetworksHTBAerialLayer();
    return null;
};
export default ElectricalNetworksHTBAerialLayer;
