import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get, post } from 'api/client';
import useCustomToast from 'hooks/useCustomToast';
import useOnError from 'hooks/useOnError';
import { IOContactGlobal, IOEmpty, IOPolygonLayerItem } from 'types/api';
import { IOCoordinate } from 'types/types';
const IOUserProject = iots.type({
    id: iots.string,
    townId: iots.string,
    townIds: iots.array(iots.string),
    town: iots.string,
    department: iots.string,
    label: iots.string,
    status: iots.number,
    contactCount: iots.number,
    plotCount: iots.number,
    lng: IOCoordinate,
    lat: IOCoordinate,
    townLng: IOCoordinate,
    townLat: IOCoordinate,
    surface: iots.number,
    turnover: iots.number,
    destination: iots.string,
    rightToBuild: iots.union([iots.number, iots.string]),
    lots: iots.union([iots.number, iots.string]),
    modifiedAt: iots.string,
    plotIds: iots.array(iots.string),
    plots: iots.array(IOPolygonLayerItem),
    contacts: iots.array(IOContactGlobal),
});
export const QUERY_KEY = ['user-projects'];
export const fetchUserProjectsList = () => () => get(iots.array(IOUserProject), 'legacy/user-projects');
const deleteFromUserProjects = ({ projects, }) => post(IOEmpty, `projects/remove`, { projects });
const useUserProjectsList = () => {
    const { t } = useTranslation();
    const onErrorCallback = useOnError();
    const queryClient = useQueryClient();
    const { getSuccessToast } = useCustomToast();
    const { data: userProjects, isInitialLoading } = useQuery({
        queryKey: QUERY_KEY,
        queryFn: fetchUserProjectsList(),
    });
    const getProjectDetail = (id) => userProjects?.find((project) => project?.id === id);
    const { mutate: removeProjects } = useMutation({
        mutationFn: deleteFromUserProjects,
        onError: (err) => onErrorCallback(err),
        onSuccess: (_data, { projects }) => {
            queryClient.invalidateQueries({ queryKey: QUERY_KEY });
            getSuccessToast({
                title: t('tabs.user_projects.selected_projects_removed', {
                    count: projects.length,
                }),
                id: 'projects_removed_success',
            });
        },
    });
    return { userProjects, isInitialLoading, getProjectDetail, removeProjects };
};
export default useUserProjectsList;
