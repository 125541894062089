import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { del, get, post } from 'api/client';
import { useUserData } from 'hooks/contexts/useAuthContext';
import useCustomToast from 'hooks/useCustomToast';
import { IOEmpty } from 'types/api';
import { IOPlotsSearchSavedFilter, } from 'utils/plotsSearch';
const fetchPlotsSearchSavedFilters = () => get(iots.array(IOPlotsSearchSavedFilter), 'legacy/plots-search/saved-filters');
const deletePlotsSearchSavedFilter = (id) => del(IOEmpty, `legacy/plots-search/saved-filters/${id}`);
const addPlotsSearchSavedFilter = (filter) => post(IOEmpty, 'legacy/plots-search/saved-filters', filter);
const usePlotsSearchSavedFilters = () => {
    const { t } = useTranslation();
    const userData = useUserData();
    const queryClient = useQueryClient();
    const { getSuccessToast, getErrorToast } = useCustomToast();
    const queryKey = ['plots-search-saved-filters', userData?.email];
    const { data: savedFilters } = useQuery({
        queryKey,
        queryFn: fetchPlotsSearchSavedFilters,
        enabled: !!userData && !!userData.email,
    });
    const { mutate: deleteFilter } = useMutation({
        mutationFn: deletePlotsSearchSavedFilter,
        onMutate: async (id) => {
            await queryClient.cancelQueries({ queryKey });
            const previousSavedFilters = queryClient.getQueryData(queryKey) ?? [];
            queryClient.setQueryData(queryKey, (old) => {
                const newData = [...old];
                const index = newData.findIndex((filter) => filter.id === id);
                if (index > -1) {
                    newData.splice(index, 1);
                }
                return newData;
            });
            return { previousSavedFilters };
        },
        onError: (err, id, context) => {
            queryClient.setQueryData(queryKey, context.previousSavedFilters);
            getErrorToast({
                title: t('global.error'),
                id: 'toast_saved_search_error',
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKey });
            getSuccessToast({
                title: t('header.search_plot.delete_search_success'),
                id: 'delete_search',
            });
        },
    });
    const { mutate: addFilter } = useMutation({
        mutationFn: addPlotsSearchSavedFilter,
        onMutate: async (newFilter) => {
            await queryClient.cancelQueries({ queryKey });
            const previousSavedFilters = queryClient.getQueryData(queryKey) ?? [];
            queryClient.setQueryData(queryKey, (old) => {
                return [
                    ...old,
                    {
                        id: 'new',
                        ...newFilter,
                    },
                ];
            });
            return { previousSavedFilters };
        },
        onError: (err, newFilter, context) => {
            queryClient.setQueryData(queryKey, context.previousSavedFilters);
            getErrorToast({
                title: t('global.error'),
                id: 'save_search',
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKey });
            getSuccessToast({
                title: t('header.search_plot.save_search_success'),
                id: 'save_search',
            });
        },
    });
    return { savedFilters, deleteFilter, addFilter };
};
export default usePlotsSearchSavedFilters;
