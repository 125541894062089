import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IORichSoilProtectedAreaLayerItem = IOLayerItem;
const fetchRichSoilProtectedArea = (townId) => () => get(IORichSoilProtectedAreaLayerItem, `legacy/${townId}/rich-soil-protected-area`);
const useRichSoilProtectedAreaLayer = () => {
    createLayersHook({
        fetch: fetchRichSoilProtectedArea,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.RICH_SOIL_PROTECTED_AREA);
};
const RichSoilProtectedAreaLayer = () => {
    useRichSoilProtectedAreaLayer();
    return null;
};
export default RichSoilProtectedAreaLayer;
