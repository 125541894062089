import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOPPRFloodingLimitedBuildingZoneLayerItem = IOLayerItem;
const fetchPPRFloodingLimitedBuildingZone = (townId) => () => get(iots.array(IOPPRFloodingLimitedBuildingZoneLayerItem), `legacy/${townId}/ppr-flooding-limited-building-zone`);
const usePPRFloodingLimitedBuildingZoneLayer = () => {
    createLayersHook({
        fetch: fetchPPRFloodingLimitedBuildingZone,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.PPR_FLOODING_LIMITED_BUILDING_ZONE);
};
const PPRFloodingLimitedBuildingZoneLayer = () => {
    usePPRFloodingLimitedBuildingZoneLayer();
    return null;
};
export default PPRFloodingLimitedBuildingZoneLayer;
