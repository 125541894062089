import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOLimitedSizeAndAccommodationCapacityAreaLayerItem = IOLayerItem;
const fetchLimitedSizeAndAccommodationCapacityArea = (townId) => () => get(iots.array(IOLimitedSizeAndAccommodationCapacityAreaLayerItem), `legacy/${townId}/limited-size-and-accommodation-capacity-area`);
const useLimitedSizeAndAccommodationCapacityAreaLayer = () => {
    createLayersHook({
        fetch: fetchLimitedSizeAndAccommodationCapacityArea,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.LIMITED_SIZE_AND_ACCOMMODATION_CAPACITY_AREA);
};
const LimitedSizeAndAccommodationCapacityAreaLayer = () => {
    useLimitedSizeAndAccommodationCapacityAreaLayer();
    return null;
};
export default LimitedSizeAndAccommodationCapacityAreaLayer;
