import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get, post } from 'api/client';
import useOnError from 'hooks/useOnError';
import { IOCoordinate } from 'types/types';
const IOAddressHistory = iots.type({
    historyId: iots.string,
    name: iots.string,
    lat: IOCoordinate,
    lng: IOCoordinate,
    plotId: iots.union([iots.null, iots.string]),
    acuteness: iots.number,
    townId: iots.string,
});
const IOPlotHistory = iots.type({
    historyId: iots.string,
    plotId: iots.string,
    cadastreId: iots.string,
    townId: iots.string,
    townName: iots.string,
    lat: IOCoordinate,
    lng: IOCoordinate,
});
const IOHistory = iots.type({
    addresses: iots.array(IOAddressHistory),
    plots: iots.array(IOPlotHistory),
});
export const QUERY_KEY = ['user-history'];
const fetchHistoryList = () => () => get(IOHistory, 'legacy/user-history');
const addToHistoryRequest = (args) => post(IOHistory, 'legacy/history', args);
const addPlotToHistoryRequest = (args) => post(IOHistory, `legacy/add-plot-to-history/${args.plotId}`, args);
const useUserHistory = (fetchList = true) => {
    const onErrorCallback = useOnError();
    const queryClient = useQueryClient();
    const { data, isInitialLoading: isLoading } = useQuery({
        queryKey: QUERY_KEY,
        queryFn: fetchHistoryList(),
        enabled: fetchList,
    });
    const { mutate: addAddressToHistory } = useMutation({
        mutationFn: addToHistoryRequest,
        onError: (err) => onErrorCallback(err),
        onSuccess: (data) => {
            const currentData = queryClient.getQueryData(QUERY_KEY);
            if (currentData) {
                queryClient.setQueryData(QUERY_KEY, {
                    ...currentData,
                    addresses: data.addresses,
                });
            }
            else {
                queryClient.invalidateQueries({ queryKey: QUERY_KEY });
            }
        },
    });
    const { mutate: addPlotToHistory } = useMutation({
        mutationFn: addPlotToHistoryRequest,
        onError: (err) => onErrorCallback(err),
        onSuccess: (data) => {
            const currentData = queryClient.getQueryData(QUERY_KEY);
            if (currentData) {
                queryClient.setQueryData(QUERY_KEY, {
                    ...currentData,
                    plots: data.plots,
                });
            }
            else {
                queryClient.invalidateQueries({ queryKey: QUERY_KEY });
            }
        },
    });
    return { data, isLoading, addAddressToHistory, addPlotToHistory };
};
export default useUserHistory;
