import * as React from 'react';
import { Box, Image } from '@chakra-ui/react';
const ImageLegend = ({ label, icon, ...props }) => {
    if (typeof icon === 'string' || icon.url) {
        return (<Image marginRight={2} src={typeof icon === 'string' ? icon : icon.url} alt={label} {...props}/>);
    }
    const svgPath = icon.path;
    if (svgPath) {
        return (<Box marginRight={2}>
        <svg fill={icon.fillColor} fillOpacity={icon.fillOpacity} stroke={icon.strokeColor} strokeOpacity={icon.strokeOpacity} strokeWidth={icon.strokeWeight} height={25} width={25} viewBox="0 0 25 25">
          <path d={svgPath}/>
        </svg>
      </Box>);
    }
    return null;
};
export default ImageLegend;
