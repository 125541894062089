import { pick, snakeCase, startCase } from 'lodash';
import { formatAddress } from 'utils/formatText';
const fields = [
    'year',
    'ownerType',
    'name',
    'address',
    'group',
    'form',
    'ape',
    'siren',
];
export const getOwnersFieldName = ({ fieldName, t }) => {
    if (fieldName === 'name') {
        return t(`tabs.plots.export.header.owner_name`, fieldName);
    }
    if (fieldName === 'Propriétaire') {
        return t(`tabs.plots.export.header.owner_surface`, fieldName);
    }
    return t(`tabs.plots.export.header.${snakeCase(fieldName)}`, fieldName);
};
export const formatOwnersCellValue = ({ cellValue, fieldName, t }) => {
    if (fieldName === 'ownerType') {
        return t('tabs.plots.export.owner_type.' + cellValue);
    }
    return cellValue;
};
export const flattenOwnerInfo = (info) => {
    if (Array.isArray(info)) {
        if (info.length === 0) {
            return '';
        }
        return info.map(flattenOwnerInfo).join(', ');
    }
    if (typeof info === 'object') {
        let value = '';
        for (const key in info) {
            value += key;
            if (typeof info[key] === 'object') {
                value += ':\n' + flattenOwnerInfo(info[key]);
            }
            else {
                value += ' ' + flattenOwnerInfo(info[key]) + '\n';
            }
        }
        return value;
    }
    if (typeof info === 'number') {
        return info + ' m²';
    }
    return info;
};
export const formatOwnersData = ({ owners }) => {
    if (!owners) {
        return null;
    }
    const allOwners = [];
    Object.keys(owners)
        .reverse()
        .forEach((year) => {
        Object.keys(owners[year]).forEach((ownerType) => {
            if (ownerType === 'building' || ownerType === 'ground') {
                owners[year][ownerType].forEach((owner) => {
                    owner.name = startCase(owner?.name);
                    const address = formatAddress(owner);
                    allOwners.push({
                        year,
                        ownerType,
                        address,
                        ...pick(owner, fields),
                        ...Object.keys(owner.info).reduce((acc, infoKey) => ({
                            ...acc,
                            [infoKey]: flattenOwnerInfo(owner.info[infoKey]),
                        }), {}),
                    });
                });
            }
        });
    });
    return allOwners;
};
