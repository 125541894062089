import axios from 'axios';
import { fold } from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import { isLeft } from 'fp-ts/lib/These';
import { IntersectionType } from 'io-ts';
import { IOEmpty } from 'types/api';
const printTypeError = (validation, url) => {
    return ('Wrong data received from endpoint ' +
        url +
        ', following fields are invalid:\n' +
        pipe(validation, fold((errors) => errors.map((error) => {
            const lastField = error.context[error.context.length - 1];
            return ('- ' +
                error.context
                    .filter(({ type, key }) => key !== '' && !(type instanceof IntersectionType))
                    .map(({ key }) => key)
                    .join('.') +
                ': expected ' +
                lastField.type.name +
                ' but got ' +
                error.value +
                '\n');
        }), () => ['no errors'])).join(''));
};
export const checkType = (IOType, data, url) => {
    if (process.env.NODE_ENV !== 'development') {
        return;
    }
    if (!IOType) {
        //eslint-disable-next-line
        console.log("Hello fellow developer! ♥ While you're at it, please provide a type to validate data from the following endpoint:", url);
        return;
    }
    if (!data) {
        return;
    }
    const result = IOType.decode(data);
    if (isLeft(result)) {
        //eslint-disable-next-line
        console.error(printTypeError(result, url));
    }
};
export const get = async (IOType, path, params, fullUrl = false, config = {}, catchError = true) => {
    const response = await axios.get(`${fullUrl ? '' : '/api/'}${path}`, {
        params: params,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        catchError,
        ...config,
    });
    const data = response ? response.data : null;
    checkType(IOType, data, path);
    return data;
};
export const post = async (IOType, path, body, params, fullUrl = false, config = {}, catchError = true) => {
    const response = await axios.post(`${fullUrl ? '' : '/api/'}${path}`, body, {
        params,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        catchError,
        ...config,
    });
    const data = response ? response.data : null;
    checkType(IOType, data, path);
    return data;
};
export const patch = async (IOType = IOEmpty, path, body, params, fullUrl = false, config = {}, catchError = true) => {
    const response = await axios.patch(`${fullUrl ? '' : '/api/'}${path}`, body, {
        params,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        catchError,
        ...config,
    });
    const data = response ? response.data : null;
    checkType(IOType, data, path);
    return data;
};
export const put = async (IOType, path, body, params, fullUrl = false, config = {}, catchError = true) => {
    const response = await axios.put(`${fullUrl ? '' : '/api/'}${path}`, body, {
        params,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        catchError,
        ...config,
    });
    const data = response ? response.data : null;
    checkType(IOType, data, path);
    return data;
};
export const del = async (IOType, path, params, fullUrl = false, config = {}, catchError = true) => {
    const response = await axios.delete(`${fullUrl ? '' : '/api/'}${path}`, {
        params: params,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        catchError,
        ...config,
    });
    const data = response ? response.data : null;
    checkType(IOType, data, path);
    return data;
};
export const getFile = async (path, fileExtension, params, method = 'get', body, fullUrl = false, config = {}, catchError = true) => {
    const url = `${fullUrl ? '' : '/api/'}${path}`;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    await axios({
        method: method,
        url: url,
        responseType: 'blob',
        params,
        data: body,
        catchError,
        ...config,
    }).then((response) => {
        if (!response || !response.data) {
            throw new Error('No file found');
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const filenameHeader = response.headers['content-disposition'];
        const filenameRegex = /attachment; filename="(.*)"/;
        const filename = filenameHeader.match(filenameRegex)
            ? filenameHeader.replace(filenameRegex, `$1`)
            : `file${fileExtension ? `.${fileExtension}` : ''}`;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
    });
};
export const getDocumentAndOpen = async (path, filename, params, method = 'get', body, fullUrl = false, config = {}, catchError = true) => {
    const url = `${fullUrl ? '' : '/api/'}${path}`;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    await axios({
        method: method,
        url: url,
        responseType: 'blob',
        params,
        data: body,
        catchError,
        ...config,
    }).then((response) => {
        if (!response || !response.data) {
            throw new Error('No file found');
        }
        const contentType = response.headers['content-type'];
        const url = window.URL.createObjectURL(new Blob([response.data], {
            type: contentType,
        }));
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.setAttribute('download', filename);
        link.click();
    });
};
export const getImage = async (path, photoId, params, method = 'get', body, fullUrl = false, config = {}, catchError = true) => {
    const url = `${fullUrl ? '' : '/api/'}${path}`;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response = await axios({
        method: method,
        url: url,
        responseType: 'blob',
        params,
        data: body,
        catchError,
        ...config,
    });
    if (!response || !response.data) {
        throw new Error('No file found');
    }
    const contentType = response.headers['content-type'];
    const imageUrl = window.URL.createObjectURL(new Blob([response.data], {
        type: contentType,
    }));
    return { [photoId]: imageUrl };
};
