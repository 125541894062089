import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOSensitiveArcheologicalAreaLayerItem = IOLayerItem;
const fetchSensitiveArcheologicalArea = (townId) => () => get(iots.array(IOSensitiveArcheologicalAreaLayerItem), `legacy/${townId}/sensitive-archeological-area`);
const useSensitiveArcheologicalAreaLayer = () => {
    createLayersHook({
        fetch: fetchSensitiveArcheologicalArea,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.SENSITIVE_ARCHEOLOGICAL_AREA);
};
const SensitiveArcheologicalAreaLayer = () => {
    useSensitiveArcheologicalAreaLayer();
    return null;
};
export default SensitiveArcheologicalAreaLayer;
