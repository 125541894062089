import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOParkingLayerItem = IOLayerItem;
const fetchParking = (townId) => () => get(iots.array(IOParkingLayerItem), `legacy/${townId}/parking`);
const useParkingLayer = () => {
    createLayersHook({
        fetch: fetchParking,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.PARKING);
};
const ParkingLayer = () => {
    useParkingLayer();
    return null;
};
export default ParkingLayer;
