import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOPadducLayerItem = IOLayerItem;
const fetchPadducLimitedBuildingZone = (townId) => () => get(iots.array(IOPadducLayerItem), `legacy/${townId}/padduc-limited-building-zone`);
const usePadducLimitedBuildingZoneLayer = () => {
    createLayersHook({
        fetch: fetchPadducLimitedBuildingZone,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.PADDUC_LIMITED_BUILDING_ZONE);
};
const PadducLimitedBuildingZoneLayer = () => {
    usePadducLimitedBuildingZoneLayer();
    return null;
};
export default PadducLimitedBuildingZoneLayer;
