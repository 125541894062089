import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { IODocument } from 'types/api';
import { TYPE_DOCUMENT, TYPE_PHOTO } from 'utils/document';
export const QUERY_KEY = 'team-project-files';
export const getTeamProjectFilesQueryKey = (townshipId, projectId) => [QUERY_KEY, projectId, townshipId];
const fetchTeamProjectFiles = (townshipId, projectId) => () => get(iots.array(IODocument), `project/${projectId}/files/team`);
const useTeamProjectFiles = (isEnabled = false, townshipId, projectId) => {
    const { data: files, isInitialLoading: isLoading } = useQuery({
        queryKey: getTeamProjectFilesQueryKey(townshipId, projectId),
        queryFn: fetchTeamProjectFiles(townshipId, projectId),
        enabled: isEnabled && (!!projectId || !!townshipId),
    });
    const documents = files?.filter((file) => file.typeId === TYPE_DOCUMENT) || [];
    const photos = files?.filter((file) => file.typeId === TYPE_PHOTO) || [];
    return { documents, photos, isLoading };
};
export default useTeamProjectFiles;
