import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOProhibitedConstructionLayerItem = IOLayerItem;
const fetchProhibitedConstruction = (townId) => () => get(iots.array(IOProhibitedConstructionLayerItem), `legacy/${townId}/prohibited-construction`);
const useProhibitedConstructionLayer = () => {
    createLayersHook({
        fetch: fetchProhibitedConstruction,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.PROHIBITED_CONSTRUCTION);
};
const ProhibitedConstructionLayer = () => {
    useProhibitedConstructionLayer();
    return null;
};
export default ProhibitedConstructionLayer;
