import * as React from 'react';
import { useCallback, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as iots from 'io-ts';
import { get } from 'api/client';
import BuildingConsumptionInfoWindow, { IOBuildingConsumptionInfo, } from 'components/infoWindow/BuildingConsumptionInfoWindow';
import { useExtraFilters } from 'hooks/contexts/useFiltersContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import consumptionImg from 'images/POIImages/Pin_Batiment_Consommation.png';
import { IOMarkerLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
const IOBuildingConsumptionMarkerItem = iots.intersection([
    IOMarkerLayerItem,
    iots.type({
        id: iots.string,
        consumption: iots.number,
        sector: iots.string,
    }),
], 'IOBuildingConsumptionMarkerItem');
const fetchBuildingConsumption = (townId, townScot, selectedTown) => () => get(iots.array(IOBuildingConsumptionMarkerItem), `${selectedTown?.department}/building-consumption`);
const fetchBuildingConsumptionInfo = (item) => {
    return get(IOBuildingConsumptionInfo, `building-consumption-info/${item.id}`);
};
const formatInfoWindowContent = (info) => {
    const content = <BuildingConsumptionInfoWindow info={info}/>;
    return ReactDOMServer.renderToStaticMarkup(content);
};
const isConsumptionAllowed = (marker, consumptionMin) => {
    if (!consumptionMin) {
        return true;
    }
    return marker.consumption >= consumptionMin;
};
const isSectorAllowed = (marker, sector) => {
    if (!sector) {
        return true;
    }
    switch (sector.toString()) {
        case '0':
        default:
            return true;
        case '1':
            return marker.sector === 'I';
        case '2':
            return marker.sector === 'T';
        case '3':
            return marker.sector === 'R';
        case '4':
            return (marker.sector === 'X' || marker.sector === 'A' || marker.sector === '');
    }
};
const getLegends = () => {
    const legends = {};
    legends.building_consumption = {
        layerKey: filtersKey.BUILDING_CONSUMPTION,
        label: 'building',
        needsTranslate: true,
        image: consumptionImg,
    };
    return legends;
};
const useBuildingConsumptionLayer = () => {
    const extraFilters = useExtraFilters();
    const [markerInfo, setMarkerInfo] = useState({});
    const allowMarker = useCallback((marker) => {
        const consumption = extraFilters?.[filtersKey.BUILDING_CONSUMPTION]?.consumption ?? null;
        const sector = extraFilters?.[filtersKey.BUILDING_CONSUMPTION]?.sector ?? null;
        return (isConsumptionAllowed(marker, consumption) &&
            isSectorAllowed(marker, sector));
    }, [extraFilters]);
    const getInfoWindowContent = async (marker) => {
        if (markerInfo[marker.id]) {
            return markerInfo[marker.id];
        }
        const telecomInfo = await fetchBuildingConsumptionInfo(marker);
        const content = formatInfoWindowContent(telecomInfo);
        setMarkerInfo({
            ...markerInfo,
            [marker.id]: content,
        });
        return content;
    };
    createLayersHook({
        fetch: fetchBuildingConsumption,
        markerImg: consumptionImg,
        getInfoWindowContent,
        allowLayer: allowMarker,
        updateOnChange: true,
        legends: getLegends(),
    })(filtersKey.BUILDING_CONSUMPTION);
};
const BuildingConsumptionLayer = () => {
    useBuildingConsumptionLayer();
    return null;
};
export default BuildingConsumptionLayer;
