import * as React from 'react';
import { memo } from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';
import { Header } from 'components/header';
import MainLayout from 'components/MainLayout';
import NewsContainer from 'components/news/Container';
import { Sidebar } from 'components/sidebar';
import usePlotOpener from 'hooks/navigation/usePlotOpener';
import useTownOpener from 'hooks/navigation/useTownOpener';
import 'focus-visible/dist/focus-visible';
import NewsModal from './components/news/Modal';
const Homepage = () => {
    useTownOpener();
    usePlotOpener();
    return (<Box as="main" width="100%">
      {process.env.BANNER_MAINTENANCE && (<Text textStyle="paragraph" paddingY={1} fontWeight="700" backgroundColor="blue.500" color="#DDDDDD" textAlign="center">
          {process.env.BANNER_MAINTENANCE}
        </Text>)}
      <Header />

      <HStack height="calc(100vh - 72px)" spacing={0} alignItems="stretch" overflow="hidden" sx={{
            '@media print': {
                height: '100vh',
                overflow: 'visible',
            },
        }}>
        <Sidebar />
        <MainLayout />
        <NewsContainer />
        <NewsModal />
      </HStack>
    </Box>);
};
export default memo(Homepage);
