import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOSpecialArchitecturalRequirementsLayerItem = IOLayerItem;
const fetchSpecialArchitecturalRequirements = (townId) => () => get(iots.array(IOSpecialArchitecturalRequirementsLayerItem), `legacy/${townId}/special-architectural-requirements`);
const useSpecialArchitecturalRequirementsLayer = () => {
    createLayersHook({
        fetch: fetchSpecialArchitecturalRequirements,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.SPECIAL_ARCHITECTURAL_REQUIREMENTS);
};
const SpecialArchitecturalRequirementsLayer = () => {
    useSpecialArchitecturalRequirementsLayer();
    return null;
};
export default SpecialArchitecturalRequirementsLayer;
