import * as React from 'react';
import { Text } from '@chakra-ui/react';
import { getCivilityFromCode } from 'components/inputs/CivilityTitleSelect';
const CourierValidationAddress = ({ sender, }) => {
    const { civility, firstname, lastname, address, addressBP, addressComplement, postalCode, town, } = sender;
    return (<>
      <Text>{`${getCivilityFromCode(civility)} ${firstname} ${lastname}`}</Text>
      <Text>{address}</Text>
      <Text>{`${postalCode} ${town}`}</Text>
      {addressBP && <Text>{`${addressBP}`}</Text>}
      {addressComplement && <Text>{`${addressComplement} `}</Text>}
    </>);
};
export default CourierValidationAddress;
