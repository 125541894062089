import * as React from 'react';
import { IconButton, InputGroup, InputRightElement } from '@chakra-ui/react';
import SearchIcon from 'images/icons/Search';
export const SearchInputWrapper = ({ children, iconColor = 'white', size = 'sm', ariaLabel = 'Search', }) => {
    return (<InputGroup size={size}>
      {children}
      <InputRightElement>
        <IconButton size="xs" cursor="pointer" backgroundColor="red.500" icon={<SearchIcon fill={iconColor} width={13} height={13}/>} aria-label={ariaLabel}/>
      </InputRightElement>
    </InputGroup>);
};
export default SearchInputWrapper;
