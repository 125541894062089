import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormProvider, useForm, useFormContext, } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Heading, HStack, Text, useDisclosure, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_COURIER } from 'analytics/useAnalytics';
import DeleteButton from 'components/buttons/DeleteButton';
import useCourierSender from 'hooks/courier/useCourierSender';
import SenderForm from './SenderForm';
const CreateSenderDrawer = ({ sender, selectRef, }) => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [formType, setFormType] = useState(null);
    const [senderData, setSenderData] = useState(null);
    useEffect(() => {
        setSenderData(sender);
        setValue('senderId', sender && sender.id);
    }, [sender]);
    const validateForm = (values) => {
        const errors = {};
        if (!values?.civility || values?.civility.trim() === '') {
            errors.civility = {
                type: 'required',
                message: 'global.fields.error.required',
            };
        }
        if (!values?.firstname || values?.firstname.trim() === '') {
            errors.firstname = {
                type: 'required',
                message: 'global.fields.error.required',
            };
        }
        if (!values?.lastname || values?.lastname.trim() === '') {
            errors.lastname = {
                type: 'required',
                message: 'global.fields.error.required',
            };
        }
        if (!values?.postalCode || values?.postalCode.trim() === '') {
            errors.postalCode = {
                type: 'required',
                message: 'global.fields.error.required',
            };
        }
        if (!values?.town || values?.town.trim() === '') {
            errors.town = {
                type: 'required',
                message: 'global.fields.error.required',
            };
        }
        return errors;
    };
    const resolver = (values) => {
        return {
            values,
            errors: validateForm(values),
        };
    };
    const methods = useForm({
        resolver,
        defaultValues: {
            civility: sender?.civility ?? '',
            firstname: sender?.firstname ?? '',
            lastname: sender?.lastname ?? '',
            company: sender?.company ?? '',
            town: sender?.town ?? '',
            address: sender?.address ?? '',
            addressBP: sender?.addressBP ?? '',
            postalCode: sender?.postalCode ?? '',
            addressComplement: sender?.addressComplement ?? '',
        },
    });
    const { handleSubmit, formState: { isValid, isDirty }, reset, } = methods;
    const { setValue, getValues } = useFormContext();
    const { createCourierSender, isCreatingCourierSender, editCourierSender, isEditingCourierSender, deleteCourierSender, isDeletingCourierSender, } = useCourierSender({
        handleSuccess: (sender) => {
            setValue('senderId', sender && sender.id);
            setSenderData(sender);
        },
    });
    const onSubmit = (data) => {
        let action = '';
        if (formType === 'create_form') {
            action = 'Créer un expéditeur';
            createCourierSender(data);
        }
        else {
            action = 'Editer un expéditeur';
            editCourierSender(data);
        }
        trackEvent({
            category: ANALYTICS_COURIER,
            action,
        });
        onClose();
    };
    return (<>
      <HStack>
        <Button variant="outline" size="sm" onClick={() => {
            setFormType('create_form');
            reset({});
            onOpen();
        }} data-cy="courier_create_sender">
          <Text as="span" color="inherit">
            {t('courier.sender.create')}
          </Text>
        </Button>
        <Button variant="outline" size="sm" onClick={() => {
            setFormType('update_form');
            reset(senderData);
            onOpen();
        }} data-cy="courier_update_sender" disabled={!getValues('senderId')}>
          <Text as="span" color="inherit">
            {t('courier.sender.update')}
          </Text>
        </Button>
      </HStack>

      <Drawer isOpen={isOpen} onClose={() => onClose()} size="md" returnFocusOnClose={false}>
        <DrawerOverlay />
        <DrawerContent backgroundColor="colorMode.background900">
          <DrawerCloseButton />
          <DrawerHeader>
            <Heading as="h4" variant="h4">
              {t(`courier.sender.create`)}
            </Heading>
          </DrawerHeader>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)} style={{ overflowY: 'auto' }}>
              <DrawerBody display="flex">
                <SenderForm />
              </DrawerBody>
              <DrawerFooter>
                <HStack marginTop={2}>
                  <DeleteButton handleDelete={() => {
            const senderId = getValues('senderId');
            if (senderId) {
                deleteCourierSender(senderId, {
                    onSuccess: () => {
                        trackEvent({
                            category: ANALYTICS_COURIER,
                            action: 'Supprimer un expéditeur',
                        });
                        selectRef?.current?.clearValue();
                        onClose();
                    },
                });
            }
        }} hidden={formType === 'create_form'} isLoading={isDeletingCourierSender} id="courier_delete_sender"/>
                  <Button isLoading={formType === 'create_form'
            ? isCreatingCourierSender
            : isEditingCourierSender} paddingX={10} variant="ghostBorder" onClick={onClose}>
                    {t('global.actions.cancel')}
                  </Button>
                  <Button paddingX={10} variant="primary" type="submit" isLoading={formType === 'create_form'
            ? isCreatingCourierSender
            : isEditingCourierSender} loadingText={t('global.status.creating')} isDisabled={!isValid && !isDirty} data-cy="courier_sender_form_submit">
                    {t('global.actions.validate')}
                  </Button>
                </HStack>
              </DrawerFooter>
            </form>
          </FormProvider>
        </DrawerContent>
      </Drawer>
    </>);
};
export default CreateSenderDrawer;
