import { createInstance } from '@jonkoops/matomo-tracker-react';
const instance = process.env.MATOMO_URL
    ? createInstance({
        urlBase: process.env.MATOMO_URL,
        siteId: 3,
        userId: undefined, // optional, default value: `undefined`.
        trackerUrl: `${process.env.MATOMO_URL}matomo.php`, // optional, default value: `${urlBase}matomo.php`
        //   srcUrl: 'https://matomo.kelfoncier.com/tracking.js', // optional, default value: `${urlBase}matomo.js`
        disabled: process.env.APP_ENV !== 'prod',
        // heartBeat: {
        //   // optional, enabled by default
        //   active: true, // optional, default value: true
        //   seconds: 15, // optional, default value: `15
        // },
        linkTracking: false, // optional, default value: true
        configurations: {
        // optional, default value: {}
        // any valid matomo configuration, all below are optional
        // disableCookies: true,
        // setSecureCookie: true,
        // setRequestMethod: 'POST',
        },
    })
    : null;
export default instance;
