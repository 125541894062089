import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getLayerCoordTileUrl } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = () => (coord, zoom) => {
    return getLayerCoordTileUrl('PROTECTEDAREAS.PNM', 'normal', zoom, coord);
};
const getLegends = () => {
    const legends = {};
    legends.marin_park = {
        layerKey: filtersKey.MARIN_PARK,
        label: 'marin_park',
        needsTranslate: true,
        color: '#53d4d4',
        opacity: 1,
    };
    return legends;
};
const useMarinParkLayer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.MARIN_PARK],
        getLegends,
    })(filtersKey.MARIN_PARK);
};
const MarinParkLayer = () => {
    useMarinParkLayer();
    return null;
};
export default MarinParkLayer;
