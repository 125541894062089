import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Center, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Spinner, Text, VStack, } from '@chakra-ui/react';
import PlotInfoText from 'components/tabs/plots/PlotSections/info/PlotInfoText';
import { useClosePlotModal, useIsPlotModalOpen, usePlotData, } from 'hooks/contexts/useModalContext';
import usePlot from 'hooks/plot/usePlot';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { getPlotCadastreId } from 'utils/plots';
const PlotModal = () => {
    const { t } = useTranslation();
    const { hasPlotInfo } = useSiteConfiguration();
    const isPlotModalOpen = useIsPlotModalOpen();
    const closePlotModal = useClosePlotModal();
    const plotData = usePlotData();
    const { plot, isLoading } = usePlot({
        townshipId: plotData?.townshipId,
        plotId: plotData?.plotId,
        enabled: isPlotModalOpen,
    });
    return (<>
      <Modal isOpen={isPlotModalOpen} onClose={closePlotModal}>
        <ModalOverlay />
        <ModalContent overflow="hidden">
          <ModalHeader>{t('plot_modal.title')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {!plot || isLoading ? (<Center width="full" padding={2} textAlign="center">
                <Spinner />
              </Center>) : (<VStack alignItems="flex-start">
                <Text textStyle="paragraph">
                  <Text as="span" textStyle="paragraph" fontWeight={600}>
                    {t('tabs.plots.section.plot.town')}
                    {' : '}
                  </Text>
                  {plot?.town}
                </Text>

                {/* cadastre */}
                <PlotInfoText label={t('tabs.plots.section.plot.plot_id')} value={getPlotCadastreId(plot)}/>

                {/* plot surface */}
                <PlotInfoText label={t('tabs.plots.section.plot.surface')} value={`${plot?.surface ?? ''} m²`}/>

                {/* zones and subzones */}
                {hasPlotInfo('zone') && (<Text textStyle="paragraph">
                    <Text as="span" fontWeight={600}>
                      {t('tabs.plots.section.plot.zone')}
                      {' : '}
                    </Text>
                    <Text as="span" whiteSpace="nowrap">
                      {plot?.zone
                    .slice(1, plot.zone.length - 1)
                    .replace(';', ' ')}
                      {' / '}
                      {plot?.subZone
                    .slice(1, plot.subZone.length - 1)
                    .replace(';', ' ')}
                    </Text>
                  </Text>)}

                {plot?.streetLine > 0 && (<Text textStyle="paragraph">
                    <Text as="span" textStyle="paragraph" fontWeight={600}>
                      {t('tabs.plots.section.plot.street_line')}
                      {' : '}
                    </Text>

                    {t('tabs.plots.section.plot.street_line_meters', {
                    streetLine: plot?.streetLine,
                })}
                  </Text>)}

                {plot?.address && plot.address.length > 0 && (<>
                    <Text textStyle="paragraph">
                      <Text as="span" textStyle="paragraph" fontWeight={600}>
                        {t('tabs.plots.section.plot.address')}
                        {' : '}
                      </Text>
                      <br />
                      {plot.address[0]}
                    </Text>
                  </>)}
              </VStack>)}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>);
};
export default PlotModal;
