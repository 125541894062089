import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOExternalAspectLayerItem = IOLayerItem;
const fetchExternalAspect = (townId) => () => get(iots.array(IOExternalAspectLayerItem), `legacy/${townId}/external-aspect`);
const useExternalAspectLayer = () => {
    createLayersHook({
        fetch: fetchExternalAspect,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.EXTERNAL_ASPECT);
};
const ExternalAspectLayer = () => {
    useExternalAspectLayer();
    return null;
};
export default ExternalAspectLayer;
