import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IODocUrbaLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOLimitedBuildingAreaEnvironmentLayerItem = IODocUrbaLayerItem;
const fetchLimitedBuildingAreaEnvironment = (townId) => () => get(iots.array(IOLimitedBuildingAreaEnvironmentLayerItem), `${townId}/limited-building-area-environment`);
const useLimitedBuildingAreaEnvironmentLayer = () => {
    createLayersHook({
        fetch: fetchLimitedBuildingAreaEnvironment,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.LIMITED_BUILDING_AREA_ENVIRONMENT);
};
const LimitedBuildingAreaEnvironmentLayer = () => {
    useLimitedBuildingAreaEnvironmentLayer();
    return null;
};
export default LimitedBuildingAreaEnvironmentLayer;
