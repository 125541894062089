export const isFreeDrawing = (mode) => [
    'free_polygon',
    'free_line',
    'free_point',
    'free_text',
    'free_edit',
].includes(mode);
export const pointExists = (path, point) => {
    for (let i = 0; i < path.length; i++) {
        const p = path[i];
        if (p.lat() === point.lat() && p.lng() === point.lng()) {
            return true;
        }
    }
    return false;
};
