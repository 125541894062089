import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Stack, VStack } from '@chakra-ui/react';
import DeathList from 'components/death/DeathList';
import useDeath from 'hooks/filter/useDeath';
const DeathSection = ({ contact }) => {
    const { t } = useTranslation();
    const { deathContact, isLoadingDeathContact } = useDeath(null, contact);
    if (!contact) {
        return;
    }
    return (<>
      <VStack width="full" spacing={3}>
        <Heading as="h4" variant="h4">
          {t('tabs.detail_contact.death')}
        </Heading>
        <Stack width="full">
          <DeathList data={deathContact} isLoading={isLoadingDeathContact}/>
        </Stack>
      </VStack>
    </>);
};
export default DeathSection;
