import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@chakra-ui/react';
import ZoomPlusIcon from 'images/icons/ZoomPlus';
const CreateButton = ({ isDisabled, onClick, label, variant = 'secondary', ...buttonProps }) => {
    const { t } = useTranslation();
    return (<Button isDisabled={isDisabled} variant={variant} onClick={onClick} rightIcon={<Box backgroundColor="white" borderRadius="md" color="colorMode.font700" width="20px" display="flex" justifyContent="center">
          <ZoomPlusIcon width="15" fill="currentColor"/>
        </Box>} {...buttonProps}>
      {t(label)}
    </Button>);
};
export default CreateButton;
