import * as React from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { Box, Button, Flex, Heading, HStack, Icon, Image, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Square, Text, VStack, } from '@chakra-ui/react';
import { t } from 'i18next';
import { getDocumentAndOpen } from 'api/client';
import PlotButton from 'components/buttons/PlotButton';
import Table from 'components/table/Table';
import { columns as historyColumns } from 'components/tabs/plots/PlotSections/crm/HistoryList';
import useTeamPlots from 'hooks/crm/useTeamPlots';
import useTeamImages from 'hooks/useTeamImages';
import FileIcon from 'images/icons/File';
import PwdIcon from 'images/icons/Pwd';
import { getPlotStatusColorFromCode, getPlotStatusFromCode, } from 'utils/constants';
import { formatDate } from 'utils/formatDate';
import { formatNumberToLocale } from 'utils/formatNumber';
import { inDateRange } from 'utils/search';
const getColumnHeader = (column) => (<Text align="center">
    {column && (<Translation>
        {(t) => t(`modals.team_plot.contact_table.${column}`)}
      </Translation>)}
  </Text>);
const COLUMNS = [
    {
        id: 'columns',
        columns: [
            {
                accessorKey: 'titleStr',
                accessorFn: (row) => {
                    if (!row.titleStr)
                        return t('table.empty_column');
                    return row.titleStr;
                },
                header: () => getColumnHeader('title'),
                cell: (info) => (<Text align="center">{info.row.original.titleStr}</Text>),
                filterFn: 'arrIncludesSome',
            },
            {
                accessorKey: 'lastname',
                header: () => getColumnHeader('contact_name'),
                accessorFn: (row) => {
                    if (!row.contactName)
                        return t('table.empty_column');
                    return row.contactName;
                },
                cell: (info) => {
                    return info.row.original.contactName;
                },
                filterFn: 'arrIncludesSome',
            },
            {
                accessorKey: 'comment',
                header: () => getColumnHeader('comment'),
                cell: (info) => info.getValue(),
                enableColumnFilter: false,
                enableSorting: false,
            },
            {
                accessorKey: 'createdAt',
                header: () => getColumnHeader('creation_date'),
                cell: (info) => formatDate(info.getValue()),
                enableGlobalFilter: false,
                filterFn: inDateRange,
            },
            {
                accessorKey: 'surface',
                header: () => getColumnHeader('surface'),
                cell: (info) => (<Translation>
            {(t, { lng }) => formatNumberToLocale(info.getValue(), lng)}
          </Translation>),
                enableColumnFilter: false,
                enableSorting: false,
            },
        ],
    },
];
const TeamPlotModal = ({ plot, triggerItem = (onOpen) => (<Button variant="link-primary" onClick={onOpen}>
      {plot?.cadastreId}
    </Button>), isOpen, onOpen, onClose, }) => {
    const { t } = useTranslation();
    const { teamPlotDetail, isLoadingTeamPlotDetail } = useTeamPlots(isOpen, plot?.plotId, plot?.email);
    const documents = teamPlotDetail?.documents?.filter((doc) => doc.type === 'document') || [];
    const photos = teamPlotDetail?.documents?.filter((doc) => doc.type === 'image') || [];
    const { data: photosFiles, isLoading } = useTeamImages(plot?.email, photos && photos.map((photo) => photo.id));
    return (<>
      {triggerItem(onOpen)}
      <Modal isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading as="h3" variant="h3">
              {t('modals.team_plot.title')}
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text textStyle="paragraph">
              {t('modals.team_plot.belongs_to', { email: plot?.email })}
            </Text>
            <Text textStyle="paragraph">
              {t('modals.team_plot.cadastre', { cadastre: plot?.cadastreId })}
            </Text>
            <Text textStyle="paragraph">{plot?.address}</Text>
            <PlotButton lat={plot?.lat} lng={plot?.lng} townId={plot?.townshipId} marginY={2} variant="link-primary" onOpenPlot={onClose} plotId={plot?.plotId}>
              {t('modals.team_plot.open_plot')}
            </PlotButton>

            {teamPlotDetail && (<VStack alignItems="start" spacing={4}>
                <Box>
                  <Heading as="h4" variant="h4" paddingBottom={2}>
                    {t('modals.team_plot.status')}
                  </Heading>
                  <Text as="div" textStyle="paragraph" display="flex" alignItems="center">
                    <Square size="20px" backgroundColor={getPlotStatusColorFromCode(teamPlotDetail.status)} borderRadius="md" marginRight={2}/>
                    {getPlotStatusFromCode(teamPlotDetail.status)}
                  </Text>
                </Box>
                {teamPlotDetail?.contacts && (<Box>
                    <Heading as="h4" variant="h4" paddingBottom={2}>
                      {t('modals.team_plot.contacts')}
                    </Heading>

                    <Table id={`table-team-plot-${plot?.plotId}-contacts`} columns={COLUMNS} data={teamPlotDetail?.contacts || []} isLoading={isLoadingTeamPlotDetail}/>
                  </Box>)}
                {/* <Box>
              <Heading as="h4" variant="h4">
                {t('modals.team_plot.activity')}
              </Heading>
            </Box> */}
                <Box>
                  <Heading as="h4" variant="h4" paddingBottom={2}>
                    {t('modals.team_plot.history')}
                  </Heading>
                  <Table id={`table-team-plot-${plot?.plotId}-history`} columns={historyColumns} data={teamPlotDetail?.history || []} isLoading={isLoadingTeamPlotDetail} initialSort={[{ id: 'date', desc: true }]}/>
                </Box>
                {teamPlotDetail?.notes && (<Box>
                    <Heading as="h4" variant="h4" paddingBottom={2}>
                      {t('modals.team_plot.notes')}
                    </Heading>

                    {teamPlotDetail.notes.map((note) => {
                    return (<Box key={note.id} backgroundColor="colorMode.base" padding={2} width="full" borderRadius="md">
                          <Text textStyle="paragraph">
                            {t('tabs.plots.section.my_crm.note.from')}{' '}
                            {formatDate(note.date, 'extended')}
                            {' : '}
                            <Text as="span" fontWeight={500}>
                              {note.note}
                            </Text>
                          </Text>
                        </Box>);
                })}
                  </Box>)}
                <Box>
                  <Heading as="h4" variant="h4" paddingBottom={2}>
                    {t('modals.team_plot.documents')}
                  </Heading>
                  <VStack>
                    {documents &&
                documents.map((document) => {
                    return (<HStack key={document.id} width="full" justifyContent="space-between" backgroundColor="colorMode.base" borderRadius="md" padding={4}>
                            <HStack alignItems="center">
                              <Icon as={FileIcon} fill="red.500"/>
                              <Text textStyle="paragraph" fontWeight={500} color="colorMode.font900">
                                {document.name}
                              </Text>
                            </HStack>
                            <HStack>
                              <Button size="sm" color="colorMode.font800" leftIcon={<PwdIcon fill="currentColor"/>} onClick={() => getDocumentAndOpen(`legacy/team-plot-files/${plot?.email}/${document.id}`, document.name)}>
                                {t('tabs.document.view_btn')}
                              </Button>
                            </HStack>
                          </HStack>);
                })}
                  </VStack>
                </Box>
                <Box>
                  <Heading as="h4" variant="h4" paddingBottom={2}>
                    {t('modals.team_plot.photos')}
                  </Heading>
                  <HStack>
                    {photos?.length > 0 &&
                photos.map((photo) => {
                    return isLoading ? (<Spinner key="photo"/>) : (<Link key={photo.id} display="block" href={photosFiles?.[photo.id]} isExternal download={photo.name}>
                            <Flex direction="column" key={photo.id} borderRadius="lg" overflow="hidden" position="relative">
                              <Image src={photosFiles?.[photo.id]} alt={photo.name} height="120px" border="1px solid gray"/>

                              <Box padding={3} backgroundColor="colorMode.base">
                                <Text textStyle="small" noOfLines={1} fontWeight={500}>
                                  {photo.name}
                                </Text>
                              </Box>
                            </Flex>
                          </Link>);
                })}
                  </HStack>
                </Box>
              </VStack>)}
          </ModalBody>

          <ModalFooter>
            <Button variant="outline" onClick={onClose}>
              {t('modals.team_plot.close')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>);
};
export default TeamPlotModal;
