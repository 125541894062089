import { useQuery } from '@tanstack/react-query';
import { get } from 'api/client';
const fetchOwners = (townshipId, query) => () => get(null, `legacy/${townshipId}/owner-search/${query}`);
// personnes_morales_20230207.personnes_morales_bati  personnes_morales_20230207.personnes_morales_non_bati
const fetchPlotOwnerCount = (siren) => () => get(null, `owner/plot/count/bySiren/${siren}`);
const useOwner = ({ townshipId, query, siren, }) => {
    const { data: owners, isInitialLoading: isLoadingOwners } = useQuery({
        queryKey: ['owner-search', { townshipId, query }],
        queryFn: fetchOwners(townshipId, query),
        enabled: !!townshipId && !!query,
    });
    const { data: plotOwnerCount, isInitialLoading: isLoadingPlotOwnerCount } = useQuery({
        queryKey: ['owner-plot-count', { siren }],
        queryFn: fetchPlotOwnerCount(siren),
        enabled: !!siren,
    });
    return {
        owners,
        isLoadingOwners,
        plotOwnerCount,
        isLoadingPlotOwnerCount,
    };
};
export default useOwner;
