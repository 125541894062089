import { useMemo } from 'react';
import { useOpenPlotModal } from 'hooks/contexts/useModalContext';
import { useSelectedProject } from 'hooks/contexts/useTabsContext';
import useProject from 'hooks/crm/useProject';
import createLayersHook from 'hooks/layers/createLayersHook';
import useOpenPlotFromMap from 'hooks/plot/useOpenPlotFromMap';
import { filtersKey } from 'utils/filters';
const useProjectPlotsLayer = ({ map = null, preSelectedProject = null, }) => {
    const selectedProject = preSelectedProject
        ? preSelectedProject
        : useSelectedProject();
    const { projectPlots } = useProject(selectedProject?.townId, selectedProject?.id);
    const openPlotFromMap = useOpenPlotFromMap();
    const openPlotModal = useOpenPlotModal();
    const providedData = useMemo(() => {
        return projectPlots ? Object.values(projectPlots) : null;
    }, [projectPlots]);
    // La gestion de l'affichage en fonction du zoom est directement gérer en css
    // (voir #googleMap .zoom-0 .marker-label-plot)
    createLayersHook({
        providedData,
        onClick: (item) => {
            if (!preSelectedProject) {
                openPlotFromMap(item);
            }
            else {
                openPlotModal({
                    townshipId: item.townId,
                    plotId: item.id,
                });
            }
        },
        currentMap: map,
    })(filtersKey.PROJECT_PLOTS);
};
const ProjectPlotsLayer = ({ map = null, selectedProject = null, }) => {
    useProjectPlotsLayer({ map, preSelectedProject: selectedProject });
    return null;
};
export default ProjectPlotsLayer;
