import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOInstallationLimitLayerItem = IOLayerItem;
const fetchInstallationLimit = (townId) => () => get(iots.array(IOInstallationLimitLayerItem), `legacy/${townId}/installation-limit`);
const useInstallationLimitLayer = () => {
    createLayersHook({
        fetch: fetchInstallationLimit,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.INSTALLATION_LIMIT);
};
const InstallationLimitLayer = () => {
    useInstallationLimitLayer();
    return null;
};
export default InstallationLimitLayer;
