import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Menu, MenuButton, MenuItemOption, MenuList, MenuOptionGroup, Text, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_TELECOMMANDE } from 'analytics/useAnalytics';
import useLocale from 'hooks/useLocale';
import DropdownIcon from 'images/icons/Dropdown';
const LanguageDropdown = ({ hideLanguageMenu = true, }) => {
    const { i18n, t } = useTranslation();
    const options = Object.keys(i18n.services.resourceStore.data);
    const { trackEvent } = useAnalytics();
    const { updateLocale } = useLocale();
    const handleChange = (value) => {
        i18n.changeLanguage(value);
        updateLocale({ lang: value });
    };
    return (!hideLanguageMenu && (<Menu computePositionOnMount>
        {({ isOpen }) => (<>
            <MenuButton as={Button} size="sm" rightIcon={<Box transform={isOpen ? 'rotateX(180deg)' : 'rotateX(0deg)'} transition="transform 0.5s">
                  <DropdownIcon fill="currentColor"/>
                </Box>} variant="ghost" color="colorMode.font800">
              <Box>
                <Text textStyle="small" textTransform="capitalize">
                  {i18n.resolvedLanguage}
                </Text>
              </Box>
            </MenuButton>

            <MenuList overflow="hidden" backgroundColor="colorMode.background900" borderRadius="md" className="smooth-box-shadow">
              <MenuOptionGroup defaultValue={i18n.resolvedLanguage} type="radio" onChange={handleChange}>
                {options.map((option, index) => (<MenuItemOption key={index} value={option} onClick={() => trackEvent({
                    category: ANALYTICS_TELECOMMANDE,
                    action: option,
                })}>
                    <Text color="colorMode.font900" textStyle="small">
                      {t(`languages.${option}`)}
                    </Text>
                  </MenuItemOption>))}
              </MenuOptionGroup>
            </MenuList>
          </>)}
      </Menu>));
};
export default LanguageDropdown;
