import * as React from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, FormLabel, HStack, Input, Radio, Spinner, Td, Text, Tr, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_ONGLET } from 'analytics/useAnalytics';
import snakeCase from 'lodash/snakeCase';
import { useIsLoadingFeasibilityPlot } from 'hooks/contexts/useSecondaryPlotsContext';
const FeasibilityTableRow = ({ rowId, rowConfig, register, formData, showFeasibilityDetail, feasibility, feasibilityPlots, label, }) => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const isLoadingFeasibilityPlot = useIsLoadingFeasibilityPlot();
    const allData = feasibility ? [...Object.values(feasibilityPlots)] : [];
    const value = feasibility
        ? rowConfig.getValue({
            sourceData: feasibility,
            formData,
            id: rowId,
            allData,
            index: 0,
        })
        : 0;
    const computedValue = rowConfig.getComputedValue
        ? rowConfig.getComputedValue({ formData, allData, index: 0, id: rowId })
        : value;
    const totalValue = feasibility
        ? rowConfig?.getTotalValue && rowConfig.getTotalValue(allData, rowId)
        : 0;
    const unit = t(`tabs.plots.section.feasibility.units.${rowConfig.unit}`);
    const fontWeight = rowConfig.mainRow ? '700' : undefined;
    return (<Tr borderBottom={(rowConfig.mainRow && !showFeasibilityDetail) ||
            (rowConfig.borderBottom && showFeasibilityDetail)
            ? '1px solid'
            : 'none'}>
      {/* ROW LABEL */}
      <Td borderRight="1px solid" width="285px">
        <Text whiteSpace="nowrap" fontWeight={fontWeight}>
          {label
            ? label
            : t(`tabs.plots.section.feasibility.headers.${snakeCase(rowId)}`)}
        </Text>
      </Td>
      {/* CALCULATRICE */}
      <Td>
        <HStack>
          {rowConfig.readOnly ? (<HStack>
              <Text fontWeight={fontWeight} width="65px" align="right" paddingRight={2}>
                {!isNaN(computedValue)
                ? computedValue.toString().replace('.', ',')
                : ''}
              </Text>
              <Text fontWeight={fontWeight}>{unit}</Text>
            </HStack>) : (<HStack>
              <Input size="sm" fontFamily="IBM-plex-sans, sans-serif" fontSize="13px" type="number" width="65px" textAlign="right" 
        // @ts-expect-error nothing
        {...register(rowId, {
            setValueAs: (v) => (v ? parseFloat(v) : 0),
            ...rowConfig.inputOptions,
        })}/>
              {rowConfig.unit && <Text fontWeight={fontWeight}>{unit}</Text>}
              {rowConfig.getExtraInfo && (<Text textStyle="small" fontWeight={fontWeight}>
                  {rowConfig.getExtraInfo({ formData, id: rowId, allData })}
                </Text>)}
            </HStack>)}
          {rowConfig.onCheck && (<FormControl display="flex" alignItems="center">
              <FormLabel paddingRight={1} cursor="pointer" margin={0} fontSize="11px">
                {t('tabs.plots.section.feasibility.checkbox')}
              </FormLabel>
              <Radio name="baseCalculationSelected" isChecked={formData.baseCalculationSelected === rowId} value={rowId} {...register('baseCalculationSelected')} onClick={() => {
                trackEvent({
                    category: ANALYTICS_ONGLET,
                    action: 'Faisabilité pour le calcul',
                });
            }}/>
            </FormControl>)}
        </HStack>
      </Td>

      {feasibility && (<>
          {/* TOTAL */}
          {Object.keys(feasibilityPlots).length > 1 && (<>
              {isLoadingFeasibilityPlot && (<Td borderLeft="1px solid">
                  <Spinner />
                </Td>)}
              <Td backgroundColor="colorMode.background700" borderLeft="1px solid" textAlign="end" fontWeight={fontWeight}>
                {!isNaN(totalValue) ? totalValue : ''} {unit}
              </Td>
            </>)}
          {Object.values(feasibilityPlots).map((feasibilityPlot, index) => {
                const feasibilityPlotValue = rowConfig.getValue({
                    sourceData: feasibilityPlot,
                    formData,
                    id: rowId,
                    allData,
                    index,
                });
                return (<Td key={feasibilityPlot?.id} borderLeft="1px solid" fontWeight={fontWeight} textAlign="end">
                {feasibilityPlotValue}
                {' '}
                {unit}
              </Td>);
            })}
        </>)}
    </Tr>);
};
export default memo(FeasibilityTableRow);
