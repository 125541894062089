import { useQuery } from '@tanstack/react-query';
import { get } from 'api/client';
export const QUERY_KEY = ['user-statistics'];
const fetchUserStatistics = () => () => get(null, 'legacy/statistics');
const useUserStatistics = () => {
    return useQuery({
        queryKey: QUERY_KEY,
        queryFn: fetchUserStatistics(),
    });
};
export default useUserStatistics;
