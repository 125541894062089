import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Box, FormControl, FormErrorMessage, FormLabel, Skeleton, } from '@chakra-ui/react';
import useUserProjectsList from 'hooks/crm/useUserProjectsList';
import { multiSelectStyles } from './MultiSelectInput';
const getCustomStyles = (error) => !error
    ? multiSelectStyles
    : {
        ...multiSelectStyles,
        control: (provided) => ({
            ...provided,
            backgroundColor: 'var(--colorModeBase)',
            borderRadius: '8px',
            width: '100%',
            borderColor: '#E62641',
            borderWidth: 2,
        }),
    };
const ProjectSelect = ({ name, disabled, onChange = () => { }, }) => {
    const { t } = useTranslation();
    const { userProjects, isInitialLoading } = useUserProjectsList();
    const { getValues, setValue, getFieldState } = useFormContext();
    const { error } = getFieldState(name);
    const hasValue = Boolean(getValues(name));
    const formattedProjects = userProjects && userProjects.length > 0
        ? userProjects.map((project) => ({
            value: project.id,
            label: project.label,
        }))
        : [];
    const defaultValue = formattedProjects.filter((option) => option.value === getValues(name));
    return (<Box width="full">
      <FormLabel>{t('tabs.project.label')}</FormLabel>
      {isInitialLoading && !hasValue ? (<>
          <Skeleton height="40px"/>
        </>) : (<FormControl isInvalid={Boolean(error)} fontSize={13}>
          <Select value={defaultValue ? defaultValue[0] : { label: '', value: '0' }} placeholder={t('tabs.project.search_project')} noOptionsMessage={() => t('global.no_result')} styles={getCustomStyles(error)} isClearable isLoading={isInitialLoading} options={formattedProjects} onChange={(e) => {
                onChange(e);
                setValue(name, e?.value);
            }} blurInputOnSelect isDisabled={disabled}/>
          {Boolean(error) && (<FormErrorMessage>{error?.message}</FormErrorMessage>)}
        </FormControl>)}
    </Box>);
};
export default ProjectSelect;
