import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import LegendImage from 'images/legends/average_solar_irradiation.png';
import { filtersKey } from 'utils/filters';
import { getLayerBoundingTileUrl } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = (map, tileWidth, tileHeight) => (coord, zoom) => {
    return getLayerBoundingTileUrl(map, 'IRRADIATION.SOLAIRE', zoom, coord, tileWidth, tileHeight);
};
const getLegends = () => {
    const legends = {};
    legends.average_solar_irradiation = {
        layerKey: filtersKey.AVERAGE_SOLAR_IRRADIATION,
        label: '',
        needsTranslate: false,
        image: LegendImage,
        height: '90px',
    };
    return legends;
};
const useAverageSolarIrradiationLayer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.AVERAGE_SOLAR_IRRADIATION],
        getLegends,
        opacity: 0.42,
    })(filtersKey.AVERAGE_SOLAR_IRRADIATION);
};
const AverageSolarIrradiationLayer = () => {
    useAverageSolarIrradiationLayer();
    return null;
};
export default AverageSolarIrradiationLayer;
