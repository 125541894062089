import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { IOProjectNote } from 'types/api';
export const QUERY_KEY = 'project-notes';
export const getProjectNotesQueryKey = (projectId) => [
    QUERY_KEY,
    projectId,
];
const fetchProjectNotes = (projectId, landPointId) => () => get(iots.array(IOProjectNote), `project/${projectId}/notes`, { landPointId });
const useProjectNotes = (projectId, landPointId) => {
    const { data: projectNotes, isInitialLoading } = useQuery({
        queryKey: getProjectNotesQueryKey(projectId),
        queryFn: fetchProjectNotes(projectId, landPointId),
        enabled: !!projectId,
    });
    return {
        projectNotes,
        isInitialLoading,
    };
};
export default useProjectNotes;
