import { memo, useState } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Heading, Stack, useDisclosure, } from '@chakra-ui/react';
import ImportPublipostageForm from './ImportPublipostageForm';
import PlotsPublipostageForm from './PlotsPublipostageForm';
const PublipostageDrawer = ({ plotContactsIds, handleClose, triggerItem, }) => {
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    // La liste des contacts importés ou sélectionnés
    const [contactIds, setContactIds] = useState([]);
    const closeDrawer = () => {
        if (handleClose)
            handleClose();
        onClose();
        setContactIds([]);
    };
    return (<>
      {triggerItem && triggerItem(onOpen)}
      <Drawer isOpen={isOpen} onClose={() => closeDrawer()} size="lg">
        <DrawerOverlay />
        <DrawerContent backgroundColor="colorMode.background900" overflowY="auto">
          <DrawerCloseButton />
          <DrawerHeader>
            <Heading as="h4" variant="h4">
              {contactIds.length
            ? t(`publipostage.import_publipostage`, {
                count: contactIds.length ?? 0,
            })
            : t(`publipostage.import_publipostage`)}
            </Heading>
          </DrawerHeader>
          <DrawerBody paddingY={1}>
            <Stack width="full" spacing={2}>
              {plotContactsIds ? (<PlotsPublipostageForm plotContactsIds={plotContactsIds} contactIds={contactIds} setContactIds={setContactIds} closeDrawer={closeDrawer}/>) : (<ImportPublipostageForm contactIds={contactIds} setContactIds={setContactIds} closeDrawer={closeDrawer}/>)}
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>);
};
export default memo(PublipostageDrawer);
