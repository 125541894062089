import { useCallback, useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, HStack, Text } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import ColorLegend from 'components/ColorLegend';
import ImageLegend from 'components/ImageLegend';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { useAddLayers, useGetLayers } from 'hooks/contexts/useMapContext';
import { filtersKey } from 'utils/filters';
import { getTVA } from 'utils/formatText';
const LegendLayers = ({ legends }) => {
    const { t } = useTranslation();
    const getLayers = useGetLayers();
    const addLayers = useAddLayers();
    const selectedTown = useSelectedTown();
    const [legendLayers, setLegendLayers] = useState();
    const [displayLayer, setDisplayLayer] = useState({});
    const [legendLayerKey, setLegendLayerKey] = useState(null);
    const legendKeys = useMemo(() => {
        const keys = Object.keys(legends);
        const hasLegendOrder = keys.some((key) => !!legends[key].legendOrder);
        if (hasLegendOrder) {
            keys.sort((a, b) => legends[a].legendOrder > legends[b].legendOrder ? 1 : -1);
        }
        const values = Object.values(legends);
        const legendWithCheckBox = values.filter((value) => value.legendWithCheckbox)[0];
        if (!isEmpty(legendWithCheckBox))
            setLegendLayerKey(legendWithCheckBox.layerKey);
        return keys;
    }, [legends]);
    React.useEffect(() => {
        if (legendLayerKey) {
            const layers = getLayers[legendLayerKey];
            if (!isEmpty(layers)) {
                setLegendLayers(Object.assign({}, getLayers[legendLayerKey]));
                const displayLayers = {};
                Object.values(layers).forEach((l) => (displayLayers[l.layerKey] = true));
                setDisplayLayer(displayLayers);
            }
        }
    }, [legendLayerKey]);
    const isLegendKeyDisplayed = useCallback((legendKey) => displayLayer[legendKey.split('@')[0]], [displayLayer]);
    const handleChange = (e, legendKey) => {
        const isChecked = e.target.checked;
        const layerKey = legendKey.split('@')[0];
        const displayLayers = {};
        displayLayers[layerKey] = isChecked;
        setDisplayLayer((prev) => ({ ...prev, ...displayLayers }));
    };
    useEffect(() => {
        const visibleLayers = {};
        const hiddenLayers = {};
        if (!isEmpty(legendLayers)) {
            Object.values(legendLayers).forEach((layer, index) => {
                if (displayLayer[layer.layerKey]) {
                    visibleLayers[index] = layer;
                }
                else {
                    hiddenLayers[index] = layer;
                }
            });
            if (!isEmpty(visibleLayers)) {
                addLayers(visibleLayers, legendLayerKey, true);
            }
            if (!isEmpty(hiddenLayers)) {
                addLayers(hiddenLayers, legendLayerKey, false);
            }
        }
    }, [displayLayer]);
    return (<Box maxH={250} overflowY="auto">
      {legendKeys.map((legendKey) => {
            const legend = legends[legendKey];
            if (!legend)
                return null;
            const label = legend.needsTranslate
                ? legend.label === 'anru_extends'
                    ? t(`legends.${legend.label}`, {
                        TVA: getTVA(selectedTown?.id),
                    })
                    : t(`legends.${legend.label}`)
                : legend.label;
            return (<HStack key={legendKey} alignItems="center" padding={1}>
            {legend.legendWithCheckbox && (<Checkbox isChecked={isLegendKeyDisplayed(legendKey)} onChange={(e) => handleChange(e, legendKey)}/>)}
            {'color' in legend ? (<ColorLegend legend={legend}/>) : (<ImageLegend icon={legend.image} label={label} height={legend.height} maxHeight={legend.height ? 'auto' : '24px'} marginLeft={legend.layerKey === filtersKey.USER_PROJECTS ||
                        legend.layerKey === filtersKey.TEAM_PROJECTS
                        ? '-15px'
                        : ''}/>)}
            <Text textStyle="paragraph">{label}</Text>
          </HStack>);
        })}
    </Box>);
};
export default LegendLayers;
