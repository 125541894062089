import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOEnergyPerformanceAreaLayerItem = IOLayerItem;
const fetchEnergyPerformanceArea = (townId) => () => get(iots.array(IOEnergyPerformanceAreaLayerItem), `legacy/${townId}/energy-performance-area`);
const useEnergyPerformanceAreaLayer = () => {
    createLayersHook({
        fetch: fetchEnergyPerformanceArea,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.ENERGY_PERFORMANCE_AREA);
};
const EnergyPerformanceAreaLayer = () => {
    useEnergyPerformanceAreaLayer();
    return null;
};
export default EnergyPerformanceAreaLayer;
