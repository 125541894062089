import * as React from 'react';
import { Stack } from '@chakra-ui/react';
import { useIsLoadingPlotLayers, usePlotLayers, } from 'hooks/contexts/useLocationContext';
import PlotLayers from './PlotLayers';
const PlotRules = ({ plot }) => {
    const isLoadingPlotLayers = useIsLoadingPlotLayers();
    const layers = usePlotLayers();
    return (<Stack spacing={4} padding={4} alignItems="start">
      <PlotLayers layers={layers[plot?.id]} isLoading={isLoadingPlotLayers}/>
    </Stack>);
};
export default PlotRules;
