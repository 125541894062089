import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getLayerCoordTileUrl, getNormalizedCoordinates } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = () => (coord, zoom) => {
    const normCoord = getNormalizedCoordinates(coord, zoom);
    if (!normCoord) {
        return null;
    }
    return getLayerCoordTileUrl('CADASTRALPARCELS.PARCELS', 'bdparcellaire', zoom, coord);
};
const useOldCadastreLayer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.OLD_CADASTRE],
    })(filtersKey.OLD_CADASTRE);
};
const OldCadastreLayer = () => {
    useOldCadastreLayer();
    return null;
};
export default OldCadastreLayer;
