import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import useOnError from 'hooks/useOnError';
import { useRefreshHandler, useToken } from './contexts/useAuthContext';
import useOnErrorBlob from './useOnErrorBlob';
axios.defaults.withCredentials = true;
const useAxiosConfig = () => {
    const { t } = useTranslation();
    const onError = useOnError();
    const onErrorBlob = useOnErrorBlob();
    const refreshHandler = useRefreshHandler();
    const token = useToken();
    const authInterceptorId = useRef(null);
    const errorInterceptorId = useRef(null);
    const refreshTokenInterceptorId = useRef(null);
    const initialized = useRef(false);
    const addAuthInterceptor = (tokenCurrent) => {
        return axios.interceptors.request.use((config) => {
            if (tokenCurrent && config.url.startsWith('/api')) {
                // Because request after refresh add authorization with new token
                // originalRequest.headers.Authorization = `Bearer ${lastToken.current}` line 270 in AuthContext.tsx
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (!config.retried) {
                    config.headers.Authorization = `Bearer ${tokenCurrent}`;
                    config.withCredentials = true;
                }
            }
            else {
                delete config.headers.Authorization;
                config.withCredentials = false;
            }
            return config;
        });
    };
    const addErrorInterceptor = () => {
        return axios.interceptors.response.use((response) => response, (error) => {
            const config = error.config;
            // used to identify the root cause of this error https://github.com/KelQuartier/jolicode/issues/3297
            if (!config) {
                // eslint-disable-next-line no-console
                console.warn(error);
            }
            if (config &&
                config.url === '/api/login' &&
                error.response?.status === 401) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const message = t(`login.error.${error.response?.data.message}`);
                onError({ ...error, message });
                return Promise.reject(error.response?.data);
            }
            const catchError = config.catchError;
            const isCancel = axios.isCancel(error);
            if (
            // We do not want a toast on every 403 errors
            error.response?.status !== 403 &&
                catchError &&
                (typeof catchError !== 'function' || catchError(error)) &&
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                !error.response?.data?.detail?.startsWith('ignore_error') &&
                !isCancel) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (error.response?.data instanceof Blob) {
                    onErrorBlob(error);
                }
                else {
                    onError(error);
                }
            }
            return Promise.reject(error.response?.data);
        });
    };
    if (!initialized.current) {
        if (refreshHandler) {
            refreshTokenInterceptorId.current = axios.interceptors.response.use((response) => response, refreshHandler);
        }
        if (token) {
            authInterceptorId.current = addAuthInterceptor(token);
        }
        errorInterceptorId.current = addErrorInterceptor();
        initialized.current = true;
    }
    useEffect(() => {
        if (refreshTokenInterceptorId.current !== null) {
            axios.interceptors.response.eject(refreshTokenInterceptorId.current);
            refreshTokenInterceptorId.current = null;
        }
        if (!refreshHandler) {
            return;
        }
        // Refresh token interceptor needs to be applied first so we remove the error interceptor
        if (errorInterceptorId.current !== null) {
            axios.interceptors.response.eject(errorInterceptorId.current);
            errorInterceptorId.current = null;
        }
        // We add the refresh token interceptor
        refreshTokenInterceptorId.current = axios.interceptors.response.use((response) => response, refreshHandler);
        // Then we reapply the error interceptor
        errorInterceptorId.current = addErrorInterceptor();
    }, [refreshHandler]);
    // We add the token on each request
    useEffect(() => {
        if (authInterceptorId.current !== null) {
            axios.interceptors.request.eject(authInterceptorId.current);
            authInterceptorId.current = null;
        }
        if (token) {
            authInterceptorId.current = addAuthInterceptor(token);
        }
    }, [token]);
};
export default useAxiosConfig;
