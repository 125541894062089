import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { EmailIcon } from '@chakra-ui/icons';
import { Box, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Heading, IconButton, useDisclosure, } from '@chakra-ui/react';
import { canSendCourier } from 'components/couriers/CourierCredits';
import CourierValidation from 'components/tabs/couriers/courierForm/CourierValidation';
import CustomTooltip from 'components/tooltips/CustomTooltip';
import useCourier from 'hooks/courier/useCourier';
const getSenderKeys = (obj) => Object.keys(obj)
    .filter((key) => key.includes('Sender'))
    .reduce((cur, key) => {
    return Object.assign(cur, { [key.replace('Sender', '')]: obj[key] });
}, {});
const SendCourierButton = ({ courierInfo, fileName, ...buttonProps }) => {
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { statsCouriersUser } = useCourier({
        enabledList: true,
    });
    const { sendCourier, isSendingCourier } = useCourier({
        enabledList: false,
        handleSuccess: onClose,
    });
    const handleSubmit = () => {
        sendCourier({ courierId: courierInfo?.id });
    };
    const disabled = !canSendCourier(statsCouriersUser, courierInfo) || isSendingCourier;
    const buttonText = disabled
        ? t('courier.no_credits')
        : t('courier.send_the_courier');
    return (<Box>
      <CustomTooltip label={buttonText}>
        <Box>
          <IconButton disabled={disabled} variant="ghost" color="colorMode.revertBase" icon={<EmailIcon fill="currentColor"/>} aria-label={buttonText} onClick={() => onOpen()} {...buttonProps}/>
        </Box>
      </CustomTooltip>

      <Drawer isOpen={isOpen} onClose={onClose} size="md" returnFocusOnClose={false}>
        <DrawerOverlay />
        <DrawerContent backgroundColor="colorMode.background900">
          <DrawerCloseButton />
          <DrawerHeader>
            <Heading as="h4" variant="h4">
              {t(`courier.send_courier`)}
            </Heading>
          </DrawerHeader>
          <DrawerBody>
            <CourierValidation courierInfo={courierInfo} handleCancel={onClose} handleConfirm={handleSubmit} fileName={fileName} sender={getSenderKeys(courierInfo)} loading={isSendingCourier}/>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>);
};
export default SendCourierButton;
