import * as React from 'react';
import { memo } from 'react';
import { Box, IconButton } from '@chakra-ui/react';
import ZoomControl from 'components/map/Toolbar/ZoomControl';
import { STREET_VIEW_MODE_FIFTY, STREET_VIEW_MODE_FULL, } from 'context/LayoutContext';
import { useCloseStreetView, useStreetViewMode, } from 'hooks/contexts/useMapContext';
import CloseIcon from 'images/icons/Close';
const StreetViewContainer = ({ refPanorama }) => {
    const streetViewMode = useStreetViewMode();
    const closeStreetView = useCloseStreetView();
    return (<Box id="panorama" height={streetViewMode === STREET_VIEW_MODE_FIFTY
            ? '50%'
            : streetViewMode === STREET_VIEW_MODE_FULL
                ? '100%'
                : '1px'} display="flex" opacity={streetViewMode ? 1 : 0} position="relative">
      <Box ref={refPanorama} height="100%" width="100%"/>
      <IconButton variant="link" aria-label="toggle-pano" color="colorMode.base" _hover={{
            color: 'red.500',
        }} icon={<CloseIcon fill="currentColor" width="30px" height="30px"/>} position="absolute" right="10px" top="24px" zIndex="10" onClick={closeStreetView}/>
      <Box position="absolute" right="24px" bottom="24px" zIndex="20">
        <ZoomControl isStreetView/>
      </Box>
    </Box>);
};
export default memo(StreetViewContainer);
