import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';
import * as iots from 'io-ts';
import InfoWindowTable from 'components/infoWindow/InfoWindowTable';
export const IOPublicMarketsInfo = iots.type({
    buyer: iots.string,
    description: iots.string,
    legacyId: iots.string,
    url: iots.string,
    date: iots.string,
});
const PublicMarketsInfoWindow = ({ info }) => {
    const { t } = useTranslation();
    return (<Box maxWidth="600px">
      {info.map((item) => (<InfoWindowTable key={item.legacyId} content={[
                {
                    title: t('info_window.public_markets.notice_number'),
                    data: item.legacyId,
                },
                {
                    title: t('info_window.public_markets.object'),
                    data: item.description,
                },
                {
                    title: t('info_window.public_markets.buyer'),
                    data: item.buyer,
                },
                {
                    title: t('info_window.public_markets.full_notice'),
                    data: (<a href={item.url} rel="noreferrer" target="_blank">
                  {t('info_window.public_markets.full_notice_url')}
                </a>),
                },
                {
                    title: t('info_window.public_markets.end_date'),
                    data: item.date,
                },
            ]}/>))}
    </Box>);
};
export default PublicMarketsInfoWindow;
