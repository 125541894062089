import { useEffect } from 'react';
import { useColorMode as useChakraColorMode } from '@chakra-ui/react';
import usePersistentContext from 'hooks/usePersistentContext';
const usePersistentColorMode = () => {
    const [colorMode, setColorMode] = usePersistentContext('color_mode');
    const { colorMode: chakraColorMode, setColorMode: setChakraColorMode } = useChakraColorMode();
    useEffect(() => {
        if (colorMode) {
            setChakraColorMode(colorMode);
        }
    }, [colorMode]);
    useEffect(() => {
        if (chakraColorMode !== colorMode) {
            setColorMode(chakraColorMode);
        }
    }, [chakraColorMode]);
    return { setColorMode };
};
export default usePersistentColorMode;
