import * as React from 'react';
import usePlotPluRegulation from 'hooks/plot/usePlotPluRegulation';
import { getPlotSkeleton } from '../PlotPannelSkeletons';
import PlotPlu from './PlotPlu';
const PlotPluAdditionalRegulation = ({ plot, }) => {
    const { pluAdditionalRegulation, isLoadingPluAdditionalRegulation } = usePlotPluRegulation(plot?.townId, plot?.id, plot?.lat, plot?.lng);
    const skeleton = getPlotSkeleton();
    if (!plot || !pluAdditionalRegulation)
        return skeleton;
    return (<PlotPlu plot={plot} pluInfo={pluAdditionalRegulation} type="PLU_ADDITIONAL" isLoading={isLoadingPluAdditionalRegulation}/>);
};
export default PlotPluAdditionalRegulation;
