import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOEcologicalContinuumLayerItem = IOLayerItem;
const fetchEcologicalContinuum = (townId) => () => get(iots.array(IOEcologicalContinuumLayerItem), `legacy/${townId}/ecological-continuum`);
const useEcologicalContinuumLayer = () => {
    createLayersHook({
        fetch: fetchEcologicalContinuum,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.ECOLOGICAL_CONTINUUM);
};
const EcologicalContinuumLayer = () => {
    useEcologicalContinuumLayer();
    return null;
};
export default EcologicalContinuumLayer;
