import { useState } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, Checkbox, FormControl, FormErrorMessage, FormLabel, HStack, Input, Stack, Text, VStack, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_LOGIN } from 'analytics/useAnalytics';
import { isRight } from 'fp-ts/These';
import { IOAuthTwoFactorInProgress, TWO_FACTOR_METHOD_EMAIL, } from 'context/AuthContext';
import { useTwoFactor } from 'hooks/contexts/useAuthContext';
const CODE_PATTERN = /^\d{6}$/;
const TwoFactorForm = ({ redirect, twoFactorInProgress, setTwoFactorInProgress, }) => {
    const { t } = useTranslation();
    const { register, handleSubmit, formState: { isSubmitting, errors }, setError, } = useForm();
    const { trackEvent } = useAnalytics();
    const { sendTwoFactorCode, changeTwoFactorMethod, resendTwoFactorCode } = useTwoFactor();
    const [canResendTwoFactorCodeIn, setCanResendTwoFactorCodeIn] = useState(0);
    const resendCode = () => {
        resendTwoFactorCode();
        let timeLeft = 30;
        const timer = setInterval(() => {
            if (timeLeft <= 0) {
                clearInterval(timer);
                return;
            }
            timeLeft--;
            setCanResendTwoFactorCodeIn(timeLeft);
        }, 1000);
    };
    const changeMethod = async (method) => {
        const result = await changeTwoFactorMethod(method);
        if (isRight(IOAuthTwoFactorInProgress.decode(result))) {
            setTwoFactorInProgress(result);
        }
    };
    const setCodeError = (message) => {
        setError('code', {
            type: 'server',
            message: t(`security.two_factor.code.${message || 'two_factor_failed'}`),
        });
    };
    const submit = async (values) => {
        try {
            await sendTwoFactorCode(values.code, values.rememberDevice, redirect);
        }
        catch (e) {
            setCodeError(e?.error);
        }
    };
    return (<Stack>
      <form onSubmit={handleSubmit(submit)}>
        <Box paddingBottom={2} width="300px">
          <FormControl isInvalid={!!errors?.code}>
            <FormLabel>
              {t(`login.two_factor_code.${twoFactorInProgress.twoFactorMethod}`, twoFactorInProgress.twoFactorContact
            ? {
                contact: twoFactorInProgress.twoFactorContact,
            }
            : undefined)}
            </FormLabel>
            <Input {...register('code', {
        required: t('security.two_factor.code.required'),
        pattern: {
            value: CODE_PATTERN,
            message: t('security.two_factor.code.required'),
        },
    })} placeholder={t('security.two_factor.code.placeholder')}/>
            <FormErrorMessage>{errors?.code?.message}</FormErrorMessage>
          </FormControl>
        </Box>

        <Box width="full" paddingBottom={2}>
          <FormControl display="flex">
            <Checkbox {...register('rememberDevice')} onClick={() => trackEvent({
            category: ANALYTICS_LOGIN,
            action: 'Ne plus me demander sur cet appareil',
        })}/>
            <FormLabel margin="0" paddingLeft={3} cursor="pointer">
              {t('login.remember_device')}
            </FormLabel>
          </FormControl>
        </Box>

        {twoFactorInProgress.twoFactorMethod === TWO_FACTOR_METHOD_EMAIL && (<Box width="full" paddingBottom={2}>
            <HStack alignItems="baseline">
              <Text marginTop="5px" textStyle="paragraph" textAlign="center">
                {t('login.two_factor_code.not_received')}
              </Text>
              <Button onClick={resendCode} variant="link-primary" paddingLeft={2} disabled={canResendTwoFactorCodeIn > 0}>
                {t(`login.two_factor_code.${canResendTwoFactorCodeIn > 0 ? 'resend_disable' : 'resend'}`, { timer: canResendTwoFactorCodeIn })}
              </Button>
            </HStack>
          </Box>)}

        <Button width={300} variant="primary" isDisabled={isSubmitting} type="submit">
          {t('security.two_factor.confirm')}
        </Button>
      </form>

      {twoFactorInProgress.twoFactorAvailableMethods.length > 1 && (<VStack style={{ marginTop: '15px' }}>
          <Text textStyle="paragraph">
            {t('security.two_factor.change_method.label')}
          </Text>
          {twoFactorInProgress.twoFactorAvailableMethods
                .filter((method) => method !== twoFactorInProgress.twoFactorMethod)
                .map((method) => (<Button key={method} variant="link-primary" onClick={() => changeMethod(method)}>
                {t(`security.two_factor.change_method.${method}`)}
              </Button>))}
        </VStack>)}
    </Stack>);
};
export default TwoFactorForm;
