import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOPhotovoltaicProneAreaMilitarySpaceLayerItem = IOLayerItem;
const fetchPhotovoltaicProneAreaMilitarySpace = (townId) => () => get(iots.array(IOPhotovoltaicProneAreaMilitarySpaceLayerItem), `${townId}/photovoltaic-prone-area/mili`);
const usePhotovoltaicProneAreaMilitaryGroundLayer = () => {
    createLayersHook({
        fetch: fetchPhotovoltaicProneAreaMilitarySpace,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.PHOTOVOLTAIC_PRONE_AREA_MILITARY_GROUND);
};
const PhotovoltaicProneAreaMilitaryGroundLayer = () => {
    usePhotovoltaicProneAreaMilitaryGroundLayer();
    return null;
};
export default PhotovoltaicProneAreaMilitaryGroundLayer;
