import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@chakra-ui/react';
const NotRequired = () => {
    const { t } = useTranslation();
    return (<Text as="span" color="colorMode.font700">
      {` - ${t('global.fields.optional')}`}
    </Text>);
};
export default NotRequired;
