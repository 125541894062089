import * as React from 'react';
import { useMap } from 'hooks/contexts/useMapContext';
const StreetViewLayer = () => {
    const map = useMap();
    const streetViewLayer = new google.maps.StreetViewCoverageLayer();
    React.useEffect(() => {
        streetViewLayer.setMap(map);
        return () => {
            streetViewLayer.setMap(null);
        };
    }, []);
    return null;
};
export default StreetViewLayer;
