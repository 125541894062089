import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Translation } from 'react-i18next';
import { Box, Text } from '@chakra-ui/react';
import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
const IOPreemptionLayerItem = IOLayerItem;
const fetchPreemption = (townId) => () => get(iots.array(IOPreemptionLayerItem), `legacy/${townId}/preemption`);
const getInfoWindowContent = (layer) => {
    const content = (<Box>
      <Text>{layer.infoTitle}</Text>
      {layer.infoLine1 && layer.infoLine1.toLowerCase().startsWith('http') ? (<a href={layer.infoLine1} rel="noreferrer" target="_blank">
          <Translation>{(t) => t('common:open')}</Translation>
        </a>) : (<Text>{layer.infoLine1}</Text>)}
    </Box>);
    return ReactDOMServer.renderToStaticMarkup(content);
};
const usePreemptionLayer = () => {
    createLayersHook({
        fetch: fetchPreemption,
        getInfoWindowContent,
    })(filtersKey.PREEMPTION);
};
const PreemptionLayer = () => {
    usePreemptionLayer();
    return null;
};
export default PreemptionLayer;
