import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getNormalizedCoordinates } from 'utils/map';
import { getCommuneTileUrlHost3 } from 'utils/multiplexage';
import { isGermanySite, isSwissSite } from 'utils/site';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = () => (coord, zoom) => {
    const normCoord = getNormalizedCoordinates(coord, zoom);
    if (!normCoord) {
        return null;
    }
    let tile = 'KqCommunes2Tile';
    if (isSwissSite()) {
        tile = 'suisseCommunesTile';
    }
    else if (isGermanySite()) {
        tile = 'allemagneCommunesTile';
    }
    const bound = Math.pow(2, zoom);
    return (getCommuneTileUrlHost3() +
        `cache/tms/1.0.0/${tile}@g/` +
        zoom +
        '/' +
        normCoord.x +
        '/' +
        (bound - normCoord.y - 1) +
        '.png');
};
const getLegends = (enabledFilters) => {
    const legends = {};
    if (enabledFilters.includes(filtersKey.TOWNS)) {
        legends.town = {
            layerKey: filtersKey.TOWNS,
            label: 'town',
            needsTranslate: true,
            color: '#FFFFFE',
            borderColor: '#000000',
            borderWidth: 1,
            borderOpacity: 1,
        };
    }
    return legends;
};
const useTownsLayer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        getLegends,
        position: CST_POSITIONS[filtersKey.TOWNS],
    })(filtersKey.TOWNS);
};
const TownsLayer = () => {
    useTownsLayer();
    return null;
};
export default TownsLayer;
