import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IORoadEasementLayerItem = IOLayerItem;
const fetchRoadEasement = (townId) => () => get(iots.array(IORoadEasementLayerItem), `legacy/${townId}/road-easement`);
const useRoadEasementLayer = () => {
    createLayersHook({
        fetch: fetchRoadEasement,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.ROAD_EASEMENT);
};
const RoadEasementLayer = () => {
    useRoadEasementLayer();
    return null;
};
export default RoadEasementLayer;
