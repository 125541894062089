import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, HStack, IconButton, Select, Text } from '@chakra-ui/react';
import ArrowLeftIcon from 'images/icons/ArrowLeft';
import ArrowRightIcon from 'images/icons/ArrowRight';
const Pagination = ({ table }) => {
    const { t } = useTranslation();
    let minVisible = 0;
    const totalRows = table.getPrePaginationRowModel().rows.length;
    const rows = table.getPaginationRowModel().rows.length;
    if (table.getState().columnFilters.length > 0) {
        minVisible = rows;
    }
    else {
        minVisible =
            table.getState().pagination.pageIndex *
                table.getState().pagination.pageSize;
    }
    const rowsVisible = minVisible + rows;
    const pageSize = table.getState().pagination.pageSize;
    const pageSizesList = [5, 10, 20, 30, 40, 50];
    if (!pageSizesList.includes(pageSize)) {
        pageSizesList.push(pageSize);
    }
    const lastIndex = table.getPageCount() - 1;
    const currentIndex = table.getState().pagination.pageIndex;
    const getIndexes = () => {
        let indexesToDisplay = [];
        if (currentIndex > 0) {
            indexesToDisplay.push(currentIndex - 1);
        }
        indexesToDisplay.push(currentIndex);
        if (currentIndex < lastIndex) {
            indexesToDisplay.push(currentIndex + 1);
        }
        if (indexesToDisplay[indexesToDisplay.length - 1] !== lastIndex) {
            indexesToDisplay.push(lastIndex);
        }
        if (indexesToDisplay[0] !== 0) {
            indexesToDisplay = [0, ...indexesToDisplay];
        }
        return indexesToDisplay;
    };
    const indexes = getIndexes();
    return (<HStack flexWrap="wrap" alignItems="center" spacing={0}>
      <Box flex={1} marginRight={1}>
        <Text textStyle="paragraph">
          {t('table.records_count', {
            min: minVisible,
            rows: rowsVisible,
            total: totalRows,
        })}
        </Text>
      </Box>

      {table.getPageCount() > 1 && (<HStack alignItems="center">
          <HStack>
            <Text textStyle="paragraph">{t('table.display')}</Text>
            <Select size="xs" borderRadius="lg" value={table.getState().pagination.pageSize} onChange={(e) => {
                table.setPageSize(Number(e.target.value));
            }}>
              {pageSizesList.map((pageSize) => (<option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>))}
            </Select>
            <Text textStyle="paragraph">{t('table.elements')}</Text>
          </HStack>

          <HStack spacing={0} alignItems="unset">
            <IconButton aria-label="btn-previous" icon={<ArrowLeftIcon fill="currentColor"/>} onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} backgroundColor="colorMode.background700" borderRightRadius={0} size="xs"/>
            <Box display="flex">
              {indexes.map((index, idx) => {
                const button = (<PaginationButton key={index} label={index + 1} handleClick={() => table.setPageIndex(index)} isCurrent={index === currentIndex}/>);
                const previousIndex = indexes[idx - 1];
                if (previousIndex !== undefined && previousIndex + 1 !== index)
                    return (<React.Fragment key={index}>
                      <PaginationButton label="..."/>
                      {button}
                    </React.Fragment>);
                return button;
            })}
            </Box>
            <IconButton aria-label="btn-next" icon={<ArrowRightIcon fill="currentColor"/>} onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} backgroundColor="colorMode.background700" borderLeftRadius={0} size="xs"/>
          </HStack>
        </HStack>)}
    </HStack>);
};
const PaginationButton = ({ isCurrent, handleClick, label, }) => {
    return (<Button onClick={handleClick} backgroundColor={isCurrent ? 'colorMode.background500' : 'colorMode.background700'} borderRadius={0} size="xs" disabled={!handleClick}>
      {label}
    </Button>);
};
export default Pagination;
