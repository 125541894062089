import * as React from 'react';
import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import createVectorTilesLayerHook from 'hooks/layers/createVectorTilesLayerHook';
import { VECTOR_TYPES } from 'types/types';
import { filtersKey } from 'utils/filters';
import { getLayerBoundingTileUrlTileUrl } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
export const OPTICAL_FIBER_ZOOM_LIMIT = 10;
const getLayerTileUrl = (enabledFilters, opacity) => {
    return {
        url: 'https://www.infrapedia.com/map/cables/{z}/{x}/{y}.pbf',
        cache: true,
        setSelected: true,
        opacity: opacity,
    };
};
const getMarkerLayerTileUrl = (map, tileWidth, tileHeight) => (coord, zoom) => {
    return getLayerBoundingTileUrlTileUrl(map, 'Applicatif', 'infrapedia_datacenter_points,infrapedia_datacenter_polygones', zoom, coord, tileWidth, tileHeight);
};
const getLegends = () => {
    const legends = {};
    legends.optical_fiber = {
        layerKey: filtersKey.OPTICAL_FIBER,
        label: 'optical_fiber',
        needsTranslate: true,
        color: '#8856A7',
        polygonType: VECTOR_TYPES.polyline,
        width: 20,
        borderWidth: 4,
    };
    legends.optical_fiber_point = {
        layerKey: filtersKey.OPTICAL_FIBER,
        label: 'optical_fiber_point',
        needsTranslate: true,
        color: '#0191FC',
        polygonType: VECTOR_TYPES.point,
        width: 10,
        height: 10,
    };
    legends.optical_fiber_polygon = {
        layerKey: filtersKey.OPTICAL_FIBER,
        label: 'optical_fiber_polygon',
        needsTranslate: true,
        color: '#0191FC',
        borderWidth: 1,
        borderColor: '#333333',
    };
    return legends;
};
const useOpticalFiberLayer = () => {
    createVectorTilesLayerHook({
        getLayerTileUrl,
        getLegends,
        position: CST_POSITIONS[filtersKey.OPTICAL_FIBER],
    })(filtersKey.OPTICAL_FIBER);
    createTilesLayerHook({
        getLayerTileUrl: getMarkerLayerTileUrl,
        getLegends,
        position: CST_POSITIONS[filtersKey.OPTICAL_FIBER_CENTER],
        tileWidth: 1024,
        tileHeight: 1024,
    })(filtersKey.OPTICAL_FIBER);
};
const OpticalFiberLayer = () => {
    useOpticalFiberLayer();
    return null;
};
const OpticalFiberWrapper = ({ computedZoom }) => {
    if (computedZoom < OPTICAL_FIBER_ZOOM_LIMIT) {
        return null;
    }
    return <OpticalFiberLayer />;
};
export default OpticalFiberWrapper;
