import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Text } from '@chakra-ui/react';
export const OwnerSearchOptional = (props) => {
    const { t } = useTranslation();
    return (<Stack alignItems="start" bgColor="colorMode.sidebar500" {...props}>
      <Text textStyle="paragraph">
        {t('sidebar.search_plots.question_want_to_know_owner_of_plot')}
      </Text>
      <Text textStyle="paragraph">
        {t('sidebar.search_plots.switch_free_test')}
      </Text>
      <Text textStyle="paragraph" fontWeight={500}>
        {t('sidebar.search_plots.contact_free_test')}
      </Text>
    </Stack>);
};
export default OwnerSearchOptional;
