import * as React from 'react';
import { Button } from '@chakra-ui/react';
import useOpenProject from 'hooks/plot/useOpenProject';
const ProjectButton = ({ projectId, projectLabel, projectTownIds, lat, lng, variant = 'link-primary', children, analyticOrigin, analyticDetail, ...buttonProps }) => {
    const openProject = useOpenProject({
        projectId,
        projectLabel,
        projectTownIds,
        lat,
        lng,
        analyticOrigin,
        analyticDetail,
    });
    return (<Button variant={variant} onClick={openProject} {...buttonProps}>
      {children}
    </Button>);
};
export default ProjectButton;
