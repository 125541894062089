import * as React from 'react';
import { Accordion, AccordionItem, AccordionPanel } from '@chakra-ui/react';
import { get } from 'lodash';
import FilterCategoryEmptyMessage from 'components/sidebar/FiltersSideBar/FilterCategoryEmptyMessage';
import FiltersCategory from 'components/sidebar/FiltersSideBar/FiltersCategory';
import { getOpenedIndices } from 'context/FiltersAccordionContext';
import { useOpenedAccordions } from 'hooks/contexts/useFiltersAccordionContext';
import { useFilteredSecondaryLayers } from 'hooks/contexts/useLayersTreeContext';
import useCurrentSite from 'hooks/useCurrentSite';
import { filtersKey, hideCategory, lastNode, } from 'utils/filters';
import FilterAccordionTitle from './FilterAccordionTitle';
const FILTER_PADDING_BOTTOM = 1;
const FILTER_PADDING_TOP = 2;
export const FILTER_PADDING_X = 4;
// Some sections need to manage their own padding
export const FULL_WIDTH_SECTIONS = ['company_owned_plots'];
const FiltersTree = ({ tree, title, level = 1, path, }) => {
    const filteredSecondaryLayers = useFilteredSecondaryLayers();
    const { currentSite } = useCurrentSite();
    const openedAccordions = useOpenedAccordions();
    const openedIndices = React.useMemo(() => getOpenedIndices(tree, openedAccordions), [openedAccordions, tree]);
    if (!tree) {
        return null;
    }
    return (<AccordionItem id={title} border="none" display={!!filteredSecondaryLayers && !get(filteredSecondaryLayers, path)
            ? 'none'
            : 'inherit'}>
      {({ isExpanded }) => {
            return (<>
            {title && (<FilterAccordionTitle level={level} title={title} tree={tree} isExpanded={isExpanded}/>)}
            <AccordionPanel paddingX={lastNode(tree) && !FULL_WIDTH_SECTIONS?.includes(title)
                    ? FILTER_PADDING_X
                    : 0} paddingTop={lastNode(tree) ? FILTER_PADDING_TOP : 0} paddingBottom={lastNode(tree) ? FILTER_PADDING_BOTTOM : 0} bgColor="colorMode.sidebar500">
              {isExpanded ? (lastNode(tree) ? (
                //filter fields group
                <FiltersCategory key={title} id={title} category={tree} level={level + 1} parentCategory={title}/>) : (<Accordion id={`${title || 'tree'}-${level}`} allowMultiple index={openedIndices} width="full">
                    {Object.keys(tree).map((category, index) => {
                        if (hideCategory(tree[category])) {
                            const paddingTop = !lastNode(tree) && index === 0
                                ? FILTER_PADDING_TOP
                                : 0;
                            return (<FilterCategoryEmptyMessage key={`${category}-${level}`} id={category} category={tree[category]} paddingTop={paddingTop} paddingBottom={FILTER_PADDING_BOTTOM} paddingX={FILTER_PADDING_X}/>);
                        }
                        if (category === filtersKey.COMPANY_OWNED_PLOTS &&
                            !currentSite.ownerPublicity &&
                            !currentSite.ownerSubscription) {
                            return null;
                        }
                        const hiddenCategory = tree[category]?.hidden === true;
                        if (hiddenCategory) {
                            return (<AccordionItem key={`${category}-${level}`} border="none" paddingX={lastNode(tree[category]) ? FILTER_PADDING_X : 0} paddingTop={lastNode(tree[category]) ? FILTER_PADDING_TOP : 0} paddingBottom={lastNode(tree[category])
                                    ? FILTER_PADDING_BOTTOM
                                    : 0}>
                            <FiltersCategory key={category} category={tree[category]} id={category} level={level + 1} parentCategory={title}/>
                          </AccordionItem>);
                        }
                        return (<FiltersTree key={`${category}-${level}`} tree={tree[category]} level={level + 1} title={category} path={path + '.' + category}/>);
                    })}
                    <FilterCategoryEmptyMessage id={title} category={tree}/>
                  </Accordion>)) : null}
            </AccordionPanel>
          </>);
        }}
    </AccordionItem>);
};
export default FiltersTree;
