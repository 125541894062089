import * as React from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { Box, HStack, Text } from '@chakra-ui/react';
import { snakeCase } from 'lodash';
import ContactButton from 'components/buttons/ContactButton';
import CreateButton from 'components/buttons/CreateButton';
import DeleteButton from 'components/buttons/DeleteButton';
import EditButton from 'components/buttons/EditButton';
import PlotButton from 'components/buttons/PlotButton';
import Table from 'components/table/Table';
import useActivity from 'hooks/useActivity';
import { AGENDA_ACTIVITY_TYPE_EVENT, AGENDA_ACTIVITY_TYPE_REMINDER, getActivityStatusLabel, getActivityStatusLabelFromDelay, } from 'utils/constants';
import { formatDate } from 'utils/formatDate';
import { inDateRange } from 'utils/search';
import ActionStatus from './ActionStatus';
import ActivityStatusInput from './ActivityStatusInput';
import ActivityTypeInput from './ActivityTypeInput';
const getColumnHeader = (column) => (<Translation>
    {(t) => t(`activity.fields.common.${column}.label`)}
  </Translation>);
const checkIfActivityIsDisabled = (activity) => {
    return (activity.agendaType !== AGENDA_ACTIVITY_TYPE_EVENT && activity.status === 1);
};
const EXPORTED_FIELDS = [
    'date',
    'type',
    'comment',
    'contact_id',
    'cadastre_id',
    'delay',
    'status',
];
const formatExportHeader = (t) => (header) => t(`activity.fields.common.${snakeCase(header)}.label`);
const formatExportValue = (t) => (value, field) => {
    if (field === 'delay') {
        const key = getActivityStatusLabelFromDelay(value);
        return t(`activity.fields.common.delay.options.${key}`);
    }
    if (field === 'status') {
        const key = getActivityStatusLabel(value);
        return t(key);
    }
    return value;
};
const ActivityTable = ({ activities, id, isLoading, setEditedActivity, }) => {
    const { t } = useTranslation();
    const { deleteActivity, updateActivity, isDeleting, isUpdating } = useActivity({
        handleSuccess: () => setEditedActivity(undefined),
    });
    const columns = [
        {
            id: 'columns',
            columns: [
                {
                    accessorKey: 'date',
                    header: () => getColumnHeader('date'),
                    cell: (info) => {
                        const isLoading = isUpdating || isDeleting;
                        const isDisabled = checkIfActivityIsDisabled(info.row.original);
                        return (<Text as={isDisabled ? 'del' : undefined} opacity={isLoading ? 0.3 : 1}>
                {formatDate(info.getValue())}
              </Text>);
                    },
                    enableGlobalFilter: false,
                    filterFn: inDateRange,
                },
                {
                    id: 'type',
                    header: () => getColumnHeader('type'),
                    cell: (info) => info.row.original.agendaType === AGENDA_ACTIVITY_TYPE_EVENT ? (<ActivityTypeInput activity={info.row.original} updateActivity={updateActivity} isLoading={isDeleting || isUpdating}/>) : (info.row.original.title),
                    filterFn: 'arrIncludesSome',
                },
                {
                    accessorKey: 'comment',
                    header: () => getColumnHeader('comment'),
                    accessorFn: (row) => {
                        if (!row.comment)
                            return t('table.empty_column');
                        return row.comment;
                    },
                    cell: (info) => {
                        const isLoading = isUpdating || isDeleting;
                        const isDisabled = checkIfActivityIsDisabled(info.row.original);
                        return info.row.original.comment ? (<Text as={isDisabled ? 'del' : undefined} opacity={isLoading ? '0.3' : '1'}>
                {info.row.original.comment}
              </Text>) : info.row.original.agendaType !==
                            AGENDA_ACTIVITY_TYPE_REMINDER ? (<CreateButton label="activity.actions.add_comment" size="sm" onClick={() => {
                                setEditedActivity(info.row.original);
                            }} isDisabled={isLoading || isDisabled}/>) : null;
                    },
                    filterFn: 'arrIncludesSome',
                },
                {
                    accessorKey: 'contactId',
                    header: () => getColumnHeader('contact_id'),
                    accessorFn: (row) => {
                        if (!row.contact?.lastname)
                            return t('table.empty_column');
                        return row.contact?.lastname;
                    },
                    cell: (info) => (<ContactButton contactId={info.row.original.contactId} isDisabled={checkIfActivityIsDisabled(info.row.original) ||
                            isUpdating ||
                            isDeleting}/>),
                    filterFn: 'arrIncludesSome',
                },
                {
                    accessorKey: 'cadastreId',
                    header: () => getColumnHeader('cadastre_id'),
                    accessorFn: (row) => {
                        if (!row.cadastreId)
                            return t('table.empty_column');
                        return row.cadastreId;
                    },
                    cell: (info) => {
                        const isLoading = isUpdating || isDeleting;
                        const isDisabled = checkIfActivityIsDisabled(info.row.original);
                        return (<Box textAlign="center">
                {info.row.original.townLabel}
                <PlotButton lat={info.row.original.plotLat} lng={info.row.original.plotLng} townId={info.row.original.townId} variant="link-primary" disabled={isLoading || isDisabled} plotId={info.row.original.plotId} marginLeft={1}>
                  <Text color="inherit" as={isDisabled ? 'del' : undefined} opacity={isLoading ? '0.3' : '1'}>
                    {info.row.original.cadastreId}
                  </Text>
                </PlotButton>
              </Box>);
                    },
                    filterFn: 'arrIncludesSome',
                },
                {
                    id: 'delay',
                    accessorFn: (row) => {
                        if (!row.delay)
                            return t('table.empty_column');
                        return getActivityStatusLabelFromDelay(row.delay);
                    },
                    header: () => getColumnHeader('delay'),
                    cell: (info) => (<Box textAlign="center">
              <ActionStatus label={getActivityStatusLabelFromDelay(info.row.original.delay)} disabled={checkIfActivityIsDisabled(info.row.original) ||
                            isDeleting ||
                            isUpdating}/>
            </Box>),
                    filterFn: 'arrIncludesSome',
                },
                {
                    id: 'status',
                    header: () => getColumnHeader('status'),
                    accessorFn: (row) => {
                        return row.agendaType !== AGENDA_ACTIVITY_TYPE_EVENT
                            ? t(getActivityStatusLabel(row.status))
                            : t(getActivityStatusLabel(0));
                    },
                    cell: ({ row }) => row.original.agendaType !== AGENDA_ACTIVITY_TYPE_EVENT && (<ActivityStatusInput activity={row.original} updateActivity={updateActivity} isLoading={isUpdating || isDeleting}/>),
                    filterFn: 'arrIncludesSome',
                },
                {
                    accessorKey: 'id',
                    header: () => '',
                    cell: (info) => {
                        const disabled = info.row.original.status === 1 || isUpdating || isDeleting;
                        return (<HStack justifyContent="center">
                <DeleteButton mode="icon" handleDelete={() => deleteActivity({
                                activityId: info.row.original.id,
                            })} disabled={isDeleting || isUpdating}/>
                <EditButton mode="icon" handleEdit={() => setEditedActivity(info.row.original)} disabled={disabled}/>
              </HStack>);
                    },
                    enableGlobalFilter: false,
                    enableColumnFilter: false,
                    enableSorting: false,
                },
            ],
        },
    ];
    const initialColumnFilters = [
        {
            id: 'status',
            value: [t(getActivityStatusLabel(0))],
        },
    ];
    return (<Table id={id} columns={columns} data={activities || []} isLoading={isLoading} initialColumnFilters={initialColumnFilters} exportedFields={EXPORTED_FIELDS} formatExportHeader={formatExportHeader(t)} formatExportValue={formatExportValue(t)} exportTitle={t('tabs.activity.title')}/>);
};
export default ActivityTable;
