import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getTileBoundingBox } from 'utils/geo';
import { getBaseTileUrlHost3 } from 'utils/multiplexage';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = (map, tileWidth, tileHeight) => (coord, zoom) => {
    const boundingBox = getTileBoundingBox(coord, zoom, map, tileWidth, tileHeight);
    const layers = 'trames_vertes_et_bleues_multipolygones,trames_vertes_et_bleues_polygones,trames_vertes_et_bleues_multilignes,trames_vertes_et_bleues_lignes';
    return (getBaseTileUrlHost3() +
        'cgi-bin/mm/mapserv.cgi?map=/var/data/public_maps/project_tvb_types.map&SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=' +
        layers +
        '&TILED=true&WIDTH=' +
        tileWidth +
        '&HEIGHT=' +
        tileHeight +
        '&CRS=EPSG:4326&STYLES=&BBOX=' +
        boundingBox);
};
const getLegends = () => {
    const legends = {};
    legends.srce_wetland = {
        layerKey: filtersKey.SRCE,
        label: 'srce_wetland',
        needsTranslate: true,
        color: '#2e76fc',
        opacity: 0.6,
    };
    legends.srce_lake_wetland = {
        layerKey: filtersKey.SRCE,
        label: 'srce_lake_wetland',
        needsTranslate: true,
        color: '#1c51a6',
        opacity: 0.6,
    };
    legends.srce_forest = {
        layerKey: filtersKey.SRCE,
        label: 'srce_forest',
        needsTranslate: true,
        color: '#4ba61b',
        opacity: 0.6,
    };
    legends.srce_wooded = {
        layerKey: filtersKey.SRCE,
        label: 'srce_wooded',
        needsTranslate: true,
        color: '#67e429',
        opacity: 0.6,
    };
    legends.srce_woodland = {
        layerKey: filtersKey.SRCE,
        label: 'srce_woodland',
        needsTranslate: true,
        color: '#73fc2e',
        opacity: 0.6,
    };
    legends.srce_calcicoles = {
        layerKey: filtersKey.SRCE,
        label: 'srce_calcicoles',
        needsTranslate: true,
        color: '#adfd7a',
        opacity: 0.6,
    };
    legends.srce_open = {
        layerKey: filtersKey.SRCE,
        label: 'srce_open',
        needsTranslate: true,
        color: '#f7a824',
        opacity: 0.6,
    };
    legends.srce_multiframe = {
        layerKey: filtersKey.SRCE,
        label: 'srce_multiframe',
        needsTranslate: true,
        color: '#a36f14',
        opacity: 0.6,
    };
    legends.srce_landscape_mosaic = {
        layerKey: filtersKey.SRCE,
        label: 'srce_landscape_mosaic',
        needsTranslate: true,
        color: '#fceab2',
        opacity: 0.6,
    };
    legends.srce_bocage = {
        layerKey: filtersKey.SRCE,
        label: 'srce_bocage',
        needsTranslate: true,
        color: '#fefc34',
        opacity: 0.6,
    };
    legends.srce_culture = {
        layerKey: filtersKey.SRCE,
        label: 'srce_culture',
        needsTranslate: true,
        color: '#a1380c',
        opacity: 0.6,
    };
    legends.srce_artificial = {
        layerKey: filtersKey.SRCE,
        label: 'srce_artificial',
        needsTranslate: true,
        color: '#fdeac0',
        opacity: 0.6,
    };
    legends.srce_coast = {
        layerKey: filtersKey.SRCE,
        label: 'srce_coast',
        needsTranslate: true,
        color: '#c1d3fe',
        opacity: 0.6,
    };
    legends.srce_alluvial = {
        layerKey: filtersKey.SRCE,
        label: 'srce_alluvial',
        needsTranslate: true,
        color: '#c3e9fe',
        opacity: 0.6,
    };
    legends.srce_wooded_wetland = {
        layerKey: filtersKey.SRCE,
        label: 'srce_wooded_wetland',
        needsTranslate: true,
        color: '#47c6fd',
        opacity: 0.6,
    };
    legends.srce_running_water = {
        layerKey: filtersKey.SRCE,
        label: 'srce_running_water',
        needsTranslate: true,
        color: '#3dabe4',
        opacity: 0.6,
    };
    legends.srce_herbaceous = {
        layerKey: filtersKey.SRCE,
        label: 'srce_herbaceous',
        needsTranslate: true,
        color: '#2d85a7',
        opacity: 0.6,
    };
    legends.srce_bat = {
        layerKey: filtersKey.SRCE,
        label: 'srce_bat',
        needsTranslate: true,
        color: '#dc19a7',
        opacity: 0.6,
    };
    legends.srce_lande = {
        layerKey: filtersKey.SRCE,
        label: 'srce_lande',
        needsTranslate: true,
        color: '#fefec1',
        opacity: 0.6,
    };
    legends.srce_other = {
        layerKey: filtersKey.SRCE,
        label: 'srce_other',
        needsTranslate: true,
        color: '#f45519',
        opacity: 0.6,
    };
    return legends;
};
const useSRCELayers = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.SRCE],
        getLegends,
    })(filtersKey.SRCE);
};
const SRCELayers = () => {
    useSRCELayers();
    return null;
};
export default SRCELayers;
