import { useMemo } from 'react';
import { useQueries } from '@tanstack/react-query';
import { getImage } from 'api/client';
const fetchImage = (email, id) => () => getImage(`legacy/team-files/${email}/${id}`, id);
const useTeamImages = (email, photoIds) => {
    const results = useQueries({
        queries: photoIds.map((id) => ({
            queryKey: ['photos', email, id],
            queryFn: fetchImage(email, id),
            staleTime: Infinity,
            enabled: !!id,
        })),
    });
    const isLoading = results.some((result) => result.isInitialLoading);
    const data = useMemo(() => {
        let photos = {};
        results.forEach((result) => {
            photos = {
                ...result.data,
                ...photos,
            };
        });
        return photos;
    }, [results]);
    return { data, isLoading };
};
export default useTeamImages;
