import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@chakra-ui/react';
import SeeMoreAccordion from 'components/SeeMoreAccordion';
const SeeMoreInformation = ({ title, value }) => {
    const { t } = useTranslation();
    const text = t(value);
    return (<Box>
      <SeeMoreAccordion title={title}>
        {text.split('\n\n').map((part, key) => {
            return (<Text textStyle="small" marginBottom={2} key={key}>
              {part.split('\n').map((i, index) => (<React.Fragment key={index}>
                  {i}
                  <br />
                </React.Fragment>))}
            </Text>);
        })}
      </SeeMoreAccordion>
    </Box>);
};
export default SeeMoreInformation;
