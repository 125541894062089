import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormControl, FormLabel, Image, Switch, Text, VStack, } from '@chakra-ui/react';
import LogoLaPoste from 'images/logo/la_poste.svg';
import { CREDIT_BLACK_AND_WHITE_ADDITIONAL_PAGE, CREDIT_BLACK_AND_WHITE_FIRST_PAGE, CREDIT_COLOR_ADDITIONAL_PAGE, CREDIT_COLOR_FIRST_PAGE, CREDIT_OPTION_TREAT_UNDELIVERED_MAIL, } from './CourierFormUtils';
const CourierOptions = ({ title }) => {
    const { t } = useTranslation();
    const { register, getValues } = useFormContext();
    return (<VStack width="full" spacing={1} alignItems="flex-start">
      <Text fontWeight="bold">
        {title}
        <Image src={LogoLaPoste} alt="la poste" display="inline-block" paddingLeft="5px" width="20px" verticalAlign="middle"/>
      </Text>
      <VStack alignItems="flex-start" spacing={1}>
        <FormControl display="flex" alignItems="center">
          <FormLabel marginBottom={0}>{t('courier.option_color')}</FormLabel>
          <Switch {...register('mailevaOptionColor')} value="1"/>
        </FormControl>
        {getValues('mailevaOptionColor') ? (<FormLabel marginBottom={0}>
            {CREDIT_COLOR_FIRST_PAGE.toFixed(2)} € (+
            {CREDIT_COLOR_ADDITIONAL_PAGE.toFixed(2)} €{' '}
            {t('courier.additional_page')})
          </FormLabel>) : (<FormLabel marginBottom={0}>
            {CREDIT_BLACK_AND_WHITE_FIRST_PAGE.toFixed(2)} € (+
            {CREDIT_BLACK_AND_WHITE_ADDITIONAL_PAGE.toFixed(2)} €{' '}
            {t('courier.additional_page')})
          </FormLabel>)}
        <FormControl display="flex" alignItems="center">
          <FormLabel marginBottom={0}>
            {t('courier.option_treat_undelivered_mail')} +
            {CREDIT_OPTION_TREAT_UNDELIVERED_MAIL.toFixed(2)} €
          </FormLabel>
          <Switch {...register('mailevaOptionTreatUndeliveredMail')} value="1"/>
        </FormControl>
      </VStack>
    </VStack>);
};
export default CourierOptions;
