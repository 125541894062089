import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
import useCurrentSite from 'hooks/useCurrentSite';
export const IOPlotFeasibilityInfo = iots.type({
    id: iots.string, // plotId
    cadastreSurface: iots.number, // surface_cadastre
    mapInfoSurface: iots.number, // surface_sig
    constructibleSurface: iots.number, // surface_const
    footprint: iots.number, // emprise_calcul
    canBeCalculated: iots.boolean,
    inconstructibleSurface: iots.number, //zones_inconstructibles_conso_calcul
    plotLineFirstSegmentMax: iots.number, // lineaire_premier_segment_maximal_calcul
    sideLimits: iots.number, // limites_laterales_calcul
    depth: iots.number, // profondeur_calcul
    requiredParkingNb: iots.number, // nb_places_exigees
    surfaceForOneParking: iots.number, // stationnement_habitations_calcul
    nbLevels: iots.number, // niveaux_regl_epan_calcul
    socialMixThreshold: iots.number,
    socialMixProportion: iots.number,
    inconstructibleUndergroundSurface: iots.number, // espace_non_constructible
});
export const IOPlotFeasibilityWithCadastre = iots.intersection([
    IOPlotFeasibilityInfo,
    iots.type({
        zone: iots.string,
        section: iots.string,
        number: iots.string,
    }),
], 'IOPlotFeasibilityWithCadastre');
const fetchPlotFeasibility = (townshipId, plotId, siteId) => () => get(IOPlotFeasibilityInfo, `legacy/${townshipId}/plot-feasibility/${plotId}`, { siteId });
const fetchPlotFeasibilityAdditional = (townshipId, plotId, siteId) => () => get(IOPlotFeasibilityInfo, `legacy/${townshipId}/plot-feasibility-additional/${plotId}`, { siteId });
const getQueryKey = (townshipId, plotId) => [
    'plot-feasibility',
    townshipId,
    plotId,
];
const getQueryKeyAdditional = (townshipId, plotId) => [
    'plot-feasibility-additional',
    townshipId,
    plotId,
];
const usePlotFeasibility = (townshipId, plotId, additional) => {
    const { currentSite } = useCurrentSite();
    const { data: plotFeasibility, isInitialLoading: isLoadingPlotFeasibility } = useQuery({
        queryKey: getQueryKey(townshipId, plotId),
        queryFn: fetchPlotFeasibility(townshipId, plotId, currentSite?.legacyId),
        enabled: !!townshipId && !!plotId,
    });
    const { data: plotFeasibilityAdditional, isInitialLoading: isLoadingPlotFeasibilityAdditional, } = useQuery({
        queryKey: getQueryKeyAdditional(townshipId, plotId),
        queryFn: fetchPlotFeasibilityAdditional(townshipId, plotId, currentSite?.legacyId),
        enabled: !!townshipId && !!plotId && additional,
    });
    return {
        plotFeasibility,
        isLoadingPlotFeasibility,
        plotFeasibilityAdditional,
        isLoadingPlotFeasibilityAdditional,
    };
};
export default usePlotFeasibility;
