import * as React from 'react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { HStack, IconButton, Link } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_OPEN } from 'analytics/useAnalytics';
import { useUpdateExtraFilter } from 'hooks/contexts/useFiltersContext';
import { useToggleLayerTab } from 'hooks/contexts/useTabsContext';
import useCurrentSite from 'hooks/useCurrentSite';
import { filtersKey } from 'utils/filters';
export const URL_SIREN = 'https://www.pappers.fr/entreprise/xx-';
export const SirenLink = ({ siren, url, analyticOrigin = ANALYTICS_OPEN, analyticDetail, id, showExternalLink, }) => {
    const { trackEvent } = useAnalytics();
    const updateExtraFilter = useUpdateExtraFilter();
    const toggleLayerTab = useToggleLayerTab();
    const { currentSite } = useCurrentSite();
    const sirenValid = siren.match('^[0-9]{9}$');
    if (!currentSite?.ownerSubscription) {
        return <>{siren}</>;
    }
    return (<HStack as="span" spacing={2}>
      <Link textStyle="small" variant="secondary" onClick={() => {
            updateExtraFilter(`${filtersKey.COMPANY_OWNED_PLOTS}.sirens`, [siren]);
            toggleLayerTab({
                tabId: filtersKey.COMPANY_OWNED_PLOTS,
            });
        }} data-cy={id}>
        {siren}
      </Link>

      {showExternalLink && sirenValid && (<Link href={url ? url : URL_SIREN + siren} textStyle="small" variant="secondary" isExternal onClick={() => {
                if (analyticOrigin && analyticDetail) {
                    trackEvent({
                        category: analyticOrigin,
                        action: analyticDetail,
                    });
                }
            }} lineHeight="1em" verticalAlign="middle">
          <IconButton icon={<ExternalLinkIcon />} aria-label="siren" variant="link" paddingStart={0} paddingEnd={0} minWidth="auto"/>
        </Link>)}
    </HStack>);
};
export default SirenLink;
