import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@chakra-ui/react';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
const PLUAdditionalSidebarContent = () => {
    const { t } = useTranslation();
    const selectedTown = useSelectedTown();
    if (!selectedTown || !selectedTown?.additionalPLU)
        return null;
    const type = !selectedTown?.additionalPLU?.infoType
        ? 'POS/PLU'
        : selectedTown?.additionalPLU?.infoType;
    return (<Box width="full" color="colorMode.font800" fontSize="13px">
      {selectedTown?.showPLU && selectedTown?.additionalPLU && (<>
          <Box paddingBottom={1}>
            <Text as="span" fontWeight={500}>{`${t('sidebar_content.plu.date')} ${type} : `}</Text>
            <Text as="span">{selectedTown?.additionalPLU.infoDate || '-'}</Text>
          </Box>
          <Box paddingBottom={1}>
            <Text as="span" fontWeight={500}>{`${t('sidebar_content.plu.state')} ${type} : `}</Text>
            <Text as="span">{selectedTown?.additionalPLU.lastDate || '-'}</Text>
          </Box>
        </>)}
    </Box>);
};
export default PLUAdditionalSidebarContent;
