import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { HStack, Text, VStack } from '@chakra-ui/react';
import PlotInfoMultiText from 'components/tabs/plots/PlotSections/info/PlotInfoMultiText';
import PlotInfoText from 'components/tabs/plots/PlotSections/info/PlotInfoText';
import { usePlotSummary } from 'hooks/contexts/useLegendContext';
import usePlotFarming from 'hooks/plot/usePlotFarming';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import SurfaceIcon from 'images/icons/Surface';
import { PLOT_INFO_FARMING } from 'types/api';
import { getPlotCadastreId } from 'utils/plots';
const PlotSummaryInfo = () => {
    const { t } = useTranslation();
    const { hasPlotInfo } = useSiteConfiguration();
    const plotSummary = usePlotSummary();
    const { plotFarming, isInitialLoading: isLoadingFarming } = usePlotFarming(hasPlotInfo(PLOT_INFO_FARMING), plotSummary);
    return (<>
      <VStack spacing={1} alignItems="stretch">
        <Text fontWeight="bold" textStyle="paragraph" textAlign="left" data-cy="plot_cadastre_id">
          {getPlotCadastreId(plotSummary)}
        </Text>
        <Text textStyle="paragraph" data-cy="plot_address">
          {plotSummary?.address?.length ? plotSummary?.address[0] : ''}
        </Text>
      </VStack>
      <HStack>
        {hasPlotInfo('information') && hasPlotInfo('zone') && (<PlotInfoText value={plotSummary?.zone.split(';').join(' ')} label={t('legends.plot_info.zone')} id="plot_zone"/>)}
        <HStack>
          <SurfaceIcon width={20} height={20}/>
          <Text textStyle="paragraph" fontWeight={600} data-cy="plot_surface">
            {`${plotSummary?.surface} m²`}
          </Text>
        </HStack>
      </HStack>
      {hasPlotInfo(PLOT_INFO_FARMING) && (<PlotInfoMultiText values={plotFarming?.length > 0
                ? plotFarming?.map((value) => {
                    return value.name + ' (' + value.info + ')';
                })
                : [t('global.none_2').toLowerCase()]} label={t('legends.plot_info.farming')} id="plot_farming" isLoading={isLoadingFarming}/>)}
    </>);
};
export default PlotSummaryInfo;
