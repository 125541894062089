import * as React from 'react';
import { useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';
import * as iots from 'io-ts';
import { get } from 'api/client';
import InfoWindowTable from 'components/infoWindow/InfoWindowTable';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOPolygonLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
const IONationalMajorProjectLayerItem = iots.intersection([
    IOPolygonLayerItem,
    iots.type({
        id: iots.string,
    }),
]);
const IONationalMajorProjectInfo = iots.type({
    operationId: iots.string,
    projectId: iots.string,
    name: iots.string,
    desc: iots.union([iots.string, iots.null]),
    desc2: iots.union([iots.string, iots.null]),
    consumption: iots.number,
});
const formatInfoWindowContent = (item, t) => {
    const content = (<Box maxHeight="400px" overflow="hidden">
      <InfoWindowTable content={[
            {
                title: t('info_window.national_major_project.name'),
                data: item.name,
                bold: true,
            },
            {
                title: t('info_window.national_major_project.desc'),
                data: item.desc,
            },
            {
                title: t('info_window.national_major_project.desc2'),
                data: item.desc2,
            },
            {
                title: t('info_window.national_major_project.consumption'),
                data: item.consumption + ' ha',
            },
            {
                title: t('info_window.national_major_project.operation_id'),
                data: item.operationId,
            },
            {
                title: t('info_window.national_major_project.project_id'),
                data: item.projectId,
            },
        ]}/>
    </Box>);
    return ReactDOMServer.renderToStaticMarkup(content);
};
const fetchNationalMajorProject = (townId) => () => get(iots.array(IOPolygonLayerItem), `${townId}/national-major-project`);
const fetchNationalMajorProjectInfo = (item) => {
    return get(IONationalMajorProjectInfo, `national-major-project/${item.id}`);
};
const useNationalMajorProjectLayer = () => {
    const { t } = useTranslation();
    const [polygonInfo, setPolygonInfo] = useState({});
    const getInfoWindowContent = async (layerInfo) => {
        if (polygonInfo[layerInfo.id]) {
            return polygonInfo[layerInfo.id];
        }
        const nationalMajorProjectInfo = await fetchNationalMajorProjectInfo(layerInfo);
        const content = formatInfoWindowContent(nationalMajorProjectInfo, t);
        setPolygonInfo({
            ...polygonInfo,
            [layerInfo.id]: content,
        });
        return content;
    };
    createLayersHook({
        fetch: fetchNationalMajorProject,
        getInfoWindowContent,
    })(filtersKey.NATIONAL_MAJOR_PROJECT);
};
const NationalMajorProjectLayer = () => {
    useNationalMajorProjectLayer();
    return null;
};
export default NationalMajorProjectLayer;
