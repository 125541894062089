import { useEffect, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { post } from 'api/client';
import { IOPolygonLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { formatSearchData, searchHasFilter, } from 'utils/plotsSearch';
export const fetchPlotsSearch = (townId, townScot, searchData) => () => post(iots.array(IOPlotsSearchLayerItem), `legacy/plots-search/${townId}`, {
    ...searchData,
});
const IOPlotsSearchLayerItem = IOPolygonLayerItem;
const usePlotsSearchResults = (searchData, currentTown) => {
    const [isNewResult, setIsNewResult] = useState(false);
    const queryClient = useQueryClient();
    const formattedSearchData = useMemo(() => formatSearchData(searchData, currentTown?.hasRegulationAnalysis || false), [searchData, currentTown]);
    const queryKey = [
        'layer',
        filtersKey.PLOTS_SEARCH,
        currentTown?.id,
        formattedSearchData,
    ];
    useEffect(() => {
        const result = queryClient.getQueryData(queryKey);
        if (result) {
            setIsNewResult(true);
        }
    }, [formattedSearchData]);
    const hasSearch = searchHasFilter(formattedSearchData);
    const { data: searchResults, isFetching, isInitialLoading, } = useQuery({
        queryKey,
        queryFn: fetchPlotsSearch(currentTown?.id, null, formattedSearchData),
        enabled: !!currentTown && !!hasSearch,
    });
    const searchResultsCount = searchResults?.length || 0;
    const getPlotsIds = (limit = 1000) => {
        const plots = limit ? searchResults?.slice(0, limit) : searchResults;
        return plots?.map((plot) => plot.id);
    };
    const searchResultsIds = [];
    searchResults?.forEach((plot) => {
        searchResultsIds.push(plot.id);
    });
    const formattedResults = useMemo(() => {
        const results = {};
        searchResults?.forEach((plot) => {
            results[plot.id] = plot;
        });
        return results;
    }, [JSON.stringify(searchResultsIds)]);
    return {
        isInitialLoading,
        formattedResults,
        searchResultsCount,
        getPlotsIds,
        isFetching,
        isNewResult,
        setIsNewResult,
    };
};
export default usePlotsSearchResults;
