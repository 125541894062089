import { useQuery } from '@tanstack/react-query';
import { get } from 'api/client';
export const QUERY_KEY_PROJECT = ['team-stats-projects'];
export const QUERY_KEY_PLOT = ['team-stats-plots'];
export const QUERY_KEY_CONTACT = ['team-stats-contacts'];
export const QUERY_KEY_ACTIVITY = ['team-stats-activities'];
export const QUERY_KEY_COURIER = ['team-stats-couriers'];
export const QUERY_KEY_FILTER = ['team-stats-filters'];
const fetchStatsProjects = () => () => get(null, 'team-statistics/projects');
const fetchStatsPlots = () => () => get(null, 'team-statistics/plots');
const fetchStatsContacts = () => () => get(null, 'team-statistics/contacts');
const fetchStatsActivities = () => () => get(null, 'team-statistics/activities');
const fetchStatsCouriers = () => () => get(null, 'team-statistics/couriers');
const fetchStatsFilters = () => () => get(null, 'team-statistics/filters');
const useTeamStatisticsCRM = ({ toLoad }) => {
    const { data: statsProjects, isInitialLoading: isLoadingStatsProjects, refetch: refetchProjects, } = useQuery({
        queryKey: QUERY_KEY_PROJECT,
        queryFn: fetchStatsProjects(),
        enabled: toLoad === 'projects',
    });
    const { data: statsPlots, isInitialLoading: isLoadingStatsPlots, refetch: refetchPlots, } = useQuery({
        queryKey: QUERY_KEY_PLOT,
        queryFn: fetchStatsPlots(),
        enabled: toLoad === 'plots',
    });
    const { data: statsContacts, isInitialLoading: isLoadingStatsContacts, refetch: refetchContacts, } = useQuery({
        queryKey: QUERY_KEY_CONTACT,
        queryFn: fetchStatsContacts(),
        enabled: toLoad === 'contacts',
    });
    const { data: statsActivities, isInitialLoading: isLoadingStatsActivities, refetch: refetchActivities, } = useQuery({
        queryKey: QUERY_KEY_ACTIVITY,
        queryFn: fetchStatsActivities(),
        enabled: toLoad === 'activities',
    });
    const { data: statsCouriers, isInitialLoading: isLoadingStatsCouriers, refetch: refetchCouriers, } = useQuery({
        queryKey: QUERY_KEY_COURIER,
        queryFn: fetchStatsCouriers(),
        enabled: toLoad === 'activities',
    });
    const { data: statsFilters, isInitialLoading: isLoadingStatsFilters, refetch: refetchFilters, } = useQuery({
        queryKey: QUERY_KEY_FILTER,
        queryFn: fetchStatsFilters(),
        enabled: toLoad === 'filters',
    });
    return {
        statsProjects,
        isLoadingStatsProjects,
        refetchProjects,
        statsPlots,
        isLoadingStatsPlots,
        refetchPlots,
        statsContacts,
        isLoadingStatsContacts,
        refetchContacts,
        statsActivities,
        isLoadingStatsActivities,
        refetchActivities,
        statsCouriers,
        isLoadingStatsCouriers,
        refetchCouriers,
        statsFilters,
        isLoadingStatsFilters,
        refetchFilters,
    };
};
export default useTeamStatisticsCRM;
