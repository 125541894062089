// Regex from legacy code
import { rankings, rankItem } from '@tanstack/match-sorter-utils';
export const EMAIL_PATTERN = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
export const removeDiacritics = (str) => str.normalize('NFKD').replace(/[^\w]/g, '');
// The react-table built-in method arrayIncludesSome only check if row value CONTAINS some value of filterValue.
// In some cases, we want row value to match exactly with some values of filterValue.
export const arrayHasSome = (row, columnId, filterValue) => {
    if (filterValue.length === 0) {
        return true;
    }
    return filterValue.some((val) => {
        const value = row.getValue(columnId);
        return value == null ? void 0 : value === val;
    });
};
export const searchMatch = (value, search, addMeta) => {
    const itemRank = rankItem(value, search, {
        keepDiacritics: false,
        threshold: rankings.ACRONYM,
    });
    if (addMeta) {
        addMeta({
            itemRank,
        });
    }
    return itemRank.passed;
};
export const inDateRange = (row, columnId, filterValue) => {
    if (filterValue.length === 0) {
        return true;
    }
    const value = new Date(row.getValue(columnId)).getTime();
    const minDate = filterValue[0] && new Date(filterValue[0]).getTime();
    const maxDate = filterValue[1] && new Date(filterValue[1]);
    if (maxDate) {
        maxDate.setDate(maxDate.getDate() + 1);
    }
    if (minDate && maxDate && (minDate > value || maxDate.getTime() < value)) {
        return false;
    }
    if (minDate && minDate > value) {
        return false;
    }
    if (maxDate && maxDate.getTime() < value) {
        return false;
    }
    return true;
};
const toStringIfNumber = (value) => typeof value === 'number' ? value.toString() : value;
export const arrIncludesSomeWithNumber = (row, columnId, filterValue) => {
    if (filterValue.length === 0) {
        return true;
    }
    const rowValue = toStringIfNumber(row.getValue(columnId));
    return filterValue.some((val) => rowValue?.includes(toStringIfNumber(val)));
};
export const extractTitle = (element) => {
    const span = document.createElement('span');
    span.innerHTML = element;
    return span.textContent || span.innerText;
};
export const extractLink = (element) => {
    const span = document.createElement('span');
    span.innerHTML = element;
    return span.getElementsByTagName('a')[0]?.getAttribute('href');
};
export const removeAccent = (string) => {
    return string?.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};
