import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { IOStaff } from './usePlotOwnersInfo';
const IOPlotOccupantsInfo = iots.type({
    siren: iots.string,
    companyName: iots.string,
    ape: iots.string, // code APE
    companyActivity: iots.string,
    form: iots.string, // forme
    category: iots.string,
    address: iots.string,
    postalCode: iots.string,
    town: iots.string,
    decreeDate: iots.string,
    tefen: iots.number,
    staff: iots.array(IOStaff),
});
const fetchPlotOccupants = (townshipId, plotId) => () => get(iots.array(IOPlotOccupantsInfo), `${townshipId}/plot-occupants/${plotId}`);
export const QUERY_KEY = 'plot-occupants';
export const getQueryKey = (townshipId, plotId) => [
    QUERY_KEY,
    townshipId,
    plotId,
];
const usePlotOccupants = (plot, enabled) => {
    const { data: occupants, isInitialLoading } = useQuery({
        queryKey: getQueryKey(plot?.townId, plot?.id),
        queryFn: fetchPlotOccupants(plot?.townId, plot?.id),
        enabled: !!plot?.townId && !!plot?.cadastreId && enabled,
    });
    let countOccupant = 0;
    if (occupants) {
        countOccupant = Object.keys(occupants).length;
    }
    return { occupants, isInitialLoading, countOccupant };
};
export default usePlotOccupants;
