import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Heading, HStack, Text, useDisclosure, VStack, } from '@chakra-ui/react';
import { getCivilityFromCode } from 'components/inputs/CivilityTitleSelect';
import useCourier from 'hooks/courier/useCourier';
import { formatNumberToLocale } from 'utils/formatNumber';
const SendCouriersDrawer = ({ couriersInfo, couriersCredits, handleClose, }) => {
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    React.useEffect(() => {
        if (couriersCredits && !isOpen) {
            onOpen();
        }
    }, [couriersCredits]);
    const totalPrice = couriersCredits && formatNumberToLocale(couriersCredits, 'fr');
    const { statsCouriersUser } = useCourier({
        enabledList: true,
    });
    const { sendMultipleCouriers, isSendingMultipleCouriers } = useCourier({
        enabledList: false,
        handleSuccess: onClose,
    });
    const handleConfirm = () => {
        sendMultipleCouriers({
            couriersIds: couriersInfo.map((courier) => courier.id),
        });
    };
    const remainingCredits = statsCouriersUser?.nbCredit || 0;
    const disabled = remainingCredits < couriersCredits || isSendingMultipleCouriers;
    return (<Drawer isOpen={isOpen} onClose={() => {
            handleClose();
            onClose();
        }} size="md" returnFocusOnClose={false}>
      <DrawerOverlay />
      <DrawerContent backgroundColor="colorMode.background900">
        <DrawerCloseButton />
        <DrawerHeader>
          <Heading as="h4" variant="h4">
            {t(`courier.send_courier`)}
          </Heading>
        </DrawerHeader>
        <DrawerBody>
          <VStack justifyContent="space-between">
            <VStack spacing={4} alignItems="flex-start">
              <Text textStyle="paragraph">{t('courier.confirm_info')}</Text>

              <VStack width={'100%'} spacing={2} alignItems="flex-start">
                <Text textStyle="paragraph" fontWeight="bold">
                  {t('courier.recipients')}
                </Text>
                {couriersInfo?.map((courier, index) => (<HStack key={index} width="100%">
                    <VStack key={index} alignItems="flex-start" p={2} borderRadius="lg" border="1px solid lightgray" fontSize="14px" flex={1}>
                      <Text>{`${getCivilityFromCode(courier.civility)}
                    ${courier.firstname} ${courier.lastname}`}</Text>
                      {courier.company && <Text>{`${courier.company}`}</Text>}
                      <Text>{courier.address}</Text>
                      <Text>{`${courier.postalCode} ${courier.town}`}</Text>
                      {courier.addressBP && (<Text>{`${courier.addressBP}`}</Text>)}
                      {courier.addressComplement && (<Text>{`${courier.addressComplement} `}</Text>)}
                    </VStack>
                    <Text p={2} fontStyle="bold" color="red.500" fontSize="20px">
                      {courier.credits}&nbsp;€
                    </Text>
                  </HStack>))}
              </VStack>
            </VStack>

            {remainingCredits < couriersCredits && (<Text color="red.500" fontSize="14px">
                {t('courier.insufficient_credits', {
                credits: formatNumberToLocale(remainingCredits, 'fr'),
            })}
              </Text>)}
            <HStack width={'100%'} justifyContent="flex-end">
              <Button paddingX={10} variant="ghostBorder" onClick={() => {
            onClose();
            handleClose();
        }}>
                {t('global.actions.cancel')}
              </Button>
              <Button paddingX={10} variant="primary" type="button" loadingText={t('global.status.creating')} isDisabled={disabled} onClick={handleConfirm} 
    // isLoading={loading}
    data-cy="courier_form_submit_send">
                {t('courier.action.send_credit', { euro: totalPrice })}
              </Button>
            </HStack>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>);
};
export default SendCouriersDrawer;
