import * as React from 'react';
import { useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import agefoImg from 'images/studentResidences/agefo.png';
import aljtImg from 'images/studentResidences/aljt.png';
import appartcityImg from 'images/studentResidences/appartcity.png';
import arpejImg from 'images/studentResidences/arpej.png';
import campuseaImg from 'images/studentResidences/campusea.png';
import capEtudesImg from 'images/studentResidences/cap-etudes.png';
import ciupImg from 'images/studentResidences/ciup.png';
import clubetudiantImg from 'images/studentResidences/clubetudiant.png';
import crousImg from 'images/studentResidences/crous.png';
import espacilHabitatImg from 'images/studentResidences/espacil-habitat.png';
import estudinesImg from 'images/studentResidences/estudines.png';
import facHabitatImg from 'images/studentResidences/fac-habitat.png';
import heneoImg from 'images/studentResidences/heneo.png';
import lesbellesanneesImg from 'images/studentResidences/lesbellesannees.png';
import logirepImg from 'images/studentResidences/logirep.png';
import nexityStudeaImg from 'images/studentResidences/nexity-studea.png';
import residetapesImg from 'images/studentResidences/residetapes.png';
import studefiImg from 'images/studentResidences/studefi.png';
import studelitesImg from 'images/studentResidences/studelites.png';
import suitetudesImg from 'images/studentResidences/suitetudes.png';
import { IOMarkerLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
const IOStudentResidencesMarkerItem = iots.intersection([
    IOMarkerLayerItem,
    iots.type({
        name: iots.string,
        label: iots.string,
    }),
    iots.partial({
        address: iots.string,
        postcode: iots.string,
        town: iots.string,
        url: iots.string,
    }),
]);
const studentResidencesImagesForLabel = {
    agefo: agefoImg,
    aljt: aljtImg,
    appartcity: appartcityImg,
    arpej: arpejImg,
    campusea: campuseaImg,
    'cap-etudes': capEtudesImg,
    ciup: ciupImg,
    clubetudiant: clubetudiantImg,
    crous: crousImg,
    'espacil-habitat': espacilHabitatImg,
    estudines: estudinesImg,
    'fac-habitat': facHabitatImg,
    heneo: heneoImg,
    lesbellesannees: lesbellesanneesImg,
    logirep: logirepImg,
    'nexity-studea': nexityStudeaImg,
    residetapes: residetapesImg,
    studefi: studefiImg,
    studelites: studelitesImg,
    suitetudes: suitetudesImg,
};
const getMarkerImg = (marker) => {
    return studentResidencesImagesForLabel[marker.label.toLowerCase()];
};
const fetchStudentResidences = (townId) => () => get(iots.array(IOStudentResidencesMarkerItem), `legacy/${townId}/student-residences`);
const formatInfoWindowContent = (marker, t) => {
    const content = (<Box>
      <Text fontWeight={900}>{marker.name}</Text>
      <Text>{`${marker.address} ${marker.postcode} ${marker.town}`}</Text>
      {marker.url && (<a href={marker.url} rel="noreferrer" target="_blank">
          {t('info_window.student_residences.url')}
        </a>)}
    </Box>);
    return ReactDOMServer.renderToStaticMarkup(content);
};
const useStudentResidencesLayer = () => {
    const { t } = useTranslation();
    const selectedTown = useSelectedTown();
    const townId = selectedTown?.id;
    const queryClient = useQueryClient();
    const data = queryClient.getQueryData([
        'layer',
        filtersKey.STUDENT_RESIDENCES,
        townId,
    ]);
    const legends = useMemo(() => {
        const legendsData = {};
        (data ?? []).forEach((marker) => {
            if (!legendsData[marker.label]) {
                legendsData[marker.label] = {
                    layerKey: filtersKey.STUDENT_RESIDENCES,
                    label: marker.label,
                    image: getMarkerImg(marker),
                };
            }
        });
        return legendsData;
    }, [data]);
    const getInfoWindowContent = (marker) => {
        return formatInfoWindowContent(marker, t);
    };
    createLayersHook({
        fetch: fetchStudentResidences,
        markerImg: getMarkerImg,
        legends,
        getInfoWindowContent,
    })(filtersKey.STUDENT_RESIDENCES);
};
const StudentResidencesLayer = () => {
    useStudentResidencesLayer();
    return null;
};
export default StudentResidencesLayer;
