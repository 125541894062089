import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, FormControl, FormErrorMessage, FormLabel, Input, Stack, Text, } from '@chakra-ui/react';
import useResetPassword from 'hooks/useResetPassword';
import { validatePassword } from 'hooks/useUserChangePassword';
const ResetPasswordForm = () => {
    const { t } = useTranslation();
    const resolver = async (values) => {
        const errors = {};
        const passwordError = validatePassword(values.password);
        if (passwordError) {
            errors.password = {
                type: 'required',
                message: t(passwordError),
            };
        }
        else if (values.password !== values.confirmPassword) {
            errors.confirmPassword = {
                type: 'required',
                message: t('reset_password.passwords_do_not_match'),
            };
        }
        return { values, errors };
    };
    const { register, handleSubmit, formState: { isSubmitting, errors }, reset, } = useForm({
        resolver,
    });
    const { resetPassword } = useResetPassword(() => reset());
    return (<Box as="form" onSubmit={handleSubmit((data) => resetPassword(data))}>
      <Stack paddingBottom={2} width="300px" spacing={2}>
        <FormControl isInvalid={!!errors?.password}>
          <FormLabel>{t('reset_password.password_label')}</FormLabel>
          <Input {...register('password')} type="password" placeholder={t('reset_password.password_placeholder')}/>
          <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors?.confirmPassword}>
          <FormLabel>{t('reset_password.confirm_password_label')}</FormLabel>
          <Input {...register('confirmPassword')} type="password" placeholder={t('reset_password.confirm_password_placeholder')}/>
          <Text textStyle="small" paddingTop={1}>
            {t('reset_password.password_invalid')}
          </Text>
          <FormErrorMessage>
            {errors?.confirmPassword?.message}
          </FormErrorMessage>
        </FormControl>
      </Stack>

      <Button width={300} variant="primary" isDisabled={isSubmitting} type="submit">
        {t('reset_password.send')}
      </Button>
    </Box>);
};
export default ResetPasswordForm;
