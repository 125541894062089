import * as React from 'react';
import { memo, useEffect } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { EmailIcon } from '@chakra-ui/icons';
import { Link, Stack, Text } from '@chakra-ui/react';
import { snakeCase } from 'lodash';
import ContactModal from 'components/modals/ContactModal';
import Table from 'components/table/Table';
import { CRM_TABS } from 'context/TabsContext';
import { useToggleCRMTab } from 'hooks/contexts/useTabsContext';
import useTeamUsersList from 'hooks/crm/useTeamUsersList';
import { formatDate } from 'utils/formatDate';
import { HELPDESK_MESSAGE_TYPES } from 'utils/helpdesk';
import { inDateRange } from 'utils/search';
const getColumnHeader = (column, year = '') => (<Translation>
    {(t) => (<Text align="center">
        {t(`tabs.team_access_rights.columns.${column}`, {
            year,
        })}
      </Text>)}
  </Translation>);
const currentYear = new Date().getFullYear();
const COLUMNS = [
    {
        id: 'columns',
        columns: [
            {
                accessorKey: 'username',
                header: () => getColumnHeader('username'),
                cell: (info) => info.getValue(),
                filterFn: 'arrIncludesSome',
                enableColumnFilter: false,
            },
            {
                accessorKey: 'email',
                header: () => getColumnHeader('email'),
                cell: (info) => info.getValue(),
                filterFn: 'arrIncludesSome',
                enableColumnFilter: false,
            },
            {
                accessorKey: 'group',
                header: () => getColumnHeader('group'),
                cell: (info) => info.getValue(),
                filterFn: 'arrIncludesSome',
                enableColumnFilter: false,
            },
            {
                accessorKey: 'accountRole',
                header: () => getColumnHeader('account_role'),
                cell: (info) => info.getValue(),
                filterFn: 'arrIncludesSome',
                enableColumnFilter: false,
            },
            {
                accessorKey: 'visibleUsers',
                header: () => getColumnHeader('visible_users'),
                cell: (info) => info.getValue(),
                filterFn: 'inNumberRange',
                enableGlobalFilter: false,
            },
            {
                accessorKey: 'createdAt',
                header: () => getColumnHeader('created_at'),
                cell: (info) => formatDate(info.getValue()),
                enableGlobalFilter: false,
                filterFn: inDateRange,
            },
            {
                accessorKey: 'connectionCountCurrentYear',
                header: () => getColumnHeader('uniq_plot_number_current_year', `${currentYear}`),
                cell: (info) => info.getValue(),
                filterFn: 'inNumberRange',
                enableGlobalFilter: false,
            },
            {
                accessorKey: 'connectionsCountPreviousYear',
                header: () => getColumnHeader('uniq_plot_number_previous_year', `${currentYear - 1}`),
                cell: (info) => info.getValue(),
                filterFn: 'inNumberRange',
                enableGlobalFilter: false,
            },
            {
                accessorKey: 'connectionsCountBeforePreviousYear',
                header: () => getColumnHeader('uniq_plot_number_before_previous_year', `${currentYear - 2}`),
                cell: (info) => info.getValue(),
                filterFn: 'inNumberRange',
                enableGlobalFilter: false,
            },
            {
                accessorKey: 'lastOnline',
                header: () => getColumnHeader('last_online'),
                cell: (info) => formatDate(info.getValue()),
                enableGlobalFilter: false,
                filterFn: inDateRange,
            },
        ],
    },
];
const EXPORTED_FIELDS = [
    'username',
    'email',
    'group',
    'accountRole',
    'visibleUsers',
    'createdAt',
    'connectionCountCurrentYear',
    'connectionsCountPreviousYear',
    'connectionsCountBeforePreviousYear',
    'lastOnline',
];
const formatExportHeader = (t) => (header) => t(`tabs.team_access_rights.columns.${snakeCase(header)}`);
const formatExportValue = (value, field) => {
    if (field === 'createdAt' || field === 'lastOnline') {
        return formatDate(value);
    }
    return value;
};
const TeamAccessRightsPanel = () => {
    const { t } = useTranslation();
    const toggleCRMTab = useToggleCRMTab();
    const { data: teamUsers, isLoading, error } = useTeamUsersList();
    useEffect(() => {
        if (error) {
            toggleCRMTab({ tabId: CRM_TABS.TEAM_ACCESS_RIGHTS, closeTab: true });
        }
    }, [error]);
    // This page has restricted access so we don't want to show anything
    // if the query returns an error
    if (error) {
        return null;
    }
    return (<Stack paddingX={5} height="100%">
      <Table headerTitle={<Text textStyle="paragraph">
            {t(`tabs.team_access_rights.intro`)}
            <ContactModal title={t(`tabs.team_access_rights.title`)} type={HELPDESK_MESSAGE_TYPES.manageUser} triggerItem={(onOpen) => (<Link color="red.500" size="sm" onClick={onOpen}>
                  <EmailIcon marginRight={1}/>
                  {t(`tabs.team_access_rights.contact_us`)}
                </Link>)}/>{' '}
            {t(`tabs.team_access_rights.intro_bis`)}{' '}
            <Link variant="primary" href="mailto:pro@kelfoncier.com" isExternal textDecoration="underline">
              pro@kelfoncier.com
            </Link>
          </Text>} id="table-teamAccessRight" columns={COLUMNS} data={teamUsers || []} isLoading={isLoading} emptyMessage={t(`tabs.team_access_rights.no_result`)} exportTitle={t(`tabs.team_access_rights.title`)} exportedFields={EXPORTED_FIELDS} formatExportHeader={formatExportHeader(t)} formatExportValue={formatExportValue}/>
    </Stack>);
};
export default memo(TeamAccessRightsPanel);
