import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOWaterPipelineLayerItem = IOLayerItem;
const fetchWaterPipeline = (townId) => () => get(iots.array(IOWaterPipelineLayerItem), `legacy/${townId}/water-pipeline`);
const useWaterPipelineLayer = () => {
    createLayersHook({
        fetch: fetchWaterPipeline,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.WATER_PIPELINE);
};
const WaterPipelineLayer = () => {
    useWaterPipelineLayer();
    return null;
};
export default WaterPipelineLayer;
