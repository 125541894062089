import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Icon, Tooltip } from '@chakra-ui/react';
import TooltipIcon from 'images/icons/Tooltip';
const InfoTooltip = ({ label }) => {
    const { t } = useTranslation();
    return (<Tooltip padding={2} label={typeof label === 'string' ? t(label) : label} backgroundColor="colorMode.base" fontSize="11px" color="colorMode.font800">
      <Box borderRadius="full" marginLeft={2} boxShadow="md" alignItems="center" width="16px" height="16px" backgroundColor="colorMode.base">
        <Icon as={TooltipIcon}/>
      </Box>
    </Tooltip>);
};
export default InfoTooltip;
