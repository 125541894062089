import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { get, post } from 'api/client';
import { QUERY_KEY as QUERY_KEY_PROJECT_CONTACTS } from 'hooks/crm/useProjectContacts';
import { QUERY_KEY as USER_PLOTS_QUERY_KEY } from 'hooks/crm/useUserPlotsList';
import useOnError from 'hooks/useOnError';
const fetchPlotContacts = (plotId) => () => get(null, `legacy/plot/${plotId}/contacts`);
const updatePlotContactRequest = ({ plotId, plotContactId, ...rest }) => post(null, `legacy/plot/${plotId}/contact/${plotContactId}/update`, rest);
const updatePlotsContactStatusRequest = ({ plotIds, plotContactId, status, }) => post(null, `plots/contact/${plotContactId}/status`, {
    plotIds,
    statusId: status,
});
export const QUERY_KEY = 'plot-contacts';
const usePlotContact = (plotId, onSuccess) => {
    const queryClient = useQueryClient();
    const { data: plotContacts, isInitialLoading: isLoading } = useQuery({
        queryKey: [QUERY_KEY, { plotId }],
        queryFn: fetchPlotContacts(plotId),
        enabled: !!plotId,
    });
    const onErrorCallback = useOnError();
    const { mutate: updatePlotContact } = useMutation({
        mutationFn: updatePlotContactRequest,
        onError: (err) => onErrorCallback(err),
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: USER_PLOTS_QUERY_KEY });
            queryClient.invalidateQueries({ queryKey: [QUERY_KEY_PROJECT_CONTACTS] });
            queryClient.invalidateQueries({
                queryKey: [QUERY_KEY, { plotId: variables.plotId }],
            });
            if (onSuccess) {
                onSuccess();
            }
        },
    });
    const { mutate: updatePlotsContactStatus } = useMutation({
        mutationFn: updatePlotsContactStatusRequest,
        onError: (err) => onErrorCallback(err),
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: USER_PLOTS_QUERY_KEY });
            queryClient.invalidateQueries({ queryKey: [QUERY_KEY_PROJECT_CONTACTS] });
            variables.plotIds.map((plotId) => {
                queryClient.invalidateQueries({
                    queryKey: [QUERY_KEY, { plotId: plotId }],
                });
            });
            if (onSuccess) {
                onSuccess();
            }
        },
    });
    return {
        plotContacts,
        isLoading,
        updatePlotContact,
        updatePlotsContactStatus,
    };
};
export default usePlotContact;
