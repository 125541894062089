import * as React from 'react';
import { Box, Stack, VStack } from '@chakra-ui/react';
import { ANALYTICS_PLOT_TAB } from 'analytics/useAnalytics';
import CreateContactButton from 'components/buttons/CreateContactButton';
import OwnerDetail from './OwnerDetail';
import OwnerIdentity from './OwnerIdentity';
import OwnerStaff from './OwnerStaff';
import OwnerType from './OwnerType';
import { formatOwnerContactInfo, NoOwner } from './PlotOwnerDetailWrapper';
const OwnerBlockDisplay = ({ owners, setContactToCreate, }) => {
    return (<VStack alignItems="stretch">
      {owners.length > 0 ? (owners?.map((owner, index) => {
            return (<VStack key={`owner-${index}`} padding={2} spacing={1} alignItems="stretch">
              <Stack direction={{ base: 'column', lg: 'row' }}>
                <Box flex={1}>
                  <OwnerIdentity owner={owner}/>
                </Box>
                <Box flex={1}>
                  <OwnerDetail owner={owner}/>
                </Box>
              </Stack>

              <Stack direction={{ base: 'column', lg: 'row' }}>
                <Box flex={1}>
                  <OwnerType owner={owner}/>
                </Box>
                <Box flex={1}>
                  <CreateContactButton newContact={formatOwnerContactInfo(owner)} setContactToCreate={setContactToCreate} analyticsCategory={ANALYTICS_PLOT_TAB} analyticsAction="Création contact - sous onglet propriétaire"/>
                </Box>
              </Stack>
              <OwnerStaff owner={owner}/>
            </VStack>);
        })) : (<Box padding={2}>
          <NoOwner />
        </Box>)}{' '}
    </VStack>);
};
export default OwnerBlockDisplay;
