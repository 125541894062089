import * as React from 'react';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, HStack, Input, Text } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_CATEGORY_SEARCH } from 'analytics/useAnalytics';
import { FEASIBILITY_MODE, PROJECT_MODE, SEARCH_MODE, } from 'context/PlotSearchContext';
import { useToggleFilter } from 'hooks/contexts/useFiltersContext';
import { useSetLayoutHalf } from 'hooks/contexts/useLayoutContext';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { useCurrentSearchTownId, useSetPlotSearchParams, } from 'hooks/contexts/usePlotSearchContext';
import { filtersKey } from 'utils/filters';
import { PLOTS_SEARCH_DEFAUT_FORM_VALUES, } from 'utils/plotsSearch';
// Plot id is composed of :
// - a prefix (3 digits number)
// - a section (one or two letters)
// - the plot number (4 digits)
// (source: http://id.eaufrance.fr/ddd/EPR/2002-1/CdParcelleCada)
const PATTERNS = {
    prefix: /^[0-9]{3}$/,
    section: /[0-9A-Za-z]{1,2}/,
    number: /^[0-9]{1,4}$/,
};
const filterValues = (values) => Object.keys(values).filter((key) => ['prefix', 'section', 'number'].includes(key));
const validateForm = (values) => {
    const errors = {};
    const emptyFields = filterValues(values).filter((key) => !values[key] || values[key].trim() === '');
    // No fields set
    if (emptyFields.length === 3) {
        return errors;
    }
    emptyFields.forEach((field) => {
        errors[field] = 'required';
    });
    filterValues(values).forEach((field) => {
        const value = values[field];
        if (!emptyFields.includes(field) && !value.match(PATTERNS[field])) {
            errors[field] = 'invalid';
        }
    });
    return errors;
};
const resolver = (values) => {
    const valuesWithDefaultPrefix = {
        ...values,
        prefix: values.prefix && values.prefix !== '' ? values.prefix : '000',
        section: values.section && values.section !== '' ? values.section : '00',
    };
    return {
        values: valuesWithDefaultPrefix,
        errors: validateForm(valuesWithDefaultPrefix),
    };
};
const SearchByPlot = ({ mode, withLabel = true, submitText = 'header.search_plot.see_plot', }) => {
    const { t } = useTranslation();
    const toggleFilter = useToggleFilter();
    const selectedTown = useSelectedTown();
    const { trackEvent } = useAnalytics();
    const setPlotSearchParams = useSetPlotSearchParams();
    const currentSearchTownId = useCurrentSearchTownId();
    const { register, handleSubmit, formState: { errors }, setValue, reset, } = useForm({
        defaultValues: PLOTS_SEARCH_DEFAUT_FORM_VALUES,
        resolver,
    });
    const sectionInputRef = useRef(null);
    const numberInputRef = useRef(null);
    const submitBtnRef = useRef(null);
    const setLayoutHalf = useSetLayoutHalf();
    const { onChange: onChangePrefixForm, ...registerPrefix } = register('prefix');
    const { ref: sectionRef, onChange: onChangeSectionForm, ...registerSection } = register('section');
    const { ref: numberRef, onChange: onChangeNumberForm, ...registerNumber } = register('number');
    const searchPlot = (values) => {
        const emptyFields = filterValues(values).filter((key) => !values[key] || values[key].trim() === '');
        // Some fields not set
        if (emptyFields.length > 0) {
            // we show plots layer instead
            if (mode === SEARCH_MODE) {
                toggleFilter(filtersKey.PLOTS, true);
            }
            return;
        }
        // else we search for the plot
        const { prefix, section, number } = values;
        currentSearchTownId.current = selectedTown?.id;
        setPlotSearchParams({
            mode,
            values: {
                ...PLOTS_SEARCH_DEFAUT_FORM_VALUES,
                prefix: prefix.trim().padStart(3, '0'),
                section: section.trim().padStart(2, '0').toUpperCase(),
                number: number.trim().padStart(4, '0'),
            },
        });
        switch (mode) {
            case FEASIBILITY_MODE:
                trackEvent({
                    category: ANALYTICS_CATEGORY_SEARCH,
                    action: 'Onglet faisabilité',
                });
                break;
            case SEARCH_MODE:
                trackEvent({
                    category: ANALYTICS_CATEGORY_SEARCH,
                    action: 'Numéro de parcelle',
                });
                break;
            case PROJECT_MODE:
                trackEvent({
                    category: ANALYTICS_CATEGORY_SEARCH,
                    action: 'Numéro de parcelle dans projet',
                });
                break;
        }
        reset();
    };
    // Automatically go to next field when field is full
    const onChangePrefix = (e) => {
        onChangePrefixForm(e);
        const value = e.target.value;
        if (value.length === 3) {
            sectionInputRef?.current.focus();
        }
        if (value.length > 3) {
            setValue('prefix', value.slice(0, 3));
        }
    };
    const onChangeSection = (e) => {
        onChangeSectionForm(e);
        const value = e.target.value;
        if (value.length === 2) {
            numberInputRef?.current.focus();
        }
        if (value.length > 2) {
            setValue('section', value.slice(0, 2));
        }
    };
    const onChangeNumber = (e) => {
        onChangeNumberForm(e);
        const value = e.target.value;
        if (value.length === 4) {
            submitBtnRef?.current.focus();
        }
        if (value.length > 4) {
            setValue('number', value.slice(0, 4));
        }
    };
    return (<Box>
      <form onSubmit={handleSubmit(searchPlot)}>
        <HStack spacing={3} alignItems="flex-end" flexWrap="wrap">
          <HStack spacing={3} alignItems="flex-end">
            {withLabel && (<Box display={{
                base: 'none',
                '2xl': 'inline-block',
            }}>
                <Text lineHeight={1.3} textStyle="paragraph" textAlign="left" width="100px">
                  {t('header.search_plot.search_title')}
                </Text>
              </Box>)}
            <Box maxWidth="75px">
              <Text textStyle="small">
                {t('header.search_plot.prefix.label')}
              </Text>
              <Input {...registerPrefix} type="number" isInvalid={!!errors.prefix} onChange={onChangePrefix} size="sm" placeholder={t('header.search_plot.prefix.placeholder')} data-cy="search_by_plot_prefix"/>
            </Box>
            <Box maxWidth="75px">
              <Text textStyle="small">
                {t('header.search_plot.section.label')}
              </Text>
              <Input {...registerSection} isInvalid={!!errors.section} ref={(e) => {
            sectionRef(e);
            sectionInputRef.current = e;
        }} onChange={onChangeSection} size="sm" placeholder={t('header.search_plot.section.placeholder')} data-cy="search_by_plot_section"/>
            </Box>
            <Box maxWidth="75px">
              <Text textStyle="small">
                {t('header.search_plot.number.label')}
              </Text>
              <Input {...registerNumber} type="number" isInvalid={!!errors.number} ref={(e) => {
            numberRef(e);
            numberInputRef.current = e;
        }} onChange={onChangeNumber} size="sm" placeholder={t('header.search_plot.number.placeholder')} data-cy="search_by_plot_number"/>
            </Box>
          </HStack>
          <Button ref={submitBtnRef} type="submit" variant="outline" onClick={setLayoutHalf} whiteSpace="break-spaces" height="auto" paddingY={2} paddingX={{ base: 1, lg: 4 }} fontWeight="medium">
            {t(submitText)}
          </Button>
        </HStack>
      </form>
    </Box>);
};
export default SearchByPlot;
