import pick from 'lodash/pick';
import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getTileBoundingBox } from 'utils/geo';
import { getBaseTileUrlHost3 } from 'utils/multiplexage';
import { CST_POSITIONS } from 'utils/tilesPositions';
const URL_KEYS = {
    [filtersKey.RAILWAY_NETWORKS_FRANCE_RAILWAY_NETWORK]: 'reseau_ferre_rff',
    [filtersKey.RAILWAY_NETWORKS_IDF_RAILWAY_NETWORK]: 'reseau_ferre_stif',
};
const getLayerTileUrl = (map, tileWidth, tileHeight, enabledFilters) => (coord, zoom) => {
    const boundingBox = getTileBoundingBox(coord, zoom, map, tileWidth, tileHeight);
    const urlParts = Object.values(pick(URL_KEYS, enabledFilters));
    return (getBaseTileUrlHost3() +
        'cgi-bin/mm/mapserv.cgi?map=/var/data/public_maps/project_reseau_ferre.map&SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=' +
        urlParts.join(',') +
        '&TILED=true&WIDTH=' +
        tileWidth +
        '&HEIGHT=' +
        tileHeight +
        '&CRS=EPSG:4326&STYLES=&BBOX=' +
        boundingBox);
};
const getLegends = (enabledFilters) => {
    const legends = {};
    if (enabledFilters.includes(filtersKey.RAILWAY_NETWORKS_FRANCE_RAILWAY_NETWORK)) {
        legends.railway_networks_france_railway_network_active = {
            layerKey: filtersKey.RAILWAY_NETWORKS_FRANCE_RAILWAY_NETWORK,
            label: 'railway_networks_france_railway_network_active',
            needsTranslate: true,
            color: '#FF0000',
            opacity: 0.6,
        };
        legends.railway_networks_france_railway_network_disabled = {
            layerKey: filtersKey.RAILWAY_NETWORKS_FRANCE_RAILWAY_NETWORK,
            label: 'railway_networks_france_railway_network_disabled',
            needsTranslate: true,
            color: '#000000',
            opacity: 0.6,
        };
    }
    if (enabledFilters.includes(filtersKey.RAILWAY_NETWORKS_IDF_RAILWAY_NETWORK)) {
        legends.railway_networks_idf_railway_network = {
            layerKey: filtersKey.RAILWAY_NETWORKS_IDF_RAILWAY_NETWORK,
            label: 'railway_networks_idf_railway_network',
            needsTranslate: true,
            color: '#0000FF',
            opacity: 0.6,
        };
    }
    return legends;
};
const useRailwayNetworkLayers = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS.RAILWAY_NETWORK_FILTERS,
        getLegends,
    })(RAILWAY_NETWORK_FILTERS);
};
export const RAILWAY_NETWORK_FILTERS = [
    filtersKey.RAILWAY_NETWORKS_FRANCE_RAILWAY_NETWORK,
    filtersKey.RAILWAY_NETWORKS_IDF_RAILWAY_NETWORK,
];
const RailwayNetworkLayers = () => {
    useRailwayNetworkLayers();
    return null;
};
export default RailwayNetworkLayers;
