import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOIncreaseInBuildableVolumesLayerItem = IOLayerItem;
const fetchIncreaseInBuildableVolumes = (townId) => () => get(iots.array(IOIncreaseInBuildableVolumesLayerItem), `legacy/${townId}/increase-in-buildable-volumes`);
const useIncreaseInBuildableVolumesLayer = () => {
    createLayersHook({
        fetch: fetchIncreaseInBuildableVolumes,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.INCREASE_IN_BUILDABLE_VOLUMES);
};
const IncreaseInBuildableVolumesLayer = () => {
    useIncreaseInBuildableVolumesLayer();
    return null;
};
export default IncreaseInBuildableVolumesLayer;
