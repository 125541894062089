import * as React from 'react';
import { Box, Center, Container, Heading, Image, Link, Spinner, VStack, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_OPEN } from 'analytics/useAnalytics';
import { menuItems } from 'components/sidebar/utils';
import { useMenuItemSelected, useNewsSelected, } from 'hooks/contexts/useMenuContext';
import useNews from 'hooks/news/useNews';
const NewsContainer = () => {
    const { trackEvent } = useAnalytics();
    const menuItemSelected = useMenuItemSelected();
    const newsSelected = useNewsSelected();
    const { isInitialLoading, data: news } = useNews();
    const currentNews = newsSelected && news?.find((item) => item.id === newsSelected);
    if (menuItemSelected !== menuItems.NEWS)
        return null;
    if (isInitialLoading) {
        return (<Center position="relative" flex={1} height="100%">
        <Spinner />
      </Center>);
    }
    if (!currentNews && !isInitialLoading) {
        return <Box position="relative" flex={1} height="100%"></Box>;
    }
    return (<Box position="relative" flex={1} height="100%" overflowY="scroll">
      <Container maxWidth="4xl" height="100%">
        <VStack minHeight="100%" spacing={2} padding={4} alignItems="flex-start" backgroundColor="white" boxShadow="md">
          <Heading as="h1" variant="h1" dangerouslySetInnerHTML={{ __html: currentNews.title }}/>
          <Box>
            {currentNews?.image && (<Image width="100%" marginBottom={2} src={currentNews.image}/>)}
            <Box textStyle="paragraph" fontSize="15px" color="colorMode.font900" dangerouslySetInnerHTML={{ __html: currentNews.content }}/>
            <Link href={currentNews.url} isExternal variant="primary" onClick={() => {
            trackEvent({
                category: ANALYTICS_OPEN,
                action: 'Actualités',
            });
        }}>
              {currentNews.urlText}
            </Link>
          </Box>
        </VStack>
      </Container>
    </Box>);
};
export default NewsContainer;
