import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOArchitecturalDisciplineLayerItem = IOLayerItem;
const fetchArchitecturalDiscipline = (townId) => () => get(iots.array(IOArchitecturalDisciplineLayerItem), `legacy/${townId}/architectural-discipline`);
const useArchitecturalDisciplineLayer = () => {
    createLayersHook({
        fetch: fetchArchitecturalDiscipline,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.ARCHITECTURAL_DISCIPLINE);
};
const ArchitecturalDisciplineLayer = () => {
    useArchitecturalDisciplineLayer();
    return null;
};
export default ArchitecturalDisciplineLayer;
