import * as React from 'react';
import { Button, Text } from '@chakra-ui/react';
import ResetIcon from 'images/icons/Reset';
export const ResetButton = ({ onClick, size = 'xs', label = 'Reset', ...rest }) => {
    return (<Button variant="link" size={size} onClick={onClick} color="colorMode.font900" rightIcon={<ResetIcon fill="currentColor"/>} {...rest}>
      <Text as="span" borderBottomWidth={1} borderBottomColor="colorMode.font900" textStyle="small" marginRight="1">
        {label}
      </Text>
    </Button>);
};
export default ResetButton;
