import * as React from 'react';
import { memo } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import AccountDropdown from 'components/dropdowns/AccountDropdown';
import LocationSearch from 'components/header/LocationSearch/LocationSearchInput';
import TownQuickSelect from 'components/TownQuickSelect';
import Punchline from '../Punchline';
import HeaderLogo from './HeaderLogo';
import HeaderPlotSearch from './HeaderPlotSearch';
const HeaderWrapper = () => {
    return (<Box className="no-print" overflowY="scroll" display="flex" as="nav" boxShadow="md" width="100%" backgroundColor="colorMode.background900" zIndex="60" height="72px" alignItems="center">
      <HeaderLogo />
      <Box marginX={{ base: 2, lg: 6 }} width={{ base: 'auto', lg: '400px' }}>
        <LocationSearch />
      </Box>
      <Box paddingRight={{ base: 2, lg: 6 }}>
        <HeaderPlotSearch />
      </Box>
      <Flex flex={1} justifyContent="flex-end" alignItems="center">
        <Punchline />
        <TownQuickSelect />
        <AccountDropdown />
      </Flex>
    </Box>);
};
export default memo(HeaderWrapper);
