import { memo, useEffect } from 'react';
import * as React from 'react';
import { Box } from '@chakra-ui/react';
import { useIsLoadingTown, useSelectedTown, } from 'hooks/contexts/useLocationContext';
import { useMap, useMapCenter, usePrecision, } from 'hooks/contexts/useMapContext';
import useComputedZoom from 'hooks/map/useComputedZoom';
import { centerAndZoomOnCoord } from 'utils/map';
const MapBlock = ({ refMap, }) => {
    const precision = usePrecision();
    const map = useMap();
    const mapCenter = useMapCenter();
    const selectedTown = useSelectedTown();
    const isLoadingTown = useIsLoadingTown();
    const computedZoom = useComputedZoom();
    // town selected
    useEffect(() => {
        if (!map || isLoadingTown) {
            return;
        }
        if (selectedTown) {
            if (mapCenter.current) {
                centerAndZoomOnCoord(map, mapCenter.current, precision);
            }
            return;
        }
    }, [selectedTown, map]);
    return (<Box id="mapModal" height="100%">
      <Box id="googleMapModal" width="100%" height="100%" className={`zoom-${computedZoom}`}>
        <Box width="100%" height="100%" ref={refMap}/>
      </Box>
    </Box>);
};
export default memo(MapBlock);
