const metrics = ['€', '%', 'km', 'Km²'];
export const isNumericValue = (value) => {
    return /^-?\d*(\s|,|\.)\d*$/.test(value);
};
export const isPercentageValue = (value) => {
    return /^-?\d*(,|\.)?\d* ?%$/.test(value);
};
export const parseCells = (data) => {
    const cellValue = data.cell?.text[0];
    const [lastItem] = cellValue?.split(' ')?.slice(-1) ?? {};
    const shouldAlignRight = (isNumericValue(cellValue) || metrics?.includes(lastItem)) &&
        data.column.index !== 0;
    if (shouldAlignRight) {
        data.cell.styles.halign = 'right';
    }
};
