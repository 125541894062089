import * as React from 'react';
import { useTranslation } from 'react-i18next';
import useContactList from 'hooks/crm/useContactList';
import AdvancedSelectInput from './AdvancedSelectInput';
const ContactSelect = ({ name, disabled, onChange, required, optional, label, }) => {
    const { t } = useTranslation();
    const { contacts, isInitialLoading } = useContactList();
    const formattedContacts = contacts && contacts.length > 0
        ? contacts.map((contact) => ({
            value: contact.id,
            label: `${contact.titleStr} ${contact.firstname || ''} ${contact.lastname}${contact.town ? `, ${contact.town}` : ''}${contact.company ? `, ${contact.company}` : ''}`,
        }))
        : [];
    return (<AdvancedSelectInput placeholder={t('contact.search_contact')} isClearable isLoading={isInitialLoading} options={formattedContacts} blurInputOnSelect isDisabled={disabled} name={name} id="contact_select" onChange={onChange} required={required} optional={optional} label={label}/>);
};
export default ContactSelect;
