import * as React from 'react';
import { useCallback, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as iots from 'io-ts';
import { get } from 'api/client';
import PublicMarketsInfoWindow, { IOPublicMarketsInfo, } from 'components/infoWindow/PublicMarketsInfoWindow';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOMarkerLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
export const IOPublicMarketsMarkerItem = iots.intersection([
    IOMarkerLayerItem,
    iots.type({
        legacyId: iots.string,
        count: iots.number,
        legacyType: iots.string,
    }),
]);
const getMarkerImg = (marker) => {
    return `/api/public/legacy/get-planning-and-studies-image/${marker.count}`;
};
const getLegends = (filterName) => ({
    [filterName]: {
        layerKey: filterName,
        label: filterName,
        needsTranslate: true,
        image: getMarkerImg({ count: 'X' }),
    },
});
const fetchPlanningAndStudiesInfo = (marker) => {
    return get(iots.array(IOPublicMarketsInfo), `legacy/planning-and-studies-info/${encodeURIComponent(marker.legacyId)}`);
};
const formatInfoWindowContent = (info) => {
    const content = <PublicMarketsInfoWindow info={info}/>;
    return ReactDOMServer.renderToStaticMarkup(content);
};
const fetchPlanningAndStudies = (townId) => () => get(iots.array(IOPublicMarketsMarkerItem), `legacy/${townId}/planning-and-studies`);
const usePlanningAndStudiesLayer = () => {
    const selectedTown = useSelectedTown();
    const [markerInfo, setMarkerInfo] = useState({});
    const getInfoWindowContent = useCallback(async (marker) => {
        const markerKey = `${marker.lat}/${marker.lng}`;
        if (markerInfo[markerKey]) {
            return markerInfo[markerKey];
        }
        const planningAndStudiesInfo = await fetchPlanningAndStudiesInfo(marker);
        const content = formatInfoWindowContent(planningAndStudiesInfo);
        setMarkerInfo({
            ...markerInfo,
            [markerKey]: content,
        });
        return content;
    }, [selectedTown]);
    createLayersHook({
        fetch: fetchPlanningAndStudies,
        markerImg: getMarkerImg,
        legends: getLegends(filtersKey.PLANNING_AND_STUDIES),
        getInfoWindowContent,
    })(filtersKey.PLANNING_AND_STUDIES);
};
const PlanningAndStudiesLayer = () => {
    usePlanningAndStudiesLayer();
    return null;
};
export default PlanningAndStudiesLayer;
