import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Heading, HStack, Image, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, VStack, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_ONGLET } from 'analytics/useAnalytics';
import InfoPopover from 'components/InfoPopover';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import useNeighborhood from 'hooks/plot/useNeighborhood';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { formatNumberToLocale } from 'utils/formatNumber';
import { getPlotSkeleton } from '../../PlotPannelSkeletons';
import RentInfo from './RentInfo';
export const NEIGHBORHOOD_CATEGORIES = {
    1: 'realEstate',
    2: 'resident',
    3: 'environment',
    4: 'education',
    5: 'trade',
    6: 'policy',
    7: 'security',
    8: 'heating',
    9: 'risk_diagnosis',
    10: 'housing',
};
const TABLE_HEADERS = [
    'compared_to_neighborhood',
    'compared_to_town',
    'compared_to_department',
];
const TABLE_HEADERS_NO_NEIGHBORHOOD = ['town', 'compared_to_department'];
const BACKGROUND_COLORS = {
    0: 'colorMode.background700',
    1: 'colorMode.background800',
    2: 'colorMode.background900',
};
const PlotNeighborhoodWrapper = ({ plot, }) => {
    const { trackEvent } = useAnalytics();
    const { t, i18n } = useTranslation();
    const [categorySelected, setCategorySelected] = useState('1');
    const selectedTown = useSelectedTown();
    const { hasPlotTab } = useSiteConfiguration();
    const { data: neighborhood, isInitialLoading: isLoadingNeighborhood } = useNeighborhood(plot?.lat, plot?.lng, hasPlotTab('neighborhood'));
    const skeleton = getPlotSkeleton();
    if (isLoadingNeighborhood)
        return skeleton;
    if (!neighborhood) {
        return (<Heading paddingY={3} as="h3" variant="h3" fontWeight={700} textAlign="center">
        {t('tabs.plots.section.neighborhood.not_exist')}
      </Heading>);
    }
    const getTableHeader = () => {
        if (neighborhood.displayNeighborhood) {
            return TABLE_HEADERS;
        }
        else {
            return TABLE_HEADERS_NO_NEIGHBORHOOD;
        }
    };
    return (<VStack padding={4}>
      <HStack width="full" alignItems="start">
        {selectedTown && (<Box flex={1} paddingBottom={4}>
            <Heading paddingY={3} paddingTop={0} as="h3" variant="h3" fontWeight={700}>
              {t('tabs.plots.section.neighborhood.town_economic_information.title')}
            </Heading>
            {selectedTown?.pinelAccession > 0 && (<Text textStyle="paragraph">
                {t('tabs.plots.section.neighborhood.town_economic_information.pinel_accession', {
                    pinelAccession: formatNumberToLocale(selectedTown?.pinelAccession, i18n.language),
                    pinel: selectedTown?.pinel,
                })}
              </Text>)}
            <RentInfo />
            <Heading paddingBottom={3} as="h3" variant="h3" fontWeight={700}>
              {neighborhood?.name}
            </Heading>
            <Text textStyle="paragraph" dangerouslySetInnerHTML={{ __html: neighborhood?.description }}/>
          </Box>)}
        <Box>
          {neighborhood?.image && (<Image borderRadius="md" src={neighborhood.image.url} alt={neighborhood.image.title} padding={2} maxHeight="300px" width="100%" objectFit="contain"/>)}
        </Box>
      </HStack>

      <HStack justifyContent="flex-start" alignItems="flex-start" paddingBottom={5} width="100%">
        <VStack backgroundColor="colorMode.base" alignItems="flex-start" boxShadow="md" borderRadius={6} minWidth="130px" marginRight={2} spacing={0}>
          {Object.keys(NEIGHBORHOOD_CATEGORIES).map((key, index) => {
            return (<Box key={index} onClick={() => {
                    setCategorySelected(key);
                    trackEvent({
                        category: ANALYTICS_ONGLET,
                        action: 'Quartier ' +
                            t('tabs.plots.section.neighborhood.sections.' +
                                NEIGHBORHOOD_CATEGORIES[key]),
                    });
                }} paddingY={2} paddingX={4} backgroundColor={categorySelected === key
                    ? 'colorMode.revertBase'
                    : 'colorMode.base'} width="100%" borderRadius={6} _hover={{
                    backgroundColor: 'colorMode.revertBase',
                    color: 'colorMode.base',
                }} color={categorySelected === key
                    ? 'colorMode.base'
                    : 'colorMode.font700'} cursor="pointer" data-cy={`plot_neighborhood_${NEIGHBORHOOD_CATEGORIES[key]}_button`}>
                <Text textStyle="small" fontWeight={700} color="currentcolor">
                  {t('tabs.plots.section.neighborhood.sections.' +
                    NEIGHBORHOOD_CATEGORIES[key])}
                </Text>
              </Box>);
        })}
        </VStack>

        <TableContainer boxShadow="md" borderRadius={6} overflowX="scroll">
          <Table variant="with-borders">
            <Thead>
              <Tr>
                <Th></Th>
                {getTableHeader().map((header, index) => {
            return (<Th key={index} backgroundColor={BACKGROUND_COLORS[index]} borderColor="colorMode.background500" borderBottomWidth="1px" borderTopRightRadius={6} shadow="md">
                      <Heading as="h5" variant="h5" textAlign="center" fontWeight={400} color="colorMode.font800">
                        {t('tabs.plots.section.neighborhood.table.' + header)}
                      </Heading>
                      <Heading as="h5" variant="h5" textAlign="center" color="colorMode.font800">
                        {header === 'compared_to_neighborhood' &&
                    neighborhood.name}
                        {(header === 'compared_to_town' || header === 'town') &&
                    neighborhood.townshipName}
                        {header === 'compared_to_department' &&
                    neighborhood.departmentId}
                      </Heading>
                    </Th>);
        })}
              </Tr>
            </Thead>
            <Tbody>
              {neighborhood.statistics[categorySelected].map((neighborhoodStats, index) => {
            return (<Tr key={index} data-cy={`plot_neighborhood_${NEIGHBORHOOD_CATEGORIES[categorySelected]}_content`}>
                      <Td>
                        <HStack>
                          <Text textStyle="paragraph" fontWeight={500} color="blue.500">
                            {neighborhoodStats.title}
                          </Text>
                          <Box>
                            <InfoPopover>
                              <Stack>
                                <Text dangerouslySetInnerHTML={{
                    __html: neighborhoodStats.goodToKnow,
                }}/>
                                <Text dangerouslySetInnerHTML={{
                    __html: neighborhoodStats.definition,
                }}/>
                                <Text>{neighborhoodStats.source}</Text>
                              </Stack>
                            </InfoPopover>
                          </Box>
                        </HStack>
                      </Td>
                      {neighborhood.displayNeighborhood && (<Td backgroundColor={BACKGROUND_COLORS[0]} textAlign="center">
                          {neighborhoodStats.neighborhood === 'ND' ||
                        neighborhoodStats.neighborhood === 'NA'
                        ? t(`tabs.plots.section.neighborhood.ND`)
                        : neighborhoodStats.neighborhood}
                        </Td>)}
                      <Td backgroundColor={BACKGROUND_COLORS[1]} textAlign="center">
                        {neighborhoodStats.town === 'ND' ||
                    neighborhoodStats.neighborhood === 'NA'
                    ? t(`tabs.plots.section.neighborhood.ND`)
                    : neighborhoodStats.town}
                      </Td>
                      <Td backgroundColor={BACKGROUND_COLORS[2]} textAlign="center">
                        {neighborhoodStats.department === 'ND' ||
                    neighborhoodStats.neighborhood === 'NA'
                    ? t(`tabs.plots.section.neighborhood.ND`)
                    : neighborhoodStats.department}
                      </Td>
                    </Tr>);
        })}
            </Tbody>
          </Table>
        </TableContainer>
      </HStack>
    </VStack>);
};
export default PlotNeighborhoodWrapper;
