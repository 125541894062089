import { useEffect, useMemo } from 'react';
import { HIGH_DEFINITION, HISTORY_MAP, SAT_IGN_TOPOGRAPHY, } from 'context/MapContext';
import { useMap, useMapDisplay } from 'hooks/contexts/useMapContext';
import { hideLayer, toggleLayer } from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getLayerCoordTileUrl } from 'utils/map';
const CST_POS = 0;
const DISPLAY_MAP_LAYERS = [HISTORY_MAP, HIGH_DEFINITION, SAT_IGN_TOPOGRAPHY];
const getHighDefinitionLayerTileUrl = () => (coord, zoom) => {
    return getLayerCoordTileUrl('THR.ORTHOIMAGERY.ORTHOPHOTOS', 'normal', zoom, coord, 'image/jpeg');
};
const getHistoryMapLayerTileUrl = (layerName) => {
    let layerFormat = 'image/jpeg';
    if (layerName === '1950-1965') {
        layerName = '.' + layerName;
        layerFormat = 'image/png';
    }
    return () => (coord, zoom) => {
        return getLayerCoordTileUrl('ORTHOIMAGERY.ORTHOPHOTOS' + layerName, 'normal', zoom, coord, layerFormat);
    };
};
const getSateliteIgnRoad = () => (coord, zoom) => {
    return getLayerCoordTileUrl('GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2', 'normal', zoom, coord, 'image/png');
};
const useMapDisplayLayer = () => {
    const map = useMap();
    const { mapDisplay, historyMapYear } = useMapDisplay();
    const getLayerTileUrl = useMemo(() => {
        if (mapDisplay === HISTORY_MAP) {
            return getHistoryMapLayerTileUrl(historyMapYear);
        }
        if (mapDisplay === HIGH_DEFINITION) {
            return getHighDefinitionLayerTileUrl;
        }
        if (mapDisplay === SAT_IGN_TOPOGRAPHY) {
            return getSateliteIgnRoad;
        }
        return null;
    }, [historyMapYear, mapDisplay]);
    useEffect(() => {
        if (!map) {
            return;
        }
        toggleLayer(DISPLAY_MAP_LAYERS.includes(mapDisplay) ? [filtersKey.DISPLAY_MAP] : [], map, getLayerTileUrl, CST_POS);
    }, [map, getLayerTileUrl]);
    const cleanup = () => {
        hideLayer(map, CST_POS);
    };
    useEffect(() => {
        return cleanup;
    }, []);
};
const MapDisplayLayer = () => {
    useMapDisplayLayer();
    return null;
};
export default MapDisplayLayer;
