import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { Translation, useTranslation } from 'react-i18next';
import { Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, Text, useDisclosure, VStack, } from '@chakra-ui/react';
import SeeMoreAccordion from 'components/SeeMoreAccordion';
import Table from 'components/table/Table';
import { APECodesTableData, } from 'utils/APECodesTableData';
const columns = [
    {
        id: 'columns',
        columns: ['code', 'name', 'section', 'category'].map((column) => {
            return {
                accessorKey: column,
                header: () => (<Translation>
            {(t) => <p>{t(`modals.ape_codes.${column}`)}</p>}
          </Translation>),
                cell: (info) => info.getValue() || '',
                filterFn: 'arrIncludesSome',
                enableColumnFilter: true,
            };
        }),
    },
];
const categories = APECodesTableData.map((plot) => plot.category);
const sortedCategories = categories.filter((item, pos) => {
    return categories.indexOf(item) === pos;
});
export const APECodesModal = () => {
    const { t } = useTranslation();
    const { setValue } = useFormContext();
    const { isOpen, onClose, getButtonProps } = useDisclosure();
    const buttonProps = getButtonProps();
    return (<>
      <Button variant="link" color="colorMode.font800" {...buttonProps}>
        <Text textStyle="paragraph" fontWeight={500} textDecoration="underline">
          {t('sidebar.search_plots.select_APE_codes')}
        </Text>
      </Button>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="xl">
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody marginTop={20} height="100%">
            <VStack spacing={4} alignItems="flex-start">
              <Text textAlign={'left'}>{t(`modals.ape_codes.helper`)}</Text>

              <Box width="100%">
                <Table categories={sortedCategories} variant="simple" id="codes-APE-table" columns={columns} data={APECodesTableData} onRowSelection={(selectedRows) => {
            const newValue = selectedRows
                .map((row) => row.getValue('code'))
                .join(';');
            setValue('ownerMainActivity', newValue);
        }} withExport={false}/>
              </Box>

              <Box>
                <SeeMoreAccordion title="sidebar.search_plots.more_info">
                  <Box textStyle="small">
                    {t('sidebar.search_plots.more_info_content')}
                  </Box>
                </SeeMoreAccordion>
              </Box>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>);
};
export default APECodesModal;
