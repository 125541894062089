import { useNavigate } from 'react-router-dom';
import { CRM_LAYER_TABS } from 'context/TabsContext';
import { useResetFilters } from 'hooks/contexts/useFiltersContext';
import { useResetLayers } from 'hooks/contexts/useMapContext';
import { useResetLegends } from './contexts/useLegendContext';
import { useClearTown, useSetFormattedAddress, useSetTownCoordinates, } from './contexts/useLocationContext';
export default () => {
    const resetFilters = useResetFilters();
    const setTownCoordinates = useSetTownCoordinates();
    const setFormattedAddress = useSetFormattedAddress();
    const clearTown = useClearTown();
    const resetLayers = useResetLayers();
    const resetLegends = useResetLegends();
    const navigate = useNavigate();
    return () => {
        setTownCoordinates(null);
        setFormattedAddress(null);
        resetFilters(CRM_LAYER_TABS);
        resetLayers();
        resetLegends();
        clearTown();
        navigate('/');
    };
};
