const hasSupport = () => typeof Storage !== 'undefined';
export const localStorageSetGet = (key) => ({
    get(defaultValue) {
        try {
            if (!hasSupport())
                return;
            return localStorage.getItem(key) ?? defaultValue;
        }
        catch (error) {
            //eslint-disable-next-line
            console.log(error);
        }
    },
    set(value) {
        if (!hasSupport())
            return;
        try {
            localStorage.setItem(key, value);
        }
        catch (error) {
            //eslint-disable-next-line
            console.log(error);
        }
    },
    type: 'localStorage',
});
