import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Box } from '@chakra-ui/react';
import { MarkerWithLabel, } from '@googlemaps/markerwithlabel';
import { STREET_VIEW_MODE_FIFTY } from 'context/LayoutContext';
import imgEmpty from 'images/1x1.gif';
import loadingImg from 'images/loading.gif';
import { IOMarkerLayerItem } from 'types/api';
import { VECTOR_TYPES, } from 'types/types';
import darkModeMapStyle from 'utils/darkModeMapStyle';
import { getTileBoundingBox } from './geo';
import { getRandomIntInclusive } from './numbers';
import { isGermanySite, isSwissSite } from './site';
export const DEFAULT_POLYLINE_BORDER_WIDTH = 3;
const DEFAULT_OPACITY = 0.5;
const DEFAULT_BORDER_OPACITY = 1;
export const DEFAULT_BORDER_WIDTH = 1;
const DEFAULT_RADIUS = 10;
const BASE_TILT = 45;
const BASE_ZOOM_FRANCE = 6;
const BASE_ZOOM_SWISS = 9;
const BASE_ZOOM_GERMANY = 6;
export const PLOT_MIN_ZOOM = 17;
export const PROJECT_MIN_ZOOM = 16;
export const BASE_PRECISION = 0;
const BASE_CENTER_FRANCE = {
    lat: parseFloat('46.461309'),
    lng: parseFloat('2.918557'),
};
const BASE_CENTER_SWISS = {
    lat: parseFloat('46.758'),
    lng: parseFloat('8.144'),
};
const BASE_CENTER_GERMANY = {
    lat: parseFloat('50.712'),
    lng: parseFloat('10.328'),
};
export const getBaseCenter = () => {
    if (isSwissSite()) {
        return BASE_CENTER_SWISS;
    }
    else if (isGermanySite()) {
        return BASE_CENTER_GERMANY;
    }
    return BASE_CENTER_FRANCE;
};
export const getBaseZoom = () => {
    if (isSwissSite()) {
        return BASE_ZOOM_SWISS;
    }
    else if (isGermanySite()) {
        return BASE_ZOOM_GERMANY;
    }
    return BASE_ZOOM_FRANCE;
};
export const defaultGetInfoWindowContent = (layerInfo) => {
    let content = layerInfo.infoTitle;
    if (layerInfo.infoLine1) {
        content += `${content ? '<br />' : ''}${layerInfo.infoLine1}`;
    }
    if (layerInfo.infoLine2) {
        content += `${content ? '<br />' : ''}${layerInfo.infoLine2}`;
    }
    return content;
};
let Markers = [];
let MarkersPanorama = [];
export const getLayerInfoFromLayerItem = (item, map, addInfoWindow, defaultColor, getInfoWindowContent, markerImage, getMarkerLabel, defaultLayerKey, onClick) => {
    const layerColor = 'color' in item ? item.color ?? defaultColor : defaultColor;
    const layerOpacity = 'opacity' in item ? item.opacity ?? DEFAULT_OPACITY : DEFAULT_OPACITY;
    const borderColor = 'borderColor' in item ? item.borderColor ?? layerColor : layerColor;
    const borderOpacity = 'borderOpacity' in item
        ? item.borderOpacity ?? DEFAULT_BORDER_OPACITY
        : DEFAULT_BORDER_OPACITY;
    const clickable = !!getInfoWindowContent || !!onClick;
    const addListenersAndInfoWindow = (vector) => {
        if (getInfoWindowContent) {
            addInfoWindow(vector, getInfoWindowContent(item));
        }
        if (onClick) {
            vector.addListener('click', (event) => {
                onClick(event);
            });
        }
    };
    const swkt = 'swkt' in item && item.swkt ? item.swkt : null;
    const type = 'type' in item ? item.type : getType(swkt ?? '');
    const points = swkt ? wktToPolygons(swkt) : [];
    const layerInfo = {
        vectors: [],
        id: item.id,
        layerKey: item.layerKey || defaultLayerKey,
        infoTitle: item.infoTitle,
        infoLine1: item.infoLine1,
        infoLine2: item.infoLine2,
    };
    if (type == VECTOR_TYPES.polyline || type == VECTOR_TYPES.polyligne) {
        points.forEach((point) => {
            const borderWidth = 'borderWidth' in item
                ? item.borderWidth ?? DEFAULT_POLYLINE_BORDER_WIDTH
                : DEFAULT_POLYLINE_BORDER_WIDTH;
            const vector = new google.maps.Polyline({
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                path: point[0],
                strokeColor: borderColor,
                strokeWeight: borderWidth,
                strokeOpacity: borderOpacity,
                visible: false,
                clickable,
                zIndex: 1,
            });
            addListenersAndInfoWindow(vector);
            vector.setMap(map);
            layerInfo.vectors.push(vector);
        });
    }
    else if (type === VECTOR_TYPES.point) {
        const borderWidth = 'borderWidth' in item
            ? item.borderWidth ?? DEFAULT_BORDER_WIDTH
            : DEFAULT_BORDER_WIDTH;
        const radius = 'radius' in item ? item.radius ?? DEFAULT_RADIUS : DEFAULT_RADIUS;
        const position = new google.maps.LatLng(points[0][0][0].lat, points[0][0][0].lng);
        const circleOptions = {
            strokeColor: borderColor,
            strokeOpacity: borderOpacity,
            strokeWeight: borderWidth,
            fillColor: layerColor,
            fillOpacity: layerOpacity,
            map: map,
            center: position,
            radius: radius,
            visible: false,
            clickable,
            zIndex: 1,
        };
        // Add the circle for this city to the map.
        const vector = new google.maps.Circle(circleOptions);
        addListenersAndInfoWindow(vector);
        vector.setMap(map);
        layerInfo.vectors.push(vector);
    }
    else if (type == VECTOR_TYPES.marker) {
        const { lat = undefined, lng = undefined } = points.length
            ? points[0][0][0]
            : IOMarkerLayerItem.is(item)
                ? item
                : {};
        const position = new google.maps.LatLng(typeof lat === 'string' ? parseFloat(lat) : lat, typeof lng === 'string' ? parseFloat(lng) : lng);
        const label = getMarkerLabel ? getMarkerLabel(item) : null;
        const vector = label
            ? new google.maps.Marker({
                icon: typeof markerImage === 'string'
                    ? {
                        url: markerImage,
                        labelOrigin: new google.maps.Point(label.anchorX, label.anchorY),
                    }
                    : markerImage,
                position: position,
                map: map,
                label: {
                    text: label.content,
                    className: label.className || 'marker-label',
                },
                visible: false,
                clickable,
            })
            : new google.maps.Marker({
                icon: markerImage,
                position: position,
                map: map,
                visible: false,
                clickable,
            });
        addListenersAndInfoWindow(vector);
        layerInfo.vectors.push(vector);
    }
    else if (type == VECTOR_TYPES.polygon) {
        const borderWidth = 'borderWidth' in item
            ? item.borderWidth ?? DEFAULT_BORDER_WIDTH
            : DEFAULT_BORDER_WIDTH;
        points.forEach((point) => {
            const vector = new google.maps.Polygon({
                paths: point,
                strokeColor: borderColor,
                strokeWeight: borderWidth,
                strokeOpacity: borderOpacity,
                fillColor: layerColor,
                fillOpacity: layerOpacity,
                visible: false,
                zIndex: 1,
                clickable,
            });
            addListenersAndInfoWindow(vector);
            vector.setMap(map);
            layerInfo.vectors.push(vector);
        });
    }
    let markerWithLabelPosition = null;
    if ('label' in item && item.label) {
        if ('lat' in item && 'lng' in item) {
            markerWithLabelPosition = {
                lat: item.lat,
                lng: item.lng,
            };
        }
        if (points.length && item.type === VECTOR_TYPES.marker) {
            const { lat, lng } = points[0][0][0];
            markerWithLabelPosition = {
                lat,
                lng,
            };
        }
    }
    // Voir PLU en vigueur (affiche un label (marker) sur le polygon)
    // Utilisé aussi par les dessins utilisateurs de type texte
    if (markerWithLabelPosition) {
        const markerLabel = new MarkerWithLabel({
            position: new google.maps.LatLng(parseFloat(markerWithLabelPosition.lat), parseFloat(markerWithLabelPosition.lng)),
            map: map,
            labelContent: item.label,
            labelClass: item.labelClass ?? 'marker-label',
            clickable: item.labelClickable ?? false,
            icon: imgEmpty,
            visible: false,
        });
        if (item.labelClickable) {
            addListenersAndInfoWindow(markerLabel);
        }
        layerInfo.vectors.push(markerLabel);
    }
    return layerInfo;
};
export const centerAndZoomOnCoord = (map, coord, precision) => {
    map.setCenter(coord);
    let zoom = 16;
    if (precision === 0 || precision === 1)
        zoom = 16;
    if (precision === 2)
        zoom = 18;
    if (precision === 3)
        zoom = 20;
    map.setZoom(zoom);
};
export const setMapCenterFromLatAndLng = (map, lat, lng) => {
    const center = new google.maps.LatLng(parseFloat(lat), parseFloat(lng));
    map.setCenter(center);
};
export const getNormalizedCoordinates = (coord, zoom) => {
    const y = coord.y;
    let x = coord.x;
    // tile range in one direction
    // 0 = 1 tile, 1 = 2 tiles, 2 = 4 tiles, 3 = 8 tiles, etc.
    const tileRange = 1 << zoom;
    // don't repeat across y-axis (vertically)
    if (y < 0 || y >= tileRange) {
        return null;
    }
    // repeat across x-axis
    if (x < 0 || x >= tileRange) {
        x = ((x % tileRange) + tileRange) % tileRange;
    }
    return { x: x, y: y };
};
const getPointsFromPolygones = (coords, isReversed = false) => {
    return coords.split('),(').map((coord, index) => {
        const points = coord.split(',');
        const formattedCoords = points.map((point) => {
            const latlng = point.split(' ');
            return { lat: parseFloat(latlng[1]), lng: parseFloat(latlng[0]) };
        });
        // Le premier polygon est toujours le contenant. Le reverse crée un trou dans le contenant
        if (index >= 1 && isReversed)
            formattedCoords.reverse();
        return formattedCoords;
    });
};
const getCleanSwkt = (swkt) => {
    if (swkt.includes('MULTIPOLYGON')) {
        return swkt
            .replace(`MULTIPOLYGON (((`, '')
            .replace(')))', '')
            .replace(`MULTIPOLYGON(((`, '')
            .split(')),((');
    }
    if (swkt.includes('POLYGON')) {
        return swkt
            .replace('POLYGON ((', '')
            .replace('))', '')
            .replace('POLYGON((', '');
    }
    if (swkt.includes('POINT')) {
        return swkt.replace('POINT (', '').replace(')', '').replace('POINT(', '');
    }
    if (swkt.includes('MULTILINESTRING')) {
        return swkt
            .replace('MULTILINESTRING ((', '')
            .replace('))', '')
            .replace('MULTILINESTRING((', '')
            .split('),(');
    }
    if (swkt.includes('LINESTRING')) {
        return swkt
            .replace('LINESTRING (', '')
            .replace(')', '')
            .replace('LINESTRING(', '');
    }
    return null;
};
export const getType = (swkt) => {
    if (swkt.includes('MULTIPOLYGON') || swkt.includes('POLYGON')) {
        return VECTOR_TYPES.polygon;
    }
    if (swkt.includes('POINT')) {
        return VECTOR_TYPES.point;
    }
    if (swkt.includes('MULTILINESTRING') || swkt.includes('LINESTRING')) {
        return VECTOR_TYPES.polyline;
    }
    return undefined;
};
export const wktToPolygons = (swkt, isReversed = false) => {
    const cleanSwkt = getCleanSwkt(swkt);
    // simple polygone
    if (typeof cleanSwkt === 'string') {
        return [getPointsFromPolygones(cleanSwkt, isReversed)];
    }
    // multiple polygone
    return cleanSwkt.map((coords) => {
        return getPointsFromPolygones(coords, isReversed);
    });
};
export const drawingToSwkt = (drawing) => {
    if (drawing instanceof google.maps.Marker ||
        drawing instanceof MarkerWithLabel) {
        return `POINT(${drawing.getPosition().lng()} ${drawing.getPosition().lat()})`;
    }
    const points = drawing
        .getPath()
        .getArray()
        .map((point) => {
        return `${point.lng()} ${point.lat()}`;
    });
    if (drawing instanceof google.maps.Polygon &&
        points[0] !== points[points.length - 1]) {
        points.push(points[0]);
    }
    return drawing instanceof google.maps.Polygon
        ? `POLYGON((${points.join(',')}))`
        : `LINESTRING(${points.join(',')})`;
};
export const swktToDrawing = (swkt, options, markerOptions) => {
    const type = getType(swkt);
    const points = wktToPolygons(swkt);
    if (type === VECTOR_TYPES.polygon) {
        return new google.maps.Polygon({
            paths: points[0],
            ...options,
        });
    }
    if (type === VECTOR_TYPES.polyline) {
        return new google.maps.Polyline({
            path: points[0][0],
            ...options,
        });
    }
    if (type === VECTOR_TYPES.point) {
        const position = new google.maps.LatLng(points[0][0][0].lat, points[0][0][0].lng);
        if ('labelContent' in markerOptions) {
            return new MarkerWithLabel({
                position,
                ...markerOptions,
            });
        }
        return new google.maps.Marker({
            position,
            ...markerOptions,
        });
    }
};
export const getBoundsFromSwkt = (swkt) => {
    const polygons = wktToPolygons(swkt);
    const bounds = new google.maps.LatLngBounds();
    polygons.forEach((polygon) => {
        polygon[0].forEach((point) => {
            bounds.extend(new google.maps.LatLng(point.lat, point.lng));
        });
    });
    return bounds;
};
export const getSwktCenter = (swkt) => {
    const bounds = getBoundsFromSwkt(swkt);
    return bounds.getCenter();
};
export const createMap = (ref, darkMode, rotateControl, mapTypeId) => {
    return new google.maps.Map(ref.current, {
        zoom: getBaseZoom(),
        scaleControl: true,
        center: getBaseCenter(),
        mapTypeId: mapTypeId || google.maps.MapTypeId.ROADMAP,
        tilt: BASE_TILT,
        disableDefaultUI: true,
        rotateControl,
        gestureHandling: 'greedy',
        clickableIcons: false,
        streetViewControl: true,
        styles: darkMode ? darkModeMapStyle : undefined,
        zoomControl: false,
        mapTypeControl: false,
        fullscreenControl: true,
        maxZoom: 21,
    });
};
export const changeMapDiv = (map, ref) => {
    const mapDiv = map.getDiv();
    ref?.current?.appendChild(mapDiv);
};
export const addSatelliteIgnMapType = (map) => {
    const satelliteIgnMapType = new google.maps.ImageMapType({
        getTileUrl: (coord, zoom) => {
            return getLayerCoordTileUrl('ORTHOIMAGERY.ORTHOPHOTOS', 'normal', zoom, coord, 'image/jpeg');
        },
        tileSize: new google.maps.Size(256, 256),
        name: 'Satellite IGN',
        maxZoom: 19,
    });
    map.mapTypes.set('satign', satelliteIgnMapType);
};
export const getLoaderMarkup = () => {
    // Google maps info windows won't render CSS animations so we need to use an image
    const loader = (<Box padding="50px">
      <img alt="Loader" src={loadingImg} width="40px"/>
    </Box>);
    return ReactDOMServer.renderToStaticMarkup(loader);
};
const createAndAddInfoWindow = async (map, marker, content) => {
    const newInfoWindow = new google.maps.InfoWindow({
        content: typeof content === 'string' ? content : getLoaderMarkup(),
    });
    newInfoWindow.open({
        map,
        anchor: marker,
    });
    if (typeof content === 'function') {
        let infoWindowContent = null;
        try {
            infoWindowContent = await content(marker.getPosition(), map);
        }
        catch (e) {
            // eslint-disable-next-line no-console
            console.warn('Error while getting info window content', e);
        }
        if (!infoWindowContent || infoWindowContent.trim() === '') {
            newInfoWindow.close();
            return null;
        }
        newInfoWindow.setContent(infoWindowContent);
    }
    return newInfoWindow;
};
export const addInfoWindowListener = async (vector, content, map, infoWindow, infoWindowListenersRef, listenerKey) => {
    const infoWindowListener = vector.addListener('click', async (event) => {
        let marker = vector;
        if (!(vector instanceof google.maps.Marker)) {
            marker = new google.maps.Marker({
                icon: '',
                position: new google.maps.LatLng(event.latLng.lat(), event.latLng.lng()),
                map: map,
                visible: false,
            });
        }
        const newInfoWindow = await createAndAddInfoWindow(map, marker, content);
        if (newInfoWindow) {
            if (infoWindow.current) {
                infoWindow.current.close();
            }
            infoWindow.current = newInfoWindow;
        }
    });
    // If listenerKey is provided, we store the listener in a ref so we can remove it later
    if (listenerKey) {
        if (infoWindowListenersRef.current[listenerKey]) {
            infoWindowListenersRef.current[listenerKey].remove();
        }
        infoWindowListenersRef.current[listenerKey] = infoWindowListener;
    }
};
const DEFAULT_PANORAMA_OPTIONS = {
    pov: {
        heading: 34,
        pitch: 10,
    },
    visible: false,
    zoomControl: false,
    fullscreenControl: false,
    panControl: false,
};
export const createPanorama = (refPanorama, map, setStreetViewMode, options) => {
    const panorama = new google.maps.StreetViewPanorama(refPanorama.current, {
        ...DEFAULT_PANORAMA_OPTIONS,
        ...options,
    });
    panorama.addListener('visible_changed', () => {
        if (panorama.getVisible()) {
            setStreetViewMode(STREET_VIEW_MODE_FIFTY);
        }
        else {
            setStreetViewMode(null);
        }
    });
    // From visible_changed event doc :
    // "Note that this event may fire asynchronously after a change in the pano ID
    // indicated through pano_changed."
    // So when creating a new panorama (after login out for example),
    // we need this hack to force its visibility
    panorama.addListener('pano_changed', () => {
        if (options.visible) {
            panorama.setVisible(true);
        }
    });
    /**
     * This code re centered the map everytime
     * you move the streetview little man
     */
    // panorama.addListener('position_changed', () => {
    //   map.setCenter(panorama.getPosition())
    // })
    map.setStreetView(panorama);
    return panorama;
};
export const addMarkerToMapAndPanorama = (panorama, map, latLng, markerImage, title = '', visible = true, draggable = true) => {
    const marker = new google.maps.Marker({
        map: map,
        position: latLng,
        icon: markerImage,
        title: title,
        visible: visible,
        draggable: draggable,
    });
    Markers.push(marker);
    marker.setMap(map);
    const markerPanorama = new google.maps.Marker({
        map: panorama,
        position: latLng,
        icon: markerImage,
        title: title,
        visible: visible,
        draggable: draggable,
    });
    markerPanorama.setMap(panorama);
    MarkersPanorama.push(markerPanorama);
    google.maps.event.addListener(marker, 'rightclick', () => {
        deleteMarkerFromMap(marker);
        deleteMarkerFromMap(markerPanorama);
    });
    google.maps.event.addListener(markerPanorama, 'rightclick', () => {
        deleteMarkerFromMap(marker);
        deleteMarkerFromMap(markerPanorama);
    });
};
export const deleteMarkerFromMap = (marker) => {
    marker.setMap(null);
};
export const cleanAllMarkerFromMapAndPanorama = () => {
    const length = Markers.length;
    for (let i = 0; i < length; i++) {
        Markers[i].setMap(null);
        MarkersPanorama[i].setMap(null);
    }
    const lengthPano = MarkersPanorama.length;
    for (let i = 0; i < lengthPano; i++) {
        MarkersPanorama[i].setMap(null);
    }
    Markers = [];
    MarkersPanorama = [];
};
export const getLayerCoordTileUrl = (layer, style, zoom, coord, format = 'image/png') => {
    return ('https://data.geopf.fr/wmts?' +
        'layer=' +
        layer +
        '&style=' +
        style +
        '&tilematrixset=PM' +
        '&Service=WMTS&Request=GetTile&Version=1.0.0' +
        '&Format=' +
        format +
        '&TILEMATRIX=' +
        zoom +
        '&TILEROW=' +
        coord.y +
        '&TILECOL=' +
        coord.x);
};
export const getLayerBoundingTileUrl = (map, layer, zoom, coord, tileWidth, tileHeight) => {
    const boundingBox = getTileBoundingBox(coord, zoom, map, tileWidth, tileHeight);
    return ('https://data.geopf.fr/wms-r/wms?' +
        'layers=' +
        layer +
        '&style=' +
        '&TRANSPARENT=true' +
        '&Service=WMS&Request=GetMap&Version=1.3.0' +
        '&Format=image/png' +
        '&TILED=true&WIDTH=' +
        tileWidth +
        '&HEIGHT=' +
        tileHeight +
        '&CRS=EPSG:4326&STYLES=&BBOX=' +
        boundingBox);
};
export const getLayerBoundingTileUrlTileUrl = (map, category, layers, zoom, coord, tileWidth, tileHeight) => {
    const boundingBox = getTileBoundingBox(coord, zoom, map, tileWidth, tileHeight);
    return ('https://datacarto' +
        getRandomIntInclusive(1, 10) +
        '.kelfoncier.com/wms_sdom/' +
        category +
        '?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image/png' +
        '&TRANSPARENT=true&LAYERS=' +
        layers +
        '&EXCEPTIONS=inimage' +
        '&CRS=EPSG:4326&STYLES=&WIDTH=' +
        tileWidth +
        '&HEIGHT=' +
        tileHeight +
        '&BBOX=' +
        boundingBox);
};
