import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getLayerCoordTileUrl } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = () => (coord, zoom) => {
    return getLayerCoordTileUrl('GEOGRAPHICALGRIDSYSTEMS.SLOPES.PAC', 'GEOGRAPHICALGRIDSYSTEMS.SLOPES.PAC', zoom, coord);
};
const getLegends = () => {
    const legends = {};
    legends.incline_10 = {
        layerKey: filtersKey.INCLINE_10,
        label: 'incline_10',
        needsTranslate: true,
        color: '#D90115',
        opacity: 0.8,
    };
    return legends;
};
const useIncline10Layer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.INCLINE_10],
        getLegends,
        opacity: 0.42,
    })(filtersKey.INCLINE_10);
};
const Incline10Layer = () => {
    useIncline10Layer();
    return null;
};
export default Incline10Layer;
