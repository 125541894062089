import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button } from '@chakra-ui/react';
import { t } from 'i18next';
import TemplateFilePublipostage from 'components/modals/publipostageModal/importPublipostageSteps/TemplateFilePublipostage';
import UploadFilePublipostage from 'components/modals/publipostageModal/importPublipostageSteps/UploadFilePublipostage';
import ValidatePublipostage from 'components/modals/publipostageModal/importPublipostageSteps/ValidatePublipostage';
import useImportPublipostage from 'hooks/import/useImportPublipostage';
import PlotsContactsList from './importPublipostageSteps/PlotsContactsList';
const PlotsPublipostageForm = ({ plotContactsIds, contactIds, closeDrawer, setContactIds, }) => {
    const [step, setStep] = useState(1);
    const [fileContent, setFileContent] = useState(null);
    const handleFileData = (data) => setFileContent(data);
    const { templatesPublipostage, isFetchingTemplate } = useImportPublipostage();
    useEffect(() => {
        if (fileContent)
            setStep((prevStep) => prevStep + 1);
    }, [fileContent]);
    const PreviousButton = (<Button size="sm" variant="outline" isLoading={false} loadingText={t('global.status.creating')} onClick={() => setStep(step - 1)} isDisabled={step === 1} data-cy="import_previous_button">
      {t('contact.actions.previous')}
    </Button>);
    return (<>
      {step === 1 ? (<PlotsContactsList setStep={setStep} plotContactsIds={plotContactsIds} setContactIds={setContactIds}>
          {PreviousButton}
        </PlotsContactsList>) : step === 2 ? (<>
          <UploadFilePublipostage handleFileData={handleFileData} nbTemplate={templatesPublipostage?.length ?? 0}/>
          <TemplateFilePublipostage templates={templatesPublipostage} isFetching={isFetchingTemplate} handleFileData={handleFileData}/>
        </>) : step === 3 ? (<ValidatePublipostage fileContent={fileContent} contactIds={contactIds} closeDrawer={closeDrawer}>
          {PreviousButton}
        </ValidatePublipostage>) : null}
    </>);
};
export default PlotsPublipostageForm;
