import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { HStack, IconButton, Text, Tooltip, useMultiStyleConfig, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_MAP_BUTTONS } from 'analytics/useAnalytics';
import { TABS_VIEW_CLOSED, TABS_VIEW_CLOSED_VERTICAL, TABS_VIEW_FULL, TABS_VIEW_FULL_VERTICAL, } from 'context/LayoutContext';
import { useChangeLayout, useLayout, useTabsView, } from 'hooks/contexts/useLayoutContext';
import ExpandIcon from '../../../images/icons/Expand';
const TabsViewButton = ({ type }) => {
    const { trackEvent } = useAnalytics();
    const { t } = useTranslation();
    const tabsView = useTabsView();
    const layout = useLayout();
    const { deployLayout, reduceLayout } = useChangeLayout();
    const isHidden = (type === 'deploy' &&
        [TABS_VIEW_FULL, TABS_VIEW_FULL_VERTICAL]?.includes(tabsView)) ||
        (type === 'reduce' &&
            [TABS_VIEW_CLOSED, TABS_VIEW_CLOSED_VERTICAL]?.includes(tabsView));
    const styles = useMultiStyleConfig('Tabs', {});
    const tabStyles = {
        ...styles.tab,
        _hover: { backgroundColor: 'colorMode.background900' },
    };
    const getIcon = () => {
        if (layout === 'vertical') {
            if (type === 'reduce') {
                return <ExpandIcon fill="currentcolor" width={18} height={18}/>;
            }
            if (type === 'deploy') {
                return (<ExpandIcon fill="currentcolor" width={18} height={18} style={{ transform: 'rotateY(180deg)' }}/>);
            }
        }
        else {
            if (type === 'reduce') {
                return <ChevronDownIcon fill="currentcolor" width={5} height={5}/>;
            }
            if (type == 'deploy') {
                return <ChevronUpIcon fill="currentcolor" width={5} height={5}/>;
            }
        }
    };
    const handleClick = () => {
        if (type === 'deploy') {
            trackEvent({
                category: ANALYTICS_MAP_BUTTONS,
                action: [TABS_VIEW_CLOSED_VERTICAL, TABS_VIEW_CLOSED]?.includes(tabsView)
                    ? 'Carte / Onglets - Milieu'
                    : 'Plein écran du panneau',
            });
            return deployLayout();
        }
        if (type === 'reduce') {
            trackEvent({
                category: ANALYTICS_MAP_BUTTONS,
                action: [TABS_VIEW_FULL, TABS_VIEW_FULL_VERTICAL]?.includes(tabsView)
                    ? 'Carte / Onglets - Milieu'
                    : 'Plein écran de la carte',
            });
            return reduceLayout();
        }
    };
    if (isHidden)
        return null;
    return (<HStack paddingX={2} paddingBottom={1} height="full" __css={tabStyles} alignItems="center" borderTopRadius="md" backgroundColor="colorMode.base" onClick={handleClick} cursor="pointer" data-cy={'tab_nav_' + type}>
      <Tooltip label={''} placement="left-start">
        <IconButton flex={1} height="full" minWidth={'none'} color="red.500" variant="link" border="none" icon={getIcon()} aria-label={t('tabs.panel.up')}/>
      </Tooltip>
      <Text fontSize="sm" color="red.500">
        {t('tabs.panel.' + type)}
      </Text>
    </HStack>);
};
export default TabsViewButton;
