import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Heading, Text, } from '@chakra-ui/react';
import AdvancedSearch, { advancedSearchFields, } from 'components/sidebar/PlotSearchSideBar/form/AdvancedSearch';
import BaseSearch, { baseSearchFields, } from 'components/sidebar/PlotSearchSideBar/form/BaseSearch';
import OwnerSearch, { ownerSearchFields, } from 'components/sidebar/PlotSearchSideBar/form/OwnerSearch';
import OwnerSearchOptional from 'components/sidebar/PlotSearchSideBar/form/OwnerSearchOptional';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import useCurrentSite from 'hooks/useCurrentSite';
import usePlotSearchFilters from 'hooks/usePlotSearchFilters';
import { accordionArrowStyle, accordionBoxShadow, } from 'utils/accordionArrowStyle';
const backgroundColor = {
    0: 'colorMode.background900',
    1: 'colorMode.sidebar600',
    2: 'colorMode.background700',
    3: 'colorMode.background600',
    4: 'colorMode.background500',
};
const PlotsSearchFormFields = ({ isLoadingLayersTree, isLoadingSearchData, hasSearchField, }) => {
    const { t } = useTranslation();
    const form = useFormContext();
    const isLoading = isLoadingSearchData || isLoadingLayersTree;
    const { currentSite } = useCurrentSite();
    const selectedTown = useSelectedTown();
    const { filters } = usePlotSearchFilters({ townshipId: selectedTown?.id });
    const hasSearchFields = (fieldNames) => fieldNames.some(hasSearchField);
    const hasFilter = (filter) => (filters ? filters[filter] : false);
    return (<Accordion allowMultiple border="none" maxWidth="400px" defaultIndex={[0, 1, 2]}>
      {hasSearchFields(baseSearchFields) && (<AccordionItem borderBottom="none" data-cy="plot_search_base_search_panel">
          <Heading marginTop="-1px" as="h2" backgroundColor={backgroundColor[1]} position="relative" _after={{
                ...accordionArrowStyle,
                borderTopColor: backgroundColor[1],
            }} _hover={{
                _after: {
                    borderTopColor: backgroundColor[2],
                },
            }} boxShadow={accordionBoxShadow}>
            <AccordionButton paddingY="12px" alignItems="center">
              <Box flex={1} textAlign="left">
                <Text textStyle="paragraph" fontWeight={500}>
                  {t(`sidebar.search_plots.search_plots`)}
                </Text>
              </Box>
              <AccordionIcon color="colorMode.font700"/>
            </AccordionButton>
          </Heading>
          <AccordionPanel padding={0}>
            <BaseSearch form={form} isLoadingSearchData={isLoadingSearchData} hasSearchField={hasSearchField}/>
          </AccordionPanel>
        </AccordionItem>)}

      {hasSearchFields(advancedSearchFields) && (<AccordionItem borderBottom="none" data-cy="plot_search_advanced_search_panel">
          <Heading marginTop="-1px" as="h1" backgroundColor={backgroundColor[1]} position="relative" _after={{
                ...accordionArrowStyle,
                borderTopColor: backgroundColor[1],
            }} _hover={{
                _after: {
                    borderTopColor: backgroundColor[2],
                },
            }} boxShadow={accordionBoxShadow}>
            <AccordionButton paddingY="12px" alignItems="center">
              <Box flex={1} textAlign="left">
                <Text textStyle="paragraph" textOverflow="ellipsis" fontWeight={500}>
                  {t('sidebar.search_plots.search_advance_plots')}
                </Text>
              </Box>
              <AccordionIcon color="colorMode.font700"/>
            </AccordionButton>
          </Heading>
          <AccordionPanel padding={0}>
            <AdvancedSearch isLoading={isLoading} hasSearchField={hasSearchField} hasFilter={hasFilter}/>
          </AccordionPanel>
        </AccordionItem>)}

      {(currentSite.ownerPublicity ||
            (currentSite.ownerSubscription &&
                hasSearchFields(ownerSearchFields))) && (<AccordionItem borderBottom="none">
          <Heading marginTop="-1px" as="h1" backgroundColor={backgroundColor[1]} position="relative" _after={{
                ...accordionArrowStyle,
                borderTopColor: backgroundColor[1],
            }} _hover={{
                _after: {
                    borderTopColor: backgroundColor[2],
                },
            }} boxShadow={accordionBoxShadow}>
            <AccordionButton paddingY="12px" alignItems="center">
              <Box flex={1} textAlign="left">
                <Text textStyle="paragraph" fontWeight={500}>
                  {t('sidebar.search_plots.search_by_owner')}
                </Text>
              </Box>
              <AccordionIcon color="colorMode.font700"/>
            </AccordionButton>
          </Heading>

          <AccordionPanel padding={0} data-cy="plot_search_owner_panel">
            {currentSite.ownerPublicity && (<OwnerSearchOptional boxShadow={accordionBoxShadow} padding={4}/>)}
            {currentSite.ownerSubscription && (<OwnerSearch form={form} hasSearchField={hasSearchField} isLoading={isLoading}/>)}
          </AccordionPanel>
        </AccordionItem>)}
    </Accordion>);
};
export default PlotsSearchFormFields;
