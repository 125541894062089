import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import PortIcon from 'images/port_icon.png';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOPhotovoltaicProneAreaPortsLayerItem = IOLayerItem;
const fetchPhotovoltaicProneAreaPorts = (townId) => () => get(iots.array(IOPhotovoltaicProneAreaPortsLayerItem), `${townId}/photovoltaic-prone-area/port`);
const usePhotovoltaicProneAreaPortsLayer = () => {
    createLayersHook({
        fetch: fetchPhotovoltaicProneAreaPorts,
        markerImg: PortIcon,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.PHOTOVOLTAIC_PRONE_AREA_PORTS);
};
const PhotovoltaicProneAreaPortsLayer = () => {
    usePhotovoltaicProneAreaPortsLayer();
    return null;
};
export default PhotovoltaicProneAreaPortsLayer;
