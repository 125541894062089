import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import { filtersKey } from 'utils/filters';
import { formatInfoWindowContent, IOEpfLandControlLayerItem, } from './EPFLandControlLayer';
const fetchEPFLandWatch = (townId) => () => get(iots.array(IOEpfLandControlLayerItem), `legacy/${townId}/epf-land-watch`);
const useEPFLandWatchLayer = () => {
    const selectedTown = useSelectedTown();
    const { t } = useTranslation();
    const [itemInfo, setItemInfo] = useState({});
    const getInfoWindowContent = useCallback((item) => {
        const content = formatInfoWindowContent(item, t);
        setItemInfo({
            ...itemInfo,
            [item.id]: content,
        });
        return content;
    }, [selectedTown]);
    createLayersHook({
        fetch: fetchEPFLandWatch,
        getInfoWindowContent,
    })(filtersKey.EPF_LAND_WATCH);
};
const EPFLandWatchLayer = () => {
    useEPFLandWatchLayer();
    return null;
};
export default EPFLandWatchLayer;
