import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IORoadProjectMultimodaleLayerItem = IOLayerItem;
const fetchRoadProjectMultimodale = (townId) => () => get(iots.array(IORoadProjectMultimodaleLayerItem), `legacy/${townId}/road-project-multimodale`);
const useRoadProjectMultimodaleLayer = () => {
    createLayersHook({
        fetch: fetchRoadProjectMultimodale,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.ROAD_PROJECT_MULTIMODALE);
};
const RoadProjectMultimodaleLayer = () => {
    useRoadProjectMultimodaleLayer();
    return null;
};
export default RoadProjectMultimodaleLayer;
