import { useQuery } from '@tanstack/react-query';
import { get } from 'api/client';
import LogoKelFoncier from 'images/logo_kelFoncier.png';
import KelfoncierWhite from 'images/logo_kelFoncier_white.png';
import { IOSite } from 'types/api';
// C'est seulement pour nos site que l'on affiche le logo définie dans la BDD, sinon c'est le logo par défaut
const SITE_KELFONCIER = [
    '3',
    '4',
    '31',
    '37',
    '128',
    '129',
    '130',
    '132',
    '134',
    '135',
    '136',
    '137',
    '138',
    '139',
];
export function getSiteLogoWhite(site) {
    if (SITE_KELFONCIER.includes(site.id)) {
        return site.logo ? require('../images/sites/' + site.logo) : KelfoncierWhite;
    }
    return KelfoncierWhite;
}
export function getSiteLogo(site) {
    if (SITE_KELFONCIER.includes(site.id)) {
        return site.logo ? require('../images/sites/' + site.logo) : LogoKelFoncier;
    }
    return LogoKelFoncier;
}
export function getSiteName(site) {
    if (!site) {
        return 'KelFoncier';
    }
    switch (site.id) {
        case '128': // foncier facile
        case '135': // immo foncier facile
        case '136': // pro foncier facile
            return 'Foncier Facile';
        default:
            return 'KelFoncier';
    }
}
const QUERY_KEY = ['site'];
const fetchSite = () => () => get(IOSite, `public/site`);
const useSite = () => {
    const { data: site, isInitialLoading: isLoading } = useQuery({
        queryKey: QUERY_KEY,
        queryFn: fetchSite(),
    });
    return {
        site,
        isLoading,
    };
};
export default useSite;
