import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@chakra-ui/react';
import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import senior1Img from 'images/seniorResidences/senior_1_icon.png';
import senior2Img from 'images/seniorResidences/senior_2_icon.png';
import senior3Img from 'images/seniorResidences/senior_3_icon.png';
import senior4Img from 'images/seniorResidences/senior_4_icon.png';
import senior5Img from 'images/seniorResidences/senior_5_icon.png';
import { IOMarkerLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
const IOSeniorResidencesMarkerItem = iots.intersection([
    IOMarkerLayerItem,
    iots.type({
        name: iots.string,
        label: iots.string,
    }),
    iots.partial({
        address: iots.string,
        postcode: iots.string,
        town: iots.string,
        url: iots.union([iots.null, iots.string]),
        category: iots.string,
        type: iots.string,
        mail: iots.string,
        phone: iots.string,
        management: iots.string,
    }),
]);
const seniorResidencesImagesForLabel = {
    'picto_seniors1.png': senior1Img,
    'picto_seniors2.png': senior2Img,
    'picto_seniors3.png': senior3Img,
    'picto_seniors4.png': senior4Img,
    'picto_seniors5.png': senior5Img,
};
const getMarkerImg = (marker) => {
    return seniorResidencesImagesForLabel[marker.label.toLowerCase()];
};
const fetchSeniorResidences = (townId) => () => get(iots.array(IOSeniorResidencesMarkerItem), `legacy/${townId}/senior-residences`);
const formatInfoWindowContent = (marker, t) => {
    const content = (<Box>
      <Text fontWeight={900}>{marker.name}</Text>
      <Text>{`${marker.address} ${marker.postcode} ${marker.town}`}</Text>
      <Text fontWeight={900}>{marker.category}</Text>
      <Text fontWeight={900}>{marker.type}</Text>
      <Text fontWeight={900}>{marker.management}</Text>
      <Text>{marker.mail}</Text>
      <Text>{marker.phone}</Text>
      {marker.url && (<a href={marker.url} rel="noreferrer" target="_blank">
          {t('info_window.senior_residences.url')}
        </a>)}
    </Box>);
    return ReactDOMServer.renderToStaticMarkup(content);
};
const LEGENDS = {
    private_residency: {
        layerKey: filtersKey.SENIOR_RESIDENCES,
        label: 'private_residency',
        needsTranslate: true,
        image: senior5Img,
    },
    permanent_EHPAD: {
        layerKey: filtersKey.SENIOR_RESIDENCES,
        label: 'permanent_EHPAD',
        needsTranslate: true,
        image: senior1Img,
    },
    temporary_EHPAD: {
        layerKey: filtersKey.SENIOR_RESIDENCES,
        label: 'temporary_EHPAD',
        needsTranslate: true,
        image: senior4Img,
    },
    autonomous_residency: {
        layerKey: filtersKey.SENIOR_RESIDENCES,
        label: 'autonomous_residency',
        needsTranslate: true,
        image: senior2Img,
    },
    long_time_cares: {
        layerKey: filtersKey.SENIOR_RESIDENCES,
        label: 'long_time_cares',
        needsTranslate: true,
        image: senior3Img,
    },
};
const useSeniorResidencesLayer = () => {
    const { t } = useTranslation();
    const getInfoWindowContent = (marker) => {
        return formatInfoWindowContent(marker, t);
    };
    createLayersHook({
        fetch: fetchSeniorResidences,
        markerImg: getMarkerImg,
        legends: LEGENDS,
        getInfoWindowContent,
    })(filtersKey.SENIOR_RESIDENCES);
};
const SeniorResidencesLayer = () => {
    useSeniorResidencesLayer();
    return null;
};
export default SeniorResidencesLayer;
