import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { IOCoordinate } from 'types/types';
const IONeighborhood = iots.type({
    displayNeighborhood: iots.boolean,
    title: iots.union([iots.string, iots.null]),
    description: iots.string,
    name: iots.string,
    townshipName: iots.string,
    departmentId: iots.number,
    lat: IOCoordinate,
    lng: IOCoordinate,
    image: iots.union([
        iots.type({
            url: iots.string,
            id: iots.string,
            title: iots.string,
        }, 'IONeighborhoodImage'),
        iots.null,
    ]),
    statistics: iots.record(iots.string, iots.array(iots.type({
        neighborhood: iots.string,
        town: iots.string,
        department: iots.string,
        title: iots.string,
        goodToKnow: iots.string,
        definition: iots.string,
        source: iots.string,
    }, 'IONeighborhoodStatistic'), 'IONeighborhoodStatistics'), 'IONeighborhoodStatisticsRecords'),
});
const fetchNeighborhood = (lat, lng) => () => get(IONeighborhood, `neighborhood/${lat}/${lng}`);
export const getQueryKey = (lat, lng) => [
    'neighborhood',
    lat,
    lng,
];
const useNeighborhood = (lat, lng, enabled) => {
    return useQuery({
        queryKey: getQueryKey(lat, lng),
        queryFn: fetchNeighborhood(lat, lng),
        enabled: !!lat && !!lng && enabled,
    });
};
export default useNeighborhood;
