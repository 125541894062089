import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { EditIcon } from '@chakra-ui/icons';
import { Box, Button, IconButton } from '@chakra-ui/react';
import CustomTooltip from 'components/tooltips/CustomTooltip';
const EditButton = ({ mode = 'button', handleEdit, label, showIcon, ...buttonProps }) => {
    const { t } = useTranslation();
    const buttonText = label ?? t('global.actions.edit');
    const getButton = () => {
        if (mode === 'icon') {
            return (<Box>
          <CustomTooltip label={buttonText}>
            <IconButton variant="ghost" color="colorMode.revertBase" icon={<EditIcon fill="currentColor"/>} aria-label={buttonText} onClick={handleEdit} {...buttonProps}/>
          </CustomTooltip>
        </Box>);
        }
        return (<Button variant="primary" onClick={handleEdit} {...buttonProps}>
        {showIcon && (<Box display="inline" paddingRight={1}>
            <EditIcon fill="currentColor"/>
          </Box>)}{' '}
        {buttonText}
      </Button>);
    };
    return getButton();
};
export default EditButton;
