import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
const IOPlotFarming = iots.type({
    id: iots.string,
    name: iots.string,
    info: iots.string,
});
const fetchPlotFarming = (plotId) => () => get(iots.array(IOPlotFarming), `plot/${plotId}/farming`);
export const QUERY_KEY = 'plot-farming';
export const getQueryKey = (plotId) => [QUERY_KEY, plotId];
const usePlotFarming = (hasConfiguration, plot) => {
    const { data: plotFarming, isInitialLoading } = useQuery({
        queryKey: getQueryKey(plot?.id),
        queryFn: fetchPlotFarming(plot?.id),
        enabled: !!plot?.id && hasConfiguration,
    });
    return { plotFarming, isInitialLoading };
};
export default usePlotFarming;
