import useCurrentSite from 'hooks/useCurrentSite';
const useSiteConfiguration = () => {
    const { currentSite } = useCurrentSite();
    const configuration = currentSite?.configuration;
    const hasFeature = (feature) => !configuration || configuration?.features?.includes(feature);
    const hasMapControl = (control) => !configuration || configuration?.map_controls?.includes(control);
    const hasDashboardItem = (item) => !configuration || configuration?.dashboard?.includes(item);
    const hasAnyDashboardItem = () => !configuration || configuration?.dashboard?.length > 0;
    const hasSearchField = (field) => !configuration || configuration?.foncier?.includes(field);
    const hasMenuItem = (menu) => !configuration || configuration?.menu?.includes(menu);
    const hasPlotTab = (tab) => !configuration || configuration.plot_tabs?.includes(tab);
    const hasProjectTab = (tab) => !configuration || configuration.project_tabs?.includes(tab);
    const hasPlotInfo = (item) => !configuration || configuration?.plot?.includes(item);
    const hasMainLayersTree = (layer) => {
        if (!configuration) {
            return true;
        }
        const mainlayers = configuration?.layers_tree?.main_layers;
        if (Array.isArray(mainlayers)) {
            return mainlayers.includes(layer);
        }
        else {
            return false;
        }
    };
    const defaultMapDisplay = () => {
        if (currentSite?.legacyId === '130')
            return google.maps.MapTypeId.SATELLITE;
        return google.maps.MapTypeId.ROADMAP;
    };
    return {
        hasFeature,
        hasMapControl,
        hasDashboardItem,
        hasAnyDashboardItem,
        hasSearchField,
        hasMenuItem,
        hasPlotTab,
        hasPlotInfo,
        hasProjectTab,
        hasMainLayersTree,
        defaultMapDisplay,
    };
};
export default useSiteConfiguration;
