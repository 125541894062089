import { useTranslation } from 'react-i18next';
import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOPolygonLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
const IOContaminatedSitesAndSoilsMarkerLayerItem = iots.intersection([
    IOPolygonLayerItem,
    iots.partial({
        url: iots.string,
    }),
]);
const fetchContaminatedSitesAndSoils = (townId) => () => get(iots.array(IOContaminatedSitesAndSoilsMarkerLayerItem), `legacy/${townId}/contaminated-sites-and-soils`);
const formatInfoWindowContent = (item, t) => {
    let content = `<b>${item.infoTitle}</b>`;
    if (item.infoLine1) {
        content += `<br /><b>${item.infoLine1}</b>`;
    }
    if (item.infoLine2) {
        content += `<br />${item.infoLine2}`;
    }
    if (item.url) {
        content += `<br /><a href="${item.url}" target="_blank">${t('info_window.contaminated_sites_and_soils.url')}</a>`;
    }
    return content;
};
const useContaminatedSitesAndSoilsLayer = () => {
    const { t } = useTranslation();
    const getInfoWindowContent = (marker) => {
        return formatInfoWindowContent(marker, t);
    };
    createLayersHook({
        fetch: fetchContaminatedSitesAndSoils,
        getInfoWindowContent,
    })(filtersKey.CONTAMINATED_SITES_AND_SOILS);
};
const ContaminatedSitesAndSoilsLayer = () => {
    useContaminatedSitesAndSoilsLayer();
    return null;
};
export default ContaminatedSitesAndSoilsLayer;
