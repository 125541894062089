import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOMiscLimitationsLayerItem = IOLayerItem;
const fetchMiscLimitations = (townId) => () => get(iots.array(IOMiscLimitationsLayerItem), `legacy/${townId}/misc-limitations`);
const useMiscLimitationsLayer = () => {
    createLayersHook({
        fetch: fetchMiscLimitations,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.MISC_LIMITATIONS);
};
const MiscLimitationsLayer = () => {
    useMiscLimitationsLayer();
    return null;
};
export default MiscLimitationsLayer;
