import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, IconButton, Text } from '@chakra-ui/react';
import useAnalytics from 'analytics/useAnalytics';
import PaperPlanIcon from 'images/icons/PaperPlanIcon';
import { FROM_CONTACT } from 'utils/constants';
const CourierCreateButton = ({ courierInfoDefault, analyticsCategory, analyticsDetail, setCourierInfo, mode, fromPanel, ...rest }) => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const handleClick = () => {
        setCourierInfo({
            ...courierInfoDefault,
        });
        if (analyticsCategory && analyticsDetail) {
            trackEvent({
                category: analyticsCategory,
                action: analyticsDetail,
            });
        }
    };
    return (<>
      {mode === 'icon' ? (<IconButton variant="ghost" cursor="pointer" color="colorMode.revertBase" icon={<PaperPlanIcon fill="currentColor"/>} aria-label={'input-icon'} onClick={handleClick}/>) : (<Button variant="outline" onClick={handleClick} {...rest} data-cy={'create_courier_button'}>
          <Text as="span" color="inherit">
            {fromPanel === FROM_CONTACT
                ? t('tabs.couriers.create_button_contact')
                : t('tabs.couriers.create_button')}
          </Text>
        </Button>)}
    </>);
};
export default CourierCreateButton;
