import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';
import * as iots from 'io-ts';
import { CDAC_ALIMENTARY_VALUES, CDAC_CREATION_VALUES, CDAC_OPINION_VALUES, } from 'utils/constants';
export const IOCDACInfo = iots.type({
    id: iots.string,
    opinion: iots.string,
    opinionDate: iots.string,
    opinionUrl: iots.union([iots.null, iots.string]),
    sign: iots.string,
    detail: iots.string,
    existingSurface: iots.number,
    solicitedSurface: iots.number,
    totalSurface: iots.number,
    applicant: iots.string,
    date: iots.string,
    alimentaryId: iots.number,
    creationId: iots.number,
});
const CDACInfoWindow = ({ info }) => {
    const { t } = useTranslation();
    if (!info) {
        return null;
    }
    return (<Box maxHeight="400px">
      {info.opinion && (<Box>
          {t('cdac.opinion.label')} :{' '}
          <strong>{t(CDAC_OPINION_VALUES[info.opinion].label)}</strong>
        </Box>)}
      {info.opinionDate && (<Box>
          {t('cdac.opinion_date')} : <strong>{info.opinionDate}</strong>
        </Box>)}
      {info.opinionUrl && (<Box>
          {t('cdac.opinion_url')} :{' '}
          <a href={info.opinionUrl} target="_blank" rel="noreferrer">
            {t('cdac.prefecture')}
          </a>
        </Box>)}
      {info.sign && (<Box>
          {t('cdac.sign')} : {info.sign}
        </Box>)}
      {info.detail && (<Box>
          {t('cdac.detail')} : {info.detail}
        </Box>)}
      {info.existingSurface !== 0 && (<Box>
          {t('cdac.existing_surface')} : {info.existingSurface} m²
        </Box>)}
      {info.solicitedSurface !== 0 && (<Box>
          {t('cdac.solicited_surface')} :{' '}
          <strong>{info.solicitedSurface} m²</strong>
        </Box>)}
      {info.totalSurface !== 0 && (<Box>
          {t('cdac.total_surface')} : {info.totalSurface} m²
        </Box>)}
      {info.applicant && (<Box>
          {t('cdac.applicant')} : {info.applicant}
        </Box>)}
      {info.alimentaryId && (<Box>
          {t('cdac.alimentary.label')} :{' '}
          <strong>{t(CDAC_ALIMENTARY_VALUES[info.alimentaryId].label)}</strong>
        </Box>)}
      {info.creationId && (<Box>
          {t('cdac.creation.label')} :{' '}
          {t(CDAC_CREATION_VALUES[info.creationId].label)}
        </Box>)}
    </Box>);
};
export default CDACInfoWindow;
