import { useEffect, useState } from 'react';
import { useMap } from 'hooks/contexts/useMapContext';
const useComputedCenter = () => {
    const map = useMap();
    const [computedCenter, setComputedCenter] = useState(null);
    useEffect(() => {
        if (map) {
            setComputedCenter(map.getCenter());
            map.addListener('idle', () => {
                setComputedCenter(map.getCenter());
            });
        }
    }, [map]);
    return computedCenter;
};
export default useComputedCenter;
