import { useContextSelector } from 'use-context-selector';
import FiltersAccordionContext from 'context/FiltersAccordionContext';
export const useIsAllOpened = () => {
    return useContextSelector(FiltersAccordionContext, (state) => {
        return state.isAllOpened;
    });
};
export const useSetIsAllOpened = () => {
    return useContextSelector(FiltersAccordionContext, (state) => {
        return state.setIsAllOpened;
    });
};
export const usetoggleAllOpened = () => {
    return useContextSelector(FiltersAccordionContext, (state) => {
        return state.toggleAllOpened;
    });
};
export const useOpenedAccordions = () => {
    return useContextSelector(FiltersAccordionContext, (state) => {
        return state.openedAccordions;
    });
};
export const useResetOpenedAccordions = () => {
    return useContextSelector(FiltersAccordionContext, (state) => {
        return state.resetOpenedAccordions;
    });
};
export const useUpdateAccordions = () => {
    return useContextSelector(FiltersAccordionContext, (state) => {
        return state.updateAccordions;
    });
};
export const useOpenAllAccordions = () => {
    return useContextSelector(FiltersAccordionContext, (state) => {
        return state.openAllAccordions;
    });
};
export const useCloseAllAccordions = () => {
    return useContextSelector(FiltersAccordionContext, (state) => {
        return state.closeAllAccordions;
    });
};
