import * as React from 'react';
import { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { useMap } from 'hooks/contexts/useMapContext';
import { hideLayer } from 'hooks/layers/createTilesLayerHook';
import useTownBound from 'hooks/useTownBound';
import PLULayer, { PLU_ZOOM_LIMIT } from './PLULayer';
const PLUsAdjacentLayerWrapper = ({ computedZoom, }) => {
    if (computedZoom < PLU_ZOOM_LIMIT) {
        return null;
    }
    return <PLUsAdjacentLayer computedZoom={computedZoom}/>;
};
const PLUsAdjacentLayer = ({ computedZoom }) => {
    const selectedTown = useSelectedTown();
    const map = useMap();
    const selectedTownId = selectedTown?.id;
    const [bounds, setBounds] = useState({
        south: 0,
        north: 0,
        west: 0,
        east: 0,
    });
    const { townIds } = useTownBound({
        bounds,
        townshipId: selectedTownId,
    });
    const [townIdsFinal, setTownIdsFinal] = useState([]);
    useEffect(() => {
        if (!map) {
            return;
        }
        const latLng = map.getBounds()?.toJSON();
        map.addListener('idle', () => {
            const latLng = map.getBounds()?.toJSON();
            setBounds(latLng);
        });
        setBounds(latLng);
    }, [map]);
    useEffect(() => {
        if (!townIds) {
            return;
        }
        if (!isEqual(townIdsFinal, townIds)) {
            const difference = townIdsFinal.filter((x) => !townIds.includes(x));
            difference.forEach((townId) => {
                hideLayer(map, townId);
            });
            setTownIdsFinal(townIds);
        }
    }, [townIds]);
    // On commence à 100 pour réserver les tilesPositions
    let position = 100;
    return (<>
      {townIdsFinal?.map((townId) => {
            if (townId === selectedTownId) {
                return;
            }
            position += 1;
            return (<PLULayer key={townId} townId={townId} isSelectedTown={false} pluAdjacent={true} position={position} computedZoom={computedZoom}/>);
        })}
    </>);
};
export default PLUsAdjacentLayerWrapper;
