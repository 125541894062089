import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Link, Text } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_CATEGORY_DOWNLOAD, } from 'analytics/useAnalytics';
import DownloadLinkWrapper from 'components/links/DownloadLinkWrapper';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { FROM_PLOT, FROM_PLOT_RULES } from 'utils/constants';
import { extractLink, extractTitle } from 'utils/search';
import FilterTitle from './FilterTitle';
const FilterCategoryDocument = ({ documentKeys, from }) => {
    const selectedTown = useSelectedTown();
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const documents = documentKeys
        .map((documentKey) => {
        return selectedTown?.docs[documentKey]
            ? {
                link: selectedTown?.docs[documentKey],
                key: documentKey,
                title: extractTitle(selectedTown?.docs[documentKey]),
                extractLink: extractLink(selectedTown?.docs[documentKey]),
            }
            : null;
    })
        .filter((document) => document !== null);
    const handleTrackEvent = (documentKey) => {
        let action = '';
        switch (documentKey) {
            case 'realEstateTax':
                action = 'Règlement du PPRI, télécharger la délibération';
                break;
            case 'carence':
                action = 'Commune carencée';
                break;
            case 'charte':
                action = "Charte d'urbanisme et paysagere";
                break;
            case 'notice':
                action = 'Document notice';
                break;
            case 'oap':
                action = "Orientations d'aménagement et de programmation (OAP)";
                break;
            case 'ort':
                action = 'Opération de Revitalisation du Territoire (ORT)';
                break;
            case 'pluFutur':
                action = 'Document futur PLU';
                break;
            case 'plu':
                action = 'Document PLU';
                break;
            default:
                action = 'Document ' + documentKey;
        }
        if (from === FROM_PLOT) {
            action += ' Onglet parcelle';
        }
        else if (from === FROM_PLOT_RULES) {
            action += ' Onglet Règles de la parcelle';
        }
        trackEvent({ category: ANALYTICS_CATEGORY_DOWNLOAD, action: action });
    };
    if (!documents || documents.length === 0) {
        return null;
    }
    return (<>
      {documents.map((document) => {
            if (document.link.match(/^http/)) {
                return (<DownloadLinkWrapper key={document.key}>
              <Link isExternal href={document.link} onClick={() => {
                        handleTrackEvent(document.key);
                    }}>
                <FilterTitle title={`${t(`sidebar_content.document_link.${document.key}`)}`}/>
              </Link>
            </DownloadLinkWrapper>);
            }
            if (document.link.match(/href=/)) {
                let links = document.link.replaceAll('<br>', '<br/>');
                links = links.split('<br/>');
                return links.map((link, index) => (<Box key={index}>
              <DownloadLinkWrapper>
                <Box color="blue.500" cursor="pointer" onClick={() => {
                        handleTrackEvent(document.key);
                    }}>
                  <a target="_blank" href={extractLink(link)} rel="noreferrer">
                    <FilterTitle title={extractTitle(link)}/>
                  </a>
                </Box>
              </DownloadLinkWrapper>
            </Box>));
            }
            else if (document.extractLink) {
                return (<React.Fragment key={document.key}>
              {document.key === 'fusion' && (<Text fontSize={13} fontWeight="700">
                  <FilterTitle title={`${t(`sidebar_content.document_link.${document.key}`)}`}/>
                </Text>)}
              <Text fontSize={12}>
                <a target="_blank" href={document.extractLink} rel="noreferrer">
                  <FilterTitle title={document.title}/>
                </a>
              </Text>
            </React.Fragment>);
            }
            else if (document.key !== 'fusion') {
                return (<Text key={document.key} fontSize={13}>
              {document.key === 'fusion' && (<Text as="span" fontWeight={700}>
                  {`${t(`sidebar_content.document_link.plu_date`)}`}
                </Text>)}
              <FilterTitle title={document.title}/>
            </Text>);
            }
        })}
    </>);
};
export default FilterCategoryDocument;
