import * as React from 'react';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Center, FormControl, FormErrorMessage, Icon, Input, Text, VStack, } from '@chakra-ui/react';
import DeleteIcon from 'images/icons/DeleteIcon';
import DownloadIcon from 'images/icons/Download';
const UploadInput = ({ name, acceptedFileFormat, fileError = null, }) => {
    const { t } = useTranslation();
    const { reset, register, formState: { errors }, setValue, watch, trigger, } = useFormContext();
    const [filename, setFilename] = useState(null);
    const error = errors[name];
    const file = watch(name);
    React.useEffect(() => {
        if (file && file[0]?.name) {
            setFilename(file[0]?.name);
            trigger(name);
        }
        else {
            setFilename(undefined);
        }
    }, [file]);
    React.useEffect(() => {
        if (error)
            setFilename(undefined);
    }, [error]);
    React.useEffect(() => {
        if (fileError) {
            reset();
            setFilename(undefined);
        }
    }, [fileError]);
    return (<FormControl isInvalid={!!error}>
      <Box borderRadius="6px" border="1px dashed" borderColor={!error ? 'colorMode.font700' : 'red.500'} _hover={{ borderColor: 'colorMode.font900' }} display={filename ? 'none' : 'block'}>
        <Input {...register(name)} type="file" height="100px" accept={acceptedFileFormat} opacity="0" cursor="pointer" position="absolute" data-cy="document_upload"/>

        <VStack justifyContent="center" alignItems="center" padding={6}>
          <Center backgroundColor="colorMode.base" width="40px" height="40px" borderRadius="32px">
            <Icon as={DownloadIcon} width={5} height={5} fill="currentColor" alignSelf="center"/>
          </Center>

          <Text textStyle="paragraph" color="colorMode.font700" fontWeight={500}>
            <Text as="span" fontWeight="bold" marginRight={1}>
              {t('tabs.document.download_file')}
            </Text>
            {t('tabs.document.or_dnd_file')}
          </Text>
          {file && file[0]?.name ? (<FormErrorMessage>{t(error?.message)}</FormErrorMessage>) : (<FormErrorMessage>
              {t('global.fields.error.required')}
            </FormErrorMessage>)}
        </VStack>
      </Box>
      {filename && (<Box>
          <Box display="flex" justifyContent="space-between" fontSize="sm" marginTop={1} padding={2} borderRadius="6px" backgroundColor="colorMode.base" _hover={{ backgroundColor: 'colorMode.background900' }} color={error ? 'red.500' : 'colorMode.font900'}>
            {filename}{' '}
            <DeleteIcon cursor="pointer" fill="currentColor" onClick={() => {
                setFilename(null);
                setValue(name, null, { shouldValidate: true });
            }}/>
          </Box>
          <FormErrorMessage paddingX={2}>{t(error?.message)}</FormErrorMessage>
        </Box>)}
    </FormControl>);
};
export default UploadInput;
