import { useSearchParams } from 'react-router-dom';
import useAnalytics from 'analytics/useAnalytics';
import qs from 'qs';
import { useClearPlotSummary } from 'hooks/contexts/useLegendContext';
import { useClearTown, useResetPlotSelectionMode, useSelectedTown, } from 'hooks/contexts/useLocationContext';
import { useMap, useSetPLUInfoData } from 'hooks/contexts/useMapContext';
import { useSetFeasibilityParentId } from 'hooks/contexts/useSecondaryPlotsContext';
import { useChangeSelectedTab, useIsTabOpened, useTogglePlot, } from 'hooks/contexts/useTabsContext';
import useChangeTown from 'hooks/useChangeTown';
import { FROM_PLOT_SUMMARY } from 'utils/constants';
import { PLOT_MIN_ZOOM } from 'utils/map';
export default ({ lat, lng, analyticDetail, analyticOrigin, plotId, townId, from, }) => {
    const selectedTown = useSelectedTown();
    const [searchParams] = useSearchParams();
    const resetPlotSelectionMode = useResetPlotSelectionMode();
    const isTabOpened = useIsTabOpened();
    const { trackEvent } = useAnalytics();
    const clearTown = useClearTown();
    const changeSelectedTab = useChangeSelectedTab();
    const changeSelectedTown = useChangeTown();
    const setPLUInfoData = useSetPLUInfoData();
    const clearPlotSummary = useClearPlotSummary();
    const setFeasibilityParentId = useSetFeasibilityParentId();
    const map = useMap();
    const isPlotTabOpened = isTabOpened(plotId);
    const togglePlot = useTogglePlot();
    const { formattedAddress } = qs.parse(searchParams.toString(), {
        ignoreQueryPrefix: true,
    });
    return () => {
        clearPlotSummary();
        if (!isPlotTabOpened && analyticOrigin && analyticDetail) {
            trackEvent({
                category: analyticOrigin,
                action: analyticDetail,
            });
        }
        resetPlotSelectionMode();
        const coordinates = new google.maps.LatLng(parseFloat(lat), parseFloat(lng));
        if (!selectedTown || (townId && selectedTown?.id !== townId)) {
            clearTown();
            const data = {
                townCoordinates: coordinates,
                precision: 2,
                plotToSelect: coordinates,
                centerMapOn: null,
                formattedAddress: formattedAddress,
                addTownMarker: true,
            };
            if (from !== FROM_PLOT_SUMMARY) {
                data.centerMapOn = coordinates;
            }
            changeSelectedTown(data);
        }
        setPLUInfoData(new google.maps.Point(parseFloat(lat), parseFloat(lng)), townId);
        if (isPlotTabOpened) {
            changeSelectedTab(plotId);
            setFeasibilityParentId(plotId);
            map?.setCenter(coordinates);
            return;
        }
        togglePlot({
            resource: {
                id: plotId,
                lat,
                lng,
                townId,
            },
        });
        if (from !== FROM_PLOT_SUMMARY) {
            if (map?.getZoom() < PLOT_MIN_ZOOM) {
                map?.setZoom(PLOT_MIN_ZOOM);
            }
            map?.setCenter(coordinates);
        }
    };
};
