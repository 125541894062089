import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOViewAnglesProtectionLayerItem = IOLayerItem;
const fetchViewAnglesProtection = (townId) => () => get(iots.array(IOViewAnglesProtectionLayerItem), `legacy/${townId}/view-angles-protection`);
const useViewAnglesProtectionLayer = () => {
    createLayersHook({
        fetch: fetchViewAnglesProtection,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.VIEW_ANGLES_PROTECTION);
};
const ViewAnglesProtectionLayer = () => {
    useViewAnglesProtectionLayer();
    return null;
};
export default ViewAnglesProtectionLayer;
