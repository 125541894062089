import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getLayerBoundingTileUrlTileUrl, getNormalizedCoordinates, } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = (map, tileWidth, tileHeight) => (coord, zoom) => {
    const normCoord = getNormalizedCoordinates(coord, zoom);
    if (!normCoord) {
        return null;
    }
    return getLayerBoundingTileUrlTileUrl(map, 'Applicatif', 'contours_quartiers_20241024', zoom, coord, tileWidth, tileHeight);
};
const getLegends = () => {
    const legends = {};
    legends.neighborhoods = {
        layerKey: filtersKey.NEIGHBORHOODS,
        label: 'neighborhoods',
        needsTranslate: true,
        color: '#FFFFFE',
        borderColor: '#6349FF',
        borderWidth: 3,
        borderOpacity: 0.7,
    };
    return legends;
};
const useNeighborhoodsLayer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        getLegends,
        position: CST_POSITIONS[filtersKey.NEIGHBORHOODS],
        opacity: 0.7,
        tileWidth: 1024,
        tileHeight: 1024,
    })(filtersKey.NEIGHBORHOODS);
};
const NeighborhoodsLayer = () => {
    useNeighborhoodsLayer();
    return null;
};
export default NeighborhoodsLayer;
