import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { del, get, post } from 'api/client';
import useCustomToast from 'hooks/useCustomToast';
import { IOCourierSender } from 'types/api';
const QUERY_KEY = ['courier_sender'];
const fetchCourierSenders = () => () => get(iots.array(IOCourierSender), `courier/senders`);
const createCourierSenderRequest = (courierSender) => post(IOCourierSender, `courier/sender/create`, courierSender);
const editCourierSenderRequest = (courierSender) => post(IOCourierSender, `courier/sender/edit`, courierSender);
const deleteCourierSenderRequest = (courierSenderId) => del(null, `courier/sender/${courierSenderId}/delete`);
const useCourierSender = ({ handleSuccess, }) => {
    const { getSuccessToast } = useCustomToast();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { data: courierSenders, isInitialLoading: isLoadingCourierSenders } = useQuery({
        queryKey: QUERY_KEY,
        queryFn: fetchCourierSenders(),
    });
    const { mutate: createCourierSender, isPending: isCreatingCourierSender } = useMutation({
        mutationFn: createCourierSenderRequest,
        onSuccess: (e) => {
            queryClient.invalidateQueries({ queryKey: QUERY_KEY });
            getSuccessToast({
                title: t('courier.toast.courier_sender_created'),
                id: 'create_sender',
            });
            handleSuccess && handleSuccess(e);
        },
    });
    const { mutate: editCourierSender, isPending: isEditingCourierSender } = useMutation({
        mutationFn: editCourierSenderRequest,
        onSuccess: (e) => {
            queryClient.invalidateQueries({ queryKey: QUERY_KEY });
            getSuccessToast({
                title: t('courier.toast.courier_sender_updated'),
                id: 'update_sender',
            });
            handleSuccess && handleSuccess(e);
        },
    });
    const { mutate: deleteCourierSender, isPending: isDeletingCourierSender } = useMutation({
        mutationFn: deleteCourierSenderRequest,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: QUERY_KEY });
            getSuccessToast({
                title: t('courier.toast.courier_sender_deleted'),
                id: 'delete_sender',
            });
        },
    });
    return {
        courierSenders,
        isLoadingCourierSenders,
        createCourierSender,
        isCreatingCourierSender,
        editCourierSender,
        isEditingCourierSender,
        deleteCourierSender,
        isDeletingCourierSender,
    };
};
export default useCourierSender;
