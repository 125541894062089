import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOPPRFloodingLowContingencyBuildingZoneLayerItem = IOLayerItem;
const fetchPPRFloodingLowContingencyBuildingZone = (townId) => () => get(iots.array(IOPPRFloodingLowContingencyBuildingZoneLayerItem), `legacy/${townId}/ppr-flooding-low-contingency-building-zone`);
const usePPRFloodingLowContingencyBuildingZoneLayer = () => {
    createLayersHook({
        fetch: fetchPPRFloodingLowContingencyBuildingZone,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.PPR_FLOODING_LOW_CONTINGENCY_BUILDING_ZONE);
};
const PPRFloodingLowContingencyBuildingZoneLayer = () => {
    usePPRFloodingLowContingencyBuildingZoneLayer();
    return null;
};
export default PPRFloodingLowContingencyBuildingZoneLayer;
