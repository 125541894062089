import * as React from 'react';
import { useCallback, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@chakra-ui/react';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import VotePlaceImg from 'images/vote_place_icon.png';
import { IOMarkerLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
const IOVotePlaceMarkerItem = iots.intersection([
    IOMarkerLayerItem,
    iots.type({
        name: iots.string,
        number: iots.string,
        address: iots.string,
        town: iots.string,
        townId: iots.string,
    }),
]);
const IOVotePlaceMarkerInfo = iots.type({
    number: iots.number,
    name: iots.string,
    firstName: iots.string,
    label: iots.string,
    voteCount: iots.number,
    votePercentage: iots.number,
});
const fetchVotePlace = (townId) => () => get(iots.array(IOVotePlaceMarkerItem), `legacy/${townId}/vote-place`);
const fetchVotePlaceInfo = (item) => {
    return get(iots.array(IOVotePlaceMarkerInfo), `legacy/${item.id}/vote-place-info`);
};
const LEGENDS = {
    vote_place: {
        layerKey: filtersKey.VOTE_PLACE,
        label: 'vote_place',
        needsTranslate: true,
        image: VotePlaceImg,
    },
};
const ResultsTable = ({ info, }) => {
    const { t } = useTranslation();
    return (<table style={{ height: '100%', fontSize: '12px' }}>
      <tr>
        <td style={{ width: '100px' }}>
          {t('info_window.vote_place.results.name')}
        </td>
        <td style={{ width: '100px', textAlign: 'center' }}>
          {t('info_window.vote_place.results.firstName')}
        </td>
        <td style={{ width: '90px', textAlign: 'center' }}>
          {t('info_window.vote_place.results.label')}
        </td>
        <td style={{ width: '90px', textAlign: 'center' }}>
          {t('info_window.vote_place.results.vote_count')}
        </td>
        <td style={{ width: '90px', textAlign: 'center' }}>
          {t('info_window.vote_place.results.vote_percentage')}
        </td>
      </tr>
      {info.length &&
            info.map((item, index) => (<tr key={index}>
            <td>{item?.name}</td>
            <td>{item?.firstName}</td>
            <td style={{ textAlign: 'center' }}>{item?.label}</td>
            <td style={{ textAlign: 'right' }}>{item?.voteCount}</td>
            <td style={{ textAlign: 'right' }}>{item?.votePercentage}%</td>
          </tr>))}
    </table>);
};
const formatInfoWindowContent = (marker, info, t) => {
    const content = (<Box>
      <Text fontWeight={500} textStyle="paragraph">
        {t('info_window.vote_place.title')} {marker.number}
      </Text>
      <Text textStyle="paragraph">{marker.name}</Text>
      <Text textStyle="paragraph">{marker.address}</Text>
      <Text textStyle="paragraph">{marker.town}</Text>

      {info.length > 0 && (<>
          <Text paddingTop="10px">
            {t('info_window.vote_place.results_2014_2')} {marker.number}
          </Text>
          <ResultsTable info={info.filter((item) => item?.number !== 1)}/>
          <Text paddingTop="10px">
            {t('info_window.vote_place.results_2014_1')} {marker.number}
          </Text>
          <ResultsTable info={info.filter((item) => item?.number === 1)}/>
        </>)}
    </Box>);
    return ReactDOMServer.renderToStaticMarkup(content);
};
const useVotePlaceLayer = () => {
    const { t } = useTranslation();
    const selectedTown = useSelectedTown();
    const [markerInfo, setMarkerInfo] = useState({});
    const getInfoWindowContent = useCallback(async (marker) => {
        const markerKey = `${marker.lat}/${marker.lng}`;
        if (markerInfo[markerKey]) {
            return markerInfo[markerKey];
        }
        const votePlaceInfo = await fetchVotePlaceInfo(marker);
        const content = formatInfoWindowContent(marker, votePlaceInfo, t);
        setMarkerInfo({
            ...markerInfo,
            [markerKey]: content,
        });
        return content;
    }, [selectedTown]);
    createLayersHook({
        fetch: fetchVotePlace,
        markerImg: VotePlaceImg,
        legends: LEGENDS,
        getInfoWindowContent,
    })(filtersKey.VOTE_PLACE);
};
const VotePlaceLayer = () => {
    useVotePlaceLayer();
    return null;
};
export default VotePlaceLayer;
