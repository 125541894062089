import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Heading, Stack, } from '@chakra-ui/react';
const GRPDcompliance = () => {
    const { t } = useTranslation();
    return (<Stack width="full">
      <Accordion allowToggle>
        <AccordionItem border="none" backgroundColor="colorMode.base" borderRadius="md">
          <AccordionButton padding={4}>
            <Box flex="1" textAlign="left">
              <Heading as="h5" variant="h5" fontWeight={700}>
                {t('tabs.plots.section.my_crm.GDPR.title')}
              </Heading>
            </Box>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel paddingBottom={4} textStyle="paragraph">
            {t('tabs.plots.section.my_crm.GDPR.description')}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Stack>);
};
export default GRPDcompliance;
