import * as React from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from '@chakra-ui/icons';
import { HStack, IconButton, Text, Tooltip, useMultiStyleConfig, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_MAP_BUTTONS } from 'analytics/useAnalytics';
import { useUpdateExtraFilter } from 'hooks/contexts/useFiltersContext';
import { useResetLocation } from 'hooks/contexts/useLocationContext';
import { useResetFeasibility } from 'hooks/contexts/useSecondaryPlotsContext';
import { useResetTabs } from 'hooks/contexts/useTabsContext';
import { filtersKey } from 'utils/filters';
const CloseAllTab = ({ isNarrowNavigation, }) => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const resetTabs = useResetTabs();
    const resetLocation = useResetLocation();
    const resetFeasibility = useResetFeasibility();
    const updateExtraFilter = useUpdateExtraFilter();
    const styles = useMultiStyleConfig('Tabs');
    const tabStyles = {
        ...styles.tab,
        borderColor: 'transparent!important',
        backgroundColor: 'colorMode.base',
        cursor: 'pointer',
    };
    const onClick = () => {
        resetTabs({ resetLayerTabs: true });
        resetLocation({});
        resetFeasibility();
        updateExtraFilter(`${filtersKey.AGRICULTURE_REARING_COMPANY}.codeAPE`, '');
        trackEvent({
            category: ANALYTICS_MAP_BUTTONS,
            action: 'Fermer tous les onglets',
        });
    };
    return (<HStack __css={tabStyles} alignItems="center" boxShadow="inset -4px 0px 4px -4px rgb(0 0 0 / 20%)" borderTopRadius="md" justifyContent="space-between" minHeight="45px" color="red.500" padding={2} onClick={onClick} data-cy="close_all_tabs">
      {!isNarrowNavigation && (<Text fontSize="sm" color="red.500" paddingRight={1} paddingTop="2px">
          {t('global.close')}
        </Text>)}
      <Tooltip label={isNarrowNavigation ? t('global.close') : ''} placement="left-start">
        <IconButton paddingTop="2px" flex={1} height="full" minWidth={'none'} marginRight={1} color="red.500" variant="link" border="none" icon={<CloseIcon fill="currentcolor" h="10px" w="10px"/>} aria-label={t('tabs.panel.up')} marginLeft={1}/>
      </Tooltip>
    </HStack>);
};
CloseAllTab.displayName = 'Tab';
export default memo(CloseAllTab);
