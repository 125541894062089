import * as React from 'react';
import { useCallback, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as iots from 'io-ts';
import { get } from 'api/client';
import MRAEInfoWindow, { IOMRAEInfo, } from 'components/infoWindow/MRAEInfoWindow';
import { useExtraFilters } from 'hooks/contexts/useFiltersContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import MRAEImg from 'images/POIImages/Pin_MRAE.png';
import { IOMarkerLayerItem } from 'types/api';
import { filtersKey, LAST_SIX_MONTHS } from 'utils/filters';
export const IOMRAEMarkerItem = iots.intersection([
    IOMarkerLayerItem,
    iots.type({
        id: iots.string,
        date: iots.string,
        typeId: iots.number,
    }),
], 'IOMRAEMarkerItem');
const fetchMRAE = () => () => get(iots.array(IOMRAEMarkerItem), `mrae`);
const fetchMRAEInfo = (item) => {
    return get(IOMRAEInfo, `mrae-info/${item.id}`);
};
export const formatInfoWindowContent = (info) => {
    const content = <MRAEInfoWindow info={info}/>;
    return ReactDOMServer.renderToStaticMarkup(content);
};
export const isTimePeriodAllowed = (marker, timePeriod) => {
    if (!timePeriod) {
        return true;
    }
    const date = new Date(marker.date);
    if (timePeriod === LAST_SIX_MONTHS) {
        const now = new Date();
        const diff = now.getTime() - date.getTime();
        const monthDiff = diff / (1000 * 3600 * 24 * 30);
        return monthDiff <= 6;
    }
    return date.getFullYear() === timePeriod;
};
const isTypeAllowed = (marker, typeId) => {
    if (!typeId) {
        return true;
    }
    return marker.typeId == typeId;
};
const useMRAELayer = () => {
    const extraFilters = useExtraFilters();
    const [markerInfo, setMarkerInfo] = useState({});
    const allowMarker = useCallback((marker) => {
        const date = extraFilters?.[filtersKey.MRAE]?.timePeriod ?? null;
        const typeId = extraFilters?.[filtersKey.MRAE]?.typeId ?? null;
        return isTimePeriodAllowed(marker, date) && isTypeAllowed(marker, typeId);
    }, [extraFilters]);
    const getInfoWindowContent = async (marker) => {
        if (markerInfo[marker.id]) {
            return markerInfo[marker.id];
        }
        const mraeInfo = await fetchMRAEInfo(marker);
        const content = formatInfoWindowContent(mraeInfo);
        setMarkerInfo({
            ...markerInfo,
            [marker.id]: content,
        });
        return content;
    };
    createLayersHook({
        fetch: fetchMRAE,
        markerImg: MRAEImg,
        getInfoWindowContent,
        allowLayer: allowMarker,
    })(filtersKey.MRAE);
};
const MRAELayer = () => {
    useMRAELayer();
    return null;
};
export default MRAELayer;
