import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getLayerCoordTileUrl } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = () => (coord, zoom) => {
    return getLayerCoordTileUrl('ENR.GRANDS.SITES.FRANCE', 'ENR.GRANDS.SITES.FRANCE', zoom, coord);
};
const getLegends = () => {
    const legends = {};
    legends.wind_large_french_site = {
        layerKey: filtersKey.WIND_LARGE_FRENCH_SITE,
        label: 'wind_large_french_site',
        needsTranslate: true,
        color: '#d0661c',
        borderColor: '#000000',
        borderWidth: 2,
    };
    return legends;
};
const useWindLargeFrenchSiteLayer = () => {
    const options = {
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.WIND_LARGE_FRENCH_SITE],
        getLegends,
    };
    createTilesLayerHook(options)(filtersKey.WIND_LARGE_FRENCH_SITE);
};
const WindLargeFrenchSiteLayer = () => {
    useWindLargeFrenchSiteLayer();
    return null;
};
export default WindLargeFrenchSiteLayer;
