import { useQuery } from '@tanstack/react-query';
import { post } from 'api/client';
const fetchAltimetry = (points) => () => post(null, 'altimetry', { points });
const useGetAltimetry = (points) => {
    return useQuery({
        queryKey: ['altimetry', { points }],
        queryFn: fetchAltimetry(points),
        enabled: !!points && points.length > 0,
    });
};
export default useGetAltimetry;
