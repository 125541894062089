export default () => {
    const isDebug = process.env.NODE_ENV === 'development';
    const showDebug = (children) => {
        if (isDebug) {
            return children;
        }
        return null;
    };
    return { showDebug, isDebug };
};
