import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
import useOnError from 'hooks/useOnError';
import { IOTeamProjectNotes } from 'types/api';
export const QUERY_KEY = 'team-project-notes';
export const fetchProjectNotes = (projectId) => () => get(iots.array(IOTeamProjectNotes), `project/${projectId}/notes/team`);
export const getTeamProjectNotesQueryKey = (projectId) => [
    QUERY_KEY,
    projectId,
];
const useTeamProjectNotes = (isEnabled = false, projectId) => {
    const onErrorCallback = useOnError();
    return useQuery({
        queryKey: getTeamProjectNotesQueryKey(projectId),
        queryFn: fetchProjectNotes(projectId),
        enabled: isEnabled && !!projectId,
        meta: { onError: onErrorCallback },
        retry: false,
    });
};
export default useTeamProjectNotes;
