import { useMemo } from 'react';
import useAnalytics, { ANALYTICS_PLOT_TAB } from 'analytics/useAnalytics';
import { useSearchResults } from 'hooks/contexts/usePlotSearchContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import useOpenPlotFromMap from 'hooks/plot/useOpenPlotFromMap';
import { filtersKey } from 'utils/filters';
const usePlotsSearchLayer = () => {
    const { trackEvent } = useAnalytics();
    const searchResults = useSearchResults();
    const openPlotFromMap = useOpenPlotFromMap();
    const providedData = useMemo(() => {
        return searchResults ? Object.values(searchResults) : null;
    }, [searchResults]);
    createLayersHook({
        providedData,
        onClick: (item) => {
            openPlotFromMap(item);
            trackEvent({
                category: ANALYTICS_PLOT_TAB,
                action: 'Afficher les parcelles Carte',
            });
        },
    })(filtersKey.PLOTS_SEARCH);
};
const PlotsSearchLayer = () => {
    usePlotsSearchLayer();
    return null;
};
export default PlotsSearchLayer;
