import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { post } from 'api/client';
import useCustomToast from './useCustomToast';
const sendEmail = (data) => post(null, `legacy/forgot-password`, data);
const useForgotPassword = (successCallback = null) => {
    const { t } = useTranslation();
    const { getSuccessToast } = useCustomToast();
    // Error is managed by useOnError in useAxiosConfig
    const onSuccessCallback = (data) => {
        getSuccessToast({
            title: t('forgotten_password.success', { email: data.email }),
            id: 'forgotten_password_success',
        });
        successCallback();
    };
    const { mutate: sendForgotPasswordEmail, isPending: isLoading } = useMutation({
        mutationFn: sendEmail,
        onSuccess: (data, values) => onSuccessCallback(values),
    });
    return { sendForgotPasswordEmail, isLoading };
};
export default useForgotPassword;
