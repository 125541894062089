import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
const IONewsCount = iots.type({
    freshNews: iots.number,
    news: iots.number,
});
export const QUERY_KEY = ['newsCount'];
const fetchNewsCount = () => () => get(IONewsCount, 'news/count');
const useNewsCount = () => {
    return useQuery({ queryKey: QUERY_KEY, queryFn: fetchNewsCount() });
};
export default useNewsCount;
