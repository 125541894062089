import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { IOPlotLayer } from 'types/api';
export const QUERY_KEY = 'project-layers';
export const getProjectLayersQueryKey = (projectId) => [
    QUERY_KEY,
    projectId,
];
const fetchProjectLayers = (projectId) => () => get(iots.array(IOPlotLayer), `project/${projectId}/layers`);
const useProjectLayers = (townshipId, projectId) => {
    const { data: projectLayers, isInitialLoading: isLoading } = useQuery({
        queryKey: getProjectLayersQueryKey(projectId),
        queryFn: fetchProjectLayers(projectId),
        enabled: !!projectId,
    });
    return {
        projectLayers,
        isLoading,
    };
};
export default useProjectLayers;
