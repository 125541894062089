import { useMemo } from 'react';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { useLayersTree } from 'hooks/contexts/useLayersTreeContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { getFilterNamesFromPathAndPrefix } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const LAYERS_PATH = 'regulatory.ppr.ppr_ground_movements';
const LAYERS_PREFIX = 'ppr_ground_movements_other';
const IOPPRGroundMovementsOtherLayerItem = IOLayerItem;
const fetchPPRGroundMovementsOther = (townId) => () => get(iots.array(IOPPRGroundMovementsOtherLayerItem), `legacy/${townId}/ppr-ground-movements-other`);
const usePPRGroundMovementsOtherLayer = () => {
    const layersTree = useLayersTree();
    const filterNames = useMemo(() => {
        return getFilterNamesFromPathAndPrefix(layersTree, LAYERS_PATH, LAYERS_PREFIX);
    }, [JSON.stringify(layersTree)]);
    createLayersHook({
        fetch: fetchPPRGroundMovementsOther,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filterNames);
};
const PPRGroundMovementsOtherLayer = () => {
    usePPRGroundMovementsOtherLayer();
    return null;
};
export default PPRGroundMovementsOtherLayer;
