export const hasData = (response) => {
    const regex = /<body>[\n\r\s]*<\/body>/g;
    return response.match(regex) === null;
};
const ID_REGEX = /<p>Identifiant : <strong>FR\d+<\/strong><\/p>/gm;
const URL_REGEX = /(?<start><p>.*)https:\/\/inpn.mnhn.fr\/site\/natura2000\/(?<id>FR9312025)(?<end>.*<\/p>)/gm;
const PDF_URL = 'https://inpn.mnhn.fr/docs/natura2000/fsdpdf/';
const SUMMARY_URL = 'https://inpn.mnhn.fr/site/natura2000/';
export const formatNatura2000Info = (response, t) => {
    let info = response.replaceAll('NATURA', 'Natura');
    info = info.replace(ID_REGEX, '');
    info = info.replace(URL_REGEX, (match, start, id, end) => {
        const pdfUrl = `${start}${PDF_URL}${id}.pdf${end}`.replaceAll('Fiche technique', t('info_window.natura_2000.pdf'));
        const summaryUrl = `${start}${SUMMARY_URL}${id}${end}`.replaceAll('Fiche technique', t('info_window.natura_2000.summary'));
        return pdfUrl + summaryUrl;
    });
    return info;
};
