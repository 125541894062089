import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOParkingSpaceExemptionLayerItem = IOLayerItem;
const fetchParkingSpaceCurrentExemptionLayer = (townId) => () => get(iots.array(IOParkingSpaceExemptionLayerItem), `legacy/${townId}/current-parking-space-exemption`);
const useParkingSpaceCurrentExemptionLayer = () => {
    createLayersHook({
        fetch: fetchParkingSpaceCurrentExemptionLayer,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.PARKING_SPACE_EXEMPTION_CURRENT);
};
const ParkingSpaceCurrentExemptionLayer = () => {
    useParkingSpaceCurrentExemptionLayer();
    return null;
};
export default ParkingSpaceCurrentExemptionLayer;
