import * as React from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { Heading, Stack, Text } from '@chakra-ui/react';
import Table from 'components/table/Table';
import usePlotHistory from 'hooks/crm/usePlotHistory';
import { formatDate } from 'utils/formatDate';
const getColumnHeader = (column) => (<Text align="center">
    <Translation>
      {(t) => t(`tabs.plots.section.my_crm.history.columns.${column}`)}
    </Translation>
  </Text>);
export const columns = [
    {
        id: 'columns',
        columns: [
            {
                accessorKey: 'date',
                header: () => getColumnHeader('date'),
                cell: (info) => formatDate(info.getValue()),
                enableColumnFilter: false,
                enableSorting: false,
            },
            {
                accessorKey: 'value',
                header: () => getColumnHeader('value'),
                cell: (info) => info.getValue(),
                enableColumnFilter: false,
                enableSorting: false,
            },
        ],
    },
];
const HistoryPlot = ({ plotId }) => {
    const { t } = useTranslation();
    const { data: plotHistory, isInitialLoading: isLoading } = usePlotHistory(plotId);
    return (<Stack width="full" spacing={3}>
      <Heading as="h4" variant="h4">
        {t('tabs.plots.section.my_crm.history.title')}
      </Heading>

      <Table id={`table-plot-${plotId}-history`} columns={columns} data={plotHistory || []} isLoading={isLoading} initialSort={[{ id: 'date', desc: true }]} withExport={false}/>
    </Stack>);
};
export default HistoryPlot;
