import * as React from 'react';
import { useEffect, useState } from 'react';
import { CADASTRE_ZOOM_LIMIT } from 'components/layers/CadastreLayer';
import useUserHistory from 'hooks/crm/useUserHistory';
import { useTranslation } from 'react-i18next';
import { Text } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_USER } from 'analytics/useAnalytics';
import { createContext } from 'use-context-selector';
import usePlotLayersById from 'hooks/plot/usePlotLayersById';
import { ADDRESS_FROM_CLICK } from 'hooks/useChangeTown';
import useCurrentSite from 'hooks/useCurrentSite';
import useCustomToast from 'hooks/useCustomToast';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import useTownByCoordinates from 'hooks/useTownByCoordinates';
import { cleanAllMarkerFromMapAndPanorama } from 'utils/map';
import { getDomain } from 'utils/site';
import { AUTH_DATA_KEY, SITE_ID_GESTION } from './AuthContext';
export const PLOT_SELECTION_FEASIBILITY = 'plot_selection_feasibility';
export const PLOT_SELECTION_PROJECT = 'plot_selection_project';
export const PLOT_SELECTION_INITIAL = 'plot_selection_initial';
export const LocationContext = createContext(null);
export const LocationProvider = ({ children }) => {
    const { t } = useTranslation();
    const [plotSelectionMode, setPlotSelectionMode] = useState(PLOT_SELECTION_INITIAL);
    const resetPlotSelectionMode = () => {
        setPlotSelectionMode(PLOT_SELECTION_INITIAL);
    };
    const isSecondarySelectionMode = [
        PLOT_SELECTION_FEASIBILITY,
        PLOT_SELECTION_PROJECT,
    ]?.includes(plotSelectionMode);
    const [displayedPlot, setDisplayedPlot] = useState(null);
    const [locatedProject, setLocatedProject] = useState(null);
    const [clickedCoord, setClickedCoord] = useState(null);
    const [townCoordinates, setTownCoordinates] = useState(null);
    const [shouldAddTownToHistory, setShouldAddTownToHistory] = useState(false);
    const [lastTownAddedToHistory, setLastTownAddedToHistory] = useState(null);
    const [formattedAddress, setFormattedAddress] = useState(null);
    const [selectedTown, setSelectedTown] = useState(null);
    const [towns, setTowns] = useState({});
    const [layers, setLayers] = useState({});
    const { addAddressToHistory } = useUserHistory(false);
    const [drawingMode, setDrawingMode] = useState(null);
    const { currentSite, setCurrentSite } = useCurrentSite();
    const { getInfoToast, closeToast } = useCustomToast();
    const { trackEvent } = useAnalytics();
    const { hasPlotTab } = useSiteConfiguration();
    const [activeUserDrawing, setActiveUserDrawing] = useState(null);
    const toggleDrawingMode = (mode) => {
        if (drawingMode === mode || mode === null) {
            setDrawingMode(null);
            return;
        }
        setDrawingMode(mode);
    };
    const { isInitialLoading: isLoadingTown, error: townError, data: townData, } = useTownByCoordinates({
        coordinates: townCoordinates,
        enabled: !!townCoordinates,
        errorCallback: () => {
            setSelectedTown(null);
            setTownCoordinates(undefined);
        },
    });
    useEffect(() => {
        if (townData) {
            handleSelectTown(townData);
            setTownCoordinates(undefined);
            let newSite;
            // En fonction de l'accès ou non à la ville on charge la configuration du site gestion ou du site courant
            // Récupération de l'ensemble des configurations
            const authData = JSON.parse(localStorage.getItem(AUTH_DATA_KEY));
            const toastId = 'forbidden-town-error-limited';
            if (!townData.accessGranted) {
                // On va chercher le site gestion
                newSite = authData.data.sites.find((site) => site.legacyId == SITE_ID_GESTION);
                getInfoToast({
                    id: toastId,
                    title: t('error.restricted_town.title'),
                    variant: 'subtle',
                    description: (<>
              <Text marginTop={2}>
                {t('error.restricted_town_access_limited.description')}
              </Text>
              <Text marginTop={2}>
                {t('error.restricted_town_access_limited.description_2')}
              </Text>
              <Text marginTop={2}>
                {t('error.restricted_town_access_limited.greetings')}
              </Text>
            </>),
                    status: 'info',
                    position: 'top',
                    duration: null,
                    isClosable: true,
                });
                trackEvent({
                    category: ANALYTICS_USER,
                    action: 'Demande accès à une commune non autorisée',
                });
            }
            else {
                const fullDomain = getDomain();
                // On va chercher le site courrant
                newSite = authData.data.sites.find((site) => site.url == fullDomain);
                closeToast(toastId, 'info');
            }
            // On va faire le changement que si le nouveau site est gestion ou si le site actuel est gestion
            // ainsi on pourra changer de site sans le perdre
            if (newSite &&
                currentSite.legacyId !== newSite.legacyId &&
                (newSite.legacyId === SITE_ID_GESTION ||
                    currentSite.legacyId === SITE_ID_GESTION)) {
                // Application de la nouvelle configuration
                setCurrentSite(newSite);
            }
        }
    }, [townData]);
    const { isInitialLoading: isLoadingPlotLayers } = usePlotLayersById({
        plot: displayedPlot,
        enabled: !!displayedPlot && !!selectedTown?.id && hasPlotTab('plot_rules'),
        selectedTown,
        successCallback: (data) => {
            if (data?.length && !layers[displayedPlot?.id]) {
                setLayers({ ...layers, [displayedPlot?.id]: data });
            }
        },
    });
    const resetSelectedTown = () => {
        setTownCoordinates(null);
        setTowns({});
        setFormattedAddress(null);
        setSelectedTown(null);
        cleanAllMarkerFromMapAndPanorama();
    };
    const handleSelectTown = (data) => {
        if (data?.id) {
            setSelectedTown(data);
            setTowns({ ...towns, [data.id]: data });
            let address = formattedAddress;
            if (!formattedAddress || formattedAddress === ADDRESS_FROM_CLICK) {
                address = data.name;
                setFormattedAddress(address);
            }
            if (!shouldAddTownToHistory || lastTownAddedToHistory === data.id) {
                return;
            }
            addAddressToHistory({
                townId: data.id,
                address,
                lat: townCoordinates?.lat().toString(),
                lng: townCoordinates?.lng().toString(),
                acuteness: CADASTRE_ZOOM_LIMIT,
            });
            setShouldAddTownToHistory(false);
            setLastTownAddedToHistory(data.id);
        }
    };
    const resetLocation = ({ resetTown }) => {
        setClickedCoord(null);
        setPlotSelectionMode(null);
        if (resetTown) {
            resetSelectedTown();
        }
    };
    return (<LocationContext.Provider value={{
            layers,
            plotSelectionMode,
            setPlotSelectionMode,
            selectedTown,
            handleSelectTown,
            townError,
            clickedCoord,
            setClickedCoord,
            townCoordinates,
            setTownCoordinates,
            setShouldAddTownToHistory,
            lastTownAddedToHistory,
            setLastTownAddedToHistory,
            formattedAddress,
            setFormattedAddress,
            isLoadingTown,
            towns,
            resetLocation,
            clearTown: () => setSelectedTown(undefined),
            isLoadingPlotLayers,
            resetPlotSelectionMode,
            isSecondarySelectionMode,
            displayedPlot,
            setDisplayedPlot,
            locatedProject,
            setLocatedProject,
            drawingMode,
            toggleDrawingMode,
            activeUserDrawing,
            setActiveUserDrawing,
        }}>
      {children}
    </LocationContext.Provider>);
};
export default LocationContext;
