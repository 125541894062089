import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';
import * as iots from 'io-ts';
export const IOWasteInfo = iots.type({
    openingDate: iots.string,
    name: iots.string,
    typeAct: iots.string,
    manageMode: iots.string,
});
const WasteInfoWindow = ({ info, filterKey, }) => {
    const { t } = useTranslation();
    if (!info) {
        return null;
    }
    return (<Box maxHeight="400px" maxWidth="400px">
      <Box>
        {t('waste.type.label')} : {t('waste.type.' + filterKey)}
      </Box>
      {info.name && (<Box>
          {t('waste.name')} : <strong>{info.name}</strong>
        </Box>)}
      {info.openingDate && (<Box>
          {t('waste.opening_date')} : {info.openingDate}
        </Box>)}
      {info.typeAct && (<Box>
          {t('waste.type_act')} : {info.typeAct}
        </Box>)}
      {info.manageMode && (<Box>
          {t('waste.manage_mode')} : {info.manageMode}
        </Box>)}
    </Box>);
};
export default WasteInfoWindow;
