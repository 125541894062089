import * as iots from 'io-ts';
import { get } from 'api/client';
import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getBoundingBoxForLatLng, getTileBoundingBox } from 'utils/geo';
import { hasData } from 'utils/ign';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = (map, tileWidth, tileHeight) => (coords, zoom) => {
    const boundingBox = getTileBoundingBox(coords, zoom, map, tileWidth, tileHeight);
    return ('https://data.geopf.fr/wms-v/ows?' +
        'SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap' +
        '&FORMAT=' +
        encodeURIComponent('image/png') +
        '&TRANSPARENT=TRUE&LAYERS=MESURES_COMPENSATOIRES' +
        '&CRS=EPSG%3A4326&STYLES=&WIDTH=' +
        tileWidth +
        '&HEIGHT=' +
        tileHeight +
        '&BBOX=' +
        encodeURIComponent(boundingBox));
};
const INFO_SIZE = 101;
const INFO_POS = 50; // INFO_SIZE / 2
const fetchInfoForCoords = (coords, map) => {
    const boundingBox = getBoundingBoxForLatLng(coords, map.getZoom(), map, INFO_SIZE, INFO_SIZE);
    return get(iots.string, 'https://data.geopf.fr/wms-v/ows?' +
        'SERVICE=WMS&VERSION=1.3.0&REQUEST=GetFeatureInfo' +
        '&FORMAT=' +
        encodeURIComponent('image/png') +
        '&TRANSPARENT=TRUE&QUERY_LAYERS=MESURES_COMPENSATOIRES&LAYERS=MESURES_COMPENSATOIRES' +
        '&INFO_FORMAT=' +
        encodeURIComponent('text/html') +
        '&I=' +
        INFO_POS +
        '&J=' +
        INFO_POS +
        '&CRS=EPSG%3A3857&STYLES=&WIDTH=' +
        INFO_SIZE +
        '&HEIGHT=' +
        INFO_SIZE +
        '&BBOX=' +
        encodeURIComponent(boundingBox), {}, true, {}, false).then((response) => {
        return hasData(response) ? response : '';
    });
};
const getInfoWindowContent = () => async (coords, map) => {
    return await fetchInfoForCoords(coords, map);
};
const getLegends = () => {
    const legends = {};
    legends.compensatory_measures_for_biodiversity_damage = {
        layerKey: filtersKey.COMPENSATORY_MEASURES_FOR_BIODIVERSITY_DAMAGE,
        label: 'compensatory_measures_for_biodiversity_damage',
        needsTranslate: true,
        color: '#4d3bf3',
        opacity: 0.8,
        borderColor: '#4d3bf3',
    };
    return legends;
};
const useCompensatoryMeasuresForBiodiversityDamage = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.COMPENSATORY_MEASURES_FOR_BIODIVERSITY_DAMAGE],
        getLegends,
        getInfoWindowContent,
    })(filtersKey.COMPENSATORY_MEASURES_FOR_BIODIVERSITY_DAMAGE);
};
const CompensatoryMeasuresForBiodiversityDamageLayer = () => {
    useCompensatoryMeasuresForBiodiversityDamage();
    return null;
};
export default CompensatoryMeasuresForBiodiversityDamageLayer;
