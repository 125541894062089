import * as React from 'react';
import { useState } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { Box, Button, Heading, HStack, Stack, Text } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_COURIER, ANALYTICS_CRM, } from 'analytics/useAnalytics';
import ActivityCreateButton from 'components/buttons/ActivityCreateButton';
import ContactButton from 'components/buttons/ContactButton';
import DeleteButton from 'components/buttons/DeleteButton';
import StatusSelect from 'components/inputs/StatusSelect';
import BindContactDrawer from 'components/modals/contactModal/BindContactDrawer';
import Table from 'components/table/Table';
import ActivityDrawer from 'components/tabs/activities/ActivityDrawer';
import CourierCreateButton from 'components/tabs/couriers/CourierCreateButton';
import CourierDrawer from 'components/tabs/couriers/courierForm/CourierDrawer';
import useContact from 'hooks/crm/useContact';
import usePlotContact from 'hooks/crm/usePlotContact';
import usePlotProjectsList from 'hooks/crm/usePlotProjectsList';
import useCustomToast from 'hooks/useCustomToast';
import { FROM_CONTACT, getPlotContactStatusFromCode, PLOT_CONTACT_STATUS_OPTIONS, } from 'utils/constants';
import { formatDate } from 'utils/formatDate';
import { inDateRange } from 'utils/search';
const getColumnHeader = (column) => (<Text align="center">
    {column && (<Translation>
        {(t) => t(`tabs.plots.section.my_crm.contact.columns.${column}`)}
      </Translation>)}
  </Text>);
export const TITLE_COLUMN = {
    accessorKey: 'title',
    header: () => getColumnHeader('title'),
    cell: (info) => <Text align="center">{info.getValue()}</Text>,
    filterFn: 'arrIncludesSome',
};
export const CREATED_AT_COLUMN = {
    accessorKey: 'createdAt',
    header: () => getColumnHeader('creation_date'),
    cell: (info) => formatDate(info.getValue()),
    enableGlobalFilter: false,
    filterFn: inDateRange,
};
const getValuesContactForCourier = (contact) => {
    return {
        file: null,
        contactId: contact?.id,
        civility: contact?.titleStr,
        firstname: contact?.firstname,
        lastname: contact?.lastname,
        company: contact?.company ?? null,
        address: contact?.address,
        addressBP: contact?.addressBP,
        addressComplement: contact?.addressComplement,
        postalCode: contact?.postalCode,
        town: contact?.town,
    };
};
const ContactList = ({ plot, townId, showAgenda = true, showCourier = true, }) => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const { id: plotId } = plot;
    const { plotContacts, isLoading } = usePlotContact(plotId);
    const { addContactToPlot, removeContactFromPlots, isDeletingContact } = useContact();
    const { updatePlotContact } = usePlotContact();
    const { plotProjects } = usePlotProjectsList(plotId);
    const [activityInfo, setActivityInfo] = React.useState();
    const [courierInfo, setCourierInfo] = useState();
    const { getSuccessToast } = useCustomToast();
    const columns = React.useMemo(() => {
        return [
            {
                id: 'columns',
                columns: [
                    {
                        accessorKey: 'lastname',
                        header: () => getColumnHeader('owner'),
                        cell: (info) => {
                            return info.getValue() ? (<ContactButton contactId={info.row.original.id} analyticOrigin={ANALYTICS_CRM} analyticDetail="Ouvrir fiche propriétaire" id="contact_button"/>) : ('');
                        },
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'title',
                        accessorFn: (row) => {
                            if (!row.titleStr)
                                return t('table.empty_column');
                            return row.titleStr;
                        },
                        header: () => getColumnHeader('title'),
                        cell: ({ row }) => (<Text align="center">{row.original.titleStr}</Text>),
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        id: 'plotContactStatus',
                        accessorFn: (row) => getPlotContactStatusFromCode(row.plotContactStatus),
                        header: () => getColumnHeader('owner_status'),
                        cell: (info) => {
                            return info.row.original.plotContactId ? (<StatusSelect data={PLOT_CONTACT_STATUS_OPTIONS} onChange={(value) => {
                                    updatePlotContact({
                                        plotId: info.row.original.plotId,
                                        plotContactId: info.row.original.plotContactId,
                                        status: value.value,
                                        comment: info.row.original.comment,
                                        lots: info.row.original.lots,
                                        groundSurface: info.row.original.groundSurface,
                                    });
                                    trackEvent({
                                        category: ANALYTICS_CRM,
                                        action: 'Statut du propriétaire onglet mon crm',
                                    });
                                }} value={info.row.original.plotContactStatus}/>) : null;
                        },
                        filterFn: 'arrIncludesSome',
                    },
                    CREATED_AT_COLUMN,
                    {
                        accessorKey: 'plotContactId',
                        header: () => '',
                        cell: (info) => {
                            const { t } = useTranslation();
                            return (<HStack>
                  {showAgenda && (<Box>
                      <ActivityCreateButton disabled={!info.row.original.id} activityCreate={{
                                        plotId: plot?.id,
                                        cadastreId: plot?.cadastreId,
                                        contactId: info.row.original.id,
                                        projectId: null,
                                    }} setActivityInfo={setActivityInfo} icon={true}/>
                    </Box>)}
                  <DeleteButton mode="icon" id="delete_plot_contact" confirmText={t('tabs.plots.section.my_crm.contact.confirm')} handleDelete={() => removeContactFromPlots({
                                    contactId: info.row.original.id,
                                    plotIds: [info.row.original.plotId],
                                    townId: townId,
                                    plotProjectsIds: plotProjects.map((project) => project.id),
                                }, {
                                    onSuccess: () => {
                                        trackEvent({
                                            category: ANALYTICS_CRM,
                                            action: 'Supprimer un contact',
                                        });
                                        getSuccessToast({
                                            title: t('tabs.contact.delete_success', {
                                                count: 1,
                                            }),
                                            id: 'delete_contact',
                                        });
                                    },
                                })} disabled={isDeletingContact} size="sm"/>
                  {showCourier && (<CourierCreateButton mode="icon" size="sm" courierInfoDefault={getValuesContactForCourier(info.row.original)} setCourierInfo={setCourierInfo} analyticsCategory={ANALYTICS_COURIER} analyticsDetail="Créer un courier dans les contact d'une parcelle" fromPanel={FROM_CONTACT}/>)}
                </HStack>);
                        },
                        enableGlobalFilter: false,
                        enableColumnFilter: false,
                        enableSorting: false,
                    },
                ],
            },
        ];
    }, [plotContacts, plotProjects, showAgenda]);
    return (<Stack spacing={3} width="100%">
      <HStack>
        <Heading as="h4" variant="h4">
          {t('tabs.plots.section.my_crm.contact.title')}
        </Heading>

        <BindContactDrawer triggerItem={(onOpen) => (<Button variant="outline" size="sm" onClick={() => {
                onOpen();
                trackEvent({
                    category: ANALYTICS_CRM,
                    action: 'Ajouter un contact',
                });
            }} data-cy="add_contact_button">
              {t('tabs.plots.section.my_crm.contact.add_contact')}
            </Button>)} onSubmit={(contactId, onClose) => {
            addContactToPlot({
                plotId: plotId,
                contactId: contactId,
                townId: townId,
                plotProjectsIds: plotProjects.map((project) => project.id),
            }, {
                onSuccess: onClose,
            });
        }}/>
      </HStack>

      <Table id={`table_plot_${plotId}_contacts`} columns={columns} data={plotContacts || []} exportTitle={t('tabs.extra_tabs.contact_list.tab_title')} isLoading={isLoading} withExport={false}/>
      <ActivityDrawer activityInfo={activityInfo} handleClose={() => setActivityInfo(undefined)}/>
      <CourierDrawer courierInfo={courierInfo} handleClose={() => setCourierInfo(undefined)}/>
    </Stack>);
};
export default ContactList;
