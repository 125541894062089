import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchCompanyOwnedPlots, } from 'components/tabs/filterTabs/CompanyOwnedPlotsPanel';
import { useExtraFilters } from 'hooks/contexts/useFiltersContext';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import { filtersKey } from 'utils/filters';
const CompanyOwnedPlotsLayer = () => {
    const extraFilters = useExtraFilters();
    const selectedTown = useSelectedTown();
    const townId = selectedTown?.id ? selectedTown?.id : '0';
    const sirens = extraFilters[filtersKey.COMPANY_OWNED_PLOTS]?.sirens;
    const { data } = useQuery({
        queryKey: ['company-owned-plots', { sirens, townId }],
        queryFn: fetchCompanyOwnedPlots(sirens, townId),
        enabled: sirens?.length > 0,
    });
    const layers = useMemo(() => (data?.layers ? Object.values(data.layers) : null), [data]);
    createLayersHook({
        providedData: layers,
    })(filtersKey.COMPANY_OWNED_PLOTS);
    return null;
};
export default CompanyOwnedPlotsLayer;
