import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { IOEmpty, IOPolygonLayerItem } from 'types/api';
const IoOwnerInfo = iots.record(iots.string, iots.union([IOEmpty, iots.record(iots.string, iots.unknown)]));
export const IOStaff = iots.type({
    id: iots.string,
    name: iots.string,
    firstname: iots.string,
    dateBirth: iots.string,
    age: iots.union([iots.null, iots.number]),
    role: iots.string,
});
const IOOwner = iots.type({
    id: iots.string,
    name: iots.string,
    siren: iots.string,
    group: iots.string,
    form: iots.string,
    ape: iots.union([iots.null, iots.string]),
    streetNumber: iots.string,
    street: iots.string,
    postalCode: iots.string,
    town: iots.string,
    info: IoOwnerInfo,
    staff: iots.union([iots.array(IOStaff), iots.undefined]),
});
const IOPlotOwnersInfo = iots.type({
    2019: iots.type({
        ground: iots.array(IOOwner),
        building: iots.array(IOOwner),
    }),
    2020: iots.type({
        ground: iots.array(IOOwner),
        building: iots.array(IOOwner),
    }),
    2021: iots.type({
        ground: iots.array(IOOwner),
        building: iots.array(IOOwner),
    }),
    2022: iots.type({
        ground: iots.array(IOOwner),
        building: iots.array(IOOwner),
    }),
    2023: iots.type({
        ground: iots.array(IOOwner),
        building: iots.array(IOOwner),
    }),
    layers: iots.array(iots.intersection([
        IOPolygonLayerItem,
        iots.type({
            plotId: iots.string,
            ownerId: iots.string,
        }),
    ])),
});
const fetchPlotOwnersInfo = (townshipId, cadastreId) => () => get(IOPlotOwnersInfo, `${townshipId}/plot-owners/${cadastreId}`);
export const getQueryKey = (hasOwnersInfo = false, townshipId, cadastreId) => ['plot-owners', hasOwnersInfo, townshipId, cadastreId];
const usePlotOwnersInfo = (hasOwnersInfo = false, selectedPlot, enabled) => {
    const townshipId = selectedPlot?.townId;
    const cadastreId = selectedPlot?.cadastreId;
    const { data: ownersByYear, isInitialLoading } = useQuery({
        queryKey: getQueryKey(hasOwnersInfo, townshipId, cadastreId),
        queryFn: fetchPlotOwnersInfo(townshipId, cadastreId),
        enabled: hasOwnersInfo && !!townshipId && !!cadastreId && enabled,
    });
    let countOwner = 0;
    if (ownersByYear) {
        const years = Object.keys(ownersByYear);
        let lastyear = 2022;
        // Search last year
        years.forEach((year) => {
            const currentYear = parseInt(year);
            if (!isNaN(currentYear) && lastyear < currentYear) {
                lastyear = currentYear;
            }
        });
        if (ownersByYear[lastyear]) {
            countOwner = ownersByYear[lastyear].nbOwner;
        }
    }
    const layersWithoutCurrentPlot = useMemo(() => ownersByYear?.layers?.filter((layer) => {
        return layer.plotId !== selectedPlot?.id;
    }), [ownersByYear, selectedPlot?.id]);
    return {
        ownersByYear,
        isInitialLoading,
        countOwner,
        layers: layersWithoutCurrentPlot,
    };
};
export default usePlotOwnersInfo;
