import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_CATEGORY_DOWNLOAD, } from 'analytics/useAnalytics';
import pick from 'lodash/pick';
import ExportButton, { ExportItemButton } from 'components/buttons/ExportButton';
import { exportDataToXLSX } from 'components/export/excelUtils';
import { exportDataToPdf } from 'components/export/pdfUtils';
import { getAllSectionsSheets, getSingleSectionSheets, } from 'components/export/plot/plotExportUtils';
import { useLayers, useSelectedTown, useTowns, } from 'hooks/contexts/useLocationContext';
import { useGetFeasibilityPlots } from 'hooks/contexts/useSecondaryPlotsContext';
import useNeighborhood from 'hooks/plot/useNeighborhood';
import usePlotBuildings from 'hooks/plot/usePlotBuildings';
import usePlotFeasibility from 'hooks/plot/usePlotFeasibility';
import usePlotOccupants from 'hooks/plot/usePlotOccupants';
import usePlotOwnersInfo from 'hooks/plot/usePlotOwnersInfo';
import usePlotPluRegulation from 'hooks/plot/usePlotPluRegulation';
import usePlotSales from 'hooks/plot/usePlotSales';
import useCurrentSite from 'hooks/useCurrentSite';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { PLOT_PLOT_TAB, PLU_REGULATION_PLOT_TAB, } from 'types/api';
import { isCompletelyEmpty } from 'utils/isEmptyExtend';
import { getPlotExportName, SECTIONS } from 'utils/plots';
import { DEFAULT_VALUES, getFeasibilityDefaultValues, } from './PlotSections/feasibility/calculator';
import { getFeasibilityWithCadastre } from './PlotSections/feasibility/PlotFeasibility';
const PlotExport = ({ plot, activeSection, disabled, }) => {
    const { t, i18n } = useTranslation();
    const towns = useTowns();
    const { trackGetNameExport, trackEvent } = useAnalytics();
    const { currentSite } = useCurrentSite();
    const { hasPlotTab } = useSiteConfiguration();
    const { plotFeasibility, isLoadingPlotFeasibility } = usePlotFeasibility(plot.townId, plot.id, false);
    const { occupants: occupant, isInitialLoading: isLoadingOccupants } = usePlotOccupants(plot, hasPlotTab('occupant'));
    const { pluRegulation, isLoadingPluRegulation } = usePlotPluRegulation(plot.townId, plot.id, plot.lat, plot.lng);
    const { ownersByYear: owners, isInitialLoading: isLoadingOwners } = usePlotOwnersInfo(currentSite?.ownerSubscription, plot, hasPlotTab('owner'));
    const { data: buildings, isInitialLoading: isLoadingBuildings } = usePlotBuildings(plot?.id, plot?.townId, hasPlotTab('building'));
    const { data: sales, isInitialLoading: isLoadingSales } = usePlotSales(plot.townId, plot.id, hasPlotTab('sales'));
    const { data: neighborhood, isInitialLoading: isLoadingNeighborhood } = useNeighborhood(plot.lat, plot.lng, hasPlotTab('neighborhood'));
    const loadingMap = {
        feasibility: isLoadingPlotFeasibility,
        plu_regulation: isLoadingPluRegulation,
        owner: isLoadingOwners,
        building: isLoadingBuildings,
        sales: isLoadingSales,
        neighborhood: isLoadingNeighborhood,
        occupant: isLoadingOccupants,
    };
    const mapData = {
        feasibility: plotFeasibility,
        plu_regulation: pluRegulation,
        owner: owners,
        building: buildings,
        sales,
        neighborhood,
        plot,
        plot_rules: plot,
        occupant,
    };
    const isLoadingActiveSection = loadingMap[activeSection];
    // Pour le CRM pas d'export actuellement
    const isDisabledSection = isCompletelyEmpty(mapData[activeSection]);
    const isLoadingSome = Object.values(loadingMap).some((loading) => loading);
    const getFeasibilityPlots = useGetFeasibilityPlots();
    const feasibilityPlots = getFeasibilityPlots(plot.id);
    const fileName = getPlotExportName(plot, towns[plot.townId]?.name);
    const { getValues, reset } = useFormContext();
    const layers = useLayers();
    const selectedTown = useSelectedTown();
    // Initialize feasibility calculator context here to enable export even when tab has not been opened
    React.useEffect(() => {
        // Set PlotFeasibilityFormData
        if (plotFeasibility && !!feasibilityPlots) {
            const feasibilityWidthCadastre = getFeasibilityWithCadastre(plotFeasibility, plot);
            reset(getFeasibilityDefaultValues({
                feasibility: feasibilityWidthCadastre,
                feasibilityPlots,
            }));
        }
        else if (!plotFeasibility) {
            const noFeasibility = DEFAULT_VALUES;
            noFeasibility.constructibleSurface = plot.floorArea
                ? plot.floorArea
                : plot.surface;
            reset(noFeasibility);
        }
    }, [feasibilityPlots, plotFeasibility]);
    const plotData = {
        activeSection,
        plot,
        plot_rules: plot,
        feasibility: {
            ...plotFeasibility,
            //We need to get last version of plot data when we export (ex feasibility secondary plots can be modified)
            formData: getValues(),
        },
        feasibilityPlots,
        pluRegulation: hasPlotTab(PLU_REGULATION_PLOT_TAB)
            ? pluRegulation
            : {
                ...pluRegulation,
                regulations: pick(pluRegulation?.regulations, ['plu_zone']),
            },
        owners,
        buildings,
        sales,
        neighborhood,
        occupant,
        towns,
        plotLayers: layers[plot?.id],
        t,
        selectedTown,
        i18n,
    };
    const exportAnalyticsEvent = {
        category: ANALYTICS_CATEGORY_DOWNLOAD,
        action: trackGetNameExport(activeSection),
    };
    const exportAllData = (type) => {
        // Plot Info and Rules export same content don't duplicate it
        const sectionFiltered = Object.keys(SECTIONS).filter((section) => section !== PLOT_PLOT_TAB &&
            (hasPlotTab(section) || section === PLU_REGULATION_PLOT_TAB));
        let action = 'Onglet Tout exporter';
        if (type === 'XLSX') {
            action = 'Onglet Tout exporter Excel';
            exportDataToXLSX(fileName, getAllSectionsSheets(sectionFiltered, plotData));
        }
        else {
            exportDataToPdf(fileName, getAllSectionsSheets(sectionFiltered, plotData));
        }
        trackEvent({ category: ANALYTICS_CATEGORY_DOWNLOAD, action });
    };
    return (<Box float="right">
      <ExportButton isDisabled={disabled || isLoadingActiveSection} isDisabledCurrent={isDisabledSection} fileName={fileName} analyticsEvent={exportAnalyticsEvent} getSheets={() => getSingleSectionSheets(plotData)}>
        <ExportItemButton isDisabled={isLoadingSome} onExport={() => exportAllData('PDF')} id="export_all_pdf_button">
          {t('tabs.plots.export.export_all_as_pdf')}
        </ExportItemButton>
        <ExportItemButton isDisabled={isLoadingSome} onExport={() => exportAllData('XLSX')} id="export_all_xlsx_button">
          {t('tabs.plots.export.export_all_as_xls')}
        </ExportItemButton>
      </ExportButton>
    </Box>);
};
export default PlotExport;
