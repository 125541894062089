import * as React from 'react';
import { Box, Spinner, Text } from '@chakra-ui/react';
export const PlotInfoMultiText = ({ values, label, id, isLoading = false, }) => {
    let key = 0;
    return (<Box data-cy={id}>
      <Text textStyle="paragraph" as="span" fontWeight={600}>
        {label}
        {' : '}
      </Text>
      {isLoading ? (<Spinner size="sm"/>) : values.length === 1 ? (<Text textStyle="paragraph" as="span">
          {values[0]}
        </Text>) : (values.map((value) => {
            key += 1;
            return (<Text key={key} textStyle="paragraph">
              - {value}
            </Text>);
        }))}
    </Box>);
};
export default PlotInfoMultiText;
