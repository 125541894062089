export const getPluRegulationFieldName = ({ fieldName, }) => {
    return fieldName.split('/')[1];
};
export const formatPluRegulationCellValue = ({ cellValue, fieldName }) => {
    if (fieldName === 'zone' || fieldName === 'subZone') {
        return cellValue.slice(1, cellValue.length - 1).replace(';', ' ');
    }
    return cellValue.value;
};
export const formatPluRegulation = ({ pluRegulation }) => {
    if (!pluRegulation)
        return null;
    const regulations = {};
    Object.keys(pluRegulation.regulations).forEach((category) => {
        pluRegulation.regulations[category].forEach((regulation) => {
            regulations[`${category}/${regulation.type}`] = regulation;
        });
    });
    return [regulations];
};
