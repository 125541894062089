import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOSensitiveNaturalAreaLayerItem = IOLayerItem;
const fetchSensitiveNaturalArea = (townId) => () => get(iots.array(IOSensitiveNaturalAreaLayerItem), `legacy/${townId}/sensitive-natural-area`);
const useSensitiveNaturalAreaLayer = () => {
    createLayersHook({
        fetch: fetchSensitiveNaturalArea,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.SENSITIVE_NATURAL_AREA);
};
const SensitiveNaturalAreaLayer = () => {
    useSensitiveNaturalAreaLayer();
    return null;
};
export default SensitiveNaturalAreaLayer;
