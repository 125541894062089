import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getLayerCoordTileUrl } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = () => (coord, zoom) => {
    return getLayerCoordTileUrl('PROTECTEDAREAS.RNCF', 'normal', zoom, coord);
};
const getLegends = () => {
    const legends = {};
    legends.hunting_reserve = {
        layerKey: filtersKey.HUNTING_RESERVE,
        label: 'hunting_reserve',
        needsTranslate: true,
        color: '#AB4124',
        opacity: 1,
    };
    return legends;
};
const useHuntingReserveLayer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.HUNTING_RESERVE],
        getLegends,
    })(filtersKey.HUNTING_RESERVE);
};
const HuntingReserveLayer = () => {
    useHuntingReserveLayer();
    return null;
};
export default HuntingReserveLayer;
