import * as React from 'react';
import { useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Heading, Input, Textarea } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_LAND_POINT } from 'analytics/useAnalytics';
import useCustomToast from 'hooks/useCustomToast';
import useLandPoint from 'hooks/useLandPoint';
const LandPointReportForm = ({ landPointReports, landPoint, projectId, }) => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const { getErrorToast } = useCustomToast();
    const formRef = useRef(null);
    const { saveLandPointProjectReport } = useLandPoint({
        landPointId: landPoint.id,
        toLoad: 'reports',
    });
    const methods = useForm({
        defaultValues: landPointReports && Object.values(landPointReports).length > 0
            ? {
                comment: landPointReports[projectId]?.comment,
                projectId: projectId,
                landPointId: projectId,
            }
            : {
                comment: '',
                projectId: projectId,
                landPointId: landPoint.id,
            },
        mode: 'onSubmit',
    });
    const { handleSubmit, register, setValue } = methods;
    useEffect(() => {
        if (!landPointReports || !projectId || !landPoint) {
            return;
        }
        setValue('landPointId', landPoint.id);
        setValue('projectId', projectId);
        setValue('comment', landPointReports[projectId]?.comment);
    }, [projectId, landPoint, landPointReports]);
    const handleBlur = () => {
        if (formRef.current) {
            const formData = new FormData(formRef.current);
            const formValues = Object.fromEntries(formData.entries());
            onSubmit(formValues);
        }
    };
    const onSubmit = (form) => {
        if (!form.comment) {
            getErrorToast({
                title: t('land_point.report.empty'),
                description: t('land_point.report.empty_description'),
                id: 'land_point_report_empty',
            });
            return;
        }
        saveLandPointProjectReport({
            landPointId: form.landPointId,
            projectId: form.projectId,
            comment: form.comment,
        });
        trackEvent({
            category: ANALYTICS_LAND_POINT,
            action: 'Sauvegarder le compte rendu',
        });
    };
    return !projectId ? (<Box display="flex" height="100%" alignItems="center" fontWeight="bold" textAlign="center">
      {t('land_point.report.select_project')}
    </Box>) : (<FormProvider {...methods}>
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
        <Heading as="h4" variant="h4" padding={1} backgroundColor="colorMode.background700" lineHeight="20px" textAlign="center">
          {landPoint.closedAt
            ? t('land_point.report.form_title_enclose')
            : t('land_point.report.form_title')}
        </Heading>
        <Box padding={2} height="calc(100% - 28px)">
          <Textarea {...register('comment')} height="100%" minHeight="auto" placeholder={t('land_point.report.placeholder')} padding={1} onBlur={handleBlur}/>
        </Box>
        <Input type="hidden" {...register('landPointId')}/>
        <Input type="hidden" {...register('projectId')}/>
      </form>
    </FormProvider>);
};
export default LandPointReportForm;
