import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox } from '@chakra-ui/react';
// Chakra UI issue with reset
// cf https://github.com/chakra-ui/chakra-ui/issues/6552
const SearchCheckbox = ({ name, ...props }) => {
    const { control } = useFormContext();
    return (<Controller name={name} control={control} render={({ field }) => (<Checkbox {...props} isChecked={field.value} onChange={(event) => {
                field.onChange(event.target.checked);
            }}/>)}/>);
};
export default SearchCheckbox;
