import { useNavigate } from 'react-router-dom';
import { FILTER_TAB_TYPE, PLOT_TAB_TYPE, PROJECT_TAB_TYPE, } from 'context/TabsContext';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { usePrecision } from 'hooks/contexts/useMapContext';
import { usePlots, useProjects, useSectionHash, } from 'hooks/contexts/useTabsContext';
import useNavigateToTown from 'hooks/navigation/useNavigateToTown';
export default () => {
    const plots = usePlots();
    const projects = useProjects();
    const navigateToTown = useNavigateToTown();
    const selectedTown = useSelectedTown();
    const navigate = useNavigate();
    const precision = usePrecision();
    const sectionHash = useSectionHash();
    return (selectedTab) => {
        if (selectedTab && selectedTab.type === FILTER_TAB_TYPE) {
            // Pour l'onglet de résultat de recherche on recentre juste sur la ville
            if (selectedTown) {
                new google.maps.LatLng(parseFloat(selectedTown.lat), parseFloat(selectedTown.lng));
            }
            return;
        }
        if (selectedTab && selectedTab.type === PLOT_TAB_TYPE) {
            const plot = plots[selectedTab.id];
            if (plot) {
                navigate({
                    pathname: `/parcelle/${plot.townId}/${plot.lat}/${plot.lng}/${plot.id}`,
                    hash: sectionHash,
                });
            }
            return;
        }
        if (selectedTab && selectedTab.type === PROJECT_TAB_TYPE) {
            const project = projects[selectedTab.id];
            if (project && project.townId !== selectedTown?.id) {
                navigateToTown({
                    lat: project.lat,
                    lng: project.lng,
                    precision,
                });
            }
            return;
        }
        if (selectedTown) {
            navigateToTown({
                lat: selectedTown.lat,
                lng: selectedTown.lng,
                precision,
            });
            return;
        }
        navigate('/');
    };
};
