import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormControl, FormErrorMessage, FormLabel, Textarea, } from '@chakra-ui/react';
import NotRequired from './NotRequired';
const TextAreaInput = ({ label, name, placeholder, required, handleBlur = () => { }, ...props }) => {
    const { register, getFieldState } = useFormContext();
    const { error } = getFieldState(name);
    return (<FormControl isInvalid={Boolean(error)} width="full">
      {label && (<FormLabel>
          {label} {!required && <NotRequired />}
        </FormLabel>)}
      <Textarea {...register(name)} placeholder={placeholder} size="sm" onBlur={(e) => {
            handleBlur(e.target.value);
        }} {...props}/>
      {Boolean(error) && <FormErrorMessage>{error?.message}</FormErrorMessage>}
    </FormControl>);
};
export default TextAreaInput;
