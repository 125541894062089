import { useEffect, useRef } from 'react';
export const usePrevious = (value, ignoreFalsy = false) => {
    const previousValue = useRef();
    useEffect(() => {
        if (!ignoreFalsy || !!value) {
            previousValue.current = value;
        }
    }, [value]);
    return previousValue.current;
};
