import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, IconButton, Menu, MenuButton, MenuItem, MenuList, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_CRM } from 'analytics/useAnalytics';
import CustomTooltip from 'components/tooltips/CustomTooltip';
import CalendarIcon from 'images/icons/Calendar';
import { AGENDA_ACTIVITY_TYPE_LAND_POINT, AGENDA_ACTIVITY_TYPES, } from 'utils/constants';
const ActivityCreateButton = ({ activityCreate, setActivityInfo, disabled, icon, ...buttonProps }) => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const createActivity = (activityCreate, agendaType) => {
        setActivityInfo({
            cadastreId: activityCreate.cadastreId,
            agendaType: agendaType,
            contactId: activityCreate.contactId,
            plotId: activityCreate.plotId,
            projectId: activityCreate.projectId,
        });
    };
    return (<Menu>
      {icon ? (<MenuButton as={IconButton} size="sm" variant="ghost" aria-label={t('tabs.plots.section.my_crm.contact.add_activity.label')} color="colorMode.revertBase" disabled={disabled} position="relative" icon={<CustomTooltip label={t('tabs.plots.section.my_crm.contact.add_activity.label')}>
              <Box _after={{
                    content: '""',
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: 0,
                    left: 0,
                }}>
                <CalendarIcon fill="currentColor" width="16" height="16"/>
              </Box>
            </CustomTooltip>} onClick={() => {
                trackEvent({
                    category: ANALYTICS_CRM,
                    action: 'Ajouter une action',
                });
            }}/>) : (<MenuButton variant="outline" size="sm" as={Button} onClick={() => {
                trackEvent({
                    category: ANALYTICS_CRM,
                    action: 'Ajouter une action',
                });
            }} {...buttonProps}>
          {t('tabs.activities.create')}
        </MenuButton>)}

      <MenuList zIndex={2}>
        {Object.keys(AGENDA_ACTIVITY_TYPES).map((agendaType) => {
            if (!activityCreate.projectId &&
                AGENDA_ACTIVITY_TYPES[agendaType] ===
                    AGENDA_ACTIVITY_TYPE_LAND_POINT) {
                return;
            }
            return (<MenuItem key={agendaType} fontSize="13px" color="colorMode.font800" onClick={() => {
                    createActivity(activityCreate, AGENDA_ACTIVITY_TYPES[agendaType]);
                    trackEvent({
                        category: ANALYTICS_CRM,
                        action: t(`activity.fields.common.agenda_type.${agendaType}`),
                    });
                }}>
              {t(`tabs.plots.section.my_crm.contact.add_activity.${agendaType}`)}
            </MenuItem>);
        })}
      </MenuList>
    </Menu>);
};
export default ActivityCreateButton;
