import * as React from 'react';
import { Heading, HStack, Icon } from '@chakra-ui/react';
const DashboardTitle = ({ icon, label }) => {
    return (<HStack paddingBottom={2} color="colorMode.font900">
      <Icon as={icon} fill="currentColor" width="20px" height="20px"/>
      <Heading as="h3" variant="h3" paddingX={1}>
        {label}
      </Heading>
    </HStack>);
};
export default DashboardTitle;
