import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getLayerBoundingTileUrlTileUrl, getNormalizedCoordinates, } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = (map, tileWidth, tileHeight) => (coord, zoom) => {
    const normCoord = getNormalizedCoordinates(coord, zoom);
    if (!normCoord) {
        return null;
    }
    return getLayerBoundingTileUrlTileUrl(map, 'Applicatif', 'epci_202401', zoom, coord, tileWidth, tileHeight);
};
const getLegends = () => {
    const legends = {};
    legends.epci = {
        layerKey: filtersKey.EPCI,
        label: 'epci',
        needsTranslate: true,
        color: '#FFFFFE',
        borderColor: '#000000',
        borderWidth: 1,
        borderOpacity: 1,
    };
    return legends;
};
const useEPCILayer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.EPCI],
        getLegends,
        tileWidth: 1024,
        tileHeight: 1024,
    })(filtersKey.EPCI);
};
const EPCILayer = () => {
    useEPCILayer();
    return null;
};
export default EPCILayer;
