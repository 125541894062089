import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
import { t } from 'i18next';
import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
const IOLowAltitudeLayerItem = iots.intersection([
    IOLayerItem,
    iots.type({
        zone: iots.string,
        name: iots.string,
        ceiling: iots.string,
        floor: iots.string,
    }),
], 'IOLowAltitudeLayerItem');
const fetchLowAltitude = (townId, townScot, selectedTown, zone) => () => get(iots.array(IOLowAltitudeLayerItem), `low-altitude/${zone}`);
const getInfoWindowContent = (layerInfo) => {
    const content = (<>
      {layerInfo?.zone && (<>
          {layerInfo.zone}
          <br />
        </>)}
      {layerInfo?.name && (<>
          {layerInfo.name}
          <br />
        </>)}
      {layerInfo?.ceiling && (<>
          {t('info_window.low_altitude.ceiling')} : {layerInfo.ceiling}
          <br />
        </>)}
      {layerInfo?.floor && (<>
          {t('info_window.low_altitude.ceiling')} : {layerInfo.floor}
        </>)}
    </>);
    return ReactDOMServer.renderToString(content);
};
const useLowAltitudeLayers = (zone) => {
    createLayersHook({
        fetch: fetchLowAltitude,
        getInfoWindowContent,
        extraParams: [zone],
    })(zone);
};
const LowAltitudeLayers = ({ zone, }) => {
    useLowAltitudeLayers(zone);
    return null;
};
export default LowAltitudeLayers;
