import { useCallback, useMemo } from 'react';
import { useFilters, useToggleFilter, useUpdateFilters, } from 'hooks/contexts/useFiltersContext';
import { getFilterRadioName } from 'utils/filters';
export const useFiltersCheckbox = (filters, allowRadioUncheck = false) => {
    const currentFilters = useFilters();
    const updateFilters = useUpdateFilters();
    const isChecked = Object.keys(filters).every((filterKey) => {
        const filter = filters[filterKey];
        const radioName = getFilterRadioName(filter);
        return radioName
            ? currentFilters[radioName] === filterKey
            : !!currentFilters[filterKey];
    });
    const onCheck = (checked) => {
        const newFilters = {};
        Object.keys(filters).forEach((filterKey) => {
            const filter = filters[filterKey];
            const radioName = getFilterRadioName(filter);
            if (radioName) {
                if (checked) {
                    newFilters[radioName] = filterKey;
                }
                else if (allowRadioUncheck) {
                    newFilters[radioName] = null;
                }
            }
            else {
                newFilters[filterKey] = checked;
            }
        });
        updateFilters(newFilters);
    };
    return { isChecked, onCheck };
};
const useFilterCheckbox = (filterKey, filter) => {
    const filters = useFilters();
    const toggleFilter = useToggleFilter();
    const radioName = useMemo(() => getFilterRadioName(filter), [filter]);
    const isChecked = radioName
        ? filters[radioName] === filterKey
        : !!filters[filterKey];
    const onCheck = useCallback((checked) => {
        if (radioName) {
            if (checked) {
                toggleFilter(radioName, filterKey);
            }
        }
        else {
            toggleFilter(filterKey, checked);
        }
    }, [radioName, filterKey]);
    return { isChecked, onCheck, radioName };
};
export default useFilterCheckbox;
