import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOEasementForNaturalAndUrbanSitesProtectionLayerItem = IOLayerItem;
const fetchEasementForNaturalAndUrbanSitesProtection = (townId) => () => get(iots.array(IOEasementForNaturalAndUrbanSitesProtectionLayerItem), `legacy/${townId}/easement-for-natural-and-urban-sites-protection`);
const useEasementForNaturalAndUrbanSitesProtectionLayer = () => {
    createLayersHook({
        fetch: fetchEasementForNaturalAndUrbanSitesProtection,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.EASEMENT_FOR_NATURAL_AND_URBAN_SITES_PROTECTION);
};
const EasementForNaturalAndUrbanSitesProtectionLayer = () => {
    useEasementForNaturalAndUrbanSitesProtectionLayer();
    return null;
};
export default EasementForNaturalAndUrbanSitesProtectionLayer;
