import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Link } from '@chakra-ui/react';
import * as iots from 'io-ts';
export const IOBODACCBusinessInfo = iots.type({
    id: iots.string,
    personMorale: iots.string,
    personPhysic: iots.string,
    town: iots.string,
    siren: iots.string,
    activity: iots.string,
    funds: iots.string,
    quality: iots.string,
    url: iots.string,
    publicationDate: iots.string,
});
const BusinessInfoWindow = ({ info, style = {}, }) => {
    const { t } = useTranslation();
    if (!info) {
        return null;
    }
    return (<Box maxHeight="400px" style={style}>
      {info.publicationDate && (<Box>
          {t('business.publication_date')} :{' '}
          <strong>{info.publicationDate}</strong>
        </Box>)}
      {info.personMorale && (<Box>
          {t('business.person_morale')} : <strong>{info.personMorale}</strong>
        </Box>)}
      {info.personPhysic && (<Box>
          {t('business.person_physic')} : <strong>{info.personPhysic}</strong>
        </Box>)}
      {info.town && (<Box>
          {t('business.town')} : {info.town}
        </Box>)}
      {info.siren && (<Box>
          {t('business.siren')} : {info.siren}
        </Box>)}
      {info.activity && (<Box>
          {t('business.activity')} : <strong>{info.activity}</strong>
        </Box>)}
      {info.funds && (<Box>
          {t('business.funds')} : <strong>{info.funds}</strong>
        </Box>)}
      {info.quality && (<Box>
          {t('business.quality')} : {info.quality}
        </Box>)}
      {info.url && (<Link href={info.url} isExternal variant="primary">
          {t('business.url')}
        </Link>)}
    </Box>);
};
export default BusinessInfoWindow;
