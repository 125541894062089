import { useEffect, useState } from 'react';
import { useMap } from 'hooks/contexts/useMapContext';
const useComputedBounds = () => {
    const map = useMap();
    const [computedBounds, setComputedBounds] = useState(null);
    useEffect(() => {
        if (map) {
            setComputedBounds(map.getBounds());
            map.addListener('idle', () => {
                setComputedBounds(map.getBounds());
            });
        }
    }, [map]);
    return computedBounds;
};
export default useComputedBounds;
