import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import useAnalytics from 'analytics/useAnalytics';
import qs from 'qs';
import { useCloseLayout } from 'hooks/contexts/useLayoutContext';
import { useClearPlotSummary } from 'hooks/contexts/useLegendContext';
import { useFormattedAddress } from 'hooks/contexts/useLocationContext';
import useNavigateToTown from 'hooks/navigation/useNavigateToTown';
const AddressButton = ({ item, children, analyticOrigin, analyticDetail, ...buttonProps }) => {
    const { trackEvent } = useAnalytics();
    const navigate = useNavigate();
    const navigateToTown = useNavigateToTown();
    const clearPlotSummary = useClearPlotSummary();
    const closeLayout = useCloseLayout();
    const formattedAddress = useFormattedAddress();
    const { lat, lng, plotId, acuteness, townId } = item;
    const handleClick = () => {
        clearPlotSummary();
        if (formattedAddress !== item.name) {
            // If we have a plot associated with address open it
            if (plotId && townId && lat && lng) {
                navigate({
                    pathname: `/parcelle/${townId}/${lat}/${lng}/${plotId}`,
                    search: qs.stringify({
                        formattedAddress: item.name,
                    }),
                });
                return;
            }
            closeLayout();
            navigateToTown({
                lat,
                lng,
                formattedAddress: item.name,
                centerMapOnLat: lat,
                centerMapOnLng: lng,
                precision: acuteness,
                addTownMarker: true,
            });
        }
        if (analyticOrigin && analyticDetail) {
            trackEvent({
                category: analyticOrigin,
                action: analyticDetail,
            });
        }
    };
    return (<Button onClick={handleClick} data-cy="history_address_button" {...buttonProps}>
      {children}
    </Button>);
};
export default AddressButton;
