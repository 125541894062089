import * as React from 'react';
import { InputGroup, InputRightElement } from '@chakra-ui/react';
export const AreaInputWrapper = ({ children, rightElementLabel = '', size = 'sm', }) => {
    return (<InputGroup size={size}>
      {children}
      <InputRightElement pointerEvents="none" color="colorMode.font900" fontWeight={500}>
        {rightElementLabel}
      </InputRightElement>
    </InputGroup>);
};
export default AreaInputWrapper;
