import * as React from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { Box, Heading, Stack, Text } from '@chakra-ui/react';
import { ANALYTICS_CRM } from 'analytics/useAnalytics';
import ModalButton from 'components/buttons/ModalButton';
import PlotButton from 'components/buttons/PlotButton';
import Table from 'components/table/Table';
import { useOpenPlotModal } from 'hooks/contexts/useModalContext';
import useProjectHistory from 'hooks/crm/useProjectHistory';
import { formatDate } from 'utils/formatDate';
const getColumnHeader = (column) => (<Text align="center">
    <Translation>
      {(t) => t(`tabs.project.columns.history.${column}`)}
    </Translation>
  </Text>);
const HistoryProject = ({ projectId, landPointId, }) => {
    const { t } = useTranslation();
    const { data: projectHistory, isInitialLoading: isLoading } = useProjectHistory(projectId, landPointId);
    const openPlotModal = useOpenPlotModal();
    const columns = React.useMemo(() => {
        return [
            {
                id: 'columns',
                columns: [
                    {
                        accessorKey: 'date',
                        header: () => getColumnHeader('date'),
                        cell: (info) => formatDate(info.getValue()),
                        enableColumnFilter: false,
                        enableSorting: false,
                    },
                    {
                        accessorKey: 'value',
                        header: () => getColumnHeader('value'),
                        cell: (info) => info.getValue(),
                        enableColumnFilter: false,
                        enableSorting: false,
                    },
                    {
                        accessorKey: 'plotNumber',
                        header: () => getColumnHeader('plot_number'),
                        cell: (info) => (<Box textAlign="left">
                {info.row.original.plotId && (<>
                    {!landPointId ? (<PlotButton lat={info.row.original.lat} lng={info.row.original.lng} townId={info.row.original.townId} variant="link-primary" analyticDetail="Ouvrir fiche parcelle fiche projet" analyticOrigin={ANALYTICS_CRM}>
                        {info.getValue()}
                      </PlotButton>) : (<ModalButton size="sm" onClick={() => {
                                        openPlotModal({
                                            townshipId: info.row.original.townId,
                                            plotId: info.row.original.plotId,
                                        });
                                    }}>
                        {info.row.original.plotNumber}
                      </ModalButton>)}
                  </>)}
              </Box>),
                        enableColumnFilter: false,
                        enableSorting: false,
                    },
                ],
            },
        ];
    }, [projectHistory]);
    return (<Stack width="100%" spacing={3}>
      <Heading as="h4" variant="h4">
        {t('tabs.project.historic')}
      </Heading>

      <Table id={`table-project-${projectId}-history`} columns={columns} data={projectHistory || []} isLoading={isLoading} initialSort={[{ id: 'date', desc: true }]} withExport={false}/>
    </Stack>);
};
export default HistoryProject;
