import * as React from 'react';
import { Box, HStack, Skeleton, Table, TableContainer, Tbody, Td, Th, Thead, Tr, VStack, } from '@chakra-ui/react';
import { useSectionHash } from 'hooks/contexts/useTabsContext';
const pickPlotSkeleton = () => {
    const sectionHash = useSectionHash();
    switch (sectionHash) {
        case 'plot':
            return <PlotInfoSkeleton />;
        case 'feasibility':
        case 'feasibility_additional':
            return <PlotFeasibilitySkeleton />;
        case 'owner':
            return <PlotOwnerSkeleton />;
        case 'plu_additional_regulation':
        case 'plu_regulation':
            return <PlotTableTabSkeleton columns={2} rows={20}/>;
        case 'plot_rules':
            return <PlotTableTabSkeleton columns={3}/>;
        case 'occupant':
            return <PlotTableTabSkeleton columns={9}/>;
        case 'my_crm':
            return <PlotCRMSkeleton />;
        case 'neighborhood':
            return <PlotNeighbourhoodSkeleton />;
        default:
            return <PlotTableTabSkeleton />;
    }
};
export const getPlotSkeleton = (withNav) => {
    const skeleton = pickPlotSkeleton();
    return <PlotSkeletonWrapper withNav={withNav}>{skeleton}</PlotSkeletonWrapper>;
};
const PlotSkeletonWrapper = ({ withNav, children, }) => (<VStack alignItems="stretch" padding={4} spacing={6}>
    {withNav && <Skeleton height="30px" speed={2}/>}
    {children}
  </VStack>);
const PlotTableSkeleton = ({ rows = 10, columns = 4, }) => {
    return (<TableContainer>
      <Table variant="striped">
        <Thead>
          <Tr>
            {[...Array(columns)].map((_, key) => (<Th key={key}>
                <Box p={2} maxW="xs" minW="150px">
                  <Skeleton width="100%" height="20px" speed={2}/>
                </Box>
              </Th>))}
          </Tr>
        </Thead>
        <Tbody>
          {[...Array(rows)].map((_, key) => (<Tr key={key}>
              {[...Array(columns)].map((_, key) => (<Td key={key}>
                  <Box p={2}>
                    <Skeleton width="100%" height="10px" speed={2}/>
                  </Box>
                </Td>))}
            </Tr>))}
        </Tbody>
      </Table>
    </TableContainer>);
};
export const PlotInfoSkeleton = () => {
    return (<>
      <VStack spacing={4} alignItems="stretch">
        <Box bg="white" p={4}>
          <Skeleton height="40px" width="sm"/>
        </Box>
        <VStack alignItems="stretch" bg="white" p={4}>
          <Skeleton height="20px" width="xs"/>
          <Skeleton height="20px" width="xs"/>
          <Skeleton height="20px" width="xs"/>
          <Skeleton height="20px" width="xs"/>
          <HStack spacing={4}>
            <Skeleton height="45px" width="xs"/>
            <Skeleton height="45px" width="xs"/>
          </HStack>
          <Skeleton height="10px" width="xs"/>
          <Skeleton height="10px" width="xs"/>
          <Skeleton height="10px" width="xs"/>
        </VStack>
      </VStack>
    </>);
};
export const PlotFeasibilitySkeleton = () => {
    return (<>
      <HStack height="100%" p={4} alignItems="flex-start" spacing={4}>
        <Box width="70%">
          <PlotTableSkeleton columns={3} rows={15}/>
        </Box>
        <VStack bg="white" p={4} spacing={4} height="100%" width="30%" alignItems="stretch">
          <Skeleton height="20px"/>
          <Skeleton height="20px"/>
          <Skeleton height="40px"/>
        </VStack>
      </HStack>
    </>);
};
export const PlotOwnerSkeleton = () => {
    return (<>
      <VStack spacing={4} p={4}>
        {[...Array(9)].map((_, key) => (<Skeleton key={key} width="100%" height="45px"/>))}
      </VStack>
    </>);
};
export const PlotTableTabSkeleton = ({ rows, columns, }) => {
    return (<>
      <PlotTableSkeleton rows={rows} columns={columns}/>
    </>);
};
const TitleContentSkeleton = ({ rows, columns, table, }) => (<VStack alignItems="stretch">
    <VStack alignItems="stretch">
      <Skeleton height="30px" maxW="xs"/>
      {table ? (<PlotTableSkeleton rows={rows} columns={columns}/>) : (<Skeleton height="50px" width="100%"/>)}
    </VStack>
  </VStack>);
export const PlotCRMSkeleton = () => (<PlotSkeletonWrapper>
    <VStack alignItems={'stretch'} spacing={6}>
      <TitleContentSkeleton columns={5} rows={3} table/>
      <TitleContentSkeleton columns={2} rows={3} table/>
      <TitleContentSkeleton />
      <TitleContentSkeleton />
    </VStack>
  </PlotSkeletonWrapper>);
export const PlotNeighbourhoodSkeleton = () => (<PlotSkeletonWrapper>
    <VStack alignItems="stretch" spacing={6}>
      <TitleContentSkeleton columns={4} rows={1} table/>
      <VStack alignItems="stretch" spacing={4}>
        <Skeleton height="30px" maxW="xs"/>
        <HStack spacing={10} alignItems="flex-start" maxW="900px">
          <Skeleton height="300px" width="20%" variant="rectangle"/>
          <Box width="80%">
            <PlotTableSkeleton columns={4} rows={8}/>
          </Box>
        </HStack>
      </VStack>
    </VStack>
  </PlotSkeletonWrapper>);
