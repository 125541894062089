import { useEffect, useState } from 'react';
import { usePrevious } from '@chakra-ui/react';
import { useFilters, useToggleFilter } from 'hooks/contexts/useFiltersContext';
export const useContextPlotHighlights = (layerKey) => {
    const filters = useFilters();
    const toggleFilter = useToggleFilter();
    const [highlights, setHighlights] = useState({});
    const filterEnabled = filters[layerKey];
    const previousFilterEnabled = usePrevious(filterEnabled);
    useEffect(() => {
        if (previousFilterEnabled && !filterEnabled) {
            clearHighlights();
        }
    }, [filterEnabled]);
    useEffect(() => {
        const hasHighlights = Object.keys(highlights).length;
        if (!filterEnabled && hasHighlights) {
            toggleFilter(layerKey, true);
            return;
        }
        if (filterEnabled && !hasHighlights) {
            toggleFilter(layerKey, false);
        }
    }, [highlights]);
    const arePlotsHighlighted = (plotsInfo, legend) => filterEnabled &&
        plotsInfo.every((plotInfo) => {
            const key = plotInfo.id;
            return (!!highlights[key] &&
                highlights[key].highlights.find((highlight) => highlight.legend === legend));
        });
    const clearHighlights = () => {
        setHighlights({});
    };
    const removeHighlights = (data) => {
        setHighlights((highlights) => {
            const newHighlights = { ...highlights };
            data.forEach((plotsInfoData) => {
                plotsInfoData.plotsInfo.forEach((plotInfo) => {
                    const key = plotInfo.id;
                    const plotHighlights = newHighlights[key];
                    if (!plotHighlights) {
                        return;
                    }
                    const highlightIndex = plotHighlights.highlights.findIndex((highlight) => highlight.legend === plotsInfoData.legend);
                    if (highlightIndex === -1) {
                        return;
                    }
                    plotHighlights.highlights.splice(highlightIndex, 1);
                    if (plotHighlights.highlights.length === 0) {
                        delete newHighlights[key];
                        return;
                    }
                    newHighlights[key] = plotHighlights;
                });
            });
            return newHighlights;
        });
    };
    const addHighlights = (data) => {
        setHighlights((highlights) => {
            const newHighlights = { ...highlights };
            data.forEach((plotsInfoData) => {
                plotsInfoData.plotsInfo.forEach((plotInfo) => {
                    const key = plotInfo.id;
                    const plotHighlights = newHighlights[key] || {
                        ...plotInfo,
                        highlights: [],
                    };
                    if (plotHighlights.highlights.find((highlight) => highlight.legend === plotsInfoData.legend)) {
                        return;
                    }
                    plotHighlights.highlights.push({
                        color: plotsInfoData.color,
                        legend: plotsInfoData.legend,
                    });
                    newHighlights[key] = plotHighlights;
                });
            });
            return newHighlights;
        });
    };
    const toggleHighlights = (plotsInfo, color, legend, value) => {
        const show = value === undefined ? !arePlotsHighlighted(plotsInfo, legend) : value;
        // If all plots are already visible with the specified legend, we hide them, otherwise, we show them
        if (show) {
            addHighlights([{ plotsInfo, color, legend }]);
            return;
        }
        removeHighlights([{ plotsInfo, legend }]);
    };
    return {
        highlights,
        addHighlights,
        removeHighlights,
        toggleHighlights,
        clearHighlights,
        arePlotsHighlighted,
    };
};
export default useContextPlotHighlights;
