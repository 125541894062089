import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { HStack, InputGroup, InputRightElement, Text } from '@chakra-ui/react';
const InputWrapper = ({ label, labelParams = {}, measureUnit = 'm²', extraInput, children, tooltip, }) => {
    const { t } = useTranslation();
    return (<HStack width="full" justifyContent="space-between">
      <HStack flex={2}>
        <Text as="div" textStyle="paragraph" display="flex">
          <Text as="span" dangerouslySetInnerHTML={{
            __html: t('modals.real_estate_tax.' + label, labelParams),
        }}/>
          {tooltip}
        </Text>
      </HStack>
      <HStack flex={1}>
        <InputGroup size="sm">
          {children}
          <InputRightElement paddingRight="4px" pointerEvents="none">
            {measureUnit}
          </InputRightElement>
        </InputGroup>
      </HStack>
      <HStack flex={1}>{extraInput}</HStack>
    </HStack>);
};
export default InputWrapper;
