import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IORegionalPlanContinuitiesLayerItem = IOLayerItem;
const fetchRegionalPlanContinuities = (townId) => () => get(iots.array(IORegionalPlanContinuitiesLayerItem), `legacy/${townId}/regional-plan-continuities`);
const useRegionalPlanContinuitiesLayer = () => {
    createLayersHook({
        fetch: fetchRegionalPlanContinuities,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.REGIONAL_PLAN_CONTINUITIES);
};
const RegionalPlanContinuitiesLayer = () => {
    useRegionalPlanContinuitiesLayer();
    return null;
};
export default RegionalPlanContinuitiesLayer;
