import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Heading, Text, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_PLOT_TAB } from 'analytics/useAnalytics';
import { capitalize } from 'lodash';
import { CREATE_CONTACT_DEFAULT_VALUES } from 'components/modals/contactModal/ContactDrawer';
import { TABS_VIEW_HALF_VERTICAL } from 'context/LayoutContext';
import { useTabsView } from 'hooks/contexts/useLayoutContext';
import { BUILDING_PLOT_TAB } from 'types/api';
import { SPACING_PANEL } from 'utils/constants';
import OwnerBlockDisplay from './OwnerBlockDisplay';
import OwnerTableDisplay from './OwnerTableDisplay';
export const formatOwnerContactInfo = (owner) => {
    return {
        ...CREATE_CONTACT_DEFAULT_VALUES,
        ownerId: owner?.id,
        name: owner?.name,
        lastname: capitalize(owner?.name ?? ''),
        address: owner.streetNumber + ' ' + owner.street,
        postalCode: owner.postalCode,
        town: owner.town,
        company: owner.siren ? capitalize(owner.name) : '',
        siren: owner.siren,
        type: [1], // owner
    };
};
export const NoOwner = () => {
    const { t } = useTranslation();
    return (<Text textStyle="paragraph" width="100%">
      {t('global.none')}
    </Text>);
};
export const flat = (data) => {
    const ownerInfo = [];
    for (const [key, value] of Object.entries(data)) {
        ownerInfo.push({
            label: key,
            value: typeof value === 'object' ? flat(value) : value,
        });
    }
    return ownerInfo;
};
const PlotOwnerDetailWrapper = ({ owners, year, type, setContactToCreate }) => {
    const { t } = useTranslation();
    const tabsView = useTabsView();
    const { trackEvent } = useAnalytics();
    const handleClick = () => {
        trackEvent({
            category: ANALYTICS_PLOT_TAB,
            action: 'Propriétaire ' + type + ' ' + year,
        });
    };
    return (<AccordionItem border="none" boxShadow="md" marginBottom={SPACING_PANEL} borderRadius="md">
      <AccordionButton backgroundColor="colorMode.background700" padding={2} alignItems="center" borderRadius="md" onClick={handleClick}>
        <Box flex={1} textAlign="left">
          <Heading as="h4" variant="h4" fontSize="13px" fontWeight={700} color="colorMode.font800">
            {t(`tabs.plots.section.owner.panel_titles.owner_${type}_year`, {
            year: year,
        })}
            {type === BUILDING_PLOT_TAB ? (<Text as="span" fontWeight="normal">
                ({t(`tabs.plots.section.owner.panel_titles.morale_society`)})
              </Text>) : (<Text as="span" fontWeight="normal">
                ({t(`tabs.plots.section.owner.panel_titles.morale_society`)})
              </Text>)}
          </Heading>
        </Box>
        <AccordionIcon color="colorMode.font700"/>
      </AccordionButton>
      <AccordionPanel padding={0} overflowX="auto">
        {tabsView === TABS_VIEW_HALF_VERTICAL ? (<OwnerBlockDisplay owners={owners} setContactToCreate={setContactToCreate}/>) : (<OwnerTableDisplay owners={owners} setContactToCreate={setContactToCreate}/>)}
      </AccordionPanel>
    </AccordionItem>);
};
export default PlotOwnerDetailWrapper;
