import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { createContext } from 'use-context-selector';
import { get } from 'api/client';
import { useFiltersSearchRegex } from 'hooks/contexts/useFiltersContext';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import useLayersTreeAdditionalData from 'hooks/layers/useLayersTreeAdditionalData';
import useCurrentSite from 'hooks/useCurrentSite';
import { filterLayersTree, IOFiltersTree, lastNode, } from 'utils/filters';
export const LayersTreeContext = createContext(null);
const extractLayersTreeEndpoints = (layersTree) => {
    const endpoints = [];
    const extractEndpoints = (tree, categoryId) => {
        if (lastNode(tree)) {
            if (tree?.endpoint) {
                const categoryEndpoint = Array.isArray(tree?.endpoint)
                    ? tree?.endpoint
                    : [tree?.endpoint];
                categoryEndpoint.forEach((endpoint) => endpoints.push({ categoryId, endpoint: endpoint }));
            }
            return;
        }
        Object.keys(tree).forEach((subCategory) => extractEndpoints(tree[subCategory], subCategory));
    };
    extractEndpoints(layersTree);
    return endpoints;
};
const fetchLayersTree = (townshipId, extraParams) => () => get(IOFiltersTree, `${townshipId}/layers-tree`, extraParams);
export const LayersTreeProvider = ({ children }) => {
    const { t, i18n } = useTranslation();
    const { currentSite } = useCurrentSite();
    const selectedTown = useSelectedTown();
    const queryClient = useQueryClient();
    const filtersSearchRegex = useFiltersSearchRegex();
    const [mainLayers, setMainLayers] = useState(null);
    const [secondaryLayers, setSecondaryLayers] = useState();
    const [layersTreeEndpoints, setLayersTreeEndpoints] = useState([]);
    const extraParams = {
        townshipScot: selectedTown?.scot,
        hasPLU: selectedTown?.hasPLU,
        additionalPLU: !!selectedTown?.additionalPLU,
        siteId: currentSite?.legacyId,
    };
    const { data: layersTree, isInitialLoading: isLoadingLayersTree } = useQuery({
        queryKey: [
            'layersTree',
            { townshipId: selectedTown?.id, ...extraParams },
        ],
        queryFn: fetchLayersTree(selectedTown?.id, extraParams),
        enabled: !!selectedTown,
        refetchOnWindowFocus: false,
    });
    const updateLayersTreeQueryData = (updater) => {
        queryClient.setQueryData([
            'layersTree',
            {
                townshipId: selectedTown?.id,
                ...extraParams,
            },
        ], updater);
    };
    useEffect(() => {
        if (layersTree) {
            const { main_layers, ...secondaryLayers } = layersTree;
            setMainLayers(main_layers ?? null);
            setSecondaryLayers(secondaryLayers);
            const endpoints = extractLayersTreeEndpoints(layersTree);
            setLayersTreeEndpoints(endpoints);
        }
    }, [layersTree]);
    const isLoadingAdditionalData = useLayersTreeAdditionalData(layersTreeEndpoints, updateLayersTreeQueryData, !!layersTree && !isLoadingLayersTree);
    const filteredSecondaryLayers = useMemo(() => {
        let filteredLayers = secondaryLayers ? { ...secondaryLayers } : {};
        if (filtersSearchRegex) {
            filteredLayers = filterLayersTree(t, i18n, filteredLayers, filtersSearchRegex, selectedTown);
        }
        return filteredLayers;
    }, [filtersSearchRegex, secondaryLayers]);
    const resetLayersTree = useCallback(() => {
        setMainLayers(null);
        setSecondaryLayers(null);
    }, [setMainLayers, setSecondaryLayers]);
    return (<LayersTreeContext.Provider value={{
            layersTree,
            mainLayers,
            secondaryLayers: secondaryLayers ?? {},
            filteredSecondaryLayers,
            isLoadingLayersTree,
            isLoadingAdditionalData,
            updateLayersTreeQueryData,
            resetLayersTree,
        }}>
      {children}
    </LayersTreeContext.Provider>);
};
export default LayersTreeContext;
