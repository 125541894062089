import { useTranslation } from 'react-i18next';
import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import stoneQuarryImg from 'images/stone_quarry.png';
import { IOMarkerLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
export const IOExploitationsMarkerItem = iots.intersection([
    IOMarkerLayerItem,
    iots.partial({
        title: iots.string,
        products: iots.string,
        material: iots.string,
        operator: iots.string,
        end: iots.string,
        url: iots.string,
    }),
], 'IOExploitationsMarkerItem');
const fetchInactiveExploitations = (townId) => () => get(iots.array(IOExploitationsMarkerItem), `legacy/${townId}/inactive-exploitations`);
const LEGENDS = {
    inactive_exploitations: {
        layerKey: filtersKey.INACTIVE_EXPLOITATIONS,
        label: 'inactive_exploitations',
        needsTranslate: true,
        image: stoneQuarryImg,
    },
};
export const formatInfoWindowContent = (item, t) => {
    let content = `<b>${item.title}</b>`;
    if (item.products) {
        content += `<br />${item.products}`;
    }
    if (item.material) {
        content += `<br />${item.material}`;
    }
    if (item.operator) {
        content += `<br />${item.operator}`;
    }
    if (item.end) {
        content += `<br />${item.end}`;
    }
    if (item.url) {
        content += `<br /><b><a href="${item.url}" target="_blank">${t('info_window.exploitations.url')}</a></b>`;
    }
    return content;
};
const useInactiveExploitationsLayer = () => {
    const { t } = useTranslation();
    const getInfoWindowContent = (marker) => {
        return formatInfoWindowContent(marker, t);
    };
    createLayersHook({
        fetch: fetchInactiveExploitations,
        markerImg: stoneQuarryImg,
        legends: LEGENDS,
        getInfoWindowContent,
    })(filtersKey.INACTIVE_EXPLOITATIONS);
};
const InactiveExploitationsLayer = () => {
    useInactiveExploitationsLayer();
    return null;
};
export default InactiveExploitationsLayer;
