import { useState } from 'react';
import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
import { t } from 'i18next';
import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOMarkerLayerItem, IOPolygonLayerItem, } from 'types/api';
import { filtersKey } from 'utils/filters';
import InfoWindowTable from '../infoWindow/InfoWindowTable';
const IOOSMEnergyItem = iots.intersection([
    IOMarkerLayerItem,
    IOPolygonLayerItem,
    iots.type({
        source: iots.string,
    }),
], 'IOOSMEnergyItem');
export const IOOSMEnergyInfo = iots.type({
    id: iots.string,
    identifier: iots.string,
    name: iots.string,
    type: iots.string,
    operator: iots.string,
    outputElectricity: iots.string,
});
const fetchOSMEnergy = (townId, townScot, selectedTown, filterKey) => () => get(iots.array(IOOSMEnergyItem), `osm/energy/${filterKey}`);
const fetchOSMEnergyInfo = (item) => {
    return get(IOOSMEnergyInfo, `osm/energy-info/${item.source}/${item.id}`);
};
const formatInfoWindowContent = (item) => {
    const content = (<>
      <div style={{ fontWeight: 'bold' }}>
        {t('info_window.osm_energy.type_' + item.type)}
      </div>
      <InfoWindowTable content={[
            {
                title: t('info_window.osm_energy.name'),
                data: item.name,
                bold: true,
            },
            {
                title: t('info_window.osm_energy.operator'),
                data: item.operator,
            },
            {
                title: t('info_window.osm_energy.output_electricity'),
                data: item.outputElectricity,
            },
        ]}/>
    </>);
    return ReactDOMServer.renderToStaticMarkup(content);
};
const getMarkerImg = (item) => {
    let icon;
    switch (item.layerKey) {
        case filtersKey.OSM_BIOGAS:
            icon = 'osm/biogas.png';
            break;
        case filtersKey.OSM_BIOMASS:
            icon = 'osm/biomass.png';
            break;
        case filtersKey.OSM_GEOTHERMAL:
            icon = 'osm/geothermal.png';
            break;
        case filtersKey.OSM_SOLAR:
            icon = 'osm/solar.png';
            break;
        case filtersKey.OSM_TIDAL:
            icon = 'osm/tidal.png';
            break;
        case filtersKey.OSM_WIND:
            icon = 'osm/wind.png';
            break;
        default:
            return;
    }
    try {
        return require('../../images/' + icon);
    }
    catch (e) {
        if (e instanceof Error) {
            //eslint-disable-next-line
            console.warn("Can't load ", icon);
        }
        else
            throw e;
    }
};
const useOSMEnergyLayers = (filterKey) => {
    const [polygonInfo, setPolygonInfo] = useState({});
    const getInfoWindowContent = async (layerInfo) => {
        if (polygonInfo[layerInfo.source + '-' + layerInfo.id]) {
            return polygonInfo[layerInfo.source + '-' + layerInfo.id];
        }
        const OSMEnergy = await fetchOSMEnergyInfo(layerInfo);
        const content = formatInfoWindowContent(OSMEnergy);
        setPolygonInfo({
            ...polygonInfo,
            [layerInfo.source + '-' + layerInfo.id]: content,
        });
        return content;
    };
    createLayersHook({
        fetch: fetchOSMEnergy,
        extraParams: [filterKey],
        markerImg: getMarkerImg,
        getInfoWindowContent,
    })(filterKey);
};
const OSMEnergyLayers = ({ filterKey, }) => {
    useOSMEnergyLayers(filterKey);
    return null;
};
export default OSMEnergyLayers;
