import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_MAP_BUTTONS } from 'analytics/useAnalytics';
import { useChangeLayout, useTabsView } from 'hooks/contexts/useLayoutContext';
import { TABS_VIEW_FULL, TABS_VIEW_FULL_VERTICAL, } from '../../context/LayoutContext';
const SeeMapButton = ({ category = ANALYTICS_MAP_BUTTONS, action, }) => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const { deployLayout, reduceLayout } = useChangeLayout();
    const tabsView = useTabsView();
    return tabsView === TABS_VIEW_FULL_VERTICAL || tabsView === TABS_VIEW_FULL ? (<Button variant="primary" size="sm" onClick={() => {
            reduceLayout();
            trackEvent({
                category: category,
                action: action + ' voir carte',
            });
        }}>
      {t('global.see_map')}
    </Button>) : (<Button variant="primary" size="sm" onClick={() => {
            deployLayout();
            trackEvent({
                category: ANALYTICS_MAP_BUTTONS,
                action: action + ' voir tableau',
            });
        }}>
      {t('global.table')}
    </Button>);
};
export default SeeMapButton;
