import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Center, CircularProgress, HStack } from '@chakra-ui/react';
import qs from 'qs';
import CarouselHome from 'components/CarouselHome';
import LoginForm from 'components/security/LoginForm';
import { useToken } from 'hooks/contexts/useAuthContext';
const LoginPage = () => {
    const token = useToken();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { redirect } = qs.parse(searchParams.toString(), {
        ignoreQueryPrefix: true,
    });
    const redirectPath = redirect ? redirect.toString() : '/';
    React.useEffect(() => {
        if (token) {
            navigate(redirectPath);
        }
    }, []);
    if (token) {
        return (<Center height="100vh">
        <CircularProgress isIndeterminate color="red.500"/>
      </Center>);
    }
    return (<Box as="main" width="100%">
      <HStack spacing={0}>
        <Box width="50%" height="100vh">
          <CarouselHome />
        </Box>
        <Box width="50%">
          <LoginForm redirect={redirectPath}/>
        </Box>
      </HStack>
    </Box>);
};
export default LoginPage;
