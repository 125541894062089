import { useState } from 'react';
import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
import * as iots from 'io-ts';
import { get } from 'api/client';
import WindFarmInfoWindows from 'components/infoWindow/WindFarmInfoWindow';
import createLayersHook from 'hooks/layers/createLayersHook';
import windFarmImg from 'images/wind_farm_icon.png';
import { IOPolygonLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
const IOWindFarmMastItem = iots.type({
    id: iots.number,
    power: iots.number,
    height: iots.number,
    heightMast: iots.number,
    rotorDiameter: iots.number,
    coteNGF: iots.number,
    period: iots.string,
    light: iots.string,
    dateOnline: iots.string,
    constructor: iots.string,
    model: iots.string,
});
export const IOWindFarmInfo = iots.type({
    name: iots.string,
    power: iots.number,
    datePermit: iots.string,
    dateOnline: iots.string,
    state: iots.string,
    status: iots.string,
    operator: iots.string,
    masts: iots.array(IOWindFarmMastItem),
});
const fetchWindFarm = () => () => get(iots.array(IOPolygonLayerItem), `wind-farm`);
const fetchWindFarmInfo = (item) => {
    return get(IOWindFarmInfo, `wind-farm/${item.id}`);
};
const LEGENDS = {
    wind_farm: {
        layerKey: filtersKey.WIND_FARM,
        label: 'wind_farm',
        color: '#00ff00',
        opacity: 0.5,
        borderColor: '#00ff00',
        borderWidth: 3,
        needsTranslate: true,
        image: windFarmImg,
    },
};
const formatInfoWindowContent = (item) => {
    const content = <WindFarmInfoWindows item={item}/>;
    return ReactDOMServer.renderToStaticMarkup(content);
};
const useWindFarmLayer = () => {
    const [polygonInfo, setPolygonInfo] = useState({});
    const getInfoWindowContent = async (layerInfo) => {
        if (polygonInfo[layerInfo.id]) {
            return polygonInfo[layerInfo.id];
        }
        const windFarm = await fetchWindFarmInfo(layerInfo);
        const content = formatInfoWindowContent(windFarm);
        setPolygonInfo({
            ...polygonInfo,
            [layerInfo.id]: content,
        });
        return content;
    };
    createLayersHook({
        fetch: fetchWindFarm,
        markerImg: windFarmImg,
        legends: LEGENDS,
        getInfoWindowContent,
    })(filtersKey.WIND_FARM);
};
const WindFarmLayer = () => {
    useWindFarmLayer();
    return null;
};
export default WindFarmLayer;
