import * as React from 'react';
import { useCallback, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Heading } from '@chakra-ui/react';
import * as iots from 'io-ts';
import { get } from 'api/client';
import InfoWindowTable from 'components/infoWindow/InfoWindowTable';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
const IOZACLayerItem = IOLayerItem;
const IOZACInfo = iots.intersection([
    IOLayerItem,
    iots.partial({
        name: iots.string,
        date1: iots.string,
        date2: iots.string,
        date3: iots.string,
        date4: iots.string,
        mainCalling: iots.string,
        secondaryCalling: iots.string,
        progressionStep: iots.string,
        requalification: iots.string,
        gestion: iots.string,
        extentionRenew: iots.string,
        developer: iots.string,
        operationType: iots.string,
        source: iots.string,
        totalProgramSize: iots.string,
        estateProgramSize: iots.string,
        activityProgramSize: iots.string,
        officeProgramSize: iots.string,
        commerceProgramSize: iots.string,
        otherActivityProgramSize: iots.string,
        equipmentProgramSize: iots.string,
        greenSpaceSize: iots.string,
        housingQuantity: iots.string,
        jobsQuantity: iots.string,
        inhabitantsQuantity: iots.string,
        comment1: iots.string,
        comment2: iots.string,
        comment3: iots.string,
        communityInterest: iots.string,
        newUrbanNeighborhood: iots.string,
        territorialDevelopmentContract: iots.string,
        perimeterScanQuality: iots.string,
        mainTown: iots.string,
        secondTown: iots.string,
        thirdTown: iots.string,
        fourthTown: iots.string,
    }),
]);
const formatInfoWindowContent = (item, t) => {
    const content = (<Box>
      <InfoWindowTable title={`${t('info_window.zac.project')} [${item.name}]`} content={[
            {
                title: t('info_window.zac.update_date'),
                data: item.date4,
            },
            {
                title: t('info_window.zac.name'),
                data: item.name,
            },
            {
                title: t('info_window.zac.main_calling'),
                data: item.mainCalling,
            },
            {
                title: t('info_window.zac.secondary_calling'),
                data: item.secondaryCalling,
            },
            {
                title: t('info_window.zac.progression_step'),
                data: item.progressionStep,
            },
            {
                title: t('info_window.zac.requalification'),
                data: item.requalification,
            },
            {
                title: t('info_window.zac.extention_renew'),
                data: item.extentionRenew,
            },
            {
                title: t('info_window.zac.gestion'),
                data: item.gestion,
            },
            {
                title: t('info_window.zac.developer'),
                data: item.developer,
            },
            {
                title: t('info_window.zac.operation_type'),
                data: item.operationType,
            },
            {
                title: t('info_window.zac.start_date'),
                data: item.date1,
            },
            {
                title: t('info_window.zac.end_date'),
                data: item.date2,
            },
            {
                title: t('info_window.zac.delivery_date'),
                data: item.date3,
            },
            {
                title: t('info_window.zac.source'),
                data: item.source,
            },
        ]}/>
      <Divider height={2} backgroundColor="gray"/>
      <InfoWindowTable title={t('info_window.zac.programmation')} content={[
            {
                title: t('info_window.zac.total_program_size'),
                data: `${item.totalProgramSize} m²`,
                align: 'right',
            },
            {
                title: t('info_window.zac.estate_program_size'),
                data: `${item.estateProgramSize} m²`,
                align: 'right',
            },
            {
                title: t('info_window.zac.activity_program_size'),
                data: `${item.activityProgramSize} m²`,
                align: 'right',
            },
            {
                title: t('info_window.zac.office_program_size'),
                data: `${item.officeProgramSize} m²`,
                align: 'right',
            },
            {
                title: t('info_window.zac.commerce_program_size'),
                data: `${item.commerceProgramSize} m²`,
                align: 'right',
            },
            {
                title: t('info_window.zac.other_activity_program_size'),
                data: `${item.otherActivityProgramSize} m²`,
                align: 'right',
            },
            {
                title: t('info_window.zac.landscaping_program_size'),
                data: `${item.equipmentProgramSize} m²`,
                align: 'right',
            },
            {
                title: t('info_window.zac.green_space_size'),
                data: `${item.greenSpaceSize} m²`,
                align: 'right',
            },
            {
                title: t('info_window.zac.housing_quantity'),
                data: `${item.housingQuantity}`,
                align: 'right',
            },
            {
                title: t('info_window.zac.jobs_quantity'),
                data: `${item.jobsQuantity}`,
                align: 'right',
            },
            {
                title: t('info_window.zac.inhabitants_quantity'),
                data: `${item.inhabitantsQuantity}`,
                align: 'right',
            },
        ]}/>
      <Divider height={2} backgroundColor="gray"/>
      <Box marginBottom="5px">
        <Heading fontSize="20px" fontWeight={900} paddingBottom="10px" paddingTop="15px">
          {t('info_window.zac.comments')}
        </Heading>
        <Box>
          <Box>{item.comment1}</Box>
          <br />
          <Box>{item.comment2}</Box>
          <br />
          <Box>{item.comment3}</Box>
        </Box>
      </Box>
      <Divider height={2} backgroundColor="gray"/>
      <InfoWindowTable title={t('info_window.zac.other')} content={[
            {
                title: t('info_window.zac.community_interest'),
                data: item.communityInterest,
            },
            {
                title: t('info_window.zac.community_interest'),
                data: item.newUrbanNeighborhood,
            },
            {
                title: t('info_window.zac.territorial_development_contract'),
                data: item.territorialDevelopmentContract,
            },
            {
                title: t('info_window.zac.perimeter_scan_quality'),
                data: item.perimeterScanQuality,
            },
            {
                title: t('info_window.zac.main_town'),
                data: item.mainTown,
            },
            {
                title: t('info_window.zac.secondary_town'),
                data: item.secondTown,
            },
            {
                title: t('info_window.zac.tertiary_town'),
                data: item.thirdTown,
            },
            {
                title: t('info_window.zac.fourth_town'),
                data: item.fourthTown,
            },
        ]}/>
    </Box>);
    return ReactDOMServer.renderToStaticMarkup(content);
};
const fetchZac = (townId) => () => get(iots.array(IOZACLayerItem), `legacy/${townId}/zac`);
const useZACLayer = () => {
    const selectedTown = useSelectedTown();
    const { t } = useTranslation();
    const [itemInfo, setItemInfo] = useState({});
    const getInfoWindowContent = useCallback((item) => {
        const content = formatInfoWindowContent(item, t);
        setItemInfo({
            ...itemInfo,
            [item.id]: content,
        });
        return content;
    }, [selectedTown]);
    createLayersHook({
        fetch: fetchZac,
        getInfoWindowContent,
    })(filtersKey.ZAC);
};
const ZACLayer = () => {
    useZACLayer();
    return null;
};
export default ZACLayer;
