import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
export default function usePersistentContext(key) {
    const queryClient = useQueryClient();
    const { data } = useQuery({
        queryKey: [key],
        queryFn: () => JSON.parse(localStorage.getItem(key)),
    });
    const { mutateAsync: setValue } = useMutation({
        mutationFn: (value) => {
            localStorage.setItem(key, JSON.stringify(value));
            return Promise.resolve(value);
        },
        onMutate: (mutatedData) => {
            const current = data;
            queryClient.setQueryData([key], mutatedData);
            return current;
        },
        onError: (_, __, rollback) => {
            queryClient.setQueryData([key], rollback);
        },
    });
    return [data, setValue];
}
