import * as React from 'react';
import { Button, Text } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_IMPORT_PUBLIPOSTAGE, } from 'analytics/useAnalytics';
import { t } from 'i18next';
import { STEP_IMPORT_CONTACT, STEP_SELECT_CONTACT, } from '../ImportPublipostageForm';
const SourceContact = ({ setStep }) => {
    const { trackEvent } = useAnalytics();
    return (<>
      <Text fontWeight="bold">{t('publipostage.contact_source.title')}</Text>
      <Button size="lg" type="submit" variant="primary" width="full" onClick={() => {
            trackEvent({
                category: ANALYTICS_IMPORT_PUBLIPOSTAGE,
                action: "Importer des contacts à partir d'un fichier Excel",
            });
            setStep(STEP_IMPORT_CONTACT);
        }}>
        {t('publipostage.contact_source.excel')}
      </Button>
      <Button size="lg" type="submit" variant="primary" width="full" marginTop={2} onClick={() => {
            trackEvent({
                category: ANALYTICS_IMPORT_PUBLIPOSTAGE,
                action: 'Sélectionner des contacts existants',
            });
            setStep(STEP_SELECT_CONTACT);
        }}>
        {t('publipostage.contact_source.bdd')}
      </Button>
    </>);
};
export default SourceContact;
