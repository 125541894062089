import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { post } from 'api/client';
import { MANUAL_HIGHLIGHT_COLOR } from 'components/buttons/HighlightPlotsSwitch';
import { IOPlotPolygon } from 'types/api';
import useOnError from './useOnError';
const MULTIPLE_OWNERS_COLOR = '#505050';
export const QUERY_KEY = 'plotsPolygonByIds';
const fetchPlotsPolygonByIds = (plotIds) => () => post(iots.array(IOPlotPolygon), `plot-polygon-by-ids`, { plotIds });
const useHighlightedPlots = (plotsHighlights) => {
    const onErrorCallback = useOnError();
    const { t } = useTranslation();
    const plotIds = Object.values(plotsHighlights).map((plot) => plot.id);
    const { data: polygons, isInitialLoading } = useQuery({
        queryKey: [QUERY_KEY, plotIds],
        queryFn: fetchPlotsPolygonByIds(plotIds),
        enabled: !!plotIds && plotIds.length > 0,
        meta: {
            onError: (error) => {
                onErrorCallback(error);
            },
        },
        retry: false,
    });
    const data = useMemo(() => {
        if (!polygons) {
            return;
        }
        const plots = {};
        polygons.forEach((plotData) => {
            if (plotData && plotData.id) {
                const plotHighlight = plotsHighlights[plotData.id];
                const ownerHighlights = plotHighlight.highlights.filter((highlight) => highlight.color !== MANUAL_HIGHLIGHT_COLOR);
                const firstOwner = ownerHighlights.length > 0 ? ownerHighlights[0] : null;
                const mainHighlight = firstOwner || plotHighlight.highlights[0];
                if (ownerHighlights.length <= 1 && !mainHighlight) {
                    return;
                }
                const color = ownerHighlights.length > 1
                    ? MULTIPLE_OWNERS_COLOR
                    : mainHighlight.color;
                const legend = ownerHighlights.length > 1
                    ? t('legends.multiple_owners_plots_highlights')
                    : mainHighlight.legend;
                plots[plotData.id] = {
                    ...plotData,
                    opacity: 1,
                    color,
                    borderColor: color,
                    legend,
                };
            }
        });
        return plots;
    }, [JSON.stringify(polygons), JSON.stringify(plotsHighlights)]);
    return { data, isInitialLoading };
};
export default useHighlightedPlots;
