import { useQuery } from '@tanstack/react-query';
import { get } from 'api/client';
import useOnError from 'hooks/useOnError';
import { IOProject } from 'types/api';
export const QUERY_KEY = 'team-project-detail';
export const getTeamProjectQueryKey = (townshipId, projectId) => [QUERY_KEY, townshipId, projectId];
export const fetchTeamProject = (townshipId, projectId) => () => get(IOProject, `${townshipId}/project/${projectId}/team`);
const useTeamProject = (isEnabled = false, townshipId, projectId) => {
    const onErrorCallback = useOnError();
    const projectQueryKey = getTeamProjectQueryKey(townshipId, projectId);
    const { data: teamProject, isInitialLoading: isLoadingTeamProject } = useQuery({
        queryKey: projectQueryKey,
        queryFn: fetchTeamProject(townshipId, projectId),
        enabled: isEnabled && !!townshipId && !!projectId,
        meta: { onError: onErrorCallback },
        retry: false,
    });
    return {
        teamProject,
        isLoadingTeamProject,
    };
};
export default useTeamProject;
