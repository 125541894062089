import * as React from 'react';
import { Box, Text } from '@chakra-ui/react';
import LanguageDropdown from 'components/dropdowns/LanguageDropdown';
import UserSitesDropdown from 'components/dropdowns/UserSitesDropdown';
import HelpModal from 'components/modals/HelpModal';
const MenuExtra = () => {
    return (<Box width="full" paddingBottom={2} textAlign="center">
      <HelpModal />
      <UserSitesDropdown />
      <LanguageDropdown />
      <Text textStyle="small" textAlign="center" color="colorMode.font800" paddingY={2}>
        V{process.env.APP_VERSION}
      </Text>
    </Box>);
};
export default React.memo(MenuExtra);
