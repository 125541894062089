import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import usePersistentContext from 'hooks/usePersistentContext';
import { getSiteImage } from 'hooks/useSiteLogo';
const CURRENT_SITE_KEY = 'current_site';
const DEFAULT_FAVICON = 'favicon.ico';
const useCurrentSite = () => {
    const [currentSite, setCurrentSite] = usePersistentContext(CURRENT_SITE_KEY);
    // update favicon when current site changes
    useEffect(() => {
        const favicon = getSiteImage(currentSite?.favicon) || DEFAULT_FAVICON;
        const link = document.createElement('link'), oldLink = document.getElementById('favicon');
        link.id = 'favicon';
        link.rel = 'icon';
        link.href = favicon;
        if (oldLink) {
            document.head.removeChild(oldLink);
        }
        document.head.appendChild(link);
        if (currentSite?.hotjarId &&
            currentSite?.hotjar === 1 &&
            !hotjar.initialized()) {
            const option = {
                id: currentSite?.hotjarId,
                sv: 6,
            };
            hotjar.initialize(option);
        }
    }, [currentSite]);
    return {
        currentSite,
        setCurrentSite,
        currentSiteId: currentSite?.legacyId,
    };
};
export default useCurrentSite;
