import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, Radio, RadioGroup, useDisclosure, } from '@chakra-ui/react';
const ActivityReminderModal = ({ triggerItem, isSubmitting, setValue, handleSubmit, }) => {
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (<>
      {triggerItem(onOpen)}
      <Modal isOpen={isOpen} onClose={onClose} size="md" isCentered variant="wide">
        <ModalOverlay />
        <ModalContent paddingX={8} paddingY={5} alignItems="center">
          <Box as="form" justifyContent="center" alignItems="center" display="flex" flexDirection="column" width="full">
            <Heading as="h4" variant="h4" textAlign="center" paddingY={3}>
              {t('activity.fields.reminder.update')}
            </Heading>
            <ModalCloseButton onClick={onClose}/>
            <ModalBody width="full">
              <RadioGroup defaultValue="1">
                <Radio name="updateOnlyOne" value="1" onChange={() => setValue('updateOnlyOne', 1)}>
                  {t('activity.fields.reminder.update_only_one')}
                </Radio>
                <Radio name="updateOnlyOne" value="0" onChange={() => setValue('updateOnlyOne', 0)}>
                  {t('activity.fields.reminder.update_next')}
                </Radio>
              </RadioGroup>
            </ModalBody>

            <ModalFooter alignSelf="end" paddingEnd={0} paddingTop={2} paddingBottom={0}>
              <Button variant="ghost" onClick={onClose} marginRight={2}>
                {t('global.actions.cancel')}
              </Button>
              <Button type="button" width="100px" paddingX={10} variant="primary" disabled={isSubmitting} onClick={() => {
            handleSubmit();
            onClose();
        }}>
                {t('global.actions.modify')}
              </Button>
            </ModalFooter>
          </Box>
        </ModalContent>
      </Modal>
    </>);
};
export default ActivityReminderModal;
