import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, FormControl, FormLabel, Heading, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, } from '@chakra-ui/react';
const PlotsSearchSaveFilterModal = ({ isOpen, onClose, onSave }) => {
    const { t } = useTranslation();
    const { register, handleSubmit, formState: { isSubmitting }, reset, } = useForm();
    const onSubmit = (values) => {
        onSave(values.name);
        reset();
    };
    const onCancel = () => {
        onClose();
        reset();
    };
    return (<Modal isOpen={isOpen} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader textAlign="center">
            <Heading as="h4" variant="h4">
              {t('sidebar.search_plots.save_filter_modal.title')}
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody textStyle="paragraph" color="colorMode.font900">
            <Box>
              <FormControl paddingBottom={2}>
                <FormLabel>
                  {t('sidebar.search_plots.save_filter_modal.content')}
                </FormLabel>
                <Input autoFocus {...register('name')} data-cy="save_search_name"/>
              </FormControl>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button variant="primary" type="submit" disabled={isSubmitting} mr={3} data-cy="save_plot_search_button">
              {t('sidebar.search_plots.save_filter_modal.save')}
            </Button>
            <Button variant="ghost" onClick={onCancel}>
              {t('sidebar.search_plots.save_filter_modal.close')}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>);
};
export default PlotsSearchSaveFilterModal;
