import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Heading, Stack, Text } from '@chakra-ui/react';
import { useActiveFiltersCount } from 'hooks/contexts/useFiltersContext';
const FiltersHeader = () => {
    const { t } = useTranslation();
    const activeFiltersCount = useActiveFiltersCount();
    return (<Stack alignItems="start" id="FiltersTreeHeader">
      <Heading width="full" as="h4" variant="h3" marginBottom="3" display="flex" justifyContent="space-between">
        <Text color="colorMode.font900" as="span">
          {t('sidebar.header.title')}
        </Text>
        <Badge textTransform="none" backgroundColor="blue.500" borderRadius={35} paddingX="2">
          <Text as="span" textStyle="small" color="white" data-cy="layers_counter">
            {t('sidebar.header.selected_layers', {
            count: activeFiltersCount,
        })}
          </Text>
        </Badge>
      </Heading>
    </Stack>);
};
export default FiltersHeader;
