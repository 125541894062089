import * as React from 'react';
import { useColorMode } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_HEADER } from 'analytics/useAnalytics';
import DarkMode from 'images/icons/DarkMode';
import LightMode from 'images/icons/LightMode';
import IconSwitch from './IconSwitch';
const ColorModeSwitch = () => {
    const { colorMode, toggleColorMode } = useColorMode();
    const { trackEvent } = useAnalytics();
    return (<IconSwitch handleClick={() => {
            // If mode is light it means we are activating dark mode
            trackEvent({
                category: ANALYTICS_HEADER,
                action: colorMode === 'light' ? 'Mode sombre' : 'Mode clair',
            });
            toggleColorMode();
        }} leftIcon={<DarkMode fill="var(--colorModeFont-800)"/>} rightIcon={<LightMode fill="var(--colorModeBackground-800)"/>} selectedChoice={colorMode === 'light' ? 'right' : 'left'}/>);
};
export default ColorModeSwitch;
