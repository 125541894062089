import { useTranslation } from 'react-i18next';
import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOPolygonLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
const IOAllowedLevelsDecreasedLayerItem = iots.intersection([
    IOPolygonLayerItem,
    iots.type({
        buildingPermitAllowedLevels: iots.number,
        pluAllowedLevels: iots.number,
        permitNumber: iots.string,
    }),
]);
const fetchAllowedLevelsDecreased = (townId) => () => get(iots.array(IOAllowedLevelsDecreasedLayerItem), `${townId}/allowed-levels-decreased`);
const useAllowedLevelsDecreasedLayer = () => {
    const { t } = useTranslation();
    const getInfoWindowContent = (layerInfo) => {
        const diff = layerInfo.buildingPermitAllowedLevels - layerInfo.pluAllowedLevels;
        let content = `<strong>${t('info_window.allowed_levels_decreased.levels_diff')} ${diff}</strong><br/>`;
        content += `${t('info_window.allowed_levels_decreased.building_permit_allowed_levels')} ${layerInfo.buildingPermitAllowedLevels}<br/>`;
        content += `${t('info_window.allowed_levels_decreased.plu_allowed_levels')} ${layerInfo.pluAllowedLevels}<br/><br/>`;
        content += `${t('info_window.allowed_levels_decreased.permit_number')} ${layerInfo.permitNumber}<br/>`;
        return content;
    };
    createLayersHook({
        fetch: fetchAllowedLevelsDecreased,
        getInfoWindowContent,
    })(filtersKey.ALLOWED_LEVELS_DECREASED);
};
const AllowedLevelsDecreasedLayer = () => {
    useAllowedLevelsDecreasedLayer();
    return null;
};
export default AllowedLevelsDecreasedLayer;
