import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
import useOnError from 'hooks/useOnError';
export const QUERY_KEY = 'team-project-contacts';
export const getTeamProjectContactsQueryKey = (projectId) => [
    QUERY_KEY,
    projectId,
];
const IOTeamPlotContacts = iots.type({
    titleStr: iots.string,
    lastname: iots.string,
    firstname: iots.string,
    comment: iots.string,
});
export const fetchTeamProjectContacts = (projectId) => () => get(IOTeamPlotContacts, `project/${projectId}/contacts/team`);
const useTeamProjectContacts = (isEnabled = false, projectId) => {
    const onErrorCallback = useOnError();
    const projectQueryKey = getTeamProjectContactsQueryKey(projectId);
    const { data: teamProjectContacts, isInitialLoading: isLoadingTeamProjectContacts, } = useQuery({
        queryKey: projectQueryKey,
        queryFn: fetchTeamProjectContacts(projectId),
        enabled: isEnabled && !!projectId,
        meta: { onError: onErrorCallback },
        retry: false,
    });
    return {
        teamProjectContacts,
        isLoadingTeamProjectContacts,
    };
};
export default useTeamProjectContacts;
