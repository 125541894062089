import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { del, get, post } from 'api/client';
import { QUERY_KEY as PROJECT_ACTIVITIES_QUERY_KEY } from 'hooks/crm/useProjectActivities';
import { ACTIVITIES_QUERY_KEY as LAND_POINT_ACTIVITIES_QUERY_KEY } from 'hooks/useLandPoint';
import useOnError from 'hooks/useOnError';
import { IOActivity, IOActivityCreation, IOActivityDelete, IOContactActivity, IOEmpty, IOPlotActivity, } from 'types/api';
import { QUERY_KEY as CONTACT_LIST_QUERY_KEY } from './crm/useContactList';
import { getProjectActivitiesQueryKey } from './crm/useProjectActivities';
import { QUERY_KEY as USER_PLOTS_QUERY_KEY } from './crm/useUserPlotsList';
import { ACTIVITY_QUERY_KEY as USER_STATS_CRM_ACTIVITY_QUERY_KEY } from './crm/useUserStatisticsCRM';
import useCustomToast from './useCustomToast';
const QUERY_KEY = ['activities'];
const fetchActivities = () => () => get(iots.array(IOActivity), `legacy/activities`);
const fetchPlotActivities = (plotId) => () => get(iots.array(IOPlotActivity), `legacy/plot/${plotId}/activities`);
const fetchContactActivities = (contactId) => () => get(iots.array(IOContactActivity), `contact/${contactId}/activities`);
const deleteActivityRequest = ({ activityId }) => del(IOActivityDelete, `agenda/activity/${activityId}/delete`);
const updateActivityRequest = ({ id, ...rest }) => post(IOEmpty, `agenda/activity/${id}/edit`, rest);
const createActivityRequest = (activity) => post(IOActivityCreation, `agenda/activity/create`, activity);
const getContactActivitiesQueryKey = (contactId) => [
    'contact-activities',
    contactId,
];
const getPlotActivitiesQueryKey = (plotId) => [
    'plot-activities',
    plotId,
];
const useActivity = ({ plotId, contactId, projectId, handleSuccess, handleDeleteSuccess, }) => {
    const { getSuccessToast } = useCustomToast();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const onErrorCallback = useOnError();
    const { data: activities, isInitialLoading: isLoading } = useQuery({
        queryKey: QUERY_KEY,
        queryFn: fetchActivities(),
        enabled: !plotId && !contactId && !projectId,
    });
    const { data: plotActivities, isInitialLoading: isLoadingPlotActivities } = useQuery({
        queryKey: getPlotActivitiesQueryKey(plotId),
        queryFn: fetchPlotActivities(plotId),
        enabled: !!plotId,
    });
    const { data: contactActivities, isInitialLoading: contactActivitiesLoading, } = useQuery({
        queryKey: getContactActivitiesQueryKey(contactId),
        queryFn: fetchContactActivities(contactId),
        enabled: !!contactId,
    });
    const { mutate: updateActivity, isPending: isUpdating } = useMutation({
        mutationFn: updateActivityRequest,
        onError: (err) => onErrorCallback(err),
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: QUERY_KEY });
            if (variables.projectId) {
                queryClient.invalidateQueries({
                    queryKey: getProjectActivitiesQueryKey(variables.projectId),
                });
                queryClient.invalidateQueries({
                    queryKey: [LAND_POINT_ACTIVITIES_QUERY_KEY],
                });
            }
            else {
                queryClient.invalidateQueries({
                    queryKey: [PROJECT_ACTIVITIES_QUERY_KEY],
                });
            }
            if (variables.contactId) {
                queryClient.invalidateQueries({
                    queryKey: getContactActivitiesQueryKey(variables.contactId),
                });
                queryClient.invalidateQueries({ queryKey: CONTACT_LIST_QUERY_KEY });
                // Pour le filtre "Uniquement avec des actions" dans MyPlotsPanel
                queryClient.invalidateQueries({ queryKey: USER_PLOTS_QUERY_KEY });
            }
            if (plotId) {
                queryClient.invalidateQueries({
                    queryKey: getPlotActivitiesQueryKey(plotId),
                });
            }
            else if (variables.plotId) {
                queryClient.invalidateQueries({
                    queryKey: getPlotActivitiesQueryKey(variables.plotId),
                });
            }
            queryClient.invalidateQueries({
                queryKey: [USER_STATS_CRM_ACTIVITY_QUERY_KEY],
            });
            getSuccessToast({
                title: t('activity.status.activity_updated'),
                id: 'status_activity_updated',
            });
            handleSuccess && handleSuccess();
        },
    });
    const { mutate: createActivity, isPending: isCreating } = useMutation({
        mutationFn: createActivityRequest,
        onError: (err) => onErrorCallback(err),
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: QUERY_KEY });
            if (variables.projectId) {
                queryClient.invalidateQueries({
                    queryKey: getProjectActivitiesQueryKey(variables.projectId),
                });
                queryClient.invalidateQueries({
                    queryKey: [LAND_POINT_ACTIVITIES_QUERY_KEY],
                });
            }
            else {
                queryClient.invalidateQueries({
                    queryKey: [PROJECT_ACTIVITIES_QUERY_KEY],
                });
            }
            if (variables.contactId) {
                queryClient.invalidateQueries({
                    queryKey: getContactActivitiesQueryKey(variables.contactId),
                });
                queryClient.invalidateQueries({ queryKey: CONTACT_LIST_QUERY_KEY });
                // Pour le filtre "Uniquement avec des actions" dans MyPlotsPanel
                queryClient.invalidateQueries({ queryKey: USER_PLOTS_QUERY_KEY });
            }
            if (plotId) {
                queryClient.invalidateQueries({
                    queryKey: getPlotActivitiesQueryKey(plotId),
                });
            }
            else if (variables.plotId) {
                queryClient.invalidateQueries({
                    queryKey: getPlotActivitiesQueryKey(variables.plotId),
                });
            }
            getSuccessToast({
                title: t('activity.status.activity_created'),
                id: 'status_activity_created',
            });
            handleSuccess && handleSuccess();
        },
    });
    const { mutate: deleteActivity, isPending: isDeleting } = useMutation({
        mutationFn: deleteActivityRequest,
        onError: (err) => onErrorCallback(err),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: QUERY_KEY });
            if (data.projectId) {
                queryClient.invalidateQueries({
                    queryKey: getProjectActivitiesQueryKey(data.projectId),
                });
                queryClient.invalidateQueries({
                    queryKey: [LAND_POINT_ACTIVITIES_QUERY_KEY],
                });
            }
            else {
                queryClient.invalidateQueries({
                    queryKey: [PROJECT_ACTIVITIES_QUERY_KEY],
                });
            }
            if (data.contactId) {
                queryClient.invalidateQueries({
                    queryKey: getContactActivitiesQueryKey(data.contactId),
                });
                queryClient.invalidateQueries({ queryKey: CONTACT_LIST_QUERY_KEY });
                // Pour le filtre "Uniquement avec des actions" dans MyPlotsPanel
                queryClient.invalidateQueries({ queryKey: USER_PLOTS_QUERY_KEY });
            }
            if (plotId) {
                queryClient.invalidateQueries({
                    queryKey: getPlotActivitiesQueryKey(plotId),
                });
            }
            else if (data.plotId) {
                queryClient.invalidateQueries({
                    queryKey: getPlotActivitiesQueryKey(data.plotId),
                });
            }
            getSuccessToast({
                title: t('activity.status.activity_deleted'),
                id: 'status_activity_deleted',
            });
            handleDeleteSuccess && handleDeleteSuccess();
        },
    });
    return {
        activities,
        isLoading,
        plotActivities,
        isLoadingPlotActivities,
        contactActivities,
        contactActivitiesLoading,
        updateActivity,
        createActivity,
        deleteActivity,
        isUpdating,
        isDeleting,
        isCreating,
    };
};
export default useActivity;
