import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOEcologicalContinuityElementsLayerItem = IOLayerItem;
const fetchEcologicalContinuityElements = (townId) => () => get(iots.array(IOEcologicalContinuityElementsLayerItem), `legacy/${townId}/ecological-continuity-elements`);
const useEcologicalContinuityElementsLayer = () => {
    createLayersHook({
        fetch: fetchEcologicalContinuityElements,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.ECOLOGICAL_CONTINUITY_ELEMENTS);
};
const EcologicalContinuityElementsLayer = () => {
    useEcologicalContinuityElementsLayer();
    return null;
};
export default EcologicalContinuityElementsLayer;
