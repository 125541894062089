import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Stack } from '@chakra-ui/react';
import { t } from 'i18next';
import FirstStep from 'components/modals/contactModal/importContactsSteps/FirstStep';
import FourthStep from 'components/modals/contactModal/importContactsSteps/FourthStep';
import SecondStep from 'components/modals/contactModal/importContactsSteps/SecondStep';
import ThirdStep from 'components/modals/contactModal/importContactsSteps/ThirdStep';
import useImportContactSteps from 'components/modals/contactModal/importContactsSteps/useImportContactSteps';
import useImportPublipostage from 'hooks/import/useImportPublipostage';
import { formatFloat } from 'utils/formatNumber';
import ContactListPublipostage from './importPublipostageSteps/ContactListPublipostage';
import SourceContact from './importPublipostageSteps/SourceContactPublipostage';
import TemplateFilePublipostage from './importPublipostageSteps/TemplateFilePublipostage';
import UploadFilePublipostage from './importPublipostageSteps/UploadFilePublipostage';
import ValidatePublipostage from './importPublipostageSteps/ValidatePublipostage';
export const STEP_IMPORT_CONTACT = 2;
export const STEP_SELECT_CONTACT = 3;
export const STEP_UPLOAD_WORD = 4;
const ImportPublipostageForm = ({ closeDrawer, contactIds, setContactIds, }) => {
    const [step, setStep] = useState(1);
    const { templatesPublipostage, isFetchingTemplate } = useImportPublipostage();
    /** IMPORT FICHIER WORD **/
    // Le fichier word à remplir avec les infos des contacts
    const [fileContentWord, setFileContentWord] = useState(null);
    const handleFileDataWord = (data) => setFileContentWord(data);
    useEffect(() => {
        if (fileContentWord)
            setStep((prevStep) => prevStep + 1);
    }, [fileContentWord]);
    /** FIN IMPORT FICHIER WORD **/
    /** IMPORT CONTACT **/
    // Le fichier excel avec la liste des contacts à importer
    const goToNextStep = () => setStep((prevStep) => formatFloat(prevStep + 0.1));
    // Finalisation de l'étape d'importation des contacts via fichier excel
    const handleLastStepImportContact = (response) => {
        setContactIds(response.contactIds);
        setStep(STEP_UPLOAD_WORD);
    };
    const { handleFileData, handleSelectedTab, handleFieldsMapping, fileData, selectedTab, importData, } = useImportContactSteps(goToNextStep);
    const PreviousButton = (<Button size="sm" variant="outline" isLoading={false} loadingText={t('global.status.creating')} onClick={() => {
            if (step === STEP_SELECT_CONTACT) {
                setStep(1);
                return;
            }
            if (Number.isInteger(step)) {
                setStep(step - 1);
            }
            else {
                setStep(formatFloat(step - 0.1));
            }
        }} isDisabled={step === 1}>
      {t('global.previous')}
    </Button>);
    return (<Stack height="100%">
      {step === 1 ? (<SourceContact setStep={setStep}/>) : step === STEP_IMPORT_CONTACT ? (<FirstStep handleFileData={handleFileData} trackPrefix={'Publipostage - '}>
          {PreviousButton}
        </FirstStep>) : step === 2.1 ? (<SecondStep handleSelectedTab={handleSelectedTab} fileData={fileData} trackPrefix={'Publipostage - '}>
          {PreviousButton}
        </SecondStep>) : step === 2.2 ? (<ThirdStep fileData={fileData} selectedTab={selectedTab} handleNextStep={handleFieldsMapping} handleLastStep={handleLastStepImportContact} importData={importData} trackPrefix={'Publipostage - '} textLastStep="global.next">
          {PreviousButton}
        </ThirdStep>) : step === 2.3 ? (<FourthStep handleLastStep={handleLastStepImportContact} importData={importData} trackPrefix={'Publipostage - '} textLastStep="global.next">
          {PreviousButton}
        </FourthStep>) : step === STEP_SELECT_CONTACT ? (<ContactListPublipostage setStep={setStep} setContactIds={setContactIds}>
          {PreviousButton}
        </ContactListPublipostage>) : step === STEP_UPLOAD_WORD ? (<>
          <UploadFilePublipostage handleFileData={handleFileDataWord} nbTemplate={templatesPublipostage?.length ?? 0}>
            {PreviousButton}
          </UploadFilePublipostage>
          <TemplateFilePublipostage templates={templatesPublipostage} isFetching={isFetchingTemplate} handleFileData={handleFileDataWord}/>
        </>) : step === 5 ? (<ValidatePublipostage contactIds={contactIds} fileContent={fileContentWord} closeDrawer={closeDrawer}>
          {PreviousButton}
        </ValidatePublipostage>) : null}
    </Stack>);
};
export default ImportPublipostageForm;
