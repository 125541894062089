import * as React from 'react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Button, HStack, Icon, Text } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_DASHBOARD } from 'analytics/useAnalytics';
const DashboardButton = ({ onClick, icon, label, disabled = false, id, }) => {
    const { trackEvent } = useAnalytics();
    return (<Button width="full" backgroundColor="colorMode.base" color="colorMode.revertBase" onClick={() => {
            trackEvent({ category: ANALYTICS_DASHBOARD, action: label });
            onClick();
        }} rightIcon={<ArrowForwardIcon fill="currentcolor"/>} justifyContent="space-between" disabled={disabled} whiteSpace="normal" height="auto" blockSize="auto" padding="12px 10px" data-cy={id}>
      <HStack spacing={1}>
        <Icon as={icon} fill="currentcolor"/>
        <Text color="inherit">{label}</Text>
      </HStack>
    </Button>);
};
export default DashboardButton;
