import pick from 'lodash/pick';
import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getTileBoundingBox } from 'utils/geo';
import { getNormalizedCoordinates } from 'utils/map';
import { getBaseTileUrlHost3 } from 'utils/multiplexage';
import { CST_POSITIONS } from 'utils/tilesPositions';
const URL_KEYS = {
    [filtersKey.ROAD_NOISE]: 'classement_sonore',
    [filtersKey.RAILWAY_NOISE]: 'classement_sonore_voies_ferrees',
};
const getLayerTileUrl = (map, tileWidth, tileHeight, enabledFilters) => (coord, zoom) => {
    const normCoord = getNormalizedCoordinates(coord, zoom);
    if (!normCoord) {
        return null;
    }
    const bbox = getTileBoundingBox(coord, zoom, map, tileWidth, tileHeight);
    const urlParts = Object.values(pick(URL_KEYS, enabledFilters));
    return (getBaseTileUrlHost3() +
        'cgi-bin/mm/mapserv.cgi?map=/var/data/public_maps/project_classement_sonore.map&SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=' +
        urlParts.join(',') +
        '&TILED=true&WIDTH=' +
        tileWidth +
        '&HEIGHT=' +
        tileHeight +
        '&CRS=EPSG:4326&STYLES=&BBOX=' +
        bbox);
};
const getLegends = (enabledFilters) => {
    const legends = {};
    if (enabledFilters.includes(filtersKey.ROAD_NOISE)) {
        legends.road_noise_1 = {
            layerKey: filtersKey.ROAD_NOISE,
            label: 'road_railway_noise_1',
            needsTranslate: true,
            color: '#A900FF',
            opacity: 0.6,
        };
        legends.road_noise_2 = {
            layerKey: filtersKey.ROAD_NOISE,
            label: 'road_railway_noise_2',
            needsTranslate: true,
            color: '#D78AFD',
            opacity: 0.6,
        };
        legends.road_noise_3 = {
            layerKey: filtersKey.ROAD_NOISE,
            label: 'road_railway_noise_3',
            needsTranslate: true,
            color: '#FD0100',
            opacity: 0.6,
        };
        legends.road_noise_4 = {
            layerKey: filtersKey.ROAD_NOISE,
            label: 'road_railway_noise_4',
            needsTranslate: true,
            color: '#FEAD01',
            opacity: 0.6,
        };
        legends.road_noise_5 = {
            layerKey: filtersKey.ROAD_NOISE,
            label: 'road_railway_noise_5',
            needsTranslate: true,
            color: '#FEFE56',
            opacity: 0.6,
        };
    }
    if (enabledFilters.includes(filtersKey.RAILWAY_NOISE)) {
        legends.railway_noise_1 = {
            layerKey: filtersKey.RAILWAY_NOISE,
            label: 'road_railway_noise_1',
            needsTranslate: true,
            color: '#A900FF',
            opacity: 0.6,
        };
        legends.railway_noise_2 = {
            layerKey: filtersKey.RAILWAY_NOISE,
            label: 'road_railway_noise_2',
            needsTranslate: true,
            color: '#D78AFD',
            opacity: 0.6,
        };
        legends.railway_noise_3 = {
            layerKey: filtersKey.RAILWAY_NOISE,
            label: 'road_railway_noise_3',
            needsTranslate: true,
            color: '#FD0100',
            opacity: 0.6,
        };
        legends.railway_noise_4 = {
            layerKey: filtersKey.RAILWAY_NOISE,
            label: 'road_railway_noise_4',
            needsTranslate: true,
            color: '#FEAD01',
            opacity: 0.6,
        };
        legends.railway_noise_5 = {
            layerKey: filtersKey.RAILWAY_NOISE,
            label: 'road_railway_noise_5',
            needsTranslate: true,
            color: '#FEFE56',
            opacity: 0.6,
        };
    }
    return legends;
};
const useRoadRailwayNoiseLayer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS.ROAD_RAILWAY_FILTERS,
        getLegends,
    })(ROAD_RAILWAY_FILTERS);
};
export const ROAD_RAILWAY_FILTERS = [
    filtersKey.ROAD_NOISE,
    filtersKey.RAILWAY_NOISE,
];
const RoadRailwayNoiseLayer = () => {
    useRoadRailwayNoiseLayer();
    return null;
};
export default RoadRailwayNoiseLayer;
