import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { IOPolygonLayerItem } from 'types/api';
const IOPlotBuildingsInfo = iots.intersection([
    IOPolygonLayerItem,
    iots.type({
        footprint: iots.number,
        floorArea: iots.union([iots.number, iots.null]),
        purpose: iots.string,
        constructionYear: iots.number,
        eavesHeight: iots.union([iots.number, iots.null]),
        levelsNumber: iots.number,
        accommodationNumber: iots.number,
        footprintFullPlot: iots.number,
        floorAreaFullPlot: iots.union([iots.number, iots.null]),
        roofMaterials: iots.string,
        wallsMaterials: iots.string,
        nature: iots.string,
        use: iots.string,
        subUse: iots.string,
        lightweightBuild: iots.string,
        buildingId: iots.string,
        plotId: iots.string,
    }),
], 'IOPlotBuildingsInfo');
export const SELECTED_BUILDING_BORDER = {
    borderColor: '#000000',
    borderWidth: 3,
};
export const fetchPlotBuildings = (townshipId, plotId) => () => get(iots.array(IOPlotBuildingsInfo), `legacy/${townshipId}/plot-buildings/${plotId}`);
export const QUERY_KEY = 'plot-buildings';
export const getQueryKey = (townshipId, plotId) => [
    QUERY_KEY,
    townshipId,
    plotId,
];
const usePlotBuildings = (currentPlotId, currentPlotTownId, enabled) => {
    const { data, isInitialLoading } = useQuery({
        queryKey: getQueryKey(currentPlotTownId, currentPlotId),
        queryFn: fetchPlotBuildings(currentPlotTownId, currentPlotId),
        enabled: !!currentPlotTownId && !!currentPlotId && enabled,
    });
    return { data, isInitialLoading };
};
export default usePlotBuildings;
