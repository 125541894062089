import { useMemo } from 'react';
import { useQueries } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { IOPolygonLayerItem } from 'types/api';
const IOPlotPotentialBuildingsInfo = iots.intersection([
    IOPolygonLayerItem,
    iots.type({
        id: iots.string,
        plotId: iots.string,
    }),
], 'IOPlotPotentialBuildingsInfo');
export const fetchPlotPotentialBuildings = (townshipId, cadastreId) => () => get(iots.array(IOPlotPotentialBuildingsInfo), `legacy/${townshipId}/plot-potential-buildings/${cadastreId}`);
export const QUERY_KEY = 'plot-potential-buildings';
export const getQueryKey = (townshipId, cadastreId) => [
    QUERY_KEY,
    townshipId,
    cadastreId,
];
const usePlotsPotentialBuildings = (plots) => {
    const results = useQueries({
        queries: Object.keys(plots).map((plotId) => {
            const plot = plots[plotId];
            return {
                queryKey: getQueryKey(plot.townId, plot.cadastreId),
                queryFn: fetchPlotPotentialBuildings(plot.townId, plot.cadastreId),
                enabled: !!plot.townId && !!plot.cadastreId,
                select: (data) => {
                    return data.map((building) => ({ ...building, plotId }));
                },
            };
        }),
    });
    const buildingsIds = [];
    results.forEach((result) => {
        const data = (result.data || []);
        data.forEach((building) => {
            buildingsIds.push(building.id);
        });
    });
    const isLoading = results.some((result) => result.isLoading);
    const data = useMemo(() => {
        const buildings = {};
        results.forEach((result) => {
            const data = result.data || [];
            data.forEach((building) => {
                buildings[building.id] = building;
            });
        });
        return buildings;
    }, [JSON.stringify(buildingsIds)]);
    return { data, isLoading };
};
export default usePlotsPotentialBuildings;
