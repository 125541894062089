import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';
import { getActivityStatusColorFromLabel } from 'utils/constants';
const ActionStatus = ({ label, disabled, }) => {
    const { t } = useTranslation();
    return (<Box bg={getActivityStatusColorFromLabel(label)} display="inline-block" paddingX={3} paddingY={1} borderRadius="50px" color="white" fontWeight="bold" opacity={disabled ? 0.3 : 1}>
      {t('activity.fields.common.delay.options.' + label)}
    </Box>);
};
export default ActionStatus;
