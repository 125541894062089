import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Heading, HStack, IconButton } from '@chakra-ui/react';
import ArrowLeftIcon from 'images/icons/ArrowLeft';
import ArrowRightIcon from 'images/icons/ArrowRight';
const CustomHeader = ({ calendarApi, viewTitle, viewType, }) => {
    const { t } = useTranslation();
    return (<Box bg="colorMode.background700" p={2} borderTopRadius="md">
      <HStack justifyContent="space-between">
        <HStack>
          <Heading as="h3" variant="h3" textTransform="capitalize" paddingEnd={2}>
            {viewTitle}
          </Heading>
          <Box>
            <IconButton onClick={() => {
            calendarApi?.prev();
        }} icon={<ArrowLeftIcon fill="currentColor"/>} aria-label="previous" marginRight={1} size="sm" variant="ghost"/>
            <IconButton onClick={() => {
            calendarApi?.next();
        }} icon={<ArrowRightIcon fill="currentColor"/>} aria-label="next" size="sm" variant="ghost"/>
          </Box>
        </HStack>
        {viewType === 'dayGridMonth' && (<Button size="sm" variant="ghost" fontWeight="bold" onClick={() => {
                calendarApi?.changeView('dayGridDay');
                calendarApi?.today();
            }}>
            {t('activity.calendar.today')}
          </Button>)}
        {viewType === 'dayGridDay' && (<Button size="sm" variant="ghost" fontWeight="bold" onClick={() => {
                calendarApi?.changeView('dayGridMonth');
            }}>
            {t('activity.calendar.month')}
          </Button>)}
      </HStack>
    </Box>);
};
export default CustomHeader;
