import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getLayerCoordTileUrl } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = () => (coord, zoom) => {
    return getLayerCoordTileUrl('GEOGRAPHICALGRIDSYSTEMS.SLOPES.MOUNTAIN', 'normal', zoom, coord);
};
const getLegends = () => {
    const legends = {};
    legends.incline_30_35 = {
        layerKey: filtersKey.INCLINE_30,
        label: 'incline_30_35',
        needsTranslate: true,
        color: '#F1E70B',
        opacity: 0.8,
    };
    legends.incline_35_40 = {
        layerKey: filtersKey.INCLINE_30,
        label: 'incline_35_40',
        needsTranslate: true,
        color: '#F86F21',
        opacity: 0.8,
    };
    legends.incline_40_45 = {
        layerKey: filtersKey.INCLINE_30,
        label: 'incline_40_45',
        needsTranslate: true,
        color: '#E3035B',
        opacity: 0.8,
    };
    legends.incline_45 = {
        layerKey: filtersKey.INCLINE_30,
        label: 'incline_45',
        needsTranslate: true,
        color: '#CB87BA',
        opacity: 0.8,
    };
    return legends;
};
const useIncline30Layer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.INCLINE_30],
        getLegends,
        opacity: 0.42,
    })(filtersKey.INCLINE_30);
};
const Incline30Layer = () => {
    useIncline30Layer();
    return null;
};
export default Incline30Layer;
