import * as React from 'react';
import { Stack } from '@chakra-ui/react';
import { SPACING_PANEL } from 'utils/constants';
import PlotInfoDetail from './PlotInfoDetail';
const PlotInfo = ({ plot }) => {
    return (<Stack spacing={SPACING_PANEL} padding={4} alignItems="start">
      <PlotInfoDetail plot={plot}/>
    </Stack>);
};
export default PlotInfo;
