import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CopyIcon } from '@chakra-ui/icons';
import { HStack, Icon, IconButton, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Text, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_MAP_BUTTONS } from 'analytics/useAnalytics';
import useCenterCross from 'hooks/map/useCenterCross';
import useComputedCenter from 'hooks/map/useComputedCenter';
import useCustomToast from 'hooks/useCustomToast';
import EarthIcon from 'images/icons/Earth';
import { toolbarButtonsStyle } from './LayersButtons';
const ComputerCenterButton = () => {
    const { getSuccessToast } = useCustomToast();
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const computedCenter = useComputedCenter();
    const toggleCenterCross = useCenterCross();
    const copy = useCallback(async (text) => {
        await navigator.clipboard.writeText(text);
        getSuccessToast({
            title: t('toolbar.coordinates.copied'),
            id: 'toolbar_coordinates_copied',
        });
    }, [t]);
    if (!computedCenter)
        return null;
    return (<Popover onOpen={() => toggleCenterCross(true)} onClose={() => toggleCenterCross(false)} closeOnBlur={false} isLazy>
      <PopoverTrigger>
        <IconButton {...toolbarButtonsStyle} aria-label="coordinates" marginRight={2} width={10} color="colorMode.font800" icon={<Icon as={EarthIcon} width={5} height={5} fill="currentColor"/>} onClick={() => {
            trackEvent({
                category: ANALYTICS_MAP_BUTTONS,
                action: 'Coordonnées',
            });
        }} data-cy="layers_button_coordinates"/>
      </PopoverTrigger>
      <PopoverContent width="160px">
        <PopoverArrow />
        <PopoverHeader>
          <HStack>
            <Text textStyle="paragraph">{t('toolbar.coordinates.title')}</Text>
            <IconButton size="xs" variant="secondary" onClick={() => {
            trackEvent({
                category: ANALYTICS_MAP_BUTTONS,
                action: 'Coordonnées copier',
            });
            copy(`${computedCenter.lat().toFixed(7)} ${computedCenter
                .lng()
                .toFixed(7)}`);
        }} icon={<CopyIcon />} aria-label="copy-button"/>
          </HStack>
        </PopoverHeader>
        <PopoverCloseButton />
        <PopoverBody>
          <Text textStyle="paragraph">
            {`${t('toolbar.latitude')} : ${computedCenter.lat().toFixed(7)}`}
          </Text>
          <Text textStyle="paragraph">
            {`${t('toolbar.longitude')} : ${computedCenter.lng().toFixed(7)}`}
          </Text>
        </PopoverBody>
      </PopoverContent>
    </Popover>);
};
export default ComputerCenterButton;
