import * as React from 'react';
import { memo } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { Stack, Text } from '@chakra-ui/react';
import snakeCase from 'lodash/snakeCase';
import SirenLink from 'components/links/SirenLink';
import Table from 'components/table/Table';
import BankruptCompaniesOwnedPlotsButton from './BankruptCompaniesOwnedPlotsButton';
const initialSort = [
    {
        id: 'town',
        desc: false,
    },
];
const EXPORTED_FIELDS = [
    'department',
    'town',
    'name',
    'siren',
    'role',
    'countOwnedPlots',
];
export const getColumnHeader = (column, suffix = null) => (<Text align="center">
    <Translation>
      {(t) => t(`tabs.extra_tabs.company_owned_manager.columns.${column}`)}
    </Translation>
    {suffix}
  </Text>);
const formatExportHeader = (t) => (header) => t(`tabs.extra_tabs.company_owned_manager.columns.${snakeCase(header)}`);
const CompaniesOwnedByManagerTable = ({ data, firstname, name, isLoading }) => {
    const { t } = useTranslation();
    const getColumns = () => [
        {
            id: 'columns',
            columns: [
                {
                    accessorKey: 'department',
                    header: () => getColumnHeader('department'),
                    cell: (info) => info.getValue(),
                    filterFn: 'arrIncludesSome',
                },
                {
                    accessorKey: 'town',
                    header: () => getColumnHeader('town'),
                    cell: (info) => info.getValue(),
                    filterFn: 'arrIncludesSome',
                },
                {
                    accessorKey: 'name',
                    header: () => getColumnHeader('name'),
                    cell: (info) => info.getValue(),
                    filterFn: 'arrIncludesSome',
                },
                {
                    accessorKey: 'siren',
                    header: () => getColumnHeader('siren'),
                    cell: (info) => {
                        return (<SirenLink siren={info.getValue()} analyticDetail="Pappers sociétés dirigeant" showExternalLink/>);
                    },
                    filterFn: 'arrIncludesSome',
                },
                {
                    accessorKey: 'role',
                    header: () => getColumnHeader('role'),
                    cell: (info) => info.getValue(),
                    filterFn: 'arrIncludesSome',
                },
                {
                    accessorKey: 'countOwnedPlots',
                    header: () => getColumnHeader('count_owned_plots'),
                    cell: (info) => {
                        return (<BankruptCompaniesOwnedPlotsButton data={{
                                siren: info.row.original.siren,
                                countOwnedPlots: info.row.original.countOwnedPlots,
                            }}/>);
                    },
                    filterFn: 'arrIncludesSome',
                },
            ],
        },
    ];
    return (<Stack paddingX={4} height="calc(100% - 20px)">
      <Table variant="simple" id="companies-owned-by-manager" columns={getColumns()} data={data} initialSort={initialSort} isLoading={isLoading} exportTitle={t(`tabs.extra_tabs.company_owned_manager.title`) +
            ' ' +
            firstname +
            ' ' +
            name} exportedFields={EXPORTED_FIELDS} formatExportHeader={formatExportHeader(t)}/>
    </Stack>);
};
export default memo(CompaniesOwnedByManagerTable);
