import { useEffect, useState } from 'react';
import { useMap } from 'hooks/contexts/useMapContext';
const useComputedZoom = () => {
    const map = useMap();
    const [computedZoom, setComputedZoom] = useState(null);
    useEffect(() => {
        if (map) {
            setComputedZoom(map.getZoom());
            map.addListener('zoom_changed', () => {
                setComputedZoom(map.getZoom());
            });
        }
    }, [map]);
    return computedZoom;
};
export default useComputedZoom;
