import * as React from 'react';
import { Icon, IconButton, VStack } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_MAP_BUTTONS } from 'analytics/useAnalytics';
import { useMap, useSetZoomStreetView } from 'hooks/contexts/useMapContext';
import useComputedZoom from 'hooks/map/useComputedZoom';
import ZoomMoinsIcon from 'images/icons/ZoomMoins';
import ZoomPlusIcon from 'images/icons/ZoomPlus';
const ZoomControl = ({ isStreetView }) => {
    const map = useMap();
    const computedZoom = useComputedZoom();
    const { trackEvent } = useAnalytics();
    const setZoomStreetView = useSetZoomStreetView();
    const setZoom = isStreetView
        ? setZoomStreetView
        : (increment) => {
            map.setZoom(computedZoom + increment);
        };
    return (<VStack backgroundColor="colorMode.background800" borderRadius="md" spacing={0} boxShadow="lg">
      <IconButton aria-label="zoom-plus" variant="ghost" onClick={() => {
            trackEvent({
                category: ANALYTICS_MAP_BUTTONS,
                action: 'Zoom',
            });
            setZoom(1);
        }} height="32px" minWidth="32px" color="blue.500" icon={<Icon as={ZoomPlusIcon} fill="currentColor"/>}/>
      <IconButton aria-label="zoom-less" variant="ghost" onClick={() => {
            trackEvent({
                category: ANALYTICS_MAP_BUTTONS,
                action: 'Dézoom',
            });
            setZoom(-1);
        }} height="32px" minWidth="32px" color="blue.500" icon={<Icon as={ZoomMoinsIcon} fill="currentColor"/>}/>
    </VStack>);
};
export default ZoomControl;
