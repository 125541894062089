import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { useColorModeValue } from '@chakra-ui/react';
const getColorBadge = (color) => {
    return color
        ? {
            alignItems: 'center',
            display: 'flex',
            ':before': {
                backgroundColor: color,
                borderRadius: '4px',
                content: '" "',
                marginRight: '5px',
                height: '12px',
                width: '16px',
                flexShrink: 0,
            },
        }
        : {};
};
const StatusSelect = ({ value = 0, data, onChange, isLoading, placeholder, isDisabled, minWidth, minHeight, ...rest }) => {
    const [currentValue, setCurrentValue] = React.useState(value);
    const { t } = useTranslation();
    const selectText = useColorModeValue('#0C1B3C', '#FBFAF9');
    const selectBackground = useColorModeValue('#FFFFFF', '#132C5E');
    const selectHoverBackground = useColorModeValue('#D9D5C9', '#15326A');
    React.useEffect(() => {
        if (value) {
            setCurrentValue(value);
        }
    }, [value]);
    const selectStyles = {
        indicatorSeparator: () => null,
        control: (provided, { isDisabled }) => ({
            ...provided,
            backgroundColor: selectBackground,
            borderColor: '#F2F2F2',
            borderRadius: '10px',
            color: selectText,
            fontSize: '12px',
            fontWeight: 500,
            fontFamily: 'IBM-plex-sans',
            minHeight: minHeight ?? '32px',
            minWidth: minWidth ?? 'auto',
            opacity: isDisabled ? 0.6 : 1,
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            padding: '5px',
        }),
        menuPortal: (base) => ({ ...base, zIndex: 300 }),
        menu: (provided) => ({ ...provided, zIndex: 300 }),
        menuList: (provided) => ({
            ...provided,
            backgroundColor: selectBackground,
        }),
        option: (provided, { data, isFocused }) => ({
            ...provided,
            color: selectText,
            backgroundColor: isFocused ? selectHoverBackground : 'transparent',
            fontSize: '12px',
            textAlign: 'left',
            ...getColorBadge(data.color),
        }),
        placeholder: (provided) => ({
            ...provided,
            ...getColorBadge('#ccc'),
            color: selectText,
        }),
        singleValue: (provided, { data }) => ({
            ...provided,
            color: selectText,
            marginLeft: 0,
            marginRight: 0,
            ...getColorBadge(data.color),
        }),
        valueContainer: (provided) => ({
            ...provided,
            position: 'relative',
            padding: '0 0 0 8px',
        }),
    };
    return (<Select {...rest} placeholder={placeholder ?? t('tabs.plots.status_placeholder')} value={data.find((option) => option.value === currentValue)} options={data} styles={selectStyles} onChange={(e) => {
            setCurrentValue(e.value);
            onChange(e);
        }} menuPlacement="auto" menuPosition="fixed" isLoading={isLoading} id="plot_panel_status" classNamePrefix="react-select" isDisabled={isLoading || isDisabled}/>);
};
export default StatusSelect;
