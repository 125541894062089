import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOStudyPerimeterLayerItem = IOLayerItem;
const fetchStudyPerimeter = (townId) => () => get(iots.array(IOStudyPerimeterLayerItem), `legacy/${townId}/study-perimeter`);
const useStudyPerimeterLayer = () => {
    createLayersHook({
        fetch: fetchStudyPerimeter,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.STUDY_PERIMETER);
};
const StudyPerimeterLayer = () => {
    useStudyPerimeterLayer();
    return null;
};
export default StudyPerimeterLayer;
