import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, } from '@chakra-ui/react';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { formatNumberToLocale } from 'utils/formatNumber';
const RENT_INFO_KEYS = ['house', 'apartment', 'T1T2', 'T3AndMore'];
const formatRentData = ({ rentData, i18n, t, }) => {
    if (!rentData)
        return t('global.unknown');
    return `${formatNumberToLocale(rentData, i18n.language, 1)} €/m²`;
};
export const getRentData = ({ selectedTown, i18n, t, }) => {
    return RENT_INFO_KEYS.map((key) => ({
        header: t('tabs.plots.section.neighborhood.town_economic_information.rent.' + key),
        data: formatRentData({
            rentData: (selectedTown?.rent ?? {})[key],
            t,
            i18n,
        }),
        key,
    }));
};
const RentInfo = () => {
    const selectedTown = useSelectedTown();
    const { t, i18n } = useTranslation();
    const rentData = React.useMemo(() => getRentData({ selectedTown, i18n, t }), [selectedTown]);
    return (<>
      <Text textStyle="paragraph">
        {t('tabs.plots.section.neighborhood.town_economic_information.rent.title')}
      </Text>
      <TableContainer marginTop={1} marginBottom={3}>
        <Table variant="simple">
          <Thead>
            <Tr>
              {rentData?.map((el, key) => (<Th key={key}>
                  <Text textAlign="center">{el?.header}</Text>
                </Th>))}
            </Tr>
          </Thead>
          <Tbody>
            <Tr borderBottom="1px solid" borderBottomColor="colorMode.background600">
              {rentData?.map((el, key) => (<Td textAlign="right" key={key}>
                  {el?.data}
                </Td>))}
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </>);
};
export default RentInfo;
