import * as React from 'react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Icon, Link } from '@chakra-ui/react';
export const ExternalLink = ({ url, text, onClick = () => { }, }) => {
    return (<Link href={url} isExternal variant="primary" onClick={onClick}>
      <Icon as={ExternalLinkIcon} width="16px" height="16px" fill="currentColor" marginRight="3px" marginLeft="7px" marginBottom="2px"/>
      {text}
    </Link>);
};
export default ExternalLink;
