export const getRecipient = (data) => {
    let recipient = data.civility +
        ' ' +
        data.firstname +
        ' ' +
        data.lastname +
        ' ' +
        data.address;
    if (data.addressBP) {
        recipient += ', ' + data.addressBP;
    }
    if (data.addressComplement) {
        recipient += ', ' + data.addressComplement;
    }
    if (data.postalCode) {
        recipient += ' - ' + data.postalCode;
    }
    if (data.town) {
        recipient += ' ' + data.town;
    }
    return recipient;
};
export const getSender = (data) => {
    let recipient = data.civilitySender + ' ' + data.firstnameSender + ' ' + data.lastnameSender;
    if (data.companySender) {
        recipient += ', ' + data.companySender;
    }
    recipient += ' ' + data.addressSender;
    if (data.addressBPSender) {
        recipient += ', ' + data.addressBPSender;
    }
    if (data.addressComplementSender) {
        recipient += ', ' + data.addressComplementSender;
    }
    if (data.postalCodeSender) {
        recipient += ' - ' + data.postalCodeSender;
    }
    if (data.townSender) {
        recipient += ' ' + data.townSender;
    }
    return recipient;
};
