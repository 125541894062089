import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControl, FormLabel, Select, Text, } from '@chakra-ui/react';
import { ACTIVITY_STATUS_OPTIONS } from 'utils/constants';
const ActivityStatusInput = ({ activity, type = 'select', hideLabel = false, updateActivity, isLoading, label, }) => {
    const { t } = useTranslation();
    const options = ACTIVITY_STATUS_OPTIONS.map((option) => ({
        value: option?.value,
        label: t(option?.label),
    }));
    const handleChange = (newValue) => {
        {
            setSelectedOption(options?.find((el) => el?.value === newValue));
            updateActivity({
                ...activity,
                status: newValue,
                updateOnlyOne: 1,
            });
        }
    };
    const [selectedOption, setSelectedOption] = React.useState(ACTIVITY_STATUS_OPTIONS.find((option) => option.value === activity.status));
    useEffect(() => {
        setSelectedOption(ACTIVITY_STATUS_OPTIONS.find((option) => option.value === activity.status));
    }, [activity]);
    if (type === 'select')
        return (<FormControl>
        {label && <FormLabel as="legend">{label}</FormLabel>}
        <Select isDisabled={isLoading} value={selectedOption?.value} onChange={(e) => handleChange(+e?.target?.value)} size="sm">
          {options.map((option) => (<option key={option?.value} value={option?.value}>
              {option?.label}
            </option>))}
        </Select>
      </FormControl>);
    return (<Checkbox defaultChecked={selectedOption?.value === 1} onChange={(e) => handleChange(e.target.checked ? 1 : 0)} disabled={isLoading}>
      {!hideLabel && (<Text fontSize="sm" fontWeight="bold">
          {selectedOption?.value === 0
                ? t('activity.actions.mark_done')
                : t('activity.actions.mark_not_done')}
        </Text>)}
    </Checkbox>);
};
export default ActivityStatusInput;
