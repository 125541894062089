import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IONonAedificandiAreaLayerItem = IOLayerItem;
const fetchNonAedificandiArea = (townId) => () => get(iots.array(IONonAedificandiAreaLayerItem), `legacy/${townId}/non-aedificandi-area`);
const useNonAedificandiAreaLayer = () => {
    createLayersHook({
        fetch: fetchNonAedificandiArea,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.NON_AEDIFICANDI_AREA);
};
const NonAedificandiAreaLayer = () => {
    useNonAedificandiAreaLayer();
    return null;
};
export default NonAedificandiAreaLayer;
