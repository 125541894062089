import { useEffect, useMemo } from 'react';
import { useQueries } from '@tanstack/react-query';
import * as iots from 'io-ts';
import getNested from 'lodash/get';
import set from 'lodash/set';
import unionWith from 'lodash/unionWith';
import { get } from 'api/client';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import useCurrentSite from 'hooks/useCurrentSite';
import { IOEmpty } from 'types/api';
import { filtersAreEqual, IOFiltersTree, } from 'utils/filters';
const mergeCategory = (cat1, cat2) => {
    const { layers, ...extraData } = cat2;
    return {
        ...(cat1 ?? {}),
        ...(extraData || {}),
        layers: unionWith(cat1.layers || [], layers || [], filtersAreEqual),
    };
};
const useLayersTreeAdditionalData = (endpoints, updateLayersTree, enabled) => {
    const selectedTown = useSelectedTown();
    const { currentSite } = useCurrentSite();
    const queries = useMemo(() => endpoints.map(({ categoryId, endpoint }) => ({
        queryKey: [
            'layersTree',
            categoryId,
            endpoint,
            {
                townshipId: selectedTown?.id,
                townshipScot: selectedTown?.scot,
                hasPLU: selectedTown?.hasPLU,
                siteId: currentSite?.legacyId,
            },
        ],
        queryFn: () => get(iots.union([IOFiltersTree, IOEmpty]), endpoint, {
            siteId: currentSite?.legacyId,
        }, true),
        enabled: !!endpoint && enabled,
    })), [JSON.stringify(endpoints)]);
    const queriesResult = useQueries({ queries });
    const isLoading = queriesResult.some((result) => result.isLoading);
    useEffect(() => {
        if (isLoading || !enabled || queriesResult.length === 0) {
            return;
        }
        updateLayersTree((layersTree) => {
            const newTreeData = { ...layersTree };
            queriesResult
                .filter((queryResult) => queryResult.isSuccess)
                .forEach((queryResult) => {
                Object.keys(queryResult?.data).forEach((category) => {
                    const categoryData = getNested(newTreeData, category);
                    const updatedCategory = mergeCategory(categoryData || {}, queryResult.data[category]);
                    set(newTreeData, category, updatedCategory);
                });
            });
            return newTreeData;
        });
    }, [isLoading, JSON.stringify(queriesResult)]);
    return isLoading;
};
export default useLayersTreeAdditionalData;
