import * as React from 'react';
import { renderToString } from 'react-dom/server';
import { Translation } from 'react-i18next';
import { Box, Link } from '@chakra-ui/react';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { filtersKey } from 'utils/filters';
export const PLU_ZOOM_LIMIT = 12;
const fetchPLUAdditional = (townId) => () => get(null, `plu-additional/${townId}`);
const getInfoWindowContent = (layerInfo) => {
    return renderToString(<>
      <Box>
        <Translation>{(t) => t('global.zone')}</Translation> : {layerInfo.zone}
      </Box>
      <Box>
        <Translation>{(t) => t('global.subZone')}</Translation> :{' '}
        {layerInfo.subZone}
      </Box>
      <Box>{layerInfo.description}</Box>
      {layerInfo.url && (<Box>
          <Link href={layerInfo.url} isExternal>
            <Translation>
              {(t) => t('sidebar.download_regulation', { zone: layerInfo.zone })}
            </Translation>
          </Link>
        </Box>)}
    </>);
};
const usePLUAdditionalLayer = () => {
    createLayersHook({
        fetch: fetchPLUAdditional,
        getInfoWindowContent: getInfoWindowContent,
    })(filtersKey.PLU_ADDITIONAL);
};
const PLUAdditionalLayer = () => {
    usePLUAdditionalLayer();
    return null;
};
const PLUAdditionalLayerWrapper = ({ computedZoom, }) => {
    if (computedZoom < PLU_ZOOM_LIMIT) {
        return null;
    }
    return <PLUAdditionalLayer />;
};
export default PLUAdditionalLayerWrapper;
