import * as React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { FormControl, FormLabel, Heading, HStack, Input, Stack, } from '@chakra-ui/react';
import AreaInputWrapper from 'components/inputs/AreaInputWrapper';
import { multiSelectStyles } from 'components/inputs/MultiSelectInput';
import { useIsLoadingLayersTree, useMainLayers, } from 'hooks/contexts/useLayersTreeContext';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { accordionBoxShadow } from 'utils/accordionArrowStyle';
import { getTownPlotsInfo } from 'utils/filters';
import { BUILDABLE_OPTIONS_KEY } from 'utils/plotsSearch';
import { isSwissSite } from 'utils/site';
export const baseSearchFields = [
    'zones',
    'zonesApplicable',
    'plotArea',
    'buildable',
    'allowedFloorArea',
    'permittedBuildingFootprint',
    'authorizedLevel',
];
const BaseSearch = ({ isLoadingSearchData, hasSearchField, form, }) => {
    const { control, register } = form;
    const mainLayers = useMainLayers();
    const isLoadingLayersTree = useIsLoadingLayersTree();
    const selectedTown = useSelectedTown();
    const isLoading = isLoadingSearchData || isLoadingLayersTree;
    const { t } = useTranslation();
    const buildableOptions = ['2', '3', '1'].map((value) => ({
        value: value,
        label: t(BUILDABLE_OPTIONS_KEY[value]),
    }));
    const townPlotsInfo = getTownPlotsInfo(mainLayers);
    const formattedZones = townPlotsInfo?.zones && townPlotsInfo.zones.length > 0
        ? townPlotsInfo.zones.map((zone) => {
            return { label: zone, value: zone };
        })
        : null;
    const formattedZonesAdditional = townPlotsInfo?.zonesAdditional && townPlotsInfo.zonesAdditional.length > 0
        ? townPlotsInfo.zonesAdditional.map((zone) => {
            return { label: zone, value: zone };
        })
        : [];
    const hasSearchFields = (fieldNames) => fieldNames.some(hasSearchField);
    const getLabel = (label) => {
        if (isSwissSite() && label === 'plu_areas') {
            return t('sidebar.search_plots.' + label + '_swiss');
        }
        return t('sidebar.search_plots.' + label);
    };
    return (<Stack alignItems="start" bgColor="colorMode.sidebar500" boxShadow={accordionBoxShadow} paddingY={4} paddingX={4} width="100%" spacing={2}>
      {hasSearchField('zones') &&
            (formattedZones?.length !== 0 || formattedZones === null) && (<>
            <FormControl isDisabled={isLoading} data-cy="plu_zone_select">
              <FormLabel htmlFor="zones" marginBottom={1}>
                {selectedTown?.additionalPLU
                ? t('sidebar.search_plots.plu_areas_decided')
                : getLabel('plu_areas')}
              </FormLabel>
              <Controller name="zones" control={control} render={({ field }) => (<Select {...field} placeholder={t('sidebar.search_plots.placeholder_plu_areas')} noOptionsMessage={() => t('sidebar.search_plots.no_data_plu_areas')} closeMenuOnSelect={false} isMulti options={formattedZones ?? []} styles={multiSelectStyles} isLoading={isLoadingLayersTree || townPlotsInfo === null} loadingMessage={() => t('global.loading')}/>)}/>
            </FormControl>
            {selectedTown?.additionalPLU && (<FormControl isDisabled={isLoading}>
                <FormLabel htmlFor="zonesApplicable" marginBottom={1}>
                  {t('sidebar.search_plots.plu_areas_applicable')}
                </FormLabel>
                <Controller name="zonesApplicable" control={control} render={({ field }) => (<Select {...field} placeholder={t('sidebar.search_plots.placeholder_plu_areas_applicable')} noOptionsMessage={() => t('sidebar.search_plots.no_data_plu_areas')} closeMenuOnSelect={false} isMulti options={formattedZonesAdditional} styles={multiSelectStyles} isLoading={isLoadingLayersTree || townPlotsInfo === null} loadingMessage={() => t('global.loading')}/>)}/>
              </FormControl>)}
          </>)}

      {hasSearchField('plotArea') && (<FormControl isDisabled={isLoading}>
          <FormLabel htmlFor="plotAreaMin" marginBottom={1}>
            {t('sidebar.search_plots.plot_area')}
          </FormLabel>
          <HStack>
            <AreaInputWrapper rightElementLabel="m²">
              <Input size="sm" placeholder="Min" {...register('plotAreaMin')} type="number" data-cy="min_area_input"/>
            </AreaInputWrapper>
            <AreaInputWrapper rightElementLabel="m²">
              <Input size="sm" placeholder="Max" {...register('plotAreaMax')} type="number" data-cy="max_area_input"/>
            </AreaInputWrapper>
          </HStack>
        </FormControl>)}

      {selectedTown?.hasFeasibility &&
            hasSearchFields([
                'buildable',
                'allowedFloorArea',
                'permittedBuildingFootprint',
                'authorizedLevel',
            ]) && (<>
            {hasSearchField('buildable') && (<FormControl isDisabled={isLoading}>
                <Heading as="h5" variant="h5" fontWeight={500}>
                  {selectedTown?.additionalPLU
                    ? t('sidebar.search_plots.feasibility_decided')
                    : t('sidebar.search_plots.feasibility')}
                </Heading>
                <FormLabel marginBottom={1}>
                  {t('sidebar.search_plots.buildable')}
                </FormLabel>
                <Controller name="buildable" control={control} render={({ field }) => (<Select {...field} placeholder={t('sidebar.search_plots.placeholder_buildable')} closeMenuOnSelect={false} isMulti options={buildableOptions} styles={multiSelectStyles} isLoading={isLoadingLayersTree}/>)}/>
              </FormControl>)}

            {hasSearchField('allowedFloorArea') && (<FormControl isDisabled={isLoading}>
                <FormLabel marginBottom={1}>
                  {t('sidebar.search_plots.allowed_floor_area')}
                </FormLabel>
                <HStack>
                  <AreaInputWrapper rightElementLabel="m²">
                    <Input size="sm" placeholder="Min" type="number" {...register('allowedFloorAreaMin')}/>
                  </AreaInputWrapper>
                  <AreaInputWrapper rightElementLabel="m²">
                    <Input size="sm" placeholder="Max" type="number" {...register('allowedFloorAreaMax')}/>
                  </AreaInputWrapper>
                </HStack>
              </FormControl>)}

            {hasSearchField('permittedBuildingFootprint') && (<FormControl isDisabled={isLoading}>
                <FormLabel marginBottom={1}>
                  {t('sidebar.search_plots.permitted_building_footprint')}
                </FormLabel>
                <HStack>
                  <AreaInputWrapper rightElementLabel="%">
                    <Input size="sm" placeholder="Min" type="number" {...register('permittedBuildingFootprintMin')}/>
                  </AreaInputWrapper>
                  <AreaInputWrapper rightElementLabel="%">
                    <Input size="sm" placeholder="Max" type="number" {...register('permittedBuildingFootprintMax')}/>
                  </AreaInputWrapper>
                </HStack>
              </FormControl>)}

            {hasSearchField('authorizedLevel') && (<FormControl isDisabled={isLoading}>
                <FormLabel marginBottom={1}>
                  {t('sidebar.search_plots.authorized_level')}
                </FormLabel>
                <HStack width="50%">
                  <AreaInputWrapper>
                    <Input size="sm" placeholder="1" type="number" {...register('authorizedLevelMin')}/>
                  </AreaInputWrapper>
                </HStack>
              </FormControl>)}
          </>)}
    </Stack>);
};
export default BaseSearch;
