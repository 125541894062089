import { pick } from 'lodash';
import { getSaleDetail } from 'components/tabs/plots/PlotSections/PlotSales';
const fields = ['date', 'realEstateValue', 'nature', 'saleDetail'];
export const formatSalesCellValue = ({ cellValue, fieldName, t }) => {
    if (fieldName === 'saleDetail') {
        return cellValue.map((detail) => getSaleDetail(t, detail)).join('\n');
    }
    return cellValue;
};
export const formatSalesData = ({ sales }) => {
    return sales ? Object.values(sales).map((sale) => pick(sale, fields)) : null;
};
