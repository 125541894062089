import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
import useOnError from 'hooks/useOnError';
import { IOProjectHistory } from 'types/api';
export const QUERY_KEY = 'project-history';
export const fetchProjectHistory = (projectId, landPointId) => () => get(iots.array(IOProjectHistory), `project/${projectId}/history`, {
    landPointId,
});
export const getProjectHistoryQueryKey = (projectId) => [
    QUERY_KEY,
    projectId,
];
const useProjectHistory = (projectId, landPointId) => {
    const onErrorCallback = useOnError();
    return useQuery({
        queryKey: getProjectHistoryQueryKey(projectId),
        queryFn: fetchProjectHistory(projectId, landPointId),
        enabled: !!projectId,
        meta: { onError: onErrorCallback },
        retry: false,
    });
};
export default useProjectHistory;
