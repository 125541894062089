import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOPhotovoltaicProneAreaWetlandLayerItem = IOLayerItem;
const fetchPhotovoltaicProneAreaWetland = (townId) => () => get(iots.array(IOPhotovoltaicProneAreaWetlandLayerItem), `${townId}/photovoltaic-prone-area/eau`);
const usePhotovoltaicProneAreaWetlandLayer = () => {
    createLayersHook({
        fetch: fetchPhotovoltaicProneAreaWetland,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.PHOTOVOLTAIC_PRONE_AREA_WETLAND);
};
const PhotovoltaicProneAreaWetlandLayer = () => {
    usePhotovoltaicProneAreaWetlandLayer();
    return null;
};
export default PhotovoltaicProneAreaWetlandLayer;
