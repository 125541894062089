import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IODocUrbaLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IONoBuildingAreaEnvironmentLayerItem = IODocUrbaLayerItem;
const fetchNoBuildingAreaEnvironment = (townId) => () => get(iots.array(IONoBuildingAreaEnvironmentLayerItem), `${townId}/no-building-area-environment`);
const useNoBuildingAreaEnvironmentLayer = () => {
    createLayersHook({
        fetch: fetchNoBuildingAreaEnvironment,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.NO_BUILDING_AREA_ENVIRONMENT);
};
const NoBuildingAreaEnvironmentLayer = () => {
    useNoBuildingAreaEnvironmentLayer();
    return null;
};
export default NoBuildingAreaEnvironmentLayer;
