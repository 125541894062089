import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getLayerCoordTileUrl } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = () => (coord, zoom) => {
    return getLayerCoordTileUrl('POTENTIEL.EOLIEN.REGLEMENTAIRE', 'POTENTIEL.EOLIEN.REGLE', zoom, coord);
};
const getLegends = () => {
    const legends = {};
    legends.wind_potential_crippling = {
        layerKey: filtersKey.WIND_POTENTIAL,
        label: 'wind_potential_crippling',
        needsTranslate: true,
        color: '#FFFFFF',
        borderColor: '#000000',
        opacity: 1,
    };
    legends.wind_potential_no_favorable_potential = {
        layerKey: filtersKey.WIND_POTENTIAL,
        label: 'wind_potential_no_favorable_potential',
        needsTranslate: true,
        color: '#FFCCFF',
        opacity: 1,
    };
    legends.wind_potential_favorable_potential = {
        layerKey: filtersKey.WIND_POTENTIAL,
        label: 'wind_potential_favorable_potential',
        needsTranslate: true,
        color: '#CE88FF',
        opacity: 1,
    };
    legends.wind_potential_favorable_potential_2 = {
        layerKey: filtersKey.WIND_POTENTIAL,
        label: 'wind_potential_favorable_potential_2',
        needsTranslate: true,
        color: '#800080',
        opacity: 1,
    };
    return legends;
};
const useWindPotentialLayer = () => {
    const options = {
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.WIND_POTENTIAL],
        getLegends,
        opacity: 0.42,
    };
    createTilesLayerHook(options)(filtersKey.WIND_POTENTIAL);
};
const WindPotentialLayer = () => {
    useWindPotentialLayer();
    return null;
};
export default WindPotentialLayer;
