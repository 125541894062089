import * as React from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, HStack, Text } from '@chakra-ui/react';
const UserStatisticsStatusItem = ({ data, option, }) => {
    const { t } = useTranslation();
    return (<HStack spacing={2} width="full">
      <HStack spacing={1} width="230px">
        {option.color && (<Box backgroundColor={option.color} width="18px" height="18px" borderRadius="md" border={option.color === '#FFFFFF' ? '1px solid #333333' : 'none'}/>)}
        <Text fontSize="14px">{t(option.label)}</Text>
      </HStack>
      <HStack spacing={1} width="calc(100% - 220px)">
        <Box display="flex" alignItems="center" justifyContent="center" backgroundColor={option.color ?? '#EF3E22'} height="18px" width={(data.percent[option.value] ? data.percent[option.value] : 0) + '%'} border={option.color === '#FFFFFF' && data.percent[option.value]
            ? '1px solid #333333'
            : 'none'} transition="width 1s ease"/>
        {data.status[option.value] !== 0 && (<Text color="colorMode.font900" fontSize="13px" fontWeight="bold">
            {data.status[option.value]}
          </Text>)}
      </HStack>
    </HStack>);
};
export default memo(UserStatisticsStatusItem);
