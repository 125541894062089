import * as React from 'react';
import { useCallback, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as iots from 'io-ts';
import { get } from 'api/client';
import PublicMarketsInfoWindow, { IOPublicMarketsInfo, } from 'components/infoWindow/PublicMarketsInfoWindow';
import { IOPublicMarketsMarkerItem, } from 'components/layers/PlanningAndStudiesLayer';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import { filtersKey } from 'utils/filters';
const getMarkerImg = (marker) => {
    return `/api/public/legacy/get-real-estate-promotions-image/${marker.count}`;
};
const fetchRealEstatePromotionsInfo = (marker) => {
    return get(iots.array(IOPublicMarketsInfo), `legacy/real-estate-promotions-info/${encodeURIComponent(marker.legacyId)}`);
};
const formatInfoWindowContent = (info) => {
    const content = <PublicMarketsInfoWindow info={info}/>;
    return ReactDOMServer.renderToStaticMarkup(content);
};
const getLegends = (filterName) => ({
    [filterName]: {
        layerKey: filterName,
        label: filterName,
        needsTranslate: true,
        image: getMarkerImg({ count: 'X' }),
    },
});
const fetchRealEstatePromotions = (townId) => () => get(iots.array(IOPublicMarketsMarkerItem), `legacy/${townId}/real-estate-promotions`);
const useRealEstatePromotionsLayer = () => {
    const selectedTown = useSelectedTown();
    const [markerInfo, setMarkerInfo] = useState({});
    const getInfoWindowContent = useCallback(async (marker) => {
        const markerKey = `${marker.lat}/${marker.lng}`;
        if (markerInfo[markerKey]) {
            return markerInfo[markerKey];
        }
        const realEstatePromotionsInfo = await fetchRealEstatePromotionsInfo(marker);
        const content = formatInfoWindowContent(realEstatePromotionsInfo);
        setMarkerInfo({
            ...markerInfo,
            [markerKey]: content,
        });
        return content;
    }, [selectedTown]);
    createLayersHook({
        fetch: fetchRealEstatePromotions,
        markerImg: getMarkerImg,
        legends: getLegends(filtersKey.REAL_ESTATE_PROMOTIONS),
        getInfoWindowContent,
    })(filtersKey.REAL_ESTATE_PROMOTIONS);
};
const RealEstatePromotionsLayer = () => {
    useRealEstatePromotionsLayer();
    return null;
};
export default RealEstatePromotionsLayer;
