import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IODeclarationOfPublicInterestLayerItem = IOLayerItem;
const fetchDeclarationOfPublicInterest = (townId) => () => get(iots.array(IODeclarationOfPublicInterestLayerItem), `legacy/${townId}/declaration-of-public-interest`);
const useDeclarationOfPublicInterestLayer = () => {
    createLayersHook({
        fetch: fetchDeclarationOfPublicInterest,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.DECLARATION_OF_PUBLIC_INTEREST);
};
const DeclarationOfPublicInterestLayer = () => {
    useDeclarationOfPublicInterestLayer();
    return null;
};
export default DeclarationOfPublicInterestLayer;
