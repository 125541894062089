import { useCallback } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CopyIcon } from '@chakra-ui/icons';
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Button, IconButton, Input, Link, ListItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, OrderedList, useDisclosure, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_AGENDA } from 'analytics/useAnalytics';
import { useUserData } from 'hooks/contexts/useAuthContext';
import useCustomToast from 'hooks/useCustomToast';
const ActivityExportModal = () => {
    const { t } = useTranslation();
    const { getSuccessToast } = useCustomToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { trackEvent } = useAnalytics();
    const userData = useUserData();
    const getUrlAgenda = () => {
        return (window.location.protocol +
            '//' +
            window.location.hostname +
            '/agenda/activity/' +
            userData.tokenCalendar +
            '.ics');
    };
    const copy = useCallback(async (text) => {
        await navigator.clipboard.writeText(text);
        getSuccessToast({
            title: t('tabs.activities.export.token_copied'),
            id: 'activities_export_token_copied',
        });
    }, [t]);
    return (<>
      <Button variant="primary" onClick={() => {
            trackEvent({
                category: ANALYTICS_AGENDA,
                action: 'Exporter agenda',
            });
            onOpen();
        }}>
        {t('tabs.activities.export.button')}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('tabs.activities.export.title')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody textStyle="paragraph">
            <Accordion defaultIndex={[0, 1]}>
              <AccordionItem border="none" backgroundColor="colorMode.base" borderRadius="6px" marginY={4}>
                <AccordionButton borderBottomWidth={1} borderBottomColor="colorMode.background700" as="div" padding={2} cursor="pointer">
                  {t(`tabs.activities.export.import_google_agenda`)}
                </AccordionButton>

                <AccordionPanel p={4} textStyle="paragraph" color="colorMode.font800">
                  <OrderedList>
                    <ListItem>
                      {t(`tabs.activities.export.import_google_agenda_step1`)}
                      <Link href="https://calendar.google.com/" variant="primary" isExternal>
                        Google Agenda
                      </Link>
                    </ListItem>
                    <ListItem dangerouslySetInnerHTML={{
            __html: t(`tabs.activities.export.import_google_agenda_step2`),
        }}/>
                    <ListItem dangerouslySetInnerHTML={{
            __html: t(`tabs.activities.export.import_google_agenda_step3`),
        }}/>
                  </OrderedList>
                  <Box position="relative" marginTop={1}>
                    <Input type="text" readOnly paddingRight="38px" value={getUrlAgenda()}/>
                    <IconButton size="xs" variant="secondary" position="absolute" top="8px" right="8px" zIndex={1} onClick={() => {
            copy(getUrlAgenda());
        }} icon={<CopyIcon />} aria-label="copy-button"/>
                  </Box>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem border="none" backgroundColor="colorMode.base" borderRadius="6px" marginY={4}>
                <AccordionButton borderBottomWidth={1} borderBottomColor="colorMode.background700" as="div" padding={2} cursor="pointer">
                  {t(`tabs.activities.export.import_outlook`)}
                </AccordionButton>

                <AccordionPanel p={4} textStyle="paragraph" color="colorMode.font800">
                  <OrderedList>
                    <ListItem>
                      {t(`tabs.activities.export.import_outlook_step1`)}
                    </ListItem>
                    <ListItem dangerouslySetInnerHTML={{
            __html: t(`tabs.activities.export.import_outlook_step2`),
        }}/>
                    <ListItem dangerouslySetInnerHTML={{
            __html: t(`tabs.activities.export.import_outlook_step3`),
        }}/>
                    <ListItem dangerouslySetInnerHTML={{
            __html: t(`tabs.activities.export.import_outlook_step4`),
        }}/>
                  </OrderedList>
                  <Box position="relative" marginTop={1}>
                    <Input type="text" readOnly paddingRight="38px" value={getUrlAgenda()}/>
                    <IconButton size="xs" variant="secondary" position="absolute" top="8px" right="8px" zIndex={1} onClick={() => {
            copy(getUrlAgenda());
        }} icon={<CopyIcon />} aria-label="copy-button"/>
                  </Box>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>);
};
export default ActivityExportModal;
