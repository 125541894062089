import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Switch } from '@chakra-ui/react';
import { useToggleContactPlotsHighlights, useTogglePlotsHighlights, } from 'hooks/contexts/useSecondaryPlotsContext';
import { getRandomHexColor } from 'utils/color';
export const MANUAL_HIGHLIGHT_COLOR = '#eb3c77';
export const HighlightContactPlotsSwitch = ({ plotsInfo, highlightLegend, highlightColor, ...props }) => {
    const { t } = useTranslation();
    const { areContactPlotsHighlighted, toggleContactPlotsHighlights } = useToggleContactPlotsHighlights();
    const legend = highlightLegend ?? t('legends.contact_plot');
    const color = highlightLegend
        ? highlightColor || getRandomHexColor()
        : MANUAL_HIGHLIGHT_COLOR;
    const onChange = () => {
        toggleContactPlotsHighlights(plotsInfo, color, legend);
    };
    const highlighted = areContactPlotsHighlighted(plotsInfo, legend);
    return (<DefaultPlotSwitch onChange={onChange} highlighted={highlighted} {...props}/>);
};
export const HighlightPlotsSwitch = ({ plotsInfo, highlightLegend, highlightColor, ...props }) => {
    const { t } = useTranslation();
    const { arePlotsHighlighted, togglePlotsHighlights } = useTogglePlotsHighlights();
    const legend = highlightLegend ?? t('legends.highlighted_plot');
    const color = highlightLegend
        ? highlightColor || getRandomHexColor()
        : MANUAL_HIGHLIGHT_COLOR;
    const onChange = () => {
        togglePlotsHighlights(plotsInfo, color, legend);
    };
    const highlighted = arePlotsHighlighted(plotsInfo, legend);
    return (<DefaultPlotSwitch onChange={onChange} highlighted={highlighted} {...props}/>);
};
const DefaultPlotSwitch = ({ onChange, highlighted, ...props }) => {
    const isChecked = highlighted && !props.isDisabled;
    const switchProps = { ...props, onChange };
    return (<Box>
      <Switch size="sm" isChecked={isChecked} {...switchProps}/>
    </Box>);
};
