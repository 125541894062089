import * as React from 'react';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { Heading, HStack, IconButton, Stack } from '@chakra-ui/react';
import moment from 'moment';
import StatisticList from 'components/tabs/crmTabs/teamStatistics/StatisticList';
import { CRM_TABS } from 'context/TabsContext';
import { useToggleCRMTab } from 'hooks/contexts/useTabsContext';
import useTeamStatistics from 'hooks/crm/useTeamStatistics';
import ArrowLeftIcon from 'images/icons/ArrowLeft';
import ArrowRightIcon from 'images/icons/ArrowRight';
import StatisticUtilisationList from './StatisticUtilisationList';
const TeamStatisticsPanel = () => {
    const toggleCRMTab = useToggleCRMTab();
    const initialDate = useMemo(() => moment().locale('fr'), []);
    const currentDate = useRef(moment().locale('fr'));
    const [date, setDate] = useState({
        month: Number(initialDate.format('M')),
        year: Number(initialDate.format('YYYY')),
    });
    const { teamStatistics, isLoadingTeamStatistics, errorTeamStatistics, teamStatisticsUtilisation, isLoadingTeamStatisticsUtilisation, } = useTeamStatistics(date.month, date.year);
    useEffect(() => {
        if (errorTeamStatistics) {
            toggleCRMTab({ tabId: CRM_TABS.TEAM_STATISTICS, closeTab: true });
        }
    }, [errorTeamStatistics]);
    // This page has restricted access so we don't want to show anything
    // if the query returns an error
    if (errorTeamStatistics) {
        return null;
    }
    return (<Stack paddingX={5} height="100%" spacing={4}>
      <HStack paddingTop={2}>
        <IconButton size="sm" variant="secondary" aria-label="Previous month" color="colorMode.font900" icon={<ArrowLeftIcon fill="currentColor"/>} onClick={() => {
            currentDate.current.subtract(1, 'M');
            setDate({
                month: Number(currentDate.current.format('M')),
                year: Number(currentDate.current.format('YYYY')),
            });
        }}/>
        <Heading as="h3" variant="h3" color="colorMode.font800">
          {currentDate.current.format('MMMM YYYY')}
        </Heading>
        {currentDate.current.format('MMMM YYYY') !==
            initialDate.format('MMMM YYYY') && (<IconButton variant="secondary" aria-label="Next month" icon={<ArrowRightIcon fill="currentColor"/>} onClick={() => {
                currentDate.current.add(1, 'M');
                setDate({
                    month: Number(currentDate.current.format('M')),
                    year: Number(currentDate.current.format('YYYY')),
                });
            }}/>)}
      </HStack>

      <StatisticUtilisationList data={teamStatisticsUtilisation || []} title="tabs.statistic_team.utilisation_title" analyticsAction="Equipe statistiques utilisation" id="table-utilisation-team" isLoading={isLoadingTeamStatisticsUtilisation}/>
      <StatisticList data={teamStatistics?.history || []} title="tabs.statistic_team.statistic_title" analyticsAction="Equipe statistiques nouvelles" id="table-statistic-team" isLoading={isLoadingTeamStatistics}/>
      <StatisticList data={teamStatistics?.global || []} title="tabs.statistic_team.opportunities_title" analyticsAction="Equipe statistiques total" id="table-opportunities-team" isLoading={isLoadingTeamStatistics}/>
    </Stack>);
};
export default memo(TeamStatisticsPanel);
