import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { post } from 'api/client';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { useGetPlotsIds, useIsLoading, } from 'hooks/contexts/usePlotSearchContext';
import { IOCoordinate } from 'types/types';
export const IOPlotsSearchDetail = iots.type({
    id: iots.string,
    town: iots.string,
    townId: iots.string,
    prefix: iots.string,
    section: iots.string,
    number: iots.string,
    surface: iots.number,
    subZone: iots.string,
    lng: IOCoordinate,
    lat: IOCoordinate,
    status: iots.number,
    address: iots.string,
    postalCode: iots.string,
});
export const QUERY_KEY = 'plot-search-details';
const fetchPlotsSearchDetails = (townId, plotIds = []) => () => post(iots.array(IOPlotsSearchDetail), `legacy/plots-search-details/${townId}`, { plotIds });
const usePlotsSearchDetails = () => {
    const selectedTown = useSelectedTown();
    const getPlotsIds = useGetPlotsIds();
    const isPlotLoading = useIsLoading();
    const plotsIds = getPlotsIds(null);
    const { data, isInitialLoading: isLoading } = useQuery({
        queryKey: [QUERY_KEY, selectedTown?.id, plotsIds],
        queryFn: fetchPlotsSearchDetails(selectedTown?.id, plotsIds),
        enabled: !!selectedTown && !!plotsIds?.length,
    });
    return {
        data,
        isLoading: isPlotLoading || isLoading,
    };
};
export default usePlotsSearchDetails;
