import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOPartnershipUrbanProjectLayerItem = IOLayerItem;
const fetchPartnershipUrbanProject = (townId) => () => get(iots.array(IOPartnershipUrbanProjectLayerItem), `legacy/${townId}/partnership-urban-project`);
const usePartnershipUrbanProjectLayer = () => {
    createLayersHook({
        fetch: fetchPartnershipUrbanProject,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.PARTNERSHIP_URBAN_PROJECT);
};
const PartnershipUrbanProjectLayer = () => {
    usePartnershipUrbanProjectLayer();
    return null;
};
export default PartnershipUrbanProjectLayer;
