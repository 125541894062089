import { useTranslation } from 'react-i18next';
import useCustomToast from './useCustomToast';
const useOnError = () => {
    const { t } = useTranslation();
    const { getErrorToast, getInfoToast } = useCustomToast();
    return (error) => {
        const statusCode = error.response?.status;
        if (statusCode === 404) {
            const errorMessage = 
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            error.response?.data.detail || error.message;
            getInfoToast({
                description: errorMessage,
                id: statusCode,
            });
        }
        else if (statusCode !== 401) {
            const error_500_message = statusCode === 500 && t('toast.500');
            const errorMessage = 
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            error_500_message || error.response?.data.detail || error.message;
            if (errorMessage) {
                getErrorToast({
                    title: t('toast.error_title'),
                    description: errorMessage,
                    id: statusCode,
                });
            }
        }
    };
};
export default useOnError;
