import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as iots from 'io-ts';
import moment from 'moment';
import { del, get, patch, post } from 'api/client';
import { useUpdateProjectName } from 'hooks/contexts/useTabsContext';
import { QUERY_KEY as CONTACT_LIST_QUERY_KEY } from 'hooks/crm/useContactList';
import { QUERY_KEY as PLOT_HISTORY_QUERY_KEY } from 'hooks/crm/usePlotHistory';
import { getPlotProjectsQueryKey, QUERY_KEY as PLOT_PROJECTS_QUERY_KEY, } from 'hooks/crm/usePlotProjectsList';
import { getProjectHistoryQueryKey } from 'hooks/crm/useProjectHistory';
import { QUERY_KEY as USER_PLOTS_QUERY_KEY } from 'hooks/crm/useUserPlotsList';
import { QUERY_KEY as USER_PROJECTS_QUERY_KEY } from 'hooks/crm/useUserProjectsList';
import { PROJECT_QUERY_KEY as USER_STATS_CRM_PROJECT_QUERY_KEY } from 'hooks/crm/useUserStatisticsCRM';
import { PROJECT_QUERY_KEY as USER_STATS_CRM_PLOT_QUERY_KEY } from 'hooks/crm/useUserStatisticsCRM';
import useCustomToast from 'hooks/useCustomToast';
import { PROJECT_QUERY_KEY as LAND_POINT_PROJECT_QUERY_KEY } from 'hooks/useLandPoint';
import useOnError from 'hooks/useOnError';
import { IOEmpty, IOProject, IOProjectCreation, IOProjectManagePlot, IOProjectPlot, } from 'types/api';
import { PROJECT_ADD_PLOT, PROJECT_DELETE_PLOT } from 'utils/constants';
import { getPlotNoteQueryKey } from './usePlotNotes';
import { getProjectContactsQueryKey } from './useProjectContacts';
import { getProjectNotesQueryKey } from './useProjectNotes';
export const QUERY_KEY = 'project-detail';
export const QUERY_PLOT_KEY = 'project-plot';
export const getProjectQueryKey = (projectId) => [QUERY_KEY, projectId];
export const getProjectPlotQueryKey = (projectId) => [
    QUERY_PLOT_KEY,
    projectId,
];
export const fetchProject = (projectId, landPointId) => () => get(IOProject, `project/${projectId}/info`, { landPointId });
export const fetchProjectPlots = (projectId, signal, landPointId) => {
    return get(iots.array(IOProjectPlot), `project/${projectId}/plots`, { landPointId }, false, { signal });
};
const deleteProjectRequest = ({ projectId }) => del(IOEmpty, `project/${projectId}`);
const deletePlotNote = ({ noteId }) => del(null, `legacy/note/${noteId}/delete`);
const addProjectRequest = ({ townshipId, plotIds, }) => post(IOProjectCreation, `${townshipId}/project/create`, {
    plotIds: plotIds.join(','),
});
const togglePlotInProjectRequest = ({ townshipId, projectId, lng, lat, plotId, }) => post(IOProjectManagePlot, `project/${projectId}/toggle-plot`, {
    townshipId,
    lng,
    lat,
    plotId,
});
const removePlotFromProjectRequest = ({ projectId, projectPlotId, removeProject = false, }) => post(IOEmpty, `project/${projectId}/remove-plot`, {
    projectPlotId,
    removeProject,
});
const addNote = ({ id, note }) => post(IOEmpty, `project/${id}/notes`, { note });
const removeNote = ({ projectId, projectNoteId, }) => del(IOEmpty, `project/${projectId}/notes/${projectNoteId}`);
const patchNote = ({ projectId, noteId, note }) => patch(IOEmpty, `project/${projectId}/notes/${noteId}`, { note });
const updateProjectInfo = ({ projectId, field, value, }) => post(IOEmpty, `project/${projectId}/update-info`, { field, value });
const updateProjectStatusRequest = ({ projectId, status, }) => post(IOEmpty, `project/${projectId}/update-status`, { status });
const useProject = (townshipId, projectId, landPointId) => {
    const onErrorCallback = useOnError();
    const { getSuccessToast } = useCustomToast();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const projectQueryKey = getProjectQueryKey(projectId);
    const projectPlotQueryKey = getProjectPlotQueryKey(projectId);
    const updateProjectName = useUpdateProjectName();
    const { data: project, isInitialLoading: isLoading } = useQuery({
        queryKey: projectQueryKey,
        queryFn: fetchProject(projectId, landPointId),
        enabled: !!townshipId && !!projectId,
        meta: { onError: onErrorCallback },
        retry: false,
    });
    const { data: projectPlots, isInitialLoading: isLoadingPlots } = useQuery({
        queryKey: projectPlotQueryKey,
        queryFn: ({ signal }) => fetchProjectPlots(projectId, signal, landPointId),
        enabled: !!townshipId && !!projectId,
        retry: false,
        meta: { onError: onErrorCallback },
    });
    const updateProjectUpdateDate = (projectId) => {
        const now = moment();
        const projectQueryKey = getProjectQueryKey(projectId);
        // Update date
        queryClient.setQueryData(projectQueryKey, (oldData) => {
            return { ...oldData, updatedAt: now.format('YYYY-MM-DD HH:mm:ss') };
        });
    };
    const invalidateProject = (projectId, removeProject = false) => {
        if (!projectId)
            return;
        const projectQueryKey = getProjectQueryKey(projectId);
        const projectHistoryQueryKey = getProjectHistoryQueryKey(projectId);
        // detail du projet
        if (removeProject) {
            queryClient.setQueryData(projectQueryKey, null);
            queryClient.setQueryData(projectHistoryQueryKey, null);
            return;
        }
        queryClient.invalidateQueries({ queryKey: projectQueryKey });
        queryClient.invalidateQueries({ queryKey: projectHistoryQueryKey });
        queryClient.invalidateQueries({ queryKey: [LAND_POINT_PROJECT_QUERY_KEY] });
        queryClient.invalidateQueries({
            queryKey: [USER_STATS_CRM_PROJECT_QUERY_KEY],
        });
    };
    const invalidateProjectPlot = (projectId, removeProject = false) => {
        if (!projectId)
            return;
        updateProjectUpdateDate(projectId);
        const projectPlotQueryKey = getProjectPlotQueryKey(projectId);
        const projectHistoryQueryKey = getProjectHistoryQueryKey(projectId);
        // detail du projet
        if (removeProject) {
            queryClient.setQueryData(projectPlotQueryKey, null);
            queryClient.setQueryData(projectHistoryQueryKey, null);
            return;
        }
        queryClient.invalidateQueries({ queryKey: projectPlotQueryKey });
        queryClient.invalidateQueries({ queryKey: projectHistoryQueryKey });
        queryClient.invalidateQueries({ queryKey: [LAND_POINT_PROJECT_QUERY_KEY] });
        queryClient.invalidateQueries({
            queryKey: [USER_STATS_CRM_PROJECT_QUERY_KEY],
        });
        queryClient.invalidateQueries({ queryKey: [USER_STATS_CRM_PLOT_QUERY_KEY] });
    };
    const invalidateLists = () => {
        // liste des parcelles
        queryClient.invalidateQueries({ queryKey: USER_PLOTS_QUERY_KEY });
        // liste des projets
        queryClient.invalidateQueries({ queryKey: USER_PROJECTS_QUERY_KEY });
    };
    // in reality juste changes active to false in BDD
    const { mutate: deleteProject } = useMutation({
        mutationFn: deleteProjectRequest,
        onError: (err) => onErrorCallback(err),
        onSuccess: (_data, { projectId }) => {
            invalidateLists();
            invalidateProject(projectId, true);
            queryClient.refetchQueries({ queryKey: [PLOT_PROJECTS_QUERY_KEY] });
            queryClient.refetchQueries({ queryKey: [PLOT_HISTORY_QUERY_KEY] });
        },
    });
    const { mutate: addProject, isPending: isAddingProject } = useMutation({
        mutationFn: addProjectRequest,
        onError: (err) => {
            onErrorCallback(err);
        },
        onSuccess: (data, { plotIds }) => {
            invalidateLists();
            plotIds.forEach((myPlotId) => {
                queryClient.invalidateQueries({
                    queryKey: getPlotProjectsQueryKey(myPlotId),
                });
                queryClient.invalidateQueries({
                    queryKey: [PLOT_HISTORY_QUERY_KEY, myPlotId],
                });
            });
        },
    });
    const { mutate: togglePlotInProject } = useMutation({
        mutationFn: togglePlotInProjectRequest,
        onSuccess: (data, { projectId }) => {
            const queryKey = getProjectPlotQueryKey(projectId);
            if (data.action === PROJECT_ADD_PLOT) {
                getSuccessToast({
                    title: t('header.search_plot.plot_added'),
                    id: 'plot_added_project_success',
                });
                // add plot to project plots
                queryClient.setQueryData(queryKey, (oldData) => [
                    ...oldData,
                    data.plot,
                ]);
            }
            else if (data.action === PROJECT_DELETE_PLOT) {
                getSuccessToast({
                    title: t('header.search_plot.plot_deleted'),
                    id: 'plot_deleted_project_success',
                });
                // remove plot from project plots
                queryClient.setQueryData(queryKey, (oldData) => {
                    return oldData.filter((plot) => plot.id !== data.plotId);
                });
            }
            queryClient.cancelQueries({
                queryKey: projectPlotQueryKey,
            });
            invalidateLists();
            invalidateProjectPlot(projectId);
            queryClient.invalidateQueries({
                queryKey: getProjectNotesQueryKey(projectId),
            });
            queryClient.invalidateQueries({
                queryKey: getProjectContactsQueryKey(projectId),
            });
            queryClient.refetchQueries({ queryKey: [PLOT_PROJECTS_QUERY_KEY] });
        },
    });
    const { mutate: removePlotFromProject, isPending: removePlotFromProjectLoading, variables: projectPlotDeletionVariables, } = useMutation({
        mutationFn: removePlotFromProjectRequest,
        onError: (err) => onErrorCallback(err),
        onSuccess: (_data, { projectId, removeProject }) => {
            invalidateLists();
            invalidateProjectPlot(projectId, removeProject);
            queryClient.invalidateQueries({
                queryKey: getProjectNotesQueryKey(projectId),
            });
            queryClient.invalidateQueries({
                queryKey: getProjectContactsQueryKey(projectId),
            });
            queryClient.refetchQueries({ queryKey: [PLOT_PROJECTS_QUERY_KEY] });
        },
    });
    const { mutate: addNoteToProject } = useMutation({
        mutationFn: addNote,
        onError: (err) => onErrorCallback(err),
        onSuccess: (data, { id: projectId }) => {
            invalidateLists();
            updateProjectUpdateDate(projectId);
            queryClient.invalidateQueries({
                queryKey: getProjectNotesQueryKey(projectId),
            });
        },
    });
    const { mutate: removeNoteFromProject } = useMutation({
        mutationFn: removeNote,
        onError: (err) => onErrorCallback(err),
        onSuccess: (_data, { projectId }) => {
            invalidateLists();
            updateProjectUpdateDate(projectId);
            queryClient.invalidateQueries({
                queryKey: getProjectNotesQueryKey(projectId),
            });
        },
    });
    const { mutate: updateNoteFromProject } = useMutation({
        mutationFn: patchNote,
        onError: (err) => onErrorCallback(err),
        onSuccess: (_data, { projectId }) => {
            invalidateLists();
            updateProjectUpdateDate(projectId);
            queryClient.invalidateQueries({
                queryKey: getProjectNotesQueryKey(projectId),
            });
        },
    });
    const { mutate: deletePlotNoteFromProject } = useMutation({
        mutationFn: deletePlotNote,
        onError: (err) => onErrorCallback(err),
        onSuccess: (_data, { plotId }) => {
            queryClient.invalidateQueries({ queryKey: getPlotNoteQueryKey(plotId) });
            queryClient.invalidateQueries({
                queryKey: getProjectNotesQueryKey(projectId),
            });
        },
    });
    const { mutate: changeProjectInfo, isPending: changeLoading } = useMutation({
        mutationFn: updateProjectInfo,
        onError: (err) => onErrorCallback(err),
        onSuccess: (_data, { projectId, field, value }) => {
            invalidateLists();
            invalidateProject(projectId);
            if (field === 'label') {
                updateProjectName({ projectId, newName: value });
            }
        },
    });
    const { mutate: updateProjectStatus, isPending: isUpdatingProjectStatus } = useMutation({
        mutationFn: updateProjectStatusRequest,
        onError: (err) => onErrorCallback(err),
        onSuccess: (_data, { projectId, plotIds }) => {
            invalidateLists();
            invalidateProject(projectId);
            queryClient.invalidateQueries({ queryKey: CONTACT_LIST_QUERY_KEY });
            if (plotIds) {
                plotIds.forEach((plotId) => {
                    queryClient.invalidateQueries({
                        queryKey: getPlotProjectsQueryKey(plotId),
                    });
                });
            }
        },
    });
    return {
        project,
        isLoading,
        projectPlots,
        isLoadingPlots,
        deleteProject,
        addProject,
        isAddingProject,
        togglePlotInProject,
        removePlotFromProject,
        projectPlotDeletionVariables,
        addNoteToProject,
        removeNoteFromProject,
        updateNoteFromProject,
        changeProjectInfo,
        changeLoading,
        updateProjectStatus,
        removePlotFromProjectLoading,
        deletePlotNoteFromProject,
        isUpdatingProjectStatus,
    };
};
export default useProject;
