import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOWetZoneLayerItem = IOLayerItem;
const fetchWetZone = (townId) => () => get(iots.array(IOWetZoneLayerItem), `legacy/${townId}/wet-zone`);
const useWetZoneLayer = () => {
    createLayersHook({
        fetch: fetchWetZone,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.WET_ZONE);
};
const WetZoneLayer = () => {
    useWetZoneLayer();
    return null;
};
export default WetZoneLayer;
