import * as React from 'react';
import { Badge, Text } from '@chakra-ui/react';
const Counter = ({ counter }) => {
    return (<Badge variant="outline" borderColor="colorMode.revertBase" borderRadius="lg" paddingX={2}>
      <Text textStyle="small" fontWeight={700} textAlign="center" color="colorMode.revertBase">
        {counter}
      </Text>
    </Badge>);
};
export default Counter;
