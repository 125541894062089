import * as React from 'react';
const ToDoIcon = (props) => (<svg width="20" height="20" viewBox="0 0 12 12" {...props}>
    <g id="g22" transform="matrix(0.0255668,0,0,0.02499995,-0.13834195,0)">
      <g id="g20">
        <path d="m 440.254,54.354 v 59.05 c 0,26.69 -21.652,48.198 -48.338,48.198 h -30.493 c -26.688,0 -48.627,-21.508 -48.627,-48.198 V 54.142 h -137.44 v 59.262 c 0,26.69 -21.938,48.198 -48.622,48.198 H 96.235 c -26.685,0 -48.336,-21.508 -48.336,-48.198 V 54.354 C 24.576,55.057 5.411,74.356 5.411,98.077 v 346.061 c 0,24.167 19.588,44.015 43.755,44.015 h 389.82 c 24.131,0 43.755,-19.889 43.755,-44.015 V 98.077 c 0,-23.721 -19.164,-43.02 -42.487,-43.723 z m -14.163,368.234 c 0,10.444 -8.468,18.917 -18.916,18.917 H 80.144 c -10.448,0 -18.916,-8.473 -18.916,-18.917 V 243.835 c 0,-10.448 8.467,-18.921 18.916,-18.921 h 327.03 c 10.448,0 18.916,8.473 18.916,18.921 z"/>
        <path d="m 96.128,129.945 h 30.162 c 9.155,0 16.578,-7.412 16.578,-16.567 V 16.573 C 142.868,7.417 135.445,0 126.29,0 H 96.128 C 86.972,0 79.55,7.417 79.55,16.573 v 96.805 c 0,9.155 7.422,16.567 16.578,16.567 z"/>
        <path d="m 361.035,129.945 h 30.162 c 9.149,0 16.572,-7.412 16.572,-16.567 V 16.573 C 407.77,7.417 400.347,0 391.197,0 h -30.162 c -9.154,0 -16.577,7.417 -16.577,16.573 v 96.805 c 0,9.155 7.423,16.567 16.577,16.567 z"/>
      </g>
      <text fill="#e21010" fontSize="103px" fontWeight="bold" strokeWidth={0.265} x="90" y="361.23651" transform="scale(0.9888336,1.0112925)">
        <tspan fill="#e21010" fontSize="103px" fontWeight="bold" strokeWidth={24.1351} x="90" y="361.23651">
          TO DO
        </tspan>
      </text>
    </g>
  </svg>);
export default ToDoIcon;
