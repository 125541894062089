import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { del, get, getFile, post } from 'api/client';
import { QUERY_KEY as COURIER_LIST_QUERY_KEY } from 'hooks/courier/useCourier';
import { CONTACT_QUERY_KEY } from 'hooks/crm/useContact';
import useCustomToast from 'hooks/useCustomToast';
import { IOCourierCreationPublipostage, IOEmpty, IOTemplatePublipostage, } from 'types/api';
const QUERY_KEY_TEMPLATE = ['template_publipostage'];
const fetchTemplatePublipostage = () => () => get(iots.array(IOTemplatePublipostage), `mailing/templates`);
const selectTemplatePublipostageRequest = (templateId) => {
    return post(null, `mailing/template/${templateId}/select`);
};
const selectDefaultTemplatePublipostageRequest = () => {
    return post(null, `mailing/template/default/select`);
};
const createTemplateAndImportPublipostageRequest = (importFile) => {
    const formData = new FormData();
    formData.append('file', importFile.file[0], importFile.file[0]?.name);
    formData.append('title', importFile.title);
    return post(null, `mailing/template/create-and-import`, formData, null, false, {
        headers: { 'Content-Type': 'multipart/form-data;' },
    });
};
const deleteTemplatePublipostageRequest = (templateId) => {
    return del(IOEmpty, `mailing/template/${templateId}/delete`);
};
const importPublipostageRequest = (importFile) => {
    const formData = new FormData();
    formData.append('file', importFile.file[0], importFile.file[0]?.name);
    return post(null, `mailing/import`, formData, null, false, {
        headers: { 'Content-Type': 'multipart/form-data;' },
    });
};
const finishImportPublipostageRequest = (importPublipostage) => {
    return getFile(`mailing/generate`, null, null, 'post', importPublipostage);
};
const generateCourierPublipostageRequest = (importPublipostage) => {
    return post(IOCourierCreationPublipostage, `mailing/courier/generate`, importPublipostage);
};
const useImportPublipostage = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const { getSuccessToast, getErrorToast } = useCustomToast();
    const { mutate: importPublipostage, isPending: isLoadingImport } = useMutation({
        mutationFn: importPublipostageRequest,
    });
    const { mutate: finishImportPublipostage, isPending: isLoading } = useMutation({
        mutationFn: finishImportPublipostageRequest,
    });
    const { mutate: generateCourierPublipostage, isPending: isLoadingGenerateCourier, } = useMutation({
        mutationFn: generateCourierPublipostageRequest,
        onSuccess: (_data, variables) => {
            queryClient.invalidateQueries({ queryKey: COURIER_LIST_QUERY_KEY });
            variables.contactIds.forEach((contactId) => {
                queryClient.invalidateQueries({
                    queryKey: [CONTACT_QUERY_KEY, contactId],
                });
            });
            if (_data.contactsNoFr.length > 0) {
                const contactsNoFr = _data.contactsNoFr.map((contact) => {
                    return contact.lastname + ' ' + contact.firstname;
                });
                getErrorToast({
                    title: t('publipostage.error_contact.title'),
                    description: t('publipostage.error_contact.contacts_no_fr', {
                        contacts: contactsNoFr.join(', '),
                    }),
                    duration: 10000,
                });
            }
            if (_data.incompleteContacts.length > 0) {
                const incompleteContacts = _data.incompleteContacts.map((contact) => {
                    return contact.lastname + ' ' + contact.firstname;
                });
                getErrorToast({
                    title: t('publipostage.error_contact.title'),
                    description: t('publipostage.error_contact.incomplete_contacts', {
                        contacts: incompleteContacts.join(', '),
                    }),
                    duration: 10000,
                });
            }
        },
    });
    const { mutate: createTemplateAndImportPublipostage, isPending: isCreatingTemplateAndImport, } = useMutation({
        mutationFn: createTemplateAndImportPublipostageRequest,
        onSuccess: (_data) => {
            queryClient.invalidateQueries({ queryKey: QUERY_KEY_TEMPLATE });
        },
    });
    const { data: templatesPublipostage, isFetching: isFetchingTemplate } = useQuery({
        queryKey: QUERY_KEY_TEMPLATE,
        queryFn: fetchTemplatePublipostage(),
    });
    const { mutate: selectTemplatePublipostage, isPending: isSelectingTemplate } = useMutation({
        mutationFn: selectTemplatePublipostageRequest,
    });
    const { mutate: selectDefaultTemplatePublipostage, isPending: isSelectingDefaultTemplate, } = useMutation({
        mutationFn: selectDefaultTemplatePublipostageRequest,
    });
    const { mutate: deleteTemplatePublipostage, isPending: isDeletingTemplate } = useMutation({
        mutationFn: deleteTemplatePublipostageRequest,
        onSuccess: (_data) => {
            queryClient.invalidateQueries({ queryKey: QUERY_KEY_TEMPLATE });
            getSuccessToast({
                title: t('publipostage.delete_template_success'),
                id: 'delete_template',
            });
        },
    });
    return {
        importPublipostage,
        isLoadingImport,
        finishImportPublipostage,
        isLoading,
        generateCourierPublipostage,
        isLoadingGenerateCourier,
        createTemplateAndImportPublipostage,
        isCreatingTemplateAndImport,
        templatesPublipostage,
        isFetchingTemplate,
        selectTemplatePublipostage,
        isSelectingTemplate,
        selectDefaultTemplatePublipostage,
        isSelectingDefaultTemplate,
        deleteTemplatePublipostage,
        isDeletingTemplate,
    };
};
export default useImportPublipostage;
