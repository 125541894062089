import { snakeCase } from 'lodash';
import { getFeasibilityRows } from 'components/tabs/plots/PlotSections/feasibility/calculator';
import { plotFeasibilityFields } from 'components/tabs/plots/PlotSections/feasibility/calculator';
const fields = ['plot_header', ...getFeasibilityRows(true)];
export const getFeasibilityFieldName = ({ fieldName, t }) => {
    return t(`tabs.plots.section.feasibility.headers.${snakeCase(fieldName)}`);
};
const getHeaderName = ({ plot, t, }) => {
    const { section, number } = plot;
    let areas;
    if ('zone' in plot) {
        areas = plot?.zone;
    }
    return `${section} ${number} - ${t('tabs.project.section.feasibility.area')} ${areas.replaceAll(/;/g, '')}`;
};
export const formatFeasibilityData = ({ feasibility, feasibilityPlots, t, }) => {
    const feasibilityTableInfo = {};
    fields.map((key) => {
        if (key === 'plot_header') {
            const headers = [];
            const extraPlots = Object.values(feasibilityPlots)?.map((plot) => getHeaderName({ plot, t })) ?? [];
            // Add headers with plots names / total / calculator
            headers.push(...extraPlots);
            if (extraPlots?.length > 1) {
                headers.push(t('tabs.plots.section.feasibility.headers.total'));
            }
            headers.push(t('tabs.plots.section.feasibility.headers.calculator'));
            feasibilityTableInfo['plot_header'] = headers;
            return;
        }
        const rowConfig = plotFeasibilityFields[key];
        const unit = t(`tabs.plots.section.feasibility.units.${rowConfig?.unit}`);
        const values = [];
        const allData = [...Object.values(feasibilityPlots)];
        Object.values(feasibilityPlots).map((plot) => {
            values.push(`${rowConfig?.getValue({
                sourceData: {
                    ...plot,
                    ...feasibilityPlots,
                },
                allData,
            })} ${unit}`);
        });
        if (Object.keys(feasibilityPlots)?.length > 1) {
            const totalValue = `${rowConfig?.getTotalValue(allData)} ${unit}`;
            values.push(totalValue);
        }
        const calculatorValue = `${rowConfig?.getValue({
            sourceData: {
                ...feasibility.formData,
                ...feasibilityPlots,
            },
            allData,
        })} ${unit}`;
        values.push(calculatorValue);
        feasibilityTableInfo[key] = values;
    });
    return [feasibilityTableInfo];
};
