import * as React from 'react';
import { Translation } from 'react-i18next';
import { Box, Button, Heading, Spinner, Text, VStack } from '@chakra-ui/react';
import { t } from 'i18next';
import DeleteButton from 'components/buttons/DeleteButton';
import PlotButton from 'components/buttons/PlotButton';
import ProjectButton from 'components/buttons/ProjectButton';
import NoteList from 'components/notes/NoteList';
import Table from 'components/table/Table';
import { CONTACT_TAB_TYPE } from 'context/TabsContext';
import { useUpdateExtraFilter } from 'hooks/contexts/useFiltersContext';
import { useDeleteTab, useToggleLayerTab } from 'hooks/contexts/useTabsContext';
import useContact from 'hooks/crm/useContact';
import useOwner from 'hooks/useOwner';
import { getPlotStatusFromCode, getProjectStatusFromCode, SPACING_PANEL, } from 'utils/constants';
import { filtersKey } from 'utils/filters';
import ContactInfo from '../ContactInfo';
import ActivitiesSection from './ActivitiesSections';
import DeathSection from './DeathSection';
const getColumnHeader = (column) => (<Translation>
    {(t) => <Text align="center">{t(`tabs.detail_contact.${column}`)}</Text>}
  </Translation>);
const NB_PLOT_LIMIT = 5300;
const COLUMNS_PLOTS = [
    {
        id: 'columns',
        columns: [
            {
                accessorKey: 'cadastreId',
                header: () => getColumnHeader('cadastreId'),
                accessorFn: (row) => {
                    if (!row.cadastreId)
                        return t('table.empty_column');
                    return row.cadastreId;
                },
                cell: (info) => {
                    const plot = info.row.original;
                    return (<PlotButton lat={plot.lat} lng={plot.lng} townId={plot.townId} variant="link-primary" plotId={plot?.id}>
              {plot.cadastreId}
            </PlotButton>);
                },
                filterFn: 'arrIncludesSome',
            },
            {
                accessorKey: 'address',
                header: () => getColumnHeader('address'),
                accessorFn: (row) => {
                    if (!row.address)
                        return t('table.empty_column');
                    return row.address;
                },
                cell: ({ row }) => row.original.address,
                filterFn: 'arrIncludesSome',
            },
            {
                accessorKey: 'surface',
                header: () => getColumnHeader('surface'),
                cell: (info) => info.getValue(),
                filterFn: 'inNumberRange',
                enableGlobalFilter: false,
            },
            {
                accessorKey: 'zone',
                header: () => getColumnHeader('zone'),
                accessorFn: (row) => {
                    if (!row.zone)
                        return t('table.empty_column');
                    return row.zone;
                },
                cell: ({ row }) => row.original.zone,
                filterFn: 'arrIncludesSome',
            },
            {
                id: 'status',
                header: () => getColumnHeader('plot_status'),
                accessorFn: (row) => {
                    if (!row.status)
                        return t('table.empty_column');
                    return getPlotStatusFromCode(row.status);
                },
                cell: ({ row }) => getPlotStatusFromCode(row.original.status),
                filterFn: 'arrIncludesSome',
            },
        ],
    },
];
const COLUMNS_PROJECTS = [
    {
        id: 'columns',
        columns: [
            {
                accessorKey: 'label',
                header: () => getColumnHeader('label'),
                accessorFn: (row) => {
                    if (!row.label)
                        return t('table.empty_column');
                    return row.label;
                },
                cell: (info) => {
                    const project = info.row.original;
                    return (<ProjectButton projectId={project.id} projectLabel={project.label} projectTownIds={[project.townId.toString()]} lat={project.lat} lng={project.lng}>
              {project.label}
            </ProjectButton>);
                },
                filterFn: 'arrIncludesSome',
            },
            {
                id: 'status',
                accessorFn: (row) => {
                    if (!row.status)
                        return t('table.empty_column');
                    return getProjectStatusFromCode(row.status);
                },
                header: () => getColumnHeader('project_status'),
                cell: ({ row }) => getProjectStatusFromCode(row.original.status),
                filterFn: 'arrIncludesSome',
            },
        ],
    },
];
const ContactSection = ({ contact, isFetching, }) => {
    const deleteTab = useDeleteTab();
    const { deleteContact } = useContact();
    const updateExtraFilter = useUpdateExtraFilter();
    const toggleLayerTab = useToggleLayerTab();
    const { plotOwnerCount, isLoadingPlotOwnerCount } = useOwner({
        siren: contact?.siren,
    });
    const { addNoteToContact, deleteNoteFromContact, updateNoteFromContact } = useContact();
    if (!contact) {
        return null;
    }
    return (<VStack spacing={SPACING_PANEL} alignItems="flex-start">
      <ContactInfo contact={contact} isFetching={isFetching}/>
      <VStack width="full" spacing={3}>
        <Heading width="full" as="h4" variant="h4">
          {t('tabs.detail_contact.title_plots')}
        </Heading>

        <Box width="full">
          <Table id="table-contact-plots" columns={COLUMNS_PLOTS} data={contact?.plots || []} isLoading={isFetching} emptyMessage={t('tabs.detail_contact.no_plots')} withExport={false}/>
        </Box>
        <Box width="full">
          {isLoadingPlotOwnerCount ? (<Spinner />) : plotOwnerCount > 0 ? (<Button variant="primary" onClick={() => {
                updateExtraFilter(filtersKey.COMPANY_OWNED_PLOTS, {
                    sirens: contact ? [contact.siren] : [],
                });
                toggleLayerTab({
                    tabId: filtersKey.COMPANY_OWNED_PLOTS,
                });
            }}>
              <>
                {plotOwnerCount > NB_PLOT_LIMIT
                ? t('tabs.detail_contact.owner_plots_max', {
                    count: plotOwnerCount,
                    max: NB_PLOT_LIMIT,
                })
                : t('tabs.detail_contact.owner_plots', {
                    count: plotOwnerCount,
                })}
              </>
            </Button>) : (contact?.siren?.length > 0 && (<Text>{t('tabs.detail_contact.owner_plots_nothing')}</Text>))}
        </Box>
      </VStack>
      <VStack width="full" spacing={3}>
        <Heading width="full" as="h4" variant="h4">
          {t('tabs.detail_contact.title_projects')}
        </Heading>

        <Box width="full">
          <Table id="table-contact-projects" columns={COLUMNS_PROJECTS} data={contact?.projects || []} isLoading={isFetching} emptyMessage={t('tabs.detail_contact.no_project')} withExport={false}/>
        </Box>
      </VStack>
      <NoteList notes={contact?.notes || []} title="tabs.detail_contact.title_comments" modalId={contact?.id} modalLabel="tabs.detail_contact.note.add_note" modalSubmit={(data, onSuccess) => {
            addNoteToContact(data, {
                onSuccess,
            });
        }} onDeleteNote={(noteId, onSuccess) => {
            deleteNoteFromContact({
                noteId,
                contactId: contact?.id,
            }, {
                onSuccess,
            });
        }} onUpdateNote={(noteId, note, onSuccess) => {
            updateNoteFromContact({
                noteId,
                note,
                contactId: contact?.id,
            }, {
                onSuccess,
            });
        }}/>
      <ActivitiesSection contactId={contact.id}/>
      <DeathSection contact={contact}/>
      <Box paddingTop={2}>
        <DeleteButton id="delete_contact" variant="outlineDelete" label={t('tabs.detail_contact.delete')} confirmText={t('tabs.detail_contact.delete_confirm')} handleDelete={() => {
            deleteContact(contact.id, {
                onSuccess: () => deleteTab({
                    type: CONTACT_TAB_TYPE,
                    id: contact.id,
                }),
            });
        }} showIcon/>
      </Box>
    </VStack>);
};
export default ContactSection;
