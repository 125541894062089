import * as React from 'react';
import { Box } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_TELECOMMANDE_ACTIONS, } from 'analytics/useAnalytics';
import FavoriteOffIcon from 'images/icons/FavoriteOff';
import FavoriteOnIcon from 'images/icons/FavoriteOn';
const SaveFilterButton = ({ isOpenSaveModal, onOpenSaveModal, }) => {
    const { trackEvent } = useAnalytics();
    return isOpenSaveModal ? (<Box marginRight={2} height="auto" minWidth="auto" cursor="pointer">
      <FavoriteOnIcon fill="red" aria-label="favorite-icon" onClick={(e) => {
            e.preventDefault();
            onOpenSaveModal();
            trackEvent({
                category: ANALYTICS_TELECOMMANDE_ACTIONS,
                action: 'Sauvegarder le filtre',
            });
        }} data-cy="save_plot_search_filter"/>
    </Box>) : (<Box marginRight={2} height="auto" minWidth="auto" cursor="pointer">
      <FavoriteOffIcon fill="red" aria-label="favorite-icon" onClick={(e) => {
            e.preventDefault();
            onOpenSaveModal();
            trackEvent({
                category: ANALYTICS_TELECOMMANDE_ACTIONS,
                action: 'Sauvegarder le filtre',
            });
        }} data-cy="save_plot_search_filter"/>
    </Box>);
};
export default SaveFilterButton;
