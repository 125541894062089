const PRECISION_BY_ADDRESS_COMPONENT_TYPE = {
    street_number: 3,
    route: 2,
    locality: 1,
    coordinates: 2,
};
export const getComponentType = (place) => place.address_components[0].types[0];
export const getPrecisionFromPlace = (place) => {
    if (!place)
        return null;
    const component_type = getComponentType(place);
    return PRECISION_BY_ADDRESS_COMPONENT_TYPE[component_type] ?? 0;
};
export const getPrecisionFromAddressString = (address) => {
    const detail = address.split(',');
    // Only town
    if (detail.length === 1) {
        return 1;
    }
    // street_number
    if (address.match('^[0-9]+ (.?)+, [0-9]+ (.?)+')) {
        return 3;
    }
    // route
    return 2;
};
export const getFormattedAddressFromPlace = (place) => {
    const component_type = getComponentType(place);
    return component_type === 'locality'
        ? place.address_components[0].long_name
        : place.formatted_address;
};
export const getLatLngFromPlace = (place) => {
    const lat = place.geometry.location.lat().toString();
    const lng = place.geometry.location.lng().toString();
    return { lat, lng };
};
