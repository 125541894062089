import * as React from 'react';
import { FormProvider } from 'react-hook-form';
import ProjectFeasibilityTable from 'components/tabs/crmTabs/project/ProjectFeasibilityTable';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { FEASIBILITY_PLOT_TAB } from 'types/api';
const FeasibilitySection = ({ project, feasibilityPlots, feasibilityMainPlot, secondaryPlots, isInitialLoading, methods, }) => {
    const { hasPlotTab } = useSiteConfiguration();
    return project && hasPlotTab(FEASIBILITY_PLOT_TAB) ? (<>
      <FormProvider {...methods}>
        <ProjectFeasibilityTable feasibilityPlots={feasibilityPlots} feasibilityMainPlot={feasibilityMainPlot} secondaryPlots={secondaryPlots} isInitialLoading={isInitialLoading}/>
      </FormProvider>
    </>) : null;
};
export default FeasibilitySection;
