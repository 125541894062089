import { useEffect } from 'react';
import usePersistentContext from 'hooks/usePersistentContext';
import { PLOT_STATUS_OPTIONS, PLOT_STATUS_VALUES, PLOT_STATUS_VALUES_WITH_NONE, } from 'utils/constants';
import usePersonalizationPlot from './usePersonalizationPlot';
export const PERSONALIZATION_KEY = 'personalization';
const usePersonalization = () => {
    const [personalization, setPersonalization] = usePersistentContext(PERSONALIZATION_KEY);
    const { personalizationPlotStatus } = usePersonalizationPlot();
    useEffect(() => {
        const perso = personalization || {};
        const persoPlot = {
            plotStatusValues: {},
            plotStatusValuesWithNone: {},
            plotStatusOptions: [],
        };
        if (personalizationPlotStatus &&
            personalizationPlotStatus.status.length > 0) {
            personalizationPlotStatus.status.forEach((plotStatus) => {
                persoPlot.plotStatusValues[plotStatus.statusId] = {
                    label: plotStatus.label,
                    color: plotStatus.color,
                    legendOrder: plotStatus.order,
                };
                persoPlot.plotStatusOptions.push({
                    value: plotStatus.statusId,
                    label: plotStatus.label,
                    color: plotStatus.color,
                    legendOrder: plotStatus.order,
                });
            });
            persoPlot.plotStatusValuesWithNone = {
                0: { label: 'plot.status.none', color: '#FFFFFF', legendOrder: 9 },
                ...persoPlot.plotStatusValues,
            };
        }
        else {
            persoPlot.plotStatusValues = PLOT_STATUS_VALUES;
            persoPlot.plotStatusValuesWithNone = PLOT_STATUS_VALUES_WITH_NONE;
            persoPlot.plotStatusOptions = PLOT_STATUS_OPTIONS;
        }
        perso.plot = persoPlot;
        setPersonalization(perso);
    }, [personalizationPlotStatus]);
    return {
        personalization,
        setPersonalization,
    };
};
export default usePersonalization;
