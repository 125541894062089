import { useMemo } from 'react';
import * as React from 'react';
import createLayersHook from 'hooks/layers/createLayersHook';
import { VECTOR_TYPES } from 'types/types';
import { filtersKey } from 'utils/filters';
const useTownLayer = ({ selectedTown }) => {
    const layersData = useMemo(() => {
        return [
            {
                id: selectedTown?.id,
                layerKey: filtersKey.TOWN,
                type: VECTOR_TYPES.polygon,
                swkt: selectedTown?.swkt,
                borderColor: '#000000',
                borderWidth: 5,
                opacity: 0,
            },
        ];
    }, [selectedTown]);
    createLayersHook({
        providedData: layersData,
        legends: {},
    })(filtersKey.TOWN);
};
const TownLayer = ({ selectedTown }) => {
    useTownLayer({ selectedTown });
    return null;
};
const TownLayerWrapper = ({ selectedTown }) => {
    if (!selectedTown) {
        return null;
    }
    return <TownLayer selectedTown={selectedTown}/>;
};
export default TownLayerWrapper;
