import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getLayerCoordTileUrl } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
import { useSelectedTown } from '../../../hooks/contexts/useLocationContext';
const getLayerTileUrl = () => (coord, zoom) => {
    return getLayerCoordTileUrl('PROTECTEDSITES.MNHN.RESERVES-REGIONALES', 'PROTECTEDSITES.MNHN.RESERVES-REGIONALES', zoom, coord);
};
const getLayerTileCorseUrl = () => (coord, zoom) => {
    return getLayerCoordTileUrl('PROTECTEDAREAS.RNC', 'PROTECTEDAREAS.RNC', zoom, coord);
};
const getLegends = () => {
    const legends = {};
    legends.regional_reserve = {
        layerKey: filtersKey.REGIONAL_RESERVE,
        label: 'regional_reserve',
        needsTranslate: true,
        color: '#8DA954',
        opacity: 1,
    };
    return legends;
};
const useRegionalReserveLayer = () => {
    const selectedTown = useSelectedTown();
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.REGIONAL_RESERVE],
        getLegends,
    })(filtersKey.REGIONAL_RESERVE);
    if (parseInt(selectedTown?.id) > 20000 &&
        parseInt(selectedTown?.id) < 20999) {
        createTilesLayerHook({
            getLayerTileUrl: getLayerTileCorseUrl,
            position: CST_POSITIONS[filtersKey.REGIONAL_RESERVE],
            getLegends,
        })(filtersKey.REGIONAL_RESERVE);
    }
};
const RegionalReserveLayer = () => {
    useRegionalReserveLayer();
    return null;
};
export default RegionalReserveLayer;
