import * as React from 'react';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Heading, HStack, Spinner, VStack } from '@chakra-ui/react';
import StatusSelect from 'components/inputs/StatusSelect';
import { isAvailable, } from 'components/tabs/crmTabs/TeamStatisticsCRMPanel';
import UserStatisticsStatusItem from 'components/tabs/crmTabs/userStatisticsCRM/UserStatisticsStatusItem';
import useTeamStatisticsCRM from 'hooks/crm/useTeamStatisticsCRM';
import { PROJECT_STATUS_OPTIONS } from 'utils/constants';
const TeamStatisticsProjectsByUsersPanel = ({ filters, statsFilters, }) => {
    const { t } = useTranslation();
    const [dataFormatted, setDataFormatted] = useState({
        status: {},
        percent: {},
    });
    const { statsProjects, isLoadingStatsProjects } = useTeamStatisticsCRM({
        toLoad: 'projects',
    });
    const [status, setStatus] = useState(null);
    useEffect(() => {
        if (statsProjects && statsFilters) {
            const data = { status: {}, percent: {} };
            statsFilters.users.forEach((statsFilter) => {
                data.status[statsFilter.email] = 0;
                data.percent[statsFilter.email] = 0;
            });
            let nbProjects = 0;
            for (const userEmail in statsProjects) {
                nbProjects += statsProjects[userEmail].length;
            }
            const filtersCurrent = { ...filters, status };
            for (const userEmail in statsProjects) {
                if (!isAvailable({ filters, email: userEmail })) {
                    continue;
                }
                const userData = statsProjects[userEmail];
                userData.forEach((project) => {
                    const date = new Date(project.updatedAt);
                    if (!isAvailable({
                        filters: filtersCurrent,
                        date,
                        status: project.status,
                        group: project.group,
                    })) {
                        return;
                    }
                    data.status[userEmail] += 1;
                    const percent = (data.status[userEmail] / nbProjects) * 100;
                    data.percent[userEmail] = percent.toFixed(2);
                });
            }
            setDataFormatted(data);
        }
    }, [statsProjects, filters, statsFilters, status]);
    return (<Box flex={1}>
      <HStack marginBottom={2}>
        <Heading as="h3" variant="h3">
          {t('tabs.team_statistics_crm.projects_by_users')}
        </Heading>
        <StatusSelect data={[
            { value: 0, label: t('global.all_status') },
            ...PROJECT_STATUS_OPTIONS,
        ]} onChange={(option) => {
            setStatus(option.value);
        }} isLoading={isLoadingStatsProjects} minWidth="180px"/>
      </HStack>
      <VStack spacing={1} alignItems="flex-start" position="relative">
        {statsFilters?.users.map((user) => {
            return (<UserStatisticsStatusItem key={user.email} option={{
                    label: user.name,
                    value: user.email,
                }} data={dataFormatted}/>);
        })}
        {isLoadingStatsProjects && (<Box display="flex" alignItems="center" justifyContent="center" position="absolute" top={0} left={0} width="100%" height="100%" backgroundColor="rgba(251, 250, 249, 0.6)">
            <Spinner />
          </Box>)}
      </VStack>
    </Box>);
};
export default memo(TeamStatisticsProjectsByUsersPanel);
