import * as React from 'react';
import { Box, HStack, Popover, PopoverTrigger, Portal, Text, useColorModeValue, useDisclosure, } from '@chakra-ui/react';
import ActivityStatusInput from 'components/tabs/activities/ActivityTable/ActivityStatusInput';
import useActivity from 'hooks/useActivity';
import { AGENDA_ACTIVITY_TYPE_EVENT } from 'utils/constants';
import EventPopoverContent from './EventPopoverContent';
const getActivityTypeColors = (type) => {
    switch (type) {
        case 1:
            return ['green.100', 'green.600'];
        case 2:
            return ['red.100', 'red.400'];
        case 3:
            return ['yellow.100', 'yellow.500'];
        default:
            return ['blue.100', 'blue.500'];
    }
};
const CustomEvent = ({ eventDetail, setEditedActivity, }) => {
    const { onOpen, onClose, isOpen } = useDisclosure();
    const [lightColor, darkColor] = getActivityTypeColors(eventDetail.agendaType);
    const { deleteActivity, updateActivity, isDeleting, isUpdating } = useActivity({
        handleDeleteSuccess: onClose,
    });
    const activity = {
        ...eventDetail,
        id: eventDetail.activityId,
        title: eventDetail.activityTitle,
    };
    return (<Popover isLazy isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <Box cursor="pointer" width="100%">
          <HStack borderRadius="sm" paddingX={2} paddingY={1} backgroundColor={isOpen
            ? useColorModeValue(darkColor, lightColor)
            : useColorModeValue(lightColor, darkColor)} width="100%" _hover={{
            backgroundColor: useColorModeValue(darkColor, lightColor),
            color: useColorModeValue(lightColor, darkColor),
        }} color={isOpen
            ? useColorModeValue(lightColor, darkColor)
            : useColorModeValue(darkColor, lightColor)} minHeight="24px">
            {activity?.agendaType !== AGENDA_ACTIVITY_TYPE_EVENT && (<ActivityStatusInput activity={activity} type="checkbox" hideLabel updateActivity={updateActivity} isLoading={isDeleting || isUpdating}/>)}
            <Text color="currentcolor" fontWeight="bold" textStyle="small" textDecorationLine={activity?.status ? 'line-through' : undefined} noOfLines={1}>
              {eventDetail.title}
            </Text>
          </HStack>
        </Box>
      </PopoverTrigger>
      <Portal>
        <EventPopoverContent eventDetail={eventDetail} setEditedActivity={setEditedActivity} deleteActivity={deleteActivity} updateActivity={updateActivity} isLoading={isDeleting || isUpdating}/>
      </Portal>
    </Popover>);
};
export default CustomEvent;
