import * as React from 'react';
import { Box, Button, HStack } from '@chakra-ui/react';
const IconSwitch = ({ handleClick, leftIcon, rightIcon, selectedChoice, id, ...props }) => {
    return (<Button backgroundColor="colorMode.background700" cursor="pointer" borderRadius="full" position="relative" onClick={handleClick} width="auto" height="auto" padding={0} data-cy={id} {...props}>
      <HStack spacing={1}>
        <Box height="32px" width="32px" display="flex" justifyContent="center" alignItems="center" zIndex={1}>
          {leftIcon}
        </Box>
        <Box height="32px" width="32px" cursor="pointer" bgColor="blue.500" borderRadius="full" position="absolute" right={selectedChoice === 'right' ? 0 : 'calc(100% - 32px)'} transition={'all 0.3s ease'}/>

        <Box height="32px" width="32px" display="flex" justifyContent="center" alignItems="center" zIndex={1}>
          {rightIcon}
        </Box>
      </HStack>
    </Button>);
};
export default IconSwitch;
