import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Heading, useDisclosure, } from '@chakra-ui/react';
import { AGENDA_ACTIVITY_TYPE_TASK, AGENDA_ACTIVITY_TYPE_VALUES, } from 'utils/constants';
import ActivityForm from './ActivityForm';
const ActivityDrawer = ({ activityInfo, triggerItem, handleClose, }) => {
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    React.useEffect(() => {
        if (activityInfo && !isOpen) {
            onOpen();
        }
    }, [activityInfo]);
    const agendaType = activityInfo?.agendaType ?? AGENDA_ACTIVITY_TYPE_TASK;
    const isCreation = !activityInfo?.id;
    return (<>
      {triggerItem && triggerItem(onOpen)}
      <Drawer isOpen={isOpen} onClose={() => {
            onClose();
            handleClose();
        }} size="md" returnFocusOnClose={false}>
        <DrawerOverlay />
        <DrawerContent backgroundColor="colorMode.background900">
          <DrawerCloseButton />
          <DrawerHeader>
            <Heading as="h4" variant="h4">
              {t(`activity.actions.${isCreation ? 'add' : 'modify'}_${AGENDA_ACTIVITY_TYPE_VALUES[agendaType]}`)}{' '}
            </Heading>
          </DrawerHeader>
          <DrawerBody>
            <ActivityForm activityInfo={activityInfo} onClose={onClose}/>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>);
};
export default ActivityDrawer;
