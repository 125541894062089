import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOMaximumHeightLayerItem = IOLayerItem;
const fetchMaximumHeight = (townId) => () => get(iots.array(IOMaximumHeightLayerItem), `legacy/${townId}/maximum-height`);
const useMaximumHeightLayer = () => {
    createLayersHook({
        fetch: fetchMaximumHeight,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.MAXIMUM_HEIGHT);
};
const MaximumHeightLayer = () => {
    useMaximumHeightLayer();
    return null;
};
export default MaximumHeightLayer;
