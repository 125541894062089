import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IODocUrbaLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOWetlandAreaLayerItem = IODocUrbaLayerItem;
const fetchWetlandArea = (townId) => () => get(iots.array(IOWetlandAreaLayerItem), `${townId}/wetland-area`);
const useWetlandAreaLayer = () => {
    createLayersHook({
        fetch: fetchWetlandArea,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.WETLAND_AREA);
};
const WetlandAreaLayer = () => {
    useWetlandAreaLayer();
    return null;
};
export default WetlandAreaLayer;
