import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Heading, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_LAND_POINT } from 'analytics/useAnalytics';
import BuildingIcon from 'images/icons/Building';
import ContactsIcon from 'images/icons/Contacts';
import EuroIcon from 'images/icons/Euro';
import LotsIcon from 'images/icons/Lots';
import PlotIcon from 'images/icons/Plot';
import SurfaceIcon from 'images/icons/Surface';
import { getProjectStatusColorFromCode, getProjectStatusFromCode, } from 'utils/constants';
import { numberWithSpaces } from 'utils/text';
const LandPointProject = ({ project, projectId, setSelectedProject, nbParticipant, }) => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const openProject = (project) => {
        setSelectedProject(project);
        trackEvent({
            category: ANALYTICS_LAND_POINT,
            action: 'Ouvrir un projet',
        });
    };
    return (<VStack spacing={0} boxShadow={project.id === projectId ? 'md' : 'none'} opacity={project.id === projectId ? 1 : 0.8} backgroundColor="white" width="full" borderRadius="md" onClick={() => openProject(project)} style={{ cursor: 'pointer' }} outline={(project.id === projectId ? 2 : 0) + 'px solid #AAAAAA'} _hover={{ boxShadow: 'md', opacity: 1 }} fontSize="14px">
      {nbParticipant > 1 && (<Box width="full" color="white" backgroundColor="#838383" padding={1} fontSize="11px" textAlign="center">
          {project.userName}
        </Box>)}
      <Box display="inline-block" color="white" padding={2} borderBottomLeftRadius="md" borderBottomRightRadius="md" fontSize="14px" backgroundColor={getProjectStatusColorFromCode(project.status)}>
        {getProjectStatusFromCode(project.status)}
      </Box>
      <VStack spacing={3} padding={3}>
        <Box textAlign="center">
          <Heading as="h3" variant="h3" marginBottom={1}>
            {project.label}
          </Heading>
          <Box>{project.town}</Box>
        </Box>
        <HStack flexWrap="wrap" spacing={1} justifyContent="center">
          <Box display="flex" alignItems="center" marginX={1}>
            <Icon as={PlotIcon} fill="colorMode.font900" width="20px" height="20px"/>
            <Text as="span" marginLeft={2} data-cy="plot_form_plot_number">
              <strong>{numberWithSpaces(project.nbPlot ?? 0)}</strong>{' '}
              {t('tabs.project.plot', { count: project.nbPlot ?? 0 })}
            </Text>
          </Box>
          <Box display="flex" alignItems="center" marginX={1}>
            <Icon as={SurfaceIcon} fill="colorMode.font700" width="20px" height="20px"/>
            <Text as="span" marginLeft={2}>
              <strong>{numberWithSpaces(project.surface)} m²</strong>{' '}
              {t('tabs.project.of_surface')}
            </Text>
          </Box>
          {project.nbContact && (<Box display="flex" alignItems="center" marginX={1}>
              <Icon as={ContactsIcon} fill="colorMode.font900" width="20px" height="20px"/>
              <Text as="span" marginLeft={2}>
                <strong>{numberWithSpaces(project.nbContact)}</strong>{' '}
                {t('tabs.project.contact', {
                count: project.nbContact,
            })}
              </Text>
            </Box>)}
        </HStack>
        <HStack flexWrap="wrap" spacing={1} justifyContent="center">
          {project.rightToBuild && (<Box display="flex" alignItems="center" marginX={1}>
              <Icon as={BuildingIcon} fill="colorMode.font900" width="18px" height="18px"/>
              <Text as="span" marginLeft={2}>
                <strong>{numberWithSpaces(project.rightToBuild)} m²</strong>{' '}
                {t('tabs.project.rightToBuild')}
              </Text>
            </Box>)}
          {project.lots && (<Box display="flex" alignItems="center" marginX={1}>
              <Icon as={LotsIcon} fill="colorMode.font900" stroke="colorMode.font900" width="20px" height="20px"/>
              <Text as="span" marginLeft={2}>
                <strong>{numberWithSpaces(project.lots)}</strong>{' '}
                {t('tabs.project.lots', {
                count: project.nbContact,
            })}
              </Text>
            </Box>)}
          {project.turnover && (<Box display="flex" alignItems="center" marginX={2}>
              <Icon as={EuroIcon} fill="colorMode.font900" width="18px" height="18px"/>
              <Text as="span" marginLeft={2}>
                <strong>{numberWithSpaces(project.turnover)}</strong> €
              </Text>
            </Box>)}
        </HStack>
      </VStack>
    </VStack>);
};
export default LandPointProject;
