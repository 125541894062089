import { useCallback, useEffect, useRef, useState } from 'react';
import { useDisclosure, usePrevious } from '@chakra-ui/react';
import { useDrawingMode, useToggleDrawingMode, } from 'hooks/contexts/useLocationContext';
import useGetAltimetry from 'hooks/useAltimetry';
import { isFreeDrawing, pointExists } from 'utils/drawingUtils';
import { numberWithSpaces } from 'utils/text';
const DRAW_COLOR = '#00FA9A';
const DRAW_OPTIONS = {
    strokeColor: DRAW_COLOR,
    strokeOpacity: 1,
    strokeWeight: 3,
    fillOpacity: 0.3,
    fillColor: DRAW_COLOR,
    editable: true,
    draggable: false,
    clickable: false,
    zIndex: 1,
};
const DREW_COLOR = '#00D2FF';
const getDistanceResultContent = (points) => {
    let distance = google.maps.geometry.spherical.computeLength(points);
    distance = Math.round(distance * 100) / 100;
    const distanceKm = distance / 1000;
    const distanceResult = {
        meter: numberWithSpaces(distance.toFixed(2)),
        km: numberWithSpaces(distanceKm.toFixed(2)),
    };
    if (distanceKm < 1) {
        return `${distanceResult.meter} m`;
    }
    return `${distanceResult.km} km`;
};
const getAreaResult = (path, t) => {
    let area = google.maps.geometry.spherical.computeArea(path);
    area = Math.round(area * 100) / 100;
    const areaKm = area / 1000000;
    const areaHa = area / 10000;
    const bounds = new google.maps.LatLngBounds();
    path.forEach(function (latlng) {
        bounds.extend(latlng);
    });
    const northEastBounds = bounds.getNorthEast();
    const southWestBounds = bounds.getSouthWest();
    const lngMax = northEastBounds.lng();
    const lngMin = southWestBounds.lng();
    const latMax = northEastBounds.lat();
    const lng = lngMin + (lngMax - lngMin) / 2;
    const position = new google.maps.LatLng(latMax, lng);
    let content;
    if (areaKm < 1) {
        content = `<div class="info-window-measure">${t('toolbar.measure.area_result_m', {
            meter: numberWithSpaces(area.toFixed(2)),
            ha: numberWithSpaces(areaHa.toFixed(2)),
        })}</div>`;
    }
    else {
        content = `<div class="info-window-measure">${t('toolbar.measure.area_result_km', {
            ha: numberWithSpaces(areaHa.toFixed(2)),
            km: numberWithSpaces(areaKm.toFixed(2)),
        })}</div>`;
    }
    return { position, content };
};
export const useMeasure = (map, t) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const activeDrawing = useRef(null);
    const validatedPoints = useRef([]);
    const [altimetryPoints, setAltimetryPoints] = useState(null);
    const { isLoading, data: altimetry } = useGetAltimetry(altimetryPoints);
    const previousAltimetry = usePrevious(altimetry);
    const clickListener = useRef(null);
    const mouseMoveListener = useRef(null);
    const drawingsData = useRef([]);
    const drawingMode = useDrawingMode();
    const toggleDrawingMode = useToggleDrawingMode();
    useEffect(() => {
        cleanAll();
        if (isFreeDrawing(drawingMode) || drawingMode === null) {
            return;
        }
        startDrawing(drawingMode);
    }, [drawingMode]);
    useEffect(() => {
        return () => {
            cleanAll();
        };
    }, []);
    useEffect(() => {
        if (!previousAltimetry && isLoading) {
            onOpen();
        }
        if (!altimetry && !isLoading) {
            onClose();
        }
    }, [isLoading]);
    const setCurrentDrawing = (drawing, listener = null) => {
        activeDrawing.current = drawing;
        drawing.setMap(map);
        drawingsData.current.push({
            drawing: drawing,
            infoWindow: null,
            listener,
        });
    };
    const startDrawing = (mode) => {
        if (!map) {
            return;
        }
        map.getDiv().classList.add('crosshair-cursor');
        clickListener.current = map.addListener('click', (event) => {
            const latLng = event.latLng;
            const currentDrawing = activeDrawing.current;
            // drawing started
            if (!currentDrawing) {
                createDrawing(latLng, mode);
                return;
            }
            // new point added
            if (!pointExists(validatedPoints.current, latLng)) {
                validatedPoints.current.push(latLng);
                addPoint(latLng, currentDrawing);
                return;
            }
            // drawing completed
            showResult(mode, currentDrawing);
            stopCurrentDrawing(mode);
        });
        mouseMoveListener.current = map.addListener('mousemove', (event) => {
            if (!activeDrawing?.current) {
                return;
            }
            showLiveResult(mode, event.latLng);
        });
    };
    const createDrawing = (latLng, mode) => {
        const startingPoint = {
            lat: latLng.lat(),
            lng: latLng.lng(),
        };
        let newDrawing = null;
        validatedPoints.current = [latLng];
        if (mode === 'area') {
            newDrawing = new google.maps.Polygon({
                paths: [startingPoint],
                ...DRAW_OPTIONS,
            });
        }
        else {
            newDrawing = new google.maps.Polyline({
                path: [startingPoint],
                ...DRAW_OPTIONS,
            });
        }
        const listener = newDrawing.addListener('click', (event) => {
            // new point clicked
            if (!pointExists(validatedPoints.current, event.latLng)) {
                validatedPoints.current.push(event.latLng);
                addPoint(event.latLng, newDrawing);
                return;
            }
            // drawing completed
            showResult(mode, newDrawing);
            stopCurrentDrawing(mode);
        });
        setCurrentDrawing(newDrawing, listener);
        // Recompute the result when the drawing is modified
        newDrawing.addListener('bounds_changed', () => {
            showResult(mode, newDrawing);
        });
        const path = newDrawing.getPath();
        path.addListener('set_at', () => {
            showResult(mode, newDrawing);
        });
        path.addListener('insert_at', () => {
            showResult(mode, newDrawing);
        });
        path.addListener('remove_at', () => {
            showResult(mode, newDrawing);
        });
    };
    const addPoint = (latLng, drawing) => {
        const path = drawing.getPath();
        path.push(latLng);
    };
    const showLiveResult = (mode, position) => {
        if (!activeDrawing.current) {
            return;
        }
        const currentPath = activeDrawing.current.getPath();
        if (currentPath.getLength() > validatedPoints.current.length) {
            currentPath.pop();
        }
        addPoint(position, activeDrawing.current);
        if (mode === 'altimetry') {
            return;
        }
        showResult(mode, activeDrawing.current);
    };
    const showResult = (mode, drawing) => {
        const path = drawing.getPath();
        if (mode === 'area') {
            if (path.getLength() < 3) {
                return;
            }
            const { position, content } = getAreaResult(path, t);
            displayResults(drawing, content, position);
            return;
        }
        const points = path.getArray();
        if (mode === 'distance') {
            const position = points[points.length - 1];
            const content = getDistanceResultContent(points);
            displayResults(drawing, content, position);
            return;
        }
        if (mode == 'altimetry' && !activeDrawing.current) {
            setAltimetryPoints(points.map((point) => ({
                lat: point.lat().toString(),
                lng: point.lng().toString(),
            })));
        }
    };
    const displayResults = useCallback((drawing, content, position) => {
        const drawingData = drawingsData.current.find((d) => d.drawing === drawing);
        if (!drawingData) {
            return;
        }
        let infoWindow = drawingData.infoWindow;
        if (infoWindow) {
            infoWindow.setContent(content);
            infoWindow.setPosition(position);
            return;
        }
        infoWindow = new google.maps.InfoWindow({
            content: content,
            position: position,
            pixelOffset: new google.maps.Size(0, -8),
            maxWidth: 700,
        });
        drawingData.infoWindow = infoWindow;
        infoWindow.addListener('close', () => {
            removeDrawing(drawing);
        });
        infoWindow.open(map);
    }, [map]);
    const stopDrawing = useCallback(() => {
        if (clickListener.current) {
            google.maps.event.removeListener(clickListener.current);
            clickListener.current = null;
        }
        if (mouseMoveListener.current) {
            google.maps.event.removeListener(mouseMoveListener.current);
            mouseMoveListener.current = null;
        }
        if (!map) {
            return;
        }
        map.getDiv().classList.remove('crosshair-cursor');
    }, [map]);
    const stopCurrentDrawing = (mode) => {
        const drawingData = drawingsData.current.find((d) => d.drawing === activeDrawing.current);
        drawingData.listener?.remove();
        activeDrawing.current = null;
        drawingData.drawing.setOptions({
            strokeColor: DREW_COLOR,
            fillColor: DREW_COLOR,
        });
        drawingData.drawing.addListener('click', () => {
            removeDrawing(drawingData.drawing);
        });
        if (mode === 'altimetry') {
            setAltimetryPoints(drawingData.drawing
                .getPath()
                .getArray()
                .map((point) => ({
                lat: point.lat().toString(),
                lng: point.lng().toString(),
            })));
        }
    };
    const removeDrawing = (drawing) => {
        if (!drawing) {
            return;
        }
        drawing.setMap(null);
        const index = drawingsData.current.findIndex((d) => d.drawing === drawing);
        drawingsData.current[index]?.infoWindow?.close();
        drawingsData.current.splice(index, 1);
    };
    const cleanAll = () => {
        const currentDrawingsData = [...drawingsData.current];
        currentDrawingsData.forEach(({ drawing, infoWindow, listener }) => {
            drawing.setMap(null);
            infoWindow?.close();
            listener?.remove();
        });
        drawingsData.current = [];
        setAltimetryPoints(null);
        stopDrawing();
        validatedPoints.current = [];
        activeDrawing.current = null;
        onClose();
    };
    return {
        drawingMode,
        toggleDrawingMode,
        showAltimetry: isOpen,
        isLoadingAltimetry: isLoading,
        altimetry,
    };
};
