import * as React from 'react';
import { Box, VisuallyHidden } from '@chakra-ui/react';
import { VECTOR_TYPES } from 'types/types';
import { DEFAULT_BORDER_WIDTH, DEFAULT_POLYLINE_BORDER_WIDTH } from 'utils/map';
const getColorWithOpacity = (color, opacity) => {
    if (!color) {
        return null;
    }
    if (color.startsWith('#') && color.length === 7 && opacity) {
        const opacityHex = Math.round(opacity * 255)
            .toString(16)
            .padStart(2, '0')
            .toUpperCase();
        return color + opacityHex;
    }
    return color;
};
const POINT_HEIGHT = 5;
const DEFAULT_WIDTH = 22;
const DEFAULT_HEIGHT = 20;
const WHITE_HEXADECIMAL = '#FFFFFF';
const ColorLegend = ({ legend }) => {
    const isLine = legend.polygonType == VECTOR_TYPES.polyline ||
        legend.polygonType == VECTOR_TYPES.polyligne;
    const isPoint = legend.polygonType == VECTOR_TYPES.point;
    const color = getColorWithOpacity(legend.color, legend.opacity);
    const height = isLine
        ? legend.borderWidth ?? DEFAULT_POLYLINE_BORDER_WIDTH
        : isPoint
            ? legend.height ?? POINT_HEIGHT
            : DEFAULT_HEIGHT;
    const width = isPoint ? height : legend.width ?? DEFAULT_WIDTH;
    const borderColor = legend?.color?.toUpperCase() === WHITE_HEXADECIMAL
        ? 'colorMode.background500'
        : getColorWithOpacity(legend.borderColor, legend.borderOpacity);
    const borderRadius = legend.borderRadius ?? (isPoint ? '100' : 'md');
    const borderWidth = isLine ? null : legend.borderWidth ?? DEFAULT_BORDER_WIDTH;
    return (<Box width="30px" display="flex" justifyContent="center">
      <Box height={`${height}px`} width={`${width}px`} backgroundColor={color} borderRadius={borderRadius} border={borderColor && borderWidth ? `${borderWidth}px solid` : 'none'} borderColor={borderColor || 'none'} marginRight={2}>
        <VisuallyHidden>{color}</VisuallyHidden>
      </Box>
    </Box>);
};
export default ColorLegend;
