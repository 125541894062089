import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Box, Button, Text, useDisclosure } from '@chakra-ui/react';
import useAnalytics from 'analytics/useAnalytics';
export const SeeMoreAccordion = ({ title, children, analyticOrigin, analyticDetail, }) => {
    const { trackEvent } = useAnalytics();
    const { getButtonProps, getDisclosureProps, isOpen } = useDisclosure();
    const { t } = useTranslation();
    const buttonProps = getButtonProps({
        size: 'sm',
        variant: 'link',
    });
    const disclosureProps = getDisclosureProps();
    return (<Box onClick={() => {
            if (!isOpen && analyticOrigin && analyticDetail) {
                trackEvent({
                    category: analyticOrigin,
                    action: analyticDetail,
                });
            }
        }}>
      <Button color="colorMode.font800" {...buttonProps}>
        <Text as="span" textStyle="paragraph" textDecoration="underline" _hover={{ color: 'colorMode.font900' }} whiteSpace="normal">
          {t(title)}
          <ChevronDownIcon w={4} h={4} fill={'colorMode.font700'} transform={isOpen ? 'rotateX(180deg)' : 'rotateX(0deg)'} transition="transform 0.5s"/>
        </Text>
      </Button>

      <Box {...disclosureProps}>{children}</Box>
    </Box>);
};
export default SeeMoreAccordion;
