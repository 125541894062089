import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOLayoutOrientationLayerItem = IOLayerItem;
const fetchLayoutOrientation = (townId) => () => get(iots.array(IOLayoutOrientationLayerItem), `legacy/${townId}/layout-orientation`);
const useLayoutOrientationLayer = () => {
    createLayersHook({
        fetch: fetchLayoutOrientation,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.LAYOUT_ORIENTATION);
};
const LayoutOrientationLayer = () => {
    useLayoutOrientationLayer();
    return null;
};
export default LayoutOrientationLayer;
