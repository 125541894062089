import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
const fetchTownsPostalCodes = () => get(iots.record(iots.string, iots.array(iots.string)), 'towns-postal-codes');
export const QUERY_KEY = 'postal-code-town';
const useTown = () => {
    const { data: townsPostalCodes, isInitialLoading: isLoadingTownsPostalCodes, } = useQuery({ queryKey: [QUERY_KEY], queryFn: fetchTownsPostalCodes });
    return {
        townsPostalCodes,
        isLoadingTownsPostalCodes,
    };
};
export default useTown;
