import { useMemo } from 'react';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { useLayersTree } from 'hooks/contexts/useLayersTreeContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { getFilterNamesFromPathAndPrefix } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const LAYERS_PATH = 'regulatory.ppr.ppr_natural';
const LAYERS_PREFIX = 'ppr_natural';
const IOPPRNaturalLayerItem = IOLayerItem;
const fetchPPRNatural = (townId) => () => get(iots.array(IOPPRNaturalLayerItem), `legacy/${townId}/ppr-natural`);
const usePPRNaturalLayer = () => {
    const layersTree = useLayersTree();
    const filterNames = useMemo(() => {
        return getFilterNamesFromPathAndPrefix(layersTree, LAYERS_PATH, LAYERS_PREFIX);
    }, [JSON.stringify(layersTree)]);
    createLayersHook({
        fetch: fetchPPRNatural,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filterNames);
};
const PPRNaturalLayer = () => {
    usePPRNaturalLayer();
    return null;
};
export default PPRNaturalLayer;
