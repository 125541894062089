import * as React from 'react';
import { useRef, useState } from 'react';
import { Text, VStack } from '@chakra-ui/react';
import CourierSenderSelect from 'components/inputs/CourierSenderSelect';
import CreateSenderDrawer from '../senderForm/CreateSenderDrawer';
const SenderFields = ({ title, senderId, }) => {
    const [sender, setSender] = useState();
    const selectRef = useRef(null);
    return (<VStack width="full" spacing={1} alignItems="flex-start">
      <Text fontWeight="bold">{title}</Text>
      <VStack width="full" spacing={2} alignItems="flex-start">
        <CourierSenderSelect name="senderId" setSender={setSender} senderId={senderId} selectRef={selectRef}/>
        <CreateSenderDrawer sender={sender} setSender={setSender} selectRef={selectRef}/>
      </VStack>
    </VStack>);
};
export default SenderFields;
