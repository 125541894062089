import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { post } from 'api/client';
import { IOContact } from 'types/api';
const fetchContactList = (plotContactsIds) => () => post(iots.array(IOContact), 'contacts', { plotContactsIds });
export const QUERY_KEY = ['contact-list'];
const useContactList = (plotContactsIds, enabled = true) => {
    const { data: contacts, isInitialLoading, isFetching, } = useQuery({
        queryKey: [QUERY_KEY[0], plotContactsIds],
        queryFn: fetchContactList(plotContactsIds),
        enabled,
    });
    const getContactDetail = (id) => contacts?.find((contact) => contact?.id === id);
    return { contacts, isInitialLoading, isFetching, getContactDetail };
};
export default useContactList;
