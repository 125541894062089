import * as React from 'react';
import { PLOT_SELECTION_PROJECT } from 'context/LocationContext';
import { useSetPlotSelectionMode } from 'hooks/contexts/useLocationContext';
import { useGetFeasibilityPlots, useSetFeasibilityParentId, } from 'hooks/contexts/useSecondaryPlotsContext';
import { useToggleProject } from 'hooks/contexts/useTabsContext';
import useProject from 'hooks/crm/useProject';
import CreateButton from './CreateButton';
const CreateProjectFromFeasibilityButton = ({ townId, plot }) => {
    const getFeasibilityPlots = useGetFeasibilityPlots();
    const feasibilityPlots = getFeasibilityPlots(plot.id);
    const setFeasibilityParentId = useSetFeasibilityParentId();
    const setPlotSelectionMode = useSetPlotSelectionMode();
    const toggleProject = useToggleProject();
    const { addProject, isAddingProject } = useProject();
    const createProject = () => {
        if (isAddingProject) {
            return;
        }
        const plotIds = Object.keys(feasibilityPlots);
        plotIds.push(plot.id);
        addProject({
            plotIds: plotIds,
            townshipId: townId,
        }, {
            onSuccess: (createdProject) => {
                setPlotSelectionMode(PLOT_SELECTION_PROJECT);
                setFeasibilityParentId(createdProject.id);
                toggleProject({
                    resource: {
                        id: createdProject.id,
                        label: createdProject.label,
                        townId: townId,
                        lat: createdProject.lat,
                        lng: createdProject.lng,
                    },
                });
            },
        });
    };
    return (<CreateButton isDisabled={isAddingProject} onClick={createProject} label={'global.create_project'} variant="primary" size="sm"/>);
};
export default CreateProjectFromFeasibilityButton;
