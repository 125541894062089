import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, FormLabel, Select } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_URBANISM_FILTERS, } from 'analytics/useAnalytics';
import { useExtraFilters, useUpdateExtraFilter, } from 'hooks/contexts/useFiltersContext';
import { DPE_BUILDING_TYPE_OPTIONS, DPE_DPE_OPTIONS, DPE_GES_OPTIONS, } from 'utils/constants';
import { filtersKey, LAST_SIX_MONTHS, OLDER_YEAR } from 'utils/filters';
const getTimePeriodOptions = () => {
    const options = [LAST_SIX_MONTHS];
    const date = new Date();
    for (let i = date.getFullYear(); i >= OLDER_YEAR; i--) {
        options.push(i);
    }
    return options;
};
const DPESidebarContent = () => {
    const { trackEvent } = useAnalytics();
    const extraFilters = useExtraFilters();
    const updateExtraFilter = useUpdateExtraFilter();
    const { t } = useTranslation();
    const timePeriodSelectedOption = extraFilters?.[filtersKey.DPE]?.timePeriod || '';
    const timePeriodDisplayOptions = getTimePeriodOptions().map((option) => {
        return {
            value: option,
            label: option === LAST_SIX_MONTHS
                ? t(`global.select.time_period.last_six_months`)
                : option === OLDER_YEAR
                    ? `${option} ${t('global.select.time_period.and_before')}`
                    : option,
        };
    });
    const GESSelectedOption = extraFilters?.[filtersKey.DPE]?.labelGES || '';
    const GESDisplayOptions = DPE_GES_OPTIONS.map((option) => {
        return {
            value: option.value,
            label: option.label,
        };
    });
    const DPESelectedOption = extraFilters?.[filtersKey.DPE]?.labelDPE || '';
    const DPEDisplayOptions = DPE_DPE_OPTIONS.map((option) => {
        return {
            value: option.value,
            label: option.label,
        };
    });
    const buildingTypeSelectedOption = extraFilters?.[filtersKey.DPE]?.buildingType || '';
    const buildingTypeDisplayOptions = DPE_BUILDING_TYPE_OPTIONS.map((option) => {
        return {
            value: option.value,
            label: option.label,
        };
    });
    const handleTimePeriodChange = (e) => {
        const value = e.target.value;
        updateExtraFilter(`${filtersKey.DPE}.timePeriod`, value === LAST_SIX_MONTHS ? value : parseInt(value));
        trackEvent({
            category: ANALYTICS_URBANISM_FILTERS,
            action: `${t(`sidebar.categories.${filtersKey.DPE}`)} ${t('global.select.time_period.label')}`,
        });
    };
    const handleDPEChange = (e) => {
        const value = e.target.value;
        updateExtraFilter(`${filtersKey.DPE}.labelDPE`, value);
        trackEvent({
            category: ANALYTICS_URBANISM_FILTERS,
            action: `${t(`sidebar.categories.${filtersKey.DPE}`)} ${t('sidebar_content.dpe.dep.label')}`,
        });
    };
    const handleGESChange = (e) => {
        const value = e.target.value;
        updateExtraFilter(`${filtersKey.DPE}.labelGES`, value);
        trackEvent({
            category: ANALYTICS_URBANISM_FILTERS,
            action: `${t(`sidebar.categories.${filtersKey.DPE}`)} ${t('sidebar_content.dpe.ges.label')}`,
        });
    };
    const handleBuildingTypeChange = (e) => {
        const value = e.target.value;
        updateExtraFilter(`${filtersKey.DPE}.buildingType`, value);
        trackEvent({
            category: ANALYTICS_URBANISM_FILTERS,
            action: `${t(`sidebar.categories.${filtersKey.DPE}`)} ${t('sidebar_content.dpe.building_type.label')}`,
        });
    };
    const methods = useForm();
    return (<Box width="full">
      <FormProvider {...methods}>
        <form>
          <FormControl display="flex" alignItems="center" marginBottom={2}>
            <FormLabel textStyle="small" marginBottom={0} minWidth="80px">
              {t('global.select.time_period.label')}&nbsp;:
            </FormLabel>
            <Select size="sm" placeholder={t('global.select.time_period.placeholder')} onChange={handleTimePeriodChange} value={timePeriodSelectedOption}>
              {timePeriodDisplayOptions.map((option) => (<option key={option.value} value={option.value}>
                  {option.label}
                </option>))}
            </Select>
          </FormControl>
          <FormControl display="flex" alignItems="center" marginBottom={2}>
            <FormLabel textStyle="small" marginBottom={0} minWidth="80px">
              {t('sidebar_content.dpe.dpe.label')}&nbsp;:
            </FormLabel>
            <Select size="sm" placeholder={t('global.indifferent')} onChange={handleDPEChange} value={DPESelectedOption}>
              {DPEDisplayOptions.map((option) => (<option key={option.value} value={option.value}>
                  {option.label}
                </option>))}
            </Select>
          </FormControl>
          <FormControl display="flex" alignItems="center" marginBottom={2}>
            <FormLabel textStyle="small" marginBottom={0} minWidth="80px">
              {t('sidebar_content.dpe.ges.label')}&nbsp;:
            </FormLabel>
            <Select size="sm" placeholder={t('global.indifferent')} onChange={handleGESChange} value={GESSelectedOption}>
              {GESDisplayOptions.map((option) => (<option key={option.value} value={option.value}>
                  {option.label}
                </option>))}
            </Select>
          </FormControl>
          <FormControl display="flex" alignItems="center" marginBottom={2}>
            <FormLabel textStyle="small" marginBottom={0} minWidth="80px">
              {t('sidebar_content.dpe.building_type.label')}&nbsp;:
            </FormLabel>
            <Select size="sm" placeholder={t('global.indifferent')} onChange={handleBuildingTypeChange} value={buildingTypeSelectedOption}>
              {buildingTypeDisplayOptions.map((option) => (<option key={option.value} value={option.value}>
                  {option.label}
                </option>))}
            </Select>
          </FormControl>
        </form>
      </FormProvider>
    </Box>);
};
export default DPESidebarContent;
