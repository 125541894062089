import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from '@chakra-ui/react';
import i18n from 'i18n';
export const CIVILITY_TITLES = {
    1: 'contact.fields.title.options.madame',
    2: 'contact.fields.title.options.ladies',
    3: 'contact.fields.title.options.mister',
    4: 'contact.fields.title.options.gentlemen',
    5: 'contact.fields.title.options.madame_mister',
};
export const getCivilityFromCode = (code) => i18n.t(CIVILITY_TITLES[code]);
const CivilityTitleSelect = ({ register, onChange, }) => {
    const { t } = useTranslation();
    return (<Select {...register('title', { ...onChange })} placeholder={t('contact.fields.title.placeholder')}>
      {Object.keys(CIVILITY_TITLES).map((key) => (<option key={key} value={key}>
          {t(CIVILITY_TITLES[key])}
        </option>))}
    </Select>);
};
export default CivilityTitleSelect;
