import * as React from 'react';
import { Box, Text } from '@chakra-ui/react';
const ChartCustomTooltip = (props) => {
    const { active, payload } = props;
    if (active && payload && payload.length) {
        return (<Box backgroundColor="colorMode.background900" padding={4} borderRadius="md" boxShadow="md">
        <Text textStyle="paragraph">{`${payload[0].value}m`}</Text>
      </Box>);
    }
    return null;
};
export default ChartCustomTooltip;
