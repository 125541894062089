import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { IOActivity } from 'types/api';
export const QUERY_KEY = 'project-activities';
export const getProjectActivitiesQueryKey = (projectId) => [
    QUERY_KEY,
    projectId,
];
const fetchProjectActivities = (projectId, landPointId) => () => get(iots.array(IOActivity), `project/${projectId}/activities`, {
    landPointId,
});
const useProjectActivities = (projectId, landPointId) => {
    const { data: projectActivities, isInitialLoading } = useQuery({
        queryKey: getProjectActivitiesQueryKey(projectId),
        queryFn: fetchProjectActivities(projectId, landPointId),
        enabled: !!projectId,
    });
    return {
        projectActivities,
        isInitialLoading,
    };
};
export default useProjectActivities;
