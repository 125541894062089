import * as React from 'react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { IconButton, Link } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_OPEN } from 'analytics/useAnalytics';
import { snakeCase } from 'lodash';
import moment from 'moment';
const URL_NAME = 'https://www.pappers.fr/dirigeant/';
export const ManagerLink = ({ name, firstname, date, analyticOrigin = ANALYTICS_OPEN, analyticDetail, }) => {
    const { trackEvent } = useAnalytics();
    const getLink = () => {
        return (URL_NAME +
            firstname.replace(' ', '-') +
            '_' +
            snakeCase(name) +
            '_' +
            moment(date).format('YYYY-MM'));
    };
    return (<Link href={getLink()} textStyle="small" variant="secondary" isExternal onClick={() => {
            if (analyticOrigin && analyticDetail) {
                trackEvent({
                    category: analyticOrigin,
                    action: analyticDetail,
                });
            }
        }} lineHeight="1em" verticalAlign="middle">
      <IconButton icon={<ExternalLinkIcon />} aria-label="manager" variant="link" paddingStart={0} paddingEnd={0} minWidth="auto"/>
    </Link>);
};
export default ManagerLink;
