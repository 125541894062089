export const formatNumberToLocale = (number, language, round = 2) => {
    const roundedNumber = parseFloat(number.toFixed(round));
    if (number - roundedNumber !== 0) {
        number = roundedNumber;
    }
    if (language === 'fr') {
        return number.toLocaleString('fr-FR');
    }
    if (language === 'en') {
        return number.toLocaleString('en-US');
    }
    return number.toLocaleString();
};
export const formatFloat = (number, round = 2) => {
    return parseFloat(number.toFixed(round));
};
