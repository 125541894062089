import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOPedestrianConnectionsLayerItem = IOLayerItem;
const fetchPedestrianConnections = (townId) => () => get(iots.array(IOPedestrianConnectionsLayerItem), `legacy/${townId}/pedestrian-connections`);
const usePedestrianConnectionsLayer = () => {
    createLayersHook({
        fetch: fetchPedestrianConnections,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.PEDESTRIAN_CONNECTIONS);
};
const PedestrianConnectionsLayer = () => {
    usePedestrianConnectionsLayer();
    return null;
};
export default PedestrianConnectionsLayer;
