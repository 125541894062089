import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IORegionalPlanNewUrbanAreaLayerItem = IOLayerItem;
const fetchRegionalPlanNewUrbanArea = (townId) => () => get(iots.array(IORegionalPlanNewUrbanAreaLayerItem), `legacy/${townId}/regional-plan-new-urban-area`);
const useRegionalPlanNewUrbanAreaLayer = () => {
    createLayersHook({
        fetch: fetchRegionalPlanNewUrbanArea,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.REGIONAL_PLAN_NEW_URBAN_AREA);
};
const RegionalPlanNewUrbanAreaLayer = () => {
    useRegionalPlanNewUrbanAreaLayer();
    return null;
};
export default RegionalPlanNewUrbanAreaLayer;
