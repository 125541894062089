import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { canSendCourier } from 'components/couriers/CourierCredits';
import useCourier from 'hooks/courier/useCourier';
import useCourierSender from 'hooks/courier/useCourierSender';
import { FROM_COURIER } from 'utils/constants';
import CourierFields from './CourierFields';
import { getCourierDefaultValues, getCourierValidationSchema, } from './CourierFormUtils';
import CourierValidation from './CourierValidation';
const CourierForm = ({ courierInfo, onClose, fromPanel = FROM_COURIER, }) => {
    const { t } = useTranslation();
    const defaultValues = getCourierDefaultValues(courierInfo);
    const validationSchema = getCourierValidationSchema(t);
    const { statsCouriersUser } = useCourier({
        enabledList: true,
    });
    const { createCourier, isCreatingCourier, updateCourier, isUpdatingCourier } = useCourier({
        enabledList: false,
        handleSuccess: onClose,
    });
    const methods = useForm({
        defaultValues,
        resolver: yupResolver(validationSchema),
        mode: 'onSubmit',
    });
    const { handleSubmit, setValue, watch, getValues } = methods;
    const confirmMode = watch('confirmMode');
    const senderId = watch('senderId');
    const { courierSenders } = useCourierSender({});
    return (<>
      <FormProvider {...methods}>
        <form style={{ height: '100%' }}>
          <Box height="100%" display="flex" justifyContent="space-between" flexDirection="column">
            {confirmMode ? (<CourierValidation courierInfo={getValues()} handleCancel={() => setValue('confirmMode', false)} handleConfirm={handleSubmit((data) => {
                if (data.id) {
                    updateCourier(data);
                    return;
                }
                data.send = 'now';
                createCourier(data);
            })} sender={senderId &&
                courierSenders?.find((sender) => sender?.id === senderId)} fileName={getValues('file')?.length && getValues('file')[0]?.name} loading={isCreatingCourier || isUpdatingCourier}/>) : (<CourierFields courierInfo={courierInfo} onClose={onClose} canSendCourier={canSendCourier(statsCouriersUser, getValues())} fromPanel={fromPanel}/>)}
          </Box>
        </form>
      </FormProvider>
    </>);
};
export default CourierForm;
