import snakeCase from 'lodash/snakeCase';
import { NEIGHBORHOOD_CATEGORIES } from 'components/tabs/plots/PlotSections/neighborhood/PlotNeighborhoodWrapper';
import { getRentData } from 'components/tabs/plots/PlotSections/neighborhood/RentInfo';
import { getCellValue, getFieldName } from './plotExportUtils';
const categories_fields = ['neighborhood', 'town', 'department'];
export const formatNeighborhoodData = ({ neighborhood, towns, plot, activeSection, t, selectedTown, i18n, }) => {
    if (!neighborhood) {
        return [];
    }
    const sheets = [];
    //Rent Info
    const rentInfo = getRentData({ selectedTown, i18n, t });
    const rentInfoSheet = {
        sheetTitle: t('tabs.plots.section.neighborhood.town_economic_information.rent.title_xls').replace(' :', ''),
        getData: () => {
            const rentData = {};
            rentInfo?.map(({ header, data }) => {
                rentData[header] = data;
            });
            return [rentData];
        },
        formatValue: (cellValue) => cellValue,
        formatHeader: (fieldName) => {
            return fieldName;
        },
        mode: 'horizontal',
    };
    sheets.push(rentInfoSheet);
    // Neighborhood Categories Table
    const neighborhoodSheets = Object.keys(NEIGHBORHOOD_CATEGORIES).map((categoryIndex) => {
        const categoryData = neighborhood.statistics[categoryIndex].map((stat) => {
            const statData = {};
            statData['stat'] = stat.title;
            categories_fields.forEach((field) => {
                if (!neighborhood.displayNeighborhood && field === 'neighborhood') {
                    return;
                }
                statData[`${t(`tabs.plots.section.neighborhood.table.${snakeCase(field)}`)}${field === 'town' ? ' ' + towns[plot.townId]?.name : ''}`] = stat[field];
            });
            return statData;
        });
        return {
            sheetTitle: t(`tabs.plots.section.neighborhood.sections.${NEIGHBORHOOD_CATEGORIES[categoryIndex]}`),
            getData: () => categoryData,
            formatHeader: getFieldName({ activeSection, t }),
            formatValue: getCellValue({ activeSection, t }),
            mode: 'horizontal',
        };
    });
    sheets.push(...neighborhoodSheets);
    return sheets;
};
