import { useQuery } from '@tanstack/react-query';
import { get } from 'api/client';
export const PROJECT_QUERY_KEY = ['user-stats-projects'];
export const PLOT_QUERY_KEY = ['user-stats-plots'];
export const CONTACT_QUERY_KEY = ['user-stats-contacts'];
export const ACTIVITY_QUERY_KEY = ['user-stats-activities'];
export const COURIER_QUERY_KEY = ['user-stats-couriers'];
const fetchStatsProjects = () => () => get(null, 'statistics/projects');
const fetchStatsPlots = () => () => get(null, 'statistics/plots');
const fetchStatsContacts = () => () => get(null, 'statistics/contacts');
const fetchStatsActivities = () => () => get(null, 'statistics/activities');
const fetchStatsCouriers = () => () => get(null, 'statistics/couriers');
const useUserStatisticsCRM = () => {
    const { data: statsProjects, isInitialLoading: isLoadingStatsProjects } = useQuery({
        queryKey: PROJECT_QUERY_KEY,
        queryFn: fetchStatsProjects(),
    });
    const { data: statsPlots, isInitialLoading: isLoadingStatsPlots } = useQuery({
        queryKey: PLOT_QUERY_KEY,
        queryFn: fetchStatsPlots(),
    });
    const { data: statsContacts, isInitialLoading: isLoadingStatsContacts } = useQuery({
        queryKey: CONTACT_QUERY_KEY,
        queryFn: fetchStatsContacts(),
    });
    const { data: statsActivities, isInitialLoading: isLoadingStatsActivities } = useQuery({
        queryKey: ACTIVITY_QUERY_KEY,
        queryFn: fetchStatsActivities(),
    });
    const { data: statsCouriers, isInitialLoading: isLoadingStatsCouriers } = useQuery({
        queryKey: COURIER_QUERY_KEY,
        queryFn: fetchStatsCouriers(),
    });
    return {
        statsProjects,
        isLoadingStatsProjects,
        statsPlots,
        isLoadingStatsPlots,
        statsContacts,
        isLoadingStatsContacts,
        statsActivities,
        isLoadingStatsActivities,
        statsCouriers,
        isLoadingStatsCouriers,
    };
};
export default useUserStatisticsCRM;
