import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import AirportIcon from 'images/airport_icon.png';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOPhotovoltaicProneAreaAirportsLayerItem = IOLayerItem;
const fetchPhotovoltaicProneAreaAirports = (townId) => () => get(iots.array(IOPhotovoltaicProneAreaAirportsLayerItem), `${townId}/photovoltaic-prone-area/aero`);
const usePhotovoltaicProneAreaAirportsLayer = () => {
    createLayersHook({
        fetch: fetchPhotovoltaicProneAreaAirports,
        markerImg: AirportIcon,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.PHOTOVOLTAIC_PRONE_AREA_AIRPORTS);
};
const PhotovoltaicProneAreaAirportsLayer = () => {
    usePhotovoltaicProneAreaAirportsLayer();
    return null;
};
export default PhotovoltaicProneAreaAirportsLayer;
