import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Heading, HStack, Spinner, Text } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import useLandPoint from 'hooks/useLandPoint';
import DropdownIcon from 'images/icons/Dropdown';
import { getActivityStatusLabel } from 'utils/constants';
import { formatDate } from 'utils/formatDate';
const LandPointActivity = ({ landPointId, projectId, }) => {
    const { t } = useTranslation();
    const swiperRef = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [swiperLength, setSwiperLength] = useState(0);
    const { landPointActivities, isLoadingActivities } = useLandPoint({
        landPointId: landPointId,
        toLoad: 'activities',
    });
    const handleNext = () => {
        if (swiperRef.current) {
            swiperRef.current.slideNext();
        }
    };
    const handlePrev = () => {
        if (swiperRef.current) {
            swiperRef.current.slidePrev();
        }
    };
    const handleSwiper = (swiper) => {
        swiperRef.current = swiper;
        swiper.on('slideChange', () => {
            setActiveIndex(swiper.activeIndex);
        });
    };
    useEffect(() => {
        if (swiperRef.current && projectId) {
            swiperRef.current.update(); // Met à jour le swiper après avoir ajouté un élément
            const swiperLength = swiperRef.current.slides?.length ?? 0;
            setSwiperLength(swiperLength);
            // Dernière slide dés le chargement du swiper
            swiperRef.current.slideTo(swiperLength - 1, 0);
            setActiveIndex(swiperRef.current.activeIndex);
        }
    }, [projectId, swiperRef.current]);
    return isLoadingActivities ? (<Box display="flex" height="100%" alignItems="center" justifyContent="center">
      <Spinner />
    </Box>) : (<Box height="100%">
      <HStack justifyContent="space-between" padding={1} backgroundColor="colorMode.background700">
        <Box cursor="pointer" transform={'rotate(90deg)'} onClick={handlePrev} opacity={activeIndex === 0 || swiperLength === 0 ? 0 : 1}>
          <DropdownIcon fill="currentColor"/>
        </Box>
        <Heading as="h4" variant="h4" textAlign="center" lineHeight="20px">
          {t('land_point.activities.title')}
        </Heading>
        <Box cursor="pointer" transform={'rotate(270deg)'} onClick={handleNext} opacity={activeIndex === swiperLength - 1 || swiperLength === 0 ? 0 : 1}>
          <DropdownIcon fill="currentColor"/>
        </Box>
      </HStack>
      {landPointActivities &&
            landPointActivities[projectId] &&
            landPointActivities[projectId].length !== 0 ? (<Box height="calc(100% - 25px)" padding={2}>
          <Swiper ref={swiperRef} onSwiper={handleSwiper} slidesPerView={1} spaceBetween={0} loop={false} pagination={false} centeredSlides={true} autoplay={false} allowTouchMove={false} style={{ height: '100%' }}>
            {landPointActivities[projectId].map((landPointActivity) => {
                return (<SwiperSlide key={landPointActivity.id} className="slide">
                  <Box height="calc(100% - 20px)" overflowY="auto" fontSize="14px" color="colorMode.font800">
                    {landPointActivity.comment}
                  </Box>
                  <Text fontSize="12px" marginTop={1}>
                    <Text as="span">
                      {formatDate(landPointActivity.createdAt, 'short')} -{' '}
                      {t(getActivityStatusLabel(landPointActivity.status))}
                    </Text>
                  </Text>
                </SwiperSlide>);
            })}
          </Swiper>
        </Box>) : (<Box display="flex" height="calc(100% - 28px)" alignItems="center" fontWeight="bold" justifyContent="center">
          <Heading as="h4" variant="h4" textAlign="center">
            {t('land_point.activities.no_report')}
          </Heading>
        </Box>)}
    </Box>);
};
export default LandPointActivity;
