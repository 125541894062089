import { useQuery } from '@tanstack/react-query';
import { get } from 'api/client';
import { IOPLUInfo } from 'types/api';
const QUERY_KEY = 'plu-infos';
const fetchPLUInfos = (townId, coords, signal) => {
    return get(IOPLUInfo, `${townId}/plu-info/${coords.x}/${coords.y}`, {}, false, { signal }, (error) => {
        return error.response.status !== 404;
    });
};
export const getPLUInfosQueryKey = (townshipId, coords) => [
    QUERY_KEY,
    townshipId,
    coords,
];
const usePLUInfo = ({ townId, coords, }) => {
    const { data: PLUInfo, isInitialLoading, error, } = useQuery({
        queryKey: getPLUInfosQueryKey(townId, coords),
        queryFn: ({ signal }) => fetchPLUInfos(townId, coords, signal),
        enabled: !!townId && !!coords,
    });
    return {
        PLUInfo,
        isInitialLoading,
        error,
    };
};
export default usePLUInfo;
