import { useTranslation } from 'react-i18next';
import useCustomToast from './useCustomToast';
const useOnErrorBlob = () => {
    const { t } = useTranslation();
    const { getErrorToast } = useCustomToast();
    return async (error) => {
        const reader = new FileReader();
        // result
        const resultFn = (e) => {
            if (e.target.result !== 'undefined') {
                const result = JSON.parse(e.target.result);
                getErrorToast({
                    title: t('toast.error_title'),
                    description: result.detail || undefined,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    id: error.response?.data.status,
                });
            }
        };
        reader.onload = resultFn;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        await reader.readAsText(error.response?.data);
    };
};
export default useOnErrorBlob;
