import * as React from 'react';
const MountainIcon = (props) => (<svg viewBox="0 0 20 15" {...props}>
    <g>
      <path strokeWidth="0.5" fill="none" stroke="#000000" d="M 0.62781849,14.418898 19.525155,14.460752 12.83203,1.3692641 C 11.043183,4.6467919 10.579385,5.6390657 8.7575836,8.7703686 L 11.469713,14.439825 6.1053279,3.4902142 Z"/>
      <path strokeWidth="0.3" fill="none" stroke="#000000" d="M 3.0598452,9.4601836 4.3981396,11.310453 5.9467585,9.2177243 7.0820635,10.588461"/>
      <path strokeWidth="0.3" fill="none" stroke="#000000" d="m 7.0820635,10.588461 0.8616721,1.039006 1.3650619,-1.364286"/>
      <path strokeWidth="0.3" fill="none" stroke="#000000" d="m 10.019197,6.3257861 1.088217,1.2661005 1.282694,-1.5128935 1.648286,1.9528736 1.362198,-1.8742799"/>
    </g>
  </svg>);
export default MountainIcon;
