import * as React from 'react';
import { ANALYTICS_CRM } from 'analytics/useAnalytics';
import ActivityList from 'components/activities/ActivityList';
import useActivity from 'hooks/useActivity';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
const ActivitiesSection = ({ contactId }) => {
    const { hasDashboardItem } = useSiteConfiguration();
    const { contactActivities, contactActivitiesLoading } = useActivity({
        contactId: contactId,
    });
    return (hasDashboardItem('agenda') && (<ActivityList title="tabs.contact.activities" activities={contactActivities} isLoading={contactActivitiesLoading} activityInfoDefault={{ contactId }} analyticsCategory={ANALYTICS_CRM} analyticsDetail="Ouvrir fiche parcelle mes activités dans contact"/>));
};
export default ActivitiesSection;
