import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOHeartOfTownLayerItem = IOLayerItem;
const fetchHeartOfTown = (townId) => () => get(iots.array(IOHeartOfTownLayerItem), `legacy/${townId}/heart-of-town`);
const useHeartOfTownLayer = () => {
    createLayersHook({
        fetch: fetchHeartOfTown,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.HEART_OF_TOWN);
};
const HeartOfTownLayer = () => {
    useHeartOfTownLayer();
    return null;
};
export default HeartOfTownLayer;
