import seedRandom from 'seedrandom';
export const getRandomHexColor = (seed) => {
    const base = seed ? seedRandom(seed)() : Math.random();
    return ('#' +
        Math.floor(base * 16777215)
            .toString(16)
            .padStart(6, '0'));
};
// Voir import {ColorService} from "react-color-palette";
/*
declare class ColorServiceStatic {
    convert<M extends keyof IColor, C extends IColor[M]>(model: M, color: C): IColor;
    toHex(value: string): IColor["hex"];
    toRgb(value: string): IColor["rgb"];
    toHsv(value: string): IColor["hsv"];
    hex2rgb(hex: IColor["hex"]): IColor["rgb"];
    rgb2hsv({ r, g, b, a }: IColor["rgb"]): IColor["hsv"];
    hsv2rgb({ h, s, v, a }: IColor["hsv"]): IColor["rgb"];
    rgb2hex({ r, g, b, a }: IColor["rgb"]): IColor["hex"];
}
declare const ColorService: ColorServiceStatic;
 */
