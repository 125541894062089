import * as React from 'react';
import { memo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Heading, HStack, Stack, Text, useDisclosure, } from '@chakra-ui/react';
import ContactSelect from 'components/inputs/ContactSelect';
import { CREATE_CONTACT_DEFAULT_VALUES, } from 'components/modals/contactModal/ContactDrawer';
import ContactForm from 'components/modals/contactModal/ContactForm';
import useContact from 'hooks/crm/useContact';
import { ACTION_CREATE } from 'utils/constants';
const DEFAULT_VALUES = {
    contact: null,
    ...CREATE_CONTACT_DEFAULT_VALUES,
};
const validateForm = (values) => {
    const errors = {};
    if (!values.contactId &&
        (!values?.lastname || values?.lastname.trim() === '')) {
        errors.lastname = {
            type: 'required',
            message: 'global.fields.mandatory',
        };
    }
    return errors;
};
const resolver = (values) => {
    return {
        values,
        errors: validateForm(values),
    };
};
const BindContactDrawer = ({ triggerItem, onSubmit, }) => {
    const { t } = useTranslation();
    if (!triggerItem) {
        triggerItem = (onOpen) => (<Button size="sm" variant="primary" onClick={onOpen}>
        {t('contact.create_contact')}
      </Button>);
    }
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedContactId, setSelectedContactId] = useState(false);
    const methods = useForm({
        resolver,
        defaultValues: DEFAULT_VALUES,
    });
    const { handleSubmit, formState: { isDirty, isSubmitting, isSubmitted }, reset, resetField, watch, } = methods;
    const contactId = watch('contactId');
    const resetContactField = () => {
        resetField('contact', { defaultValue: null });
    };
    const resetAndClose = () => {
        reset(DEFAULT_VALUES);
        resetContactField();
        setSelectedContactId(false);
        onClose();
    };
    const { addContact } = useContact();
    const onSubmitForm = (data) => {
        if (data.contactId) {
            onSubmit(data.contactId, resetAndClose);
        }
        else {
            const formData = { ...data };
            delete formData.contact;
            const dataFormated = {
                ...formData,
                type: formData.type
                    .map((option) => {
                    return option.value;
                })
                    .join(','),
            };
            addContact({
                data: dataFormated,
            }, {
                onSuccess: ({ newContactId }) => {
                    onSubmit(newContactId, resetAndClose);
                },
            });
        }
    };
    const onChangeContactId = (value) => {
        setSelectedContactId(!!value);
    };
    return (<>
      {triggerItem(onOpen)}
      <Drawer isOpen={isOpen} onClose={resetAndClose} size="lg">
        <DrawerOverlay />
        <DrawerContent backgroundColor="colorMode.background900" overflowY="auto" data-cy="contact_drawer">
          <DrawerCloseButton />
          <DrawerHeader>
            <Heading as="h4" variant="h4">
              {t('contact.add_contact_title')}
            </Heading>
          </DrawerHeader>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmitForm)}>
              <DrawerBody overflow="none">
                <Stack width="full" spacing={2}>
                  <ContactSelect name="contactId" required onChange={onChangeContactId}/>

                  {!selectedContactId && (<Box>
                      <Box width="full" marginY={2}>
                        <Text textAlign="center">{t('global.or')}</Text>
                      </Box>

                      <Stack width="full" spacing={2} borderColor="#DAD8D4" borderRadius={6}>
                        <Box flex="1" textAlign="left">
                          <Text textStyle="paragraph" fontWeight={500}>
                            {t('contact.create_contact')}
                          </Text>
                        </Box>

                        <ContactForm onChange={resetContactField} action={ACTION_CREATE}/>
                      </Stack>
                    </Box>)}
                </Stack>
              </DrawerBody>
              <DrawerFooter>
                <HStack justifyContent="flex-end">
                  <Button paddingX={10} variant="ghostBorder" onClick={resetAndClose}>
                    {t('global.actions.cancel')}
                  </Button>
                  <Button paddingX={10} variant="primary" type="submit" isLoading={isSubmitting || isSubmitted} loadingText={t('global.status.creating')} isDisabled={(!isDirty && !contactId) || isSubmitted} data-cy="contact_form_submit">
                    {t('global.actions.validate')}
                  </Button>
                </HStack>
              </DrawerFooter>
            </form>
          </FormProvider>
        </DrawerContent>
      </Drawer>
    </>);
};
export default memo(BindContactDrawer);
