import { useMemo } from 'react';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { useLayersTree } from 'hooks/contexts/useLayersTreeContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { getFilterNamesFromPathAndPrefix } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const LAYERS_PATH = 'regulatory.ppr.ppr_ground_movements';
const LAYERS_PREFIX = 'ppr_ground_movements_earthquake';
const IOPPRGroundMovementsEarthquakeLayerItem = IOLayerItem;
const fetchPPRGroundMovementsEarthquake = (townId) => () => get(iots.array(IOPPRGroundMovementsEarthquakeLayerItem), `legacy/${townId}/ppr-ground-movements-earthquake`);
const usePPRGroundMovementsEarthquakeLayer = () => {
    const layersTree = useLayersTree();
    const filterNames = useMemo(() => {
        return getFilterNamesFromPathAndPrefix(layersTree, LAYERS_PATH, LAYERS_PREFIX);
    }, [JSON.stringify(layersTree)]);
    createLayersHook({
        fetch: fetchPPRGroundMovementsEarthquake,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filterNames);
};
const PPRGroundMovementsEarthquakeLayer = () => {
    usePPRGroundMovementsEarthquakeLayer();
    return null;
};
export default PPRGroundMovementsEarthquakeLayer;
