import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Center, Heading, HStack, Spinner, Stack, Text, VStack, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_CRM } from 'analytics/useAnalytics';
import DeleteButton from 'components/buttons/DeleteButton';
import EditButton from 'components/buttons/EditButton';
import AddNoteModal from 'components/modals/AddNoteModal';
import EditNoteModal from 'components/modals/EditNoteModal';
import Counter from 'components/notes/Counter';
import useCustomToast from 'hooks/useCustomToast';
import { formatDate } from 'utils/formatDate';
const NoteSkeleton = () => {
    return (<Center p={4}>
      <Spinner />
    </Center>);
};
const NoteList = ({ title, notes, isLoading = false, withCounter = false, modalId, modalLabel, isUserOwner = true, modalSubmit, onDeleteNote, onUpdateNote, }) => {
    const { getSuccessToast } = useCustomToast();
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    return (<Stack width="full" spacing={3} data-cy="plot_note_list">
      <HStack>
        <Heading as="h4" variant="h4">
          {t(title)}
        </Heading>
        {withCounter && <Counter counter={notes?.length}/>}
        {isUserOwner && (<AddNoteModal id={modalId} triggerItem={(onOpen) => (<Button variant="outline" size="sm" onClick={onOpen} data-cy="add_note">
                {t(modalLabel)}
              </Button>)} onSubmit={(data, onSuccess) => {
                modalSubmit(data, () => {
                    onSuccess();
                    getSuccessToast({
                        title: t('tabs.plots.section.my_crm.note.create_success'),
                        id: 'note_create_success',
                    });
                });
            }}/>)}
      </HStack>
      {notes && notes.length > 0 ? (<VStack width="full" spacing={3}>
          {notes.map((note) => (<Box border="none" backgroundColor="colorMode.base" borderRadius="6px" data-cy="note_accordion" width="full" key={note.id}>
              <HStack>
                <Text flex="1" textAlign="left" p={4} textStyle="paragraph" color="colorMode.font900" data-cy="note_content">
                  {note.note}
                </Text>
                {note.plotNumber && (<Text textStyle="paragraph">{note.plotNumber}</Text>)}
                {isUserOwner && (<Box onClick={(e) => e.stopPropagation()} pr={2}>
                    <HStack>
                      <EditNoteModal id={modalId} note={note.note} triggerItem={(onOpen) => (<EditButton size="sm" mode="icon" handleEdit={onOpen}/>)} handleUpdate={(data, onSuccess) => {
                        onUpdateNote(note.id, data.note, () => {
                            getSuccessToast({
                                title: t('tabs.plots.section.my_crm.note.update_success'),
                                id: 'note_update_success',
                            });
                            onSuccess();
                        });
                        trackEvent({
                            category: ANALYTICS_CRM,
                            action: 'Modifier une note',
                        });
                    }}/>
                      <DeleteButton size="sm" mode="icon" handleDelete={() => {
                        onDeleteNote(note.id, () => {
                            getSuccessToast({
                                title: t('tabs.plots.section.my_crm.note.delete_success'),
                                id: 'note_delete_success',
                            });
                        });
                        trackEvent({
                            category: ANALYTICS_CRM,
                            action: 'Supprimer une note',
                        });
                    }} id="delete_note"/>
                    </HStack>
                  </Box>)}
              </HStack>
              <Text fontSize="xs" pl={4} pb={2}>
                {formatDate(note.updatedAt || note.date)}
              </Text>
            </Box>))}
        </VStack>) : (<Box fontSize="14px">{t('note.none')}</Box>)}
      {isLoading && <NoteSkeleton />}
    </Stack>);
};
export default NoteList;
