export const APECodesTableData = [
    {
        code: '0000Z',
        name: 'Code provisoire',
        category: '00-Autres',
    },
    { code: 'ZZZZZ', name: 'Non défini', category: '00-Autres' },
    {
        code: '01',
        name: 'Culture et production animale, chasse et services annexes',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '011',
        name: 'Cultures non permanentes',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0111',
        name: 'Culture de céréales (sf riz) légumineuses, graines oléagineuses',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0111Z',
        name: 'Culture de céréales (sf riz) légumineuses, graines oléagineuses',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0112',
        name: 'Culture du riz',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0112Z',
        name: 'Culture du riz',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0113',
        name: 'Culture de légumes, de melons, de racines et de tubercules',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0113Z',
        name: 'Culture de légumes, de melons, de racines et de tubercules',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0114',
        name: 'Culture de la canne à sucre',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0114Z',
        name: 'Culture de la canne à sucre',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0115',
        name: 'Culture du tabac',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0115Z',
        name: 'Culture du tabac',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0116',
        name: 'Culture de plantes à fibres',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0116Z',
        name: 'Culture de plantes à fibres',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0119',
        name: 'Autres cultures non permanentes',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0119Z',
        name: 'Autres cultures non permanentes',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '012',
        name: 'Cultures permanentes',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0121',
        name: 'Culture de la vigne',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0121Z',
        name: 'Culture de la vigne',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0122',
        name: 'Culture de fruits tropicaux et subtropicaux',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0122Z',
        name: 'Culture de fruits tropicaux et subtropicaux',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0123',
        name: "Culture d'agrumes",
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0123Z',
        name: "Culture d'agrumes",
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0124',
        name: 'Culture de fruits à pépins et à noyau',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0124Z',
        name: 'Culture de fruits à pépins et à noyau',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0125',
        name: "Culture d'aut. fruits d'arbres ou d'arbustes et de fruits a coque",
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0125Z',
        name: "Culture d'aut. fruits d'arbres ou d'arbustes et de fruits a coque",
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0126',
        name: 'Culture de fruits oléagineux',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0126Z',
        name: 'Culture de fruits oléagineux',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0127',
        name: 'Culture de plantes à boissons',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0127Z',
        name: 'Culture de plantes à boissons',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0128',
        name: 'Culture plantes à épices aromatiques médicinales pharmaceutiques',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0128Z',
        name: 'Culture plantes à épices aromatiques médicinales pharmaceutiques',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0129',
        name: 'Autres cultures permanentes',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0129Z',
        name: 'Autres cultures permanentes',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '013',
        name: 'Reproduction de plantes',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0130',
        name: 'Reproduction de plantes',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0130Z',
        name: 'Reproduction de plantes',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '014',
        name: 'Production animale',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0141',
        name: 'Élevage de vaches laitières',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0141Z',
        name: 'Élevage de vaches laitières',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0142',
        name: "Élevage d'autres bovins et de buffles",
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0142Z',
        name: "Élevage d'autres bovins et de buffles",
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0143',
        name: "Élevage de chevaux et d'autres équidés",
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0143Z',
        name: "Élevage de chevaux et d'autres équidés",
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0145',
        name: "Élevage d'ovins et de caprins",
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0145Z',
        name: "Élevage d'ovins et de caprins",
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0146',
        name: 'Élevage de porcins',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0146Z',
        name: 'Élevage de porcins',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0147',
        name: 'Élevage de volailles',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0147Z',
        name: 'Élevage de volailles',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0149',
        name: "Élevage d'autres animaux",
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0149Z',
        name: "Élevage d'autres animaux",
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '015',
        name: 'Culture et élevage associés',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0150',
        name: 'Culture et élevage associés',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0150Z',
        name: 'Culture et élevage associés',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '016',
        name: "Activ. soutien à l'agriculture & trt primaire des récoltes",
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0161',
        name: 'Activités de soutien aux cultures',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0161Z',
        name: 'Activités de soutien aux cultures',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0162',
        name: 'Activités de soutien à la production animale',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0162Z',
        name: 'Activités de soutien à la production animale',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0163',
        name: 'Traitement primaire des récoltes',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0163Z',
        name: 'Traitement primaire des récoltes',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0164',
        name: 'Traitement des semences',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0164Z',
        name: 'Traitement des semences',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '017',
        name: 'Chasse, piégeage et services annexes',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0170',
        name: 'Chasse, piégeage et services annexes',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '0170Z',
        name: 'Chasse, piégeage et services annexes',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '01-Culture et production animale, chasse et services annexes',
    },
    {
        code: '02',
        name: 'Sylviculture et exploitation forestière',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '02-Sylviculture et exploitation forestière',
    },
    {
        code: '021',
        name: 'Sylviculture et autres activités forestières',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '02-Sylviculture et exploitation forestière',
    },
    {
        code: '0210',
        name: 'Sylviculture et autres activités forestières',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '02-Sylviculture et exploitation forestière',
    },
    {
        code: '0210Z',
        name: 'Sylviculture et autres activités forestières',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '02-Sylviculture et exploitation forestière',
    },
    {
        code: '022',
        name: 'Exploitation forestière',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '02-Sylviculture et exploitation forestière',
    },
    {
        code: '0220',
        name: 'Exploitation forestière',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '02-Sylviculture et exploitation forestière',
    },
    {
        code: '0220Z',
        name: 'Exploitation forestière',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '02-Sylviculture et exploitation forestière',
    },
    {
        code: '023',
        name: "Récolte produits forestiers non ligneux poussant à l'état sauvage",
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '02-Sylviculture et exploitation forestière',
    },
    {
        code: '0230',
        name: "Récolte prod. forestiers non ligneux poussant à l'état sauvage",
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '02-Sylviculture et exploitation forestière',
    },
    {
        code: '0230Z',
        name: "Récolte prod. forestiers non ligneux poussant à l'état sauvage",
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '02-Sylviculture et exploitation forestière',
    },
    {
        code: '024',
        name: "Services de soutien à l'exploitation forestière",
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '02-Sylviculture et exploitation forestière',
    },
    {
        code: '0240',
        name: "Services de soutien à l'exploitation forestière",
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '02-Sylviculture et exploitation forestière',
    },
    {
        code: '0240Z',
        name: "Services de soutien à l'exploitation forestière",
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '02-Sylviculture et exploitation forestière',
    },
    {
        code: '03',
        name: 'Pêche et aquaculture',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '03-Pêche et aquaculture',
    },
    {
        code: '031',
        name: 'Pêche',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '03-Pêche et aquaculture',
    },
    {
        code: '0311',
        name: 'Pêche en mer',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '03-Pêche et aquaculture',
    },
    {
        code: '0311Z',
        name: 'Pêche en mer',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '03-Pêche et aquaculture',
    },
    {
        code: '0312',
        name: 'Pêche en eau douce',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '03-Pêche et aquaculture',
    },
    {
        code: '0312Z',
        name: 'Pêche en eau douce',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '03-Pêche et aquaculture',
    },
    {
        code: '032',
        name: 'Aquaculture',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '03-Pêche et aquaculture',
    },
    {
        code: '0321',
        name: 'Aquaculture en mer',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '03-Pêche et aquaculture',
    },
    {
        code: '0321Z',
        name: 'Aquaculture en mer',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '03-Pêche et aquaculture',
    },
    {
        code: '0322',
        name: 'Aquaculture en eau douce',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '03-Pêche et aquaculture',
    },
    {
        code: '0322Z',
        name: 'Aquaculture en eau douce',
        section: 'Section A - Agriculture, Sylviculture et Pêche',
        category: '03-Pêche et aquaculture',
    },
    {
        code: '05',
        name: 'Extraction de houille et de lignite',
        section: 'Section B - Industries Extractives',
        category: '05-Extraction de houille et de lignite',
    },
    {
        code: '051',
        name: 'Extraction de houille',
        section: 'Section B - Industries Extractives',
        category: '05-Extraction de houille et de lignite',
    },
    {
        code: '0510',
        name: 'Extraction de houille',
        section: 'Section B - Industries Extractives',
        category: '05-Extraction de houille et de lignite',
    },
    {
        code: '0510Z',
        name: 'Extraction de houille',
        section: 'Section B - Industries Extractives',
        category: '05-Extraction de houille et de lignite',
    },
    {
        code: '06',
        name: "Extraction d'hydrocarbures",
        section: 'Section B - Industries Extractives',
        category: "06-Extraction d'hydrocarbures",
    },
    {
        code: '061',
        name: 'Extraction de pétrole brut',
        section: 'Section B - Industries Extractives',
        category: "06-Extraction d'hydrocarbures",
    },
    {
        code: '0610',
        name: 'Extraction de pétrole brut',
        section: 'Section B - Industries Extractives',
        category: "06-Extraction d'hydrocarbures",
    },
    {
        code: '0610Z',
        name: 'Extraction de pétrole brut',
        section: 'Section B - Industries Extractives',
        category: "06-Extraction d'hydrocarbures",
    },
    {
        code: '07',
        name: 'Extraction de minerais métalliques',
        section: 'Section B - Industries Extractives',
        category: '07-Extraction de minerais métalliques',
    },
    {
        code: '071',
        name: 'Extraction de minerais de fer',
        section: 'Section B - Industries Extractives',
        category: '07-Extraction de minerais métalliques',
    },
    {
        code: '0710',
        name: 'Extraction de minerais de fer',
        section: 'Section B - Industries Extractives',
        category: '07-Extraction de minerais métalliques',
    },
    {
        code: '0710Z',
        name: 'Extraction de minerais de fer',
        section: 'Section B - Industries Extractives',
        category: '07-Extraction de minerais métalliques',
    },
    {
        code: '072',
        name: 'Extraction de minerais de métaux non ferreux',
        section: 'Section B - Industries Extractives',
        category: '07-Extraction de minerais métalliques',
    },
    {
        code: '0721',
        name: "Extraction de minerais d'uranium et de thorium",
        section: 'Section B - Industries Extractives',
        category: '07-Extraction de minerais métalliques',
    },
    {
        code: '0721Z',
        name: "Extraction de minerais d'uranium et de thorium",
        section: 'Section B - Industries Extractives',
        category: '07-Extraction de minerais métalliques',
    },
    {
        code: '0729',
        name: "Extraction d'autres minerais de métaux non ferreux",
        section: 'Section B - Industries Extractives',
        category: '07-Extraction de minerais métalliques',
    },
    {
        code: '0729Z',
        name: "Extraction d'autres minerais de métaux non ferreux",
        section: 'Section B - Industries Extractives',
        category: '07-Extraction de minerais métalliques',
    },
    {
        code: '08',
        name: 'Autres industries extractives',
        section: 'Section B - Industries Extractives',
        category: '08-Autres industries extractives',
    },
    {
        code: '081',
        name: "Extraction de pierres, de sables et d'argiles",
        section: 'Section B - Industries Extractives',
        category: '08-Autres industries extractives',
    },
    {
        code: '0811',
        name: 'Extraction pierres ornement. construc. calcaire industriel, gypse',
        section: 'Section B - Industries Extractives',
        category: '08-Autres industries extractives',
    },
    {
        code: '0811Z',
        name: 'Extraction pierres ornement. construc. calcaire industriel, gypse',
        section: 'Section B - Industries Extractives',
        category: '08-Autres industries extractives',
    },
    {
        code: '0812',
        name: 'Exploit gravieres & sablieres, extraction argiles & kaolin',
        section: 'Section B - Industries Extractives',
        category: '08-Autres industries extractives',
    },
    {
        code: '0812Z',
        name: 'Exploit gravieres & sablieres, extraction argiles & kaolin',
        section: 'Section B - Industries Extractives',
        category: '08-Autres industries extractives',
    },
    {
        code: '089',
        name: 'Activités extractives n.c.a.',
        section: 'Section B - Industries Extractives',
        category: '08-Autres industries extractives',
    },
    {
        code: '0891',
        name: "Extraction des minéraux chimiques et d'engrais minéraux",
        section: 'Section B - Industries Extractives',
        category: '08-Autres industries extractives',
    },
    {
        code: '0891Z',
        name: "Extraction des minéraux chimiques et d'engrais minéraux",
        section: 'Section B - Industries Extractives',
        category: '08-Autres industries extractives',
    },
    {
        code: '0892',
        name: 'Extraction de tourbe',
        section: 'Section B - Industries Extractives',
        category: '08-Autres industries extractives',
    },
    {
        code: '0892Z',
        name: 'Extraction de tourbe',
        section: 'Section B - Industries Extractives',
        category: '08-Autres industries extractives',
    },
    {
        code: '0893',
        name: 'Production de sel',
        section: 'Section B - Industries Extractives',
        category: '08-Autres industries extractives',
    },
    {
        code: '0893Z',
        name: 'Production de sel',
        section: 'Section B - Industries Extractives',
        category: '08-Autres industries extractives',
    },
    {
        code: '0899',
        name: 'Autres activités extractives n.c.a.',
        section: 'Section B - Industries Extractives',
        category: '08-Autres industries extractives',
    },
    {
        code: '0899Z',
        name: 'Autres activités extractives n.c.a.',
        section: 'Section B - Industries Extractives',
        category: '08-Autres industries extractives',
    },
    {
        code: '09',
        name: 'Services de soutien aux industries extractives',
        section: 'Section B - Industries Extractives',
        category: '09-Services de soutien aux industries extractives',
    },
    {
        code: '091',
        name: "Activités de soutien à l'extraction d'hydrocarbures",
        section: 'Section B - Industries Extractives',
        category: '09-Services de soutien aux industries extractives',
    },
    {
        code: '0910',
        name: "Activités de soutien à l'extraction d'hydrocarbures",
        section: 'Section B - Industries Extractives',
        category: '09-Services de soutien aux industries extractives',
    },
    {
        code: '0910Z',
        name: "Activités de soutien à l'extraction d'hydrocarbures",
        section: 'Section B - Industries Extractives',
        category: '09-Services de soutien aux industries extractives',
    },
    {
        code: '099',
        name: 'Activités de soutien aux autres industries extractives',
        section: 'Section B - Industries Extractives',
        category: '09-Services de soutien aux industries extractives',
    },
    {
        code: '0990',
        name: 'Activités de soutien aux autres industries extractives',
        section: 'Section B - Industries Extractives',
        category: '09-Services de soutien aux industries extractives',
    },
    {
        code: '0990Z',
        name: 'Activités de soutien aux autres industries extractives',
        section: 'Section B - Industries Extractives',
        category: '09-Services de soutien aux industries extractives',
    },
    {
        code: '10',
        name: 'Industries alimentaires',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '101',
        name: 'Transform. & conserv. viande & préparation prod. à base de viande',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1011',
        name: 'Transformation et conservation de la viande de boucherie',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1011Z',
        name: 'Transformation et conservation de la viande de boucherie',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1012',
        name: 'Transformation et conservation de la viande de volaille',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1012Z',
        name: 'Transformation et conservation de la viande de volaille',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1013',
        name: 'Préparation de produits à base de viande',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1013A',
        name: 'Préparation industrielle de produits à base de viande',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1013B',
        name: 'Charcuterie',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '102',
        name: 'Transform. & conserv. poisson, crustacés & mollusques',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1020',
        name: 'Transform. & conserv. poisson, crustacés & mollusques',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1020Z',
        name: 'Transform. & conserv. poisson, crustacés & mollusques',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '103',
        name: 'Transformation et conservation de fruits et légumes',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1031',
        name: 'Transformation et conservation de pommes de terre',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1031Z',
        name: 'Transformation et conservation de pommes de terre',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1032',
        name: 'Préparation de jus de fruits et légumes',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1032Z',
        name: 'Préparation de jus de fruits et légumes',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1039',
        name: 'Autre transformation et conservation de fruits et légumes',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1039A',
        name: 'Autre transformation et conservation de légumes',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1039B',
        name: 'Transformation et conservation de fruits',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '104',
        name: 'Fabrication d’huiles et graisses végétales et animales',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1041',
        name: "Fabrication d'huiles et graisses",
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1041A',
        name: "Fabrication d'huiles et graisses brutes",
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1041B',
        name: "Fabrication d'huiles et graisses raffinées",
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1042',
        name: 'Fabrication de margarine et graisses comestibles similaires',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1042Z',
        name: 'Fabrication de margarine et graisses comestibles similaires',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '105',
        name: 'Fabrication de produits laitiers',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1051',
        name: 'Exploitation de laiteries et fabrication de fromage',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1051A',
        name: 'Fabrication de lait liquide et de produits frais',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1051B',
        name: 'Fabrication de beurre',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1051C',
        name: 'Fabrication de fromage',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1051D',
        name: "Fabrication d'autres produits laitiers",
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1052',
        name: 'Fabrication de glaces et sorbets',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1052Z',
        name: 'Fabrication de glaces et sorbets',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '106',
        name: 'Travail des grains ; fabrication de produits amylacés',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1061',
        name: 'Travail des grains',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1061A',
        name: 'Meunerie',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1061B',
        name: 'Autres activités du travail des grains',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1062',
        name: 'Fabrication de produits amylacés',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1062Z',
        name: 'Fabrication de produits amylacés',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '107',
        name: 'Fabrication produits boulangerie-pâtisserie & pâtes alimentaires',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1071',
        name: 'Fabrication de pain et de pâtisserie fraîche',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1071A',
        name: 'Fabrication industrielle de pain et de pâtisserie fraîche',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1071B',
        name: 'Cuisson de produits de boulangerie',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1071C',
        name: 'Boulangerie et boulangerie-pâtisserie',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1071D',
        name: 'Pâtisserie',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1072',
        name: 'Fabrication de biscuits, biscottes et pâtisseries de conservation',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1072Z',
        name: 'Fabrication de biscuits, biscottes et pâtisseries de conservation',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1073',
        name: 'Fabrication de pâtes alimentaires',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1073Z',
        name: 'Fabrication de pâtes alimentaires',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '108',
        name: "Fabrication d'autres produits alimentaires",
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1081',
        name: 'Fabrication de sucre',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1081Z',
        name: 'Fabrication de sucre',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1082',
        name: 'Fabrication de cacao, chocolat et de produits de confiserie',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1082Z',
        name: 'Fabrication de cacao, chocolat et de produits de confiserie',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1083',
        name: 'Transformation du thé et du café',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1083Z',
        name: 'Transformation du thé et du café',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1084',
        name: 'Fabrication de condiments et assaisonnements',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1084Z',
        name: 'Fabrication de condiments et assaisonnements',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1085',
        name: 'Fabrication de plats préparés',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1085Z',
        name: 'Fabrication de plats préparés',
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1086',
        name: "Fabrication d'aliments homogénéisés et diététiques",
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1086Z',
        name: "Fabrication d'aliments homogénéisés et diététiques",
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1089',
        name: "Fabrication d'autres produits alimentaires n.c.a.",
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1089Z',
        name: "Fabrication d'autres produits alimentaires n.c.a.",
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '109',
        name: "Fabrication d'aliments pour animaux",
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1091',
        name: "Fabrication d'aliments pour animaux de ferme",
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1091Z',
        name: "Fabrication d'aliments pour animaux de ferme",
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1092',
        name: "Fabrication d'aliments pour animaux de compagnie",
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '1092Z',
        name: "Fabrication d'aliments pour animaux de compagnie",
        section: 'Section C - Industrie Manufacturière',
        category: '10-Industries alimentaires',
    },
    {
        code: '11',
        name: 'Fabrication de boissons',
        section: 'Section C - Industrie Manufacturière',
        category: '11-Fabrication de boissons',
    },
    {
        code: '110',
        name: 'Fabrication de boissons',
        section: 'Section C - Industrie Manufacturière',
        category: '11-Fabrication de boissons',
    },
    {
        code: '1101',
        name: 'Production de boissons alcooliques distillées',
        section: 'Section C - Industrie Manufacturière',
        category: '11-Fabrication de boissons',
    },
    {
        code: '1101Z',
        name: 'Production de boissons alcooliques distillées',
        section: 'Section C - Industrie Manufacturière',
        category: '11-Fabrication de boissons',
    },
    {
        code: '1102',
        name: 'Production de vin (de raisin)',
        section: 'Section C - Industrie Manufacturière',
        category: '11-Fabrication de boissons',
    },
    {
        code: '1102A',
        name: 'Fabrication de vins effervescents',
        section: 'Section C - Industrie Manufacturière',
        category: '11-Fabrication de boissons',
    },
    {
        code: '1102B',
        name: 'Vinification',
        section: 'Section C - Industrie Manufacturière',
        category: '11-Fabrication de boissons',
    },
    {
        code: '1103',
        name: 'Fabrication de cidre et de vins de fruits',
        section: 'Section C - Industrie Manufacturière',
        category: '11-Fabrication de boissons',
    },
    {
        code: '1103Z',
        name: 'Fabrication de cidre et de vins de fruits',
        section: 'Section C - Industrie Manufacturière',
        category: '11-Fabrication de boissons',
    },
    {
        code: '1104',
        name: "Production d'autres boissons fermentées non distillées",
        section: 'Section C - Industrie Manufacturière',
        category: '11-Fabrication de boissons',
    },
    {
        code: '1104Z',
        name: "Production d'autres boissons fermentées non distillées",
        section: 'Section C - Industrie Manufacturière',
        category: '11-Fabrication de boissons',
    },
    {
        code: '1105',
        name: 'Fabrication de bière',
        section: 'Section C - Industrie Manufacturière',
        category: '11-Fabrication de boissons',
    },
    {
        code: '1105Z',
        name: 'Fabrication de bière',
        section: 'Section C - Industrie Manufacturière',
        category: '11-Fabrication de boissons',
    },
    {
        code: '1106',
        name: 'Fabrication de malt',
        section: 'Section C - Industrie Manufacturière',
        category: '11-Fabrication de boissons',
    },
    {
        code: '1106Z',
        name: 'Fabrication de malt',
        section: 'Section C - Industrie Manufacturière',
        category: '11-Fabrication de boissons',
    },
    {
        code: '1107',
        name: 'Industrie des eaux embouteillées et des boissons rafraîchissantes',
        section: 'Section C - Industrie Manufacturière',
        category: '11-Fabrication de boissons',
    },
    {
        code: '1107A',
        name: 'Industrie des eaux de table',
        section: 'Section C - Industrie Manufacturière',
        category: '11-Fabrication de boissons',
    },
    {
        code: '1107B',
        name: 'Production de boissons rafraîchissantes',
        section: 'Section C - Industrie Manufacturière',
        category: '11-Fabrication de boissons',
    },
    {
        code: '12',
        name: 'Fabrication de produits à base de tabac',
        section: 'Section C - Industrie Manufacturière',
        category: '12-Fabrication de produits à base de tabac',
    },
    {
        code: '120',
        name: 'Fabrication de produits à base de tabac',
        section: 'Section C - Industrie Manufacturière',
        category: '12-Fabrication de produits à base de tabac',
    },
    {
        code: '1200',
        name: 'Fabrication de produits à base de tabac',
        section: 'Section C - Industrie Manufacturière',
        category: '12-Fabrication de produits à base de tabac',
    },
    {
        code: '1200Z',
        name: 'Fabrication de produits à base de tabac',
        section: 'Section C - Industrie Manufacturière',
        category: '12-Fabrication de produits à base de tabac',
    },
    {
        code: '13',
        name: 'Fabrication de textiles',
        section: 'Section C - Industrie Manufacturière',
        category: '13-Fabrication de textiles',
    },
    {
        code: '131',
        name: 'Préparation de fibres textiles et filature',
        section: 'Section C - Industrie Manufacturière',
        category: '13-Fabrication de textiles',
    },
    {
        code: '1310',
        name: 'Préparation de fibres textiles et filature',
        section: 'Section C - Industrie Manufacturière',
        category: '13-Fabrication de textiles',
    },
    {
        code: '1310Z',
        name: 'Préparation de fibres textiles et filature',
        section: 'Section C - Industrie Manufacturière',
        category: '13-Fabrication de textiles',
    },
    {
        code: '132',
        name: 'Tissage',
        section: 'Section C - Industrie Manufacturière',
        category: '13-Fabrication de textiles',
    },
    {
        code: '1320',
        name: 'Tissage',
        section: 'Section C - Industrie Manufacturière',
        category: '13-Fabrication de textiles',
    },
    {
        code: '1320Z',
        name: 'Tissage',
        section: 'Section C - Industrie Manufacturière',
        category: '13-Fabrication de textiles',
    },
    {
        code: '133',
        name: 'Ennoblissement textile',
        section: 'Section C - Industrie Manufacturière',
        category: '13-Fabrication de textiles',
    },
    {
        code: '1330',
        name: 'Ennoblissement textile',
        section: 'Section C - Industrie Manufacturière',
        category: '13-Fabrication de textiles',
    },
    {
        code: '1330Z',
        name: 'Ennoblissement textile',
        section: 'Section C - Industrie Manufacturière',
        category: '13-Fabrication de textiles',
    },
    {
        code: '139',
        name: "Fabrication d'autres textiles",
        section: 'Section C - Industrie Manufacturière',
        category: '13-Fabrication de textiles',
    },
    {
        code: '1391',
        name: "Fabrication d'étoffes à mailles",
        section: 'Section C - Industrie Manufacturière',
        category: '13-Fabrication de textiles',
    },
    {
        code: '1391Z',
        name: "Fabrication d'étoffes à mailles",
        section: 'Section C - Industrie Manufacturière',
        category: '13-Fabrication de textiles',
    },
    {
        code: '1392',
        name: "Fabrication d'articles textiles, sauf habillement",
        section: 'Section C - Industrie Manufacturière',
        category: '13-Fabrication de textiles',
    },
    {
        code: '1392Z',
        name: "Fabrication d'articles textiles, sauf habillement",
        section: 'Section C - Industrie Manufacturière',
        category: '13-Fabrication de textiles',
    },
    {
        code: '1393',
        name: 'Fabrication de tapis et moquettes',
        section: 'Section C - Industrie Manufacturière',
        category: '13-Fabrication de textiles',
    },
    {
        code: '1393Z',
        name: 'Fabrication de tapis et moquettes',
        section: 'Section C - Industrie Manufacturière',
        category: '13-Fabrication de textiles',
    },
    {
        code: '1394',
        name: 'Fabrication de ficelles, cordes et filets',
        section: 'Section C - Industrie Manufacturière',
        category: '13-Fabrication de textiles',
    },
    {
        code: '1394Z',
        name: 'Fabrication de ficelles, cordes et filets',
        section: 'Section C - Industrie Manufacturière',
        category: '13-Fabrication de textiles',
    },
    {
        code: '1395',
        name: 'Fabrication de non-tissés, sauf habillement',
        section: 'Section C - Industrie Manufacturière',
        category: '13-Fabrication de textiles',
    },
    {
        code: '1395Z',
        name: 'Fabrication de non-tissés, sauf habillement',
        section: 'Section C - Industrie Manufacturière',
        category: '13-Fabrication de textiles',
    },
    {
        code: '1396',
        name: "Fabrication d'autres textiles techniques et industriels",
        section: 'Section C - Industrie Manufacturière',
        category: '13-Fabrication de textiles',
    },
    {
        code: '1396Z',
        name: "Fabrication d'autres textiles techniques et industriels",
        section: 'Section C - Industrie Manufacturière',
        category: '13-Fabrication de textiles',
    },
    {
        code: '1399',
        name: "Fabrication d'autres textiles n.c.a.",
        section: 'Section C - Industrie Manufacturière',
        category: '13-Fabrication de textiles',
    },
    {
        code: '1399Z',
        name: "Fabrication d'autres textiles n.c.a.",
        section: 'Section C - Industrie Manufacturière',
        category: '13-Fabrication de textiles',
    },
    {
        code: '14',
        name: "Industrie de l'habillement",
        section: 'Section C - Industrie Manufacturière',
        category: "14-Industrie de l'habillement",
    },
    {
        code: '141',
        name: "Fabrication de vêtements, autres qu'en fourrure",
        section: 'Section C - Industrie Manufacturière',
        category: "14-Industrie de l'habillement",
    },
    {
        code: '1411',
        name: 'Fabrication de vêtements en cuir',
        section: 'Section C - Industrie Manufacturière',
        category: "14-Industrie de l'habillement",
    },
    {
        code: '1411Z',
        name: 'Fabrication de vêtements en cuir',
        section: 'Section C - Industrie Manufacturière',
        category: "14-Industrie de l'habillement",
    },
    {
        code: '1412',
        name: 'Fabrication de vêtements de travail',
        section: 'Section C - Industrie Manufacturière',
        category: "14-Industrie de l'habillement",
    },
    {
        code: '1412Z',
        name: 'Fabrication de vêtements de travail',
        section: 'Section C - Industrie Manufacturière',
        category: "14-Industrie de l'habillement",
    },
    {
        code: '1413',
        name: 'Fabrication de vêtements de dessus',
        section: 'Section C - Industrie Manufacturière',
        category: "14-Industrie de l'habillement",
    },
    {
        code: '1413Z',
        name: 'Fabrication de vêtements de dessus',
        section: 'Section C - Industrie Manufacturière',
        category: "14-Industrie de l'habillement",
    },
    {
        code: '1414',
        name: 'Fabrication de vêtements de dessous',
        section: 'Section C - Industrie Manufacturière',
        category: "14-Industrie de l'habillement",
    },
    {
        code: '1414Z',
        name: 'Fabrication de vêtements de dessous',
        section: 'Section C - Industrie Manufacturière',
        category: "14-Industrie de l'habillement",
    },
    {
        code: '1419',
        name: "Fabrication d'autres vêtements et accessoires",
        section: 'Section C - Industrie Manufacturière',
        category: "14-Industrie de l'habillement",
    },
    {
        code: '1419Z',
        name: "Fabrication d'autres vêtements et accessoires",
        section: 'Section C - Industrie Manufacturière',
        category: "14-Industrie de l'habillement",
    },
    {
        code: '142',
        name: "Fabrication d'articles en fourrure",
        section: 'Section C - Industrie Manufacturière',
        category: "14-Industrie de l'habillement",
    },
    {
        code: '1420',
        name: "Fabrication d'articles en fourrure",
        section: 'Section C - Industrie Manufacturière',
        category: "14-Industrie de l'habillement",
    },
    {
        code: '1420Z',
        name: "Fabrication d'articles en fourrure",
        section: 'Section C - Industrie Manufacturière',
        category: "14-Industrie de l'habillement",
    },
    {
        code: '143',
        name: "Fabrication d'articles à mailles",
        section: 'Section C - Industrie Manufacturière',
        category: "14-Industrie de l'habillement",
    },
    {
        code: '1431',
        name: "Fabrication d'articles chaussants à mailles",
        section: 'Section C - Industrie Manufacturière',
        category: "14-Industrie de l'habillement",
    },
    {
        code: '1431Z',
        name: "Fabrication d'articles chaussants à mailles",
        section: 'Section C - Industrie Manufacturière',
        category: "14-Industrie de l'habillement",
    },
    {
        code: '1439',
        name: "Fabrication d'autres articles à mailles",
        section: 'Section C - Industrie Manufacturière',
        category: "14-Industrie de l'habillement",
    },
    {
        code: '1439Z',
        name: "Fabrication d'autres articles à mailles",
        section: 'Section C - Industrie Manufacturière',
        category: "14-Industrie de l'habillement",
    },
    {
        code: '15',
        name: 'Industrie du cuir et de la chaussure',
        section: 'Section C - Industrie Manufacturière',
        category: '15-Industrie du cuir et de la chaussure',
    },
    {
        code: '151',
        name: 'Prépa. des cuir & fourrure; fabric. art. voyage maroq. & sellerie',
        section: 'Section C - Industrie Manufacturière',
        category: '15-Industrie du cuir et de la chaussure',
    },
    {
        code: '1511',
        name: 'Apprêt, tannage des cuirs; préparation et teinture des fourrures',
        section: 'Section C - Industrie Manufacturière',
        category: '15-Industrie du cuir et de la chaussure',
    },
    {
        code: '1511Z',
        name: 'Apprêt, tannage des cuirs; préparation et teinture des fourrures',
        section: 'Section C - Industrie Manufacturière',
        category: '15-Industrie du cuir et de la chaussure',
    },
    {
        code: '1512',
        name: "Fabrication d'articles de voyage, de maroquinerie et de sellerie",
        section: 'Section C - Industrie Manufacturière',
        category: '15-Industrie du cuir et de la chaussure',
    },
    {
        code: '1512Z',
        name: "Fabrication d'articles de voyage, de maroquinerie et de sellerie",
        section: 'Section C - Industrie Manufacturière',
        category: '15-Industrie du cuir et de la chaussure',
    },
    {
        code: '152',
        name: 'Fabrication de chaussures',
        section: 'Section C - Industrie Manufacturière',
        category: '15-Industrie du cuir et de la chaussure',
    },
    {
        code: '1520',
        name: 'Fabrication de chaussures',
        section: 'Section C - Industrie Manufacturière',
        category: '15-Industrie du cuir et de la chaussure',
    },
    {
        code: '1520Z',
        name: 'Fabrication de chaussures',
        section: 'Section C - Industrie Manufacturière',
        category: '15-Industrie du cuir et de la chaussure',
    },
    {
        code: '16',
        name: 'Trav. bois; fab. art. bois liège (sf mbles), vannerie & sparterie',
        section: 'Section C - Industrie Manufacturière',
        category: '16-Trav. bois; fab. art. bois liège (sf mbles), vannerie & sparterie',
    },
    {
        code: '161',
        name: 'Sciage et rabotage du bois',
        section: 'Section C - Industrie Manufacturière',
        category: '16-Trav. bois; fab. art. bois liège (sf mbles), vannerie & sparterie',
    },
    {
        code: '1610',
        name: 'Sciage et rabotage du bois',
        section: 'Section C - Industrie Manufacturière',
        category: '16-Trav. bois; fab. art. bois liège (sf mbles), vannerie & sparterie',
    },
    {
        code: '1610A',
        name: 'Sciage et rabotage du bois, hors imprégnation',
        section: 'Section C - Industrie Manufacturière',
        category: '16-Trav. bois; fab. art. bois liège (sf mbles), vannerie & sparterie',
    },
    {
        code: '1610B',
        name: 'Imprégnation du bois',
        section: 'Section C - Industrie Manufacturière',
        category: '16-Trav. bois; fab. art. bois liège (sf mbles), vannerie & sparterie',
    },
    {
        code: '162',
        name: "Fabrication d'articles en bois, liège, vannerie et sparterie",
        section: 'Section C - Industrie Manufacturière',
        category: '16-Trav. bois; fab. art. bois liège (sf mbles), vannerie & sparterie',
    },
    {
        code: '1621',
        name: 'Fabrication de placage et de panneaux de bois',
        section: 'Section C - Industrie Manufacturière',
        category: '16-Trav. bois; fab. art. bois liège (sf mbles), vannerie & sparterie',
    },
    {
        code: '1621Z',
        name: 'Fabrication de placage et de panneaux de bois',
        section: 'Section C - Industrie Manufacturière',
        category: '16-Trav. bois; fab. art. bois liège (sf mbles), vannerie & sparterie',
    },
    {
        code: '1622',
        name: 'Fabrication de parquets assemblés',
        section: 'Section C - Industrie Manufacturière',
        category: '16-Trav. bois; fab. art. bois liège (sf mbles), vannerie & sparterie',
    },
    {
        code: '1622Z',
        name: 'Fabrication de parquets assemblés',
        section: 'Section C - Industrie Manufacturière',
        category: '16-Trav. bois; fab. art. bois liège (sf mbles), vannerie & sparterie',
    },
    {
        code: '1623',
        name: "Fabrication de charpentes et d'autres menuiseries",
        section: 'Section C - Industrie Manufacturière',
        category: '16-Trav. bois; fab. art. bois liège (sf mbles), vannerie & sparterie',
    },
    {
        code: '1623Z',
        name: "Fabrication de charpentes et d'autres menuiseries",
        section: 'Section C - Industrie Manufacturière',
        category: '16-Trav. bois; fab. art. bois liège (sf mbles), vannerie & sparterie',
    },
    {
        code: '1624',
        name: "Fabrication d'emballages en bois",
        section: 'Section C - Industrie Manufacturière',
        category: '16-Trav. bois; fab. art. bois liège (sf mbles), vannerie & sparterie',
    },
    {
        code: '1624Z',
        name: "Fabrication d'emballages en bois",
        section: 'Section C - Industrie Manufacturière',
        category: '16-Trav. bois; fab. art. bois liège (sf mbles), vannerie & sparterie',
    },
    {
        code: '1629',
        name: 'Fabrication objets divers en bois, liège, vannerie et sparterie',
        section: 'Section C - Industrie Manufacturière',
        category: '16-Trav. bois; fab. art. bois liège (sf mbles), vannerie & sparterie',
    },
    {
        code: '1629Z',
        name: 'Fabrication objets divers en bois, liège, vannerie et sparterie',
        section: 'Section C - Industrie Manufacturière',
        category: '16-Trav. bois; fab. art. bois liège (sf mbles), vannerie & sparterie',
    },
    {
        code: '17',
        name: 'Industrie du papier et du carton',
        section: 'Section C - Industrie Manufacturière',
        category: '17-Industrie du papier et du carton',
    },
    {
        code: '171',
        name: 'Fabrication de pâte à papier, de papier et de carton',
        section: 'Section C - Industrie Manufacturière',
        category: '17-Industrie du papier et du carton',
    },
    {
        code: '1711',
        name: 'Fabrication de pâte à papier',
        section: 'Section C - Industrie Manufacturière',
        category: '17-Industrie du papier et du carton',
    },
    {
        code: '1711Z',
        name: 'Fabrication de pâte à papier',
        section: 'Section C - Industrie Manufacturière',
        category: '17-Industrie du papier et du carton',
    },
    {
        code: '1712',
        name: 'Fabrication de papier et de carton',
        section: 'Section C - Industrie Manufacturière',
        category: '17-Industrie du papier et du carton',
    },
    {
        code: '1712Z',
        name: 'Fabrication de papier et de carton',
        section: 'Section C - Industrie Manufacturière',
        category: '17-Industrie du papier et du carton',
    },
    {
        code: '172',
        name: "Fabrication d'articles en papier ou en carton",
        section: 'Section C - Industrie Manufacturière',
        category: '17-Industrie du papier et du carton',
    },
    {
        code: '1721',
        name: 'Fabrication papier & carton ondulés & emballages papier ou carton',
        section: 'Section C - Industrie Manufacturière',
        category: '17-Industrie du papier et du carton',
    },
    {
        code: '1721A',
        name: 'Fabrication de carton ondulé',
        section: 'Section C - Industrie Manufacturière',
        category: '17-Industrie du papier et du carton',
    },
    {
        code: '1721B',
        name: 'Fabrication de cartonnages',
        section: 'Section C - Industrie Manufacturière',
        category: '17-Industrie du papier et du carton',
    },
    {
        code: '1721C',
        name: "Fabrication d'emballages en papier",
        section: 'Section C - Industrie Manufacturière',
        category: '17-Industrie du papier et du carton',
    },
    {
        code: '1722',
        name: "Fabrication d'articles en papier à usage sanitaire ou domestique",
        section: 'Section C - Industrie Manufacturière',
        category: '17-Industrie du papier et du carton',
    },
    {
        code: '1722Z',
        name: "Fabrication d'articles en papier à usage sanitaire ou domestique",
        section: 'Section C - Industrie Manufacturière',
        category: '17-Industrie du papier et du carton',
    },
    {
        code: '1723',
        name: "Fabrication d'articles de papeterie",
        section: 'Section C - Industrie Manufacturière',
        category: '17-Industrie du papier et du carton',
    },
    {
        code: '1723Z',
        name: "Fabrication d'articles de papeterie",
        section: 'Section C - Industrie Manufacturière',
        category: '17-Industrie du papier et du carton',
    },
    {
        code: '1724',
        name: 'Fabrication de papiers peints',
        section: 'Section C - Industrie Manufacturière',
        category: '17-Industrie du papier et du carton',
    },
    {
        code: '1724Z',
        name: 'Fabrication de papiers peints',
        section: 'Section C - Industrie Manufacturière',
        category: '17-Industrie du papier et du carton',
    },
    {
        code: '1729',
        name: "Fabrication d'autres articles en papier ou en carton",
        section: 'Section C - Industrie Manufacturière',
        category: '17-Industrie du papier et du carton',
    },
    {
        code: '1729Z',
        name: "Fabrication d'autres articles en papier ou en carton",
        section: 'Section C - Industrie Manufacturière',
        category: '17-Industrie du papier et du carton',
    },
    {
        code: '18',
        name: "Imprimerie et reproduction d'enregistrements",
        section: 'Section C - Industrie Manufacturière',
        category: "18-Imprimerie et reproduction d'enregistrements",
    },
    {
        code: '181',
        name: 'Imprimerie et services annexes',
        section: 'Section C - Industrie Manufacturière',
        category: "18-Imprimerie et reproduction d'enregistrements",
    },
    {
        code: '1811',
        name: 'Imprimerie de journaux',
        section: 'Section C - Industrie Manufacturière',
        category: "18-Imprimerie et reproduction d'enregistrements",
    },
    {
        code: '1811Z',
        name: 'Imprimerie de journaux',
        section: 'Section C - Industrie Manufacturière',
        category: "18-Imprimerie et reproduction d'enregistrements",
    },
    {
        code: '1812',
        name: 'Autre imprimerie (labeur)',
        section: 'Section C - Industrie Manufacturière',
        category: "18-Imprimerie et reproduction d'enregistrements",
    },
    {
        code: '1812Z',
        name: 'Autre imprimerie (labeur)',
        section: 'Section C - Industrie Manufacturière',
        category: "18-Imprimerie et reproduction d'enregistrements",
    },
    {
        code: '1813',
        name: 'Activités de pré-presse',
        section: 'Section C - Industrie Manufacturière',
        category: "18-Imprimerie et reproduction d'enregistrements",
    },
    {
        code: '1813Z',
        name: 'Activités de pré-presse',
        section: 'Section C - Industrie Manufacturière',
        category: "18-Imprimerie et reproduction d'enregistrements",
    },
    {
        code: '1814',
        name: 'Reliure et activités connexes',
        section: 'Section C - Industrie Manufacturière',
        category: "18-Imprimerie et reproduction d'enregistrements",
    },
    {
        code: '1814Z',
        name: 'Reliure et activités connexes',
        section: 'Section C - Industrie Manufacturière',
        category: "18-Imprimerie et reproduction d'enregistrements",
    },
    {
        code: '182',
        name: "Reproduction d'enregistrements",
        section: 'Section C - Industrie Manufacturière',
        category: "18-Imprimerie et reproduction d'enregistrements",
    },
    {
        code: '1820',
        name: "Reproduction d'enregistrements",
        section: 'Section C - Industrie Manufacturière',
        category: "18-Imprimerie et reproduction d'enregistrements",
    },
    {
        code: '1820Z',
        name: "Reproduction d'enregistrements",
        section: 'Section C - Industrie Manufacturière',
        category: "18-Imprimerie et reproduction d'enregistrements",
    },
    {
        code: '19',
        name: 'Cokéfaction et raffinage',
        section: 'Section C - Industrie Manufacturière',
        category: '19-Cokéfaction et raffinage',
    },
    {
        code: '191',
        name: 'Cokéfaction',
        section: 'Section C - Industrie Manufacturière',
        category: '19-Cokéfaction et raffinage',
    },
    {
        code: '1910',
        name: 'Cokéfaction',
        section: 'Section C - Industrie Manufacturière',
        category: '19-Cokéfaction et raffinage',
    },
    {
        code: '1910Z',
        name: 'Cokéfaction',
        section: 'Section C - Industrie Manufacturière',
        category: '19-Cokéfaction et raffinage',
    },
    {
        code: '192',
        name: 'Raffinage du pétrole',
        section: 'Section C - Industrie Manufacturière',
        category: '19-Cokéfaction et raffinage',
    },
    {
        code: '1920',
        name: 'Raffinage du pétrole',
        section: 'Section C - Industrie Manufacturière',
        category: '19-Cokéfaction et raffinage',
    },
    {
        code: '1920Z',
        name: 'Raffinage du pétrole',
        section: 'Section C - Industrie Manufacturière',
        category: '19-Cokéfaction et raffinage',
    },
    {
        code: '20',
        name: 'Industrie chimique',
        section: 'Section C - Industrie Manufacturière',
        category: '20-Industrie chimique',
    },
    {
        code: '201',
        name: 'Fab. prod. chimiq. base, azoté, engrais, plast. & caoutch. synth.',
        section: 'Section C - Industrie Manufacturière',
        category: '20-Industrie chimique',
    },
    {
        code: '2011',
        name: 'Fabrication de gaz industriels',
        section: 'Section C - Industrie Manufacturière',
        category: '20-Industrie chimique',
    },
    {
        code: '2011Z',
        name: 'Fabrication de gaz industriels',
        section: 'Section C - Industrie Manufacturière',
        category: '20-Industrie chimique',
    },
    {
        code: '2012',
        name: 'Fabrication de colorants et de pigments',
        section: 'Section C - Industrie Manufacturière',
        category: '20-Industrie chimique',
    },
    {
        code: '2012Z',
        name: 'Fabrication de colorants et de pigments',
        section: 'Section C - Industrie Manufacturière',
        category: '20-Industrie chimique',
    },
    {
        code: '2013',
        name: "Fabrication d'autres produits chimiques inorganiques de base",
        section: 'Section C - Industrie Manufacturière',
        category: '20-Industrie chimique',
    },
    {
        code: '2013A',
        name: 'Enrichissement et retraitement de matières nucléaires',
        section: 'Section C - Industrie Manufacturière',
        category: '20-Industrie chimique',
    },
    {
        code: '2013B',
        name: "Fabric. d'autres produits chimiques inorganiques de base n.c.a.",
        section: 'Section C - Industrie Manufacturière',
        category: '20-Industrie chimique',
    },
    {
        code: '2014',
        name: "Fabrication d'autres produits chimiques organiques de base",
        section: 'Section C - Industrie Manufacturière',
        category: '20-Industrie chimique',
    },
    {
        code: '2014Z',
        name: "Fabrication d'autres produits chimiques organiques de base",
        section: 'Section C - Industrie Manufacturière',
        category: '20-Industrie chimique',
    },
    {
        code: '2015',
        name: "Fabrication de produits azotés et d'engrais",
        section: 'Section C - Industrie Manufacturière',
        category: '20-Industrie chimique',
    },
    {
        code: '2015Z',
        name: "Fabrication de produits azotés et d'engrais",
        section: 'Section C - Industrie Manufacturière',
        category: '20-Industrie chimique',
    },
    {
        code: '2016',
        name: 'Fabrication de matières plastiques de base',
        section: 'Section C - Industrie Manufacturière',
        category: '20-Industrie chimique',
    },
    {
        code: '2016Z',
        name: 'Fabrication de matières plastiques de base',
        section: 'Section C - Industrie Manufacturière',
        category: '20-Industrie chimique',
    },
    {
        code: '2017',
        name: 'Fabrication de caoutchouc synthétique',
        section: 'Section C - Industrie Manufacturière',
        category: '20-Industrie chimique',
    },
    {
        code: '2017Z',
        name: 'Fabrication de caoutchouc synthétique',
        section: 'Section C - Industrie Manufacturière',
        category: '20-Industrie chimique',
    },
    {
        code: '202',
        name: 'Fabrication de pesticides et d’autres produits agrochimiques',
        section: 'Section C - Industrie Manufacturière',
        category: '20-Industrie chimique',
    },
    {
        code: '2020',
        name: 'Fabrication de pesticides et d’autres produits agrochimiques',
        section: 'Section C - Industrie Manufacturière',
        category: '20-Industrie chimique',
    },
    {
        code: '2020Z',
        name: 'Fabrication de pesticides et d’autres produits agrochimiques',
        section: 'Section C - Industrie Manufacturière',
        category: '20-Industrie chimique',
    },
    {
        code: '203',
        name: 'Fabrication de peintures, vernis, encres et mastics',
        section: 'Section C - Industrie Manufacturière',
        category: '20-Industrie chimique',
    },
    {
        code: '2030',
        name: 'Fabrication de peintures, vernis, encres et mastics',
        section: 'Section C - Industrie Manufacturière',
        category: '20-Industrie chimique',
    },
    {
        code: '2030Z',
        name: 'Fabrication de peintures, vernis, encres et mastics',
        section: 'Section C - Industrie Manufacturière',
        category: '20-Industrie chimique',
    },
    {
        code: '204',
        name: "Fabrication de savons, de produits d'entretien et de parfums",
        section: 'Section C - Industrie Manufacturière',
        category: '20-Industrie chimique',
    },
    {
        code: '2041',
        name: "Fabrication de savons, détergents et produits d'entretien",
        section: 'Section C - Industrie Manufacturière',
        category: '20-Industrie chimique',
    },
    {
        code: '2041Z',
        name: "Fabrication de savons, détergents et produits d'entretien",
        section: 'Section C - Industrie Manufacturière',
        category: '20-Industrie chimique',
    },
    {
        code: '2042',
        name: 'Fabrication de parfums et de produits pour la toilette',
        section: 'Section C - Industrie Manufacturière',
        category: '20-Industrie chimique',
    },
    {
        code: '2042Z',
        name: 'Fabrication de parfums et de produits pour la toilette',
        section: 'Section C - Industrie Manufacturière',
        category: '20-Industrie chimique',
    },
    {
        code: '205',
        name: "Fabrication d'autres produits chimiques",
        section: 'Section C - Industrie Manufacturière',
        category: '20-Industrie chimique',
    },
    {
        code: '2051',
        name: 'Fabrication de produits explosifs',
        section: 'Section C - Industrie Manufacturière',
        category: '20-Industrie chimique',
    },
    {
        code: '2051Z',
        name: 'Fabrication de produits explosifs',
        section: 'Section C - Industrie Manufacturière',
        category: '20-Industrie chimique',
    },
    {
        code: '2052',
        name: 'Fabrication de colles',
        section: 'Section C - Industrie Manufacturière',
        category: '20-Industrie chimique',
    },
    {
        code: '2052Z',
        name: 'Fabrication de colles',
        section: 'Section C - Industrie Manufacturière',
        category: '20-Industrie chimique',
    },
    {
        code: '2053',
        name: "Fabrication d'huiles essentielles",
        section: 'Section C - Industrie Manufacturière',
        category: '20-Industrie chimique',
    },
    {
        code: '2053Z',
        name: "Fabrication d'huiles essentielles",
        section: 'Section C - Industrie Manufacturière',
        category: '20-Industrie chimique',
    },
    {
        code: '2059',
        name: "Fabrication d'autres produits chimiques n.c.a.",
        section: 'Section C - Industrie Manufacturière',
        category: '20-Industrie chimique',
    },
    {
        code: '2059Z',
        name: "Fabrication d'autres produits chimiques n.c.a.",
        section: 'Section C - Industrie Manufacturière',
        category: '20-Industrie chimique',
    },
    {
        code: '206',
        name: 'Fabrication de fibres artificielles ou synthétiques',
        section: 'Section C - Industrie Manufacturière',
        category: '20-Industrie chimique',
    },
    {
        code: '2060',
        name: 'Fabrication de fibres artificielles ou synthétiques',
        section: 'Section C - Industrie Manufacturière',
        category: '20-Industrie chimique',
    },
    {
        code: '2060Z',
        name: 'Fabrication de fibres artificielles ou synthétiques',
        section: 'Section C - Industrie Manufacturière',
        category: '20-Industrie chimique',
    },
    {
        code: '21',
        name: 'Industrie pharmaceutique',
        section: 'Section C - Industrie Manufacturière',
        category: '21-Industrie pharmaceutique',
    },
    {
        code: '211',
        name: 'Fabrication de produits pharmaceutiques de base',
        section: 'Section C - Industrie Manufacturière',
        category: '21-Industrie pharmaceutique',
    },
    {
        code: '2110',
        name: 'Fabrication de produits pharmaceutiques de base',
        section: 'Section C - Industrie Manufacturière',
        category: '21-Industrie pharmaceutique',
    },
    {
        code: '2110Z',
        name: 'Fabrication de produits pharmaceutiques de base',
        section: 'Section C - Industrie Manufacturière',
        category: '21-Industrie pharmaceutique',
    },
    {
        code: '212',
        name: 'Fabrication de préparations pharmaceutiques',
        section: 'Section C - Industrie Manufacturière',
        category: '21-Industrie pharmaceutique',
    },
    {
        code: '2120',
        name: 'Fabrication de préparations pharmaceutiques',
        section: 'Section C - Industrie Manufacturière',
        category: '21-Industrie pharmaceutique',
    },
    {
        code: '2120Z',
        name: 'Fabrication de préparations pharmaceutiques',
        section: 'Section C - Industrie Manufacturière',
        category: '21-Industrie pharmaceutique',
    },
    {
        code: '22',
        name: 'Fabrication de produits en caoutchouc et en plastique',
        section: 'Section C - Industrie Manufacturière',
        category: '22-Fabrication de produits en caoutchouc et en plastique',
    },
    {
        code: '221',
        name: 'Fabrication de produits en caoutchouc',
        section: 'Section C - Industrie Manufacturière',
        category: '22-Fabrication de produits en caoutchouc et en plastique',
    },
    {
        code: '2211',
        name: 'Fabrication et rechapage de pneumatiques',
        section: 'Section C - Industrie Manufacturière',
        category: '22-Fabrication de produits en caoutchouc et en plastique',
    },
    {
        code: '2211Z',
        name: 'Fabrication et rechapage de pneumatiques',
        section: 'Section C - Industrie Manufacturière',
        category: '22-Fabrication de produits en caoutchouc et en plastique',
    },
    {
        code: '2219',
        name: "Fabrication d'autres articles en caoutchouc",
        section: 'Section C - Industrie Manufacturière',
        category: '22-Fabrication de produits en caoutchouc et en plastique',
    },
    {
        code: '2219Z',
        name: "Fabrication d'autres articles en caoutchouc",
        section: 'Section C - Industrie Manufacturière',
        category: '22-Fabrication de produits en caoutchouc et en plastique',
    },
    {
        code: '222',
        name: 'Fabrication  de produits en plastique',
        section: 'Section C - Industrie Manufacturière',
        category: '22-Fabrication de produits en caoutchouc et en plastique',
    },
    {
        code: '2221',
        name: 'Fabrication plaques, feuilles, tubes et profilés en plastique',
        section: 'Section C - Industrie Manufacturière',
        category: '22-Fabrication de produits en caoutchouc et en plastique',
    },
    {
        code: '2221Z',
        name: 'Fabrication plaques, feuilles, tubes et profilés en plastique',
        section: 'Section C - Industrie Manufacturière',
        category: '22-Fabrication de produits en caoutchouc et en plastique',
    },
    {
        code: '2222',
        name: "Fabrication d'emballages en matières plastiques",
        section: 'Section C - Industrie Manufacturière',
        category: '22-Fabrication de produits en caoutchouc et en plastique',
    },
    {
        code: '2222Z',
        name: "Fabrication d'emballages en matières plastiques",
        section: 'Section C - Industrie Manufacturière',
        category: '22-Fabrication de produits en caoutchouc et en plastique',
    },
    {
        code: '2223',
        name: "Fabrication d'éléments matières plastiques pour la construction",
        section: 'Section C - Industrie Manufacturière',
        category: '22-Fabrication de produits en caoutchouc et en plastique',
    },
    {
        code: '2223Z',
        name: "Fabrication d'éléments matières plastiques pour la construction",
        section: 'Section C - Industrie Manufacturière',
        category: '22-Fabrication de produits en caoutchouc et en plastique',
    },
    {
        code: '2229',
        name: "Fabrication d'autres articles en matières plastiques",
        section: 'Section C - Industrie Manufacturière',
        category: '22-Fabrication de produits en caoutchouc et en plastique',
    },
    {
        code: '2229A',
        name: 'Fabrication de pièces techniques à base de matières plastiques',
        section: 'Section C - Industrie Manufacturière',
        category: '22-Fabrication de produits en caoutchouc et en plastique',
    },
    {
        code: '2229B',
        name: 'Fabrication produits de consommation courante en plastique',
        section: 'Section C - Industrie Manufacturière',
        category: '22-Fabrication de produits en caoutchouc et en plastique',
    },
    {
        code: '23',
        name: "Fabrication d'autres produits minéraux non métalliques",
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '231',
        name: "Fabrication de verre et d'articles en verre",
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2311',
        name: 'Fabrication de verre plat',
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2311Z',
        name: 'Fabrication de verre plat',
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2312',
        name: 'Façonnage et transformation du verre plat',
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2312Z',
        name: 'Façonnage et transformation du verre plat',
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2313',
        name: 'Fabrication de verre creux',
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2313Z',
        name: 'Fabrication de verre creux',
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2314',
        name: 'Fabrication de fibres de verre',
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2314Z',
        name: 'Fabrication de fibres de verre',
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2319',
        name: 'Fabrication & façonnage autres articles verre yc verre technique',
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2319Z',
        name: 'Fabrication & façonnage autres articles verre yc verre technique',
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '232',
        name: 'Fabrication de produits réfractaires',
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2320',
        name: 'Fabrication de produits réfractaires',
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2320Z',
        name: 'Fabrication de produits réfractaires',
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '233',
        name: 'Fabrication de matériaux de construction en terre cuite',
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2331',
        name: 'Fabrication de carreaux en céramique',
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2331Z',
        name: 'Fabrication de carreaux en céramique',
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2332',
        name: 'Fabrication briques tuiles & prod. de construction en terre cuite',
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2332Z',
        name: 'Fabrication briques tuiles & prod. de construction en terre cuite',
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '234',
        name: "Fabrication d'autres produits en céramique et en porcelaine",
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2341',
        name: "Fabrication d'articles céramiques à usage domestique, ornemental",
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2341Z',
        name: "Fabrication d'articles céramiques à usage domestique, ornemental",
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2342',
        name: "Fabrication d'appareils sanitaires en céramique",
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2342Z',
        name: "Fabrication d'appareils sanitaires en céramique",
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2343',
        name: "Fabrication d'isolateurs et pièces isolantes en céramique",
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2343Z',
        name: "Fabrication d'isolateurs et pièces isolantes en céramique",
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2344',
        name: "Fabrication d'autres produits céramiques à usage technique",
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2344Z',
        name: "Fabrication d'autres produits céramiques à usage technique",
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2349',
        name: "Fabrication d'autres produits céramiques",
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2349Z',
        name: "Fabrication d'autres produits céramiques",
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '235',
        name: 'Fabrication de ciment, chaux et plâtre',
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2351',
        name: 'Fabrication de ciment',
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2351Z',
        name: 'Fabrication de ciment',
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2352',
        name: 'Fabrication de chaux et plâtre',
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2352Z',
        name: 'Fabrication de chaux et plâtre',
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '236',
        name: "Fabrication d'ouvrages en béton, en ciment ou en plâtre",
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2361',
        name: "Fabrication d'éléments en béton pour la construction",
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2361Z',
        name: "Fabrication d'éléments en béton pour la construction",
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2362',
        name: "Fabrication d'éléments en plâtre pour la construction",
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2362Z',
        name: "Fabrication d'éléments en plâtre pour la construction",
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2363',
        name: "Fabrication de béton prêt à l'emploi",
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2363Z',
        name: "Fabrication de béton prêt à l'emploi",
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2364',
        name: 'Fabrication de mortiers et bétons secs',
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2364Z',
        name: 'Fabrication de mortiers et bétons secs',
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2365',
        name: "Fabrication d'ouvrages en fibre-ciment",
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2365Z',
        name: "Fabrication d'ouvrages en fibre-ciment",
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2369',
        name: "Fabrication d'autres ouvrages en béton, en ciment ou en plâtre",
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2369Z',
        name: "Fabrication d'autres ouvrages en béton, en ciment ou en plâtre",
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '237',
        name: 'Taille, façonnage et finissage de pierres',
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2370',
        name: 'Taille, façonnage et finissage de pierres',
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2370Z',
        name: 'Taille, façonnage et finissage de pierres',
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '239',
        name: 'Fabric. prod. abrasifs & produits minéraux non métalliques n.c.a.',
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2391',
        name: 'Fabrication de produits abrasifs',
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2391Z',
        name: 'Fabrication de produits abrasifs',
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2399',
        name: "Fabrication d'autres produits minéraux non métalliques n.c.a.",
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '2399Z',
        name: "Fabrication d'autres produits minéraux non métalliques n.c.a.",
        section: 'Section C - Industrie Manufacturière',
        category: "23-Fabrication d'autres produits minéraux non métalliques",
    },
    {
        code: '24',
        name: 'Métallurgie',
        section: 'Section C - Industrie Manufacturière',
        category: '24-Métallurgie',
    },
    {
        code: '241',
        name: 'Sidérurgie',
        section: 'Section C - Industrie Manufacturière',
        category: '24-Métallurgie',
    },
    {
        code: '2410',
        name: 'Sidérurgie',
        section: 'Section C - Industrie Manufacturière',
        category: '24-Métallurgie',
    },
    {
        code: '2410Z',
        name: 'Sidérurgie',
        section: 'Section C - Industrie Manufacturière',
        category: '24-Métallurgie',
    },
    {
        code: '242',
        name: 'Fabric. tubes, tuyaux, profilés creux & accessoir. corresp. acier',
        section: 'Section C - Industrie Manufacturière',
        category: '24-Métallurgie',
    },
    {
        code: '2420',
        name: 'Fabric. tubes, tuyaux, profilés creux & accessoir. corresp. acier',
        section: 'Section C - Industrie Manufacturière',
        category: '24-Métallurgie',
    },
    {
        code: '2420Z',
        name: 'Fabric. tubes, tuyaux, profilés creux & accessoir. corresp. acier',
        section: 'Section C - Industrie Manufacturière',
        category: '24-Métallurgie',
    },
    {
        code: '243',
        name: "Fabrication autres produits 1ere transformation de l'acier",
        section: 'Section C - Industrie Manufacturière',
        category: '24-Métallurgie',
    },
    {
        code: '2431',
        name: 'Étirage à froid de barres',
        section: 'Section C - Industrie Manufacturière',
        category: '24-Métallurgie',
    },
    {
        code: '2431Z',
        name: 'Étirage à froid de barres',
        section: 'Section C - Industrie Manufacturière',
        category: '24-Métallurgie',
    },
    {
        code: '2432',
        name: 'Laminage à froid de feuillards',
        section: 'Section C - Industrie Manufacturière',
        category: '24-Métallurgie',
    },
    {
        code: '2432Z',
        name: 'Laminage à froid de feuillards',
        section: 'Section C - Industrie Manufacturière',
        category: '24-Métallurgie',
    },
    {
        code: '2433',
        name: 'Profilage à froid par formage ou pliage',
        section: 'Section C - Industrie Manufacturière',
        category: '24-Métallurgie',
    },
    {
        code: '2433Z',
        name: 'Profilage à froid par formage ou pliage',
        section: 'Section C - Industrie Manufacturière',
        category: '24-Métallurgie',
    },
    {
        code: '2434',
        name: 'Tréfilage à froid',
        section: 'Section C - Industrie Manufacturière',
        category: '24-Métallurgie',
    },
    {
        code: '2434Z',
        name: 'Tréfilage à froid',
        section: 'Section C - Industrie Manufacturière',
        category: '24-Métallurgie',
    },
    {
        code: '244',
        name: "Production de métaux précieux et d'autres métaux non ferreux",
        section: 'Section C - Industrie Manufacturière',
        category: '24-Métallurgie',
    },
    {
        code: '2441',
        name: 'Production de métaux précieux',
        section: 'Section C - Industrie Manufacturière',
        category: '24-Métallurgie',
    },
    {
        code: '2441Z',
        name: 'Production de métaux précieux',
        section: 'Section C - Industrie Manufacturière',
        category: '24-Métallurgie',
    },
    {
        code: '2442',
        name: "Métallurgie de l'aluminium",
        section: 'Section C - Industrie Manufacturière',
        category: '24-Métallurgie',
    },
    {
        code: '2442Z',
        name: "Métallurgie de l'aluminium",
        section: 'Section C - Industrie Manufacturière',
        category: '24-Métallurgie',
    },
    {
        code: '2443',
        name: "Métallurgie du plomb, du zinc ou de l'étain",
        section: 'Section C - Industrie Manufacturière',
        category: '24-Métallurgie',
    },
    {
        code: '2443Z',
        name: "Métallurgie du plomb, du zinc ou de l'étain",
        section: 'Section C - Industrie Manufacturière',
        category: '24-Métallurgie',
    },
    {
        code: '2444',
        name: 'Métallurgie du cuivre',
        section: 'Section C - Industrie Manufacturière',
        category: '24-Métallurgie',
    },
    {
        code: '2444Z',
        name: 'Métallurgie du cuivre',
        section: 'Section C - Industrie Manufacturière',
        category: '24-Métallurgie',
    },
    {
        code: '2445',
        name: 'Métallurgie des autres métaux non ferreux',
        section: 'Section C - Industrie Manufacturière',
        category: '24-Métallurgie',
    },
    {
        code: '2445Z',
        name: 'Métallurgie des autres métaux non ferreux',
        section: 'Section C - Industrie Manufacturière',
        category: '24-Métallurgie',
    },
    {
        code: '245',
        name: 'Fonderie',
        section: 'Section C - Industrie Manufacturière',
        category: '24-Métallurgie',
    },
    {
        code: '2451',
        name: 'Fonderie de fonte',
        section: 'Section C - Industrie Manufacturière',
        category: '24-Métallurgie',
    },
    {
        code: '2451Z',
        name: 'Fonderie de fonte',
        section: 'Section C - Industrie Manufacturière',
        category: '24-Métallurgie',
    },
    {
        code: '2452',
        name: "Fonderie d'acier",
        section: 'Section C - Industrie Manufacturière',
        category: '24-Métallurgie',
    },
    {
        code: '2452Z',
        name: "Fonderie d'acier",
        section: 'Section C - Industrie Manufacturière',
        category: '24-Métallurgie',
    },
    {
        code: '2453',
        name: 'Fonderie de métaux légers',
        section: 'Section C - Industrie Manufacturière',
        category: '24-Métallurgie',
    },
    {
        code: '2453Z',
        name: 'Fonderie de métaux légers',
        section: 'Section C - Industrie Manufacturière',
        category: '24-Métallurgie',
    },
    {
        code: '2454',
        name: "Fonderie d'autres métaux non ferreux",
        section: 'Section C - Industrie Manufacturière',
        category: '24-Métallurgie',
    },
    {
        code: '2454Z',
        name: "Fonderie d'autres métaux non ferreux",
        section: 'Section C - Industrie Manufacturière',
        category: '24-Métallurgie',
    },
    {
        code: '25',
        name: 'Fabric. produits métalliques, sf machines & équipements',
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '251',
        name: "Fabrication d'éléments en métal pour la construction",
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '2511',
        name: 'Fabrication de structures métalliques et de parties de structures',
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '2511Z',
        name: 'Fabrication de structures métalliques et de parties de structures',
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '2512',
        name: 'Fabrication de portes et fenêtres en métal',
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '2512Z',
        name: 'Fabrication de portes et fenêtres en métal',
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '252',
        name: 'Fabrication de réservoirs, citernes et conteneurs métalliques',
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '2521',
        name: 'Fabrication radiateurs et chaudières pour le chauffage central',
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '2521Z',
        name: 'Fabrication radiateurs et chaudières pour le chauffage central',
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '2529',
        name: "Fabrication d'autres réservoirs, citernes, conteneurs métalliques",
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '2529Z',
        name: "Fabrication d'autres réservoirs, citernes, conteneurs métalliques",
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '253',
        name: 'Fabric. générateurs vapeur sf chaudières pour chauffage central',
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '2530',
        name: 'Fabric. générateurs vapeur sf chaudières pour chauffage central',
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '2530Z',
        name: 'Fabric. générateurs vapeur sf chaudières pour chauffage central',
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '254',
        name: "Fabrication d'armes et de munitions",
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '2540',
        name: "Fabrication d'armes et de munitions",
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '2540Z',
        name: "Fabrication d'armes et de munitions",
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '255',
        name: 'Forge, emboutissage, estampage; métallurgie des poudres',
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '2550',
        name: 'Forge, emboutissage, estampage; métallurgie des poudres',
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '2550A',
        name: 'Forge, estampage, matriçage; métallurgie des poudres',
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '2550B',
        name: 'Découpage, emboutissage',
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '256',
        name: 'Traitement et revêtement des métaux; usinage',
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '2561',
        name: 'Traitement et revêtement des métaux',
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '2561Z',
        name: 'Traitement et revêtement des métaux',
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '2562',
        name: 'Usinage',
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '2562A',
        name: 'Décolletage',
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '2562B',
        name: 'Mécanique industrielle',
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '257',
        name: "Fabrication de coutellerie, d'outillage et de quincaillerie",
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '2571',
        name: 'Fabrication de coutellerie',
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '2571Z',
        name: 'Fabrication de coutellerie',
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '2572',
        name: 'Fabrication de serrures et de ferrures',
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '2572Z',
        name: 'Fabrication de serrures et de ferrures',
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '2573',
        name: "Fabrication d'outillage",
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '2573A',
        name: 'Fabrication de moules et modèles',
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '2573B',
        name: "Fabrication d'autres outillages",
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '259',
        name: "Fabrication d'autres ouvrages en métaux",
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '2591',
        name: 'Fabrication de fûts et emballages métalliques similaires',
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '2591Z',
        name: 'Fabrication de fûts et emballages métalliques similaires',
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '2592',
        name: "Fabrication d'emballages métalliques légers",
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '2592Z',
        name: "Fabrication d'emballages métalliques légers",
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '2593',
        name: "Fabrication d'articles en fils métalliques, chaînes et ressorts",
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '2593Z',
        name: "Fabrication d'articles en fils métalliques, chaînes et ressorts",
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '2594',
        name: 'Fabrication de vis et de boulons',
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '2594Z',
        name: 'Fabrication de vis et de boulons',
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '2599',
        name: "Fabrication d'autres produits métalliques n.c.a.",
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '2599A',
        name: "Fabrication d'articles métalliques ménagers",
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '2599B',
        name: "Fabrication d'autres articles métalliques",
        section: 'Section C - Industrie Manufacturière',
        category: '25-Fabric. produits métalliques, sf machines & équipements',
    },
    {
        code: '26',
        name: 'Fabrication de produits informatiques, électroniques et optiques',
        section: 'Section C - Industrie Manufacturière',
        category: '26-Fabrication de produits informatiques, électroniques et optiques',
    },
    {
        code: '261',
        name: 'Fabrication de composants et cartes électroniques',
        section: 'Section C - Industrie Manufacturière',
        category: '26-Fabrication de produits informatiques, électroniques et optiques',
    },
    {
        code: '2611',
        name: 'Fabrication de composants électroniques',
        section: 'Section C - Industrie Manufacturière',
        category: '26-Fabrication de produits informatiques, électroniques et optiques',
    },
    {
        code: '2611Z',
        name: 'Fabrication de composants électroniques',
        section: 'Section C - Industrie Manufacturière',
        category: '26-Fabrication de produits informatiques, électroniques et optiques',
    },
    {
        code: '2612',
        name: 'Fabrication de cartes électroniques assemblées',
        section: 'Section C - Industrie Manufacturière',
        category: '26-Fabrication de produits informatiques, électroniques et optiques',
    },
    {
        code: '2612Z',
        name: 'Fabrication de cartes électroniques assemblées',
        section: 'Section C - Industrie Manufacturière',
        category: '26-Fabrication de produits informatiques, électroniques et optiques',
    },
    {
        code: '262',
        name: "Fabrication d'ordinateurs et d'équipements périphériques",
        section: 'Section C - Industrie Manufacturière',
        category: '26-Fabrication de produits informatiques, électroniques et optiques',
    },
    {
        code: '2620',
        name: "Fabrication d'ordinateurs et d'équipements périphériques",
        section: 'Section C - Industrie Manufacturière',
        category: '26-Fabrication de produits informatiques, électroniques et optiques',
    },
    {
        code: '2620Z',
        name: "Fabrication d'ordinateurs et d'équipements périphériques",
        section: 'Section C - Industrie Manufacturière',
        category: '26-Fabrication de produits informatiques, électroniques et optiques',
    },
    {
        code: '263',
        name: "Fabrication d'équipements de communication",
        section: 'Section C - Industrie Manufacturière',
        category: '26-Fabrication de produits informatiques, électroniques et optiques',
    },
    {
        code: '2630',
        name: "Fabrication d'équipements de communication",
        section: 'Section C - Industrie Manufacturière',
        category: '26-Fabrication de produits informatiques, électroniques et optiques',
    },
    {
        code: '2630Z',
        name: "Fabrication d'équipements de communication",
        section: 'Section C - Industrie Manufacturière',
        category: '26-Fabrication de produits informatiques, électroniques et optiques',
    },
    {
        code: '264',
        name: 'Fabrication de produits électroniques grand public',
        section: 'Section C - Industrie Manufacturière',
        category: '26-Fabrication de produits informatiques, électroniques et optiques',
    },
    {
        code: '2640',
        name: 'Fabrication de produits électroniques grand public',
        section: 'Section C - Industrie Manufacturière',
        category: '26-Fabrication de produits informatiques, électroniques et optiques',
    },
    {
        code: '2640Z',
        name: 'Fabrication de produits électroniques grand public',
        section: 'Section C - Industrie Manufacturière',
        category: '26-Fabrication de produits informatiques, électroniques et optiques',
    },
    {
        code: '265',
        name: 'Fab. instrum. & appar. de mesure, essai & navigation; horlogerie',
        section: 'Section C - Industrie Manufacturière',
        category: '26-Fabrication de produits informatiques, électroniques et optiques',
    },
    {
        code: '2651',
        name: "Fabric. instruments & d'appareils mesure, essai & de navigation",
        section: 'Section C - Industrie Manufacturière',
        category: '26-Fabrication de produits informatiques, électroniques et optiques',
    },
    {
        code: '2651A',
        name: "Fabrication d'équipements d'aide à la navigation",
        section: 'Section C - Industrie Manufacturière',
        category: '26-Fabrication de produits informatiques, électroniques et optiques',
    },
    {
        code: '2651B',
        name: "Fabrication d'instrumentation scientifique et technique",
        section: 'Section C - Industrie Manufacturière',
        category: '26-Fabrication de produits informatiques, électroniques et optiques',
    },
    {
        code: '2652',
        name: 'Horlogerie',
        section: 'Section C - Industrie Manufacturière',
        category: '26-Fabrication de produits informatiques, électroniques et optiques',
    },
    {
        code: '2652Z',
        name: 'Horlogerie',
        section: 'Section C - Industrie Manufacturière',
        category: '26-Fabrication de produits informatiques, électroniques et optiques',
    },
    {
        code: '266',
        name: "Fab. éqpts d'irradiation médic. électromédic. & électrothérapeut.",
        section: 'Section C - Industrie Manufacturière',
        category: '26-Fabrication de produits informatiques, électroniques et optiques',
    },
    {
        code: '2660',
        name: "Fab. éqpts d'irradiation médic. électromédic. & électrothérapeut.",
        section: 'Section C - Industrie Manufacturière',
        category: '26-Fabrication de produits informatiques, électroniques et optiques',
    },
    {
        code: '2660Z',
        name: "Fab. éqpts d'irradiation médic. électromédic. & électrothérapeut.",
        section: 'Section C - Industrie Manufacturière',
        category: '26-Fabrication de produits informatiques, électroniques et optiques',
    },
    {
        code: '267',
        name: 'Fabrication de matériels optique et photographique',
        section: 'Section C - Industrie Manufacturière',
        category: '26-Fabrication de produits informatiques, électroniques et optiques',
    },
    {
        code: '2670',
        name: 'Fabrication de matériels optique et photographique',
        section: 'Section C - Industrie Manufacturière',
        category: '26-Fabrication de produits informatiques, électroniques et optiques',
    },
    {
        code: '2670Z',
        name: 'Fabrication de matériels optique et photographique',
        section: 'Section C - Industrie Manufacturière',
        category: '26-Fabrication de produits informatiques, électroniques et optiques',
    },
    {
        code: '27',
        name: "Fabrication d'équipements électriques",
        section: 'Section C - Industrie Manufacturière',
        category: "27-Fabrication d'équipements électriques",
    },
    {
        code: '271',
        name: 'Fab. moteurs générat. transfo. élec., mat. distrib. & cde électr.',
        section: 'Section C - Industrie Manufacturière',
        category: "27-Fabrication d'équipements électriques",
    },
    {
        code: '2711',
        name: 'Fabrication de moteurs, génératrices, transformateurs électriques',
        section: 'Section C - Industrie Manufacturière',
        category: "27-Fabrication d'équipements électriques",
    },
    {
        code: '2711Z',
        name: 'Fabrication de moteurs, génératrices, transformateurs électriques',
        section: 'Section C - Industrie Manufacturière',
        category: "27-Fabrication d'équipements électriques",
    },
    {
        code: '2712',
        name: 'Fabrication de matériel de distribution et de commande électrique',
        section: 'Section C - Industrie Manufacturière',
        category: "27-Fabrication d'équipements électriques",
    },
    {
        code: '2712Z',
        name: 'Fabrication de matériel de distribution et de commande électrique',
        section: 'Section C - Industrie Manufacturière',
        category: "27-Fabrication d'équipements électriques",
    },
    {
        code: '272',
        name: "Fabrication de piles et d'accumulateurs électriques",
        section: 'Section C - Industrie Manufacturière',
        category: "27-Fabrication d'équipements électriques",
    },
    {
        code: '2720',
        name: "Fabrication de piles et d'accumulateurs électriques",
        section: 'Section C - Industrie Manufacturière',
        category: "27-Fabrication d'équipements électriques",
    },
    {
        code: '2720Z',
        name: "Fabrication de piles et d'accumulateurs électriques",
        section: 'Section C - Industrie Manufacturière',
        category: "27-Fabrication d'équipements électriques",
    },
    {
        code: '273',
        name: "Fabric. fils câbles & matériel d'installation électrique",
        section: 'Section C - Industrie Manufacturière',
        category: "27-Fabrication d'équipements électriques",
    },
    {
        code: '2731',
        name: 'Fabrication de câbles de fibres optiques',
        section: 'Section C - Industrie Manufacturière',
        category: "27-Fabrication d'équipements électriques",
    },
    {
        code: '2731Z',
        name: 'Fabrication de câbles de fibres optiques',
        section: 'Section C - Industrie Manufacturière',
        category: "27-Fabrication d'équipements électriques",
    },
    {
        code: '2732',
        name: "Fabrication d'autres fils et câbles électroniques ou électriques",
        section: 'Section C - Industrie Manufacturière',
        category: "27-Fabrication d'équipements électriques",
    },
    {
        code: '2732Z',
        name: "Fabrication d'autres fils et câbles électroniques ou électriques",
        section: 'Section C - Industrie Manufacturière',
        category: "27-Fabrication d'équipements électriques",
    },
    {
        code: '2733',
        name: "Fabrication de matériel d'installation électrique",
        section: 'Section C - Industrie Manufacturière',
        category: "27-Fabrication d'équipements électriques",
    },
    {
        code: '2733Z',
        name: "Fabrication de matériel d'installation électrique",
        section: 'Section C - Industrie Manufacturière',
        category: "27-Fabrication d'équipements électriques",
    },
    {
        code: '274',
        name: "Fabrication d'appareils d'éclairage électrique",
        section: 'Section C - Industrie Manufacturière',
        category: "27-Fabrication d'équipements électriques",
    },
    {
        code: '2740',
        name: "Fabrication d'appareils d'éclairage électrique",
        section: 'Section C - Industrie Manufacturière',
        category: "27-Fabrication d'équipements électriques",
    },
    {
        code: '2740Z',
        name: "Fabrication d'appareils d'éclairage électrique",
        section: 'Section C - Industrie Manufacturière',
        category: "27-Fabrication d'équipements électriques",
    },
    {
        code: '275',
        name: "Fabrication d'appareils ménagers",
        section: 'Section C - Industrie Manufacturière',
        category: "27-Fabrication d'équipements électriques",
    },
    {
        code: '2751',
        name: "Fabrication d'appareils électroménagers",
        section: 'Section C - Industrie Manufacturière',
        category: "27-Fabrication d'équipements électriques",
    },
    {
        code: '2751Z',
        name: "Fabrication d'appareils électroménagers",
        section: 'Section C - Industrie Manufacturière',
        category: "27-Fabrication d'équipements électriques",
    },
    {
        code: '2752',
        name: "Fabrication d'appareils ménagers non électriques",
        section: 'Section C - Industrie Manufacturière',
        category: "27-Fabrication d'équipements électriques",
    },
    {
        code: '2752Z',
        name: "Fabrication d'appareils ménagers non électriques",
        section: 'Section C - Industrie Manufacturière',
        category: "27-Fabrication d'équipements électriques",
    },
    {
        code: '279',
        name: "Fabrication d'autres matériels électriques",
        section: 'Section C - Industrie Manufacturière',
        category: "27-Fabrication d'équipements électriques",
    },
    {
        code: '2790',
        name: "Fabrication d'autres matériels électriques",
        section: 'Section C - Industrie Manufacturière',
        category: "27-Fabrication d'équipements électriques",
    },
    {
        code: '2790Z',
        name: "Fabrication d'autres matériels électriques",
        section: 'Section C - Industrie Manufacturière',
        category: "27-Fabrication d'équipements électriques",
    },
    {
        code: '28',
        name: 'Fabrication de machines et équipements n.c.a.',
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '281',
        name: "Fabrication de machines d'usage général",
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2811',
        name: "Fabrication moteurs & turbines sf moteurs d'avions & de véhicules",
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2811Z',
        name: "Fabrication moteurs & turbines sf moteurs d'avions & de véhicules",
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2812',
        name: "Fabrication d'équipements hydrauliques et pneumatiques",
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2812Z',
        name: "Fabrication d'équipements hydrauliques et pneumatiques",
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2813',
        name: "Fabrication d'autres pompes et compresseurs",
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2813Z',
        name: "Fabrication d'autres pompes et compresseurs",
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2814',
        name: "Fabrication d'autres articles de robinetterie",
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2814Z',
        name: "Fabrication d'autres articles de robinetterie",
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2815',
        name: "Fabrication d'engrenages et d'organes mécaniques de transmission",
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2815Z',
        name: "Fabrication d'engrenages et d'organes mécaniques de transmission",
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '282',
        name: "Fabrication d'autres machines d'usage général",
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2821',
        name: 'Fabrication de fours et brûleurs',
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2821Z',
        name: 'Fabrication de fours et brûleurs',
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2822',
        name: 'Fabrication de matériel de levage et de manutention',
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2822Z',
        name: 'Fabrication de matériel de levage et de manutention',
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2823',
        name: 'Fab. machines équipts bureau (sf ordinateurs & équipts périph.)',
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2823Z',
        name: 'Fab. machines équipts bureau (sf ordinateurs & équipts périph.)',
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2825',
        name: 'Fabrication équipements aérauliques et frigorifiques industriels',
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2825Z',
        name: 'Fabrication équipements aérauliques et frigorifiques industriels',
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2829',
        name: "Fabrication de machines diverses d'usage général",
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2829A',
        name: 'Fabrication équipts emballage, conditionnement & pesage',
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2829B',
        name: "Fabrication d'autres machines d'usage général",
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '283',
        name: 'Fabrication de machines agricoles et forestières',
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2830',
        name: 'Fabrication de machines agricoles et forestières',
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2830Z',
        name: 'Fabrication de machines agricoles et forestières',
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '284',
        name: 'Fabrication machines de formage des métaux & machines-outils',
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2841',
        name: 'Fabrication de machines de formage des métaux',
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2841Z',
        name: 'Fabrication de machines-outils pour le travail des métaux',
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2849',
        name: "Fabrication d'autres machines-outils",
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2849Z',
        name: "Fabrication d'autres machines-outils",
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '289',
        name: "Fabrication d'autres machines d'usage spécifique",
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2891',
        name: 'Fabrication de machines pour la métallurgie',
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2891Z',
        name: 'Fabrication de machines pour la métallurgie',
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2892',
        name: "Fabrication de machines pour l'extraction ou la construction",
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2892Z',
        name: "Fabrication de machines pour l'extraction ou la construction",
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2893',
        name: "Fabrication de machines pour l'industrie agro-alimentaire",
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2893Z',
        name: "Fabrication de machines pour l'industrie agro-alimentaire",
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2894',
        name: 'Fabrication de machines pour les industries textiles',
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2894Z',
        name: 'Fabrication de machines pour les industries textiles',
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2895',
        name: 'Fabrication de machines pour les industries du papier et carton',
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2895Z',
        name: 'Fabrication de machines pour les industries du papier et carton',
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2896',
        name: 'Fabric. machines pour le travail du caoutchouc ou des plastiques',
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2896Z',
        name: 'Fabric. machines pour le travail du caoutchouc ou des plastiques',
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2899',
        name: "Fabrication d'autres machines d'usage spécifique n.c.a.",
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2899A',
        name: "Fabrication de machines d'imprimerie",
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '2899B',
        name: "Fabrication d'autres machines spécialisées",
        section: 'Section C - Industrie Manufacturière',
        category: '28-Fabrication de machines et équipements n.c.a.',
    },
    {
        code: '29',
        name: 'Industrie automobile',
        section: 'Section C - Industrie Manufacturière',
        category: '29-Industrie automobile',
    },
    {
        code: '291',
        name: 'Construction de véhicules automobiles',
        section: 'Section C - Industrie Manufacturière',
        category: '29-Industrie automobile',
    },
    {
        code: '2910',
        name: 'Construction de véhicules automobiles',
        section: 'Section C - Industrie Manufacturière',
        category: '29-Industrie automobile',
    },
    {
        code: '2910Z',
        name: 'Construction de véhicules automobiles',
        section: 'Section C - Industrie Manufacturière',
        category: '29-Industrie automobile',
    },
    {
        code: '292',
        name: 'Fabrication de carrosseries et remorques',
        section: 'Section C - Industrie Manufacturière',
        category: '29-Industrie automobile',
    },
    {
        code: '2920',
        name: 'Fabrication de carrosseries et remorques',
        section: 'Section C - Industrie Manufacturière',
        category: '29-Industrie automobile',
    },
    {
        code: '2920Z',
        name: 'Fabrication de carrosseries et remorques',
        section: 'Section C - Industrie Manufacturière',
        category: '29-Industrie automobile',
    },
    {
        code: '293',
        name: "Fabrication d'équipements automobiles",
        section: 'Section C - Industrie Manufacturière',
        category: '29-Industrie automobile',
    },
    {
        code: '2931',
        name: 'Fabrication équipements électriques et électroniques automobiles',
        section: 'Section C - Industrie Manufacturière',
        category: '29-Industrie automobile',
    },
    {
        code: '2931Z',
        name: 'Fabrication équipements électriques et électroniques automobiles',
        section: 'Section C - Industrie Manufacturière',
        category: '29-Industrie automobile',
    },
    {
        code: '2932',
        name: "Fabrication d'autres équipements automobiles",
        section: 'Section C - Industrie Manufacturière',
        category: '29-Industrie automobile',
    },
    {
        code: '2932Z',
        name: "Fabrication d'autres équipements automobiles",
        section: 'Section C - Industrie Manufacturière',
        category: '29-Industrie automobile',
    },
    {
        code: '30',
        name: "Fabrication d'autres matériels de transport",
        section: 'Section C - Industrie Manufacturière',
        category: "30-Fabrication d'autres matériels de transport",
    },
    {
        code: '301',
        name: 'Construction navale',
        section: 'Section C - Industrie Manufacturière',
        category: "30-Fabrication d'autres matériels de transport",
    },
    {
        code: '3011',
        name: 'Construction de navires et de structures flottantes',
        section: 'Section C - Industrie Manufacturière',
        category: "30-Fabrication d'autres matériels de transport",
    },
    {
        code: '3011Z',
        name: 'Construction de navires et de structures flottantes',
        section: 'Section C - Industrie Manufacturière',
        category: "30-Fabrication d'autres matériels de transport",
    },
    {
        code: '3012',
        name: 'Construction de bateaux de plaisance',
        section: 'Section C - Industrie Manufacturière',
        category: "30-Fabrication d'autres matériels de transport",
    },
    {
        code: '3012Z',
        name: 'Construction de bateaux de plaisance',
        section: 'Section C - Industrie Manufacturière',
        category: "30-Fabrication d'autres matériels de transport",
    },
    {
        code: '302',
        name: 'Construction locomotives et autre matériel ferroviaire roulant',
        section: 'Section C - Industrie Manufacturière',
        category: "30-Fabrication d'autres matériels de transport",
    },
    {
        code: '3020',
        name: 'Construction locomotives & autre matériel ferroviaire roulant',
        section: 'Section C - Industrie Manufacturière',
        category: "30-Fabrication d'autres matériels de transport",
    },
    {
        code: '3020Z',
        name: 'Construction locomotives & autre matériel ferroviaire roulant',
        section: 'Section C - Industrie Manufacturière',
        category: "30-Fabrication d'autres matériels de transport",
    },
    {
        code: '303',
        name: 'Construction aéronautique et spatiale',
        section: 'Section C - Industrie Manufacturière',
        category: "30-Fabrication d'autres matériels de transport",
    },
    {
        code: '3030',
        name: 'Construction aéronautique et spatiale',
        section: 'Section C - Industrie Manufacturière',
        category: "30-Fabrication d'autres matériels de transport",
    },
    {
        code: '3030Z',
        name: 'Construction aéronautique et spatiale',
        section: 'Section C - Industrie Manufacturière',
        category: "30-Fabrication d'autres matériels de transport",
    },
    {
        code: '304',
        name: 'Construction de véhicules militaires de combat',
        section: 'Section C - Industrie Manufacturière',
        category: "30-Fabrication d'autres matériels de transport",
    },
    {
        code: '3040',
        name: 'Construction de véhicules militaires de combat',
        section: 'Section C - Industrie Manufacturière',
        category: "30-Fabrication d'autres matériels de transport",
    },
    {
        code: '3040Z',
        name: 'Construction de véhicules militaires de combat',
        section: 'Section C - Industrie Manufacturière',
        category: "30-Fabrication d'autres matériels de transport",
    },
    {
        code: '309',
        name: 'Fabrication de matériels de transport n.c.a.',
        section: 'Section C - Industrie Manufacturière',
        category: "30-Fabrication d'autres matériels de transport",
    },
    {
        code: '3091',
        name: 'Fabrication de motocycles',
        section: 'Section C - Industrie Manufacturière',
        category: "30-Fabrication d'autres matériels de transport",
    },
    {
        code: '3091Z',
        name: 'Fabrication de motocycles',
        section: 'Section C - Industrie Manufacturière',
        category: "30-Fabrication d'autres matériels de transport",
    },
    {
        code: '3092',
        name: 'Fabrication de bicyclettes et de véhicules pour invalides',
        section: 'Section C - Industrie Manufacturière',
        category: "30-Fabrication d'autres matériels de transport",
    },
    {
        code: '3092Z',
        name: 'Fabrication de bicyclettes et de véhicules pour invalides',
        section: 'Section C - Industrie Manufacturière',
        category: "30-Fabrication d'autres matériels de transport",
    },
    {
        code: '3099',
        name: 'Fabrication d’autres équipements de transport n.c.a.',
        section: 'Section C - Industrie Manufacturière',
        category: "30-Fabrication d'autres matériels de transport",
    },
    {
        code: '3099Z',
        name: 'Fabrication d’autres équipements de transport n.c.a.',
        section: 'Section C - Industrie Manufacturière',
        category: "30-Fabrication d'autres matériels de transport",
    },
    {
        code: '31',
        name: 'Fabrication de meubles',
        section: 'Section C - Industrie Manufacturière',
        category: '31-Fabrication de meubles',
    },
    {
        code: '310',
        name: 'Fabrication de meubles',
        section: 'Section C - Industrie Manufacturière',
        category: '31-Fabrication de meubles',
    },
    {
        code: '3101',
        name: 'Fabrication de meubles de bureau et de magasin',
        section: 'Section C - Industrie Manufacturière',
        category: '31-Fabrication de meubles',
    },
    {
        code: '3101Z',
        name: 'Fabrication de meubles de bureau et de magasin',
        section: 'Section C - Industrie Manufacturière',
        category: '31-Fabrication de meubles',
    },
    {
        code: '3102',
        name: 'Fabrication de meubles de cuisine',
        section: 'Section C - Industrie Manufacturière',
        category: '31-Fabrication de meubles',
    },
    {
        code: '3102Z',
        name: 'Fabrication de meubles de cuisine',
        section: 'Section C - Industrie Manufacturière',
        category: '31-Fabrication de meubles',
    },
    {
        code: '3103',
        name: 'Fabrication de matelas',
        section: 'Section C - Industrie Manufacturière',
        category: '31-Fabrication de meubles',
    },
    {
        code: '3103Z',
        name: 'Fabrication de matelas',
        section: 'Section C - Industrie Manufacturière',
        category: '31-Fabrication de meubles',
    },
    {
        code: '3109',
        name: "Fabrication d'autres meubles",
        section: 'Section C - Industrie Manufacturière',
        category: '31-Fabrication de meubles',
    },
    {
        code: '3109A',
        name: "Fabrication de sièges d'ameublement d'intérieur",
        section: 'Section C - Industrie Manufacturière',
        category: '31-Fabrication de meubles',
    },
    {
        code: '3109B',
        name: "Fabrication autres meubles & industries connexes de l'ameublement",
        section: 'Section C - Industrie Manufacturière',
        category: '31-Fabrication de meubles',
    },
    {
        code: '32',
        name: 'Autres industries manufacturières',
        section: 'Section C - Industrie Manufacturière',
        category: '32-Autres industries manufacturières',
    },
    {
        code: '321',
        name: 'Fabrication articles joaillerie, bijouterie & articles similaires',
        section: 'Section C - Industrie Manufacturière',
        category: '32-Autres industries manufacturières',
    },
    {
        code: '3211',
        name: 'Frappe de monnaie',
        section: 'Section C - Industrie Manufacturière',
        category: '32-Autres industries manufacturières',
    },
    {
        code: '3211Z',
        name: 'Frappe de monnaie',
        section: 'Section C - Industrie Manufacturière',
        category: '32-Autres industries manufacturières',
    },
    {
        code: '3212',
        name: 'Fabrication d’articles de joaillerie et bijouterie',
        section: 'Section C - Industrie Manufacturière',
        category: '32-Autres industries manufacturières',
    },
    {
        code: '3212Z',
        name: 'Fabrication d’articles de joaillerie et bijouterie',
        section: 'Section C - Industrie Manufacturière',
        category: '32-Autres industries manufacturières',
    },
    {
        code: '3213',
        name: 'Fabrication articles bijouterie fantaisie & articles similaires',
        section: 'Section C - Industrie Manufacturière',
        category: '32-Autres industries manufacturières',
    },
    {
        code: '3213Z',
        name: 'Fabrication articles bijouterie fantaisie & articles similaires',
        section: 'Section C - Industrie Manufacturière',
        category: '32-Autres industries manufacturières',
    },
    {
        code: '322',
        name: "Fabrication d'instruments de musique",
        section: 'Section C - Industrie Manufacturière',
        category: '32-Autres industries manufacturières',
    },
    {
        code: '3220',
        name: "Fabrication d'instruments de musique",
        section: 'Section C - Industrie Manufacturière',
        category: '32-Autres industries manufacturières',
    },
    {
        code: '3220Z',
        name: "Fabrication d'instruments de musique",
        section: 'Section C - Industrie Manufacturière',
        category: '32-Autres industries manufacturières',
    },
    {
        code: '323',
        name: "Fabrication d'articles de sport",
        section: 'Section C - Industrie Manufacturière',
        category: '32-Autres industries manufacturières',
    },
    {
        code: '3230',
        name: "Fabrication d'articles de sport",
        section: 'Section C - Industrie Manufacturière',
        category: '32-Autres industries manufacturières',
    },
    {
        code: '3230Z',
        name: "Fabrication d'articles de sport",
        section: 'Section C - Industrie Manufacturière',
        category: '32-Autres industries manufacturières',
    },
    {
        code: '324',
        name: 'Fabrication de jeux et jouets',
        section: 'Section C - Industrie Manufacturière',
        category: '32-Autres industries manufacturières',
    },
    {
        code: '3240',
        name: 'Fabrication de jeux et jouets',
        section: 'Section C - Industrie Manufacturière',
        category: '32-Autres industries manufacturières',
    },
    {
        code: '3240Z',
        name: 'Fabrication de jeux et jouets',
        section: 'Section C - Industrie Manufacturière',
        category: '32-Autres industries manufacturières',
    },
    {
        code: '325',
        name: 'Fabrication instruments & fournitures à usage médical & dentaire',
        section: 'Section C - Industrie Manufacturière',
        category: '32-Autres industries manufacturières',
    },
    {
        code: '3250',
        name: 'Fabrication instruments & fournitures à usage médical & dentaire',
        section: 'Section C - Industrie Manufacturière',
        category: '32-Autres industries manufacturières',
    },
    {
        code: '3250A',
        name: 'Fabrication de matériel médico-chirurgical et dentaire',
        section: 'Section C - Industrie Manufacturière',
        category: '32-Autres industries manufacturières',
    },
    {
        code: '3250B',
        name: 'Fabrication de lunettes',
        section: 'Section C - Industrie Manufacturière',
        category: '32-Autres industries manufacturières',
    },
    {
        code: '329',
        name: 'Activités manufacturières n.c.a.',
        section: 'Section C - Industrie Manufacturière',
        category: '32-Autres industries manufacturières',
    },
    {
        code: '3291',
        name: 'Fabrication d’articles de brosserie',
        section: 'Section C - Industrie Manufacturière',
        category: '32-Autres industries manufacturières',
    },
    {
        code: '3291Z',
        name: 'Fabrication d’articles de brosserie',
        section: 'Section C - Industrie Manufacturière',
        category: '32-Autres industries manufacturières',
    },
    {
        code: '3299',
        name: 'Autres activités manufacturières n.c.a.',
        section: 'Section C - Industrie Manufacturière',
        category: '32-Autres industries manufacturières',
    },
    {
        code: '3299Z',
        name: 'Autres activités manufacturières n.c.a.',
        section: 'Section C - Industrie Manufacturière',
        category: '32-Autres industries manufacturières',
    },
    {
        code: '33',
        name: "Réparation et installation de machines et d'équipements",
        section: 'Section C - Industrie Manufacturière',
        category: "33-Réparation et installation de machines et d'équipements",
    },
    {
        code: '331',
        name: "Réparation d'ouvrages en métaux, de machines et d'équipements",
        section: 'Section C - Industrie Manufacturière',
        category: "33-Réparation et installation de machines et d'équipements",
    },
    {
        code: '3311',
        name: "Réparation d'ouvrages en métaux",
        section: 'Section C - Industrie Manufacturière',
        category: "33-Réparation et installation de machines et d'équipements",
    },
    {
        code: '3311Z',
        name: "Réparation d'ouvrages en métaux",
        section: 'Section C - Industrie Manufacturière',
        category: "33-Réparation et installation de machines et d'équipements",
    },
    {
        code: '3312',
        name: 'Réparation de machines et équipements mécaniques',
        section: 'Section C - Industrie Manufacturière',
        category: "33-Réparation et installation de machines et d'équipements",
    },
    {
        code: '3312Z',
        name: 'Réparation de machines et équipements mécaniques',
        section: 'Section C - Industrie Manufacturière',
        category: "33-Réparation et installation de machines et d'équipements",
    },
    {
        code: '3313',
        name: 'Réparation de matériels électroniques et optiques',
        section: 'Section C - Industrie Manufacturière',
        category: "33-Réparation et installation de machines et d'équipements",
    },
    {
        code: '3313Z',
        name: 'Réparation de matériels électroniques et optiques',
        section: 'Section C - Industrie Manufacturière',
        category: "33-Réparation et installation de machines et d'équipements",
    },
    {
        code: '3314',
        name: "Réparation d'équipements électriques",
        section: 'Section C - Industrie Manufacturière',
        category: "33-Réparation et installation de machines et d'équipements",
    },
    {
        code: '3314Z',
        name: "Réparation d'équipements électriques",
        section: 'Section C - Industrie Manufacturière',
        category: "33-Réparation et installation de machines et d'équipements",
    },
    {
        code: '3315',
        name: 'Réparation et maintenance navale',
        section: 'Section C - Industrie Manufacturière',
        category: "33-Réparation et installation de machines et d'équipements",
    },
    {
        code: '3315Z',
        name: 'Réparation et maintenance navale',
        section: 'Section C - Industrie Manufacturière',
        category: "33-Réparation et installation de machines et d'équipements",
    },
    {
        code: '3316',
        name: "Réparation et maintenance d'aéronefs et d'engins spatiaux",
        section: 'Section C - Industrie Manufacturière',
        category: "33-Réparation et installation de machines et d'équipements",
    },
    {
        code: '3316Z',
        name: "Réparation et maintenance d'aéronefs et d'engins spatiaux",
        section: 'Section C - Industrie Manufacturière',
        category: "33-Réparation et installation de machines et d'équipements",
    },
    {
        code: '3317',
        name: "Réparation et maintenance d'autres équipements de transport",
        section: 'Section C - Industrie Manufacturière',
        category: "33-Réparation et installation de machines et d'équipements",
    },
    {
        code: '3317Z',
        name: "Réparation et maintenance d'autres équipements de transport",
        section: 'Section C - Industrie Manufacturière',
        category: "33-Réparation et installation de machines et d'équipements",
    },
    {
        code: '3319',
        name: "Réparation d'autres équipements",
        section: 'Section C - Industrie Manufacturière',
        category: "33-Réparation et installation de machines et d'équipements",
    },
    {
        code: '3319Z',
        name: "Réparation d'autres équipements",
        section: 'Section C - Industrie Manufacturière',
        category: "33-Réparation et installation de machines et d'équipements",
    },
    {
        code: '332',
        name: "Installation de machines et d'équipements industriels",
        section: 'Section C - Industrie Manufacturière',
        category: "33-Réparation et installation de machines et d'équipements",
    },
    {
        code: '3320',
        name: "Installation de machines et d'équipements industriels",
        section: 'Section C - Industrie Manufacturière',
        category: "33-Réparation et installation de machines et d'équipements",
    },
    {
        code: '3320A',
        name: 'Installation structures métalliques, chaudronnées et tuyauterie',
        section: 'Section C - Industrie Manufacturière',
        category: "33-Réparation et installation de machines et d'équipements",
    },
    {
        code: '3320B',
        name: 'Installation de machines et équipements mécaniques',
        section: 'Section C - Industrie Manufacturière',
        category: "33-Réparation et installation de machines et d'équipements",
    },
    {
        code: '3320C',
        name: "Concept. d'ens. & assembl s/site d'éqpts ctrle des processus ind.",
        section: 'Section C - Industrie Manufacturière',
        category: "33-Réparation et installation de machines et d'équipements",
    },
    {
        code: '3320D',
        name: 'Instal. éqpts électriq, mat. électro. et optiq. ou aut. matériels',
        section: 'Section C - Industrie Manufacturière',
        category: "33-Réparation et installation de machines et d'équipements",
    },
    {
        code: '35',
        name: 'Prod. & distribution électricité gaz vapeur & air conditionné',
        section: 'Section D - Prdn & distr. élec. gaz vap. & air cond.',
        category: '35-Prod. & distribution électricité gaz vapeur & air conditionné',
    },
    {
        code: '351',
        name: "Production, transport et distribution d'électricité",
        section: 'Section D - Prdn & distr. élec. gaz vap. & air cond.',
        category: '35-Prod. & distribution électricité gaz vapeur & air conditionné',
    },
    {
        code: '3511',
        name: "Production d'électricité",
        section: 'Section D - Prdn & distr. élec. gaz vap. & air cond.',
        category: '35-Prod. & distribution électricité gaz vapeur & air conditionné',
    },
    {
        code: '3511Z',
        name: "Production d'électricité",
        section: 'Section D - Prdn & distr. élec. gaz vap. & air cond.',
        category: '35-Prod. & distribution électricité gaz vapeur & air conditionné',
    },
    {
        code: '3512',
        name: "Transport d'électricité",
        section: 'Section D - Prdn & distr. élec. gaz vap. & air cond.',
        category: '35-Prod. & distribution électricité gaz vapeur & air conditionné',
    },
    {
        code: '3512Z',
        name: "Transport d'électricité",
        section: 'Section D - Prdn & distr. élec. gaz vap. & air cond.',
        category: '35-Prod. & distribution électricité gaz vapeur & air conditionné',
    },
    {
        code: '3513',
        name: "Distribution d'électricité",
        section: 'Section D - Prdn & distr. élec. gaz vap. & air cond.',
        category: '35-Prod. & distribution électricité gaz vapeur & air conditionné',
    },
    {
        code: '3513Z',
        name: "Distribution d'électricité",
        section: 'Section D - Prdn & distr. élec. gaz vap. & air cond.',
        category: '35-Prod. & distribution électricité gaz vapeur & air conditionné',
    },
    {
        code: '3514',
        name: "Commerce d'électricité",
        section: 'Section D - Prdn & distr. élec. gaz vap. & air cond.',
        category: '35-Prod. & distribution électricité gaz vapeur & air conditionné',
    },
    {
        code: '3514Z',
        name: "Commerce d'électricité",
        section: 'Section D - Prdn & distr. élec. gaz vap. & air cond.',
        category: '35-Prod. & distribution électricité gaz vapeur & air conditionné',
    },
    {
        code: '352',
        name: 'Production et distribution de combustibles gazeux',
        section: 'Section D - Prdn & distr. élec. gaz vap. & air cond.',
        category: '35-Prod. & distribution électricité gaz vapeur & air conditionné',
    },
    {
        code: '3521',
        name: 'Production de combustibles gazeux',
        section: 'Section D - Prdn & distr. élec. gaz vap. & air cond.',
        category: '35-Prod. & distribution électricité gaz vapeur & air conditionné',
    },
    {
        code: '3521Z',
        name: 'Production de combustibles gazeux',
        section: 'Section D - Prdn & distr. élec. gaz vap. & air cond.',
        category: '35-Prod. & distribution électricité gaz vapeur & air conditionné',
    },
    {
        code: '3522',
        name: 'Distribution de combustibles gazeux par conduites',
        section: 'Section D - Prdn & distr. élec. gaz vap. & air cond.',
        category: '35-Prod. & distribution électricité gaz vapeur & air conditionné',
    },
    {
        code: '3522Z',
        name: 'Distribution de combustibles gazeux par conduites',
        section: 'Section D - Prdn & distr. élec. gaz vap. & air cond.',
        category: '35-Prod. & distribution électricité gaz vapeur & air conditionné',
    },
    {
        code: '3523',
        name: 'Commerce de combustibles gazeux par conduites',
        section: 'Section D - Prdn & distr. élec. gaz vap. & air cond.',
        category: '35-Prod. & distribution électricité gaz vapeur & air conditionné',
    },
    {
        code: '3523Z',
        name: 'Commerce de combustibles gazeux par conduites',
        section: 'Section D - Prdn & distr. élec. gaz vap. & air cond.',
        category: '35-Prod. & distribution électricité gaz vapeur & air conditionné',
    },
    {
        code: '353',
        name: "Production et distribution de vapeur et d'air conditionné",
        section: 'Section D - Prdn & distr. élec. gaz vap. & air cond.',
        category: '35-Prod. & distribution électricité gaz vapeur & air conditionné',
    },
    {
        code: '3530',
        name: "Production et distribution de vapeur et d'air conditionné",
        section: 'Section D - Prdn & distr. élec. gaz vap. & air cond.',
        category: '35-Prod. & distribution électricité gaz vapeur & air conditionné',
    },
    {
        code: '3530Z',
        name: "Production et distribution de vapeur et d'air conditionné",
        section: 'Section D - Prdn & distr. élec. gaz vap. & air cond.',
        category: '35-Prod. & distribution électricité gaz vapeur & air conditionné',
    },
    {
        code: '36',
        name: "Captage, traitement et distribution d'eau",
        section: 'Section E - Gestion eau, déchets & dépollution',
        category: "36-Captage, traitement et distribution d'eau",
    },
    {
        code: '360',
        name: "Captage, traitement et distribution d'eau",
        section: 'Section E - Gestion eau, déchets & dépollution',
        category: "36-Captage, traitement et distribution d'eau",
    },
    {
        code: '3600',
        name: "Captage, traitement et distribution d'eau",
        section: 'Section E - Gestion eau, déchets & dépollution',
        category: "36-Captage, traitement et distribution d'eau",
    },
    {
        code: '3600Z',
        name: "Captage, traitement et distribution d'eau",
        section: 'Section E - Gestion eau, déchets & dépollution',
        category: "36-Captage, traitement et distribution d'eau",
    },
    {
        code: '37',
        name: 'Collecte et traitement des eaux usées',
        section: 'Section E - Gestion eau, déchets & dépollution',
        category: '37-Collecte et traitement des eaux usées',
    },
    {
        code: '370',
        name: 'Collecte et traitement des eaux usées',
        section: 'Section E - Gestion eau, déchets & dépollution',
        category: '37-Collecte et traitement des eaux usées',
    },
    {
        code: '3700',
        name: 'Collecte et traitement des eaux usées',
        section: 'Section E - Gestion eau, déchets & dépollution',
        category: '37-Collecte et traitement des eaux usées',
    },
    {
        code: '3700Z',
        name: 'Collecte et traitement des eaux usées',
        section: 'Section E - Gestion eau, déchets & dépollution',
        category: '37-Collecte et traitement des eaux usées',
    },
    {
        code: '38',
        name: 'Collecte, traitement et élimination des déchets ; récupération',
        section: 'Section E - Gestion eau, déchets & dépollution',
        category: '38-Collecte, traitement et élimination des déchets ; récupération',
    },
    {
        code: '381',
        name: 'Collecte des déchets',
        section: 'Section E - Gestion eau, déchets & dépollution',
        category: '38-Collecte, traitement et élimination des déchets ; récupération',
    },
    {
        code: '3811',
        name: 'Collecte des déchets non dangereux',
        section: 'Section E - Gestion eau, déchets & dépollution',
        category: '38-Collecte, traitement et élimination des déchets ; récupération',
    },
    {
        code: '3811Z',
        name: 'Collecte des déchets non dangereux',
        section: 'Section E - Gestion eau, déchets & dépollution',
        category: '38-Collecte, traitement et élimination des déchets ; récupération',
    },
    {
        code: '3812',
        name: 'Collecte des déchets dangereux',
        section: 'Section E - Gestion eau, déchets & dépollution',
        category: '38-Collecte, traitement et élimination des déchets ; récupération',
    },
    {
        code: '3812Z',
        name: 'Collecte des déchets dangereux',
        section: 'Section E - Gestion eau, déchets & dépollution',
        category: '38-Collecte, traitement et élimination des déchets ; récupération',
    },
    {
        code: '382',
        name: 'Traitement et élimination des déchets',
        section: 'Section E - Gestion eau, déchets & dépollution',
        category: '38-Collecte, traitement et élimination des déchets ; récupération',
    },
    {
        code: '3821',
        name: 'Traitement et élimination des déchets non dangereux',
        section: 'Section E - Gestion eau, déchets & dépollution',
        category: '38-Collecte, traitement et élimination des déchets ; récupération',
    },
    {
        code: '3821Z',
        name: 'Traitement et élimination des déchets non dangereux',
        section: 'Section E - Gestion eau, déchets & dépollution',
        category: '38-Collecte, traitement et élimination des déchets ; récupération',
    },
    {
        code: '3822',
        name: 'Traitement et élimination des déchets dangereux',
        section: 'Section E - Gestion eau, déchets & dépollution',
        category: '38-Collecte, traitement et élimination des déchets ; récupération',
    },
    {
        code: '3822Z',
        name: 'Traitement et élimination des déchets dangereux',
        section: 'Section E - Gestion eau, déchets & dépollution',
        category: '38-Collecte, traitement et élimination des déchets ; récupération',
    },
    {
        code: '383',
        name: 'Récupération',
        section: 'Section E - Gestion eau, déchets & dépollution',
        category: '38-Collecte, traitement et élimination des déchets ; récupération',
    },
    {
        code: '3831',
        name: "Démantèlement d'épaves",
        section: 'Section E - Gestion eau, déchets & dépollution',
        category: '38-Collecte, traitement et élimination des déchets ; récupération',
    },
    {
        code: '3831Z',
        name: "Démantèlement d'épaves",
        section: 'Section E - Gestion eau, déchets & dépollution',
        category: '38-Collecte, traitement et élimination des déchets ; récupération',
    },
    {
        code: '3832',
        name: 'Récupération de déchets triés',
        section: 'Section E - Gestion eau, déchets & dépollution',
        category: '38-Collecte, traitement et élimination des déchets ; récupération',
    },
    {
        code: '3832Z',
        name: 'Récupération de déchets triés',
        section: 'Section E - Gestion eau, déchets & dépollution',
        category: '38-Collecte, traitement et élimination des déchets ; récupération',
    },
    {
        code: '39',
        name: 'Dépollution et autres services de gestion des déchets',
        section: 'Section E - Gestion eau, déchets & dépollution',
        category: '39-Dépollution et autres services de gestion des déchets',
    },
    {
        code: '390',
        name: 'Dépollution et autres services de gestion des déchets',
        section: 'Section E - Gestion eau, déchets & dépollution',
        category: '39-Dépollution et autres services de gestion des déchets',
    },
    {
        code: '3900',
        name: 'Dépollution et autres services de gestion des déchets',
        section: 'Section E - Gestion eau, déchets & dépollution',
        category: '39-Dépollution et autres services de gestion des déchets',
    },
    {
        code: '3900Z',
        name: 'Dépollution et autres services de gestion des déchets',
        section: 'Section E - Gestion eau, déchets & dépollution',
        category: '39-Dépollution et autres services de gestion des déchets',
    },
    {
        code: '41',
        name: 'Construction de bâtiments',
        section: 'Section F - Construction',
        category: '41-Construction de bâtiments',
    },
    {
        code: '411',
        name: 'Promotion immobilière',
        section: 'Section F - Construction',
        category: '41-Construction de bâtiments',
    },
    {
        code: '4110',
        name: 'Promotion immobilière',
        section: 'Section F - Construction',
        category: '41-Construction de bâtiments',
    },
    {
        code: '4110A',
        name: 'Promotion immobilière de logements',
        section: 'Section F - Construction',
        category: '41-Construction de bâtiments',
    },
    {
        code: '4110B',
        name: 'Promotion immobilière de bureaux',
        section: 'Section F - Construction',
        category: '41-Construction de bâtiments',
    },
    {
        code: '4110C',
        name: "Promotion immobilière d'autres bâtiments",
        section: 'Section F - Construction',
        category: '41-Construction de bâtiments',
    },
    {
        code: '4110D',
        name: 'Supports juridiques de programmes',
        section: 'Section F - Construction',
        category: '41-Construction de bâtiments',
    },
    {
        code: '412',
        name: 'Construction de bâtiments résidentiels et non résidentiels',
        section: 'Section F - Construction',
        category: '41-Construction de bâtiments',
    },
    {
        code: '4120',
        name: 'Construction de bâtiments résidentiels et non résidentiels',
        section: 'Section F - Construction',
        category: '41-Construction de bâtiments',
    },
    {
        code: '4120A',
        name: 'Construction de maisons individuelles',
        section: 'Section F - Construction',
        category: '41-Construction de bâtiments',
    },
    {
        code: '4120B',
        name: "Construction d'autres bâtiments",
        section: 'Section F - Construction',
        category: '41-Construction de bâtiments',
    },
    {
        code: '42',
        name: 'Génie civil',
        section: 'Section F - Construction',
        category: '42-Génie civil',
    },
    {
        code: '421',
        name: 'Construction de routes et de voies ferrées',
        section: 'Section F - Construction',
        category: '42-Génie civil',
    },
    {
        code: '4211',
        name: 'Construction de routes et autoroutes',
        section: 'Section F - Construction',
        category: '42-Génie civil',
    },
    {
        code: '4211Z',
        name: 'Construction de routes et autoroutes',
        section: 'Section F - Construction',
        category: '42-Génie civil',
    },
    {
        code: '4212',
        name: 'Construction de voies ferrées de surface et souterraines',
        section: 'Section F - Construction',
        category: '42-Génie civil',
    },
    {
        code: '4212Z',
        name: 'Construction de voies ferrées de surface et souterraines',
        section: 'Section F - Construction',
        category: '42-Génie civil',
    },
    {
        code: '4213',
        name: 'Construction de ponts et tunnels',
        section: 'Section F - Construction',
        category: '42-Génie civil',
    },
    {
        code: '4213A',
        name: "Construction d'ouvrages d'art",
        section: 'Section F - Construction',
        category: '42-Génie civil',
    },
    {
        code: '4213B',
        name: 'Construction et entretien de tunnels',
        section: 'Section F - Construction',
        category: '42-Génie civil',
    },
    {
        code: '422',
        name: 'Construction de réseaux et de lignes',
        section: 'Section F - Construction',
        category: '42-Génie civil',
    },
    {
        code: '4221',
        name: 'Construction de réseaux pour fluides',
        section: 'Section F - Construction',
        category: '42-Génie civil',
    },
    {
        code: '4221Z',
        name: 'Construction de réseaux pour fluides',
        section: 'Section F - Construction',
        category: '42-Génie civil',
    },
    {
        code: '4222',
        name: 'Construction de réseaux électriques et de télécommunications',
        section: 'Section F - Construction',
        category: '42-Génie civil',
    },
    {
        code: '4222Z',
        name: 'Construction de réseaux électriques et de télécommunications',
        section: 'Section F - Construction',
        category: '42-Génie civil',
    },
    {
        code: '429',
        name: "Construction d'autres ouvrages de génie civil",
        section: 'Section F - Construction',
        category: '42-Génie civil',
    },
    {
        code: '4291',
        name: "Construction d'ouvrages maritimes et fluviaux",
        section: 'Section F - Construction',
        category: '42-Génie civil',
    },
    {
        code: '4291Z',
        name: "Construction d'ouvrages maritimes et fluviaux",
        section: 'Section F - Construction',
        category: '42-Génie civil',
    },
    {
        code: '4299',
        name: "Construction d'autres ouvrages de génie civil n.c.a.",
        section: 'Section F - Construction',
        category: '42-Génie civil',
    },
    {
        code: '4299Z',
        name: "Construction d'autres ouvrages de génie civil n.c.a.",
        section: 'Section F - Construction',
        category: '42-Génie civil',
    },
    {
        code: '43',
        name: 'Travaux de construction spécialisés',
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '431',
        name: 'Démolition et préparation des sites',
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '4311',
        name: 'Travaux de démolition',
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '4311Z',
        name: 'Travaux de démolition',
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '4312',
        name: 'Travaux de préparation des sites',
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '4312A',
        name: 'Travaux de terrassement courants et travaux préparatoires',
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '4312B',
        name: 'Travaux de terrassement spécialisés ou de grande masse',
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '4313',
        name: 'Forages et sondages',
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '4313Z',
        name: 'Forages et sondages',
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '432',
        name: "Travaux install. électrique plomberie & autres travaux d'install.",
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '4321',
        name: 'Installation électrique',
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '4321A',
        name: "Travaux d'installation électrique dans tous locaux",
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '4321B',
        name: "Travaux d'installation électrique sur la voie publique",
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '4322',
        name: "Travaux plomberie, install. chauffage & conditionnement d'air",
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '4322A',
        name: "Travaux d'installation d'eau et de gaz en tous locaux",
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '4322B',
        name: "Travaux d'installation équipements thermiques et climatisation",
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '4329',
        name: "Autres travaux d'installation",
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '4329A',
        name: "Travaux d'isolation",
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '4329B',
        name: "Autres travaux d'installation n.c.a.",
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '433',
        name: 'Travaux de finition',
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '4331',
        name: 'Travaux de plâtrerie',
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '4331Z',
        name: 'Travaux de plâtrerie',
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '4332',
        name: 'Travaux de menuiserie',
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '4332A',
        name: 'Travaux de menuiserie bois et PVC',
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '4332B',
        name: 'Travaux de menuiserie métallique et serrurerie',
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '4332C',
        name: 'Agencement de lieux de vente',
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '4333',
        name: 'Travaux de revêtement des sols et des murs',
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '4333Z',
        name: 'Travaux de revêtement des sols et des murs',
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '4334',
        name: 'Travaux de peinture et vitrerie',
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '4334Z',
        name: 'Travaux de peinture et vitrerie',
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '4339',
        name: 'Autres travaux de finition',
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '4339Z',
        name: 'Autres travaux de finition',
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '439',
        name: 'Autres travaux de construction spécialisés',
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '4391',
        name: 'Travaux de couverture',
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '4391A',
        name: 'Travaux de charpente',
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '4391B',
        name: 'Travaux de couverture par éléments',
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '4399',
        name: 'Autres travaux de construction spécialisés n.c.a.',
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '4399A',
        name: "Travaux d'étanchéification",
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '4399B',
        name: 'Travaux de montage de structures métalliques',
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '4399C',
        name: 'Travaux de maçonnerie générale et gros oeuvre de bâtiment',
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '4399D',
        name: 'Autres travaux spécialisés de construction',
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '4399E',
        name: 'Location avec opérateur de matériel de construction',
        section: 'Section F - Construction',
        category: '43-Travaux de construction spécialisés',
    },
    {
        code: '45',
        name: "Commerce et réparation d'automobiles et de motocycles",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: "45-Commerce et réparation d'automobiles et de motocycles",
    },
    {
        code: '451',
        name: 'Commerce de véhicules automobiles',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: "45-Commerce et réparation d'automobiles et de motocycles",
    },
    {
        code: '4511',
        name: 'Commerce de voitures et de véhicules automobiles légers',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: "45-Commerce et réparation d'automobiles et de motocycles",
    },
    {
        code: '4511Z',
        name: 'Commerce de voitures et de véhicules automobiles légers',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: "45-Commerce et réparation d'automobiles et de motocycles",
    },
    {
        code: '4519',
        name: "Commerce d'autres véhicules automobiles",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: "45-Commerce et réparation d'automobiles et de motocycles",
    },
    {
        code: '4519Z',
        name: "Commerce d'autres véhicules automobiles",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: "45-Commerce et réparation d'automobiles et de motocycles",
    },
    {
        code: '452',
        name: 'Entretien et réparation de véhicules automobiles',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: "45-Commerce et réparation d'automobiles et de motocycles",
    },
    {
        code: '4520',
        name: 'Entretien et réparation de véhicules automobiles',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: "45-Commerce et réparation d'automobiles et de motocycles",
    },
    {
        code: '4520A',
        name: 'Entretien et réparation de véhicules automobiles légers',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: "45-Commerce et réparation d'automobiles et de motocycles",
    },
    {
        code: '4520B',
        name: "Entretien et réparation d'autres véhicules automobiles",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: "45-Commerce et réparation d'automobiles et de motocycles",
    },
    {
        code: '453',
        name: "Commerce d'équipements automobiles",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: "45-Commerce et réparation d'automobiles et de motocycles",
    },
    {
        code: '4531',
        name: "Commerce de gros d'équipements automobiles",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: "45-Commerce et réparation d'automobiles et de motocycles",
    },
    {
        code: '4531Z',
        name: "Commerce de gros d'équipements automobiles",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: "45-Commerce et réparation d'automobiles et de motocycles",
    },
    {
        code: '4532',
        name: "Commerce de détail d'équipements automobiles",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: "45-Commerce et réparation d'automobiles et de motocycles",
    },
    {
        code: '4532Z',
        name: "Commerce de détail d'équipements automobiles",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: "45-Commerce et réparation d'automobiles et de motocycles",
    },
    {
        code: '454',
        name: 'Commerce et réparation de motocycles',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: "45-Commerce et réparation d'automobiles et de motocycles",
    },
    {
        code: '4540',
        name: 'Commerce et réparation de motocycles',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: "45-Commerce et réparation d'automobiles et de motocycles",
    },
    {
        code: '4540Z',
        name: 'Commerce et réparation de motocycles',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: "45-Commerce et réparation d'automobiles et de motocycles",
    },
    {
        code: '46',
        name: 'Commerce de gros, à l’exception des automobiles et des motocycles',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '461',
        name: 'Intermédiaires du commerce de gros',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4611',
        name: 'Interm. du comm. en m.p. agricoles & textiles, animaux vivants',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4611Z',
        name: 'Interm. du comm. en m.p. agricoles & textiles, animaux vivants',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4612',
        name: 'Interm. comm. combustibles métaux minéraux & produits chimiques',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4612A',
        name: "Centrales d'achat de carburant",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4612B',
        name: 'Autres interm. comm. combustibles métaux minéraux prod. chimiques',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4613',
        name: 'Intermédiaires du commerce en bois et matériaux de construction',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4613Z',
        name: 'Intermédiaires du commerce en bois et matériaux de construction',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4614',
        name: 'Interm. comm. machines, équipts industriels, navires et avions',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4614Z',
        name: 'Interm. comm. machines, équipts industriels, navires et avions',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4615',
        name: 'Interm. comm. en meubles, articles de ménage et quincaillerie',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4615Z',
        name: 'Interm. comm. en meubles, articles de ménage et quincaillerie',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4616',
        name: 'Interm. comm. textiles, habillt, fourrures, chaussures & art cuir',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4616Z',
        name: 'Interm. comm. textiles, habillt, fourrures, chaussures & art cuir',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4617',
        name: 'Intermédiaires du commerce en denrées, boissons et tabac',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4617A',
        name: "Centrales d'achat alimentaires",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4617B',
        name: 'Autres intermédiaires du commerce en denrées, boissons et tabac',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4618',
        name: "Interm. spécialisés commerce d'autres produits spécifiques",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4618Z',
        name: "Interm. spécialisés commerce d'autres produits spécifiques",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4619',
        name: 'Intermédiaires du commerce en produits divers',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4619A',
        name: "Centrales d'achat non alimentaires",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4619B',
        name: 'Autres intermédiaires du commerce en produits divers',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '462',
        name: "Commerce de gros de produits agricoles bruts et d'animaux vivants",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4621',
        name: 'Comm. de gros céréales, tabac non manuf. et aliments pour bétail',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4621Z',
        name: 'Comm. de gros céréales, tabac non manuf. et aliments pour bétail',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4622',
        name: 'Commerce de gros de fleurs et plantes',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4622Z',
        name: 'Commerce de gros (commerce interentreprises) de fleurs et plantes',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4623',
        name: "Commerce de gros d'animaux vivants",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4623Z',
        name: "Commerce de gros (commerce interentreprises) d'animaux vivants",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4624',
        name: 'Commerce de gros de cuirs et peaux',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4624Z',
        name: 'Commerce de gros (commerce interentreprises) de cuirs et peaux',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '463',
        name: 'Comm. gros produits alimentaires boissons & tabac',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4631',
        name: 'Commerce de gros de fruits et légumes',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4631Z',
        name: 'Commerce de gros (commerce interentreprises) de fruits et légumes',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4632',
        name: 'Commerce de gros de viandes et de produits à base de viande',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4632A',
        name: 'Commerce de gros de viandes de boucherie',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4632B',
        name: 'Commerce de gros de produits à base de viande',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4632C',
        name: 'Commerce de gros de volailles et gibier',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4633',
        name: 'Com. gros produits laitiers, oeufs, huiles & mat. grasses comest.',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4633Z',
        name: 'Com. gros produits laitiers, oeufs, huiles & mat. grasses comest.',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4634',
        name: 'Commerce de gros de boissons',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4634Z',
        name: 'Commerce de gros (commerce interentreprises) de boissons',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4635',
        name: 'Commerce de gros de produits à base de tabac',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4635Z',
        name: 'Commerce de gros de produits à base de tabac',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4636',
        name: 'Commerce de gros de sucre, chocolat et confiserie',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4636Z',
        name: 'Commerce de gros de sucre, chocolat et confiserie',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4637',
        name: 'Commerce de gros de café, thé, cacao et épices',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4637Z',
        name: 'Commerce de gros de café, thé, cacao et épices',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4638',
        name: 'Comm. gros autres prod. alim. yc poissons crustacés mollusques',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4638A',
        name: 'Commerce de gros de poissons, crustacés et mollusques',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4638B',
        name: 'Commerce de gros alimentaire spécialisé divers',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4639',
        name: 'Commerce de gros non spécialisé de denrées, boissons et tabac',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4639A',
        name: 'Commerce de gros (commerce interentreprises) de produits surgelés',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4639B',
        name: 'Commerce de gros alimentaire non spécialisé',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '464',
        name: 'Commerce de gros de biens domestiques',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4641',
        name: 'Commerce de gros de textiles',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4641Z',
        name: 'Commerce de gros (commerce interentreprises) de textiles',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4642',
        name: "Commerce de gros d'habillement et de chaussures",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4642Z',
        name: "Commerce de gros d'habillement et de chaussures",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4643',
        name: "Commerce de gros d'appareils électroménagers",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4643Z',
        name: "Commerce de gros d'appareils électroménagers",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4644',
        name: "Commerce de gros de vaisselle, verrerie et produits d'entretien",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4644Z',
        name: "Commerce de gros de vaisselle, verrerie et produits d'entretien",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4645',
        name: 'Commerce de gros de parfumerie et de produits de beauté',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4645Z',
        name: 'Commerce de gros de parfumerie et de produits de beauté',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4646',
        name: 'Commerce de gros de produits pharmaceutiques',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4646Z',
        name: 'Commerce de gros de produits pharmaceutiques',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4647',
        name: "Commerce de gros de meubles, de tapis et d'appareils d'éclairage",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4647Z',
        name: "Commerce de gros de meubles, de tapis et d'appareils d'éclairage",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4648',
        name: "Commerce de gros d'articles d'horlogerie et de bijouterie",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4648Z',
        name: "Commerce de gros d'articles d'horlogerie et de bijouterie",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4649',
        name: "Commerce de gros d'autres biens domestiques",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4649Z',
        name: "Commerce de gros d'autres biens domestiques",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '465',
        name: "Comm. gros équipts de l'information et de la communication",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4651',
        name: "Comm. de gros d'ordinat., d'éqpts informatiq. périph. & logiciels",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4651Z',
        name: "Comm. de gros d'ordinat., d'éqpts informatiq. périph. & logiciels",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4652',
        name: "Comm. de gros d'éqpts et composants électroniques et de télécomm.",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4652Z',
        name: "Comm. de gros d'éqpts et composants électroniques et de télécomm.",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '466',
        name: "Commerce de gros d'autres équipements industriels",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4661',
        name: 'Commerce de gros de matériel agricole',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4661Z',
        name: 'Commerce de gros (commerce interentreprises) de matériel agricole',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4662',
        name: 'Commerce de gros de machines-outils',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4662Z',
        name: 'Commerce de gros (commerce interentreprises) de machines-outils',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4663',
        name: "Comm de gros de machines pour l'extrac, la constr, le génie civil",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4663Z',
        name: "Comm de gros de machines pour l'extrac, la constr, le génie civil",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4664',
        name: 'Comm. gros (interentr.) machines pour industrie textile & habill.',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4664Z',
        name: 'Comm. gros (interentr.) machines pour industrie textile & habill.',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4665',
        name: 'Commerce de gros de mobilier de bureau',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4665Z',
        name: 'Commerce de gros de mobilier de bureau',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4666',
        name: "Commerce de gros d'autres machines et équipements de bureau",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4666Z',
        name: "Commerce de gros d'autres machines et équipements de bureau",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4669',
        name: "Commerce de gros d'autres machines et équipements",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4669A',
        name: 'Commerce de gros de matériel électrique',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4669B',
        name: 'Commerce de gros de fournitures et équipements industriels divers',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4669C',
        name: 'Comm. gros de fournitures & équipts divers pour commerces & sces',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '467',
        name: 'Autres commerces de gros spécialisés',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4671',
        name: 'Commerce de gros de combustibles et de produits annexes',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4671Z',
        name: 'Commerce de gros de combustibles et de produits annexes',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4672',
        name: 'Commerce de gros de minerais et métaux',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4672Z',
        name: 'Commerce de gros de minerais et métaux',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4673',
        name: 'Comm. gros bois, matériaux de construction & appareils sanitaires',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4673A',
        name: 'Commerce de gros de bois et de matériaux de construction',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4673B',
        name: "Commerce de gros d'appareils sanitaires et produits de décoration",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4674',
        name: 'Comm. gros quincaillerie & fournitures pour plomberie & chauffage',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4674A',
        name: 'Commerce de gros (commerce interentreprises) de quincaillerie',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4674B',
        name: 'Commerce de gros de fournitures pour la plomberie et le chauffage',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4675',
        name: 'Commerce de gros de produits chimiques',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4675Z',
        name: 'Commerce de gros de produits chimiques',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4676',
        name: "Commerce de gros d'autres produits intermédiaires",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4676Z',
        name: "Commerce de gros d'autres produits intermédiaires",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4677',
        name: 'Commerce de gros de déchets et débris',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4677Z',
        name: 'Commerce de gros (commerce interentreprises) de déchets et débris',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '469',
        name: 'Commerce de gros non spécialisé',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4690',
        name: 'Commerce de gros non spécialisé',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '4690Z',
        name: 'Commerce de gros (commerce interentreprises) non spécialisé',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '46-Commerce de gros, à l’exception des automobiles et des motocycles',
    },
    {
        code: '47',
        name: 'Comm. détail, sf automobiles et motocycles',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '471',
        name: 'Commerce de détail en magasin non spécialisé',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4711',
        name: 'Comm. détail en magasin non spécialisé à prédominance alimentaire',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4711A',
        name: 'Commerce de détail de produits surgelés',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4711B',
        name: "Commerce d'alimentation générale",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4711C',
        name: 'Supérettes',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4711D',
        name: 'Supermarchés',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4711E',
        name: 'Magasins multi-commerces',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4711F',
        name: 'Hypermarchés',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4719',
        name: 'Autre commerce de détail en magasin non spécialisé',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4719A',
        name: 'Grands magasins',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4719B',
        name: 'Autres commerces de détail en magasin non spécialisé',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '472',
        name: 'Commerce de détail alimentaire en magasin spécialisé',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4721',
        name: 'Commerce de détail de fruits et légumes en magasin spécialisé',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4721Z',
        name: 'Commerce de détail de fruits et légumes en magasin spécialisé',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4722',
        name: 'Comm. détail viandes & produits à base de viande (magas. spéc.)',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4722Z',
        name: 'Comm. détail viandes & produits à base de viande (magas. spéc.)',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4723',
        name: 'Comm. détail poissons crustacés & mollusques (magasin spécialisé)',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4723Z',
        name: 'Comm. détail poissons crustacés & mollusques (magasin spécialisé)',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4724',
        name: 'Comm. détail pain pâtisserie & confiserie (magasin spécialisé)',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4724Z',
        name: 'Comm. détail pain pâtisserie & confiserie (magasin spécialisé)',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4725',
        name: 'Commerce de détail de boissons en magasin spécialisé',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4725Z',
        name: 'Commerce de détail de boissons en magasin spécialisé',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4726',
        name: 'Comm. détail de produits à base de tabac en magasin spécialisé',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4726Z',
        name: 'Comm. détail de produits à base de tabac en magasin spécialisé',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4729',
        name: 'Autres commerces de détail alimentaires en magasin spécialisé',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4729Z',
        name: 'Autres commerces de détail alimentaires en magasin spécialisé',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '473',
        name: 'Commerce de détail de carburants en magasin spécialisé',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4730',
        name: 'Commerce de détail de carburants en magasin spécialisé',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4730Z',
        name: 'Commerce de détail de carburants en magasin spécialisé',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '474',
        name: "Comm. détail équipts de l'information & communic. (magas. spéc.)",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4741',
        name: 'Comm. détail ordinateurs unités périph. & logiciels (magas. spéc)',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4741Z',
        name: 'Comm. détail ordinateurs unités périph. & logiciels (magas. spéc)',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4742',
        name: 'Comm. détail matériels télécommunication (magasin spécialisé)',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4742Z',
        name: 'Comm. détail matériels télécommunication (magasin spécialisé)',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4743',
        name: 'Commerce de détail de matériels audio/vidéo en magasin spécialisé',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4743Z',
        name: 'Commerce de détail de matériels audio/vidéo en magasin spécialisé',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '475',
        name: 'Comm. détail autres équipts du foyer (magasin spécialisé)',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4751',
        name: 'Commerce de détail de textiles en magasin spécialisé',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4751Z',
        name: 'Commerce de détail de textiles en magasin spécialisé',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4752',
        name: 'Comm. détail quincaillerie peintures & verres (magasin spéc.)',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4752A',
        name: 'Comm. détail de quincaillerie, peintures et verres (mag.< 400 m²)',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4752B',
        name: 'Comm. détail de quincaillerie, peintures et verres (mag.> 400 m²)',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4753',
        name: 'Comm. détail tapis, moquettes & revêts murs & sols (magas. spéc.)',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4753Z',
        name: 'Comm. détail tapis, moquettes & revêts murs & sols (magas. spéc.)',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4754',
        name: 'Commerce de détail appareils électroménagers (magasin spécialisé)',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4754Z',
        name: 'Commerce de détail appareils électroménagers (magasin spécialisé)',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4759',
        name: 'Comm. détail meubles appareils éclairage & autres art. ménage',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4759A',
        name: 'Commerce de détail de meubles',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4759B',
        name: "Commerce de détail d'autres équipements du foyer",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '476',
        name: 'Comm. détail biens culturels & loisirs (magasin spécialisé)',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4761',
        name: 'Commerce de détail de livres en magasin spécialisé',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4761Z',
        name: 'Commerce de détail de livres en magasin spécialisé',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4762',
        name: 'Commerce de détail de journaux et papeterie en magasin spécialisé',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4762Z',
        name: 'Commerce de détail de journaux et papeterie en magasin spécialisé',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4763',
        name: 'Comm. détail enreg. musicaux & vidéo (magasin spécialisé)',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4763Z',
        name: 'Comm. détail enreg. musicaux & vidéo (magasin spécialisé)',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4764',
        name: "Commerce de détail d'articles de sport en magasin spécialisé",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4764Z',
        name: "Commerce de détail d'articles de sport en magasin spécialisé",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4765',
        name: 'Commerce de détail de jeux et jouets en magasin spécialisé',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4765Z',
        name: 'Commerce de détail de jeux et jouets en magasin spécialisé',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '477',
        name: 'Autres commerces de détail en magasin spécialisé',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4771',
        name: "Commerce de détail d'habillement en magasin spécialisé",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4771Z',
        name: "Commerce de détail d'habillement en magasin spécialisé",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4772',
        name: 'Comm. détail chaussures & articles cuir (magasin spécialisé)',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4772A',
        name: 'Commerce de détail de la chaussure',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4772B',
        name: "Commerce de détail de maroquinerie et d'articles de voyage",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4773',
        name: 'Commerce de détail produits pharmaceutiques (magasin spécialisé)',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4773Z',
        name: 'Commerce de détail produits pharmaceutiques (magasin spécialisé)',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4774',
        name: "Comm. détail d'articles médicaux & orthopédiques en magasin spéc.",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4774Z',
        name: "Comm. détail d'articles médicaux & orthopédiques en magasin spéc.",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4775',
        name: 'Comm. détail de parfumerie & produits de beauté en magasin spéc.',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4775Z',
        name: 'Comm. détail de parfumerie & produits de beauté en magasin spéc.',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4776',
        name: 'Comm. dét. fleurs, plantes, etc, animaux de cie et leurs aliments',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4776Z',
        name: 'Comm. dét. fleurs, plantes, etc, animaux de cie et leurs aliments',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4777',
        name: "Comm. détail d'articles horlogerie & bijouterie (magas. spéc.)",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4777Z',
        name: "Comm. détail d'articles horlogerie & bijouterie (magas. spéc.)",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4778',
        name: 'Autre commerce de détail de biens neufs en magasin spécialisé',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4778A',
        name: "Commerces de détail d'optique",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4778B',
        name: 'Commerces de détail de charbons et combustibles',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4778C',
        name: 'Autres commerces de détail spécialisés divers',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4779',
        name: "Commerce de détail de biens d'occasion en magasin",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4779Z',
        name: "Commerce de détail de biens d'occasion en magasin",
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '478',
        name: 'Commerce de détail sur éventaires et marchés',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4781',
        name: 'Commerce de détail alimentaire sur éventaires et marchés',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4781Z',
        name: 'Commerce de détail alimentaire sur éventaires et marchés',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4782',
        name: 'Comm. détail textiles habillt & chaussures s/éventaires & marchés',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4782Z',
        name: 'Comm. détail textiles habillt & chaussures s/éventaires & marchés',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4789',
        name: 'Autres commerces de détail sur éventaires et marchés',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4789Z',
        name: 'Autres commerces de détail sur éventaires et marchés',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '479',
        name: 'Commerce de détail hors magasin, éventaires ou marchés',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4791',
        name: 'Vente à distance',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4791A',
        name: 'Vente à distance sur catalogue général',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4791B',
        name: 'Vente à distance sur catalogue spécialisé',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4799',
        name: 'Autres commerces de détail hors magasin, éventaires ou marchés',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4799A',
        name: 'Vente à domicile',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '4799B',
        name: 'Vente par automate, aut. com. dét. hors mag., éventaire ou marché',
        section: 'Section G - Commerce ; Répar. Automobile & Motocycle',
        category: '47-Comm. détail, sf automobiles et motocycles',
    },
    {
        code: '49',
        name: 'Transports terrestres et transport par conduites',
        section: 'Section H - Transports et Entreposage',
        category: '49-Transports terrestres et transport par conduites',
    },
    {
        code: '491',
        name: 'Transport ferroviaire interurbain de voyageurs',
        section: 'Section H - Transports et Entreposage',
        category: '49-Transports terrestres et transport par conduites',
    },
    {
        code: '4910',
        name: 'Transport ferroviaire interurbain de voyageurs',
        section: 'Section H - Transports et Entreposage',
        category: '49-Transports terrestres et transport par conduites',
    },
    {
        code: '4910Z',
        name: 'Transport ferroviaire interurbain de voyageurs',
        section: 'Section H - Transports et Entreposage',
        category: '49-Transports terrestres et transport par conduites',
    },
    {
        code: '492',
        name: 'Transports ferroviaires de fret',
        section: 'Section H - Transports et Entreposage',
        category: '49-Transports terrestres et transport par conduites',
    },
    {
        code: '4920',
        name: 'Transports ferroviaires de fret',
        section: 'Section H - Transports et Entreposage',
        category: '49-Transports terrestres et transport par conduites',
    },
    {
        code: '4920Z',
        name: 'Transports ferroviaires de fret',
        section: 'Section H - Transports et Entreposage',
        category: '49-Transports terrestres et transport par conduites',
    },
    {
        code: '493',
        name: 'Autres transports terrestres de voyageurs',
        section: 'Section H - Transports et Entreposage',
        category: '49-Transports terrestres et transport par conduites',
    },
    {
        code: '4931',
        name: 'Transports urbains et suburbains de voyageurs',
        section: 'Section H - Transports et Entreposage',
        category: '49-Transports terrestres et transport par conduites',
    },
    {
        code: '4931Z',
        name: 'Transports urbains et suburbains de voyageurs',
        section: 'Section H - Transports et Entreposage',
        category: '49-Transports terrestres et transport par conduites',
    },
    {
        code: '4932',
        name: 'Transports de voyageurs par taxis',
        section: 'Section H - Transports et Entreposage',
        category: '49-Transports terrestres et transport par conduites',
    },
    {
        code: '4932Z',
        name: 'Transports de voyageurs par taxis',
        section: 'Section H - Transports et Entreposage',
        category: '49-Transports terrestres et transport par conduites',
    },
    {
        code: '4939',
        name: 'Autres transports terrestres de voyageurs n.c.a.',
        section: 'Section H - Transports et Entreposage',
        category: '49-Transports terrestres et transport par conduites',
    },
    {
        code: '4939A',
        name: 'Transports routiers réguliers de voyageurs',
        section: 'Section H - Transports et Entreposage',
        category: '49-Transports terrestres et transport par conduites',
    },
    {
        code: '4939B',
        name: 'Autres transports routiers de voyageurs',
        section: 'Section H - Transports et Entreposage',
        category: '49-Transports terrestres et transport par conduites',
    },
    {
        code: '4939C',
        name: 'Téléphériques et remontées mécaniques',
        section: 'Section H - Transports et Entreposage',
        category: '49-Transports terrestres et transport par conduites',
    },
    {
        code: '494',
        name: 'Transports routiers de fret et services de déménagement',
        section: 'Section H - Transports et Entreposage',
        category: '49-Transports terrestres et transport par conduites',
    },
    {
        code: '4941',
        name: 'Transports routiers de fret',
        section: 'Section H - Transports et Entreposage',
        category: '49-Transports terrestres et transport par conduites',
    },
    {
        code: '4941A',
        name: 'Transports routiers de fret interurbains',
        section: 'Section H - Transports et Entreposage',
        category: '49-Transports terrestres et transport par conduites',
    },
    {
        code: '4941B',
        name: 'Transports routiers de fret de proximité',
        section: 'Section H - Transports et Entreposage',
        category: '49-Transports terrestres et transport par conduites',
    },
    {
        code: '4941C',
        name: 'Location de camions avec chauffeur',
        section: 'Section H - Transports et Entreposage',
        category: '49-Transports terrestres et transport par conduites',
    },
    {
        code: '4942',
        name: 'Services de déménagement',
        section: 'Section H - Transports et Entreposage',
        category: '49-Transports terrestres et transport par conduites',
    },
    {
        code: '4942Z',
        name: 'Services de déménagement',
        section: 'Section H - Transports et Entreposage',
        category: '49-Transports terrestres et transport par conduites',
    },
    {
        code: '495',
        name: 'Transports par conduites',
        section: 'Section H - Transports et Entreposage',
        category: '49-Transports terrestres et transport par conduites',
    },
    {
        code: '4950',
        name: 'Transports par conduites',
        section: 'Section H - Transports et Entreposage',
        category: '49-Transports terrestres et transport par conduites',
    },
    {
        code: '4950Z',
        name: 'Transports par conduites',
        section: 'Section H - Transports et Entreposage',
        category: '49-Transports terrestres et transport par conduites',
    },
    {
        code: '50',
        name: 'Transports par eau',
        section: 'Section H - Transports et Entreposage',
        category: '50-Transports par eau',
    },
    {
        code: '501',
        name: 'Transports maritimes et côtiers de passagers',
        section: 'Section H - Transports et Entreposage',
        category: '50-Transports par eau',
    },
    {
        code: '5010',
        name: 'Transports maritimes et côtiers de passagers',
        section: 'Section H - Transports et Entreposage',
        category: '50-Transports par eau',
    },
    {
        code: '5010Z',
        name: 'Transports maritimes et côtiers de passagers',
        section: 'Section H - Transports et Entreposage',
        category: '50-Transports par eau',
    },
    {
        code: '502',
        name: 'Transports maritimes et côtiers de fret',
        section: 'Section H - Transports et Entreposage',
        category: '50-Transports par eau',
    },
    {
        code: '5020',
        name: 'Transports maritimes et côtiers de fret',
        section: 'Section H - Transports et Entreposage',
        category: '50-Transports par eau',
    },
    {
        code: '5020Z',
        name: 'Transports maritimes et côtiers de fret',
        section: 'Section H - Transports et Entreposage',
        category: '50-Transports par eau',
    },
    {
        code: '503',
        name: 'Transports fluviaux de passagers',
        section: 'Section H - Transports et Entreposage',
        category: '50-Transports par eau',
    },
    {
        code: '5030',
        name: 'Transports fluviaux de passagers',
        section: 'Section H - Transports et Entreposage',
        category: '50-Transports par eau',
    },
    {
        code: '5030Z',
        name: 'Transports fluviaux de passagers',
        section: 'Section H - Transports et Entreposage',
        category: '50-Transports par eau',
    },
    {
        code: '504',
        name: 'Transports fluviaux de fret',
        section: 'Section H - Transports et Entreposage',
        category: '50-Transports par eau',
    },
    {
        code: '5040',
        name: 'Transports fluviaux de fret',
        section: 'Section H - Transports et Entreposage',
        category: '50-Transports par eau',
    },
    {
        code: '5040Z',
        name: 'Transports fluviaux de fret',
        section: 'Section H - Transports et Entreposage',
        category: '50-Transports par eau',
    },
    {
        code: '51',
        name: 'Transports aériens',
        section: 'Section H - Transports et Entreposage',
        category: '51-Transports aériens',
    },
    {
        code: '511',
        name: 'Transports aériens de passagers',
        section: 'Section H - Transports et Entreposage',
        category: '51-Transports aériens',
    },
    {
        code: '5110',
        name: 'Transports aériens de passagers',
        section: 'Section H - Transports et Entreposage',
        category: '51-Transports aériens',
    },
    {
        code: '5110Z',
        name: 'Transports aériens de passagers',
        section: 'Section H - Transports et Entreposage',
        category: '51-Transports aériens',
    },
    {
        code: '512',
        name: 'Transports aériens de fret et transports spatiaux',
        section: 'Section H - Transports et Entreposage',
        category: '51-Transports aériens',
    },
    {
        code: '5121',
        name: 'Transports aériens de fret',
        section: 'Section H - Transports et Entreposage',
        category: '51-Transports aériens',
    },
    {
        code: '5121Z',
        name: 'Transports aériens de fret',
        section: 'Section H - Transports et Entreposage',
        category: '51-Transports aériens',
    },
    {
        code: '52',
        name: 'Entreposage et services auxiliaires des transports',
        section: 'Section H - Transports et Entreposage',
        category: '52-Entreposage et services auxiliaires des transports',
    },
    {
        code: '521',
        name: 'Entreposage et stockage',
        section: 'Section H - Transports et Entreposage',
        category: '52-Entreposage et services auxiliaires des transports',
    },
    {
        code: '5210',
        name: 'Entreposage et stockage',
        section: 'Section H - Transports et Entreposage',
        category: '52-Entreposage et services auxiliaires des transports',
    },
    {
        code: '5210A',
        name: 'Entreposage et stockage frigorifique',
        section: 'Section H - Transports et Entreposage',
        category: '52-Entreposage et services auxiliaires des transports',
    },
    {
        code: '5210B',
        name: 'Entreposage et stockage non frigorifique',
        section: 'Section H - Transports et Entreposage',
        category: '52-Entreposage et services auxiliaires des transports',
    },
    {
        code: '522',
        name: 'Services auxiliaires des transports',
        section: 'Section H - Transports et Entreposage',
        category: '52-Entreposage et services auxiliaires des transports',
    },
    {
        code: '5221',
        name: 'Services auxiliaires des transports terrestres',
        section: 'Section H - Transports et Entreposage',
        category: '52-Entreposage et services auxiliaires des transports',
    },
    {
        code: '5221Z',
        name: 'Services auxiliaires des transports terrestres',
        section: 'Section H - Transports et Entreposage',
        category: '52-Entreposage et services auxiliaires des transports',
    },
    {
        code: '5222',
        name: 'Services auxiliaires des transports par eau',
        section: 'Section H - Transports et Entreposage',
        category: '52-Entreposage et services auxiliaires des transports',
    },
    {
        code: '5222Z',
        name: 'Services auxiliaires des transports par eau',
        section: 'Section H - Transports et Entreposage',
        category: '52-Entreposage et services auxiliaires des transports',
    },
    {
        code: '5223',
        name: 'Services auxiliaires des transports aériens',
        section: 'Section H - Transports et Entreposage',
        category: '52-Entreposage et services auxiliaires des transports',
    },
    {
        code: '5223Z',
        name: 'Services auxiliaires des transports aériens',
        section: 'Section H - Transports et Entreposage',
        category: '52-Entreposage et services auxiliaires des transports',
    },
    {
        code: '5224',
        name: 'Manutention',
        section: 'Section H - Transports et Entreposage',
        category: '52-Entreposage et services auxiliaires des transports',
    },
    {
        code: '5224A',
        name: 'Manutention portuaire',
        section: 'Section H - Transports et Entreposage',
        category: '52-Entreposage et services auxiliaires des transports',
    },
    {
        code: '5224B',
        name: 'Manutention non portuaire',
        section: 'Section H - Transports et Entreposage',
        category: '52-Entreposage et services auxiliaires des transports',
    },
    {
        code: '5229',
        name: 'Autres services auxiliaires des transports',
        section: 'Section H - Transports et Entreposage',
        category: '52-Entreposage et services auxiliaires des transports',
    },
    {
        code: '5229A',
        name: 'Messagerie, fret express',
        section: 'Section H - Transports et Entreposage',
        category: '52-Entreposage et services auxiliaires des transports',
    },
    {
        code: '5229B',
        name: 'Affrètement et organisation des transports',
        section: 'Section H - Transports et Entreposage',
        category: '52-Entreposage et services auxiliaires des transports',
    },
    {
        code: '53',
        name: 'Activités de poste et de courrier',
        section: 'Section H - Transports et Entreposage',
        category: '53-Activités de poste et de courrier',
    },
    {
        code: '531',
        name: "Activ. poste dans le cadre d'une obligation de service universel",
        section: 'Section H - Transports et Entreposage',
        category: '53-Activités de poste et de courrier',
    },
    {
        code: '5310',
        name: "Activ. poste dans le cadre d'une obligation de service universel",
        section: 'Section H - Transports et Entreposage',
        category: '53-Activités de poste et de courrier',
    },
    {
        code: '5310Z',
        name: "Activ. poste dans le cadre d'une obligation de service universel",
        section: 'Section H - Transports et Entreposage',
        category: '53-Activités de poste et de courrier',
    },
    {
        code: '532',
        name: 'Autres activités de poste et de courrier',
        section: 'Section H - Transports et Entreposage',
        category: '53-Activités de poste et de courrier',
    },
    {
        code: '5320',
        name: 'Autres activités de poste et de courrier',
        section: 'Section H - Transports et Entreposage',
        category: '53-Activités de poste et de courrier',
    },
    {
        code: '5320Z',
        name: 'Autres activités de poste et de courrier',
        section: 'Section H - Transports et Entreposage',
        category: '53-Activités de poste et de courrier',
    },
    {
        code: '55',
        name: 'Hébergement',
        section: 'Section I - Hébergement et Restauration',
        category: '55-Hébergement',
    },
    {
        code: '551',
        name: 'Hôtels et hébergement similaire',
        section: 'Section I - Hébergement et Restauration',
        category: '55-Hébergement',
    },
    {
        code: '5510',
        name: 'Hôtels et hébergement similaire',
        section: 'Section I - Hébergement et Restauration',
        category: '55-Hébergement',
    },
    {
        code: '5510Z',
        name: 'Hôtels et hébergement similaire',
        section: 'Section I - Hébergement et Restauration',
        category: '55-Hébergement',
    },
    {
        code: '552',
        name: 'Hébergement touristique et autre hébergement de courte durée',
        section: 'Section I - Hébergement et Restauration',
        category: '55-Hébergement',
    },
    {
        code: '5520',
        name: 'Hébergement touristique et autre hébergement de courte durée',
        section: 'Section I - Hébergement et Restauration',
        category: '55-Hébergement',
    },
    {
        code: '5520Z',
        name: 'Hébergement touristique et autre hébergement de courte durée',
        section: 'Section I - Hébergement et Restauration',
        category: '55-Hébergement',
    },
    {
        code: '553',
        name: 'Terrains camping & parcs pour caravanes ou véhicules de loisirs',
        section: 'Section I - Hébergement et Restauration',
        category: '55-Hébergement',
    },
    {
        code: '5530',
        name: 'Terrains de camping et parcs pour caravanes, véhicules de loisirs',
        section: 'Section I - Hébergement et Restauration',
        category: '55-Hébergement',
    },
    {
        code: '5530Z',
        name: 'Terrains de camping et parcs pour caravanes, véhicules de loisirs',
        section: 'Section I - Hébergement et Restauration',
        category: '55-Hébergement',
    },
    {
        code: '559',
        name: 'Autres hébergements',
        section: 'Section I - Hébergement et Restauration',
        category: '55-Hébergement',
    },
    {
        code: '5590',
        name: 'Autres hébergements',
        section: 'Section I - Hébergement et Restauration',
        category: '55-Hébergement',
    },
    {
        code: '5590Z',
        name: 'Autres hébergements',
        section: 'Section I - Hébergement et Restauration',
        category: '55-Hébergement',
    },
    {
        code: '56',
        name: 'Restauration',
        section: 'Section I - Hébergement et Restauration',
        category: '56-Restauration',
    },
    {
        code: '561',
        name: 'Restaurants et services de restauration mobile',
        section: 'Section I - Hébergement et Restauration',
        category: '56-Restauration',
    },
    {
        code: '5610',
        name: 'Restaurants et services de restauration mobile',
        section: 'Section I - Hébergement et Restauration',
        category: '56-Restauration',
    },
    {
        code: '5610A',
        name: 'Restauration traditionnelle',
        section: 'Section I - Hébergement et Restauration',
        category: '56-Restauration',
    },
    {
        code: '5610B',
        name: 'Cafétérias et autres libres-services',
        section: 'Section I - Hébergement et Restauration',
        category: '56-Restauration',
    },
    {
        code: '5610C',
        name: 'Restauration de type rapide',
        section: 'Section I - Hébergement et Restauration',
        category: '56-Restauration',
    },
    {
        code: '562',
        name: 'Traiteurs et autres services de restauration',
        section: 'Section I - Hébergement et Restauration',
        category: '56-Restauration',
    },
    {
        code: '5621',
        name: 'Services des traiteurs',
        section: 'Section I - Hébergement et Restauration',
        category: '56-Restauration',
    },
    {
        code: '5621Z',
        name: 'Services des traiteurs',
        section: 'Section I - Hébergement et Restauration',
        category: '56-Restauration',
    },
    {
        code: '5629',
        name: 'Autres services de restauration',
        section: 'Section I - Hébergement et Restauration',
        category: '56-Restauration',
    },
    {
        code: '5629A',
        name: 'Restauration collective sous contrat',
        section: 'Section I - Hébergement et Restauration',
        category: '56-Restauration',
    },
    {
        code: '5629B',
        name: 'Autres services de restauration n.c.a.',
        section: 'Section I - Hébergement et Restauration',
        category: '56-Restauration',
    },
    {
        code: '563',
        name: 'Débits de boissons',
        section: 'Section I - Hébergement et Restauration',
        category: '56-Restauration',
    },
    {
        code: '5630',
        name: 'Débits de boissons',
        section: 'Section I - Hébergement et Restauration',
        category: '56-Restauration',
    },
    {
        code: '5630Z',
        name: 'Débits de boissons',
        section: 'Section I - Hébergement et Restauration',
        category: '56-Restauration',
    },
    {
        code: '58',
        name: 'Édition',
        section: 'Section J - Information et Communication',
        category: '58-Édition',
    },
    {
        code: '581',
        name: "Édition de livres et périodiques et autres activités d'édition",
        section: 'Section J - Information et Communication',
        category: '58-Édition',
    },
    {
        code: '5811',
        name: 'Édition de livres',
        section: 'Section J - Information et Communication',
        category: '58-Édition',
    },
    {
        code: '5811Z',
        name: 'Édition de livres',
        section: 'Section J - Information et Communication',
        category: '58-Édition',
    },
    {
        code: '5812',
        name: "Édition de répertoires et de fichiers d'adresses",
        section: 'Section J - Information et Communication',
        category: '58-Édition',
    },
    {
        code: '5812Z',
        name: "Édition de répertoires et de fichiers d'adresses",
        section: 'Section J - Information et Communication',
        category: '58-Édition',
    },
    {
        code: '5813',
        name: 'Édition de journaux',
        section: 'Section J - Information et Communication',
        category: '58-Édition',
    },
    {
        code: '5813Z',
        name: 'Édition de journaux',
        section: 'Section J - Information et Communication',
        category: '58-Édition',
    },
    {
        code: '5814',
        name: 'Édition de revues et périodiques',
        section: 'Section J - Information et Communication',
        category: '58-Édition',
    },
    {
        code: '5814Z',
        name: 'Édition de revues et périodiques',
        section: 'Section J - Information et Communication',
        category: '58-Édition',
    },
    {
        code: '5819',
        name: "Autres activités d'édition",
        section: 'Section J - Information et Communication',
        category: '58-Édition',
    },
    {
        code: '5819Z',
        name: "Autres activités d'édition",
        section: 'Section J - Information et Communication',
        category: '58-Édition',
    },
    {
        code: '582',
        name: 'Édition de logiciels',
        section: 'Section J - Information et Communication',
        category: '58-Édition',
    },
    {
        code: '5821',
        name: 'Édition de jeux électroniques',
        section: 'Section J - Information et Communication',
        category: '58-Édition',
    },
    {
        code: '5821Z',
        name: 'Édition de jeux électroniques',
        section: 'Section J - Information et Communication',
        category: '58-Édition',
    },
    {
        code: '5829',
        name: "Édition d'autres logiciels",
        section: 'Section J - Information et Communication',
        category: '58-Édition',
    },
    {
        code: '5829A',
        name: 'Édition de logiciels système et de réseau',
        section: 'Section J - Information et Communication',
        category: '58-Édition',
    },
    {
        code: '5829B',
        name: 'Edition de logiciels outils de développement et de langages',
        section: 'Section J - Information et Communication',
        category: '58-Édition',
    },
    {
        code: '5829C',
        name: 'Edition de logiciels applicatifs',
        section: 'Section J - Information et Communication',
        category: '58-Édition',
    },
    {
        code: '59',
        name: 'Prod. films cinémat. vidéo & prog.TV; enrg. sonore & éd. musicale',
        section: 'Section J - Information et Communication',
        category: '59-Prod. films cinémat. vidéo & prog.TV; enrg. sonore & éd. musicale',
    },
    {
        code: '591',
        name: 'Activités cinématographiques, vidéo et de télévision',
        section: 'Section J - Information et Communication',
        category: '59-Prod. films cinémat. vidéo & prog.TV; enrg. sonore & éd. musicale',
    },
    {
        code: '5911',
        name: 'Production films cinémat. vidéo & programmes de télévision',
        section: 'Section J - Information et Communication',
        category: '59-Prod. films cinémat. vidéo & prog.TV; enrg. sonore & éd. musicale',
    },
    {
        code: '5911A',
        name: 'Production de films et de programmes pour la télévision',
        section: 'Section J - Information et Communication',
        category: '59-Prod. films cinémat. vidéo & prog.TV; enrg. sonore & éd. musicale',
    },
    {
        code: '5911B',
        name: 'Production de films institutionnels et publicitaires',
        section: 'Section J - Information et Communication',
        category: '59-Prod. films cinémat. vidéo & prog.TV; enrg. sonore & éd. musicale',
    },
    {
        code: '5911C',
        name: 'Production de films pour le cinéma',
        section: 'Section J - Information et Communication',
        category: '59-Prod. films cinémat. vidéo & prog.TV; enrg. sonore & éd. musicale',
    },
    {
        code: '5912',
        name: 'Post-production films cinématograph. vidéo & prog. de télévision',
        section: 'Section J - Information et Communication',
        category: '59-Prod. films cinémat. vidéo & prog.TV; enrg. sonore & éd. musicale',
    },
    {
        code: '5912Z',
        name: 'Post-production films cinématograph. vidéo & prog. de télévision',
        section: 'Section J - Information et Communication',
        category: '59-Prod. films cinémat. vidéo & prog.TV; enrg. sonore & éd. musicale',
    },
    {
        code: '5913',
        name: 'Distribution films cinémat. vidéo & programmes de télévision',
        section: 'Section J - Information et Communication',
        category: '59-Prod. films cinémat. vidéo & prog.TV; enrg. sonore & éd. musicale',
    },
    {
        code: '5913A',
        name: 'Distribution de films cinématographiques',
        section: 'Section J - Information et Communication',
        category: '59-Prod. films cinémat. vidéo & prog.TV; enrg. sonore & éd. musicale',
    },
    {
        code: '5913B',
        name: 'Edition et distribution vidéo',
        section: 'Section J - Information et Communication',
        category: '59-Prod. films cinémat. vidéo & prog.TV; enrg. sonore & éd. musicale',
    },
    {
        code: '5914',
        name: 'Projection de films cinématographiques',
        section: 'Section J - Information et Communication',
        category: '59-Prod. films cinémat. vidéo & prog.TV; enrg. sonore & éd. musicale',
    },
    {
        code: '5914Z',
        name: 'Projection de films cinématographiques',
        section: 'Section J - Information et Communication',
        category: '59-Prod. films cinémat. vidéo & prog.TV; enrg. sonore & éd. musicale',
    },
    {
        code: '592',
        name: 'Enregistrement sonore et édition musicale',
        section: 'Section J - Information et Communication',
        category: '59-Prod. films cinémat. vidéo & prog.TV; enrg. sonore & éd. musicale',
    },
    {
        code: '5920',
        name: 'Enregistrement sonore et édition musicale',
        section: 'Section J - Information et Communication',
        category: '59-Prod. films cinémat. vidéo & prog.TV; enrg. sonore & éd. musicale',
    },
    {
        code: '5920Z',
        name: 'Enregistrement sonore et édition musicale',
        section: 'Section J - Information et Communication',
        category: '59-Prod. films cinémat. vidéo & prog.TV; enrg. sonore & éd. musicale',
    },
    {
        code: '60',
        name: 'Programmation et diffusion',
        section: 'Section J - Information et Communication',
        category: '60-Programmation et diffusion',
    },
    {
        code: '601',
        name: 'Édition et diffusion de programmes radio',
        section: 'Section J - Information et Communication',
        category: '60-Programmation et diffusion',
    },
    {
        code: '6010',
        name: 'Édition et diffusion de programmes radio',
        section: 'Section J - Information et Communication',
        category: '60-Programmation et diffusion',
    },
    {
        code: '6010Z',
        name: 'Édition et diffusion de programmes radio',
        section: 'Section J - Information et Communication',
        category: '60-Programmation et diffusion',
    },
    {
        code: '602',
        name: 'Programmation de télévision et télédiffusion',
        section: 'Section J - Information et Communication',
        category: '60-Programmation et diffusion',
    },
    {
        code: '6020',
        name: 'Programmation de télévision et télédiffusion',
        section: 'Section J - Information et Communication',
        category: '60-Programmation et diffusion',
    },
    {
        code: '6020A',
        name: 'Edition de chaînes généralistes',
        section: 'Section J - Information et Communication',
        category: '60-Programmation et diffusion',
    },
    {
        code: '6020B',
        name: 'Edition de chaînes thématiques',
        section: 'Section J - Information et Communication',
        category: '60-Programmation et diffusion',
    },
    {
        code: '61',
        name: 'Télécommunications',
        section: 'Section J - Information et Communication',
        category: '61-Télécommunications',
    },
    {
        code: '611',
        name: 'Télécommunications filaires',
        section: 'Section J - Information et Communication',
        category: '61-Télécommunications',
    },
    {
        code: '6110',
        name: 'Télécommunications filaires',
        section: 'Section J - Information et Communication',
        category: '61-Télécommunications',
    },
    {
        code: '6110Z',
        name: 'Télécommunications filaires',
        section: 'Section J - Information et Communication',
        category: '61-Télécommunications',
    },
    {
        code: '612',
        name: 'Télécommunications sans fil',
        section: 'Section J - Information et Communication',
        category: '61-Télécommunications',
    },
    {
        code: '6120',
        name: 'Télécommunications sans fil',
        section: 'Section J - Information et Communication',
        category: '61-Télécommunications',
    },
    {
        code: '6120Z',
        name: 'Télécommunications sans fil',
        section: 'Section J - Information et Communication',
        category: '61-Télécommunications',
    },
    {
        code: '613',
        name: 'Télécommunications par satellite',
        section: 'Section J - Information et Communication',
        category: '61-Télécommunications',
    },
    {
        code: '6130',
        name: 'Télécommunications par satellite',
        section: 'Section J - Information et Communication',
        category: '61-Télécommunications',
    },
    {
        code: '6130Z',
        name: 'Télécommunications par satellite',
        section: 'Section J - Information et Communication',
        category: '61-Télécommunications',
    },
    {
        code: '619',
        name: 'Autres activités de télécommunication',
        section: 'Section J - Information et Communication',
        category: '61-Télécommunications',
    },
    {
        code: '6190',
        name: 'Autres activités de télécommunication',
        section: 'Section J - Information et Communication',
        category: '61-Télécommunications',
    },
    {
        code: '6190Z',
        name: 'Autres activités de télécommunication',
        section: 'Section J - Information et Communication',
        category: '61-Télécommunications',
    },
    {
        code: '62',
        name: 'Programmation, conseil et autres activités informatiques',
        section: 'Section J - Information et Communication',
        category: '62-Programmation, conseil et autres activités informatiques',
    },
    {
        code: '620',
        name: 'Programmation, conseil et autres activités informatiques',
        section: 'Section J - Information et Communication',
        category: '62-Programmation, conseil et autres activités informatiques',
    },
    {
        code: '6201',
        name: 'Programmation informatique',
        section: 'Section J - Information et Communication',
        category: '62-Programmation, conseil et autres activités informatiques',
    },
    {
        code: '6201Z',
        name: 'Programmation informatique',
        section: 'Section J - Information et Communication',
        category: '62-Programmation, conseil et autres activités informatiques',
    },
    {
        code: '6202',
        name: 'Conseil informatique',
        section: 'Section J - Information et Communication',
        category: '62-Programmation, conseil et autres activités informatiques',
    },
    {
        code: '6202A',
        name: 'Conseil en systèmes et logiciels informatiques',
        section: 'Section J - Information et Communication',
        category: '62-Programmation, conseil et autres activités informatiques',
    },
    {
        code: '6202B',
        name: 'Tierce maintenance de systèmes et d’applications informatiques',
        section: 'Section J - Information et Communication',
        category: '62-Programmation, conseil et autres activités informatiques',
    },
    {
        code: '6203',
        name: "Gestion d'installations informatiques",
        section: 'Section J - Information et Communication',
        category: '62-Programmation, conseil et autres activités informatiques',
    },
    {
        code: '6203Z',
        name: "Gestion d'installations informatiques",
        section: 'Section J - Information et Communication',
        category: '62-Programmation, conseil et autres activités informatiques',
    },
    {
        code: '6209',
        name: 'Autres activités informatiques',
        section: 'Section J - Information et Communication',
        category: '62-Programmation, conseil et autres activités informatiques',
    },
    {
        code: '6209Z',
        name: 'Autres activités informatiques',
        section: 'Section J - Information et Communication',
        category: '62-Programmation, conseil et autres activités informatiques',
    },
    {
        code: '63',
        name: "Services d'information",
        section: 'Section J - Information et Communication',
        category: "63-Services d'information",
    },
    {
        code: '631',
        name: 'Trt de données, hébergement & activ. connexes; portails Internet',
        section: 'Section J - Information et Communication',
        category: "63-Services d'information",
    },
    {
        code: '6311',
        name: 'Traitement de données, hébergement et activités connexes',
        section: 'Section J - Information et Communication',
        category: "63-Services d'information",
    },
    {
        code: '6311Z',
        name: 'Traitement de données, hébergement et activités connexes',
        section: 'Section J - Information et Communication',
        category: "63-Services d'information",
    },
    {
        code: '6312',
        name: 'Portails Internet',
        section: 'Section J - Information et Communication',
        category: "63-Services d'information",
    },
    {
        code: '6312Z',
        name: 'Portails Internet',
        section: 'Section J - Information et Communication',
        category: "63-Services d'information",
    },
    {
        code: '639',
        name: "Autres services d'information",
        section: 'Section J - Information et Communication',
        category: "63-Services d'information",
    },
    {
        code: '6391',
        name: 'Activités des agences de presse',
        section: 'Section J - Information et Communication',
        category: "63-Services d'information",
    },
    {
        code: '6391Z',
        name: 'Activités des agences de presse',
        section: 'Section J - Information et Communication',
        category: "63-Services d'information",
    },
    {
        code: '6399',
        name: "Autres services d'information n.c.a.",
        section: 'Section J - Information et Communication',
        category: "63-Services d'information",
    },
    {
        code: '6399Z',
        name: "Autres services d'information n.c.a.",
        section: 'Section J - Information et Communication',
        category: "63-Services d'information",
    },
    {
        code: '64',
        name: 'Activ. services financiers, hors assurance & caisses de retraite',
        section: "Section K - Activités Financières et d'Assurance",
        category: '64-Activ. services financiers, hors assurance & caisses de retraite',
    },
    {
        code: '641',
        name: 'Intermédiation monétaire',
        section: "Section K - Activités Financières et d'Assurance",
        category: '64-Activ. services financiers, hors assurance & caisses de retraite',
    },
    {
        code: '6411',
        name: 'Activités de banque centrale',
        section: "Section K - Activités Financières et d'Assurance",
        category: '64-Activ. services financiers, hors assurance & caisses de retraite',
    },
    {
        code: '6411Z',
        name: 'Activités de banque centrale',
        section: "Section K - Activités Financières et d'Assurance",
        category: '64-Activ. services financiers, hors assurance & caisses de retraite',
    },
    {
        code: '6419',
        name: 'Autres intermédiations monétaires',
        section: "Section K - Activités Financières et d'Assurance",
        category: '64-Activ. services financiers, hors assurance & caisses de retraite',
    },
    {
        code: '6419Z',
        name: 'Autres intermédiations monétaires',
        section: "Section K - Activités Financières et d'Assurance",
        category: '64-Activ. services financiers, hors assurance & caisses de retraite',
    },
    {
        code: '642',
        name: 'Activités des sociétés holding',
        section: "Section K - Activités Financières et d'Assurance",
        category: '64-Activ. services financiers, hors assurance & caisses de retraite',
    },
    {
        code: '6420',
        name: 'Activités des sociétés holding',
        section: "Section K - Activités Financières et d'Assurance",
        category: '64-Activ. services financiers, hors assurance & caisses de retraite',
    },
    {
        code: '6420Z',
        name: 'Activités des sociétés holding',
        section: "Section K - Activités Financières et d'Assurance",
        category: '64-Activ. services financiers, hors assurance & caisses de retraite',
    },
    {
        code: '643',
        name: 'Fonds de placement et entités financières similaires',
        section: "Section K - Activités Financières et d'Assurance",
        category: '64-Activ. services financiers, hors assurance & caisses de retraite',
    },
    {
        code: '6430',
        name: 'Fonds de placement et entités financières similaires',
        section: "Section K - Activités Financières et d'Assurance",
        category: '64-Activ. services financiers, hors assurance & caisses de retraite',
    },
    {
        code: '6430Z',
        name: 'Fonds de placement et entités financières similaires',
        section: "Section K - Activités Financières et d'Assurance",
        category: '64-Activ. services financiers, hors assurance & caisses de retraite',
    },
    {
        code: '649',
        name: 'Autres activ. serv. financiers, hors assurance & caisses retraite',
        section: "Section K - Activités Financières et d'Assurance",
        category: '64-Activ. services financiers, hors assurance & caisses de retraite',
    },
    {
        code: '6491',
        name: 'Crédit-bail',
        section: "Section K - Activités Financières et d'Assurance",
        category: '64-Activ. services financiers, hors assurance & caisses de retraite',
    },
    {
        code: '6491Z',
        name: 'Crédit-bail',
        section: "Section K - Activités Financières et d'Assurance",
        category: '64-Activ. services financiers, hors assurance & caisses de retraite',
    },
    {
        code: '6492',
        name: 'Autre distribution de crédit',
        section: "Section K - Activités Financières et d'Assurance",
        category: '64-Activ. services financiers, hors assurance & caisses de retraite',
    },
    {
        code: '6492Z',
        name: 'Autre distribution de crédit',
        section: "Section K - Activités Financières et d'Assurance",
        category: '64-Activ. services financiers, hors assurance & caisses de retraite',
    },
    {
        code: '6499',
        name: 'Autres activ. serv. financiers sf assurance & c. de retraite, nca',
        section: "Section K - Activités Financières et d'Assurance",
        category: '64-Activ. services financiers, hors assurance & caisses de retraite',
    },
    {
        code: '6499Z',
        name: 'Autres activ. serv. financiers sf assurance & c. de retraite, nca',
        section: "Section K - Activités Financières et d'Assurance",
        category: '64-Activ. services financiers, hors assurance & caisses de retraite',
    },
    {
        code: '65',
        name: 'Assurance',
        section: "Section K - Activités Financières et d'Assurance",
        category: '65-Assurance',
    },
    {
        code: '651',
        name: 'Assurance',
        section: "Section K - Activités Financières et d'Assurance",
        category: '65-Assurance',
    },
    {
        code: '6511',
        name: 'Assurance vie',
        section: "Section K - Activités Financières et d'Assurance",
        category: '65-Assurance',
    },
    {
        code: '6511Z',
        name: 'Assurance vie',
        section: "Section K - Activités Financières et d'Assurance",
        category: '65-Assurance',
    },
    {
        code: '6512',
        name: 'Autres assurances',
        section: "Section K - Activités Financières et d'Assurance",
        category: '65-Assurance',
    },
    {
        code: '6512Z',
        name: 'Autres assurances',
        section: "Section K - Activités Financières et d'Assurance",
        category: '65-Assurance',
    },
    {
        code: '652',
        name: 'Réassurance',
        section: "Section K - Activités Financières et d'Assurance",
        category: '65-Assurance',
    },
    {
        code: '6520',
        name: 'Réassurance',
        section: "Section K - Activités Financières et d'Assurance",
        category: '65-Assurance',
    },
    {
        code: '6520Z',
        name: 'Réassurance',
        section: "Section K - Activités Financières et d'Assurance",
        category: '65-Assurance',
    },
    {
        code: '653',
        name: 'Caisses de retraite',
        section: "Section K - Activités Financières et d'Assurance",
        category: '65-Assurance',
    },
    {
        code: '6530',
        name: 'Caisses de retraite',
        section: "Section K - Activités Financières et d'Assurance",
        category: '65-Assurance',
    },
    {
        code: '6530Z',
        name: 'Caisses de retraite',
        section: "Section K - Activités Financières et d'Assurance",
        category: '65-Assurance',
    },
    {
        code: '66',
        name: "Activités auxiliaires de services financiers et d'assurance",
        section: "Section K - Activités Financières et d'Assurance",
        category: "66-Activités auxiliaires de services financiers et d'assurance",
    },
    {
        code: '661',
        name: 'Activ. auxil. serv. financiers, hors assurance & caisses retraite',
        section: "Section K - Activités Financières et d'Assurance",
        category: "66-Activités auxiliaires de services financiers et d'assurance",
    },
    {
        code: '6611',
        name: 'Administration de marchés financiers',
        section: "Section K - Activités Financières et d'Assurance",
        category: "66-Activités auxiliaires de services financiers et d'assurance",
    },
    {
        code: '6611Z',
        name: 'Administration de marchés financiers',
        section: "Section K - Activités Financières et d'Assurance",
        category: "66-Activités auxiliaires de services financiers et d'assurance",
    },
    {
        code: '6612',
        name: 'Courtage de valeurs mobilières et de marchandises',
        section: "Section K - Activités Financières et d'Assurance",
        category: "66-Activités auxiliaires de services financiers et d'assurance",
    },
    {
        code: '6612Z',
        name: 'Courtage de valeurs mobilières et de marchandises',
        section: "Section K - Activités Financières et d'Assurance",
        category: "66-Activités auxiliaires de services financiers et d'assurance",
    },
    {
        code: '6619',
        name: 'Aut. activ. auxil. serv. financ., hors assur. & caisses retraite',
        section: "Section K - Activités Financières et d'Assurance",
        category: "66-Activités auxiliaires de services financiers et d'assurance",
    },
    {
        code: '6619A',
        name: 'Supports juridiques de gestion de patrimoine mobilier',
        section: "Section K - Activités Financières et d'Assurance",
        category: "66-Activités auxiliaires de services financiers et d'assurance",
    },
    {
        code: '6619B',
        name: 'Aut. activ. auxil. serv. financ., hors assur. & caisse retr. nca.',
        section: "Section K - Activités Financières et d'Assurance",
        category: "66-Activités auxiliaires de services financiers et d'assurance",
    },
    {
        code: '662',
        name: "Activités auxiliaires d'assurance et de caisses de retraite",
        section: "Section K - Activités Financières et d'Assurance",
        category: "66-Activités auxiliaires de services financiers et d'assurance",
    },
    {
        code: '6621',
        name: 'Évaluation des risques et dommages',
        section: "Section K - Activités Financières et d'Assurance",
        category: "66-Activités auxiliaires de services financiers et d'assurance",
    },
    {
        code: '6621Z',
        name: 'Évaluation des risques et dommages',
        section: "Section K - Activités Financières et d'Assurance",
        category: "66-Activités auxiliaires de services financiers et d'assurance",
    },
    {
        code: '6622',
        name: "Activités des agents et courtiers d'assurances",
        section: "Section K - Activités Financières et d'Assurance",
        category: "66-Activités auxiliaires de services financiers et d'assurance",
    },
    {
        code: '6622Z',
        name: "Activités des agents et courtiers d'assurances",
        section: "Section K - Activités Financières et d'Assurance",
        category: "66-Activités auxiliaires de services financiers et d'assurance",
    },
    {
        code: '6629',
        name: "Aut. activités auxiliaires d'assurance et de caisses de retraite",
        section: "Section K - Activités Financières et d'Assurance",
        category: "66-Activités auxiliaires de services financiers et d'assurance",
    },
    {
        code: '6629Z',
        name: "Aut. activités auxiliaires d'assurance et de caisses de retraite",
        section: "Section K - Activités Financières et d'Assurance",
        category: "66-Activités auxiliaires de services financiers et d'assurance",
    },
    {
        code: '663',
        name: 'Gestion de fonds',
        section: "Section K - Activités Financières et d'Assurance",
        category: "66-Activités auxiliaires de services financiers et d'assurance",
    },
    {
        code: '6630',
        name: 'Gestion de fonds',
        section: "Section K - Activités Financières et d'Assurance",
        category: "66-Activités auxiliaires de services financiers et d'assurance",
    },
    {
        code: '6630Z',
        name: 'Gestion de fonds',
        section: "Section K - Activités Financières et d'Assurance",
        category: "66-Activités auxiliaires de services financiers et d'assurance",
    },
    {
        code: '68',
        name: 'Activités immobilières',
        section: 'Section L - Activités Immobilières',
        category: '68-Activités immobilières',
    },
    {
        code: '681',
        name: 'Activités des marchands de biens immobiliers',
        section: 'Section L - Activités Immobilières',
        category: '68-Activités immobilières',
    },
    {
        code: '6810',
        name: 'Activités des marchands de biens immobiliers',
        section: 'Section L - Activités Immobilières',
        category: '68-Activités immobilières',
    },
    {
        code: '6810Z',
        name: 'Activités des marchands de biens immobiliers',
        section: 'Section L - Activités Immobilières',
        category: '68-Activités immobilières',
    },
    {
        code: '682',
        name: 'Location et exploitation de biens immobiliers propres ou loués',
        section: 'Section L - Activités Immobilières',
        category: '68-Activités immobilières',
    },
    {
        code: '6820',
        name: 'Location et exploitation de biens immobiliers propres ou loués',
        section: 'Section L - Activités Immobilières',
        category: '68-Activités immobilières',
    },
    {
        code: '6820A',
        name: 'Location de logements',
        section: 'Section L - Activités Immobilières',
        category: '68-Activités immobilières',
    },
    {
        code: '6820B',
        name: "Location de terrains et d'autres biens immobiliers",
        section: 'Section L - Activités Immobilières',
        category: '68-Activités immobilières',
    },
    {
        code: '683',
        name: 'Activités immobilières pour compte de tiers',
        section: 'Section L - Activités Immobilières',
        category: '68-Activités immobilières',
    },
    {
        code: '6831',
        name: 'Agences immobilières',
        section: 'Section L - Activités Immobilières',
        category: '68-Activités immobilières',
    },
    {
        code: '6831Z',
        name: 'Agences immobilières',
        section: 'Section L - Activités Immobilières',
        category: '68-Activités immobilières',
    },
    {
        code: '6832',
        name: 'Administration de biens immobiliers',
        section: 'Section L - Activités Immobilières',
        category: '68-Activités immobilières',
    },
    {
        code: '6832A',
        name: "Administration d'immeubles et autres biens immobiliers",
        section: 'Section L - Activités Immobilières',
        category: '68-Activités immobilières',
    },
    {
        code: '6832B',
        name: 'Supports juridiques de gestion de patrimoine immobilier',
        section: 'Section L - Activités Immobilières',
        category: '68-Activités immobilières',
    },
    {
        code: '69',
        name: 'Activités juridiques et comptables',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '69-Activités juridiques et comptables',
    },
    {
        code: '691',
        name: 'Activités juridiques',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '69-Activités juridiques et comptables',
    },
    {
        code: '6910',
        name: 'Activités juridiques',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '69-Activités juridiques et comptables',
    },
    {
        code: '6910Z',
        name: 'Activités juridiques',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '69-Activités juridiques et comptables',
    },
    {
        code: '692',
        name: 'Activités comptables',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '69-Activités juridiques et comptables',
    },
    {
        code: '6920',
        name: 'Activités comptables',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '69-Activités juridiques et comptables',
    },
    {
        code: '6920Z',
        name: 'Activités comptables',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '69-Activités juridiques et comptables',
    },
    {
        code: '70',
        name: 'Activités des sièges sociaux ; conseil de gestion',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '70-Activités des sièges sociaux ; conseil de gestion',
    },
    {
        code: '701',
        name: 'Activités des sièges sociaux',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '70-Activités des sièges sociaux ; conseil de gestion',
    },
    {
        code: '7010',
        name: 'Activités des sièges sociaux',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '70-Activités des sièges sociaux ; conseil de gestion',
    },
    {
        code: '7010Z',
        name: 'Activités des sièges sociaux',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '70-Activités des sièges sociaux ; conseil de gestion',
    },
    {
        code: '702',
        name: 'Conseil de gestion',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '70-Activités des sièges sociaux ; conseil de gestion',
    },
    {
        code: '7021',
        name: 'Conseil en relations publiques et communication',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '70-Activités des sièges sociaux ; conseil de gestion',
    },
    {
        code: '7021Z',
        name: 'Conseil en relations publiques et communication',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '70-Activités des sièges sociaux ; conseil de gestion',
    },
    {
        code: '7022',
        name: 'Conseil pour les affaires et autres conseils de gestion',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '70-Activités des sièges sociaux ; conseil de gestion',
    },
    {
        code: '7022Z',
        name: 'Conseil pour les affaires et autres conseils de gestion',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '70-Activités des sièges sociaux ; conseil de gestion',
    },
    {
        code: '71',
        name: 'Activ.  architecture & ingénierie; contrôle & analyses techniques',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '71-Activ.  architecture & ingénierie; contrôle & analyses techniques',
    },
    {
        code: '711',
        name: "Activités d'architecture et d'ingénierie",
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '71-Activ.  architecture & ingénierie; contrôle & analyses techniques',
    },
    {
        code: '7111',
        name: "Activités d'architecture",
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '71-Activ.  architecture & ingénierie; contrôle & analyses techniques',
    },
    {
        code: '7111Z',
        name: "Activités d'architecture",
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '71-Activ.  architecture & ingénierie; contrôle & analyses techniques',
    },
    {
        code: '7112',
        name: "Activités d'ingénierie",
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '71-Activ.  architecture & ingénierie; contrôle & analyses techniques',
    },
    {
        code: '7112A',
        name: 'Activité des géomètres',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '71-Activ.  architecture & ingénierie; contrôle & analyses techniques',
    },
    {
        code: '7112B',
        name: 'Ingénierie, études techniques',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '71-Activ.  architecture & ingénierie; contrôle & analyses techniques',
    },
    {
        code: '712',
        name: 'Activités de contrôle et analyses techniques',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '71-Activ.  architecture & ingénierie; contrôle & analyses techniques',
    },
    {
        code: '7120',
        name: 'Activités de contrôle et analyses techniques',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '71-Activ.  architecture & ingénierie; contrôle & analyses techniques',
    },
    {
        code: '7120A',
        name: 'Contrôle technique automobile',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '71-Activ.  architecture & ingénierie; contrôle & analyses techniques',
    },
    {
        code: '7120B',
        name: 'Analyses, essais et inspections techniques',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '71-Activ.  architecture & ingénierie; contrôle & analyses techniques',
    },
    {
        code: '72',
        name: 'Recherche-développement scientifique',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '72-Recherche-développement scientifique',
    },
    {
        code: '721',
        name: 'Recherche-développement en sciences physiques et naturelles',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '72-Recherche-développement scientifique',
    },
    {
        code: '7211',
        name: 'Recherche-développement en biotechnologie',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '72-Recherche-développement scientifique',
    },
    {
        code: '7211Z',
        name: 'Recherche-développement en biotechnologie',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '72-Recherche-développement scientifique',
    },
    {
        code: '7219',
        name: 'Recherche-développement : autres sciences physiques et naturelles',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '72-Recherche-développement scientifique',
    },
    {
        code: '7219Z',
        name: 'Recherche-développement : autres sciences physiques et naturelles',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '72-Recherche-développement scientifique',
    },
    {
        code: '722',
        name: 'Recherche-développement en sciences humaines et sociales',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '72-Recherche-développement scientifique',
    },
    {
        code: '7220',
        name: 'Recherche-développement en sciences humaines et sociales',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '72-Recherche-développement scientifique',
    },
    {
        code: '7220Z',
        name: 'Recherche-développement en sciences humaines et sociales',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '72-Recherche-développement scientifique',
    },
    {
        code: '73',
        name: 'Publicité et études de marché',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '73-Publicité et études de marché',
    },
    {
        code: '731',
        name: 'Publicité',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '73-Publicité et études de marché',
    },
    {
        code: '7311',
        name: 'Activités des agences de publicité',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '73-Publicité et études de marché',
    },
    {
        code: '7311Z',
        name: 'Activités des agences de publicité',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '73-Publicité et études de marché',
    },
    {
        code: '7312',
        name: 'Régie publicitaire de médias',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '73-Publicité et études de marché',
    },
    {
        code: '7312Z',
        name: 'Régie publicitaire de médias',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '73-Publicité et études de marché',
    },
    {
        code: '732',
        name: 'Études de marché et sondages',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '73-Publicité et études de marché',
    },
    {
        code: '7320',
        name: 'Études de marché et sondages',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '73-Publicité et études de marché',
    },
    {
        code: '7320Z',
        name: 'Études de marché et sondages',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '73-Publicité et études de marché',
    },
    {
        code: '74',
        name: 'Autres activités spécialisées, scientifiques et techniques',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '74-Autres activités spécialisées, scientifiques et techniques',
    },
    {
        code: '741',
        name: 'Activités spécialisées de design',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '74-Autres activités spécialisées, scientifiques et techniques',
    },
    {
        code: '7410',
        name: 'Activités spécialisées de design',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '74-Autres activités spécialisées, scientifiques et techniques',
    },
    {
        code: '7410Z',
        name: 'Activités spécialisées de design',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '74-Autres activités spécialisées, scientifiques et techniques',
    },
    {
        code: '742',
        name: 'Activités photographiques',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '74-Autres activités spécialisées, scientifiques et techniques',
    },
    {
        code: '7420',
        name: 'Activités photographiques',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '74-Autres activités spécialisées, scientifiques et techniques',
    },
    {
        code: '7420Z',
        name: 'Activités photographiques',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '74-Autres activités spécialisées, scientifiques et techniques',
    },
    {
        code: '743',
        name: 'Traduction et interprétation',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '74-Autres activités spécialisées, scientifiques et techniques',
    },
    {
        code: '7430',
        name: 'Traduction et interprétation',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '74-Autres activités spécialisées, scientifiques et techniques',
    },
    {
        code: '7430Z',
        name: 'Traduction et interprétation',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '74-Autres activités spécialisées, scientifiques et techniques',
    },
    {
        code: '749',
        name: 'Autres activités spécialisées, scientifiques et techniques n.c.a.',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '74-Autres activités spécialisées, scientifiques et techniques',
    },
    {
        code: '7490',
        name: 'Autres activités spécialisées, scientifiques et techniques n.c.a.',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '74-Autres activités spécialisées, scientifiques et techniques',
    },
    {
        code: '7490A',
        name: 'Activité des économistes de la construction',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '74-Autres activités spécialisées, scientifiques et techniques',
    },
    {
        code: '7490B',
        name: 'Activités spécialisées, scientifiques et techniques diverses',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '74-Autres activités spécialisées, scientifiques et techniques',
    },
    {
        code: '75',
        name: 'Activités vétérinaires',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '75-Activités vétérinaires',
    },
    {
        code: '750',
        name: 'Activités vétérinaires',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '75-Activités vétérinaires',
    },
    {
        code: '7500',
        name: 'Activités vétérinaires',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '75-Activités vétérinaires',
    },
    {
        code: '7500Z',
        name: 'Activités vétérinaires',
        section: 'Section M - Act. Spécialisée, Scientifiq. & Techniq.',
        category: '75-Activités vétérinaires',
    },
    {
        code: '77',
        name: 'Activités de location et location-bail',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '77-Activités de location et location-bail',
    },
    {
        code: '771',
        name: 'Location et location-bail de véhicules automobiles',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '77-Activités de location et location-bail',
    },
    {
        code: '7711',
        name: 'Location & location-bail voitures & véhicules automobiles légers',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '77-Activités de location et location-bail',
    },
    {
        code: '7711A',
        name: 'Location de courte durée voitures & véhicules auto. légers',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '77-Activités de location et location-bail',
    },
    {
        code: '7711B',
        name: 'Location de longue durée voitures & véhicules automobiles légers',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '77-Activités de location et location-bail',
    },
    {
        code: '7712',
        name: 'Location et location-bail de camions',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '77-Activités de location et location-bail',
    },
    {
        code: '7712Z',
        name: 'Location et location-bail de camions',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '77-Activités de location et location-bail',
    },
    {
        code: '772',
        name: 'Location et location-bail de biens personnels et domestiques',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '77-Activités de location et location-bail',
    },
    {
        code: '7721',
        name: "Location et location-bail d'articles de loisirs et de sport",
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '77-Activités de location et location-bail',
    },
    {
        code: '7721Z',
        name: "Location et location-bail d'articles de loisirs et de sport",
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '77-Activités de location et location-bail',
    },
    {
        code: '7722',
        name: 'Location de vidéocassettes et disques vidéo',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '77-Activités de location et location-bail',
    },
    {
        code: '7722Z',
        name: 'Location de vidéocassettes et disques vidéo',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '77-Activités de location et location-bail',
    },
    {
        code: '7729',
        name: 'Location et location-bail autres biens personnels et domestiques',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '77-Activités de location et location-bail',
    },
    {
        code: '7729Z',
        name: 'Location et location-bail autres biens personnels et domestiques',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '77-Activités de location et location-bail',
    },
    {
        code: '773',
        name: "Location et location-bail d'autres machines, équipements et biens",
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '77-Activités de location et location-bail',
    },
    {
        code: '7731',
        name: 'Location et location-bail de machines et équipements agricoles',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '77-Activités de location et location-bail',
    },
    {
        code: '7731Z',
        name: 'Location et location-bail de machines et équipements agricoles',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '77-Activités de location et location-bail',
    },
    {
        code: '7732',
        name: 'Location et location-bail machines & équipts pour la construction',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '77-Activités de location et location-bail',
    },
    {
        code: '7732Z',
        name: 'Location et location-bail machines & équipts pour la construction',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '77-Activités de location et location-bail',
    },
    {
        code: '7733',
        name: 'Location et location-bail machines bureau & matériel informatique',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '77-Activités de location et location-bail',
    },
    {
        code: '7733Z',
        name: 'Location et location-bail machines bureau & matériel informatique',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '77-Activités de location et location-bail',
    },
    {
        code: '7734',
        name: 'Location et location-bail de matériels de transport par eau',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '77-Activités de location et location-bail',
    },
    {
        code: '7734Z',
        name: 'Location et location-bail de matériels de transport par eau',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '77-Activités de location et location-bail',
    },
    {
        code: '7735',
        name: 'Location et location-bail de matériels de transport aérien',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '77-Activités de location et location-bail',
    },
    {
        code: '7735Z',
        name: 'Location et location-bail de matériels de transport aérien',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '77-Activités de location et location-bail',
    },
    {
        code: '7739',
        name: 'Location et location-bail machines, équipements et biens divers',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '77-Activités de location et location-bail',
    },
    {
        code: '7739Z',
        name: 'Location et location-bail machines, équipements et biens divers',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '77-Activités de location et location-bail',
    },
    {
        code: '774',
        name: 'Loc-bail de propriété intell. & sf oeuvres soumises à copyright',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '77-Activités de location et location-bail',
    },
    {
        code: '7740',
        name: 'Loc-bail de propriété intell. & sf oeuvres soumises à copyright',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '77-Activités de location et location-bail',
    },
    {
        code: '7740Z',
        name: 'Loc-bail de propriété intell. & sf oeuvres soumises à copyright',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '77-Activités de location et location-bail',
    },
    {
        code: '78',
        name: "Activités liées à l'emploi",
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: "78-Activités liées à l'emploi",
    },
    {
        code: '781',
        name: "Activités des agences de placement de main-d'oeuvre",
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: "78-Activités liées à l'emploi",
    },
    {
        code: '7810',
        name: "Activités des agences de placement de main-d'oeuvre",
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: "78-Activités liées à l'emploi",
    },
    {
        code: '7810Z',
        name: "Activités des agences de placement de main-d'oeuvre",
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: "78-Activités liées à l'emploi",
    },
    {
        code: '782',
        name: 'Activités des agences de travail temporaire',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: "78-Activités liées à l'emploi",
    },
    {
        code: '7820',
        name: 'Activités des agences de travail temporaire',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: "78-Activités liées à l'emploi",
    },
    {
        code: '7820Z',
        name: 'Activités des agences de travail temporaire',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: "78-Activités liées à l'emploi",
    },
    {
        code: '783',
        name: 'Autre mise à disposition de ressources humaines',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: "78-Activités liées à l'emploi",
    },
    {
        code: '7830',
        name: 'Autre mise à disposition de ressources humaines',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: "78-Activités liées à l'emploi",
    },
    {
        code: '7830Z',
        name: 'Autre mise à disposition de ressources humaines',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: "78-Activités liées à l'emploi",
    },
    {
        code: '79',
        name: 'Activ. agences voyage, voyagistes, serv. résa. & activ. connexes',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '79-Activ. agences voyage, voyagistes, serv. résa. & activ. connexes',
    },
    {
        code: '791',
        name: 'Activités des agences de voyage et voyagistes',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '79-Activ. agences voyage, voyagistes, serv. résa. & activ. connexes',
    },
    {
        code: '7911',
        name: 'Activités des agences de voyage',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '79-Activ. agences voyage, voyagistes, serv. résa. & activ. connexes',
    },
    {
        code: '7911Z',
        name: 'Activités des agences de voyage',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '79-Activ. agences voyage, voyagistes, serv. résa. & activ. connexes',
    },
    {
        code: '7912',
        name: 'Activités des voyagistes',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '79-Activ. agences voyage, voyagistes, serv. résa. & activ. connexes',
    },
    {
        code: '7912Z',
        name: 'Activités des voyagistes',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '79-Activ. agences voyage, voyagistes, serv. résa. & activ. connexes',
    },
    {
        code: '799',
        name: 'Autres services de réservation et activités connexes',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '79-Activ. agences voyage, voyagistes, serv. résa. & activ. connexes',
    },
    {
        code: '7990',
        name: 'Autres services de réservation et activités connexes',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '79-Activ. agences voyage, voyagistes, serv. résa. & activ. connexes',
    },
    {
        code: '7990Z',
        name: 'Autres services de réservation et activités connexes',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '79-Activ. agences voyage, voyagistes, serv. résa. & activ. connexes',
    },
    {
        code: '80',
        name: 'Enquêtes et sécurité',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '80-Enquêtes et sécurité',
    },
    {
        code: '801',
        name: 'Activités de sécurité privée',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '80-Enquêtes et sécurité',
    },
    {
        code: '8010',
        name: 'Activités de sécurité privée',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '80-Enquêtes et sécurité',
    },
    {
        code: '8010Z',
        name: 'Activités de sécurité privée',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '80-Enquêtes et sécurité',
    },
    {
        code: '802',
        name: 'Activités liées aux systèmes de sécurité',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '80-Enquêtes et sécurité',
    },
    {
        code: '8020',
        name: 'Activités liées aux systèmes de sécurité',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '80-Enquêtes et sécurité',
    },
    {
        code: '8020Z',
        name: 'Activités liées aux systèmes de sécurité',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '80-Enquêtes et sécurité',
    },
    {
        code: '803',
        name: "Activités d'enquête",
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '80-Enquêtes et sécurité',
    },
    {
        code: '8030',
        name: "Activités d'enquête",
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '80-Enquêtes et sécurité',
    },
    {
        code: '8030Z',
        name: "Activités d'enquête",
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '80-Enquêtes et sécurité',
    },
    {
        code: '81',
        name: 'Services relatifs aux bâtiments et aménagement paysager',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '81-Services relatifs aux bâtiments et aménagement paysager',
    },
    {
        code: '811',
        name: 'Activités combinées de soutien lié aux bâtiments',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '81-Services relatifs aux bâtiments et aménagement paysager',
    },
    {
        code: '8110',
        name: 'Activités combinées de soutien lié aux bâtiments',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '81-Services relatifs aux bâtiments et aménagement paysager',
    },
    {
        code: '8110Z',
        name: 'Activités combinées de soutien lié aux bâtiments',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '81-Services relatifs aux bâtiments et aménagement paysager',
    },
    {
        code: '812',
        name: 'Activités de nettoyage',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '81-Services relatifs aux bâtiments et aménagement paysager',
    },
    {
        code: '8121',
        name: 'Nettoyage courant des bâtiments',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '81-Services relatifs aux bâtiments et aménagement paysager',
    },
    {
        code: '8121Z',
        name: 'Nettoyage courant des bâtiments',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '81-Services relatifs aux bâtiments et aménagement paysager',
    },
    {
        code: '8122',
        name: 'Autres activités nettoyage des bâtiments et nettoyage industriel',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '81-Services relatifs aux bâtiments et aménagement paysager',
    },
    {
        code: '8122Z',
        name: 'Autres activités nettoyage des bâtiments et nettoyage industriel',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '81-Services relatifs aux bâtiments et aménagement paysager',
    },
    {
        code: '8129',
        name: 'Autres activités de nettoyage',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '81-Services relatifs aux bâtiments et aménagement paysager',
    },
    {
        code: '8129A',
        name: 'Désinfection, désinsectisation, dératisation',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '81-Services relatifs aux bâtiments et aménagement paysager',
    },
    {
        code: '8129B',
        name: 'Autres activités de nettoyage n.c.a.',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '81-Services relatifs aux bâtiments et aménagement paysager',
    },
    {
        code: '813',
        name: "Services d'aménagement paysager",
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '81-Services relatifs aux bâtiments et aménagement paysager',
    },
    {
        code: '8130',
        name: "Services d'aménagement paysager",
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '81-Services relatifs aux bâtiments et aménagement paysager',
    },
    {
        code: '8130Z',
        name: "Services d'aménagement paysager",
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '81-Services relatifs aux bâtiments et aménagement paysager',
    },
    {
        code: '82',
        name: 'Activ. administratives & autres activ. soutien aux entreprises',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '82-Activ. administratives & autres activ. soutien aux entreprises',
    },
    {
        code: '821',
        name: 'Activités administratives',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '82-Activ. administratives & autres activ. soutien aux entreprises',
    },
    {
        code: '8211',
        name: 'Services administratifs combinés de bureau',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '82-Activ. administratives & autres activ. soutien aux entreprises',
    },
    {
        code: '8211Z',
        name: 'Services administratifs combinés de bureau',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '82-Activ. administratives & autres activ. soutien aux entreprises',
    },
    {
        code: '8219',
        name: 'Photocopie prépa. documents & aut. activ. spéc. soutien de bureau',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '82-Activ. administratives & autres activ. soutien aux entreprises',
    },
    {
        code: '8219Z',
        name: 'Photocopie prépa. documents & aut. activ. spéc. soutien de bureau',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '82-Activ. administratives & autres activ. soutien aux entreprises',
    },
    {
        code: '822',
        name: "Activités de centres d'appels",
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '82-Activ. administratives & autres activ. soutien aux entreprises',
    },
    {
        code: '8220',
        name: "Activités de centres d'appels",
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '82-Activ. administratives & autres activ. soutien aux entreprises',
    },
    {
        code: '8220Z',
        name: "Activités de centres d'appels",
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '82-Activ. administratives & autres activ. soutien aux entreprises',
    },
    {
        code: '823',
        name: 'Organisation de salons professionnels et congrès',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '82-Activ. administratives & autres activ. soutien aux entreprises',
    },
    {
        code: '8230',
        name: 'Organisation de salons professionnels et congrès',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '82-Activ. administratives & autres activ. soutien aux entreprises',
    },
    {
        code: '8230Z',
        name: 'Organisation de foires, salons professionnels et congrès',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '82-Activ. administratives & autres activ. soutien aux entreprises',
    },
    {
        code: '829',
        name: 'Activités de soutien aux entreprises n.c.a.',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '82-Activ. administratives & autres activ. soutien aux entreprises',
    },
    {
        code: '8291',
        name: "Activ. de recouvrement factures & d'info. financ. s/la clientèle",
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '82-Activ. administratives & autres activ. soutien aux entreprises',
    },
    {
        code: '8291Z',
        name: "Activ. de recouvrement factures & d'info. financ. s/la clientèle",
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '82-Activ. administratives & autres activ. soutien aux entreprises',
    },
    {
        code: '8292',
        name: 'Activités de conditionnement',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '82-Activ. administratives & autres activ. soutien aux entreprises',
    },
    {
        code: '8292Z',
        name: 'Activités de conditionnement',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '82-Activ. administratives & autres activ. soutien aux entreprises',
    },
    {
        code: '8299',
        name: 'Autres activités de soutien aux entreprises n.c.a.',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '82-Activ. administratives & autres activ. soutien aux entreprises',
    },
    {
        code: '8299Z',
        name: 'Autres activités de soutien aux entreprises n.c.a.',
        section: 'Section N - Activités De Sce Administr. & De Soutien',
        category: '82-Activ. administratives & autres activ. soutien aux entreprises',
    },
    {
        code: '84',
        name: 'Administration publique et défense ; sécurité sociale obligatoire',
        section: 'Section O - Administration Publique',
        category: '84-Administration publique et défense ; sécurité sociale obligatoire',
    },
    {
        code: '841',
        name: 'Administration générale, économique et sociale',
        section: 'Section O - Administration Publique',
        category: '84-Administration publique et défense ; sécurité sociale obligatoire',
    },
    {
        code: '8411',
        name: 'Administration publique générale',
        section: 'Section O - Administration Publique',
        category: '84-Administration publique et défense ; sécurité sociale obligatoire',
    },
    {
        code: '8411Z',
        name: 'Administration publique générale',
        section: 'Section O - Administration Publique',
        category: '84-Administration publique et défense ; sécurité sociale obligatoire',
    },
    {
        code: '8412',
        name: 'Adm. pub. tutelle santé form. cult. & social (aut que sécu. soc.)',
        section: 'Section O - Administration Publique',
        category: '84-Administration publique et défense ; sécurité sociale obligatoire',
    },
    {
        code: '8412Z',
        name: 'Adm. pub. tutelle santé form. cult. & social (aut que sécu. soc.)',
        section: 'Section O - Administration Publique',
        category: '84-Administration publique et défense ; sécurité sociale obligatoire',
    },
    {
        code: '8413',
        name: 'Administration publique (tutelle) des activités économiques',
        section: 'Section O - Administration Publique',
        category: '84-Administration publique et défense ; sécurité sociale obligatoire',
    },
    {
        code: '8413Z',
        name: 'Administration publique (tutelle) des activités économiques',
        section: 'Section O - Administration Publique',
        category: '84-Administration publique et défense ; sécurité sociale obligatoire',
    },
    {
        code: '842',
        name: 'Services de prérogative publique',
        section: 'Section O - Administration Publique',
        category: '84-Administration publique et défense ; sécurité sociale obligatoire',
    },
    {
        code: '8422',
        name: 'Défense',
        section: 'Section O - Administration Publique',
        category: '84-Administration publique et défense ; sécurité sociale obligatoire',
    },
    {
        code: '8422Z',
        name: 'Défense',
        section: 'Section O - Administration Publique',
        category: '84-Administration publique et défense ; sécurité sociale obligatoire',
    },
    {
        code: '8423',
        name: 'Justice',
        section: 'Section O - Administration Publique',
        category: '84-Administration publique et défense ; sécurité sociale obligatoire',
    },
    {
        code: '8423Z',
        name: 'Justice',
        section: 'Section O - Administration Publique',
        category: '84-Administration publique et défense ; sécurité sociale obligatoire',
    },
    {
        code: '8424',
        name: 'Activités d’ordre public et de sécurité',
        section: 'Section O - Administration Publique',
        category: '84-Administration publique et défense ; sécurité sociale obligatoire',
    },
    {
        code: '8424Z',
        name: 'Activités d’ordre public et de sécurité',
        section: 'Section O - Administration Publique',
        category: '84-Administration publique et défense ; sécurité sociale obligatoire',
    },
    {
        code: '8425',
        name: 'Services du feu et de secours',
        section: 'Section O - Administration Publique',
        category: '84-Administration publique et défense ; sécurité sociale obligatoire',
    },
    {
        code: '8425Z',
        name: 'Services du feu et de secours',
        section: 'Section O - Administration Publique',
        category: '84-Administration publique et défense ; sécurité sociale obligatoire',
    },
    {
        code: '843',
        name: 'Sécurité sociale obligatoire',
        section: 'Section O - Administration Publique',
        category: '84-Administration publique et défense ; sécurité sociale obligatoire',
    },
    {
        code: '8430',
        name: 'Sécurité sociale obligatoire',
        section: 'Section O - Administration Publique',
        category: '84-Administration publique et défense ; sécurité sociale obligatoire',
    },
    {
        code: '8430A',
        name: 'Activités générales de sécurité sociale',
        section: 'Section O - Administration Publique',
        category: '84-Administration publique et défense ; sécurité sociale obligatoire',
    },
    {
        code: '8430B',
        name: 'Gestion des retraites complémentaires',
        section: 'Section O - Administration Publique',
        category: '84-Administration publique et défense ; sécurité sociale obligatoire',
    },
    {
        code: '8430C',
        name: 'Distribution sociale de revenus',
        section: 'Section O - Administration Publique',
        category: '84-Administration publique et défense ; sécurité sociale obligatoire',
    },
    {
        code: '85',
        name: 'Enseignement',
        section: 'Section P - Enseignement',
        category: '85-Enseignement',
    },
    {
        code: '851',
        name: 'Enseignement pré-primaire',
        section: 'Section P - Enseignement',
        category: '85-Enseignement',
    },
    {
        code: '8510',
        name: 'Enseignement pré-primaire',
        section: 'Section P - Enseignement',
        category: '85-Enseignement',
    },
    {
        code: '8510Z',
        name: 'Enseignement pré-primaire',
        section: 'Section P - Enseignement',
        category: '85-Enseignement',
    },
    {
        code: '852',
        name: 'Enseignement primaire',
        section: 'Section P - Enseignement',
        category: '85-Enseignement',
    },
    {
        code: '8520',
        name: 'Enseignement primaire',
        section: 'Section P - Enseignement',
        category: '85-Enseignement',
    },
    {
        code: '8520Z',
        name: 'Enseignement primaire',
        section: 'Section P - Enseignement',
        category: '85-Enseignement',
    },
    {
        code: '853',
        name: 'Enseignement secondaire',
        section: 'Section P - Enseignement',
        category: '85-Enseignement',
    },
    {
        code: '8531',
        name: 'Enseignement secondaire général',
        section: 'Section P - Enseignement',
        category: '85-Enseignement',
    },
    {
        code: '8531Z',
        name: 'Enseignement secondaire général',
        section: 'Section P - Enseignement',
        category: '85-Enseignement',
    },
    {
        code: '8532',
        name: 'Enseignement secondaire technique ou professionnel',
        section: 'Section P - Enseignement',
        category: '85-Enseignement',
    },
    {
        code: '8532Z',
        name: 'Enseignement secondaire technique ou professionnel',
        section: 'Section P - Enseignement',
        category: '85-Enseignement',
    },
    {
        code: '854',
        name: 'Enseignement supérieur et post-secondaire non supérieur',
        section: 'Section P - Enseignement',
        category: '85-Enseignement',
    },
    {
        code: '8541',
        name: 'Enseignement post-secondaire non supérieur',
        section: 'Section P - Enseignement',
        category: '85-Enseignement',
    },
    {
        code: '8541Z',
        name: 'Enseignement post-secondaire non supérieur',
        section: 'Section P - Enseignement',
        category: '85-Enseignement',
    },
    {
        code: '8542',
        name: 'Enseignement supérieur',
        section: 'Section P - Enseignement',
        category: '85-Enseignement',
    },
    {
        code: '8542Z',
        name: 'Enseignement supérieur',
        section: 'Section P - Enseignement',
        category: '85-Enseignement',
    },
    {
        code: '855',
        name: "Autres activités d'enseignement",
        section: 'Section P - Enseignement',
        category: '85-Enseignement',
    },
    {
        code: '8551',
        name: "Enseignement de disciplines sportives et d'activités de loisirs",
        section: 'Section P - Enseignement',
        category: '85-Enseignement',
    },
    {
        code: '8551Z',
        name: "Enseignement de disciplines sportives et d'activités de loisirs",
        section: 'Section P - Enseignement',
        category: '85-Enseignement',
    },
    {
        code: '8552',
        name: 'Enseignement culturel',
        section: 'Section P - Enseignement',
        category: '85-Enseignement',
    },
    {
        code: '8552Z',
        name: 'Enseignement culturel',
        section: 'Section P - Enseignement',
        category: '85-Enseignement',
    },
    {
        code: '8553',
        name: 'Enseignement de la conduite',
        section: 'Section P - Enseignement',
        category: '85-Enseignement',
    },
    {
        code: '8553Z',
        name: 'Enseignement de la conduite',
        section: 'Section P - Enseignement',
        category: '85-Enseignement',
    },
    {
        code: '8559',
        name: 'Enseignements divers',
        section: 'Section P - Enseignement',
        category: '85-Enseignement',
    },
    {
        code: '8559A',
        name: "Formation continue d'adultes",
        section: 'Section P - Enseignement',
        category: '85-Enseignement',
    },
    {
        code: '8559B',
        name: 'Autres enseignements',
        section: 'Section P - Enseignement',
        category: '85-Enseignement',
    },
    {
        code: '856',
        name: "Activités de soutien à l'enseignement",
        section: 'Section P - Enseignement',
        category: '85-Enseignement',
    },
    {
        code: '8560',
        name: "Activités de soutien à l'enseignement",
        section: 'Section P - Enseignement',
        category: '85-Enseignement',
    },
    {
        code: '8560Z',
        name: "Activités de soutien à l'enseignement",
        section: 'Section P - Enseignement',
        category: '85-Enseignement',
    },
    {
        code: '86',
        name: 'Activités pour la santé humaine',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '86-Activités pour la santé humaine',
    },
    {
        code: '861',
        name: 'Activités hospitalières',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '86-Activités pour la santé humaine',
    },
    {
        code: '8610',
        name: 'Activités hospitalières',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '86-Activités pour la santé humaine',
    },
    {
        code: '8610Z',
        name: 'Activités hospitalières',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '86-Activités pour la santé humaine',
    },
    {
        code: '862',
        name: 'Activité des médecins et des dentistes',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '86-Activités pour la santé humaine',
    },
    {
        code: '8621',
        name: 'Activité des médecins généralistes',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '86-Activités pour la santé humaine',
    },
    {
        code: '8621Z',
        name: 'Activité des médecins généralistes',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '86-Activités pour la santé humaine',
    },
    {
        code: '8622',
        name: 'Activité des médecins spécialistes',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '86-Activités pour la santé humaine',
    },
    {
        code: '8622A',
        name: 'Activités de radiodiagnostic et de radiothérapie',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '86-Activités pour la santé humaine',
    },
    {
        code: '8622B',
        name: 'Activités chirurgicales',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '86-Activités pour la santé humaine',
    },
    {
        code: '8622C',
        name: 'Autres activités des médecins spécialistes',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '86-Activités pour la santé humaine',
    },
    {
        code: '8623',
        name: 'Pratique dentaire',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '86-Activités pour la santé humaine',
    },
    {
        code: '8623Z',
        name: 'Pratique dentaire',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '86-Activités pour la santé humaine',
    },
    {
        code: '869',
        name: 'Autres activités pour la santé humaine',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '86-Activités pour la santé humaine',
    },
    {
        code: '8690',
        name: 'Autres activités pour la santé humaine',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '86-Activités pour la santé humaine',
    },
    {
        code: '8690A',
        name: 'Ambulances',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '86-Activités pour la santé humaine',
    },
    {
        code: '8690B',
        name: "Laboratoires d'analyses médicales",
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '86-Activités pour la santé humaine',
    },
    {
        code: '8690C',
        name: "Centres de collecte et banques d'organes",
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '86-Activités pour la santé humaine',
    },
    {
        code: '8690D',
        name: 'Activités des infirmiers et des sages-femmes',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '86-Activités pour la santé humaine',
    },
    {
        code: '8690E',
        name: 'Activité profess. rééducation appareillage & pédicures-podologues',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '86-Activités pour la santé humaine',
    },
    {
        code: '8690F',
        name: 'Activités de santé humaine non classées ailleurs',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '86-Activités pour la santé humaine',
    },
    {
        code: '87',
        name: 'Hébergement médico-social et social',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '87-Hébergement médico-social et social',
    },
    {
        code: '871',
        name: 'Hébergement médicalisé',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '87-Hébergement médico-social et social',
    },
    {
        code: '8710',
        name: 'Hébergement médicalisé',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '87-Hébergement médico-social et social',
    },
    {
        code: '8710A',
        name: 'Hébergement médicalisé pour personnes âgées',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '87-Hébergement médico-social et social',
    },
    {
        code: '8710B',
        name: 'Hébergement médicalisé pour enfants handicapés',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '87-Hébergement médico-social et social',
    },
    {
        code: '8710C',
        name: 'Héberg. médicalisé adultes handicapés & autre héberg. médicalisé',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '87-Hébergement médico-social et social',
    },
    {
        code: '872',
        name: 'Héberg. social person. handicap. & malades mentales & toxicomanes',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '87-Hébergement médico-social et social',
    },
    {
        code: '8720',
        name: 'Héberg. social person. handicap. & malades mentales & toxicomanes',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '87-Hébergement médico-social et social',
    },
    {
        code: '8720A',
        name: 'Hébergement social pour handicapés mentaux et malades mentaux',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '87-Hébergement médico-social et social',
    },
    {
        code: '8720B',
        name: 'Hébergement social pour toxicomanes',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '87-Hébergement médico-social et social',
    },
    {
        code: '873',
        name: 'Hébergement social pour personnes âgées ou handicapées physiques',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '87-Hébergement médico-social et social',
    },
    {
        code: '8730',
        name: 'Hébergement social pour personnes âgées ou handicapées physiques',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '87-Hébergement médico-social et social',
    },
    {
        code: '8730A',
        name: 'Hébergement social pour personnes âgées',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '87-Hébergement médico-social et social',
    },
    {
        code: '8730B',
        name: 'Hébergement social pour handicapés  physiques',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '87-Hébergement médico-social et social',
    },
    {
        code: '879',
        name: 'Autres activités d’hébergement social',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '87-Hébergement médico-social et social',
    },
    {
        code: '8790',
        name: 'Autres activités d’hébergement social',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '87-Hébergement médico-social et social',
    },
    {
        code: '8790A',
        name: 'Hébergement social pour enfants en difficultés',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '87-Hébergement médico-social et social',
    },
    {
        code: '8790B',
        name: 'Hébergement social pour adultes, familles en difficultés et autre',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '87-Hébergement médico-social et social',
    },
    {
        code: '88',
        name: 'Action sociale sans hébergement',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '88-Action sociale sans hébergement',
    },
    {
        code: '881',
        name: 'Action soc. sans héberg. pers. âgées & handicapées',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '88-Action sociale sans hébergement',
    },
    {
        code: '8810',
        name: 'Action soc. sans héberg. pers. âgées & handicapées',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '88-Action sociale sans hébergement',
    },
    {
        code: '8810A',
        name: 'Aide à domicile',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '88-Action sociale sans hébergement',
    },
    {
        code: '8810B',
        name: 'Accueil accompagn. sans héberg. adultes handicapés ou pers. âgées',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '88-Action sociale sans hébergement',
    },
    {
        code: '8810C',
        name: 'Aide par le travail',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '88-Action sociale sans hébergement',
    },
    {
        code: '889',
        name: 'Autre action sociale sans hébergement',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '88-Action sociale sans hébergement',
    },
    {
        code: '8891',
        name: 'Action sociale sans hébergement pour jeunes enfants',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '88-Action sociale sans hébergement',
    },
    {
        code: '8891A',
        name: 'Accueil de jeunes enfants',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '88-Action sociale sans hébergement',
    },
    {
        code: '8891B',
        name: 'Accueil ou accompagnement sans hébergement d’enfants handicapés',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '88-Action sociale sans hébergement',
    },
    {
        code: '8899',
        name: 'Autre action sociale sans hébergement n.c.a.',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '88-Action sociale sans hébergement',
    },
    {
        code: '8899A',
        name: 'Aut. accueil ou accompgnt sans hébergt d’enfants et adolescents',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '88-Action sociale sans hébergement',
    },
    {
        code: '8899B',
        name: 'Action sociale sans hébergement n.c.a.',
        section: 'Section Q - Santé Humaine et Action Sociale',
        category: '88-Action sociale sans hébergement',
    },
    {
        code: '90',
        name: 'Activités créatives, artistiques et de spectacle',
        section: 'Section R - Arts, Spectacles & Activités Récréatives',
        category: '90-Activités créatives, artistiques et de spectacle',
    },
    {
        code: '900',
        name: 'Activités créatives, artistiques et de spectacle',
        section: 'Section R - Arts, Spectacles & Activités Récréatives',
        category: '90-Activités créatives, artistiques et de spectacle',
    },
    {
        code: '9001',
        name: 'Arts du spectacle vivant',
        section: 'Section R - Arts, Spectacles & Activités Récréatives',
        category: '90-Activités créatives, artistiques et de spectacle',
    },
    {
        code: '9001Z',
        name: 'Arts du spectacle vivant',
        section: 'Section R - Arts, Spectacles & Activités Récréatives',
        category: '90-Activités créatives, artistiques et de spectacle',
    },
    {
        code: '9002',
        name: 'Activités de soutien au spectacle vivant',
        section: 'Section R - Arts, Spectacles & Activités Récréatives',
        category: '90-Activités créatives, artistiques et de spectacle',
    },
    {
        code: '9002Z',
        name: 'Activités de soutien au spectacle vivant',
        section: 'Section R - Arts, Spectacles & Activités Récréatives',
        category: '90-Activités créatives, artistiques et de spectacle',
    },
    {
        code: '9003',
        name: 'Création artistique',
        section: 'Section R - Arts, Spectacles & Activités Récréatives',
        category: '90-Activités créatives, artistiques et de spectacle',
    },
    {
        code: '9003A',
        name: 'Création artistique relevant des arts plastiques',
        section: 'Section R - Arts, Spectacles & Activités Récréatives',
        category: '90-Activités créatives, artistiques et de spectacle',
    },
    {
        code: '9003B',
        name: 'Autre création artistique',
        section: 'Section R - Arts, Spectacles & Activités Récréatives',
        category: '90-Activités créatives, artistiques et de spectacle',
    },
    {
        code: '9004',
        name: 'Gestion de salles de spectacles',
        section: 'Section R - Arts, Spectacles & Activités Récréatives',
        category: '90-Activités créatives, artistiques et de spectacle',
    },
    {
        code: '9004Z',
        name: 'Gestion de salles de spectacles',
        section: 'Section R - Arts, Spectacles & Activités Récréatives',
        category: '90-Activités créatives, artistiques et de spectacle',
    },
    {
        code: '91',
        name: 'Bibliothèques, archives, musées et autres activités culturelles',
        section: 'Section R - Arts, Spectacles & Activités Récréatives',
        category: '91-Bibliothèques, archives, musées et autres activités culturelles',
    },
    {
        code: '910',
        name: 'Bibliothèques, archives, musées et autres activités culturelles',
        section: 'Section R - Arts, Spectacles & Activités Récréatives',
        category: '91-Bibliothèques, archives, musées et autres activités culturelles',
    },
    {
        code: '9101',
        name: 'Gestion des bibliothèques et des archives',
        section: 'Section R - Arts, Spectacles & Activités Récréatives',
        category: '91-Bibliothèques, archives, musées et autres activités culturelles',
    },
    {
        code: '9101Z',
        name: 'Gestion des bibliothèques et des archives',
        section: 'Section R - Arts, Spectacles & Activités Récréatives',
        category: '91-Bibliothèques, archives, musées et autres activités culturelles',
    },
    {
        code: '9102',
        name: 'Gestion des musées',
        section: 'Section R - Arts, Spectacles & Activités Récréatives',
        category: '91-Bibliothèques, archives, musées et autres activités culturelles',
    },
    {
        code: '9102Z',
        name: 'Gestion des musées',
        section: 'Section R - Arts, Spectacles & Activités Récréatives',
        category: '91-Bibliothèques, archives, musées et autres activités culturelles',
    },
    {
        code: '9103',
        name: 'Gestion sites monuments historiques & attractions tourist. simil.',
        section: 'Section R - Arts, Spectacles & Activités Récréatives',
        category: '91-Bibliothèques, archives, musées et autres activités culturelles',
    },
    {
        code: '9103Z',
        name: 'Gestion sites monuments historiques & attractions tourist. simil.',
        section: 'Section R - Arts, Spectacles & Activités Récréatives',
        category: '91-Bibliothèques, archives, musées et autres activités culturelles',
    },
    {
        code: '9104',
        name: 'Gest. des jardins botaniques et zoolog. et des réserv. naturelles',
        section: 'Section R - Arts, Spectacles & Activités Récréatives',
        category: '91-Bibliothèques, archives, musées et autres activités culturelles',
    },
    {
        code: '9104Z',
        name: 'Gest. des jardins botaniques et zoolog. et des réserv. naturelles',
        section: 'Section R - Arts, Spectacles & Activités Récréatives',
        category: '91-Bibliothèques, archives, musées et autres activités culturelles',
    },
    {
        code: '92',
        name: "Organisation de jeux de hasard et d'argent",
        section: 'Section R - Arts, Spectacles & Activités Récréatives',
        category: "92-Organisation de jeux de hasard et d'argent",
    },
    {
        code: '920',
        name: "Organisation de jeux de hasard et d'argent",
        section: 'Section R - Arts, Spectacles & Activités Récréatives',
        category: "92-Organisation de jeux de hasard et d'argent",
    },
    {
        code: '9200',
        name: "Organisation de jeux de hasard et d'argent",
        section: 'Section R - Arts, Spectacles & Activités Récréatives',
        category: "92-Organisation de jeux de hasard et d'argent",
    },
    {
        code: '9200Z',
        name: "Organisation de jeux de hasard et d'argent",
        section: 'Section R - Arts, Spectacles & Activités Récréatives',
        category: "92-Organisation de jeux de hasard et d'argent",
    },
    {
        code: '93',
        name: 'Activités sportives, récréatives et de loisirs',
        section: 'Section R - Arts, Spectacles & Activités Récréatives',
        category: '93-Activités sportives, récréatives et de loisirs',
    },
    {
        code: '931',
        name: 'Activités liées au sport',
        section: 'Section R - Arts, Spectacles & Activités Récréatives',
        category: '93-Activités sportives, récréatives et de loisirs',
    },
    {
        code: '9311',
        name: "Gestion d'installations sportives",
        section: 'Section R - Arts, Spectacles & Activités Récréatives',
        category: '93-Activités sportives, récréatives et de loisirs',
    },
    {
        code: '9311Z',
        name: "Gestion d'installations sportives",
        section: 'Section R - Arts, Spectacles & Activités Récréatives',
        category: '93-Activités sportives, récréatives et de loisirs',
    },
    {
        code: '9312',
        name: 'Activités de clubs de sports',
        section: 'Section R - Arts, Spectacles & Activités Récréatives',
        category: '93-Activités sportives, récréatives et de loisirs',
    },
    {
        code: '9312Z',
        name: 'Activités de clubs de sports',
        section: 'Section R - Arts, Spectacles & Activités Récréatives',
        category: '93-Activités sportives, récréatives et de loisirs',
    },
    {
        code: '9313',
        name: 'Activités des centres de culture physique',
        section: 'Section R - Arts, Spectacles & Activités Récréatives',
        category: '93-Activités sportives, récréatives et de loisirs',
    },
    {
        code: '9313Z',
        name: 'Activités des centres de culture physique',
        section: 'Section R - Arts, Spectacles & Activités Récréatives',
        category: '93-Activités sportives, récréatives et de loisirs',
    },
    {
        code: '9319',
        name: 'Autres activités liées au sport',
        section: 'Section R - Arts, Spectacles & Activités Récréatives',
        category: '93-Activités sportives, récréatives et de loisirs',
    },
    {
        code: '9319Z',
        name: 'Autres activités liées au sport',
        section: 'Section R - Arts, Spectacles & Activités Récréatives',
        category: '93-Activités sportives, récréatives et de loisirs',
    },
    {
        code: '932',
        name: 'Activités récréatives et de loisirs',
        section: 'Section R - Arts, Spectacles & Activités Récréatives',
        category: '93-Activités sportives, récréatives et de loisirs',
    },
    {
        code: '9321',
        name: "Activités des parcs d'attractions et parcs à thèmes",
        section: 'Section R - Arts, Spectacles & Activités Récréatives',
        category: '93-Activités sportives, récréatives et de loisirs',
    },
    {
        code: '9321Z',
        name: "Activités des parcs d'attractions et parcs à thèmes",
        section: 'Section R - Arts, Spectacles & Activités Récréatives',
        category: '93-Activités sportives, récréatives et de loisirs',
    },
    {
        code: '9329',
        name: 'Autres activités récréatives et de loisirs',
        section: 'Section R - Arts, Spectacles & Activités Récréatives',
        category: '93-Activités sportives, récréatives et de loisirs',
    },
    {
        code: '9329Z',
        name: 'Autres activités récréatives et de loisirs',
        section: 'Section R - Arts, Spectacles & Activités Récréatives',
        category: '93-Activités sportives, récréatives et de loisirs',
    },
    {
        code: '94',
        name: 'Activités des organisations associatives',
        section: 'Section S - Autres Activités De Services',
        category: '94-Activités des organisations associatives',
    },
    {
        code: '941',
        name: 'Activ. organisations économiques, patronales et professionnelles',
        section: 'Section S - Autres Activités De Services',
        category: '94-Activités des organisations associatives',
    },
    {
        code: '9411',
        name: 'Activités des organisations patronales et consulaires',
        section: 'Section S - Autres Activités De Services',
        category: '94-Activités des organisations associatives',
    },
    {
        code: '9411Z',
        name: 'Activités des organisations patronales et consulaires',
        section: 'Section S - Autres Activités De Services',
        category: '94-Activités des organisations associatives',
    },
    {
        code: '9412',
        name: 'Activités des organisations professionnelles',
        section: 'Section S - Autres Activités De Services',
        category: '94-Activités des organisations associatives',
    },
    {
        code: '9412Z',
        name: 'Activités des organisations professionnelles',
        section: 'Section S - Autres Activités De Services',
        category: '94-Activités des organisations associatives',
    },
    {
        code: '942',
        name: 'Activités des syndicats de salariés',
        section: 'Section S - Autres Activités De Services',
        category: '94-Activités des organisations associatives',
    },
    {
        code: '9420',
        name: 'Activités des syndicats de salariés',
        section: 'Section S - Autres Activités De Services',
        category: '94-Activités des organisations associatives',
    },
    {
        code: '9420Z',
        name: 'Activités des syndicats de salariés',
        section: 'Section S - Autres Activités De Services',
        category: '94-Activités des organisations associatives',
    },
    {
        code: '949',
        name: 'Activités des autres organisations associatives',
        section: 'Section S - Autres Activités De Services',
        category: '94-Activités des organisations associatives',
    },
    {
        code: '9491',
        name: 'Activités des organisations religieuses',
        section: 'Section S - Autres Activités De Services',
        category: '94-Activités des organisations associatives',
    },
    {
        code: '9491Z',
        name: 'Activités des organisations religieuses',
        section: 'Section S - Autres Activités De Services',
        category: '94-Activités des organisations associatives',
    },
    {
        code: '9492',
        name: 'Activités des organisations politiques',
        section: 'Section S - Autres Activités De Services',
        category: '94-Activités des organisations associatives',
    },
    {
        code: '9492Z',
        name: 'Activités des organisations politiques',
        section: 'Section S - Autres Activités De Services',
        category: '94-Activités des organisations associatives',
    },
    {
        code: '9499',
        name: 'Activités des organisations associatives n.c.a.',
        section: 'Section S - Autres Activités De Services',
        category: '94-Activités des organisations associatives',
    },
    {
        code: '9499Z',
        name: 'Autres organisations fonctionnant par adhésion volontaire',
        section: 'Section S - Autres Activités De Services',
        category: '94-Activités des organisations associatives',
    },
    {
        code: '95',
        name: "Réparation d'ordinateurs et de biens personnels et domestiques",
        section: 'Section S - Autres Activités De Services',
        category: 'section s - Autres Activités De Services',
    },
    {
        code: '951',
        name: "Réparation d'ordinateurs et d'équipements de communication",
        section: 'Section S - Autres Activités De Services',
        category: 'section s - Autres Activités De Services',
    },
    {
        code: '9511',
        name: "Réparation d'ordinateurs et d'équipements périphériques",
        section: 'Section S - Autres Activités De Services',
        category: 'section s - Autres Activités De Services',
    },
    {
        code: '9511Z',
        name: "Réparation d'ordinateurs et d'équipements périphériques",
        section: 'Section S - Autres Activités De Services',
        category: 'section s - Autres Activités De Services',
    },
    {
        code: '9512',
        name: "Réparation d'équipements de communication",
        section: 'Section S - Autres Activités De Services',
        category: 'section s - Autres Activités De Services',
    },
    {
        code: '9512Z',
        name: "Réparation d'équipements de communication",
        section: 'Section S - Autres Activités De Services',
        category: 'section s - Autres Activités De Services',
    },
    {
        code: '952',
        name: 'Réparation de biens personnels et domestiques',
        section: 'Section S - Autres Activités De Services',
        category: 'section s - Autres Activités De Services',
    },
    {
        code: '9521',
        name: 'Réparation de produits électroniques grand public',
        section: 'Section S - Autres Activités De Services',
        category: 'section s - Autres Activités De Services',
    },
    {
        code: '9521Z',
        name: 'Réparation de produits électroniques grand public',
        section: 'Section S - Autres Activités De Services',
        category: 'section s - Autres Activités De Services',
    },
    {
        code: '9522',
        name: 'Réparation appareils électromén. & équipts maison & jardin',
        section: 'Section S - Autres Activités De Services',
        category: 'section s - Autres Activités De Services',
    },
    {
        code: '9522Z',
        name: 'Réparation appareils électromén. & équipts maison & jardin',
        section: 'Section S - Autres Activités De Services',
        category: 'section s - Autres Activités De Services',
    },
    {
        code: '9523',
        name: "Réparation de chaussures et d'articles en cuir",
        section: 'Section S - Autres Activités De Services',
        category: 'section s - Autres Activités De Services',
    },
    {
        code: '9523Z',
        name: "Réparation de chaussures et d'articles en cuir",
        section: 'Section S - Autres Activités De Services',
        category: 'section s - Autres Activités De Services',
    },
    {
        code: '9524',
        name: "Réparation de meubles et d'équipements du foyer",
        section: 'Section S - Autres Activités De Services',
        category: 'section s - Autres Activités De Services',
    },
    {
        code: '9524Z',
        name: "Réparation de meubles et d'équipements du foyer",
        section: 'Section S - Autres Activités De Services',
        category: 'section s - Autres Activités De Services',
    },
    {
        code: '9525',
        name: "Réparation d'articles d'horlogerie et de bijouterie",
        section: 'Section S - Autres Activités De Services',
        category: 'section s - Autres Activités De Services',
    },
    {
        code: '9525Z',
        name: "Réparation d'articles d'horlogerie et de bijouterie",
        section: 'Section S - Autres Activités De Services',
        category: 'section s - Autres Activités De Services',
    },
    {
        code: '9529',
        name: "Réparation d'autres biens personnels et domestiques",
        section: 'Section S - Autres Activités De Services',
        category: 'section s - Autres Activités De Services',
    },
    {
        code: '9529Z',
        name: "Réparation d'autres biens personnels et domestiques",
        section: 'Section S - Autres Activités De Services',
        category: 'section s - Autres Activités De Services',
    },
    {
        code: '96',
        name: 'Autres services personnels',
        section: 'Section S - Autres Activités De Services',
        category: '96-Autres services personnels',
    },
    {
        code: '960',
        name: 'Autres services personnels',
        section: 'Section S - Autres Activités De Services',
        category: '96-Autres services personnels',
    },
    {
        code: '9601',
        name: 'Blanchisserie-teinturerie',
        section: 'Section S - Autres Activités De Services',
        category: '96-Autres services personnels',
    },
    {
        code: '9601A',
        name: 'Blanchisserie-teinturerie de gros',
        section: 'Section S - Autres Activités De Services',
        category: '96-Autres services personnels',
    },
    {
        code: '9601B',
        name: 'Blanchisserie-teinturerie de détail',
        section: 'Section S - Autres Activités De Services',
        category: '96-Autres services personnels',
    },
    {
        code: '9602',
        name: 'Coiffure et soins de beauté',
        section: 'Section S - Autres Activités De Services',
        category: '96-Autres services personnels',
    },
    {
        code: '9602A',
        name: 'Coiffure',
        section: 'Section S - Autres Activités De Services',
        category: '96-Autres services personnels',
    },
    {
        code: '9602B',
        name: 'Soins de beauté',
        section: 'Section S - Autres Activités De Services',
        category: '96-Autres services personnels',
    },
    {
        code: '9603',
        name: 'Services funéraires',
        section: 'Section S - Autres Activités De Services',
        category: '96-Autres services personnels',
    },
    {
        code: '9603Z',
        name: 'Services funéraires',
        section: 'Section S - Autres Activités De Services',
        category: '96-Autres services personnels',
    },
    {
        code: '9604',
        name: 'Entretien corporel',
        section: 'Section S - Autres Activités De Services',
        category: '96-Autres services personnels',
    },
    {
        code: '9604Z',
        name: 'Entretien corporel',
        section: 'Section S - Autres Activités De Services',
        category: '96-Autres services personnels',
    },
    {
        code: '9609',
        name: 'Autres services personnels n.c.a.',
        section: 'Section S - Autres Activités De Services',
        category: '96-Autres services personnels',
    },
    {
        code: '9609Z',
        name: 'Autres services personnels n.c.a.',
        section: 'Section S - Autres Activités De Services',
        category: '96-Autres services personnels',
    },
    {
        code: '99',
        name: 'Activités des organisations et organismes extraterritoriaux',
        section: '99-Activités des organisations et organismes extraterritoriaux',
        category: '99-Activités des organisations et organismes extraterritoriaux',
    },
    {
        code: '990',
        name: 'Activités des organisations et organismes extraterritoriaux',
        section: '99-Activités des organisations et organismes extraterritoriaux',
        category: '99-Activités des organisations et organismes extraterritoriaux',
    },
    {
        code: '9900',
        name: 'Activités des organisations et organismes extraterritoriaux',
        section: '99-Activités des organisations et organismes extraterritoriaux',
        category: '99-Activités des organisations et organismes extraterritoriaux',
    },
    {
        code: '9900Z',
        name: 'Activités des organisations et organismes extraterritoriaux',
        section: '99-Activités des organisations et organismes extraterritoriaux',
        category: '99-Activités des organisations et organismes extraterritoriaux',
    },
];
