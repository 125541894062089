import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Button, FormControl, FormLabel, HStack, Text, VStack, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_IMPORT_CONTACT } from 'analytics/useAnalytics';
import { format } from 'date-fns';
import { t } from 'i18next';
import DateTimePickerInput from 'components/inputs/DateTimePickerInput';
import SelectInput from 'components/inputs/SelectInput';
import useImportContacts from 'hooks/import/useImportContacts';
const DUPLICATED_CHECK_OPTIONS = [
    {
        label: t('contact.fields.update_fields_options.no_update'),
        value: '',
    },
    {
        label: t('contact.fields.update_fields_options.email'),
        value: 'email',
    },
    {
        label: t('contact.fields.update_fields_options.phone'),
        value: 'phone',
    },
    {
        label: t('contact.fields.update_fields_options.lastname_town'),
        value: 'lastname_town',
    },
];
const shouldSelectTown = (selectedFields) => Object.values(selectedFields).includes('plotsNumbers');
const ThirdStep = ({ children, fileData, selectedTab, importData, handleNextStep, handleLastStep, trackPrefix = '', textLastStep = 'contact.actions.finish', }) => {
    const { trackEvent } = useAnalytics();
    const methods = useForm({
        defaultValues: {
            duplicateCheck: 'lastname_town',
            courierDate: '',
        },
        mode: 'onSubmit',
    });
    const { handleSubmit } = methods;
    const { finishImportContacts, isLoading } = useImportContacts();
    const [selectedTabData, setSelectedTabData] = useState();
    const [selectedFields, setSelectedFields] = useState({});
    const [hasCourierDate, setHasCourierDate] = useState(false);
    const formRef = useRef(null);
    const isLastStep = shouldSelectTown(selectedFields);
    useEffect(() => {
        const tabData = fileData.tabs.filter((tab) => tab.tabTitle === selectedTab)[0];
        if (importData) {
            importData.fields.forEach((field, index) => {
                if (field.fieldDb !== '') {
                    tabData.content[index].fieldDb = field.fieldDb;
                }
            });
        }
        setSelectedTabData(tabData);
        const selectedFieldsDefault = {};
        tabData.content.forEach((field, index) => {
            if (field.fieldDb !== '') {
                selectedFieldsDefault[index] = field.fieldDb;
                if (field.fieldDb === 'courierDate') {
                    setHasCourierDate(true);
                }
            }
        });
        setSelectedFields(selectedFieldsDefault);
    }, [selectedTab, importData]);
    const handleOnChange = (index) => (e) => {
        const form = new FormData(formRef.current);
        let hasCourierDate = false;
        form.forEach((value) => {
            if (value === 'courierDate') {
                hasCourierDate = true;
            }
        });
        setHasCourierDate(hasCourierDate);
        setSelectedFields((prev) => ({ ...prev, [index]: e.target.value }));
    };
    const onSubmit = (data) => {
        const fields = selectedTabData.content.map((tabContent, index) => {
            return {
                fieldFile: tabContent.fieldFile,
                fieldDb: data?.fieldsDb[index],
            };
        });
        const importContactsData = {
            fields,
            duplicateCheck: data.duplicateCheck,
            courierDate: data.courierDate
                ? format(data.courierDate, 'yyyy-MM-dd HH:mm:ss')
                : '',
            importId: fileData.importId,
            tab: selectedTab,
        };
        if (shouldSelectTown(fields.map((field) => field.fieldDb))) {
            handleNextStep(importContactsData);
            trackEvent({
                category: ANALYTICS_IMPORT_CONTACT,
                action: trackPrefix + "Sélectionner les champs de l'import",
            });
        }
        else {
            finishImportContacts(importContactsData, handleLastStep);
            trackEvent({
                category: ANALYTICS_IMPORT_CONTACT,
                action: trackPrefix + "Terminer l'import",
            });
        }
    };
    const propsFileLabel = {
        width: '33%',
        marginRight: 0,
        marginBottom: 0,
    };
    const propsHeader = {
        width: '33%',
        fontSize: '14px',
        fontWeight: 'bold',
    };
    const getFieldOptions = ({ fieldDb }) => {
        const options = [
            { value: '', label: 'Aucun', hide: false, selected: false },
        ];
        const fields = fileData.availableFields;
        fields.forEach((field) => {
            options.push({
                value: field,
                label: t(`contact.fields.import.db.${field}`),
                hide: Object.values(selectedFields).includes(field),
                selected: fieldDb === field,
            });
        });
        return options;
    };
    return (<>
      <Text fontWeight="bold">{t('contact.third_step_title')}</Text>
      <HStack>
        <Text {...propsHeader}>{t('contact.fields.import.fileValue')}</Text>
        <Text {...propsHeader}>{t('contact.fields.import.DBValue')}</Text>
        <Text {...propsHeader}>
          {t('contact.fields.import.firstLineValue')}
        </Text>
      </HStack>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
          {selectedTabData?.content.map((tabContent, index) => (<HStack key={index} marginTop={2}>
              <FormLabel {...propsFileLabel} data-cy="file_label">
                {tabContent.fieldFile}
              </FormLabel>
              <SelectInput name={`fieldsDb[${index}]`} widthFormControl={'33%'} defaultValue={tabContent.fieldDb} options={getFieldOptions(tabContent)} onChange={handleOnChange(index)} style={{
                fontWeight: `${selectedFields[index] ? '' : 'bold'}`,
                backgroundColor: `${selectedFields[index] ? '' : '#ffe5a9'}`,
            }}/>
              <FormLabel width={'33%'} data-cy="file_value">
                {tabContent.value}
              </FormLabel>
            </HStack>))}

          <VStack marginTop={10}>
            <SelectInput name="duplicateCheck" label={t('contact.fields.import.update_fields')} options={DUPLICATED_CHECK_OPTIONS} formControlDisplay="flex" formLabelWidth="33%" width="33%"/>
            <FormControl display="flex" hidden={hasCourierDate}>
              <FormLabel mb={0} width={'33%'}>
                {t('contact.fields.import.send_date')}
              </FormLabel>
              <DateTimePickerInput name="courierDate" minDate={null} width="33%" isClearable={true}/>
            </FormControl>
          </VStack>
          <Text as="p" fontSize="12px" marginTop={2} lineHeight={1.2}>
            {t('contact.fields.import.info_plot_ids')}
            <br />
            {t('contact.fields.import.info_more')}
            <br />
            {t('contact.fields.import.info_contact_type')}
          </Text>

          <HStack marginTop={5} justifyContent="flex-end">
            <Box> {children} </Box>
            <Box>
              <Button size="sm" type="submit" variant="primary" isLoading={isLoading} loadingText={t('global.status.importing')} data-cy="third-step-import-contact">
                {t(isLastStep ? 'contact.actions.next' : textLastStep)}
              </Button>
            </Box>
          </HStack>
        </form>
      </FormProvider>
    </>);
};
export default ThirdStep;
