import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Button, Center, Heading, Spinner, VStack } from '@chakra-ui/react';
import { ANALYTICS_DASHBOARD } from 'analytics/useAnalytics';
import AddressButton from 'components/buttons/AddressButton';
import PlotButton from 'components/buttons/PlotButton';
import { menuItems } from 'components/sidebar/utils';
import { useToggleMenu } from 'hooks/contexts/useMenuContext';
const LoadMoreHistoryList = ({ items, title, handleClick, type, isLoading, id, }) => {
    const { t } = useTranslation();
    const [seeAll, setSeeAll] = React.useState(false);
    const itemsList = items ? (seeAll ? items : items?.slice(0, 4)) : [];
    const toggleMenu = useToggleMenu();
    return (<VStack backgroundColor="colorMode.base" alignItems="flex-start" borderRadius="md" width="full" p={3} data-cy={id}>
      <Heading as="h4" variant="h4">
        {title}
      </Heading>
      <VStack alignItems="flex-start" spacing={0} width="full" maxH="200px" overflowY="auto">
        {isLoading ? (<Center display="flex" width="full" overflow="hidden" marginY={2}>
            <Spinner />
          </Center>) : (itemsList.map((item) => {
            if (type === 'address')
                return (<AddressButton key={item.historyId} item={item} flexBasis="50%" variant="link-primary" justifyContent="start" textAlign="left" height="auto" padding={0} paddingBottom={1} whiteSpace="normal" analyticOrigin={ANALYTICS_DASHBOARD} analyticDetail="Dernière adresse ">
                  {item.name}
                </AddressButton>);
            return (<PlotButton key={item.historyId} flexBasis="50%" lat={item.lat} lng={item.lng} townId={item.townId} variant="link-primary" justifyContent="start" textAlign="left" height="auto" padding={0} paddingBottom={1} whiteSpace="normal" plotId={item?.plotId} data-cy="plotButton" analyticOrigin={ANALYTICS_DASHBOARD} analyticDetail="Dernière parcelle" id="history" onOpenPlot={() => toggleMenu(menuItems.LAYERS_TREE)}>
                {item.townName} {item.cadastreId}
              </PlotButton>);
        }))}
      </VStack>
      <Button variant="outline" size="sm" rightIcon={<ArrowForwardIcon />} onClick={() => {
            setSeeAll(!seeAll);
            handleClick();
        }} data-cy={type + '_see_more'}>
        {seeAll
            ? t(`tabs.dashboard.see_less_results`)
            : t(`tabs.dashboard.see_more_results`)}
      </Button>
    </VStack>);
};
export default LoadMoreHistoryList;
