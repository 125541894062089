import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOIncreasedRealEstateTaxesLayerItem = IOLayerItem;
const fetchIncreasedRealEstateTaxes = (townId) => () => get(iots.array(IOIncreasedRealEstateTaxesLayerItem), `legacy/${townId}/increased-real-estate-taxes`);
const useIncreasedRealEstateTaxesLayer = () => {
    createLayersHook({
        fetch: fetchIncreasedRealEstateTaxes,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.INCREASED_REAL_ESTATE_TAXES);
};
const IncreasedRealEstateTaxesLayer = () => {
    useIncreasedRealEstateTaxesLayer();
    return null;
};
export default IncreasedRealEstateTaxesLayer;
