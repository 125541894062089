import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, } from '@chakra-ui/react';
import { LayerSwitch } from 'components/tabs/plots/PlotSections/info/PlotLayerSwitch';
import usePlotSales from 'hooks/plot/usePlotSales';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { filtersKey } from 'utils/filters';
import { getPlotSkeleton } from '../PlotPannelSkeletons';
export const getSaleDetail = (t, detail) => {
    return detail.type && detail.builtArea
        ? `${detail.type} ${detail.builtArea} ${t('tabs.plots.section.sales.square_meter')}`
        : `${t('tabs.plots.section.sales.floor')} ${detail.floorArea} ${t('tabs.plots.section.sales.square_meter')}`;
};
const PlotSales = ({ plot, setDvfEnabled, }) => {
    const { t } = useTranslation();
    const { hasPlotTab } = useSiteConfiguration();
    const { data: sales, isInitialLoading: isLoadingSales } = usePlotSales(plot?.townId, plot?.id, hasPlotTab('sales'));
    const skeleton = getPlotSkeleton();
    if (isLoadingSales)
        return skeleton;
    if (!isLoadingSales && !sales) {
        return (<Box margin={4}>
        <Text textStyle="paragraph">
          {t('tabs.plots.section.sales.not_available')}
        </Text>
      </Box>);
    }
    return (<Stack alignItems="start" margin={4}>
      <Box>
        <Flex alignItems="center">
          <Text textStyle="paragraph" marginRight={2}>
            {t('tabs.plots.section.sales.toggle_dvf')}
          </Text>
          <LayerSwitch onChange={(value) => setDvfEnabled(value)} filters={{
            layers: {
                [filtersKey.OLD_MARKET_DVF]: filtersKey.OLD_MARKET_DVF,
            },
        }}/>
        </Flex>
      </Box>
      <TableContainer width="full" className="smooth-box-shadow" borderRadius={6} boxShadow="md" maxWidth="1200px" data-cy="plot_sales_table">
        {Object.values(sales).length > 0 ? (<Table variant="striped">
            <Thead>
              <Tr>
                <Th width="10%" minWidth="auto!important">
                  {t('tabs.plots.section.sales.columns.date')}
                </Th>
                <Th width="15%" minWidth="auto!important">
                  {t('tabs.plots.section.sales.columns.land_value')}
                </Th>
                <Th width="35%">
                  {t('tabs.plots.section.sales.columns.nature')}
                </Th>
                <Th width="40%">
                  {t('tabs.plots.section.sales.columns.type')}
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {Object.values(sales).map((item, index) => {
                return (<Tr key={index}>
                    <Td minWidth="100px" valign="top">
                      {item.date}
                    </Td>
                    <Td minWidth="100px" fontWeight={600} textAlign="right" valign="top">
                      {item.realEstateValue}
                    </Td>
                    <Td minWidth="100px" fontWeight={600} valign="top">
                      {item.nature}
                    </Td>
                    <Td minWidth="100px" valign="top">
                      {item.saleDetail.map((detail, index) => {
                        return (<Text key={index}>{getSaleDetail(t, detail)}</Text>);
                    })}
                    </Td>
                  </Tr>);
            })}
            </Tbody>
          </Table>) : (<Box padding={4}>
            <Text>{t('tabs.plots.section.sales.no_data')}</Text>
          </Box>)}
      </TableContainer>
    </Stack>);
};
export default PlotSales;
