import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import monumentImg from 'images/monument.png';
import { IOMarkerLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
const IOABFHistoricalBuildingMarkerItem = iots.intersection([
    IOMarkerLayerItem,
    iots.type({
        title: iots.string,
        popId: iots.union([iots.string, iots.undefined]),
    }),
], 'IOABFHistoricalBuildingMarkerItem');
const fetchABFHistoricalBuilding = (townId) => () => get(iots.array(IOABFHistoricalBuildingMarkerItem), `legacy/${townId}/abf-historical-building`);
const LEGENDS = {
    abf_historical_building: {
        label: filtersKey.ABF_HISTORICAL_BUILDING,
        layerKey: filtersKey.ABF_HISTORICAL_BUILDING,
        needsTranslate: true,
        image: monumentImg,
    },
};
const useABFHistoricalBuildingLayer = () => {
    const filterName = filtersKey.ABF_HISTORICAL_BUILDING;
    const { t } = useTranslation();
    const getInfoWindowContent = useCallback((item) => {
        let content = `<b>${item.title}</b>`;
        if (item.popId) {
            content += `<br /><a href="https://www.pop.culture.gouv.fr/notice/merimee/${item.popId}" target="_blank">${t('markers.open_url')}</a>`;
        }
        return content;
    }, [t]);
    createLayersHook({
        fetch: fetchABFHistoricalBuilding,
        markerImg: monumentImg,
        legends: LEGENDS,
        getInfoWindowContent,
    })(filterName);
};
const ABFHistoricalBuildingLayer = () => {
    useABFHistoricalBuildingLayer();
    return null;
};
export default ABFHistoricalBuildingLayer;
