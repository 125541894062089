import * as React from 'react';
import { Text } from '@chakra-ui/react';
import PlotButton from 'components/buttons/PlotButton';
import { IOCoordinate } from 'types/types';
const NO_SUBSCRIPTION = 'hors abonnement';
const CompanyOwnedPlotsCadastreButton = ({ data }) => {
    const { cadastre, townId, xy, plotId } = data;
    if (cadastre === NO_SUBSCRIPTION || !xy) {
        return <Text>{cadastre}</Text>;
    }
    const [lng, lat] = xy
        .replace('POINT', '')
        .replace('(', '')
        .replace(')', '')
        .split(' ');
    if (!IOCoordinate.is(lat) || !IOCoordinate.is(lng)) {
        return <Text>{cadastre}</Text>;
    }
    return (<PlotButton lat={lat} lng={lng} townId={townId} padding={0} variant="link-primary" plotId={plotId}>
      {cadastre}
    </PlotButton>);
};
export default CompanyOwnedPlotsCadastreButton;
