import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
const IORPGFarming = iots.type({
    id: iots.string,
    name: iots.string,
});
const fetchRPGFarming = (townshipId) => () => get(iots.array(IORPGFarming), `${townshipId}/RPG/farming`);
const QUERY_KEY = 'rpg';
const useRPG = (townshipId) => {
    const { data: farmingList, isLoading: isLoadingFarming } = useQuery({
        queryKey: [QUERY_KEY, townshipId],
        queryFn: fetchRPGFarming(townshipId),
        enabled: !!townshipId,
    });
    return {
        farmingList,
        isLoadingFarming,
    };
};
export default useRPG;
