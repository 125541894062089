import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOProtectedOrAtRiskSitesLayerItem = IOLayerItem;
const fetchProtectedOrAtRiskSites = (townId) => () => get(iots.array(IOProtectedOrAtRiskSitesLayerItem), `legacy/${townId}/protected-or-at-risk-sites`);
const useProtectedOrAtRiskSitesLayer = () => {
    createLayersHook({
        fetch: fetchProtectedOrAtRiskSites,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.PROTECTED_OR_AT_RISK_SITES);
};
const ProtectedOrAtRiskSitesLayer = () => {
    useProtectedOrAtRiskSitesLayer();
    return null;
};
export default ProtectedOrAtRiskSitesLayer;
