import * as i18next from 'i18next';
import { filtersKey, IOFilterObject } from 'utils/filters';
import { getTVA } from './formatText';
export const LAYERS_KEY = 'layers';
export const ENDPOINT_KEY = 'endpoint';
export const CONTENT_KEY = 'content';
export const formatLayersNames = (tree) => {
    const layerNames = {};
    const iterate = (obj) => {
        Object.keys(obj).forEach((key) => {
            if (key === LAYERS_KEY) {
                obj[key].forEach((layer) => {
                    if (IOFilterObject.is(layer)) {
                        const prefix = layer?.prefix ?? null;
                        if (layer?.title) {
                            layerNames[layer.id] = {
                                title: layer.title,
                                prefix,
                            };
                        }
                        else {
                            layerNames[layer.id] = {
                                title: layer.id,
                                prefix,
                            };
                        }
                    }
                    else {
                        layerNames[layer] = {
                            title: layer,
                        };
                    }
                });
            }
            if (typeof obj[key] === 'object' &&
                obj[key] !== null &&
                key !== 'polygons' &&
                key !== 'markers') {
                iterate(obj[key]);
            }
        });
    };
    iterate(tree);
    return layerNames;
};
export const getNameAndPrefixForLayerKey = (layerKey, layerNames, t, selectedTownId) => {
    // To override the layer name in the legend we only need to
    // add a translation for this layer key
    const nameOverridden = i18next.exists(`sidebar.layers.${layerKey}`, {
        TVA: getTVA(selectedTownId),
    });
    let name = nameOverridden
        ? t(`sidebar.layers.${layerKey}`, {
            layerName: layerNames[layerKey]?.title,
            TVA: getTVA(selectedTownId),
            interpolation: { escapeValue: false },
        })
        : layerNames[layerKey]?.title;
    if (!name && layerKey === filtersKey.TOWNS) {
        name = 'town';
    }
    const prefix = layerNames[layerKey]?.prefix
        ? t(`sidebar.layers.${layerNames[layerKey].prefix}`, {
            TVA: getTVA(selectedTownId),
        })
        : null;
    return { name, prefix };
};
