import * as React from 'react';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Heading, Spinner, VStack } from '@chakra-ui/react';
import { isAvailable, } from 'components/tabs/crmTabs/TeamStatisticsCRMPanel';
import UserStatisticsStatusItem from 'components/tabs/crmTabs/userStatisticsCRM/UserStatisticsStatusItem';
import useTeamStatisticsCRM from 'hooks/crm/useTeamStatisticsCRM';
import { PLOT_CONTACT_STATUS_OPTIONS } from 'utils/constants';
const TeamStatisticsContactsPanel = ({ filters, }) => {
    const { t } = useTranslation();
    const [dataFormatted, setDataFormatted] = useState({
        status: {},
        percent: {},
    });
    const { statsContacts, isLoadingStatsContacts, refetchPlots } = useTeamStatisticsCRM({ toLoad: 'contacts' });
    useEffect(() => {
        refetchPlots();
    }, []);
    useEffect(() => {
        if (statsContacts) {
            const data = { status: {}, percent: {} };
            PLOT_CONTACT_STATUS_OPTIONS.map((option) => {
                data.status[option.value] = 0;
                data.percent[option.value] = 0;
            });
            let nbContacts = 0;
            for (const userEmail in statsContacts) {
                nbContacts += statsContacts[userEmail].length;
            }
            for (const userEmail in statsContacts) {
                if (!isAvailable({ filters, email: userEmail })) {
                    continue;
                }
                const userData = statsContacts[userEmail];
                userData.forEach((contact) => {
                    const date = new Date(contact.updatedAt);
                    if (!isAvailable({
                        filters,
                        date,
                        status: contact.status,
                        group: contact.group,
                    })) {
                        return;
                    }
                    data.status[contact.status] += 1;
                    const percent = (data.status[contact.status] / nbContacts) * 100;
                    data.percent[contact.status] = percent.toFixed(2);
                });
            }
            setDataFormatted(data);
        }
    }, [statsContacts, filters]);
    return (<Box flex={1}>
      <Heading as="h3" variant="h3" marginBottom={2}>
        {t('tabs.team_statistics_crm.contacts')}
      </Heading>
      <VStack spacing={1} alignItems="flex-start" position="relative">
        {PLOT_CONTACT_STATUS_OPTIONS.map((option) => {
            return (<UserStatisticsStatusItem key={option.value} option={option} data={dataFormatted}/>);
        })}
        {isLoadingStatsContacts && (<Box display="flex" alignItems="center" justifyContent="center" position="absolute" top={0} left={0} width="100%" height="100%" backgroundColor="rgba(251, 250, 249, 0.6)">
            <Spinner />
          </Box>)}
      </VStack>
    </Box>);
};
export default memo(TeamStatisticsContactsPanel);
