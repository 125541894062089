import { useTranslation } from 'react-i18next';
import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import stoneQuarryImg from 'images/stone_quarry.png';
import { filtersKey } from 'utils/filters';
import { formatInfoWindowContent, IOExploitationsMarkerItem, } from './InactiveExploitationsLayer';
const fetchActiveExploitations = (townId) => () => get(iots.array(IOExploitationsMarkerItem), `legacy/${townId}/active-exploitations`);
const LEGENDS = {
    active_exploitations: {
        layerKey: filtersKey.ACTIVE_EXPLOITATIONS,
        label: 'active_exploitations',
        needsTranslate: true,
        image: stoneQuarryImg,
    },
};
const useActiveExploitationsLayer = () => {
    const { t } = useTranslation();
    const getInfoWindowContent = (marker) => {
        return formatInfoWindowContent(marker, t);
    };
    createLayersHook({
        fetch: fetchActiveExploitations,
        markerImg: stoneQuarryImg,
        legends: LEGENDS,
        getInfoWindowContent,
    })(filtersKey.ACTIVE_EXPLOITATIONS);
};
const ActiveExploitationsLayer = () => {
    useActiveExploitationsLayer();
    return null;
};
export default ActiveExploitationsLayer;
