import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOFloodZoneLayerItem = IOLayerItem;
const fetchFloodZone = (townId) => () => get(iots.array(IOFloodZoneLayerItem), `legacy/${townId}/flood-zone`);
const useFloodZoneLayer = () => {
    createLayersHook({
        fetch: fetchFloodZone,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.FLOOD_ZONE);
};
const FloodZoneLayer = () => {
    useFloodZoneLayer();
    return null;
};
export default FloodZoneLayer;
