import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { get } from 'api/client';
import { IOSecondaryPlot } from 'types/api';
import { useSelectedTown } from './contexts/useLocationContext';
import useCurrentSite from './useCurrentSite';
export const QUERY_KEY = 'secondaryPlotByCoordinates';
const ADDITIONAL_QUERY_KEY = 'secondaryPlotByCoordinatesAdditional';
const URL = 'secondary-plot-by-coordinates';
const ADDITIONAL_URL = 'secondary-plot-by-coordinates-additional';
export const fetchSecondaryPlotByCoordinates = (additional, townshipId, lat, lng, siteId, plotId) => () => {
    plotId = plotId ?? '0';
    return get(IOSecondaryPlot, `${additional ? ADDITIONAL_URL : URL}/${townshipId}/${plotId}/${lat}/${lng}`, { siteId }, false);
};
export const useSecondaryPlotByCoordinates = ({ additional = false, enabled, secondaryPlotCoord, errorCallback, successCallback, plotId, }) => {
    const { currentSite } = useCurrentSite();
    const lat = secondaryPlotCoord?.coord?.lat()?.toString();
    const lng = secondaryPlotCoord?.coord?.lng()?.toString();
    const selectedTown = useSelectedTown();
    const townshipId = selectedTown?.id;
    const { data, isInitialLoading } = useQuery({
        queryKey: [additional ? ADDITIONAL_QUERY_KEY : QUERY_KEY, lat, lng],
        queryFn: fetchSecondaryPlotByCoordinates(additional, townshipId, lat, lng, currentSite?.legacyId, plotId),
        enabled: !!townshipId &&
            !!lat &&
            !!lng &&
            enabled &&
            (!additional || selectedTown?.additionalFeasibility),
        staleTime: 0,
        meta: {
            onError: (error) => {
                errorCallback && errorCallback(error);
            },
        },
    });
    useEffect(() => {
        if (data) {
            successCallback(data);
        }
    }, [data]);
    return { isInitialLoading };
};
