import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as iots from 'io-ts';
import { get } from 'api/client';
import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { latLngToTileValues } from 'utils/geo';
import { formatNatura2000Info, hasData } from 'utils/ign';
import { getLayerCoordTileUrl } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = () => (coord, zoom) => {
    return getLayerCoordTileUrl('PROTECTEDAREAS.ZPS', 'PROTECTEDAREAS.ZPS', zoom, coord);
};
const TILE_SIZE = 256;
const fetchInfoForCoords = (coords, map, t) => {
    const { tileRow, tileCol, i, j } = latLngToTileValues(coords, map, TILE_SIZE, TILE_SIZE);
    return get(iots.string, 'https://data.geopf.fr/wmts?' +
        '&SERVICE=WMTS&REQUEST=GetFeatureInfo&VERSION=1.0.0' +
        '&LAYER=PROTECTEDAREAS.ZPS' +
        '&STYLE=PROTECTEDAREAS.ZPS' +
        '&FORMAT=' +
        encodeURIComponent('image/png') +
        '&INFOFORMAT=' +
        encodeURIComponent('text/html') +
        '&TILEMATRIXSET=PM' +
        '&TILEMATRIX=' +
        map.getZoom() +
        '&TILEROW=' +
        tileRow +
        '&TILECOL=' +
        tileCol +
        '&I=' +
        i +
        '&J=' +
        j, {}, true, {}, false).then((response) => {
        return hasData(response) ? formatNatura2000Info(response, t) : '';
    });
};
const getDetails = (t) => () => async (coords, map) => {
    return await fetchInfoForCoords(coords, map, t);
};
const getLegends = () => {
    const legends = {};
    legends.natura_2000_zps = {
        layerKey: filtersKey.NATURA_2000_ZPS,
        label: 'natura_2000_zps',
        needsTranslate: true,
        color: '#A8D613',
        opacity: 0.6,
    };
    return legends;
};
const useNatural2000ZPSLayer = () => {
    const { t } = useTranslation();
    const getInfoWindowContent = useMemo(() => getDetails(t), [t]);
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.NATURA_2000_ZPS],
        getLegends,
        getInfoWindowContent,
        tileWidth: TILE_SIZE,
        tileHeight: TILE_SIZE,
        opacity: 0.5,
    })(filtersKey.NATURA_2000_ZPS);
};
const Natura2000ZPSLayer = () => {
    useNatural2000ZPSLayer();
    return null;
};
export default Natura2000ZPSLayer;
