import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';
import * as iots from 'io-ts';
import { getSectorFromCode } from 'utils/constants';
export const IOBuildingConsumptionInfo = iots.type({
    id: iots.string,
    consumption: iots.number,
    sector: iots.string,
    codeNAF: iots.string,
    address: iots.string,
});
const BuildingConsumptionInfoWindow = ({ info }) => {
    const { t } = useTranslation();
    if (!info) {
        return null;
    }
    return (<Box maxHeight="400px">
      {info.consumption && (<Box>
          {t('building_consumption.consumption')} : {info.consumption} MWh
        </Box>)}
      {info.sector && (<Box>
          {t('building_consumption.sector')} : {getSectorFromCode(info.sector)}
        </Box>)}
      {info.codeNAF && (<Box>
          {t('building_consumption.codeNAF')} : {info.codeNAF}
        </Box>)}
      {info.address && (<Box>
          {t('building_consumption.address')} : {info.address}
        </Box>)}
    </Box>);
};
export default BuildingConsumptionInfoWindow;
