import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { AddIcon } from '@chakra-ui/icons';
import { Button, FormControl, FormLabel, Skeleton, VStack, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_CRM } from 'analytics/useAnalytics';
import DeleteButton from 'components/buttons/DeleteButton';
import StatusSelect from 'components/inputs/StatusSelect';
import usePlotStatus from 'hooks/crm/usePlotStatus';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { PLOT_STATUS_PRIORITY } from 'utils/constants';
import { filtersKey } from 'utils/filters';
import { getPlotStatusOption } from 'utils/personalization';
const PlotStatus = ({ plotId }) => {
    const { t } = useTranslation();
    const { hasPlotInfo, hasDashboardItem } = useSiteConfiguration();
    const { trackEvent } = useAnalytics();
    const { plotStatus, isLoading, changePlotStatus, isUpdating } = usePlotStatus(plotId);
    const hasAccess = hasDashboardItem(filtersKey.USER_PLOTS) && hasPlotInfo('status');
    if (!hasAccess)
        return null;
    if (!plotStatus) {
        return isLoading ? (<Skeleton height="30px" width="100%"/>) : (<Button padding={0} leftIcon={<AddIcon fill="currentColor"/>} variant="link-primary" onClick={() => {
                changePlotStatus({
                    plotId: plotId,
                    status: PLOT_STATUS_PRIORITY,
                });
                trackEvent({
                    category: ANALYTICS_CRM,
                    action: 'Ajouter à mes parcelles',
                });
            }} isLoading={isUpdating} disabled={isUpdating} data-cy="plot_panel_add_to_plots">
        {t('tabs.plots.section.plot.add_to_user_plots')}
      </Button>);
    }
    return (<VStack spacing={0} alignItems="flex-start" width="100%" maxW="sm">
      <FormControl>
        <FormLabel>{t('tabs.plots.section.plot.status')}</FormLabel>
        <StatusSelect isLoading={isLoading || isUpdating} isDisabled={isLoading || isUpdating} data={getPlotStatusOption()} onChange={(value) => {
            changePlotStatus({
                plotId,
                status: value.value,
            });
            trackEvent({
                category: ANALYTICS_CRM,
                action: 'Statut de la parcelle onglet parcelle',
            });
        }} value={plotStatus}/>
      </FormControl>

      <DeleteButton showIcon padding={0} fontSize="12px" variant="link-primary" isLoading={isUpdating} label={t('tabs.plots.section.plot.remove_from_user_plots')} confirmText={t('tabs.plots.section.plot.confirm_remove_from_user_plots')} handleDelete={() => {
            changePlotStatus({
                plotId: plotId,
                status: 0, // Aucun
            });
            trackEvent({
                category: ANALYTICS_CRM,
                action: 'Retirer de mes parcelles',
            });
        }} disabled={isUpdating} data-cy="plot_panel_remove_from_plots" id="remove_from_plots"/>
    </VStack>);
};
export default PlotStatus;
