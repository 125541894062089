import { useCallback, useEffect, useRef } from 'react';
import { useMap, useSetPLUInfoData, useStreetViewMode, } from 'hooks/contexts/useMapContext';
import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getNormalizedCoordinates } from 'utils/map';
import { getBaseTileUrlHost3 } from 'utils/multiplexage';
import { CST_POSITIONS } from 'utils/tilesPositions';
import * as React from 'react';
import { useDrawingMode, useIsSecondarySelectionMode, } from 'hooks/contexts/useLocationContext';
import { isSwissSite } from 'utils/site';
export const PLU_ZOOM_LIMIT = 12;
const getLegends = (enabledFilters) => {
    const legends = {};
    if (enabledFilters.includes(filtersKey.PLU)) {
        legends.plu = {
            layerKey: filtersKey.PLU,
            label: 'plu',
            needsTranslate: true,
            color: '#FFFFFE',
            borderColor: '#000000',
            borderWidth: 1,
            borderOpacity: 1,
        };
    }
    if (enabledFilters.includes(filtersKey.PLU_ADJACENT)) {
        legends.plu_adjacent = {
            layerKey: filtersKey.PLU_ADJACENT,
            label: 'plu_adjacent',
            needsTranslate: true,
            color: '#FFFFFE',
            borderColor: '#000000',
            borderWidth: 1,
            borderOpacity: 1,
        };
    }
    return legends;
};
const usePLULayer = ({ townId, pluAdjacent, position, isSelectedTown, }) => {
    const map = useMap();
    const setPLUInfoData = useSetPLUInfoData();
    const streetViewMode = useStreetViewMode();
    const infoWindowListener = useRef(null);
    const isSecondarySelectionMode = useIsSecondarySelectionMode();
    const drawingMode = useDrawingMode();
    useEffect(() => {
        infoWindowListener?.current?.remove();
        if (!map || !isSelectedTown) {
            return;
        }
        infoWindowListener.current = map.addListener('click', (event) => {
            if (streetViewMode || isSecondarySelectionMode || drawingMode) {
                return;
            }
            const marker = new google.maps.Marker({
                icon: '',
                position: new google.maps.LatLng(event.latLng.lat(), event.latLng.lng()),
                map: map,
                visible: false,
            });
            const coords = new google.maps.Point(marker.getPosition().lat(), marker.getPosition().lng());
            setPLUInfoData(coords, townId);
        });
        return () => {
            infoWindowListener?.current?.remove();
            setPLUInfoData(null);
        };
    }, [
        map,
        streetViewMode,
        isSecondarySelectionMode,
        drawingMode,
        townId,
        isSelectedTown,
    ]);
    const getLayerTileUrlPLU = useCallback(() => (coord, zoom) => {
        const normCoord = getNormalizedCoordinates(coord, zoom);
        if (!normCoord) {
            return null;
        }
        let maps = 'search_plu_ref_mmxx_x_polygones_plu_light_';
        if (isSwissSite()) {
            maps = 'search_suissePLU_polygones_plu_light_';
        }
        return (getBaseTileUrlHost3() +
            'cgi-bin/mm/mapserv.cgi?map=/var/data/maps/' +
            maps +
            townId +
            '.map&mode=tile&tilemode=gmap&tile=' +
            coord.x +
            '+' +
            coord.y +
            '+' +
            zoom +
            '&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=polygones_plu_light&TILED=true&CRS=EPSG:3857');
    }, [townId]);
    const getLayerTileUrlPLULabels = useCallback(() => (coord, zoom) => {
        const normCoord = getNormalizedCoordinates(coord, zoom);
        if (!normCoord) {
            return null;
        }
        let maps = 'search_plu_ref_mmxx_x_polygones_plu_centroides_';
        if (isSwissSite()) {
            maps = 'search_suissePLU_polygones_plu_centroides_';
        }
        return (getBaseTileUrlHost3() +
            'cgi-bin/mm/mapserv.cgi?map=/var/data/maps/' +
            maps +
            townId +
            '.map&mode=tile&tilemode=gmap&tile=' +
            coord.x +
            '+' +
            coord.y +
            '+' +
            zoom +
            '&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=polygones_plu_centroides&TILED=true&CRS=EPSG:3857');
    }, [townId]);
    // position + 30 car on peut avoir un maximum de 30 communes autour d'une seule (cf. Paris)
    return [
        createTilesLayerHook({
            getLayerTileUrl: getLayerTileUrlPLU,
            position: position ? position : CST_POSITIONS.PLU,
            getLegends,
            refreshParam: pluAdjacent ? position + '-' + townId : townId,
        })(pluAdjacent ? filtersKey.PLU_ADJACENT : filtersKey.PLU),
        createTilesLayerHook({
            getLayerTileUrl: getLayerTileUrlPLULabels,
            position: position ? position + 30 : CST_POSITIONS.LABELS,
            getLegends,
            refreshParam: pluAdjacent ? position + '-' + townId : townId,
        })(pluAdjacent ? filtersKey.PLU_ADJACENT : filtersKey.PLU),
    ];
};
const PLULayer = ({ townId, pluAdjacent, position, isSelectedTown, }) => {
    usePLULayer({ townId, position, pluAdjacent, isSelectedTown });
    return null;
};
const PLULayerWrapper = ({ computedZoom, townId, pluAdjacent, position, isSelectedTown, }) => {
    if (computedZoom < PLU_ZOOM_LIMIT) {
        return null;
    }
    return (<PLULayer townId={townId} pluAdjacent={pluAdjacent} position={position} isSelectedTown={isSelectedTown}/>);
};
export default PLULayerWrapper;
