import * as React from 'react';
const FolderIcon = (props) => (<svg width="20" height="20" viewBox="0 0 20 20" {...props}>
    <g clipPath="url(#clip0_305_14171)">
      <path d="M15.1429 8.0714V12.7857C15.1429 13.6094 14.4666 14.2857 13.6429 14.2857H5.5C4.67629 14.2857 4 13.6094 4 12.7857V6.35712C4 5.5334 4.67629 4.85712 5.5 4.85712H7.64286C8.46657 4.85712 9.14286 5.5334 9.14286 6.35712V6.5714H13.6429C14.4666 6.5714 15.1429 7.24769 15.1429 8.0714Z"/>
    </g>
    <defs>
      <clipPath id="clip0_305_14171">
        <rect width="11.1429" height="12" fill="white" transform="translate(4 4)"/>
      </clipPath>
    </defs>
  </svg>);
export default FolderIcon;
