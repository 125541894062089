import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { del, get, post } from 'api/client';
import { getPlotFileQueryKey } from 'hooks/crm/usePlotFiles';
import useOnError from 'hooks/useOnError';
import { IODocument, IOEmpty } from 'types/api';
import { TYPE_DOCUMENT, TYPE_PHOTO } from 'utils/document';
import { getProjectHistoryQueryKey } from './useProjectHistory';
export const QUERY_KEY = 'project-files';
export const getProjectFilesQueryKey = (projectId) => [
    QUERY_KEY,
    projectId,
];
const fetchProjectFiles = (projectId, landPointId) => () => get(iots.array(IODocument), `project/${projectId}/files`, { landPointId });
const deleteProjectFile = ({ document }) => {
    if (document.projectId !== 0) {
        return del(IOEmpty, `project/${document.projectId}/files/${document.id}`, {
            t: document.typeId,
        });
    }
    else if (document.plotId !== 0) {
        return del(null, `legacy/plot/file/${document.id}/delete`);
    }
};
const addProjectDocument = ({ projectId, file, }) => {
    const formData = new FormData();
    formData.append('file', file[0], file[0]?.name);
    formData.append('typeId', TYPE_DOCUMENT.toString());
    return post(null, `project/${projectId}/add-document`, formData, null, false, {
        headers: { 'Content-Type': 'multipart/form-data;' },
    });
};
const addProjectPhoto = ({ projectId, file, }) => {
    const formData = new FormData();
    formData.append('file', file[0], file[0]?.name);
    formData.append('typeId', TYPE_PHOTO.toString());
    return post(null, `project/${projectId}/add-document`, formData, null, false, {
        headers: { 'Content-Type': 'multipart/form-data;' },
    });
};
const useProjectFiles = (projectId, landPointId) => {
    const queryClient = useQueryClient();
    const { data: files, isInitialLoading: isLoading } = useQuery({
        queryKey: getProjectFilesQueryKey(projectId),
        queryFn: fetchProjectFiles(projectId, landPointId),
        enabled: !!projectId,
    });
    const onErrorCallback = useOnError();
    const { mutate: addDocument } = useMutation({
        mutationFn: addProjectDocument,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: getProjectFilesQueryKey(projectId),
            });
            queryClient.invalidateQueries({
                queryKey: getProjectHistoryQueryKey(projectId),
            });
        },
    });
    const { mutate: addPhoto } = useMutation({
        mutationFn: addProjectPhoto,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: getProjectFilesQueryKey(projectId),
            });
            queryClient.invalidateQueries({
                queryKey: getProjectHistoryQueryKey(projectId),
            });
        },
    });
    const { mutate: deleteFile } = useMutation({
        mutationFn: deleteProjectFile,
        onError: (err) => onErrorCallback(err),
        onSuccess: (data, variables) => {
            if (variables.document.plotId !== 0) {
                queryClient.invalidateQueries({
                    queryKey: getPlotFileQueryKey(variables.document.plotId.toString()),
                });
            }
            else {
                // Si suppression document parcelle inutile de recharger l'historique du projet
                queryClient.invalidateQueries({
                    queryKey: getProjectHistoryQueryKey(projectId),
                });
            }
            queryClient.invalidateQueries({
                queryKey: getProjectFilesQueryKey(projectId),
            });
        },
    });
    const documents = files?.filter((file) => file.typeId === TYPE_DOCUMENT) || [];
    const photos = files?.filter((file) => file.typeId === TYPE_PHOTO) || [];
    return { documents, photos, isLoading, deleteFile, addDocument, addPhoto };
};
export default useProjectFiles;
