import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FormLabel, HStack } from '@chakra-ui/react';
import { useIsFetching } from '@tanstack/react-query';
import { useFilters, useToggleFilter } from 'hooks/contexts/useFiltersContext';
import { filtersKey } from 'utils/filters';
const ThematicMapsNoneSidebarContent = ({ item, }) => {
    const { t } = useTranslation();
    const isLoadingLayers = useIsFetching({ queryKey: ['legend'] }) > 0;
    const filters = useFilters();
    const toggleFilter = useToggleFilter();
    const radioName = filtersKey.THEMATIC_MAPS;
    return (<HStack alignItems="center">
      <input type="radio" id={`${item.radioName}-none`} name={item.radioName} disabled={isLoadingLayers} checked={!filters[radioName]} onChange={() => toggleFilter(radioName, undefined)}/>
      <FormLabel htmlFor={`${radioName}-none`} cursor="pointer">
        {t(`sidebar.layers.${item.title}`, {
            TVA: '2,1',
        })}
      </FormLabel>
    </HStack>);
};
export default ThematicMapsNoneSidebarContent;
