import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MarkerWithLabel } from '@googlemaps/markerwithlabel';
import { getMarkerIcon } from 'components/layers/UserDrawingsLayer';
import { useActiveUserDrawing, useDrawingMode, useSetActiveUserDrawing, useToggleDrawingMode, } from 'hooks/contexts/useLocationContext';
import useUserDrawing from 'hooks/crm/useUserDrawing';
import imgEmpty from 'images/1x1.gif';
import { isFreeDrawing, pointExists } from 'utils/drawingUtils';
import { swktToDrawing } from 'utils/map';
import { useToggleFilter } from '../hooks/contexts/useFiltersContext';
import { filtersKey } from './filters';
const DRAW_COLOR = '#FF6510';
const DRAW_OPTIONS = {
    strokeColor: DRAW_COLOR,
    strokeOpacity: 1,
    strokeWeight: 3,
    fillOpacity: 0.3,
    fillColor: DRAW_COLOR,
    editable: true,
    draggable: true,
    zIndex: 10000,
    cursor: 'grab',
};
const getMarkerOptions = () => ({
    draggable: true,
    zIndex: 10000,
    cursor: 'grab',
    icon: {
        ...getMarkerIcon(),
        fillColor: DRAW_COLOR,
    },
});
const getMarkerWithLabelOptions = (label) => ({
    draggable: true,
    clickable: false,
    zIndex: 10000,
    cursor: 'grab',
    icon: imgEmpty,
    labelContent: label,
    labelClass: 'user-drawing-text user-drawing-text--editing',
});
export const useDrawing = (map) => {
    const currentDrawing = useRef(null);
    const activeListener = useRef(null);
    const validatedPoints = useRef([]);
    const clickListener = useRef(null);
    const mouseMoveListener = useRef(null);
    const drawingMode = useDrawingMode();
    const toggleDrawingMode = useToggleDrawingMode();
    const activeUserDrawing = useActiveUserDrawing();
    const setActiveUserDrawing = useSetActiveUserDrawing();
    const onRemoveDrawing = useCallback(() => {
        setActiveUserDrawing(null);
        toggleDrawingMode(null);
    }, [setActiveUserDrawing, toggleDrawingMode]);
    const { updateUserDrawing, createUserDrawing, removeUserDrawing } = useUserDrawing(null, onRemoveDrawing);
    const [hasCurrentDrawing, setHasCurrentDrawing] = useState(false);
    const [clickedUserDrawing, setClickedUserDrawing] = useState(false);
    const { t } = useTranslation();
    const toggleFilter = useToggleFilter();
    useEffect(() => {
        removeCurrentDrawing();
        if (!activeUserDrawing) {
            return;
        }
        if (drawingMode !== 'free_edit') {
            // We just clicked on a drawing, we set it as active and toggle edit mode
            setClickedUserDrawing(true);
            toggleDrawingMode('free_edit');
        }
        // When active user drawing is set, and we're in free edit mode, we set it as current drawing
        const newDrawing = swktToDrawing(activeUserDrawing.swkt, DRAW_OPTIONS, activeUserDrawing.note
            ? getMarkerWithLabelOptions(activeUserDrawing.note)
            : getMarkerOptions());
        setCurrentDrawing(newDrawing);
    }, [activeUserDrawing]);
    useEffect(() => {
        cleanAll();
        if (!isFreeDrawing(drawingMode) || drawingMode === null) {
            return;
        }
        startDrawing(drawingMode);
    }, [drawingMode]);
    useEffect(() => {
        return () => {
            cleanAll();
        };
    }, []);
    const setCurrentDrawing = (drawing, listener = null) => {
        setHasCurrentDrawing(true);
        currentDrawing.current = drawing;
        activeListener.current = listener;
        drawing.setMap(map);
    };
    const removeCurrentDrawing = () => {
        setHasCurrentDrawing(false);
        activeListener.current?.remove();
        activeListener.current = null;
        currentDrawing.current?.setMap(null);
        currentDrawing.current = null;
    };
    const startDrawing = (mode) => {
        if (!map || mode === 'free_edit') {
            return;
        }
        // Avec map.getDiv().classList ne fonctionne pas :(
        map.getDiv().parentElement.classList.add('crosshair-cursor');
        clickListener.current = map.addListener('click', (event) => {
            const latLng = event.latLng;
            const drawing = currentDrawing.current;
            // drawing started
            if (!drawing) {
                createDrawing(latLng, mode);
                return;
            }
            if (drawing instanceof google.maps.Marker) {
                return;
            }
            // new point added
            if (!pointExists(validatedPoints.current, latLng)) {
                validatedPoints.current.push(latLng);
                addPoint(latLng, drawing);
                return;
            }
            // drawing completed
            saveDrawing(mode, drawing);
        });
        mouseMoveListener.current = map.addListener('mousemove', (event) => {
            if (!currentDrawing?.current) {
                return;
            }
            draw(mode, event.latLng);
        });
    };
    const createDrawing = (latLng, mode) => {
        const startingPoint = {
            lat: latLng.lat(),
            lng: latLng.lng(),
        };
        let newDrawing = null;
        validatedPoints.current = [latLng];
        if (mode === 'free_polygon') {
            newDrawing = new google.maps.Polygon({
                paths: [startingPoint],
                ...DRAW_OPTIONS,
            });
        }
        else if (mode === 'free_point') {
            newDrawing = new google.maps.Marker({
                position: latLng,
                ...getMarkerOptions(),
            });
        }
        else if (mode === 'free_text') {
            newDrawing = new MarkerWithLabel({
                position: latLng,
                ...getMarkerWithLabelOptions(t('toolbar.free_draw.actions.placeholder')),
            });
        }
        else {
            newDrawing = new google.maps.Polyline({
                path: [startingPoint],
                ...DRAW_OPTIONS,
            });
        }
        const listener = newDrawing instanceof google.maps.Marker
            ? null
            : newDrawing.addListener('click', (event) => {
                // new point clicked
                if (!pointExists(validatedPoints.current, event.latLng)) {
                    validatedPoints.current.push(event.latLng);
                    addPoint(event.latLng, newDrawing);
                    return;
                }
                // drawing completed
                saveDrawing(mode, newDrawing);
            });
        setCurrentDrawing(newDrawing, listener);
        if (mode === 'free_point') {
            saveDrawing(mode, newDrawing);
        }
    };
    const draw = (mode, position) => {
        if (!currentDrawing.current ||
            currentDrawing.current instanceof google.maps.Marker ||
            currentDrawing.current instanceof MarkerWithLabel) {
            return;
        }
        const currentPath = currentDrawing.current.getPath();
        if (currentPath.getLength() > validatedPoints.current.length) {
            currentPath.pop();
        }
        addPoint(position, currentDrawing.current);
    };
    const saveDrawing = (mode, drawing) => {
        stopDrawing();
        if (activeUserDrawing && mode === 'free_edit') {
            updateUserDrawing({ id: activeUserDrawing.id, drawing });
        }
        else {
            createUserDrawing({ drawing });
        }
        toggleDrawingMode(null);
        toggleFilter(filtersKey.USER_DRAWINGS, true);
    };
    const addPoint = (latLng, drawing) => {
        const path = drawing.getPath();
        path.push(latLng);
    };
    const stopDrawing = useCallback(() => {
        if (clickListener.current) {
            google.maps.event.removeListener(clickListener.current);
            clickListener.current = null;
        }
        if (mouseMoveListener.current) {
            google.maps.event.removeListener(mouseMoveListener.current);
            mouseMoveListener.current = null;
        }
        if (!map) {
            return;
        }
        // Avec map.getDiv().classList ne fonctionne pas :(
        map.getDiv().parentElement.classList.remove('crosshair-cursor');
    }, [map]);
    const cleanAll = () => {
        validatedPoints.current = [];
        if (!clickedUserDrawing) {
            setActiveUserDrawing(null);
            removeCurrentDrawing();
        }
        stopDrawing();
        setClickedUserDrawing(false);
    };
    const saveActiveDrawing = () => {
        if (!currentDrawing.current) {
            return;
        }
        saveDrawing(drawingMode, currentDrawing.current);
    };
    const updateDrawingText = (text) => {
        if (!currentDrawing.current ||
            !(currentDrawing.current instanceof MarkerWithLabel)) {
            return;
        }
        currentDrawing.current.labelContent = text;
    };
    const deleteActiveDrawing = () => {
        if (!activeUserDrawing) {
            return;
        }
        removeUserDrawing(activeUserDrawing.id);
    };
    return {
        drawingMode,
        hasCurrentDrawing,
        saveActiveDrawing,
        deleteActiveDrawing,
        updateDrawingText,
        isFreeDrawingActive: isFreeDrawing(drawingMode),
        toggleDrawingMode,
        activeUserDrawing,
    };
};
