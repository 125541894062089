import * as React from 'react';
import { Box, HStack } from '@chakra-ui/react';
import ActivityCreateButton from 'components/buttons/ActivityCreateButton';
import ActivityExportModal from 'components/modals/ActivityExportModal';
import RoundedTabs from 'components/RoundedTabs';
import useActivity from 'hooks/useActivity';
import CalendarIcon from 'images/icons/Calendar';
import TableIcon from 'images/icons/Table';
import { ActivityCalendar } from './ActivityCalendar';
import ActivityDrawer from './ActivityDrawer';
import { ActivityTable } from './ActivityTable';
const ActivityPanel = () => {
    const { activities, isLoading } = useActivity({});
    const [editedActivity, setEditedActivity] = React.useState();
    const tabsList = [
        {
            title: 'Calendrier',
            icon: <CalendarIcon fill="inherit"/>,
            content: (<ActivityCalendar activities={activities} setEditedActivity={setEditedActivity}/>),
        },
        {
            title: 'Tableau',
            icon: <TableIcon fill="inherit"/>,
            content: (<ActivityTable id="user-activities" activities={activities} isLoading={isLoading} setEditedActivity={setEditedActivity}/>),
        },
    ];
    return (<Box height="100%" p={4} display="flex" flexDirection="column">
      <HStack flex={1}>
        <RoundedTabs tabsList={tabsList}>
          <Box>
            <ActivityCreateButton activityCreate={{
            plotId: null,
            cadastreId: null,
            contactId: null,
            projectId: null,
        }} icon={false} disabled={isLoading} setActivityInfo={setEditedActivity} marginRight={4} height={10}/>
            <ActivityExportModal />
          </Box>
        </RoundedTabs>
      </HStack>
      <ActivityDrawer activityInfo={editedActivity} handleClose={() => setEditedActivity(undefined)}/>
    </Box>);
};
export default ActivityPanel;
