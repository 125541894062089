export const accordionArrowStyle = {
    position: 'absolute',
    zIndex: 40,
    content: '""',
    width: '8px',
    height: '8px',
    left: '5px',
    bottom: '-8px',
    borderTopWidth: '9px',
    borderLeft: '6px solid transparent',
    borderRight: '6px solid transparent',
    borderBottom: 'none',
    filter: 'drop-shadow(0px 5px 5px var(--chakra-colors-gray-200))',
};
export const accordionBoxShadow = '1px 4px 4px var(--chakra-colors-gray-700)';
export const sidebarAccordionBoxShadow = `1px 4px 10px var(--chakra-colors-gray-200) !important`;
