import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IORegionalPlanUrbanFrontLayerItem = IOLayerItem;
const fetchRegionalPlanUrbanFront = (townId) => () => get(IORegionalPlanUrbanFrontLayerItem, `legacy/${townId}/regional-plan-urban-front`);
const useRegionalPlanUrbanFrontLayer = () => {
    createLayersHook({
        fetch: fetchRegionalPlanUrbanFront,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.REGIONAL_PLAN_URBAN_FRONT);
};
const RegionalPlanUrbanFrontLayer = () => {
    useRegionalPlanUrbanFrontLayer();
    return null;
};
export default RegionalPlanUrbanFrontLayer;
