import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get, post } from 'api/client';
import { CRM_TABS } from 'context/TabsContext';
import { useIsTabOpened } from 'hooks/contexts/useTabsContext';
import useCustomToast from 'hooks/useCustomToast';
import useOnError from 'hooks/useOnError';
import { IOContactGlobal, IOEmpty, IOProject } from 'types/api';
import { IOCoordinate } from 'types/types';
export const UserPlotType = {
    activityStatus: iots.boolean,
    address: iots.union([iots.string, iots.null]),
    townId: iots.string,
    townLat: IOCoordinate,
    townLng: IOCoordinate,
    townLabel: iots.string,
    comment: iots.union([iots.string, iots.null]),
    activityComment: iots.union([iots.string, iots.null]),
    zipcode: iots.string,
    agendaType: iots.number,
    activityDate: iots.union([iots.string, iots.null]),
    activityDateEnd: iots.union([iots.string, iots.null]),
    modifiedAt: iots.union([iots.string, iots.null]),
    historyDate: iots.union([iots.string, iots.null]),
    historyType: iots.union([iots.string, iots.null]),
    cadastreId: iots.string,
    contact: iots.union([IOContactGlobal, iots.null]),
    swkt: iots.string,
    plotId: iots.string,
    plotContactId: iots.union([iots.string, iots.null]),
    plotAgendaActivityId: iots.union([iots.string, iots.null]),
    projects: iots.array(IOProject),
    lots: iots.number,
    plotLat: IOCoordinate,
    plotLng: IOCoordinate,
    plotContactStatus: iots.number,
    plotStatus: iots.number,
    surface: iots.number,
    groundSurface: iots.number,
    zone: iots.string,
    note: iots.union([iots.string, iots.null]),
};
const IOUserPlot = iots.intersection([
    iots.type(UserPlotType),
    iots.partial({
        createdAt: iots.union([iots.string, iots.null]),
    }),
], 'IOUserPlot');
export const IOUserPlotAdd = iots.type({
    added: iots.number,
    alreadyAdded: iots.number,
});
export const QUERY_KEY = ['user-plots'];
const fetchUserPlotsList = () => () => get(iots.array(IOUserPlot), 'legacy/user-plots');
const deleteFromUserPlots = ({ plots }) => post(IOEmpty, `user-plots/remove`, { plots });
const addPlotSelection = ({ plots }) => post(null, `user-plots/add`, { plots });
const useUserPlotsList = () => {
    const onErrorCallback = useOnError();
    const queryClient = useQueryClient();
    const { getSuccessToast } = useCustomToast();
    const { t } = useTranslation();
    const isTabOpened = useIsTabOpened();
    const { data, isInitialLoading, refetch, isRefetching } = useQuery({
        queryKey: QUERY_KEY,
        queryFn: fetchUserPlotsList(),
        enabled: isTabOpened(CRM_TABS.USER_PLOTS),
    });
    const invalidateList = () => {
        queryClient.invalidateQueries({ queryKey: QUERY_KEY });
    };
    const { mutate: removePlots } = useMutation({
        mutationFn: deleteFromUserPlots,
        onError: (err) => onErrorCallback(err),
        onSuccess: (_data, { plots }) => {
            invalidateList();
            getSuccessToast({
                title: t('tabs.user_plots.selected_plots_removed', {
                    count: plots.length,
                }),
                id: 'plots_removed_success',
            });
        },
    });
    const { mutate: addPlots } = useMutation({
        mutationFn: addPlotSelection,
        onError: (err) => onErrorCallback(err),
        onSuccess: (data) => {
            invalidateList();
            if (data.added > 0 && data.alreadyAdded === 0) {
                const trad = data.added > 1
                    ? 'tabs.user_plots.selected_plots_added'
                    : 'tabs.user_plots.selected_plots_added_one';
                getSuccessToast({
                    title: t(trad, {
                        added: data.added,
                    }) + '.',
                    id: 'plots_added_success',
                });
            }
            else if (data.alreadyAdded > 0 && data.added === 0) {
                const trad = data.alreadyAdded > 1
                    ? 'tabs.user_plots.selected_plots_already_added'
                    : 'tabs.user_plots.selected_plots_already_added_one';
                getSuccessToast({
                    title: t(trad, {
                        alreadyAdded: data.alreadyAdded,
                    }) + '.',
                    id: 'plots_added_success',
                });
            }
            else {
                let title = data.added > 1
                    ? t('tabs.user_plots.selected_plots_added', {
                        added: data.added,
                        alreadyAdded: data.alreadyAdded,
                    })
                    : t('tabs.user_plots.selected_plots_added_one', {
                        added: data.added,
                        alreadyAdded: data.alreadyAdded,
                    });
                title += ' ' + t('global.and') + ' ';
                title +=
                    data.alreadyAdded > 1
                        ? t('tabs.user_plots.selected_plots_already_added', {
                            added: data.added,
                            alreadyAdded: data.alreadyAdded,
                        })
                        : t('tabs.user_plots.selected_plots_already_added_one', {
                            added: data.added,
                            alreadyAdded: data.alreadyAdded,
                        });
                title += '.';
                getSuccessToast({
                    title,
                    id: 'plots_added_success',
                });
            }
        },
    });
    return {
        data,
        isInitialLoading,
        refetch,
        removePlots,
        isRefetching,
        addPlots,
    };
};
export default useUserPlotsList;
