import { useContextSelector } from 'use-context-selector';
import ModalContext from 'context/ModalContext';
export const useIsPlotModalOpen = () => {
    return useContextSelector(ModalContext, (state) => state?.isPlotModalOpen);
};
export const useOpenPlotModal = () => {
    return useContextSelector(ModalContext, (state) => state?.openPlotModal);
};
export const useClosePlotModal = () => {
    return useContextSelector(ModalContext, (state) => state?.closePlotModal);
};
export const usePlotData = () => {
    return useContextSelector(ModalContext, (state) => state?.plotData);
};
