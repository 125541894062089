import * as React from 'react';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, Divider, FormControl, FormLabel, HStack, Input, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Text, VStack, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_TABLE } from 'analytics/useAnalytics';
import { t } from 'i18next';
import SearchInputWrapper from 'components/inputs/SearchInputWrapper';
import CheckIcon from 'images/icons/Check';
import { searchMatch } from 'utils/search';
import { FILTER_DATE_TYPE, FILTER_NUMBER_TYPE } from './FilterDropdown';
const filtersTranslations = {
    residency: 'sidebar_content.building_permit_new_market.building_permit_housing.purpose.',
    purpose: 'sidebar_content.building_permit_new_market.building_permit_offices.purpose.',
    delay: 'activity.fields.common.delay.options.',
};
export const displayValue = (value, columnId) => {
    if (!value) {
        return '';
    }
    if (filtersTranslations[columnId] && typeof value === 'string') {
        return value
            .split(', ')
            .map((el) => t(filtersTranslations[columnId] + el))
            .join(', ');
    }
    return value;
};
const FilterForm = ({ filters, column, query, setQuery, canFilter, filterType, analytics, }) => {
    const { trackEvent } = useAnalytics();
    const { t } = useTranslation();
    const columnFilterValue = column.getFilterValue();
    const uniqueValues = Array.from(column.getFacetedUniqueValues()?.keys());
    const sortedUniqueValues = useMemo(() => {
        if (!canFilter ||
            filterType === FILTER_NUMBER_TYPE ||
            filterType === FILTER_DATE_TYPE) {
            return [];
        }
        return uniqueValues
            .sort((a, b) => {
            return a?.localeCompare(b);
        })
            .filter((item) => {
            if (query) {
                if (typeof item === 'string' || typeof item === 'number') {
                    return searchMatch(item.toString(), query);
                }
                return false;
            }
            return true;
        });
    }, [JSON.stringify(uniqueValues), query, canFilter]);
    if (filterType === FILTER_NUMBER_TYPE) {
        return (<HStack paddingBottom={4} paddingX={4}>
        <FormControl>
          <FormLabel>{t('table.from')}</FormLabel>
          <NumberInput value={columnFilterValue?.[0] ?? ''} onChange={(valueString) => column.setFilterValue((old) => [
                valueString,
                old?.[1],
            ])} size="sm">
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>

        <FormControl>
          <FormLabel>{t('table.to')}</FormLabel>
          <NumberInput value={columnFilterValue?.[1] ?? ''} onChange={(valueString) => {
                return column.setFilterValue((old) => [
                    old?.[0],
                    valueString,
                ]);
            }} size="sm">
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
      </HStack>);
    }
    if (filterType === FILTER_DATE_TYPE) {
        return (<HStack paddingBottom={4} paddingX={4}>
        <FormControl>
          <FormLabel>{t('table.from')}</FormLabel>
          <Input placeholder={t('table.from')} size="sm" type="date" value={columnFilterValue?.[0] ?? ''} onChange={(e) => {
                return column.setFilterValue((old) => [
                    e.target.value,
                    old?.[1],
                ]);
            }}/>
        </FormControl>
        <FormControl>
          <FormLabel>{t('table.to')}</FormLabel>
          <Input placeholder={t('table.to')} size="sm" type="date" value={columnFilterValue?.[1] ?? ''} onChange={(e) => {
                return column.setFilterValue((old) => [
                    old?.[0],
                    e.target.value,
                ]);
            }}/>
        </FormControl>
      </HStack>);
    }
    return (<>
      <VStack paddingX={4} paddingBottom={4}>
        <SearchInputWrapper>
          <Input placeholder={t('table.search')} value={query ?? ''} onChange={(e) => {
            column.setFilterValue([]);
            const value = e.target.value;
            setQuery(value.trim() !== '' ? value : null);
        }} boxShadow="sm" type="search"/>
        </SearchInputWrapper>
      </VStack>

      <Divider />

      <Box overflowY="scroll" maxHeight="250px">
        <VStack padding={4} justifyContent="flex-start" alignItems="flex-start">
          <Checkbox isChecked={sortedUniqueValues.length > 0 &&
            sortedUniqueValues.length === filters.length} icon={<CheckIcon />} onChange={() => {
            // All values already selected
            if (sortedUniqueValues.length === filters.length) {
                column.setFilterValue([]);
                return;
            }
            column.setFilterValue([...sortedUniqueValues]);
            if (analytics) {
                trackEvent({
                    category: ANALYTICS_TABLE,
                    action: `${analytics?.tableName} filtrer ${analytics?.getColumnName(column.id)}`,
                });
            }
        }}>
            <Text as="span" textStyle="paragraph" fontWeight={400}>
              {t('table.select_all')}
            </Text>
          </Checkbox>

          {sortedUniqueValues.map((value) => {
            return (<Checkbox key={value} isChecked={filters.includes(value)} onChange={() => {
                    if (filters.includes(value)) {
                        column.setFilterValue(filters.filter((filter) => filter !== value));
                    }
                    else {
                        column.setFilterValue([...filters, value]);
                        if (analytics) {
                            trackEvent({
                                category: ANALYTICS_TABLE,
                                action: `${analytics?.tableName} filtrer ${analytics?.getColumnName(column.id)}`,
                            });
                        }
                    }
                }} icon={<CheckIcon />}>
                <Text as="span" textStyle="paragraph" whiteSpace="normal" fontWeight={400}>
                  {displayValue(value, column.id)}
                </Text>
              </Checkbox>);
        })}
        </VStack>
      </Box>
    </>);
};
export default memo(FilterForm);
