import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Button, Checkbox, FormControl, FormLabel, Heading, HStack, Input, NumberInput, NumberInputField, Stack, Text, useDisclosure, } from '@chakra-ui/react';
import AreaInputWrapper from 'components/inputs/AreaInputWrapper';
import { multiSelectStyles } from 'components/inputs/MultiSelectInput';
import SearchCheckbox from 'components/inputs/SearchCheckbox';
import SelectInput from 'components/inputs/SelectInput';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import CheckIcon from 'images/icons/Check';
import { accordionBoxShadow } from 'utils/accordionArrowStyle';
import { BUILDING_PURPOSE_KEY, COOWNERS_LOTS_KEY, FARMING_KEY, OFFICE_CONSTRUCTION_KEY, REDUCED_VAT_ZONE_KEY, ROOFING_MATERIAL_KEY, STORE_CONSTRUCTION_KEY, WALL_MATERIAL_KEY, WAREHOUSE_CONSTRUCTION_KEY, } from 'utils/plotsSearch';
import SeeMoreInformation from './SeeMoreInformation';
export const ELEVATION_OPTIONS_KEY = {
    1: 'sidebar.search_plots.possible_elevations_option.level1',
    2: 'sidebar.search_plots.possible_elevations_option.level2',
    3: 'sidebar.search_plots.possible_elevations_option.level3',
};
export const advancedSearchFields = [
    'undevelopedPlots',
    'possibleElevations',
    'smallFootprints',
    'coOwnerLots',
    'currentBuildingFootprint',
    'buildingPurpose',
    'constructionYear',
    'eaveHeight',
    'numberOfLevels',
    'buildingFloorArea',
    'roofingMaterial',
    'wallMaterial',
    'excludeFloodProneAreas',
    'officeConstruction',
    'storeConstruction',
    'warehouseConstruction',
    'removeStreets',
    'roadLinear',
    'reducedVATZone',
    'socialMix',
    'farming',
];
export const getPlotSelectOptions = (optionDetails, fieldName, t, withSorting = false) => {
    const options = [];
    for (const option in optionDetails) {
        options.push({
            value: option.replace('#', ''),
            label: t(`sidebar.search_plots.${fieldName}_options.${optionDetails[option]}`),
        });
    }
    if (withSorting)
        options.sort((a, b) => a.label.localeCompare(b.label));
    return options;
};
export const getSelectedOptionsLabel = (optionDetails, fieldName, option, t) => {
    return t(`sidebar.search_plots.${fieldName}_options.${optionDetails[option]}`);
};
export const getSelectedOptionsArrayLabel = (field, option, t) => {
    return t(`sidebar.search_plots.${field}_options.${option}`);
};
const AdvancedSearch = ({ isLoading, hasSearchField, hasFilter, }) => {
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { control, register, setValue } = useFormContext();
    const selectedTown = useSelectedTown();
    const elevationOptions = [];
    for (const key in ELEVATION_OPTIONS_KEY) {
        elevationOptions.push({
            value: key,
            label: t(ELEVATION_OPTIONS_KEY[key]),
        });
    }
    const hasSearchFields = (fieldNames) => fieldNames.some(hasSearchField);
    return (<Stack alignItems="start" padding={4} boxShadow={accordionBoxShadow} bgColor="colorMode.sidebar500" spacing={2}>
      {hasSearchFields([
            'buildingFloorArea',
            'currentBuildingFootprint',
            'numberOfLevels',
            'currentBuildingFootprint',
            'buildingPurpose',
            'constructionYear',
            'eaveHeight',
        ]) && (<>
          <Text textStyle="paragraph" fontWeight={500}>
            {t('sidebar.search_plots.current_building')}
          </Text>

          {hasSearchField('buildingFloorArea') && (<FormControl isDisabled={isLoading}>
              <FormLabel marginBottom={1}>
                {t('sidebar.search_plots.building_floor_area')}
              </FormLabel>
              <HStack>
                <AreaInputWrapper rightElementLabel="m²">
                  <Input size="sm" placeholder="Min" type="number" {...register('buildingFloorAreaMin')}/>
                </AreaInputWrapper>
                <AreaInputWrapper rightElementLabel="m²">
                  <Input size="sm" placeholder="Max" type="number" {...register('buildingFloorAreaMax')}/>
                </AreaInputWrapper>
              </HStack>
            </FormControl>)}

          {hasSearchField('numberOfLevels') && (<FormControl isDisabled={isLoading}>
              <FormLabel marginBottom={1}>
                {t('sidebar.search_plots.number_of_levels')}
              </FormLabel>
              <HStack>
                <AreaInputWrapper>
                  <Input size="sm" placeholder="Min" type="number" {...register('numberOfLevelsMin')}/>
                </AreaInputWrapper>
                <AreaInputWrapper>
                  <Input size="sm" placeholder="Max" type="number" {...register('numberOfLevelsMax')}/>
                </AreaInputWrapper>
              </HStack>
            </FormControl>)}

          {hasSearchField('currentBuildingFootprint') && (<FormControl isDisabled={isLoading}>
              <FormLabel marginBottom={1}>
                {t('sidebar.search_plots.current_building_footprint')}
              </FormLabel>
              <HStack>
                <AreaInputWrapper rightElementLabel="%">
                  <Input size="sm" placeholder="Min" type="number" {...register('currentBuildingFootprintMin')}/>
                </AreaInputWrapper>
                <AreaInputWrapper rightElementLabel="%">
                  <Input size="sm" placeholder="Max" type="number" {...register('currentBuildingFootprintMax')}/>
                </AreaInputWrapper>
              </HStack>
            </FormControl>)}

          {hasSearchField('buildingPurpose') && (<SelectInput size="sm" isLoading={isLoading} name="buildingPurpose" label={t('sidebar.search_plots.building_purpose')} options={getPlotSelectOptions(BUILDING_PURPOSE_KEY, 'building_purpose', t)}/>)}

          {hasSearchField('constructionYear') && (<FormControl isDisabled={isLoading}>
              <FormLabel marginBottom={1}>
                {t('sidebar.search_plots.construction_year')}
              </FormLabel>
              <HStack>
                <AreaInputWrapper>
                  <Input size="sm" placeholder="Min" type="number" {...register('constructionYearMin')}/>
                </AreaInputWrapper>
                <AreaInputWrapper>
                  <Input size="sm" placeholder="Max" type="number" {...register('constructionYearMax')}/>
                </AreaInputWrapper>
              </HStack>
            </FormControl>)}

          {hasSearchField('eaveHeight') && (<FormControl isDisabled={isLoading}>
              <FormLabel marginBottom={1}>
                {t('sidebar.search_plots.eave_height')}
              </FormLabel>
              <HStack>
                <AreaInputWrapper rightElementLabel="m">
                  <Input size="sm" placeholder="Min" type="number" {...register('eaveHeightMin')}/>
                </AreaInputWrapper>
                <AreaInputWrapper rightElementLabel="m">
                  <Input size="sm" placeholder="Max" type="number" {...register('eaveHeightMax')}/>
                </AreaInputWrapper>
              </HStack>
            </FormControl>)}
        </>)}

      {hasSearchFields(['undevelopedPlots', 'roadLinear', 'removeStreets']) && (<Text textStyle="paragraph" fontWeight={500}>
          {t('sidebar.search_plots.plot')}
        </Text>)}

      {hasSearchField('undevelopedPlots') && (<>
          <FormControl display="flex" isDisabled={isLoading}>
            <SearchCheckbox name="undevelopedPlots"/>
            <FormLabel margin="0" paddingLeft={3} cursor="pointer">
              {t('sidebar.search_plots.search_undeveloped_plots')}
            </FormLabel>
          </FormControl>
        </>)}

      {hasSearchField('roadLinear') && (<FormControl isDisabled={isLoading}>
          <FormLabel marginBottom={1}>
            {t('sidebar.search_plots.road_linear')}
          </FormLabel>
          <HStack>
            <AreaInputWrapper>
              <Input size="sm" placeholder="Min" type="number" {...register('roadLinearMin')}/>
            </AreaInputWrapper>
            <AreaInputWrapper>
              <Input size="sm" placeholder="Max" type="number" {...register('roadLinearMax')}/>
            </AreaInputWrapper>
          </HStack>
        </FormControl>)}

      {hasSearchField('coOwnerLots') && (<SelectInput isLoading={isLoading} name="coOwnerLots" size="sm" variant="filled" label={t('sidebar.search_plots.coOwnerLots')} options={getPlotSelectOptions(COOWNERS_LOTS_KEY, 'coOwnerLots', t)}/>)}

      {selectedTown?.hasFeasibility &&
            hasSearchField('warehouseConstruction') &&
            hasFilter('warehouseConstruction') && (<FormControl isDisabled={isLoading}>
            <FormLabel marginBottom={1}>
              {t('sidebar.search_plots.warehouse_construction')}
            </FormLabel>
            <HStack>
              <Controller name="warehouseConstruction" control={control} render={({ field }) => (<Select {...field} isLoading={isLoading} placeholder={t('global.indifferente')} closeMenuOnSelect={false} options={getPlotSelectOptions(WAREHOUSE_CONSTRUCTION_KEY, 'construction', t)} isMulti styles={multiSelectStyles}/>)}/>
            </HStack>
          </FormControl>)}

      {hasSearchField('reducedVATZone') && (<SelectInput size="sm" variant="filled" isLoading={isLoading} name="reducedVATZone" label={t('sidebar.search_plots.reduced_vat_zone')} options={getPlotSelectOptions(REDUCED_VAT_ZONE_KEY, 'reduced_vat_zone', t)}/>)}

      {hasSearchField('farming') && (<FormControl isDisabled={isLoading}>
          <FormLabel marginBottom={1}>
            {t('sidebar.search_plots.farming')}
          </FormLabel>
          <HStack>
            <Controller name="farming" control={control} render={({ field }) => (<Select {...field} placeholder={t('global.indifferente')} closeMenuOnSelect={false} options={getPlotSelectOptions(FARMING_KEY, 'farming', t, true)} isMulti styles={multiSelectStyles}/>)}/>
          </HStack>
        </FormControl>)}

      {hasSearchField('excludeFloodProneAreas') && (<FormControl display="flex" isDisabled={isLoading}>
          <SearchCheckbox name="excludeFloodProneAreas"/>
          <FormLabel margin="0" paddingLeft={3} cursor="pointer">
            {t('sidebar.search_plots.exclude_flood_prone_areas')}
          </FormLabel>
        </FormControl>)}

      {selectedTown?.hasFeasibility && (<>
          {hasSearchField('possibleElevations') && (<>
              <FormControl isDisabled={isLoading}>
                <Checkbox icon={<CheckIcon />} onChange={(e) => {
                    if (e.target.checked) {
                        setValue('possibleElevations', elevationOptions);
                    }
                    else {
                        setValue('possibleElevations', []);
                    }
                }}>
                  <FormLabel marginRight="0">
                    {t('sidebar.search_plots.possible_elevations')}
                  </FormLabel>
                </Checkbox>

                <Controller name="possibleElevations" control={control} render={({ field }) => (<Select {...field} placeholder={t('sidebar.search_plots.possible_elevations_option.placeholder')} closeMenuOnSelect={false} options={elevationOptions} isMulti styles={multiSelectStyles}/>)}/>
              </FormControl>
            </>)}

          {hasSearchField('smallFootprints') && (<>
              <FormControl display="flex" isDisabled={isLoading}>
                <SearchCheckbox name="smallFootprints"/>
                <FormLabel margin="0" paddingLeft={3} cursor="pointer">
                  {t('sidebar.search_plots.small_footprints')}
                </FormLabel>
              </FormControl>

              <SeeMoreInformation title="sidebar.search_plots.small_footprints_title" value="sidebar.search_plots.small_footprints_text"/>
            </>)}
        </>)}

      {hasSearchField('removeStreets') && (<FormControl display="flex" isDisabled={isLoading}>
          <SearchCheckbox name="removeStreets"/>
          <FormLabel margin="0" paddingLeft={3} cursor="pointer">
            {t('sidebar.search_plots.remove_streets')}
          </FormLabel>
        </FormControl>)}

      {selectedTown?.hasRegulationAnalysis && hasSearchField('socialMix') && (<>
          <FormControl isDisabled={isLoading}>
            <FormLabel htmlFor="socialMix" marginBottom={1}>
              {t('sidebar.search_plots.social_mix')}
            </FormLabel>
            <HStack>
              <AreaInputWrapper rightElementLabel="%">
                <NumberInput size="sm" min={0} max={100}>
                  <NumberInputField placeholder="Min" maxLength={5} {...register('socialMixMin', { valueAsNumber: true })} data-cy="min_social_mix_input"/>
                </NumberInput>
              </AreaInputWrapper>
              <AreaInputWrapper rightElementLabel="%">
                <NumberInput size="sm" min={0} max={100}>
                  <NumberInputField placeholder="Max" maxLength={5} {...register('socialMixMax', { valueAsNumber: true })} data-cy="max_social_mix_input"/>
                </NumberInput>
              </AreaInputWrapper>
            </HStack>
          </FormControl>
          <FormControl display="flex" isDisabled={isLoading}>
            <SearchCheckbox name="socialMix"/>
            <FormLabel margin="0" paddingLeft={3} cursor="pointer">
              {t('sidebar.search_plots.social_mix_remove')}
            </FormLabel>
          </FormControl>
        </>)}

      {(hasSearchField('wallMaterial') ||
            hasSearchField('roofingMaterial') ||
            ((hasSearchField('officeConstruction') ||
                hasSearchField('storeConstruction')) &&
                selectedTown?.hasFeasibility)) && (<Button size="sm" variant="link" onClick={() => {
                isOpen ? onClose() : onOpen();
            }}>
          <Heading as="h5" variant="h5" fontWeight={500} lineHeight={5} whiteSpace="normal" textDecoration="underline">
            {!isOpen
                ? t('sidebar.search_plots.more_criteria')
                : t('sidebar.search_plots.less_criteria')}
            <ChevronDownIcon w={4} h={4} fill={'colorMode.font800'} transform={isOpen ? 'rotateX(180deg)' : 'rotateX(0deg)'} transition="transform 0.5s"/>
          </Heading>
        </Button>)}

      {isOpen && (<>
          {hasSearchField('roofingMaterial') && (<SelectInput size="sm" variant="filled" isLoading={isLoading} name="roofingMaterial" label={t('sidebar.search_plots.roofing_material')} options={getPlotSelectOptions(ROOFING_MATERIAL_KEY, 'roofing_material', t)}/>)}

          {hasSearchField('wallMaterial') && (<SelectInput size="sm" variant="filled" isLoading={isLoading} name="wallMaterial" label={t('sidebar.search_plots.wall_material')} options={getPlotSelectOptions(WALL_MATERIAL_KEY, 'wall_material', t)}/>)}
          {selectedTown?.hasFeasibility && (<>
              {hasSearchField('officeConstruction') && (<FormControl isDisabled={isLoading}>
                  <FormLabel marginBottom={1}>
                    {t('sidebar.search_plots.office_construction')}
                  </FormLabel>
                  <HStack>
                    <Controller name="officeConstruction" control={control} render={({ field }) => (<Select {...field} isLoading={isLoading} placeholder={t('global.indifferente')} closeMenuOnSelect={false} options={getPlotSelectOptions(OFFICE_CONSTRUCTION_KEY, 'construction', t)} isMulti styles={multiSelectStyles}/>)}/>
                  </HStack>
                </FormControl>)}

              {hasSearchField('storeConstruction') && (<FormControl isDisabled={isLoading}>
                  <FormLabel marginBottom={1}>
                    {t('sidebar.search_plots.store_construction')}
                  </FormLabel>
                  <HStack>
                    <Controller name="storeConstruction" control={control} render={({ field }) => (<Select {...field} isLoading={isLoading} placeholder={t('global.indifferente')} closeMenuOnSelect={false} options={getPlotSelectOptions(STORE_CONSTRUCTION_KEY, 'construction', t)} isMulti styles={multiSelectStyles}/>)}/>
                  </HStack>
                </FormControl>)}
            </>)}
        </>)}
    </Stack>);
};
export default AdvancedSearch;
