import { useMemo } from 'react';
import { useGetFeasibilityPlots } from 'hooks/contexts/useSecondaryPlotsContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import { filtersKey } from 'utils/filters';
const useFeasibilityPlotsLayer = () => {
    const getFeasibilityPlots = useGetFeasibilityPlots();
    const feasibilityPlots = getFeasibilityPlots();
    const providedData = useMemo(() => {
        return feasibilityPlots ? Object.values(feasibilityPlots) : null;
    }, [feasibilityPlots]);
    createLayersHook({
        providedData,
        // this overrides the selected plots layer
        // but if we change it, the feasibility plots are never shown
        // since the related filter is never enabled
        alwaysEnabled: true,
    })([filtersKey.FEASIBILITY_PLOTS]);
};
const FeasibilityPlotsLayer = () => {
    useFeasibilityPlotsLayer();
    return null;
};
export default FeasibilityPlotsLayer;
