import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IORegionalPlanUrbanAreaLayerItem = IOLayerItem;
const fetchRegionalPlanUrbanArea = (townId) => () => get(iots.array(IORegionalPlanUrbanAreaLayerItem), `legacy/${townId}/regional-plan-urban-area`);
const useRegionalPlanUrbanAreaLayer = () => {
    createLayersHook({
        fetch: fetchRegionalPlanUrbanArea,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.REGIONAL_PLAN_URBAN_AREA);
};
const RegionalPlanUrbanAreaLayer = () => {
    useRegionalPlanUrbanAreaLayer();
    return null;
};
export default RegionalPlanUrbanAreaLayer;
