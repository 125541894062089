import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOCommercialScotLayerItem = IOLayerItem;
const fetchCommercialScot = (townId, townScot) => () => get(iots.array(IOCommercialScotLayerItem), `legacy/${townId}/commercial-scot/${townScot}`);
const useCommercialScotLayer = () => {
    createLayersHook({
        fetch: fetchCommercialScot,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.COMMERCIAL_SCOT);
};
const CommercialScotLayer = () => {
    useCommercialScotLayer();
    return null;
};
export default CommercialScotLayer;
