import * as React from 'react';
import { ANALYTICS_CATEGORY_DOWNLOAD } from 'analytics/useAnalytics';
import ExportButton from 'components/buttons/ExportButton';
const TableExportButton = ({ exportName, exportTitle, isLoading, dataFiltered, analyticsAction, exportedFields, formatExportHeader, formatExportValue, sheetMode, extraExportItems, exportItems, exportButtonTitle, }) => {
    return (<ExportButton fileName={exportName || exportTitle} exportItems={exportItems} isDisabled={isLoading || dataFiltered.length === 0} analyticsEvent={analyticsAction && {
            category: ANALYTICS_CATEGORY_DOWNLOAD,
            action: analyticsAction,
        }} getSheets={() => [
            {
                exportedFields,
                formatHeader: formatExportHeader,
                formatValue: formatExportValue,
                sheetTitle: exportTitle,
                getData: () => dataFiltered,
                mode: sheetMode,
            },
        ]} exportButtonTitle={exportButtonTitle}>
      {extraExportItems}
    </ExportButton>);
};
export default TableExportButton;
