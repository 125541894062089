import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IONoBuildingZoneResourcesAndFacilitiesLayerItem = IOLayerItem;
const fetchNoBuildingZoneResourcesAndFacilities = (townId) => () => get(iots.array(IONoBuildingZoneResourcesAndFacilitiesLayerItem), `legacy/${townId}/no-building-zone-resources-and-facilities`);
const useNoBuildingZoneResourcesAndFacilitiesLayer = () => {
    createLayersHook({
        fetch: fetchNoBuildingZoneResourcesAndFacilities,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.NO_BUILDING_ZONE_RESOURCES_AND_FACILITIES);
};
const NoBuildingZoneResourcesAndFacilitiesLayer = () => {
    useNoBuildingZoneResourcesAndFacilitiesLayer();
    return null;
};
export default NoBuildingZoneResourcesAndFacilitiesLayer;
