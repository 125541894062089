import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOParkingSpaceExemptionLayerItem = IOLayerItem;
const fetchParkingSpaceFutureExemptionLayer = (townId) => () => get(iots.array(IOParkingSpaceExemptionLayerItem), `legacy/${townId}/future-parking-space-exemption`);
const useParkingSpaceFutureExemptionLayer = () => {
    createLayersHook({
        fetch: fetchParkingSpaceFutureExemptionLayer,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.PARKING_SPACE_EXEMPTION_FUTURE);
};
const ParkingSpaceFutureExemptionLayer = () => {
    useParkingSpaceFutureExemptionLayer();
    return null;
};
export default ParkingSpaceFutureExemptionLayer;
