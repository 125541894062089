import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';
import * as iots from 'io-ts';
export const IOUserPOIInfo = iots.type({
    name: iots.union([iots.string, iots.null]),
    address: iots.string,
    status: iots.number,
    deliveryYear: iots.string,
    lodgings: iots.string,
    label1: iots.union([iots.string, iots.null]),
    info1: iots.union([iots.string, iots.null]),
    label2: iots.union([iots.string, iots.null]),
    info2: iots.union([iots.string, iots.null]),
    label3: iots.union([iots.string, iots.null]),
    info3: iots.union([iots.string, iots.null]),
    label4: iots.union([iots.string, iots.null]),
    info4: iots.union([iots.string, iots.null]),
    label5: iots.union([iots.string, iots.null]),
    info5: iots.union([iots.string, iots.null]),
    label6: iots.union([iots.string, iots.null]),
    info6: iots.union([iots.string, iots.null]),
    label7: iots.union([iots.string, iots.null]),
    info7: iots.union([iots.string, iots.null]),
    label8: iots.union([iots.string, iots.null]),
    info8: iots.union([iots.string, iots.null]),
});
const UserPOIInfoWindow = ({ info }) => {
    const { t } = useTranslation();
    if (!info) {
        return null;
    }
    return (<Box maxHeight="400px">
      {info.name && (<Box>
          <b>{t('info_window.user_pois.name')}</b> : {info.name}
        </Box>)}
      {info.address && (<Box>
          <b>{t('info_window.user_pois.address')}</b> : {info.address}
        </Box>)}
      {info.deliveryYear && (<Box>
          <b>{t('info_window.user_pois.delivery_year')}</b> :{' '}
          {info.deliveryYear}
        </Box>)}
      {info.lodgings && (<Box>
          <b>{t('info_window.user_pois.lodgings')}</b> : {info.lodgings}
        </Box>)}
      {[...Array(20)].map((_, index) => {
            const value = info[`info${index}`];
            const label = info[`label${index}`];
            return value ? (<Box key={index}>
            <b>{label}</b>
            {' : '}
            {value.startsWith('http') ? (<a href={value} target="_blank" rel="noreferrer">
                {value}
              </a>) : (value)}
          </Box>) : (<></>);
        })}
    </Box>);
};
export default UserPOIInfoWindow;
