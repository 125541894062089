const getTileUrlHost = (baseTile, vhost) => {
    const hostList = JSON.parse(process.env.BASE_TILE_URL_HOST_LIST);
    const vhostList = JSON.parse(vhost);
    const randomHost = hostList[Math.floor(Math.random() * hostList.length)];
    const randomVhost = vhostList[Math.floor(Math.random() * vhostList.length)];
    baseTile = baseTile.replace('%host%', randomHost);
    baseTile = baseTile.replace('%vhost%', randomVhost);
    return baseTile;
};
export const getBaseTileUrlHost3 = () => {
    return getTileUrlHost(process.env.BASE_TILE_URL_HOST_3, process.env.BASE_TILE_URL_VHOST_LIST);
};
export const getCommuneTileUrlHost3 = () => {
    return getTileUrlHost(process.env.BASE_TILE_URL_HOST_3, process.env.BASE_TILE_URL_VHOST_COMMUNE_LIST);
};
export const getBaseTileUrlHosts3 = () => {
    const baseTile = process.env.BASE_TILE_URL_HOSTS_3;
    const hostList = JSON.parse(process.env.BASE_TILE_URL_HOST_LIST);
    const randomHost = hostList[Math.floor(Math.random() * hostList.length)];
    return baseTile.replace('%host%', randomHost);
};
export const getTileUrlHost2 = () => {
    return getTileUrlHost(process.env.BASE_TILE_URL_HOST_2, process.env.BASE_TILE_URL_VHOST_2_LIST);
};
export const getThematicBaseTileUrlHost2 = () => {
    const baseTile = process.env.BASE_TILE_URL_HOST_2_THEMATIC;
    const hostList = JSON.parse(process.env.BASE_TILE_URL_HOST_LIST);
    const randomHost = hostList[Math.floor(Math.random() * hostList.length)];
    return baseTile.replace('%host%', randomHost);
};
