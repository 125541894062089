import * as React from 'react';
import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getLayerBoundingTileUrlTileUrl, getNormalizedCoordinates, } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
export const DVF_ZOOM_LIMIT = 12;
const getLayerTileUrl = (map, tileWidth, tileHeight) => (coord, zoom) => {
    const normCoord = getNormalizedCoordinates(coord, zoom);
    if (!normCoord) {
        return null;
    }
    return getLayerBoundingTileUrlTileUrl(map, 'Applicatif', 'dvf_avec_bati', zoom, coord, tileWidth, tileHeight);
};
const getLegends = () => {
    const legends = {};
    legends.old_market_dvf_built = {
        layerKey: filtersKey.OLD_MARKET_DVF,
        label: 'old_market_dvf_built',
        needsTranslate: true,
        color: '#58bffa',
    };
    legends.old_market_dvf_no_built = {
        layerKey: filtersKey.OLD_MARKET_DVF,
        label: 'old_market_dvf_no_built',
        needsTranslate: true,
        color: '#e5d34e',
    };
    return legends;
};
const useOldMarketDvfLayer = () => {
    createTilesLayerHook({
        getLayerTileUrl: getLayerTileUrl,
        getLegends,
        position: CST_POSITIONS[filtersKey.OLD_MARKET_DVF],
        tileWidth: 1024,
        tileHeight: 1024,
    })(filtersKey.OLD_MARKET_DVF);
};
const OldMarketDvfLayer = () => {
    useOldMarketDvfLayer();
    return null;
};
const OldMarketDvfWrapper = ({ computedZoom }) => {
    if (computedZoom < DVF_ZOOM_LIMIT) {
        return null;
    }
    return <OldMarketDvfLayer />;
};
export default OldMarketDvfWrapper;
