import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOOINLayerItem = IOLayerItem;
const fetchOINLayer = (townId) => () => get(iots.array(IOOINLayerItem), `legacy/${townId}/oin`);
const useOINLayer = () => {
    createLayersHook({
        fetch: fetchOINLayer,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.OIN);
};
const OINLayer = () => {
    useOINLayer();
    return null;
};
export default OINLayer;
