import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Flex, Heading, IconButton, Spinner, Text, } from '@chakra-ui/react';
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import ChartCustomTooltip from 'components/chart/ChartCustomTooltip';
import CustomLabel from 'components/chart/CustomLabel';
import { useMap } from 'hooks/contexts/useMapContext';
import CloseIcon from 'images/icons/Close';
import { numberWithSpaces } from 'utils/text';
const DEFAULT_COLOR = '#FF0000';
const CIRCLE_DEFAULT_OPTIONS = {
    strokeColor: DEFAULT_COLOR,
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: DEFAULT_COLOR,
    fillOpacity: 0.35,
    radius: 10,
    visible: true,
    zIndex: 2,
};
const formatDataforGraph = (altimetryPoints, distanceTotal) => {
    let distanceFromBase = 0;
    let previousLatLng = null;
    return altimetryPoints.map((element) => {
        const latLng = new google.maps.LatLng(parseFloat(element.lat), parseFloat(element.lng));
        const distance = previousLatLng
            ? google.maps.geometry.spherical.computeLength([previousLatLng, latLng])
            : 0;
        distanceFromBase += Math.round(distance * 100) / 100;
        previousLatLng = latLng;
        return {
            distance: distanceTotal < 1000 ? distanceFromBase : distanceFromBase / 1000,
            elevation: element.elevation,
        };
    });
};
const AltimetryModal = ({ isLoading, altimetry, handleOnClose, }) => {
    const { t } = useTranslation();
    const map = useMap();
    const [activeTooltip, setActiveTooltip] = useState(null);
    const [circle, setCircle] = useState(null);
    useEffect(() => {
        if (circle) {
            circle.setMap(null);
        }
        if (map && altimetry && activeTooltip) {
            setCircle(new google.maps.Circle({
                ...CIRCLE_DEFAULT_OPTIONS,
                center: new google.maps.LatLng(parseFloat(altimetry.points[activeTooltip].lat), parseFloat(altimetry.points[activeTooltip].lng)),
                map: map,
            }));
        }
    }, [altimetry, activeTooltip, map]);
    const totalDistance = altimetry?.totalDistance ?? 0;
    const onClose = () => {
        circle?.setMap(null);
        setActiveTooltip(null);
        handleOnClose();
    };
    return (<Box minWidth="xl" position="absolute" right="0" zIndex="19" top="0" backgroundColor="colorMode.background900" boxShadow="lg" padding={4} borderRadius="md">
      <Flex justifyContent="space-between" marginBottom={4}>
        <Heading as="h4" variant="h4">
          {t('toolbar.measure.modal.title')}
        </Heading>
        <IconButton variant="link" aria-label="close" color="colorMode.font900" icon={<CloseIcon fill="currentColor"/>} onClick={onClose}/>
      </Flex>

      <Box marginBottom={4}>
        <Box marginBottom={4}>
          {!!totalDistance && (<Text textStyle="paragraph" dangerouslySetInnerHTML={{
                __html: t('toolbar.measure.distance_result', {
                    meter: numberWithSpaces(totalDistance.toFixed(2)),
                    km: (totalDistance / 1000).toFixed(2),
                }),
            }}></Text>)}
        </Box>
        {isLoading && <Spinner />}
        {!isLoading && altimetry && (<Box>
            <LineChart width={500} height={300} margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 10,
            }} data={formatDataforGraph(altimetry?.points ?? [], altimetry?.totalDistance)} onMouseMove={(e) => {
                if (e?.activeTooltipIndex !== activeTooltip) {
                    setActiveTooltip(e?.activeTooltipIndex);
                }
            }}>
              <Line isAnimationActive={false} type="monotone" dataKey="elevation" stroke="#8884d8" {...{ label: <CustomLabel /> }}/>
              <Tooltip content={<ChartCustomTooltip />}/>
              <CartesianGrid stroke="colorMode.background700"/>
              <XAxis dataKey="distance" fontSize="13px" stroke="var(--colorModeFont-700)" type="number" unit={altimetry?.totalDistance < 1000 ? '\u00A0m' : '\u00A0km'}/>

              <YAxis dataKey="elevation" domain={['dataMin', 'dataMax']} fontSize="13px" type="number" unit="&nbsp;m" tickFormatter={(tick) => Math.round(tick).toString()} stroke="var(--colorModeFont-700)"/>
            </LineChart>
            <Box>
              <Flex>
                <Text textStyle="paragraph" marginRight={1}>
                  {t('toolbar.measure.modal.positive_elevation_gain')} :
                </Text>
                <Text textStyle="paragraph" as="b">
                  {altimetry.positiveElevationGain.toFixed(2)} m
                </Text>
              </Flex>
              <Flex>
                <Text textStyle="paragraph" marginRight={1}>
                  {t('toolbar.measure.modal.negative_elevation_gain')} :
                </Text>
                <Text textStyle="paragraph" as="b">
                  {altimetry.negativeElevationGain.toFixed(2)} m
                </Text>
              </Flex>
              <Flex>
                <Text textStyle="paragraph" marginRight={1}>
                  {t('toolbar.measure.modal.average_slope')} :
                </Text>
                <Text textStyle="paragraph" as="b">
                  {altimetry.averageSlope.toFixed(0)} %
                </Text>
              </Flex>
              <Flex>
                <Text textStyle="paragraph" marginRight={1}>
                  {t('toolbar.measure.modal.steepest_slope')} :
                </Text>
                <Text textStyle="paragraph" as="b">
                  {altimetry.steepestSlope.toFixed(0)} %
                </Text>
              </Flex>
            </Box>
          </Box>)}
      </Box>

      <Flex width="full" justifyContent="flex-end">
        <Button variant="primary" onClick={onClose}>
          {t('toolbar.measure.modal.close')}
        </Button>
      </Flex>
    </Box>);
};
export default AltimetryModal;
