import * as React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useUpdatePlot } from 'hooks/contexts/useTabsContext';
import useUserHistory from 'hooks/crm/useUserHistory';
import usePlotByCoordinates from 'hooks/plot/usePlotByCoordinates';
import PlotPanelContent from './PlotPanelContent';
import { getPlotSkeleton } from './PlotPannelSkeletons';
const PlotPanel = () => {
    const [addedToHistory, setAddedToHistory] = useState(false);
    const [plotData, setPlotData] = useState(null);
    const { lat, lng, townId, plotId } = useParams();
    const coordinates = new google.maps.LatLng(parseFloat(lat), parseFloat(lng));
    const updatePlot = useUpdatePlot();
    const { addPlotToHistory } = useUserHistory();
    const { data, isInitialLoading: isPlotLoading } = usePlotByCoordinates({
        coordinates,
        enabled: Boolean(lat && lng && townId),
        townId: townId,
        plotId: plotId,
        successCallback: (data) => {
            updatePlot(data);
        },
    });
    useEffect(() => {
        if (data) {
            setPlotData(data);
        }
    }, [data]);
    useEffect(() => {
        if (plotData && !addedToHistory && !isPlotLoading) {
            setAddedToHistory(true);
            addPlotToHistory({
                plotId: plotData.id,
                cadastreId: plotData.cadastreId,
                townshipId: plotData.townId,
                townshipName: plotData.town,
                lat: plotData.lat,
                lng: plotData.lng,
            });
        }
    }, [isPlotLoading, plotData, addedToHistory]);
    const skeleton = getPlotSkeleton(true);
    if (isPlotLoading)
        return skeleton;
    if (!!plotData && !isPlotLoading)
        return <PlotPanelContent plot={plotData}/>;
    return null;
};
export default PlotPanel;
