import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOFireHazardLayerItem = IOLayerItem;
const fetchFireHazard = (townId) => () => get(iots.array(IOFireHazardLayerItem), `legacy/${townId}/fire-hazard`);
const useFireHazardLayer = () => {
    createLayersHook({
        fetch: fetchFireHazard,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.FIRE_HAZARD);
};
const FireHazardLayer = () => {
    useFireHazardLayer();
    return null;
};
export default FireHazardLayer;
