import { useCallback, useState } from 'react';
import { usePlotSelectionMode } from 'hooks/contexts/useLocationContext';
import { useSecondaryPlotByCoordinates } from 'hooks/useSecondaryPlotByCoordinates';
import { PLOT_SELECTION_FEASIBILITY, PLOT_SELECTION_PROJECT, } from './LocationContext';
// We use a constant to ensure that the empty object returned by getFeasibilityPlots is always the same
const EMPTY_PLOTS = {};
export const useContextFeasibilityPlots = (currentParentId, secondaryPlotCoord, setSecondaryPlotCoord, additional) => {
    const plotSelectionMode = usePlotSelectionMode();
    const [feasibilityPlots, setFeasibilityPlots] = useState({});
    const getFeasibilityPlots = useCallback((parentId) => {
        return feasibilityPlots[parentId ?? currentParentId] ?? EMPTY_PLOTS;
    }, [feasibilityPlots, currentParentId]);
    let plotId = '0';
    // Si on est en mode selection pour ajouter à la faisabilité (PLOT_SELECTION_FEASIBILITY) on ne prend pas en compte la parcelle d'ouverture
    if (plotSelectionMode !== PLOT_SELECTION_FEASIBILITY) {
        plotId = currentParentId;
    }
    const { isInitialLoading: isLoadingFeasibilityPlot } = useSecondaryPlotByCoordinates({
        additional,
        secondaryPlotCoord,
        enabled: !!currentParentId && plotSelectionMode !== PLOT_SELECTION_PROJECT,
        plotId,
        errorCallback: () => {
            setSecondaryPlotCoord(null);
        },
        successCallback: (data) => {
            toggleOneFeasibilityPlot(currentParentId, data);
        },
    });
    const toggleOneFeasibilityPlot = (parentId, plot) => {
        const newFeasibilityPlots = {
            ...(feasibilityPlots[parentId] ?? {}),
        };
        if (newFeasibilityPlots[plot?.id] &&
            plotSelectionMode === PLOT_SELECTION_FEASIBILITY) {
            // if plot has already been loaded remove it
            delete newFeasibilityPlots[plot?.id];
        }
        else {
            // else add it
            newFeasibilityPlots[plot?.id] = plot;
        }
        setFeasibilityPlots({
            ...feasibilityPlots,
            [parentId]: newFeasibilityPlots,
        });
    };
    const removeOneFeasibilityPlot = (parentId, plotId) => {
        const newFeasibilityPlots = {
            ...(feasibilityPlots[parentId] ?? {}),
        };
        delete newFeasibilityPlots[plotId];
        setFeasibilityPlots({
            ...feasibilityPlots,
            [parentId]: newFeasibilityPlots,
        });
    };
    const clearAllFeasibilityForParent = (parentId) => {
        const newFeasibilityPlots = {
            ...feasibilityPlots,
        };
        delete newFeasibilityPlots[parentId];
        setFeasibilityPlots(newFeasibilityPlots);
    };
    const resetFeasibilityPlots = () => {
        setFeasibilityPlots({});
    };
    return {
        getFeasibilityPlots,
        feasibilityPlots,
        isLoadingFeasibilityPlot,
        removeOneFeasibilityPlot,
        clearAllFeasibilityForParent,
        resetFeasibilityPlots,
    };
};
export default useContextFeasibilityPlots;
