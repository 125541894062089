import * as React from 'react';
import { memo } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { Stack, Text } from '@chakra-ui/react';
import { snakeCase } from 'lodash';
import Table from 'components/table/Table';
import useUserStatistics from 'hooks/crm/useUserStatistics';
const getColumnHeader = (column) => (<Translation>
    {(t) => (<Text align="center">{t(`tabs.my_statistics.columns.${column}`)}</Text>)}
  </Translation>);
const COLUMNS = [
    {
        id: 'columns',
        columns: [
            {
                accessorKey: 'id',
                header: () => getColumnHeader('id'),
                cell: (info) => <Text>{info.row.original.date}</Text>,
                filterFn: 'arrIncludesSome',
                enableColumnFilter: false,
            },
            {
                accessorKey: 'townsSeen',
                header: () => getColumnHeader('towns_seen'),
                cell: (info) => info.getValue(),
                filterFn: 'inNumberRange',
                enableGlobalFilter: false,
            },
            {
                accessorKey: 'addressLooked',
                header: () => getColumnHeader('address_looked'),
                cell: (info) => info.getValue(),
                filterFn: 'inNumberRange',
                enableGlobalFilter: false,
            },
            {
                accessorKey: 'favoritesCreated',
                header: () => getColumnHeader('favorites_created'),
                cell: (info) => info.getValue(),
                filterFn: 'inNumberRange',
                enableGlobalFilter: false,
            },
            {
                accessorKey: 'projectsCreated',
                header: () => getColumnHeader('projects_created'),
                cell: (info) => info.getValue(),
                filterFn: 'inNumberRange',
                enableGlobalFilter: false,
            },
        ],
    },
];
const EXPORTED_FIELDS = [
    'id',
    'townsSeen',
    'addressLooked',
    'favoritesCreated',
    'projectsCreated',
];
const formatExportHeader = (t) => (header) => t(`tabs.my_statistics.columns.${snakeCase(header)}`);
const initialSort = [
    {
        id: 'id',
        desc: true,
    },
];
const UserStatisticsPanel = () => {
    const { t } = useTranslation();
    const { data: userStatistics, isInitialLoading: isLoading } = useUserStatistics();
    return (<Stack paddingX={5} height="100%">
      <Table id="table-my-statistisc" initialSort={initialSort} columns={COLUMNS} data={userStatistics || []} isLoading={isLoading} emptyMessage={t(`global.no_result`)} exportTitle={t('tabs.my_statistics.title')} exportedFields={EXPORTED_FIELDS} formatExportHeader={formatExportHeader(t)}/>
    </Stack>);
};
export default memo(UserStatisticsPanel);
