import ProjectDocumentsSection from 'components/tabs/crmTabs/project/projectSections/DocumentsSection';
import FeasibilitySection from 'components/tabs/crmTabs/project/projectSections/FeasibilitySection';
import ProjectFinanceSection from 'components/tabs/crmTabs/project/projectSections/FinanceSection';
import ProjectFollowSection from 'components/tabs/crmTabs/project/projectSections/FollowSection';
import ProjectPhotosSection from 'components/tabs/crmTabs/project/projectSections/PhotosSection';
import ProjectPlotsSection from 'components/tabs/crmTabs/project/projectSections/PlotsSection';
import ProjectSection from 'components/tabs/crmTabs/project/projectSections/ProjectSection';
import RulesSection from 'components/tabs/crmTabs/project/projectSections/RulesSection';
import { PROJECT_DOCUMENTS, PROJECT_FEASIBILITY, PROJECT_FINANCE, PROJECT_FOLLOW, PROJECT_PHOTOS, PROJECT_PLOTS, PROJECT_RULES, PROJECT_SECTION, } from 'types/api';
export const SECTIONS = {
    [PROJECT_SECTION]: ProjectSection,
    [PROJECT_FEASIBILITY]: FeasibilitySection,
    [PROJECT_RULES]: RulesSection,
    [PROJECT_FOLLOW]: ProjectFollowSection,
    [PROJECT_PLOTS]: ProjectPlotsSection,
    [PROJECT_DOCUMENTS]: ProjectDocumentsSection,
    [PROJECT_PHOTOS]: ProjectPhotosSection,
    [PROJECT_FINANCE]: ProjectFinanceSection,
};
export const PROJECT_TOOLBAR_HEIGHT = '32px';
