import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { CST_POSITIONS } from 'utils/tilesPositions';
const LAYER_BASE_TILE_URL = process.env.BASE_TILE_URL_HOST_1 +
    'cgi-bin/mm/mapserv.cgi?map=/var/data/public_maps/project_seisme.map&LAYERS=risque_sismique&mode=tile&tilemode=gmap&tile=';
const getLayerTileUrl = () => (coord, zoom) => {
    return (LAYER_BASE_TILE_URL +
        coord.x +
        '+' +
        coord.y +
        '+' +
        zoom +
        '&FORMAT=image%2Fpng&TRANSPARENT=true&TILED=true&CRS=EPSG:3857');
};
const getLegends = (enabledFilters) => {
    if (!enabledFilters.includes(filtersKey.PPR_EARTHQUAKE_HAZARD)) {
        return {};
    }
    return {
        earthquake_area_type_1: {
            layerKey: filtersKey.PPR_EARTHQUAKE_HAZARD,
            label: 'earthquake_area_type_1',
            needsTranslate: true,
            color: '#F1F1F1',
            opacity: 0.6,
        },
        earthquake_area_type_2: {
            layerKey: filtersKey.PPR_EARTHQUAKE_HAZARD,
            label: 'earthquake_area_type_2',
            needsTranslate: true,
            color: '#FFD564',
            opacity: 0.6,
        },
        earthquake_area_type_3: {
            layerKey: filtersKey.PPR_EARTHQUAKE_HAZARD,
            label: 'earthquake_area_type_3',
            needsTranslate: true,
            color: '#FB9533',
            opacity: 0.6,
        },
        earthquake_area_type_4: {
            layerKey: filtersKey.PPR_EARTHQUAKE_HAZARD,
            label: 'earthquake_area_type_4',
            needsTranslate: true,
            color: '#E82D28',
            opacity: 0.6,
        },
        earthquake_area_type_5: {
            layerKey: filtersKey.PPR_EARTHQUAKE_HAZARD,
            label: 'earthquake_area_type_5',
            needsTranslate: true,
            color: '#6A0300',
            opacity: 0.6,
        },
    };
};
const usePPREarthquakeHazardLayer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.PPR_EARTHQUAKE_HAZARD],
        getLegends,
    })(filtersKey.PPR_EARTHQUAKE_HAZARD);
};
const PPREarthquakeHazardLayer = () => {
    usePPREarthquakeHazardLayer();
    return null;
};
export default PPREarthquakeHazardLayer;
