import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { usePrevious } from '@chakra-ui/react';
import { createContext } from 'use-context-selector';
import { PLOT_SELECTION_FEASIBILITY, PLOT_SELECTION_PROJECT, } from 'context/LocationContext';
import { useToggleFilter } from 'hooks/contexts/useFiltersContext';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { useSetSecondaryPlotCoord } from 'hooks/contexts/useSecondaryPlotsContext';
import { useToggleLayerTab } from 'hooks/contexts/useTabsContext';
import usePlotsSearchResults from 'hooks/plotsSearch/usePlotsSearchResults';
import useCustomToast from 'hooks/useCustomToast';
import { filtersKey } from 'utils/filters';
import { getSwktCenter } from 'utils/map';
import { PLOTS_SEARCH_DEFAUT_FORM_VALUES, } from 'utils/plotsSearch';
export const SEARCH_MODE = 'search';
export const FEASIBILITY_MODE = 'feasibility';
export const PROJECT_MODE = 'project';
export const PlotSearchContext = createContext(null);
export const PlotSearchProvider = ({ children }) => {
    const { t } = useTranslation();
    const currentSearchTownId = useRef(null);
    const selectedTown = useSelectedTown();
    const [currentTown, setCurrentTown] = useState(null);
    const { getInfoToast, getSuccessToast } = useCustomToast();
    const setSecondaryPlotCoord = useSetSecondaryPlotCoord();
    const toggleLayerTab = useToggleLayerTab();
    const toggleFilter = useToggleFilter();
    const navigate = useNavigate();
    const [plotSearchParams, setPlotSearchParams] = useState({
        mode: SEARCH_MODE,
        values: PLOTS_SEARCH_DEFAUT_FORM_VALUES,
    });
    useEffect(() => {
        setCurrentTown(selectedTown);
    }, [plotSearchParams]);
    const { formattedResults: searchResults, isFetching, getPlotsIds, searchResultsCount, isInitialLoading: isLoading, isNewResult, setIsNewResult, } = usePlotsSearchResults(plotSearchParams?.values, currentTown);
    const previousIsSearchFetching = usePrevious(isFetching);
    const resultKeys = Object.keys(searchResults);
    const searchMode = plotSearchParams?.mode || SEARCH_MODE;
    useEffect(() => {
        if ((previousIsSearchFetching && !isFetching) || isNewResult) {
            if (!searchResults || resultKeys.length === 0) {
                getInfoToast({
                    title: t('header.search_plot.no_plot_found'),
                    position: searchMode !== SEARCH_MODE ? 'bottom' : 'top',
                    id: 'no_plots_found',
                });
                return;
            }
            // If we get only one search result, we open the plot and center the map on it
            if (resultKeys.length === 1) {
                const plot = Object.values(searchResults)[0];
                const center = getSwktCenter(plot.swkt);
                if (searchMode === SEARCH_MODE) {
                    navigate(`/parcelle/${selectedTown?.id}/${center?.lat().toString()}/${center
                        ?.lng()
                        .toString()}/${plot.id}`);
                }
                if (searchMode === PROJECT_MODE) {
                    setSecondaryPlotCoord({
                        mode: PLOT_SELECTION_PROJECT,
                        coord: center,
                        id: plot.id,
                        town: selectedTown,
                    });
                }
                if (searchMode === FEASIBILITY_MODE) {
                    setSecondaryPlotCoord({
                        mode: PLOT_SELECTION_FEASIBILITY,
                        coord: center,
                        id: plot.id,
                        town: selectedTown,
                    });
                }
            }
            if (resultKeys.length >= 2) {
                if (searchMode === SEARCH_MODE) {
                    toggleLayerTab({ tabId: filtersKey.PLOTS_SEARCH });
                    toggleFilter(filtersKey.PLOTS_SEARCH, true);
                    toggleFilter(filtersKey.PLOTS, false);
                }
                getSuccessToast({
                    title: searchMode === PROJECT_MODE
                        ? t('secondary_search.more_than_one_found', {
                            count: resultKeys.length,
                        })
                        : t('header.search_plot.more_than_one_found', {
                            count: resultKeys.length,
                        }),
                    position: searchMode !== SEARCH_MODE ? 'bottom' : 'top',
                    id: 'search_plots_found',
                });
            }
            setIsNewResult(false);
        }
    }, [isFetching, isNewResult]);
    return (<PlotSearchContext.Provider value={{
            plotSearchParams,
            setPlotSearchParams,
            currentSearchTownId,
            getPlotsIds,
            isFetching,
            searchResults,
            searchResultsCount,
            isLoading,
            isNewResult,
        }}>
      {children}
    </PlotSearchContext.Provider>);
};
export default PlotSearchContext;
