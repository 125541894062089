import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import qs from 'qs';
import { useCloseLayout, useSetLayoutHalf, } from 'hooks/contexts/useLayoutContext';
import useNavigateToTown from 'hooks/navigation/useNavigateToTown';
import { useFetchPlotData } from 'hooks/useFetchPlotData';
import useTownByCoordinates from 'hooks/useTownByCoordinates';
import { getFormattedAddressFromPlace, getPrecisionFromPlace, } from 'utils/address';
import { FROM_GOOGLE } from 'utils/constants';
// Manage map and plot opening from google search results
const useLocationSearch = () => {
    const [place, setPlace] = useState();
    const navigate = useNavigate();
    const navigateToTown = useNavigateToTown();
    const precision = useMemo(() => getPrecisionFromPlace(place), [place]);
    const setLayoutHalf = useSetLayoutHalf();
    const closeLayout = useCloseLayout();
    const locateTown = () => {
        const lat = place?.geometry?.location.lat().toString();
        const lng = place?.geometry?.location.lng().toString();
        navigateToTown({
            lat,
            lng,
            centerMapOnLat: lat,
            centerMapOnLng: lng,
            precision,
            addTownMarker: true,
            formattedAddress: getFormattedAddressFromPlace(place),
        });
        closeLayout();
    };
    const { getPlotData, isLoading: isLoadingPlot, plotData, plotFetchError, } = useFetchPlotData({ from: FROM_GOOGLE });
    useEffect(() => {
        if (plotData) {
            setLayoutHalf();
            navigate({
                pathname: `/parcelle/${plotData?.townId}/${plotData?.lat}/${plotData.lng}/${plotData?.id}`,
                search: qs.stringify({
                    formattedAddress: getFormattedAddressFromPlace(place),
                    precision: getPrecisionFromPlace(place),
                }),
            });
        }
        if (!!plotFetchError && !!townData) {
            // No plot found for provided address then just locate address on map
            locateTown();
        }
        setPlace(undefined);
    }, [plotData, plotFetchError]);
    const { isInitialLoading: isLoadingTown, data: townData, error: townError, } = useTownByCoordinates({
        coordinates: place?.geometry?.location,
        enabled: !!place,
    });
    useEffect(() => {
        if (townData && !townError) {
            // Street number precision might be linked to a plot we need to open
            if (precision === 3) {
                getPlotData({
                    coordinates: place?.geometry?.location,
                    townId: townData?.id,
                });
                return;
            }
            locateTown();
        }
    }, [townData]);
    const locatePlace = (place) => {
        // We first need to find city id then fetch plot data to open plot panel
        setPlace(place);
    };
    return {
        locatePlace,
        isLocating: isLoadingPlot || isLoadingTown,
    };
};
export default useLocationSearch;
