import { initReactI18next } from 'react-i18next';
import * as i18n from 'i18next';
import * as translationEn from './locales/en/translation.json';
import * as translationFr from './locales/fr/translation.json';
export const resources = {
    en: {
        translation: translationEn,
    },
    fr: {
        translation: translationFr,
    },
};
i18n.use(initReactI18next).init({
    resources,
    lng: 'fr',
    fallbackLng: 'fr',
    returnNull: false,
});
export default i18n;
