import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';
import * as iots from 'io-ts';
import { get } from 'api/client';
import InfoWindowTable from 'components/infoWindow/InfoWindowTable';
import createLayersHook from 'hooks/layers/createLayersHook';
import wastelandNoProject from 'images/wasteland/no_project.png';
import wastelandPotential from 'images/wasteland/potential.png';
import wastelandProject from 'images/wasteland/project.png';
import wastelandReconverted from 'images/wasteland/reconverted.png';
import wastelandSolar from 'images/wasteland/solar.png';
import { IOMarkerLayerItem, IOPolygonLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
const IOWastelandLayerItem = iots.intersection([
    IOPolygonLayerItem,
    IOMarkerLayerItem,
    iots.type({
        wastelandType: iots.union([
            iots.literal('project'),
            iots.literal('no_project'),
            iots.literal('solar'),
            iots.literal('potential'),
            iots.literal('reconverted'),
            iots.null,
        ]),
        address: iots.union([iots.string, iots.null]),
        name: iots.string,
        url: iots.union([iots.string, iots.null]),
        status: iots.union([iots.string, iots.null]),
        occupation: iots.union([iots.string, iots.null]),
        surface: iots.union([iots.number, iots.null]),
        project: iots.union([iots.string, iots.null]),
        projectUrl: iots.union([iots.string, iots.null]),
        projectEtude: iots.union([iots.string, iots.null]),
        activity: iots.union([iots.string, iots.null]),
        activityDateEnd: iots.union([iots.string, iots.null]),
        buildingType: iots.union([iots.string, iots.null]),
        buildingPollution: iots.union([iots.string, iots.null]),
        buildingVacant: iots.union([iots.string, iots.null]),
        buildingState: iots.union([iots.string, iots.null]),
        building_date: iots.union([iots.string, iots.null]),
        ownerType: iots.union([iots.string, iots.null]),
        ownerName: iots.union([iots.string, iots.null]),
        groundPollution: iots.union([iots.string, iots.null]),
    }),
]);
const getInfoWindowContent = (t) => (item) => {
    let fields = [
        { title: t('info_window.wasteland.name'), data: item.name, bold: true },
        { title: t('info_window.wasteland.status'), data: item.status },
        { title: t('info_window.wasteland.address'), data: item.address },
    ];
    if (item.url) {
        fields.push({
            title: t('info_window.wasteland.url'),
            data: (<a target="_blank" rel="noreferrer" href={item.url}>
            {t('info_window.wasteland.url_label')}
          </a>),
        });
    }
    fields = [
        ...fields,
        {
            title: t('info_window.wasteland.type'),
            data: t('info_window.wasteland.type_' + item.wastelandType),
        },
        {
            title: t('info_window.wasteland.surface'),
            data: `${item.surface} m²`,
            bold: true,
        },
        { title: t('info_window.wasteland.project'), data: item.project },
        {
            title: t('info_window.wasteland.project_etude'),
            data: item.projectEtude,
        },
    ];
    if (item.projectUrl) {
        fields.push({
            title: t('info_window.wasteland.project_url'),
            data: (<a target="_blank" rel="noreferrer" href={item.projectUrl}>
            {t('info_window.wasteland.project_url_label')}
          </a>),
        });
    }
    fields = [
        ...fields,
        {
            title: t('info_window.wasteland.activity'),
            data: item.activity,
            bold: true,
        },
        {
            title: t('info_window.wasteland.activity_date_end'),
            data: item.activityDateEnd,
        },
        {
            title: t('info_window.wasteland.building_type'),
            data: item.buildingType,
        },
        {
            title: t('info_window.wasteland.building_pollution'),
            data: item.buildingPollution,
        },
        {
            title: t('info_window.wasteland.building_vacant'),
            data: item.buildingVacant,
        },
        {
            title: t('info_window.wasteland.building_state'),
            data: item.buildingState,
        },
        {
            title: t('info_window.wasteland.building_date'),
            data: item.building_date,
        },
        {
            title: t('info_window.wasteland.owner_type'),
            data: item.ownerType,
        },
        {
            title: t('info_window.wasteland.owner_name'),
            data: item.ownerName,
            bold: true,
        },
        {
            title: t('info_window.wasteland.ground_pollution'),
            data: item.groundPollution,
        },
    ];
    const content = (<Box>
        <InfoWindowTable content={fields} labelTableCellProps={{ style: { width: '180px' } }} valueTableCellProps={{ style: { maxWidth: '480px' } }}/>
      </Box>);
    return ReactDOMServer.renderToStaticMarkup(content);
};
const IMAGES = {
    no_project: wastelandNoProject,
    potential: wastelandPotential,
    project: wastelandProject,
    reconverted: wastelandReconverted,
    solar: wastelandSolar,
};
const LEGENDS = {
    wasteland_potential: {
        layerKey: filtersKey.WASTELAND,
        label: 'wasteland_potential',
        needsTranslate: true,
        image: wastelandPotential,
    },
    wasteland_reconverted: {
        layerKey: filtersKey.WASTELAND,
        label: 'wasteland_reconverted',
        needsTranslate: true,
        image: wastelandReconverted,
    },
    wasteland_project: {
        layerKey: filtersKey.WASTELAND,
        label: 'wasteland_project',
        needsTranslate: true,
        image: wastelandProject,
    },
    wasteland_no_project: {
        layerKey: filtersKey.WASTELAND,
        label: 'wasteland_no_project',
        needsTranslate: true,
        image: wastelandNoProject,
    },
    wasteland_solar: {
        layerKey: filtersKey.WASTELAND,
        label: 'wasteland_solar',
        needsTranslate: true,
        image: wastelandSolar,
    },
};
const getMarkerImage = (item) => item.wastelandType ? IMAGES[item.wastelandType] : null;
const fetchWasteland = (townId) => () => get(iots.array(IOWastelandLayerItem), `${townId}/wasteland`);
const useWastelandLayer = () => {
    const { t } = useTranslation();
    createLayersHook({
        fetch: fetchWasteland,
        markerImg: getMarkerImage,
        legends: LEGENDS,
        getInfoWindowContent: getInfoWindowContent(t),
    })(filtersKey.WASTELAND);
};
const WastelandLayer = () => {
    useWastelandLayer();
    return null;
};
export default WastelandLayer;
