import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getLayerCoordTileUrl } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = () => (coord, zoom) => {
    return getLayerCoordTileUrl('PROTECTEDAREAS.MNHN.CONSERVATOIRES', 'normal', zoom, coord);
};
const getLegends = () => {
    const legends = {};
    legends.regional_natural_space_conservatoire = {
        layerKey: filtersKey.REGIONAL_NATURAL_SPACE_CONSERVATOIRE,
        label: 'regional_natural_space_conservatoire',
        needsTranslate: true,
        color: '#732600',
        opacity: 1,
    };
    return legends;
};
const useRegionalConservatoireLayer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.REGIONAL_NATURAL_SPACE_CONSERVATOIRE],
        getLegends,
    })(filtersKey.REGIONAL_NATURAL_SPACE_CONSERVATOIRE);
};
const RegionalNaturalSpaceConservatoireLayer = () => {
    useRegionalConservatoireLayer();
    return null;
};
export default RegionalNaturalSpaceConservatoireLayer;
