import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
export const IOPlotHistory = iots.type({
    date: iots.string,
    value: iots.string,
});
export const QUERY_KEY = 'plot-history';
const fetchPlotHistory = (plotId) => () => get(iots.array(IOPlotHistory), `legacy/${plotId}/history`);
const usePlotHistory = (plotId) => {
    return useQuery({
        queryKey: [QUERY_KEY, { plotId }],
        queryFn: fetchPlotHistory(plotId),
        enabled: !!plotId,
    });
};
export default usePlotHistory;
