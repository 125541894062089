export const getDomain = () => {
    const domain = window.location.hostname;
    if (domain === 'local.kelfoncier.com' ||
        domain === 'jolicode.kelfoncier.com') {
        return 'immo.kelfoncier.com';
    }
    if (domain === 'local-suisse.kelfoncier.com' ||
        domain === 'test.kelfoncier.com') {
        return 'suisse.kelfoncier.com';
    }
    return domain;
};
export const isSwissSite = () => {
    return getDomain() === 'suisse.kelfoncier.com';
};
export const isGermanySite = () => {
    return getDomain() === 'allemagne.kelfoncier.com';
};
