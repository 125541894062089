export const getTileBoundingBox = (tileCoords, zoom, map, tileWidth, tileHeight) => {
    const projection = map.getProjection();
    const bound = Math.pow(2, zoom);
    const top = projection.fromPointToLatLng(new google.maps.Point((tileCoords.x * tileWidth) / bound, (tileCoords.y * tileHeight) / bound));
    const bottom = projection.fromPointToLatLng(new google.maps.Point(((tileCoords.x + 1) * tileWidth) / bound, ((tileCoords.y + 1) * tileHeight) / bound));
    return bottom.lat() + ',' + top.lng() + ',' + top.lat() + ',' + bottom.lng();
};
const convertEPSG4326ToEPSG3857 = (coords) => {
    const x = (coords.lng() * 20037508.34) / 180;
    let y = Math.log(Math.tan(((90 + coords.lat()) * Math.PI) / 360)) / (Math.PI / 180);
    y = (y * 20037508.34) / 180;
    return new google.maps.Point(x, y);
};
const latLngToWorldCoordinates = (latLng, map) => {
    const projection = map.getProjection();
    return projection.fromLatLngToPoint(latLng);
};
const worldCoordinatesToPixelCoordinates = (worldCoordinates, zoom) => {
    const scale = Math.pow(2, zoom);
    return new google.maps.Point(worldCoordinates.x * scale, worldCoordinates.y * scale);
};
const pixelCoordinatesToTileValues = (pixelCoords, tileWidth, tileHeight) => {
    const tileCol = Math.floor(pixelCoords.x / tileWidth);
    const tileRow = Math.floor(pixelCoords.y / tileHeight);
    const i = Math.floor(pixelCoords.x % tileWidth);
    const j = Math.floor(pixelCoords.y % tileHeight);
    return { tileCol, tileRow, i, j };
};
export const latLngToPixelCoordinates = (latLng, map) => {
    const worldCoordinates = latLngToWorldCoordinates(latLng, map);
    return worldCoordinatesToPixelCoordinates(worldCoordinates, map.getZoom());
};
export const latLngToMapDivPosition = (latLng, map) => {
    const pixelCoordinates = latLngToPixelCoordinates(latLng, map);
    const topLeft = new google.maps.LatLng(map.getBounds().getNorthEast().lat(), map.getBounds().getSouthWest().lng());
    const topLeftPixelCoordinates = latLngToPixelCoordinates(topLeft, map);
    return new google.maps.Point(pixelCoordinates.x - topLeftPixelCoordinates.x, pixelCoordinates.y - topLeftPixelCoordinates.y);
};
export const latLngToTileValues = (latLng, map, tileWidth, tileHeight) => {
    const worldCoordinates = latLngToWorldCoordinates(latLng, map);
    const pixelCoordinates = worldCoordinatesToPixelCoordinates(worldCoordinates, map.getZoom());
    return pixelCoordinatesToTileValues(pixelCoordinates, tileWidth, tileHeight);
};
// Return the coordinates of a bounding box centered on coord
export const getBoundingBoxForLatLng = (coord, zoom, map, width, height) => {
    const projection = map.getProjection();
    const bound = Math.pow(2, zoom);
    const center = latLngToWorldCoordinates(coord, map);
    const topEPSG4326 = projection.fromPointToLatLng(new google.maps.Point(center.x - width / 2 / bound, center.y - height / 2 / bound));
    const bottomEPSG4326 = projection.fromPointToLatLng(new google.maps.Point(center.x + width / 2 / bound, center.y + height / 2 / bound));
    const bottom = convertEPSG4326ToEPSG3857(bottomEPSG4326);
    const top = convertEPSG4326ToEPSG3857(topEPSG4326);
    return top.x + ',' + bottom.y + ',' + bottom.x + ',' + top.y;
};
export const pointToLatLng = (point, map) => {
    const topRight = map
        .getProjection()
        .fromLatLngToPoint(map.getBounds().getNorthEast());
    const bottomLeft = map
        .getProjection()
        .fromLatLngToPoint(map.getBounds().getSouthWest());
    const scale = Math.pow(2, map.getZoom());
    const worldPoint = new google.maps.Point(point.x / scale + bottomLeft.x, point.y / scale + topRight.y);
    return map.getProjection().fromPointToLatLng(worldPoint);
};
