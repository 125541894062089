import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOSocialMixingLayerItem = IOLayerItem;
const fetchSocialMixing = (townId) => () => get(iots.array(IOSocialMixingLayerItem), `legacy/${townId}/social-mixing`);
const useSocialMixingLayer = () => {
    createLayersHook({
        fetch: fetchSocialMixing,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.SOCIAL_MIXING);
};
const SocialMixingLayer = () => {
    useSocialMixingLayer();
    return null;
};
export default SocialMixingLayer;
