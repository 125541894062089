import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { del, get, post } from 'api/client';
import useOnError from 'hooks/useOnError';
import { IOEmpty, IOLandPoint, IOLandPointActivities, IOLandPointPrevReports, IOLandPointProject, IOLandPointReport, IOLandPointReports, } from 'types/api';
import useCustomToast from './useCustomToast';
const QUERY_KEY = ['landPoints'];
export const PROJECT_QUERY_KEY = ['landPointProject'];
const REPORT_QUERY_KEY = ['landPointReport'];
const REPORTS_QUERY_KEY = ['landPointReports'];
export const ACTIVITIES_QUERY_KEY = ['landPointActivities'];
const fetchLandPoints = () => () => get(iots.array(IOLandPoint), 'landPoints');
const fetchLandPointProjects = (landPointId) => () => get(iots.array(IOLandPointProject), `landPoint/${landPointId}/projects`);
const fetchLandPointReport = (landPointId) => () => get(iots.union([IOLandPointReports, IOEmpty]), `landPoint/${landPointId}/projects/report`);
const fetchLandPointPrevReports = (landPointId) => () => get(iots.union([IOLandPointPrevReports, IOEmpty]), `landPoint/${landPointId}/project/reports/prev`);
const fetchLandPointActivities = (landPointId) => () => get(iots.union([IOLandPointActivities, IOEmpty]), `landPoint/${landPointId}/project/activities`);
const createLandPointRequest = (landPointCreation) => post(IOEmpty, `landPoint/create`, landPointCreation);
const deleteLandPointRequest = ({ landPointId }) => del(IOEmpty, `landPoint/${landPointId}/delete`);
const encloseLandPointRequest = ({ landPointId }) => post(IOEmpty, `landPoint/${landPointId}/enclose`);
const saveLandPointProjectReportRequest = ({ landPointId, projectId, comment, }) => post(IOLandPointReport, `landPoint/${landPointId}/project/${projectId}/report`, { comment });
const useLandPoint = ({ landPointId, toLoad, }) => {
    const { getSuccessToast } = useCustomToast();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const onErrorCallback = useOnError();
    const { data: landPoints, isInitialLoading: isLoading } = useQuery({
        queryKey: QUERY_KEY,
        queryFn: fetchLandPoints(),
        enabled: toLoad === 'list',
    });
    const { data: landPointProjects, isInitialLoading: isLoadingProjects, refetch: refetchProjects, isRefetching: isRefetchingProjects, } = useQuery({
        queryKey: [PROJECT_QUERY_KEY, landPointId],
        queryFn: fetchLandPointProjects(landPointId),
        enabled: !!landPointId && toLoad === 'projects',
    });
    const { data: landPointReports, isInitialLoading: isLoadingReports } = useQuery({
        queryKey: [REPORT_QUERY_KEY, landPointId],
        queryFn: fetchLandPointReport(landPointId),
        enabled: !!landPointId && toLoad === 'reports',
    });
    const { data: landPointPrevReports, isInitialLoading: isLoadingPrevReports } = useQuery({
        queryKey: [REPORTS_QUERY_KEY, landPointId],
        queryFn: fetchLandPointPrevReports(landPointId),
        enabled: !!landPointId && toLoad === 'prevReports',
    });
    const { data: landPointActivities, isInitialLoading: isLoadingActivities } = useQuery({
        queryKey: [ACTIVITIES_QUERY_KEY, landPointId],
        queryFn: fetchLandPointActivities(landPointId),
        enabled: !!landPointId && toLoad === 'activities',
    });
    const { mutate: createLandPoint, isPending: isCreating } = useMutation({
        mutationFn: createLandPointRequest,
        onError: (err) => onErrorCallback(err),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: QUERY_KEY });
            getSuccessToast({
                title: t('land_point.created'),
                id: 'land_point_created',
            });
        },
    });
    const { mutate: encloseLandPoint, isPending: isEnclosing } = useMutation({
        mutationFn: encloseLandPointRequest,
        onError: (err) => onErrorCallback(err),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: QUERY_KEY });
            getSuccessToast({
                title: t('land_point.closing'),
                id: 'land_point_closing',
            });
        },
    });
    const { mutate: deleteLandPoint, isPending: isDeleting } = useMutation({
        mutationFn: deleteLandPointRequest,
        onError: (err) => onErrorCallback(err),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: QUERY_KEY });
            getSuccessToast({
                title: t('land_point.deleting'),
                id: 'land_point_deleting',
            });
        },
    });
    const { mutate: saveLandPointProjectReport, isPending: isSavingReport } = useMutation({
        mutationFn: saveLandPointProjectReportRequest,
        onError: (err) => onErrorCallback(err),
        onSuccess: (_data) => {
            queryClient.setQueryData([REPORT_QUERY_KEY, landPointId], (oldData) => {
                oldData[_data.projectId] = _data;
                return oldData;
            });
            getSuccessToast({
                title: t('land_point.report.save'),
                id: 'land_point_save_report',
            });
        },
    });
    return {
        landPoints,
        isLoading,
        landPointProjects,
        isLoadingProjects,
        refetchProjects,
        isRefetchingProjects,
        landPointActivities,
        isLoadingActivities,
        createLandPoint,
        isCreating,
        encloseLandPoint,
        isEnclosing,
        deleteLandPoint,
        isDeleting,
        landPointReports,
        isLoadingReports,
        landPointPrevReports,
        isLoadingPrevReports,
        saveLandPointProjectReport,
        isSavingReport,
    };
};
export default useLandPoint;
