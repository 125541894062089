import { useCallback, useMemo, useState } from 'react';
import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { useExtraFilters } from 'hooks/contexts/useFiltersContext';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOMarkerLayerItem } from 'types/api';
import { filtersKey, LAST_SIX_MONTHS } from 'utils/filters';
import { formatNumberToLocale } from 'utils/formatNumber';
import { numberWithSpaces } from 'utils/text';
const IONewMarketPricesMarkerItem = iots.intersection([
    IOMarkerLayerItem,
    iots.type({
        year: iots.number,
        masterKey: iots.number,
        typeCode: iots.union([iots.null, iots.string]),
        median: iots.number,
        lastSixMonths: iots.boolean,
        image: iots.string,
        title: iots.string,
    }),
], 'IONewMarketPricesMarkerItem');
const IONewMarketProgramLotInfo = iots.type({
    price: iots.number,
    pricePerSquareMeter: iots.number,
    surface: iots.number,
    nbRooms: iots.string,
});
const IONewMarketProgramPriceInfo = iots.type({
    value: iots.union([iots.null, iots.string]),
    nbRooms: iots.string,
});
const IONewMarketProgramInfo = iots.type({
    address: iots.string,
    scheduledDelivery: iots.string,
    description: iots.string,
    additionalInfo: iots.string,
    price: iots.array(IONewMarketProgramPriceInfo),
    propertyDeveloper: iots.string,
    title: iots.string,
    goodType: iots.string,
    url: iots.string,
    lots: iots.array(IONewMarketProgramLotInfo),
});
const LABEL_WIDTH = 60;
const LABEL_LINE_HEIGHT = 18;
const MAX_DESCRIPTION_LENGTH = 800;
const fetchNewMarketProgramInfo = (marker) => {
    return get(IONewMarketProgramInfo, `legacy/new-market-programs-info/${marker.masterKey}`, {
        typeCode: marker.typeCode,
    });
};
const formatInfoWindowContent = (info, t, i18n) => {
    let description = info.description ?? '';
    if (description.length > MAX_DESCRIPTION_LENGTH) {
        description = description.substring(0, MAX_DESCRIPTION_LENGTH) + '…';
    }
    const content = (<Box maxWidth="1000px" maxHeight="400px" width="600px">
      <table style={{ width: '100%', fontSize: '12px' }}>
        <tr>
          <td>{t('info_window.new_market_programs.property_developer')}</td>
          <td colSpan={4}>{info.propertyDeveloper}</td>
        </tr>
        <tr>
          <td>{t('info_window.new_market_programs.title')}</td>
          <td colSpan={4}>{info.title}</td>
        </tr>
        <tr>
          <td>{t('info_window.new_market_programs.address')}</td>
          <td colSpan={4}>{info.address}</td>
        </tr>
        <tr>
          <td>{t('info_window.new_market_programs.good_type')}</td>
          <td colSpan={4}>{info.goodType}</td>
        </tr>
        <tr>
          <td>{t('info_window.new_market_programs.scheduled_delivery')}</td>
          <td colSpan={4}>{info.scheduledDelivery}</td>
        </tr>
        <tr>
          <td style={{
            width: '130px',
            verticalAlign: 'top',
        }}>
            {t('info_window.new_market_programs.description')}
          </td>
          <td colSpan={4}>{description}</td>
        </tr>

        {!!info.price && info.price.length > 0 && (<>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            {info.price.map((price, index) => {
                const value = price.value ? `${price.value}€` : '';
                let nbRooms = '';
                if (price.nbRooms) {
                    if (price.value.includes('usq') || price.value.includes('de')) {
                        nbRooms =
                            price.nbRooms.length === 1
                                ? `${price.nbRooms} ${t('info_window.new_market_programs.price.nb_rooms')}`
                                : price.nbRooms;
                    }
                    else {
                        nbRooms =
                            price.nbRooms.length === 1
                                ? `${price.nbRooms} ${t('info_window.new_market_programs.price.nb_rooms')}`
                                : price.nbRooms;
                        nbRooms = `${nbRooms} ${t('info_window.new_market_programs.price.starting_from')}`;
                    }
                }
                return (<tr key={index}>
                  <td>{t('info_window.new_market_programs.price.title')}</td>
                  <td>{`${nbRooms} ${value}`}</td>
                </tr>);
            })}
          </>)}

        {!info.additionalInfo ? (info.url ? (<tr>
              <td style={{ verticalAlign: 'top' }}>
                {t('info_window.new_market_programs.url')}
              </td>
              <td>
                <a target="_blank" rel="noreferrer" href={info.url}>{`${info.url.substring(0, 30)}…`}</a>
              </td>
            </tr>) : null) : (<tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>)}

        {!!info.additionalInfo && (<>
            {info.lots.length > 0 && (<tr>
                <td>{t('info_window.new_market_programs.lots.title')}</td>

                <td style={{
                    width: '10%',
                    textAlign: 'center',
                }}>
                  {t('info_window.new_market_programs.lots.nb_rooms')}
                </td>

                <td style={{
                    width: '25%',
                    textAlign: 'center',
                }}>
                  {t('info_window.new_market_programs.lots.surface')}
                </td>

                <td style={{
                    width: '25%',
                    textAlign: 'center',
                }}>
                  {t('info_window.new_market_programs.lots.price')}
                </td>

                <td style={{
                    width: '25%',
                    textAlign: 'center',
                }}>
                  {t('info_window.new_market_programs.lots.price_per_square_meter')}
                </td>
              </tr>)}

            {info.lots.map((lot, index) => (<tr key={index}>
                <td>&nbsp;</td>
                <td style={{ textAlign: 'center' }}>{lot.nbRooms}</td>
                <td style={{ textAlign: 'center' }}>{lot.surface} m²</td>
                <td style={{ textAlign: 'center' }}>
                  {formatNumberToLocale(lot.price, i18n)} €
                </td>
                <td style={{ textAlign: 'center' }}>
                  {lot.pricePerSquareMeter === 0
                    ? '&nbsp;'
                    : `${formatNumberToLocale(lot.pricePerSquareMeter, i18n)} €`}
                </td>
              </tr>))}

            {info.url && (<tr>
                <td style={{
                    width: '130px',
                    verticalAlign: 'top',
                    paddingTop: '8px',
                }}>
                  {t('info_window.new_market_programs.more_info')}
                </td>
                <td colSpan={4} style={{
                    paddingTop: '8px',
                }}>
                  <a target="_blank" rel="noreferrer" href={info.url}>
                    {info.url.substring(0, 70)}
                    {info.url.length > 70 ? '…' : ''}
                  </a>
                </td>
              </tr>)}
          </>)}
      </table>
    </Box>);
    return ReactDOMServer.renderToStaticMarkup(content);
};
const getMarkerImg = (marker) => {
    try {
        return require(`../../images/programIcons/${marker.image}`);
    }
    catch (e) {
        if (e instanceof Error) {
            //eslint-disable-next-line
            console.error("Can't load ", marker.image);
        }
        else
            throw e;
    }
};
const getMarkerLabel = (marker) => {
    if (marker.median === 0) {
        return null;
    }
    return {
        content: numberWithSpaces(marker.median) + ` €`,
        anchorX: LABEL_WIDTH / 3.5,
        anchorY: -LABEL_LINE_HEIGHT / 1.5,
    };
};
const fetchNewMarketPrices = (townId) => () => get(iots.array(IONewMarketPricesMarkerItem), `legacy/${townId}/new-market-prices`);
const useNewMarketPricesLayer = () => {
    const { t, i18n } = useTranslation();
    const [markerInfo, setMarkerInfo] = useState({});
    const extraFilters = useExtraFilters();
    const selectedTown = useSelectedTown();
    const townId = selectedTown?.id;
    const getInfoWindowContent = useCallback(async (marker) => {
        const markerKey = `${marker.lat}/${marker.lng}`;
        if (markerInfo[markerKey]) {
            return markerInfo[markerKey];
        }
        const priceInfo = await fetchNewMarketProgramInfo(marker);
        const content = formatInfoWindowContent(priceInfo, t, i18n.language);
        setMarkerInfo({
            ...markerInfo,
            [markerKey]: content,
        });
        return content;
    }, []);
    const allowMarker = useCallback((marker) => {
        const timePeriod = extraFilters?.[filtersKey.NEW_MARKET_PRICES]?.timePeriod ?? null;
        if (!timePeriod) {
            return true;
        }
        if (timePeriod === LAST_SIX_MONTHS) {
            return marker.lastSixMonths === true;
        }
        return marker.year === timePeriod;
    }, [extraFilters]);
    const queryClient = useQueryClient();
    const data = queryClient.getQueryData([
        'layer',
        filtersKey.NEW_MARKET_PROGRAMS,
        townId,
    ]);
    const legends = useMemo(() => {
        const legendsData = {};
        (data ?? []).forEach((marker) => {
            if (!allowMarker(marker)) {
                return;
            }
            if (!legendsData[marker.image]) {
                legendsData[marker.image] = {
                    layerKey: filtersKey.NEW_MARKET_PROGRAMS,
                    label: marker.title,
                    image: getMarkerImg(marker),
                };
            }
        });
        return legendsData;
    }, [data]);
    createLayersHook({
        fetch: fetchNewMarketPrices,
        legends,
        markerImg: getMarkerImg,
        getInfoWindowContent,
        allowLayer: allowMarker,
        getMarkerLabel,
    })(filtersKey.NEW_MARKET_PROGRAMS);
};
const NewMarketPricesLayer = () => {
    useNewMarketPricesLayer();
    return null;
};
export default NewMarketPricesLayer;
