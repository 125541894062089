import * as React from 'react';
import { Box, Heading, Table, TableContainer, Tbody, Td, Tr, } from '@chakra-ui/react';
const InfoWindowTable = ({ title, content, labelTableCellProps = {}, valueTableCellProps = {}, }) => {
    return (<Box marginBottom="5px">
      {title && (<Heading style={{ fontSize: '14px', fontWeight: 'bold', paddingBottom: '5px' }}>
          {title}
        </Heading>)}
      <TableContainer>
        <Table size="sm" variant="simple">
          <Tbody>
            {content.map(({ title, data, align = 'left', bold = false }, index) => {
            if (data !== '' && data !== null) {
                return (<Tr key={index}>
                      <Td whiteSpace="normal" verticalAlign="top" style={{ paddingLeft: 0 }} {...labelTableCellProps}>
                        {title}&nbsp;:&nbsp;
                      </Td>
                      <Td whiteSpace="normal" textAlign={align} {...valueTableCellProps}>
                        <Box style={{ fontWeight: bold ? 'bold' : 'normal' }}>
                          {data}
                        </Box>
                      </Td>
                    </Tr>);
            }
            else {
                return '';
            }
        })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>);
};
export default InfoWindowTable;
