import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Flex, Stack } from '@chakra-ui/react';
import CreateProjectFromFeasibilityButton from 'components/buttons/CreateProjectFromFeasibilityButton';
import { getPlotSkeleton } from 'components/tabs/plots/PlotPannelSkeletons';
import AddPlotFromMap from 'components/tabs/utils/AddPlotFromMap';
import { TABS_VIEW_FULL, TABS_VIEW_HALF, TABS_VIEW_HALF_VERTICAL, } from 'context/LayoutContext';
import { PLOT_SELECTION_FEASIBILITY } from 'context/LocationContext';
import { useTabsView } from 'hooks/contexts/useLayoutContext';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { useCloseOneFeasibilityPlot, useGetFeasibilityAdditionalPlots, useIsLoadingFeasibilityAdditionalPlot, } from 'hooks/contexts/useSecondaryPlotsContext';
import usePlotFeasibility from 'hooks/plot/usePlotFeasibility';
import { SPACING_PANEL } from 'utils/constants';
import { getFeasibilityDefaultValues } from './calculator';
import PlotFeasibilityTable from './FeasibilityTable';
import { getFeasibilityWithCadastre } from './PlotFeasibility';
const PlotFeasibilityAdditional = ({ plot, }) => {
    const tabsView = useTabsView();
    const selectedTown = useSelectedTown();
    const closeOneFeasibilityPlot = useCloseOneFeasibilityPlot();
    const isLoadingFeasibilityPlot = useIsLoadingFeasibilityAdditionalPlot();
    const getFeasibilityPlots = useGetFeasibilityAdditionalPlots();
    const feasibilityPlots = getFeasibilityPlots(plot.id);
    const { plotFeasibilityAdditional, isLoadingPlotFeasibilityAdditional } = usePlotFeasibility(plot?.townId, plot?.id, !!selectedTown?.additionalPLU);
    const feasibility = useMemo(() => plotFeasibilityAdditional
        ? getFeasibilityWithCadastre(plotFeasibilityAdditional, plot)
        : null, [plotFeasibilityAdditional]);
    const methods = useForm();
    const { reset } = methods;
    useEffect(() => {
        if (feasibilityPlots) {
            const feasibilityWidthCadastre = getFeasibilityWithCadastre(plotFeasibilityAdditional, plot);
            reset(getFeasibilityDefaultValues({
                feasibility: feasibilityWidthCadastre,
                feasibilityPlots,
            }));
        }
    }, [feasibilityPlots]);
    const skeleton = getPlotSkeleton();
    if (isLoadingPlotFeasibilityAdditional)
        return skeleton;
    return (<Stack alignItems="start" margin={4} spacing={SPACING_PANEL} data-cy="plot_feasibility_table">
      <Stack alignItems="flex-start" direction={tabsView === TABS_VIEW_HALF_VERTICAL
            ? 'column-reverse'
            : { base: 'column-reverse', md: 'row' }} spacing={4} id="PlotFeasibility" width="100%">
        <Box width="100%">
          <FormProvider {...methods}>
            <form>
              <PlotFeasibilityTable feasibility={feasibility} feasibilityPlots={feasibilityPlots} isLoadingFeasibilityPlot={isLoadingFeasibilityPlot} closeOneFeasibilityPlot={closeOneFeasibilityPlot} hasFeasibility={selectedTown?.hasFeasibility}/>
            </form>
          </FormProvider>
        </Box>
        <Flex width="full" flexWrap="wrap" flexDir={tabsView === TABS_VIEW_HALF_VERTICAL ? 'row' : 'column'} bg={tabsView === TABS_VIEW_HALF_VERTICAL ? 'white' : 'inherit'} padding={4}>
          <AddPlotFromMap mode={PLOT_SELECTION_FEASIBILITY}/>
          <Box marginLeft={4} marginTop={tabsView === TABS_VIEW_HALF || tabsView === TABS_VIEW_FULL ? 3 : 0}>
            <CreateProjectFromFeasibilityButton townId={selectedTown?.id} plot={plot}/>
          </Box>
        </Flex>
      </Stack>
    </Stack>);
};
export default PlotFeasibilityAdditional;
