import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IONoiseAreaLayerItem = IOLayerItem;
const fetchNoiseArea = (townId) => () => get(iots.array(IONoiseAreaLayerItem), `legacy/${townId}/noise-area`);
const useNoiseAreaLayer = () => {
    createLayersHook({
        fetch: fetchNoiseArea,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.NOISE_AREA);
};
const NoiseAreaLayer = () => {
    useNoiseAreaLayer();
    return null;
};
export default NoiseAreaLayer;
