import * as React from 'react';
import useProject from 'hooks/crm/useProject';
import { PROJECT_STATUS_OPTIONS } from 'utils/constants';
import StatusSelect from './StatusSelect';
const ProjectStatusSelect = ({ townshipId, plotIds, projectId, status, onChange, isDisabled = false, }) => {
    const { updateProjectStatus, isUpdatingProjectStatus } = useProject();
    return (<StatusSelect data={PROJECT_STATUS_OPTIONS} onChange={(value) => {
            updateProjectStatus({
                townshipId,
                projectId,
                status: value.value,
                plotIds,
            });
            onChange && onChange();
        }} value={status} isLoading={isUpdatingProjectStatus} minWidth="180px" isDisabled={isDisabled}/>);
};
export default ProjectStatusSelect;
