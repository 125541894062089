import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Link } from '@chakra-ui/react';
import * as iots from 'io-ts';
import { formatDate } from 'utils/formatDate';
export const IODREALInfo = iots.type({
    id: iots.string,
    noticeDate: iots.string,
    company: iots.string,
    information: iots.string,
    sources: iots.string,
});
const DREALInfoWindow = ({ info }) => {
    const { t } = useTranslation();
    if (!info) {
        return null;
    }
    return (<Box maxHeight="400px">
      {info.noticeDate && (<Box>
          {t('info_window.dreal.noticeDate')} : {formatDate(info.noticeDate)}
        </Box>)}
      {info.company && (<Box>
          {t('info_window.dreal.company')} : <b>{info.company}</b>
        </Box>)}
      {info.information && (<Box>
          {t('info_window.dreal.information')} : {info.information}
        </Box>)}
      {info.sources && (<Box>
          <Link isExternal href={info.sources}>
            {t('info_window.dreal.source')}
          </Link>
        </Box>)}
    </Box>);
};
export default DREALInfoWindow;
