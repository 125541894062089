import * as React from 'react';
import { useCallback, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as iots from 'io-ts';
import { get } from 'api/client';
import BuildingPermitNewMarketInfoWindow, { IOBuildingPermitNewMarketInfo, } from 'components/infoWindow/BuildingPermitNewMarketInfoWindow';
import { useExtraFilters } from 'hooks/contexts/useFiltersContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import buildingPermitHousingAllowedPermit from 'images/building_permit_housing_allowed_permit.png';
import buildingPermitHousingCanceledPermit from 'images/building_permit_housing_canceled_permit.png';
import buildingPermitHousingWorkDone from 'images/building_permit_housing_work_done.png';
import buildingPermitHousingWorkStarted from 'images/building_permit_housing_work_started.png';
import buildingPermitOfficesAllowedPermit from 'images/building_permit_offices_allowed_permit.png';
import buildingPermitOfficesCanceledPermit from 'images/building_permit_offices_canceled_permit.png';
import buildingPermitOfficesWorkDone from 'images/building_permit_offices_work_done.png';
import buildingPermitOfficesWorkStarted from 'images/building_permit_offices_work_started.png';
import buildingPermitPlanningPermitsAllowedPermit from 'images/building_permit_planning_permits_allowed_permit.png';
import buildingPermitPlanningPermitsCanceledPermit from 'images/building_permit_planning_permits_canceled_permit.png';
import buildingPermitPlanningPermitsWorkDone from 'images/building_permit_planning_permits_work_done.png';
import buildingPermitPlanningPermitsWorkStarted from 'images/building_permit_planning_permits_work_started.png';
import { IOMarkerLayerItem } from 'types/api';
import { filtersKey, LAST_SIX_MONTHS } from 'utils/filters';
import { numberWithSpaces } from 'utils/text';
const IOBuildingPermitNewMarketMarkerItem = iots.intersection([
    IOMarkerLayerItem,
    iots.type({
        year: iots.number,
        lastSixMonths: iots.boolean,
        parentCategory: iots.string,
        price: iots.union([iots.string, iots.null]),
        computedPropertyTaxes: iots.union([iots.number, iots.null]),
        legacyId: iots.number,
        purpose: iots.union([iots.array(iots.number), iots.null]),
    }),
], 'IOBuildingPermitNewMarketMarkerItem');
const fetchBuildingPermitNewMarket = (townId) => () => get(iots.array(IOBuildingPermitNewMarketMarkerItem), `legacy/${townId}/building-permit-new-market`);
const fetchBuildingPermitNewMarketInfo = (item) => {
    return get(IOBuildingPermitNewMarketInfo, `legacy/building-permit-new-market-info/${item.parentCategory}/${item.legacyId}`);
};
const IMAGES = {
    [filtersKey.BUILDING_PERMIT_HOUSING_ALLOWED_PERMITS]: buildingPermitHousingAllowedPermit,
    [filtersKey.BUILDING_PERMIT_HOUSING_WORK_STARTED]: buildingPermitHousingWorkStarted,
    [filtersKey.BUILDING_PERMIT_HOUSING_WORK_DONE]: buildingPermitHousingWorkDone,
    [filtersKey.BUILDING_PERMIT_HOUSING_CANCELED_PERMITS]: buildingPermitHousingCanceledPermit,
    [filtersKey.BUILDING_PERMIT_OFFICES_ALLOWED_PERMITS]: buildingPermitOfficesAllowedPermit,
    [filtersKey.BUILDING_PERMIT_OFFICES_WORK_STARTED]: buildingPermitOfficesWorkStarted,
    [filtersKey.BUILDING_PERMIT_OFFICES_WORK_DONE]: buildingPermitOfficesWorkDone,
    [filtersKey.BUILDING_PERMIT_OFFICES_CANCELED_PERMITS]: buildingPermitOfficesCanceledPermit,
    [filtersKey.BUILDING_PERMIT_PLANNING_PERMITS_ALLOWED_PERMITS]: buildingPermitPlanningPermitsAllowedPermit,
    [filtersKey.BUILDING_PERMIT_PLANNING_PERMITS_WORK_STARTED]: buildingPermitPlanningPermitsWorkStarted,
    [filtersKey.BUILDING_PERMIT_PLANNING_PERMITS_WORK_DONE]: buildingPermitPlanningPermitsWorkDone,
    [filtersKey.BUILDING_PERMIT_PLANNING_PERMITS_CANCELED_PERMITS]: buildingPermitPlanningPermitsCanceledPermit,
};
export const BUILDING_PERMIT_NEW_MARKET_FILTERS = Object.keys(IMAGES);
const LABEL_WIDTH = 60;
const LABEL_WIDTH_WITH_TAXES = 75;
const LABEL_LINE_HEIGHT = 18;
const getMarkerImage = (marker) => {
    return IMAGES[marker.layerKey];
};
const getMarkerLabel = (item) => {
    if (item.price === null) {
        return null;
    }
    const label = (<>
      {item.computedPropertyTaxes && (<>
          <b>{item.computedPropertyTaxes} %</b>
          <br />
        </>)}
      {numberWithSpaces(item.price)} €
    </>);
    return {
        content: ReactDOMServer.renderToStaticMarkup(label),
        anchorX: (item.computedPropertyTaxes ? LABEL_WIDTH_WITH_TAXES : LABEL_WIDTH) / 3.5,
        anchorY: -LABEL_LINE_HEIGHT / 1.5,
        className: 'marker-label',
    };
};
const formatInfoWindowContent = (info) => {
    const content = <BuildingPermitNewMarketInfoWindow info={info}/>;
    let staticContent = ReactDOMServer.renderToStaticMarkup(content);
    staticContent = staticContent.replace('id="median-input"', 'id="median-input" onkeyup="updateBuildingPermitInfoWindowMedian()"');
    staticContent = staticContent.replace('id="sale-price-input"', 'id="sale-price-input" onkeyup="updateBuildingPermitInfoWindowSalePrice()"');
    staticContent = staticContent.replace('id="surface-input"', 'id="surface-input" onkeyup="updateBuildingPermitInfoWindowSurface()"');
    return staticContent;
};
const isTimePeriodAllowed = (marker, timePeriod) => {
    if (!timePeriod) {
        return true;
    }
    if (timePeriod === LAST_SIX_MONTHS) {
        return marker.lastSixMonths === true;
    }
    return marker.year === timePeriod;
};
const isPurposeAllowed = (marker, purpose) => {
    // Senior is 0
    if (purpose === null || purpose === '') {
        return true;
    }
    return marker.purpose && marker.purpose.includes(parseInt(purpose, 10));
};
const useBuildingPermitNewMarketLayers = () => {
    const extraFilters = useExtraFilters();
    const [markerInfo, setMarkerInfo] = useState({});
    const allowMarker = useCallback((marker, enabledFilters) => {
        if (marker.layerKey && !enabledFilters.includes(marker.layerKey)) {
            return false;
        }
        const timePeriod = extraFilters?.[marker.parentCategory]?.timePeriod ?? null;
        const purpose = extraFilters?.[marker.parentCategory]?.purpose ?? null;
        return (isTimePeriodAllowed(marker, timePeriod) &&
            isPurposeAllowed(marker, purpose));
    }, [extraFilters]);
    const getInfoWindowContent = async (marker) => {
        if (markerInfo[marker.id]) {
            return markerInfo[marker.id];
        }
        const buildingPermitInfo = await fetchBuildingPermitNewMarketInfo(marker);
        const content = formatInfoWindowContent(buildingPermitInfo);
        setMarkerInfo({
            ...markerInfo,
            [marker.id]: content,
        });
        return content;
    };
    createLayersHook({
        fetch: fetchBuildingPermitNewMarket,
        markerImg: getMarkerImage,
        getInfoWindowContent,
        allowLayer: allowMarker,
        getMarkerLabel,
    })(BUILDING_PERMIT_NEW_MARKET_FILTERS);
};
const BuildingPermitNewMarketLayers = () => {
    useBuildingPermitNewMarketLayers();
    return null;
};
export default BuildingPermitNewMarketLayers;
