import * as React from 'react';
import { Box } from '@chakra-ui/react';
import CourierList from 'components/couriers/CourierList';
import useCourier from 'hooks/courier/useCourier';
const CourierPanel = () => {
    const { couriers, isLoadingCouriers } = useCourier({ enabledList: true });
    return (<Box height="100%" paddingX={4} display="flex" flexDirection="column">
      <CourierList title="tabs.courier.title" couriers={couriers} isLoading={isLoadingCouriers} courierInfoDefault={{}} analyticsDetail="Envoyer un courrier via Mes courriers"/>
    </Box>);
};
export default CourierPanel;
