import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Text } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_HEADER } from 'analytics/useAnalytics';
import { useUserData } from 'hooks/contexts/useAuthContext';
import useCurrentSite from 'hooks/useCurrentSite';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
const Punchline = () => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    //const { userPunchline } = usePunchline()
    const { currentSite } = useCurrentSite();
    const userData = useUserData();
    if (!userData) {
        return null;
    }
    const { sites } = userData;
    const { hasFeature } = useSiteConfiguration();
    if (!hasFeature('old_site_link')) {
        return null;
    }
    // TODO temporary
    const getOldSiteUrl = () => {
        if (sites.length === 1 || !currentSite) {
            return null;
        }
        let oldSiteUrl = '';
        if (!currentSite.url.match('2.kelfoncier.com')) {
            oldSiteUrl = currentSite.url.replace('.kelfoncier.com', '-v4.kelfoncier.com');
        }
        else {
            oldSiteUrl = currentSite.url.replace('2.kelfoncier.com', '.kelfoncier.com');
        }
        const oldSite = sites.find((site) => site.url === oldSiteUrl);
        if (!oldSite) {
            return null;
        }
        return 'https://' + oldSite.url;
    };
    /*if (!userPunchline) {
      return null
    }*/
    return (<>
      {getOldSiteUrl() && (<Text textStyle="paragraph" marginRight={2}>
          {t('header.old_site.part_1')}
          <Link href={getOldSiteUrl()} variant="primary" isExternal onClick={() => {
                trackEvent({
                    category: ANALYTICS_HEADER,
                    action: 'Version antérieure',
                });
            }}>
            {t('header.old_site.link')}
          </Link>
          {t('header.old_site.part_2')}
        </Text>)}
      {/*userPunchline?.description && (
          <Text
            className="punchline"
            textStyle="paragraph"
            marginRight={2}
            dangerouslySetInnerHTML={{ __html: userPunchline.description }}
          />
        )*/}
    </>);
};
export default Punchline;
