import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getLayerCoordTileUrl } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = () => (coord, zoom) => {
    return getLayerCoordTileUrl('PROTECTEDAREAS.BIOS', 'PROTECTEDAREAS.BIOS', zoom, coord);
};
const getLegends = () => {
    const legends = {};
    legends.biosphere_reserve_central = {
        layerKey: filtersKey.BIOSPHERE_RESERVE,
        label: 'biosphere_reserve_central',
        needsTranslate: true,
        color: '#B96E50',
        opacity: 1,
    };
    legends.biosphere_reserve_tampon = {
        layerKey: filtersKey.BIOSPHERE_RESERVE,
        label: 'biosphere_reserve_tampon',
        needsTranslate: true,
        color: '#F5B964',
        opacity: 1,
    };
    legends.biosphere_reserve_transition = {
        layerKey: filtersKey.BIOSPHERE_RESERVE,
        label: 'biosphere_reserve_transition',
        needsTranslate: true,
        color: '#F5B964',
        opacity: 1,
    };
    return legends;
};
const useBiosphereReserveLayer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.BIOSPHERE_RESERVE],
        getLegends,
    })(filtersKey.BIOSPHERE_RESERVE);
};
const BiosphereReserveLayer = () => {
    useBiosphereReserveLayer();
    return null;
};
export default BiosphereReserveLayer;
