import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from '@chakra-ui/react';
const PinelSidebarContent = ({ selectedTown, }) => {
    const { t } = useTranslation();
    let colorTag;
    switch (selectedTown?.pinel) {
        case 'Abis':
        case 'A':
        case 'B1':
            colorTag = 'blue.500';
            break;
        case 'C':
        case 'B2':
            colorTag = 'gray.700';
            break;
        default:
            colorTag = 'gray.700';
            break;
    }
    return (<Tag borderRadius="full" backgroundColor={colorTag} color="white">{`${t('sidebar_content.pinel.zone')} : ${selectedTown?.pinel}`}</Tag>);
};
export default PinelSidebarContent;
