import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOShrinkageSwellingOfClaysLayerItem = IOLayerItem;
const fetchShrinkageSwellingOfClays = (townId) => () => get(iots.array(IOShrinkageSwellingOfClaysLayerItem), `legacy/${townId}/shrinkage-swelling-of-clays`);
const useShrinkageSwellingOfClaysLayer = () => {
    createLayersHook({
        fetch: fetchShrinkageSwellingOfClays,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.SHRINKAGE_SWELLING_OF_CLAYS);
};
const ShrinkageSwellingOfClaysLayer = () => {
    useShrinkageSwellingOfClaysLayer();
    return null;
};
export default ShrinkageSwellingOfClaysLayer;
