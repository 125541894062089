import { useEffect, useState } from 'react';
import { usePrevious } from '@chakra-ui/react';
import { useMap } from 'hooks/contexts/useMapContext';
export const CONTEXT_MENU_ID = 'context-menu';
export const useContextMenu = () => {
    // if the class is defined outside of the hook, it will blow up because it can't find google.maps
    class ContextMenuOverlay extends google.maps.OverlayView {
        latLng;
        onShow;
        div;
        constructor(latLng, onShow) {
            super();
            this.latLng = latLng;
            this.onShow = onShow;
        }
        onAdd() {
            this.div = document.createElement('div');
            this.div.id = CONTEXT_MENU_ID;
            this.div.style.borderStyle = 'none';
            this.div.style.borderWidth = '0px';
            this.div.style.position = 'absolute';
            this.getPanes().floatPane.appendChild(this.div);
            this.onShow();
        }
        draw() {
            const overlayProjection = this.getProjection();
            const leftRight = overlayProjection.fromLatLngToDivPixel(this.latLng);
            if (this.div) {
                this.div.style.left = leftRight.x + 'px';
                this.div.style.top = leftRight.y + 'px';
            }
        }
        onRemove() {
            if (this.div) {
                this.div.parentNode.removeChild(this.div);
                delete this.div;
            }
        }
    }
    const map = useMap();
    const [clickRightListener, setClickRightListener] = useState(null);
    const [clickListener, setClickListener] = useState(null);
    const previousClickListener = usePrevious(clickRightListener);
    const [contextMenu, setContextMenu] = useState(null);
    const [latLng, setLatLng] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const removeContextMenu = () => {
        if (contextMenu) {
            setIsOpen(false);
            setLatLng(null);
            contextMenu.setMap(null);
        }
    };
    const showContextMenu = (latLng) => {
        removeContextMenu();
        if (!map) {
            return;
        }
        setLatLng(latLng);
        const newContextMenu = new ContextMenuOverlay(latLng, () => setIsOpen(true));
        newContextMenu.setMap(map);
        setContextMenu(newContextMenu);
    };
    useEffect(() => {
        if (map) {
            if (null !== clickRightListener) {
                google.maps.event.removeListener(clickRightListener);
            }
            const newClickRightListener = map.addListener('rightclick', (event) => {
                showContextMenu(event.latLng);
            });
            if (null !== clickListener) {
                google.maps.event.removeListener(clickListener);
            }
            const newClickListener = map.addListener('click', () => {
                removeContextMenu();
            });
            setClickRightListener(newClickRightListener);
            setClickListener(newClickListener);
        }
    }, [map, contextMenu]);
    useEffect(() => {
        if (previousClickListener) {
            google.maps.event.removeListener(previousClickListener);
        }
    }, [clickListener]);
    useEffect(() => {
        return () => {
            if (clickListener) {
                google.maps.event.removeListener(clickListener);
            }
        };
    }, []);
    return { isOpen, latLng, close: removeContextMenu };
};
