import { useContextSelector } from 'use-context-selector';
import LayersTreeContext from 'context/LayersTreeContext';
export const useLayersTree = () => {
    return useContextSelector(LayersTreeContext, (state) => state.layersTree);
};
export const useMainLayers = () => {
    return useContextSelector(LayersTreeContext, (state) => state.mainLayers);
};
export const useSecondaryLayers = () => {
    return useContextSelector(LayersTreeContext, (state) => state.secondaryLayers);
};
export const useFilteredSecondaryLayers = () => {
    return useContextSelector(LayersTreeContext, (state) => state.filteredSecondaryLayers);
};
export const useIsLoadingLayersTree = () => {
    return useContextSelector(LayersTreeContext, (state) => state.isLoadingLayersTree);
};
export const useUpdateLayersTreeQueryData = () => {
    return useContextSelector(LayersTreeContext, (state) => state.updateLayersTreeQueryData);
};
export const useResetLayersTree = () => {
    return useContextSelector(LayersTreeContext, (state) => state.resetLayersTree);
};
export const useIsLoadingAdditionnalData = () => {
    return useContextSelector(LayersTreeContext, (state) => state.isLoadingAdditionalData);
};
