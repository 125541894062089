import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getLayerCoordTileUrl } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = () => (coord, zoom) => {
    return getLayerCoordTileUrl('PROTECTEDAREAS.MNHN.CDL.PARCELS', 'PROTECTEDAREAS.MNHN.CDL.PARCELS', zoom, coord);
};
const getLegends = () => {
    const legends = {};
    legends.coastline_conservatoire = {
        layerKey: filtersKey.COASTLINE_CONSERVATOIRE,
        label: 'coastline_conservatoire',
        needsTranslate: true,
        color: '#1B76FF',
        opacity: 1,
    };
    return legends;
};
const useCoastlineConservatoireLayer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.COASTLINE_CONSERVATOIRE],
        getLegends,
    })(filtersKey.COASTLINE_CONSERVATOIRE);
};
const CoastlineConservatoireLayer = () => {
    useCoastlineConservatoireLayer();
    return null;
};
export default CoastlineConservatoireLayer;
