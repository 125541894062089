import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useColorMode } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_MAP_BUTTONS, ANALYTICS_PLOT_TAB, } from 'analytics/useAnalytics';
import { CRM_LAYER_TABS } from 'context/TabsContext';
import { useFilters, useResetFilters } from 'hooks/contexts/useFiltersContext';
import { useInfoWindowListenersRef, useInitMap as useInitMapInContext, useInitPanorama, useMap, useMapCenter, usePanorama, usePrecision, useSetComputedMapType, useSetDarkMode, useSetRotateControl, useStreetViewMode, } from 'hooks/contexts/useMapContext';
import useNavigateToTown from 'hooks/navigation/useNavigateToTown';
import useClearSelectedTown from 'hooks/useClearSelectedTown';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { filtersKey } from 'utils/filters';
import { centerAndZoomOnCoord, changeMapDiv } from 'utils/map';
import { useFetchPlotSummary } from './contexts/useLegendContext';
import { useDrawingMode, useIsSecondarySelectionMode, usePlotSelectionMode, useSelectedTown, useSetClickedCoord, useTownError, } from './contexts/useLocationContext';
import { useResetMenu } from './contexts/useMenuContext';
import useCustomToast from './useCustomToast';
// filters required to enable click on map
const PLOT_SELECT_LAYERS = [filtersKey.PLOTS];
export const useInitMap = (refMap, refPanorama) => {
    const { getErrorToast } = useCustomToast();
    const { t } = useTranslation();
    const map = useMap();
    const panorama = usePanorama();
    const initMap = useInitMapInContext();
    const initPanorama = useInitPanorama();
    const setDarkMode = useSetDarkMode();
    const setRotateControl = useSetRotateControl();
    const setComputedMapType = useSetComputedMapType();
    const streetViewMode = useStreetViewMode();
    const mapCenter = useMapCenter();
    const precision = usePrecision();
    const resetFilters = useResetFilters();
    const filters = useFilters();
    const townError = useTownError();
    const setClickedCoord = useSetClickedCoord();
    const selectedTown = useSelectedTown();
    const plotSelectionMode = usePlotSelectionMode();
    const isSecondarySelectionMode = useIsSecondarySelectionMode();
    const drawingMode = useDrawingMode();
    const infoWindowListenersRef = useInfoWindowListenersRef();
    const { trackEvent } = useAnalytics();
    const navigateToTown = useNavigateToTown();
    const clearSelectedTown = useClearSelectedTown();
    const { colorMode } = useColorMode();
    const darkMode = colorMode === 'dark';
    const { hasMapControl } = useSiteConfiguration();
    const rotateControl = hasMapControl('display_oblique');
    const sv = new google.maps.StreetViewService();
    const fetchPlotSummary = useFetchPlotSummary();
    const canSelectPlot = useMemo(() => {
        return PLOT_SELECT_LAYERS.some((filterKey) => filters[filterKey]);
    }, [JSON.stringify(filters)]);
    const resetMenu = useResetMenu();
    const [clickedMapCoord, setClickedMapCoord] = useState(null);
    const clickListener = useRef(null);
    const handleClick = (latLng) => {
        if (drawingMode) {
            return;
        }
        if (streetViewMode) {
            sv.getPanorama({ location: latLng })
                .then(({ data }) => panorama.setPano(data.location.pano))
                .catch(() => getErrorToast({
                title: t('map.street_view.location_error'),
                description: process.env.NODE_ENV === 'development' ? status : undefined,
            }));
            return;
        }
        // Uniquement pour la carte de la France sur la page d'accueil
        if (!selectedTown) {
            navigateToTown({
                lat: latLng.lat().toString(),
                lng: latLng.lng().toString(),
                centerMapOnLat: latLng.lat().toString(),
                centerMapOnLng: latLng.lng().toString(),
                addTownMarker: true,
            });
            return;
        }
        if (canSelectPlot) {
            if (!isSecondarySelectionMode) {
                trackEvent({
                    category: ANALYTICS_PLOT_TAB,
                    action: 'Voir la parcelle - clique carte',
                });
                fetchPlotSummary(latLng);
                return;
            }
            setClickedCoord(latLng);
            if (!plotSelectionMode) {
                map.setCenter(latLng);
            }
        }
    };
    // map creation
    useEffect(() => {
        if (refMap.current && !map) {
            initMap(refMap, darkMode, rotateControl);
        }
    }, [refMap, map]);
    // refMap changed
    // usually happens when the map div is unmounted
    // for example when the user was disconnected
    useEffect(() => {
        if (!map || !refMap.current) {
            return;
        }
        changeMapDiv(map, refMap);
    }, [refMap]);
    // panorama creation
    useEffect(() => {
        if (refPanorama.current && map) {
            // we copy existing panorama
            const options = panorama
                ? {
                    pov: panorama.getPov(),
                    visible: panorama.getVisible(),
                }
                : {
                    imageDateControl: true,
                };
            initPanorama(refPanorama, map, options);
        }
    }, [refPanorama, map]);
    // map listeners
    useEffect(() => {
        if (map) {
            setComputedMapType(map.getMapTypeId());
            map.addListener('maptypeid_changed', () => {
                setComputedMapType(map.getMapTypeId());
            });
            if (!clickListener.current) {
                clickListener.current = map.addListener('click', (event) => {
                    setClickedMapCoord(event.latLng);
                });
            }
        }
    }, [map]);
    // Track map full screen mode
    document.onfullscreenchange = function () {
        if (map && document.fullscreenElement === map?.getDiv().firstChild) {
            trackEvent({
                category: ANALYTICS_MAP_BUTTONS,
                action: 'Plein écran Google Map',
            });
        }
    };
    useEffect(() => {
        if (clickedMapCoord) {
            handleClick(clickedMapCoord);
        }
    }, [clickedMapCoord]);
    useEffect(() => {
        if (map) {
            setDarkMode(darkMode);
        }
    }, [darkMode]);
    useEffect(() => {
        if (map) {
            setRotateControl(rotateControl);
        }
    }, [rotateControl]);
    useEffect(() => {
        if (townError) {
            if (townError?.status === 403) {
                centerAndZoomOnCoord(map, mapCenter.current, precision);
                resetMenu();
                resetFilters(CRM_LAYER_TABS);
            }
            else {
                clearSelectedTown();
            }
        }
    }, [townError]);
    useEffect(() => {
        Object.keys(infoWindowListenersRef.current).forEach((listenerName) => {
            if (!filters[listenerName]) {
                infoWindowListenersRef.current[listenerName].remove();
                delete infoWindowListenersRef.current[listenerName];
            }
        });
    }, [JSON.stringify(filters)]);
};
