import { PERSONALIZATION_KEY } from 'hooks/personalization/usePersonalization';
export const getPlotStatusLabel = (statusId) => {
    const personalization = JSON.parse(localStorage.getItem(PERSONALIZATION_KEY));
    return (personalization.plot.plotStatusValuesWithNone[statusId]?.label ?? 'Aucun');
};
export const hasPlotStatus = (statusId) => {
    const personalization = JSON.parse(localStorage.getItem(PERSONALIZATION_KEY));
    return !!personalization.plot.plotStatusValues[statusId];
};
export const getPlotStatusColor = (statusId) => {
    const personalization = JSON.parse(localStorage.getItem(PERSONALIZATION_KEY));
    return personalization.plot.plotStatusValues[statusId]?.color ?? '#DDDDDD';
};
export const getPlotStatusOption = () => {
    const personalization = JSON.parse(localStorage.getItem(PERSONALIZATION_KEY));
    return personalization.plot.plotStatusOptions.sort((a, b) => a.legendOrder > b.legendOrder ? 1 : -1);
};
export const getPlotStatusOrder = (statusId) => {
    const personalization = JSON.parse(localStorage.getItem(PERSONALIZATION_KEY));
    return personalization.plot.plotStatusValues[statusId]?.legendOrder ?? 0;
};
