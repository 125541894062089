import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getTileBoundingBox } from 'utils/geo';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = (map, tileWidth, tileHeight) => (coord, zoom) => {
    const boundingBox = getTileBoundingBox(coord, zoom, map, tileWidth, tileHeight);
    return ('https://geosas.fr' +
        '/geoserver/ows?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true' +
        '&LAYERS=mntsurf:mph_fr' +
        '&TILED=true&WIDTH=' +
        tileWidth +
        '&HEIGHT=' +
        tileHeight +
        '&CRS=EPSG:4326&STYLES=&BBOX=' +
        boundingBox);
};
const getLegends = () => {
    const legends = {};
    legends.wetland_area_potentially_enough_strong = {
        layerKey: filtersKey.WETLAND_AREA_POTENTIALLY,
        label: 'wetland_area_potentially_enough_strong',
        needsTranslate: true,
        color: '#00FFC5',
    };
    legends.wetland_area_potentially_strong = {
        layerKey: filtersKey.WETLAND_AREA_POTENTIALLY,
        label: 'wetland_area_potentially_strong',
        needsTranslate: true,
        color: '#0077FF',
    };
    legends.wetland_area_potentially_very_strong = {
        layerKey: filtersKey.WETLAND_AREA_POTENTIALLY,
        label: 'wetland_area_potentially_very_strong',
        needsTranslate: true,
        color: '#002673',
    };
    legends.wetland_area_potentially_water = {
        layerKey: filtersKey.WETLAND_AREA_POTENTIALLY,
        label: 'wetland_area_potentially_water',
        needsTranslate: true,
        color: '#FF73DF',
    };
    legends.wetland_area_potentially_estran = {
        layerKey: filtersKey.WETLAND_AREA_POTENTIALLY,
        label: 'wetland_area_potentially_estran',
        needsTranslate: true,
        color: '#FFEBBE',
    };
    return legends;
};
const useWetlandAreaPotentiallyLayers = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.WETLAND_AREA_POTENTIALLY],
        getLegends,
    })(filtersKey.WETLAND_AREA_POTENTIALLY);
};
const WetlandAreaPotentiallyLayers = () => {
    useWetlandAreaPotentiallyLayers();
    return null;
};
export default WetlandAreaPotentiallyLayers;
