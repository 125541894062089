import * as React from 'react';
import { Box, HStack, Skeleton, VStack } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import FiltersTreeWrapper from 'components/sidebar/FiltersSideBar/FiltersTreeWrapper';
import { useSecondaryLayers } from 'hooks/contexts/useLayersTreeContext';
import NoFiltersResults from './NoFiltersResults';
import OpenAllFiltersButton from './OpenAllFiltersButton';
const SecondaryLayerSkeleton = () => {
    return (<VStack spacing={2} alignItems="stretch" paddingX={4}>
      <Skeleton height="60px"/>
      <Skeleton width="80%" height="60px"/>
      {Array.from(Array(8).keys()).map((i) => (<HStack key={i} width="60%">
          <Skeleton width="20px" height="20px"/>
          <Skeleton flex={1} height="20px"/>
        </HStack>))}
      {Array.from(Array(5).keys()).map((i) => (<Skeleton key={i} height="60px"/>))}
    </VStack>);
};
const SecondaryLayersFilters = ({ isFilterSideBarLoading, }) => {
    const secondaryLayers = useSecondaryLayers();
    if (isFilterSideBarLoading && isEmpty(secondaryLayers))
        return <SecondaryLayerSkeleton />;
    return (<Box display="flex" flexDirection="column" width="full" overflow="hidden">
      <OpenAllFiltersButton />
      <NoFiltersResults />
      <Box overflowY="scroll">
        <FiltersTreeWrapper key={JSON.stringify(Object.keys(secondaryLayers))} tree={secondaryLayers}/>
      </Box>
    </Box>);
};
export default SecondaryLayersFilters;
