import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getBaseTileUrlHost3 } from 'utils/multiplexage';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = () => (coord, zoom) => {
    return (getBaseTileUrlHost3() +
        'cgi-bin/mm/mapserv.cgi?map=/var/data/public_maps/project_entrepot_logistique.map&LAYERS=entrepot_logistique&mode=tile&tilemode=gmap&tile=' +
        coord.x +
        '+' +
        coord.y +
        '+' +
        zoom +
        '&FORMAT=image%2Fpng&TRANSPARENT=true&TILED=true&CRS=EPSG:3857');
};
const getLegends = (enabledFilters) => {
    const legends = {};
    if (enabledFilters.includes(filtersKey.WAREHOUSES_AND_DENSE_LOGISTICS_AREAS)) {
        legends.dense_logistic_areas = {
            layerKey: filtersKey.WAREHOUSES_AND_DENSE_LOGISTICS_AREAS,
            label: 'dense_logistic_areas',
            needsTranslate: true,
            color: '#0000FF',
            opacity: 0.6,
        };
        legends.very_dense_logistic_areas = {
            layerKey: filtersKey.WAREHOUSES_AND_DENSE_LOGISTICS_AREAS,
            label: 'very_dense_logistic_areas',
            needsTranslate: true,
            color: '#FF0000',
            opacity: 0.6,
        };
    }
    return legends;
};
const useLogisticsBuildingsLayers = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.WAREHOUSES_AND_DENSE_LOGISTICS_AREAS],
        getLegends,
    })(filtersKey.WAREHOUSES_AND_DENSE_LOGISTICS_AREAS);
};
const LogisticsBuildingsLayers = () => {
    useLogisticsBuildingsLayers();
    return null;
};
export default LogisticsBuildingsLayers;
