import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { CST_POSITIONS } from 'utils/tilesPositions';
const LAYER_BASE_TILE_URL = process.env.BASE_TILE_URL_HOST_1 +
    'cgi-bin/mm/mapserv.cgi?map=/var/data/public_maps/project_termites.map&LAYERS=risque_termites&mode=tile&tilemode=gmap&tile=';
const getLayerTileUrl = () => (coord, zoom) => {
    return (LAYER_BASE_TILE_URL +
        coord.x +
        '+' +
        coord.y +
        '+' +
        zoom +
        '&FORMAT=image%2Fpng&TRANSPARENT=true&TILED=true&CRS=EPSG:3857');
};
const getLegends = (enabledFilters) => {
    const legends = {};
    if (enabledFilters.includes(filtersKey.TERMITES)) {
        legends.from_75_to_100 = {
            layerKey: filtersKey.TERMITES,
            label: 'De 75 à 100 %',
            needsTranslate: false,
            color: '#4F1200',
            opacity: 0.6,
        };
        legends.from_50_to_75 = {
            layerKey: filtersKey.TERMITES,
            label: 'De 50 à 75 %',
            needsTranslate: false,
            color: '#9E3F13',
            opacity: 0.6,
        };
        legends.from_25_to_50 = {
            layerKey: filtersKey.TERMITES,
            label: 'De 25 à 50 %',
            needsTranslate: false,
            color: '#E07000',
            opacity: 0.6,
        };
        legends.from_10_to_25 = {
            layerKey: filtersKey.TERMITES,
            label: 'De 10 à 25 %',
            needsTranslate: false,
            color: '#F5A331',
            opacity: 0.6,
        };
        legends.from_1_to_10 = {
            layerKey: filtersKey.TERMITES,
            label: 'De 1 à 10 %',
            needsTranslate: false,
            color: '#FCD66B',
            opacity: 0.6,
        };
        legends.localised_infestation = {
            layerKey: filtersKey.TERMITES,
            label: "Niveau d'infestation localisée",
            needsTranslate: false,
            color: '#FCF6BA',
            opacity: 0.6,
        };
        legends.unknown = {
            layerKey: filtersKey.TERMITES,
            label: "Niveau d'infestation inconnu",
            needsTranslate: false,
            color: '#FCF3E2',
            opacity: 0.6,
        };
    }
    return legends;
};
const useTermitesLayer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.TERMITES],
        getLegends,
    })(filtersKey.TERMITES);
};
const TermitesLayer = () => {
    useTermitesLayer();
    return null;
};
export default TermitesLayer;
