import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOPhotovoltaicNotProneAreaAocWineLayerItem = IOLayerItem;
const fetchPhotovoltaicNotProneAreaAocWine = (townId) => () => get(iots.array(IOPhotovoltaicNotProneAreaAocWineLayerItem), `legacy/${townId}/photovoltaic-not-prone-area-aoc-wine`);
const usePhotovoltaicNotProneAreaAocWineLayer = () => {
    createLayersHook({
        fetch: fetchPhotovoltaicNotProneAreaAocWine,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_AOC_WINE);
};
const PhotovoltaicNotProneAreaAocWineLayer = () => {
    usePhotovoltaicNotProneAreaAocWineLayer();
    return null;
};
export default PhotovoltaicNotProneAreaAocWineLayer;
