import * as React from 'react';
import { Input } from '@chakra-ui/react';
const ActivityTypeInput = ({ activity, updateActivity, isLoading, }) => {
    const handleChange = (newValue) => {
        {
            updateActivity({
                ...activity,
                title: newValue,
            });
        }
    };
    return (<Input type="text" size="sm" isDisabled={isLoading} defaultValue={activity?.title} onBlur={(e) => handleChange(e?.target?.value)}/>);
};
export default ActivityTypeInput;
