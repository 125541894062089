import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, FormControl, FormErrorMessage, FormLabel, Heading, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, Textarea, useDisclosure, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_MORE_INFO } from 'analytics/useAnalytics';
import { useUserData } from 'hooks/contexts/useAuthContext';
import useSendEmail from 'hooks/useSendEmail';
import { EMAIL_PATTERN } from 'utils/search';
const ContactModal = ({ triggerItem, type, title, handleSuccess, }) => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const userData = useUserData();
    const resetAndClose = () => {
        reset();
        onClose();
        !!handleSuccess && handleSuccess();
    };
    const { sendMessage, isSendingMessage } = useSendEmail({
        onSuccess: resetAndClose,
        onError: onClose,
    });
    const { register, handleSubmit, formState: { isSubmitting, errors }, reset, } = useForm({
        defaultValues: { email: userData?.email, type },
    });
    const send = (data) => {
        sendMessage(data);
        trackEvent({
            category: ANALYTICS_MORE_INFO,
            action: 'Envoyer une nouvelle vérification',
        });
    };
    return (<>
      {triggerItem(onOpen)}
      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered variant="wide">
        <ModalOverlay />
        <ModalContent paddingX={12} paddingY={7} alignItems="center">
          <Box as="form" onSubmit={handleSubmit((data) => send(data))} justifyContent="center" alignItems="center" display="flex" flexDirection="column" width="full">
            <Heading as="h4" variant="h4" textAlign="center" paddingY={3}>
              {title ?? t('contact_modal.title')}
            </Heading>
            <ModalCloseButton onClick={() => reset()}/>
            <ModalBody width="full">
              <FormControl isInvalid={!!errors?.email}>
                <FormLabel>{t('contact_modal.email')}</FormLabel>
                <Input {...register('email', {
        required: t('contact_modal.invalid_email'),
        pattern: {
            value: EMAIL_PATTERN,
            message: t('contact_modal.invalid_email'),
        },
    })} placeholder={t('contact_modal.placeholder_email')}/>
                <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
              </FormControl>
              <FormControl paddingTop={6}>
                <FormLabel>{t('contact_modal.message')}</FormLabel>
                <Textarea {...register('message')} placeholder={t('contact_modal.placeholder_message')}/>
              </FormControl>
            </ModalBody>

            <ModalFooter alignSelf="end">
              <Button variant="ghost" onClick={onClose} marginRight={2} disabled={isSendingMessage || isSendingMessage}>
                {t('contact_modal.cancel')}
              </Button>
              <Button isDisabled={isSubmitting} type="submit" variant="primary" isLoading={isSendingMessage}>
                {t('contact_modal.send')}
              </Button>
            </ModalFooter>
          </Box>
        </ModalContent>
      </Modal>
    </>);
};
export default ContactModal;
