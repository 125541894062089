import { useMemo } from 'react';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { useLayersTree } from 'hooks/contexts/useLayersTreeContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { getFilterNamesFromPathAndPrefix } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOAirborneNoiseLayerItem = IOLayerItem;
const LAYERS_PATH = 'regulatory.easements.airborne_noise';
const fetchAirborneNoise = (townId) => () => get(iots.array(IOAirborneNoiseLayerItem), `legacy/${townId}/airborne-noise`);
const useAirborneNoiseLayers = () => {
    const layersTree = useLayersTree();
    const filterNames = useMemo(() => {
        return getFilterNamesFromPathAndPrefix(layersTree, LAYERS_PATH);
    }, [JSON.stringify(layersTree)]);
    createLayersHook({
        fetch: fetchAirborneNoise,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filterNames);
};
const AirborneNoiseLayers = () => {
    useAirborneNoiseLayers();
    return null;
};
export default AirborneNoiseLayers;
