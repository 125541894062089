import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { del, get, patch, post } from 'api/client';
import useOnError from 'hooks/useOnError';
import { IOEmpty } from 'types/api';
import { getPlotProjectsQueryKey } from './usePlotProjectsList';
import { getProjectNotesQueryKey } from './useProjectNotes';
export const IOPlotNote = iots.type({
    id: iots.string,
    note: iots.string,
    date: iots.string,
});
export const QUERY_KEY = 'plot-notes';
export const getPlotNoteQueryKey = (plotId) => [QUERY_KEY, plotId];
const fetchPlotNotes = (plotId) => () => get(iots.array(IOPlotNote), `legacy/${plotId}/notes`);
const deletePlotNote = ({ noteId }) => del(IOEmpty, `legacy/note/${noteId}/delete`);
const addPlotNote = ({ id, note }) => post(iots.string, `legacy/plot/${id}/add-note`, { note });
const updatePlotNote = ({ plotId, noteId, note }) => patch(IOEmpty, `plot/${plotId}/note/${noteId}`, { note });
const usePlotNotes = (plotId) => {
    const queryClient = useQueryClient();
    const { data: notes, isInitialLoading } = useQuery({
        queryKey: getPlotNoteQueryKey(plotId),
        queryFn: fetchPlotNotes(plotId),
        enabled: !!plotId,
    });
    const onErrorCallback = useOnError();
    const onSuccessCallback = () => {
        queryClient.invalidateQueries({ queryKey: getPlotNoteQueryKey(plotId) });
        const plotProjects = queryClient.getQueryData(getPlotProjectsQueryKey(plotId));
        if (plotProjects) {
            // Invalidate project contain the plot
            plotProjects.forEach((project) => {
                queryClient.invalidateQueries({
                    queryKey: getProjectNotesQueryKey(project.id),
                });
            });
        }
    };
    const { mutate: addNote } = useMutation({
        mutationFn: addPlotNote,
        onError: (err) => onErrorCallback(err),
        onSuccess: onSuccessCallback,
    });
    const { mutate: deleteNote } = useMutation({
        mutationFn: deletePlotNote,
        onError: (err) => onErrorCallback(err),
        onSuccess: onSuccessCallback,
    });
    const { mutate: updateNote } = useMutation({
        mutationFn: updatePlotNote,
        onError: (err) => onErrorCallback(err),
        onSuccess: onSuccessCallback,
    });
    return { notes, isInitialLoading, deleteNote, addNote, updateNote };
};
export default usePlotNotes;
