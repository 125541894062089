import { useQuery } from '@tanstack/react-query';
import { get } from 'api/client';
const fetchFilters = (townshipId) => () => get(null, `${townshipId}/plots-search/filters`);
const usePlotSearchFilters = ({ townshipId }) => {
    const { data: filters, isInitialLoading: isLoading } = useQuery({
        queryKey: ['plots-search-filters', { townshipId }],
        queryFn: fetchFilters(townshipId),
        enabled: !!townshipId,
    });
    return {
        filters,
        isLoading,
    };
};
export default usePlotSearchFilters;
