import { useState } from 'react';
import { useMap } from 'hooks/contexts/useMapContext';
import CrossImg from 'images/cross.svg';
const useCenterCross = () => {
    const map = useMap();
    const [cross, setCross] = useState(null);
    const addCenterCross = () => {
        if (!map) {
            return;
        }
        if (cross) {
            cross.setMap(map);
            cross.setVisible(true);
            return;
        }
        const marker = new google.maps.Marker({
            map: map,
            icon: CrossImg,
        });
        marker.bindTo('position', map, 'center');
        setCross(marker);
    };
    const removeCenterCross = () => {
        if (!cross) {
            return;
        }
        cross.setMap(null);
        cross.setVisible(false);
        setCross(null);
    };
    return (visible = true) => {
        if (visible) {
            addCenterCross();
        }
        else {
            removeCenterCross();
        }
    };
};
export default useCenterCross;
