import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { TOWN_URL, useCurrentPath } from 'app';
import qs from 'qs';
import { FILTER_TAB_TYPE } from 'context/TabsContext';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { useDeleteTab } from 'hooks/contexts/useTabsContext';
import useChangeTown from 'hooks/useChangeTown';
import { filtersKey } from 'utils/filters';
const useTownOpener = () => {
    const { lat, lng } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const changeTown = useChangeTown();
    const selectedTown = useSelectedTown();
    const currentPath = useCurrentPath();
    const deleteTab = useDeleteTab();
    const { plotToSelectLat, plotToSelectLng, formattedAddress, precision, centerMapOnLat, centerMapOnLng, addTownMarker, } = qs.parse(searchParams.toString(), {
        ignoreQueryPrefix: true,
    });
    useEffect(() => {
        if (currentPath !== TOWN_URL) {
            return;
        }
        if (!lat || !lng) {
            return;
        }
        if (lat &&
            lng &&
            selectedTown &&
            selectedTown.lat === lat &&
            selectedTown.lng === lng) {
            // We don't want to change the town if it's already selected
            return;
        }
        const townCoordinates = lat && lng
            ? new google.maps.LatLng(parseFloat(lat), parseFloat(lng))
            : null;
        const plotToSelect = plotToSelectLat && plotToSelectLng
            ? new google.maps.LatLng(parseFloat(plotToSelectLat), parseFloat(plotToSelectLng))
            : null;
        const centerMapOn = centerMapOnLat && centerMapOnLng
            ? new google.maps.LatLng(parseFloat(centerMapOnLat), parseFloat(centerMapOnLng))
            : townCoordinates;
        // On ferme le résultat de la recherche avant de changer de ville pour éviter de relancer la recherche (avec les infos de la nouvelle ville)
        deleteTab({ id: filtersKey.PLOTS_SEARCH, type: FILTER_TAB_TYPE });
        changeTown({
            townCoordinates,
            plotToSelect,
            formattedAddress: formattedAddress,
            precision: precision ? parseInt(precision) : 1,
            centerMapOn,
            addTownMarker: !addTownMarker || addTownMarker !== 'false',
        });
        // If the formatted address is accurate enough to open a plot, and the search params are cleared,
        // the formatted address will be lost. So we need to keep the search params until the plot is opened.
        // They will then be removed in usePlotOpener.ts
        if (!plotToSelectLat && !plotToSelectLng) {
            setSearchParams({}, { replace: true });
        }
    }, [lat, lng, plotToSelectLat, plotToSelectLng]);
};
export default useTownOpener;
