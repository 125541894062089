import * as React from 'react';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'app';
import { t } from 'i18next';
import * as iots from 'io-ts';
import { post } from 'api/client';
import { CONTACT_COURIER_QUERY_KEY } from 'hooks/courier/useCourier';
import { CONTACT_QUERY_KEY } from 'hooks/crm/useContact';
import { QUERY_KEY as CONTACT_LIST_QUERY_KEY } from 'hooks/crm/useContactList';
import useCustomToast from 'hooks/useCustomToast';
const importContactRequest = (importFile) => {
    const formData = new FormData();
    formData.append('file', importFile.file[0], importFile.file[0]?.name);
    return post(null, `contact/import-client/file`, formData, null, false, {
        headers: { 'Content-Type': 'multipart/form-data;' },
    });
};
const finishImportContactRequest = (importContacts) => {
    return post(null, `contact/import-client/treatment`, importContacts, null, false);
};
export const IOContactReport = iots.type({
    lineImported: iots.number,
    lineIgnored: iots.number,
    contactCreated: iots.number,
    contactUpdated: iots.number,
    contactIds: iots.array(iots.string),
    errors: iots.array(iots.string),
});
const useImportContacts = () => {
    const { getSuccessToast } = useCustomToast();
    const { mutate: importContacts, isPending: isLoadingImport } = useMutation({ mutationFn: importContactRequest });
    const { mutate: finishImportContactsMutate, isPending: isLoading } = useMutation({
        mutationFn: finishImportContactRequest,
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: CONTACT_LIST_QUERY_KEY });
            data.contactIds.forEach((contactId) => {
                // Utile si on crée aussi des couriers
                queryClient.invalidateQueries({
                    queryKey: [CONTACT_COURIER_QUERY_KEY, contactId],
                });
                queryClient.invalidateQueries({
                    queryKey: [CONTACT_QUERY_KEY, contactId],
                });
            });
        },
    });
    const finishImportContacts = (importContacts, onSuccess) => {
        finishImportContactsMutate(importContacts, {
            onSuccess: (response) => {
                getSuccessToast({
                    title: (<>
              <p>
                {t('contact.imported_contacts_lines', {
                            count: response.lineImported,
                        })}
              </p>
              <p>
                {t('contact.ignored_contacts_lines', {
                            count: response.lineIgnored,
                        })}
              </p>
              <p>
                {t('contact.created_contacts_lines', {
                            count: response.contactCreated,
                        })}
              </p>
              <p>
                {t('contact.updated_contacts_lines', {
                            count: response.contactUpdated,
                        })}
              </p>
              {response.errors.map((error, index) => {
                            return <p key={index}>{error}</p>;
                        })}
            </>),
                    duration: response.errors.length > 0 ? 30000 : 10000,
                    id: 'imported_contacts',
                });
                onSuccess(response);
            },
        });
    };
    return {
        importContacts,
        isLoadingImport,
        finishImportContacts,
        isLoading,
    };
};
export default useImportContacts;
