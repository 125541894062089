import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IODocUrbaLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IORisingWaterTableAreaLayerItem = IODocUrbaLayerItem;
const fetchRisingWaterTableArea = (townId) => () => get(iots.array(IORisingWaterTableAreaLayerItem), `${townId}/rising-water-table-area`);
const useRisingWaterTableAreaLayer = () => {
    createLayersHook({
        fetch: fetchRisingWaterTableArea,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.RISING_WATER_TABLE_AREA);
};
const RisingWaterTableAreaLayer = () => {
    useRisingWaterTableAreaLayer();
    return null;
};
export default RisingWaterTableAreaLayer;
