import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Center, CircularProgress, Heading, HStack, Link, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, Spinner, Text, useColorModeValue, VStack, } from '@chakra-ui/react';
import ContactButton from 'components/buttons/ContactButton';
import CreateButton from 'components/buttons/CreateButton';
import DeleteButton from 'components/buttons/DeleteButton';
import EditButton from 'components/buttons/EditButton';
import PlotButton from 'components/buttons/PlotButton';
import ProjectButton from 'components/buttons/ProjectButton';
import ActivityStatusInput from 'components/tabs/activities/ActivityTable/ActivityStatusInput';
import useContactList from 'hooks/crm/useContactList';
import useUserProjectsList from 'hooks/crm/useUserProjectsList';
import { ACTIVITY_RECURRENCE_VALUES, AGENDA_ACTIVITY_TYPE_EVENT, AGENDA_ACTIVITY_TYPE_REMINDER, } from 'utils/constants';
const EventPopoverContent = ({ eventDetail, setEditedActivity, deleteActivity, updateActivity, isLoading, }) => {
    const activity = {
        ...eventDetail,
        id: eventDetail.activityId,
        title: eventDetail.activityTitle,
    };
    const { t } = useTranslation();
    const { getContactDetail, isInitialLoading: isLoadingContactList } = useContactList();
    const { agendaType, title, contactId, projectId, plotLat, plotLng, cadastreId, townId, comment, location, recurrence, } = eventDetail;
    const contact = getContactDetail(contactId);
    const { userProjects, isInitialLoading: isLoadingProjectList } = useUserProjectsList();
    const project = userProjects?.find((project) => {
        return project.id === projectId;
    });
    const { mobile, landline } = contact ?? {};
    const border = useColorModeValue('none', '1px solid white');
    if (isLoadingContactList) {
        return (<Center>
        <CircularProgress />
      </Center>);
    }
    return (<PopoverContent backgroundColor="colorMode.base" boxShadow="lg" border={border} _focus={{ boxShadow: 'var(--chakra-shadows-lg) !important' }}>
      <PopoverArrow backgroundColor="colorMode.base"/>
      <PopoverCloseButton />
      <PopoverBody padding={6}>
        <VStack alignItems="baseline">
          {title ? (<Heading as="h4" variant="h4">
              {title}
            </Heading>) : (<CreateButton size="sm" label={agendaType === AGENDA_ACTIVITY_TYPE_EVENT
                ? 'activity.actions.add_object'
                : 'activity.actions.add_title'} onClick={() => {
                setEditedActivity(activity);
            }}/>)}
          {mobile && (<Link variant="primary" textDecoration="underline" href={`tel:${mobile}`} padding={0}>
              {mobile}
            </Link>)}
          {landline && (<Link variant="primary" textDecoration="underline" href={`tel:${landline}`} padding={0}>
              {landline}
            </Link>)}
          {agendaType !== AGENDA_ACTIVITY_TYPE_REMINDER && (<Box>
              {comment ? (<Text textStyle="paragraph">{comment}</Text>) : (<CreateButton size="sm" label="activity.actions.add_comment" onClick={() => {
                    setEditedActivity(activity);
                }}/>)}
            </Box>)}

          {contactId && (<HStack>
              <Text textStyle="paragraph" fontWeight="bold">
                {t('activity.fields.common.contact_id.label')}
              </Text>
              <ContactButton contactId={contactId} padding={0} height="auto"/>
            </HStack>)}
          {cadastreId && (<HStack marginTop={2}>
              <Text textStyle="paragraph" fontWeight="bold">
                {t('activity.fields.common.cadastre_id.label')}
              </Text>
              <PlotButton lat={plotLat} lng={plotLng} townId={townId} variant="link-primary" padding={0} height="auto" plotId={eventDetail.plotId}>
                {cadastreId}
              </PlotButton>
            </HStack>)}

          {isLoadingProjectList ? (<Spinner />) : (projectId && (<HStack marginTop={2}>
                <Text textStyle="paragraph" fontWeight="bold">
                  {t('activity.fields.common.project.label')}
                </Text>
                <ProjectButton projectId={project.id} projectLabel={project.label} projectTownIds={[project.townId.toString()]} lat={project.lat} lng={project.lng}>
                  {project.label}
                </ProjectButton>
              </HStack>))}

          {agendaType === AGENDA_ACTIVITY_TYPE_REMINDER && (<HStack>
              <Text textStyle="paragraph" fontWeight="bold">
                {t('activity.fields.reminder.recurrence.label')}
              </Text>
              <Text textStyle="paragraph">
                {t(`activity.fields.reminder.recurrence.${ACTIVITY_RECURRENCE_VALUES[recurrence]}`)}
              </Text>
            </HStack>)}

          {agendaType === AGENDA_ACTIVITY_TYPE_EVENT && location && (<HStack>
              <Text textStyle="paragraph" fontWeight="bold">
                {t('activity.fields.event.location.label')}
              </Text>
              <Text textStyle="paragraph">{location}</Text>
            </HStack>)}

          {agendaType !== AGENDA_ACTIVITY_TYPE_EVENT && (<ActivityStatusInput activity={activity} isLoading={isLoading} updateActivity={updateActivity} label={t('activity.fields.common.delay.label')}/>)}
          <HStack paddingTop={4} justifyContent="space-between" width="100%">
            <EditButton size="sm" showIcon variant="outline" handleEdit={() => setEditedActivity(activity)} disabled={isLoading}/>
            <DeleteButton size="sm" showIcon handleDelete={() => deleteActivity({
            plotId: eventDetail.plotId,
            activityId: eventDetail.activityId,
        })} disabled={isLoading}/>
          </HStack>
        </VStack>
      </PopoverBody>
    </PopoverContent>);
};
export default EventPopoverContent;
