import * as React from 'react';
import CourierList from 'components/couriers/CourierList';
import useCourier from 'hooks/courier/useCourier';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { FROM_CONTACT } from 'utils/constants';
const getValuesContactForCourier = (contact) => {
    return {
        file: null,
        contactId: contact?.id,
        civility: contact?.titleStr,
        firstname: contact?.firstname,
        lastname: contact?.lastname,
        company: contact?.company ?? null,
        address: contact?.address,
        addressBP: contact?.addressBP,
        addressComplement: contact?.addressComplement,
        postalCode: contact?.postalCode,
        town: contact?.town,
        country: contact?.country,
    };
};
const CouriersSection = ({ contact }) => {
    const { hasDashboardItem } = useSiteConfiguration();
    const { contactCouriers, isLoadingContactCouriers } = useCourier({
        enabledList: hasDashboardItem('courier'),
        contactId: contact.id,
    });
    return (hasDashboardItem('courier') && (<CourierList title="tabs.contact.couriers" couriers={contactCouriers} isLoading={isLoadingContactCouriers} courierInfoDefault={getValuesContactForCourier(contact)} analyticsDetail="Envoyer un courrier via une fiche contact" withExport={false} fromPanel={FROM_CONTACT}/>));
};
export default CouriersSection;
