import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormControl, FormErrorMessage, FormLabel, Input, } from '@chakra-ui/react';
import NotRequired from './NotRequired';
const TextInput = ({ label, name, placeholder, disabled, required, isLoading, isOptional, ...rest }) => {
    const { register, formState: { errors }, watch, } = useFormContext();
    const error = errors[name];
    const { type } = rest;
    const watchFieldValue = watch(name);
    return (<FormControl width="full" isInvalid={Boolean(error)} isDisabled={disabled || isLoading}>
      {label && (<FormLabel>
          {label}
          {!!required && '*'}
          {!!isOptional && <NotRequired />}
        </FormLabel>)}
      <Input {...register(name, type === 'number' ? { valueAsNumber: true } : {})} placeholder={`${placeholder ?? ''} ${required ? '*' : ''}`} size="md" disabled={disabled} isInvalid={Boolean(error)} {...rest} value={watchFieldValue || ''}/>
      {Boolean(error) && <FormErrorMessage>{error?.message}</FormErrorMessage>}
    </FormControl>);
};
export default TextInput;
