import { useContextSelector } from 'use-context-selector';
import LocationContext from 'context/LocationContext';
export const useSelectedTown = () => {
    return useContextSelector(LocationContext, (state) => state.selectedTown);
};
export const useHandleSelectTown = () => {
    return useContextSelector(LocationContext, (state) => state.handleSelectTown);
};
export const useTowns = () => {
    return useContextSelector(LocationContext, (state) => state.towns);
};
export const useClickedCoord = () => {
    return useContextSelector(LocationContext, (state) => state.clickedCoord);
};
export const useIsLoadingTown = () => {
    return useContextSelector(LocationContext, (state) => state.isLoadingTown);
};
export const useResetLocation = () => {
    return useContextSelector(LocationContext, (state) => state.resetLocation);
};
export const useFormattedAddress = () => {
    return useContextSelector(LocationContext, (state) => state.formattedAddress);
};
export const useSetFormattedAddress = () => {
    return useContextSelector(LocationContext, (state) => state.setFormattedAddress);
};
export const useSetTownCoordinates = () => {
    return useContextSelector(LocationContext, (state) => state.setTownCoordinates);
};
export const useSetShouldAddTownToHistory = () => {
    return useContextSelector(LocationContext, (state) => state.setShouldAddTownToHistory);
};
export const useLastTownAddedToHistory = () => {
    return useContextSelector(LocationContext, (state) => [
        state.lastTownAddedToHistory,
        state.setLastTownAddedToHistory,
    ]);
};
export const usePlotSelectionMode = () => {
    return useContextSelector(LocationContext, (state) => state.plotSelectionMode);
};
export const useSetPlotSelectionMode = () => {
    return useContextSelector(LocationContext, (state) => state.setPlotSelectionMode);
};
export const useDisplayedPlot = () => {
    return useContextSelector(LocationContext, (state) => state.displayedPlot);
};
export const useSetDisplayedPlot = () => {
    return useContextSelector(LocationContext, (state) => state.setDisplayedPlot);
};
export const useLocatedProject = () => {
    return useContextSelector(LocationContext, (state) => state.locatedProject);
};
export const useSetLocatedProject = () => {
    return useContextSelector(LocationContext, (state) => state.setLocatedProject);
};
export const useIsLoadingPlotLayers = () => {
    return useContextSelector(LocationContext, (state) => state.isLoadingPlotLayers);
};
export const usePlotLayers = () => {
    return useContextSelector(LocationContext, (state) => state.layers);
};
export const useClearTown = () => {
    return useContextSelector(LocationContext, (state) => state.clearTown);
};
export const useLayers = () => {
    return useContextSelector(LocationContext, (state) => state.layers);
};
export const useSetClickedCoord = () => {
    return useContextSelector(LocationContext, (state) => state.setClickedCoord);
};
export const useTownError = () => {
    return useContextSelector(LocationContext, (state) => state.townError);
};
export const useResetPlotSelectionMode = () => {
    return useContextSelector(LocationContext, (state) => state.resetPlotSelectionMode);
};
export const useIsSecondarySelectionMode = () => {
    return useContextSelector(LocationContext, (state) => state.isSecondarySelectionMode);
};
export const useDrawingMode = () => {
    return useContextSelector(LocationContext, (state) => state.drawingMode);
};
export const useToggleDrawingMode = () => {
    return useContextSelector(LocationContext, (state) => state.toggleDrawingMode);
};
export const useActiveUserDrawing = () => {
    return useContextSelector(LocationContext, (state) => state.activeUserDrawing);
};
export const useSetActiveUserDrawing = () => {
    return useContextSelector(LocationContext, (state) => state.setActiveUserDrawing);
};
