import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getLayerCoordTileUrl } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = () => (coord, zoom) => {
    return getLayerCoordTileUrl('ENR.PERIMETRE.HABITATION', 'PERIMETRE.HABITATION', zoom, coord);
};
const getLegends = () => {
    const legends = {};
    legends.wind_constraint_housing = {
        layerKey: filtersKey.WIND_CONSTRAINT_HOUSING,
        label: 'wind_constraint_housing',
        needsTranslate: true,
        color: '#FCA98B',
    };
    return legends;
};
const useWindHousingLayer = () => {
    const options = {
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.WIND_CONSTRAINT_HOUSING],
        getLegends,
        opacity: 0.6,
    };
    createTilesLayerHook(options)(filtersKey.WIND_CONSTRAINT_HOUSING);
};
const WindHousingLayer = () => {
    useWindHousingLayer();
    return null;
};
export default WindHousingLayer;
