import * as React from 'react';
import { useCallback } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';
import * as iots from 'io-ts';
import mapValues from 'lodash/mapValues';
import snakeCase from 'lodash/snakeCase';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
const IOOldMarketBuildableLotsIdfLayerItem = iots.intersection([
    IOLayerItem,
    iots.type({
        pricePerSquareMeter: iots.number,
        nbSoldLots: iots.number,
        totalSoldLotsPrice: iots.string,
        totalSoldLotsSurface: iots.string,
    }),
]);
const fetchOldMarketBuildableLotsIdf = (townId) => () => get(iots.array(IOOldMarketBuildableLotsIdfLayerItem), `legacy/${townId}/old-market-buildable-lots-idf`);
const formatInfoWindowContent = (item, t) => {
    const content = (<Box maxWidth="1000px" maxHeight="400px" width="150px">
      <Box>
        <b>
          {t('info_window.old_market_buildable_lots_idf.price_per_square_meter')}{' '}
          {item.pricePerSquareMeter}&nbsp;€
        </b>
      </Box>
      <Box>
        {t('info_window.old_market_buildable_lots_idf.nb_sold_lots')}{' '}
        {item.nbSoldLots}
      </Box>
      <Box>
        {t('info_window.old_market_buildable_lots_idf.total_sold_lots_price')}{' '}
        {item.totalSoldLotsPrice}&nbsp;M&euro;
      </Box>
      <Box>
        {t('info_window.old_market_buildable_lots_idf.total_sold_lots_surface')}{' '}
        {item.totalSoldLotsSurface}&nbsp;
        {t('info_window.old_market_buildable_lots_idf.hectare')}
      </Box>
    </Box>);
    return ReactDOMServer.renderToStaticMarkup(content);
};
const LEGENDS_COLORS = {
    lessThan10: '#000080',
    from10to25: '#303070',
    from25to50: '#505050',
    from50to75: '#808040',
    from75to100: '#B0B030',
    from100to150: '#D0D010',
    from150to200: '#F0D000',
    from200to300: '#F0B000',
    from300to500: '#E08000',
    from500to750: '#D05000',
    from750to1000: '#D03000',
    from1000: '#C00000',
};
const LEGENDS = mapValues(LEGENDS_COLORS, (color, key) => ({
    color,
    layerKey: filtersKey.OLD_MARKET_BUILDABLE_LOTS_IDF,
    label: `old_market_buildable_lots_idf_prices.${snakeCase(key)}`,
    needsTranslate: true,
}));
const useOldMarketBuildableLotsIdfLayer = () => {
    const { t } = useTranslation();
    const getInfoWindowContent = useCallback((layerItem) => {
        return formatInfoWindowContent(layerItem, t);
    }, [t]);
    createLayersHook({
        fetch: fetchOldMarketBuildableLotsIdf,
        legends: LEGENDS,
        getInfoWindowContent,
    })(filtersKey.OLD_MARKET_BUILDABLE_LOTS_IDF);
};
const OldMarketBuildableLotsIdfLayer = () => {
    useOldMarketBuildableLotsIdfLayer();
    return null;
};
export default OldMarketBuildableLotsIdfLayer;
