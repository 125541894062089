import { useState } from 'react';
const useImportContactSteps = (goToNextStep) => {
    const [fileData, setFileData] = useState(null);
    const [selectedTab, setSelectedTab] = useState(null);
    const [importData, setImportData] = useState();
    const handleFileData = (data) => {
        setFileData(data);
        goToNextStep();
    };
    const handleSelectedTab = (data) => {
        setSelectedTab(data);
        goToNextStep();
    };
    const handleFieldsMapping = async (data) => {
        setImportData(data);
        goToNextStep();
    };
    return {
        handleFileData,
        handleSelectedTab,
        handleFieldsMapping,
        fileData,
        selectedTab,
        importData,
    };
};
export default useImportContactSteps;
