import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { post } from 'api/client';
import useCustomToast from './useCustomToast';
const IOUserPassword = iots.type({
    password: iots.string,
    passwordConfirm: iots.string,
});
const setUserPassword = ({ password, confirmPassword, oldPassword }) => post(iots.string, 'user/change-password', {
    password,
    confirmPassword,
    oldPassword,
});
export const validatePassword = (password) => {
    if (!password) {
        return 'reset_password.password_required';
    }
    if (!password.match(/[0-9]/g)) {
        return 'reset_password.password_invalid';
    }
    if (!password.match(/[A-Z]/g)) {
        return 'reset_password.password_invalid';
    }
    if (password.length < 8) {
        return 'reset_password.password_invalid';
    }
};
const useUserChangePassword = (successCallback = null) => {
    const { t } = useTranslation();
    const { getSuccessToast } = useCustomToast();
    const onSuccessCallback = useCallback(async () => {
        getSuccessToast({
            title: t('profile.success_change_password'),
            id: 'password_change_success',
        });
        successCallback();
    }, [t]);
    const { mutate: changePassword, isPending: isLoading } = useMutation({
        mutationFn: setUserPassword,
        onSuccess: () => onSuccessCallback(),
    });
    return {
        changePassword,
        isLoading,
    };
};
export default useUserChangePassword;
