import { useEffect } from 'react';
import { usePrevious } from '@chakra-ui/react';
export default (handler) => {
    const previousHandler = usePrevious(handler);
    useEffect(() => {
        window.addEventListener('click', handler);
        return () => {
            window.removeEventListener('click', handler);
        };
    }, []);
    useEffect(() => {
        window.removeEventListener('click', previousHandler);
        window.addEventListener('click', handler);
        return () => {
            window.removeEventListener('click', handler);
        };
    }, [handler]);
};
