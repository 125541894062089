import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { CRM_TABS } from 'context/TabsContext';
import { useIsTabOpened } from 'hooks/contexts/useTabsContext';
import { UserPlotType } from 'hooks/crm/useUserPlotsList';
import { IOPlotHistory } from './usePlotHistory';
export const IOTeamPlot = iots.type({
    ...UserPlotType,
    userEmail: iots.string,
    nbContact: iots.number,
});
export const IOTeamPlotModal = iots.type({
    id: iots.string,
    plotContactId: iots.string,
    status: iots.string,
    surface: iots.string,
    titleStr: iots.string,
    comment: iots.string,
    contactName: iots.string,
    createdAt: iots.string,
});
const IOTeamPlotContacts = iots.type({
    titleStr: iots.string,
    contactName: iots.string,
    status: iots.number,
    comment: iots.string,
    surface: iots.number,
    createdAt: iots.string,
});
const IOTeamPlotActivity = iots.type({
    date: iots.string,
    type: iots.string,
    plotId: iots.string,
});
const IOTeamPlotDocuments = iots.type({
    id: iots.string,
    name: iots.string,
    url: iots.string,
    type: iots.string,
    date: iots.string,
});
const IOTeamPlotNotes = iots.type({
    date: iots.string,
    id: iots.string,
    note: iots.string,
});
const IOTeamPlotDetail = iots.type({
    contacts: iots.array(IOTeamPlotContacts),
    activity: iots.array(IOTeamPlotActivity),
    status: iots.number,
    documents: iots.array(IOTeamPlotDocuments),
    notes: iots.array(IOTeamPlotNotes),
    history: iots.array(IOPlotHistory),
});
export const QUERY_KEY = ['team-plots'];
const fetchTeamPlotsList = () => get(iots.array(IOTeamPlot), 'legacy/team-plots');
const fetchTeamPlotDetail = (plotId, email) => () => get(IOTeamPlotDetail, `legacy/team-plot-detail/${plotId}/${email}`);
const useTeamPlots = (isEnabled = false, plotId, email) => {
    const isTabOpened = useIsTabOpened();
    const { data: teamPlots, isInitialLoading: isLoading, error, } = useQuery({
        queryKey: QUERY_KEY,
        queryFn: fetchTeamPlotsList,
        enabled: isTabOpened(CRM_TABS.TEAM_PLOTS),
    });
    const { data: teamPlotDetail, isInitialLoading: isLoadingTeamPlotDetail } = useQuery({
        queryKey: ['team-plot-detail', plotId, email],
        queryFn: fetchTeamPlotDetail(plotId, email),
        enabled: isEnabled && !!plotId && !!email,
    });
    return {
        teamPlots,
        isLoading,
        teamPlotDetail,
        isLoadingTeamPlotDetail,
        error,
    };
};
export default useTeamPlots;
