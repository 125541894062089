import { useQuery } from '@tanstack/react-query';
import { get } from 'api/client';
const fetchTeamStatistics = (month, year) => () => get(null, `legacy/team-statistics/${month}-${year}`);
const fetchTeamStatisticsUtilisation = (month, year) => () => get(null, `team-statistics-utilisation/${month}-${year}`);
const useTeamStatistics = (month, year) => {
    const { data: teamStatistics, isInitialLoading: isLoadingTeamStatistics, error: errorTeamStatistics, } = useQuery({
        queryKey: ['team-statistics', { month, year }],
        queryFn: fetchTeamStatistics(month, year),
        enabled: !!month && !!year,
    });
    const { data: teamStatisticsUtilisation, isInitialLoading: isLoadingTeamStatisticsUtilisation, error: errorTeamStatisticsUtilisation, } = useQuery({
        queryKey: ['team-statistics-utilisation', { month, year }],
        queryFn: fetchTeamStatisticsUtilisation(month, year),
        enabled: !!month && !!year,
    });
    return {
        teamStatistics,
        isLoadingTeamStatistics,
        errorTeamStatistics,
        teamStatisticsUtilisation,
        isLoadingTeamStatisticsUtilisation,
        errorTeamStatisticsUtilisation,
    };
};
export default useTeamStatistics;
