import * as React from 'react';
import { memo, useEffect, useState } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { Link, Stack, Text } from '@chakra-ui/react';
import { useIsFetching } from '@tanstack/react-query';
import { t } from 'i18next';
import { snakeCase } from 'lodash';
import moment from 'moment';
import { HOUSING_PURPOSE_OPTIONS, OFFICES_PURPOSE_OPTIONS, } from 'components/sidebarContent/BuildingPermitNewMarketSidebarContent';
import Table from 'components/table/Table';
import { FILTERS_BUILDING_PERMIT } from 'context/TabsContext';
import { useExtraFilters, useFilters } from 'hooks/contexts/useFiltersContext';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import useBuildingPermitList from 'hooks/useBuildingPermitList';
import { filtersKey, getTimePeriodOptions } from 'utils/filters';
import { formatDate } from 'utils/formatDate';
import { formatNumberToLocale } from 'utils/formatNumber';
import { getTownExportName } from 'utils/plots';
import { inDateRange } from 'utils/search';
import BuildingPermitPermitNumberButton from './BuildingPermitPermitNumberButton';
const getColumnHeader = (column) => (<Text align="center">
    <Translation>
      {(t) => t(`tabs.extra_tabs.building_permit_panel.columns.${column}`)}
    </Translation>
  </Text>);
const TOWN_NAME_COLUMN = {
    accessorKey: 'townName',
    header: () => getColumnHeader('town_name'),
    cell: (info) => info.getValue(),
    filterFn: 'arrIncludesSome',
};
const DATE_COLUMN = {
    accessorKey: 'date',
    header: () => getColumnHeader('date'),
    cell: ({ getValue }) => formatDate(getValue()),
    filterFn: inDateRange,
    enableGlobalFilter: false,
};
const STEP_COLUMN = {
    accessorKey: 'step',
    header: () => getColumnHeader('step'),
    cell: (info) => info.getValue(),
    filterFn: 'arrIncludesSome',
};
const NUMBER_HOUSING_CREATED_COLUMN = {
    accessorKey: 'numberHousingCreated',
    header: () => getColumnHeader('number_housing_created'),
    cell: ({ getValue }) => (<Translation>
      {(t, { lng }) => formatNumberToLocale(getValue(), lng)}
    </Translation>),
    filterFn: 'inNumberRange',
    enableGlobalFilter: false,
};
const AREA_CREATED_COLUMN = {
    accessorKey: 'areaCreated',
    header: () => getColumnHeader('area_created'),
    cell: ({ getValue }) => (<Translation>
      {(t, { lng }) => formatNumberToLocale(getValue(), lng)}
    </Translation>),
    filterFn: 'inNumberRange',
    enableGlobalFilter: false,
};
const APPLICANT_COLUMN = {
    accessorKey: 'applicant',
    header: () => getColumnHeader('applicant'),
    accessorFn: (row) => {
        if (!row.applicant)
            return t('table.empty_column');
        return row.applicant;
    },
    cell: ({ row, getValue }) => {
        const label = row.original.applicant;
        const siret = row.original.siretNumber?.toString();
        if (!label || !siret) {
            return null;
        }
        return (<Link variant="secondary" isExternal href={`https://www.societe.com/societe/xx-${siret.substring(0, 9)}.html`}>
        {getValue()}
      </Link>);
    },
    filterFn: 'arrIncludesSome',
    enableGlobalFilter: false,
};
const BUILDING_PERMIT_ID_COLUMN = {
    accessorKey: 'buildingPermitId',
    header: () => getColumnHeader('building_permit_id'),
    cell: ({ row }) => {
        return <BuildingPermitPermitNumberButton data={row.original}/>;
    },
    enableGlobalFilter: false,
    enableColumnFilter: false,
};
const PRICE_COLUMN = {
    accessorKey: 'price',
    header: () => getColumnHeader('price'),
    cell: ({ getValue }) => {
        return getValue() ? (<Translation>
        {(t, { lng }) => `${formatNumberToLocale(getValue(), lng)} €`}
      </Translation>) : ('');
    },
    enableGlobalFilter: false,
    enableColumnFilter: false,
};
const LAND_CHARGE_COLUMN = {
    accessorKey: 'landCharge',
    header: () => getColumnHeader('land_charge'),
    accessorFn: (row) => {
        if (!row.landCharge)
            return t('table.empty_column');
        return row.landCharge;
    },
    cell: ({ row }) => {
        return <Text textAlign="right">{row.original.landCharge}</Text>;
    },
    filterFn: 'arrIncludesSome',
};
const RESIDENCY_COLUMN = {
    id: 'residency',
    accessorFn: (row) => row?.residency?.length
        ? row?.residency.join(', ')
        : t('table.empty_column'),
    header: () => getColumnHeader('residency'),
    cell: ({ row }) => {
        const value = row?.original.residency?.length
            ? row?.original.residency.join(', ')
            : '';
        return (<>
        {value?.split(', ').map((residency, index) => {
                return (<Translation key={residency}>
              {(t) => {
                        return residency
                            ? `${t(`sidebar_content.building_permit_new_market.building_permit_housing.purpose.${residency}`)}${index !== value.split(', ')?.length - 1 ? ', ' : ''}`
                            : '';
                    }}
            </Translation>);
            })}
      </>);
    },
    filterFn: 'arrIncludesSome',
};
const OFFICE_COLUMN = {
    accessorKey: 'office',
    header: () => getColumnHeader('office'),
    cell: ({ getValue }) => (<Translation>
      {(t, { lng }) => formatNumberToLocale(getValue(), lng)}
    </Translation>),
    filterFn: 'inNumberRange',
    enableGlobalFilter: false,
};
const RETAIL_COLUMN = {
    accessorKey: 'retail',
    header: () => getColumnHeader('retail'),
    cell: ({ getValue }) => (<Translation>
      {(t, { lng }) => formatNumberToLocale(getValue(), lng)}
    </Translation>),
    filterFn: 'inNumberRange',
    enableGlobalFilter: false,
};
const OTHER_COLUMN = {
    accessorKey: 'other',
    header: () => getColumnHeader('other'),
    cell: ({ getValue }) => (<Translation>
      {(t, { lng }) => formatNumberToLocale(getValue(), lng)}
    </Translation>),
    filterFn: 'inNumberRange',
    enableGlobalFilter: false,
};
const PURPOSE_COLUMN = {
    id: 'purpose',
    accessorFn: (row) => row.purpose ? OFFICES_PURPOSE_OPTIONS[row.purpose] : '',
    header: () => getColumnHeader('purpose'),
    cell: (info) => {
        return (<Translation>
        {(t) => t(`sidebar_content.building_permit_new_market.building_permit_offices.purpose.${info.getValue()}`)}
      </Translation>);
    },
    filterFn: 'arrIncludesSome',
};
const AREA_COLUMN = {
    accessorKey: 'area',
    header: () => getColumnHeader('area'),
    cell: ({ getValue }) => (<Translation>
      {(t, { lng }) => formatNumberToLocale(getValue(), lng)}
    </Translation>),
    filterFn: 'inNumberRange',
    enableGlobalFilter: false,
};
const OPERATING_AREA_COLUMN = {
    accessorKey: 'operatingArea',
    header: () => getColumnHeader('operating_area'),
    cell: ({ getValue }) => (<Translation>
      {(t, { lng }) => formatNumberToLocale(getValue(), lng)}
    </Translation>),
    filterFn: 'inNumberRange',
    enableGlobalFilter: false,
};
const COLUMNS_BY_TYPE = {
    [filtersKey.BUILDING_PERMIT_HOUSING]: [
        TOWN_NAME_COLUMN,
        DATE_COLUMN,
        STEP_COLUMN,
        NUMBER_HOUSING_CREATED_COLUMN,
        AREA_CREATED_COLUMN,
        APPLICANT_COLUMN,
        BUILDING_PERMIT_ID_COLUMN,
        PRICE_COLUMN,
        LAND_CHARGE_COLUMN,
        RESIDENCY_COLUMN,
    ],
    [filtersKey.BUILDING_PERMIT_OFFICES]: [
        TOWN_NAME_COLUMN,
        DATE_COLUMN,
        STEP_COLUMN,
        OFFICE_COLUMN,
        RETAIL_COLUMN,
        OTHER_COLUMN,
        APPLICANT_COLUMN,
        BUILDING_PERMIT_ID_COLUMN,
        PURPOSE_COLUMN,
    ],
    [filtersKey.BUILDING_PERMIT_PLANNING_PERMIT]: [
        TOWN_NAME_COLUMN,
        DATE_COLUMN,
        STEP_COLUMN,
        AREA_COLUMN,
        OPERATING_AREA_COLUMN,
        APPLICANT_COLUMN,
        BUILDING_PERMIT_ID_COLUMN,
    ],
};
const getValueForFilterKey = (filterKey) => {
    if ([
        filtersKey.BUILDING_PERMIT_HOUSING_ALLOWED_PERMITS,
        filtersKey.BUILDING_PERMIT_OFFICES_ALLOWED_PERMITS,
        filtersKey.BUILDING_PERMIT_PLANNING_PERMITS_ALLOWED_PERMITS,
    ].includes(filterKey)) {
        return 'Autorisé';
    }
    if ([
        filtersKey.BUILDING_PERMIT_HOUSING_WORK_STARTED,
        filtersKey.BUILDING_PERMIT_OFFICES_WORK_STARTED,
        filtersKey.BUILDING_PERMIT_PLANNING_PERMITS_WORK_STARTED,
    ].includes(filterKey)) {
        return 'Travaux commencés';
    }
    if ([
        filtersKey.BUILDING_PERMIT_HOUSING_WORK_DONE,
        filtersKey.BUILDING_PERMIT_OFFICES_WORK_DONE,
        filtersKey.BUILDING_PERMIT_PLANNING_PERMITS_WORK_DONE,
    ].includes(filterKey)) {
        return 'Terminé';
    }
    if ([
        filtersKey.BUILDING_PERMIT_HOUSING_CANCELED_PERMITS,
        filtersKey.BUILDING_PERMIT_OFFICES_CANCELED_PERMITS,
        filtersKey.BUILDING_PERMIT_PLANNING_PERMITS_CANCELED_PERMITS,
    ].includes(filterKey)) {
        return 'Annulé';
    }
};
const columns = {};
Object.keys(COLUMNS_BY_TYPE).forEach((columnType) => {
    columns[columnType] = {
        id: `building-permit-${columnType}`,
        columns: COLUMNS_BY_TYPE[columnType],
    };
});
const EXPORTED_FIELDS = {
    [filtersKey.BUILDING_PERMIT_HOUSING]: [
        'townName',
        'date',
        'step',
        'numberHousingCreated',
        'areaCreated',
        'applicant',
        'buildingPermitId',
        'price',
        'landCharge',
        'residency',
    ],
    [filtersKey.BUILDING_PERMIT_OFFICES]: [
        'townName',
        'date',
        'step',
        'office',
        'retail',
        'other',
        'applicant',
        'buildingPermitId',
        'purpose',
    ],
    [filtersKey.BUILDING_PERMIT_PLANNING_PERMIT]: [
        'townName',
        'date',
        'step',
        'area',
        'operatingArea',
        'applicant',
        'buildingPermitId',
    ],
};
const formatExportHeader = (t) => (header) => t(`tabs.extra_tabs.building_permit_panel.columns.${snakeCase(header)}`);
const formatExportValue = (t) => (value, field, data) => {
    if (field === 'date') {
        return formatDate(value);
    }
    if (field === 'applicant' && data.siretNumber) {
        return `${value} - https://www.societe.com/societe/xx-${data.siretNumber
            .toString()
            .substring(0, 9)}.html`;
    }
    if (field === 'residency') {
        return value.map((residency) => t(`sidebar_content.building_permit_new_market.building_permit_housing.purpose.${residency}`));
    }
    if (field === 'purpose') {
        return t(`sidebar_content.building_permit_new_market.building_permit_offices.purpose.${OFFICES_PURPOSE_OPTIONS[value]}`);
    }
    return value;
};
// Get period range for filter from year value selected period
const getPeriodRangeFilterValue = (selectedPeriod) => {
    const timingPeriodOptions = getTimePeriodOptions();
    const isLast = selectedPeriod === timingPeriodOptions[timingPeriodOptions?.length - 1];
    if (isLast) {
        //return anything prior to 31st december of the last year
        return [undefined, `${selectedPeriod}-12-31`];
    }
    if (selectedPeriod === 'lastSixMonths') {
        const d = new Date();
        d.setMonth(d.getMonth() - 6);
        return [moment(d).format('YYYY-MM-DD'), undefined];
    }
    return [`${selectedPeriod}-01-01`, `${selectedPeriod}-12-31`];
};
const BuildingPermitPanel = ({ id }) => {
    const { t } = useTranslation();
    const filters = useFilters();
    const extraFilters = useExtraFilters();
    const selectedTown = useSelectedTown();
    const { data: buildingPermitList, isInitialLoading: isLoading } = useBuildingPermitList(selectedTown?.id);
    const isLoadingLayers = useIsFetching({ queryKey: ['legend'] }) > 0;
    const [columnFilters, setColumnFilters] = useState([]);
    useEffect(() => {
        const newFilters = [];
        let updatedColumnFilters = [...columnFilters];
        if (filters) {
            const filterValue = FILTERS_BUILDING_PERMIT[id]
                .filter((filterKey) => filters[filterKey] || false)
                .map((filter) => getValueForFilterKey(filter));
            updatedColumnFilters = updatedColumnFilters.filter((columnFilter) => columnFilter.id !== 'step');
            newFilters.push({ id: 'step', value: filterValue });
            updatedColumnFilters = updatedColumnFilters.filter((columnFilter) => columnFilter.id !== 'date');
            updatedColumnFilters = updatedColumnFilters.filter((columnFilter) => columnFilter.id !==
                (id === 'building_permit_housing' ? 'residency' : 'purpose'));
        }
        if (extraFilters) {
            if (extraFilters[id]?.timePeriod) {
                newFilters.push({
                    id: 'date',
                    value: getPeriodRangeFilterValue(extraFilters[id].timePeriod),
                });
            }
            if (extraFilters[id]?.purpose) {
                newFilters.push({
                    id: id === 'building_permit_housing' ? 'residency' : 'purpose',
                    value: id === 'building_permit_housing'
                        ? [HOUSING_PURPOSE_OPTIONS[extraFilters[id]?.purpose]]
                        : [OFFICES_PURPOSE_OPTIONS[extraFilters[id]?.purpose]],
                });
            }
        }
        if (newFilters?.length) {
            setColumnFilters([...updatedColumnFilters, ...newFilters]);
        }
    }, [JSON.stringify(filters), JSON.stringify(extraFilters)]);
    const townName = getTownExportName(selectedTown?.name);
    return (<Stack paddingX={4} height="100%">
      <Table id={`building-permit-list-${id}`} columns={[columns[id]]} data={buildingPermitList?.[id] || []} customColumnFilters={columnFilters} setCustomColumnFilters={setColumnFilters} isLoading={isLoading || isLoadingLayers} emptyMessage={t('tabs.extra_tabs.building_permit_panel.empty_message')} exportTitle={t(`tabs.extra_tabs.${id}.export_title`, {
            town: selectedTown?.name,
        })} analyticsAction={t(`tabs.extra_tabs.${id}.analytics`)} exportName={id === filtersKey.BUILDING_PERMIT_HOUSING
            ? t(`tabs.extra_tabs.${id}.export_name`, { townName })
            : null} exportedFields={EXPORTED_FIELDS[id]} formatExportHeader={formatExportHeader(t)} formatExportValue={formatExportValue(t)}/>
    </Stack>);
};
export default memo(BuildingPermitPanel);
