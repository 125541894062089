import { useCallback } from 'react';
import * as iots from 'io-ts';
import { useActiveUserDrawing, useSetActiveUserDrawing, } from 'hooks/contexts/useLocationContext';
import { fetchUserDrawings } from 'hooks/crm/useUserDrawing';
import createLayersHook from 'hooks/layers/createLayersHook';
import imgEmptyMarker from 'images/30x30.png';
import { IOPolygonLayerItem } from 'types/api';
import { VECTOR_TYPES } from 'types/types';
import { filtersKey } from 'utils/filters';
export const IOUserDrawingLayerItem = iots.intersection([
    IOPolygonLayerItem,
    iots.type({
        note: iots.union([iots.string, iots.null]),
    }),
], 'IOUserDrawingLayerItem');
const COLOR = '#A1400A';
export const getMarkerIcon = () => ({
    path: 'M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z',
    fillOpacity: 1,
    fillColor: COLOR,
    strokeWeight: 2,
    strokeColor: 'white',
    scale: 2,
    anchor: new google.maps.Point(12, 22),
});
const getMarkerImage = (item) => {
    if (item.note) {
        return imgEmptyMarker;
    }
    return getMarkerIcon();
};
const getLegends = () => {
    const legends = {};
    legends.user_drawing_marker = {
        layerKey: filtersKey.USER_DRAWINGS,
        label: 'user_drawing_marker',
        needsTranslate: true,
        image: getMarkerIcon(),
    };
    legends.user_drawing_line = {
        layerKey: filtersKey.USER_DRAWINGS,
        label: 'user_drawing_line',
        needsTranslate: true,
        color: '#A1400A',
        width: 20,
        borderWidth: 4,
        polygonType: VECTOR_TYPES.polyline,
    };
    legends.user_drawing_polygon = {
        layerKey: filtersKey.USER_DRAWINGS,
        label: 'user_drawing_polygon',
        needsTranslate: true,
        color: '#A1400A',
    };
    return legends;
};
const useUserDrawingsLayer = () => {
    const activeUserDrawing = useActiveUserDrawing();
    const setActiveUserDrawing = useSetActiveUserDrawing();
    const showDrawing = useCallback((drawing) => {
        return !activeUserDrawing || activeUserDrawing.id !== drawing.id;
    }, [activeUserDrawing]);
    const handleClick = useCallback((item) => {
        setActiveUserDrawing(item);
    }, [setActiveUserDrawing]);
    createLayersHook({
        fetch: fetchUserDrawings,
        alwaysEnabled: true,
        availableWithoutTown: true,
        onClick: handleClick,
        markerImg: getMarkerImage,
        legends: getLegends(),
        allowLayer: showDrawing,
    })(filtersKey.USER_DRAWINGS);
};
const UserDrawingsLayer = () => {
    useUserDrawingsLayer();
    return null;
};
export default UserDrawingsLayer;
