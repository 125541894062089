import * as React from 'react';
import { useCallback, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import lowThresholdSevesoImg from 'images/seveso_high_threshold.png';
import highThresholdSevesoImg from 'images/seveso_low_threshold.png';
import notSevesoImg from 'images/seveso_not.png';
import { IOMarkerLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
const HIGH_SEVESO = 'high_threshold_seveso';
const LOW_SEVESO = 'low_threshold_seveso';
const NOT_SEVESO = 'not_seveso';
const SEVESO_TYPES = [HIGH_SEVESO, LOW_SEVESO, NOT_SEVESO];
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const IOSevesoType = iots.union(SEVESO_TYPES.map(iots.literal));
const MULTIPLE_SEVESO_IMG = '/api/public/legacy/get-seveso-image';
const IOICPEMarkerItem = iots.intersection([
    IOMarkerLayerItem,
    iots.type({
        sevesoType: iots.union([IOSevesoType, iots.null]),
        count: iots.number,
        geoKey: iots.string,
    }),
]);
const IOICPEInfo = iots.type({
    name: iots.string,
    address: iots.string,
    siret: iots.string,
    rule: iots.string,
    sevesoType: iots.string,
    ied: iots.number, // 1 ou 0
    priority: iots.number, // 1 ou 0
    url: iots.string,
});
const fetchICPEInfo = (townId, item) => {
    return get(iots.array(IOICPEInfo), `legacy/${townId}/icpe-info/${encodeURIComponent(item.geoKey)}/${item.count}`);
};
const fetchICPE = (townId) => () => get(iots.array(IOICPEMarkerItem), `legacy/${townId}/icpe`);
const formatInfoWindowContent = (info, t) => {
    const content = (<Box maxWidth="1000px" maxHeight="400px" width="600px">
      {info.map((infoItem) => (<Box key={infoItem.name}>
          <span>
            {t('info_window.ppr_technological_icpe.name')}{' '}
            <b>{infoItem.name}</b>
            <br />
          </span>
          <span>
            {t('info_window.ppr_technological_icpe.address')}{' '}
            <b>{infoItem.address}</b>
            <br />
          </span>
          <span>
            {t('info_window.ppr_technological_icpe.siret')}{' '}
            <b>{infoItem.siret !== '' ? infoItem.siret : t('global.ND')}</b>
            <br />
          </span>
          <span>
            {t('info_window.ppr_technological_icpe.rule')}{' '}
            <b>{infoItem.rule}</b>
            <br />
          </span>
          <span>
            {t('info_window.ppr_technological_icpe.priority')}{' '}
            <b>{infoItem.priority === 1 ? t('global.yes') : t('global.no')}</b>
            <br />
          </span>
          <span>
            {t('info_window.ppr_technological_icpe.seveso_type')}{' '}
            <b>
              {infoItem.sevesoType !== ''
                ? infoItem.sevesoType
                : t('global.ND')}
            </b>
            <br />
          </span>
          <span>
            {t('info_window.ppr_technological_icpe.ied')}{' '}
            <b>{infoItem.ied === 1 ? t('global.yes') : t('global.no')}</b>
            <br />
          </span>
          <a href={infoItem.url} rel="noreferrer" target="_blank">
            {t('info_window.ppr_technological_icpe.url')}
          </a>
          {info.length > 1 ? (<>
              <br />
              <br />
            </>) : (<></>)}
        </Box>))}
    </Box>);
    return ReactDOMServer.renderToStaticMarkup(content);
};
const LEGENDS = {
    icpe_seveso_high_threshold: {
        layerKey: filtersKey.PPR_ICPE,
        label: 'ppr_technological_icpe_seveso_high_threshold',
        needsTranslate: true,
        image: highThresholdSevesoImg,
    },
    icpe_seveso_low_threshold: {
        layerKey: filtersKey.PPR_ICPE,
        label: 'ppr_technological_icpe_seveso_low_threshold',
        needsTranslate: true,
        image: lowThresholdSevesoImg,
    },
    icpe_not_seveso: {
        layerKey: filtersKey.PPR_ICPE,
        label: 'ppr_technological_icpe_not_seveso',
        needsTranslate: true,
        image: notSevesoImg,
    },
};
const ICPEImagesForTypes = {
    [HIGH_SEVESO]: highThresholdSevesoImg,
    [LOW_SEVESO]: lowThresholdSevesoImg,
    [NOT_SEVESO]: notSevesoImg,
};
const getMarkerImg = (marker) => {
    if (marker.count === 1) {
        return ICPEImagesForTypes[marker.sevesoType];
    }
    return MULTIPLE_SEVESO_IMG + `/${marker.count}`;
};
const useICPELayer = () => {
    const selectedTown = useSelectedTown();
    const { t } = useTranslation();
    const [markerInfo, setMarkerInfo] = useState({});
    const getInfoWindowContent = useCallback(async (marker) => {
        const markerKey = `${marker.lat}/${marker.lng}`;
        if (markerInfo[markerKey]) {
            return markerInfo[markerKey];
        }
        const icpeInfo = await fetchICPEInfo(selectedTown?.id, marker);
        const content = formatInfoWindowContent(icpeInfo, t);
        setMarkerInfo({
            ...markerInfo,
            [markerKey]: content,
        });
        return content;
    }, [selectedTown]);
    createLayersHook({
        fetch: fetchICPE,
        markerImg: getMarkerImg,
        legends: LEGENDS,
        getInfoWindowContent,
    })(filtersKey.PPR_ICPE);
};
const ICPELayer = () => {
    useICPELayer();
    return null;
};
export default ICPELayer;
