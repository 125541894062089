import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Box, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { multiSelectStyles } from './MultiSelectInput';
const getStyles = (error) => !error
    ? multiSelectStyles
    : {
        ...multiSelectStyles,
        control: (provided) => ({
            ...provided,
            backgroundColor: 'var(--colorModeBase)',
            borderRadius: '8px',
            width: '100%',
            borderColor: '#E62641',
            borderWidth: 2,
        }),
    };
const PlotSelect = ({ plots, disabled, onChange, name, }) => {
    const { t } = useTranslation();
    const { getFieldState, control, handleSubmit } = useFormContext();
    const { error } = getFieldState(name);
    const formattedPlots = plots && plots.length > 0
        ? plots.map((plot) => ({
            value: plot.id,
            label: `${plot.plotNumber || ''}`,
            townId: plot.townId,
        }))
        : [];
    return (<Box width="full">
      <FormControl isInvalid={Boolean(error)}>
        <Controller name={name} control={control} render={({ field }) => (<Select {...field} placeholder={'Ajouter une parcelle'} noOptionsMessage={() => t('global.no_options')} options={formattedPlots} styles={{
                ...getStyles(error),
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            }} isDisabled={disabled} blurInputOnSelect menuPortalTarget={document.body} onChange={(value) => {
                field.onChange(value);
                handleSubmit(onChange)();
            }} menuPlacement="auto"/>)}/>
        {Boolean(error) && (<FormErrorMessage>{error?.message}</FormErrorMessage>)}
      </FormControl>
    </Box>);
};
export default PlotSelect;
