import { useCallback, useMemo, useState } from 'react';
import * as iots from 'io-ts';
import { get, post } from 'api/client';
import { IODPEInfo } from 'components/infoWindow/DPEInfoWindow';
import { useExtraFilters } from 'hooks/contexts/useFiltersContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import DPETertiaryImg from 'images/dpe_tertiary.png';
import { filtersKey } from 'utils/filters';
import useComputedBounds from '../../hooks/map/useComputedBounds';
import { formatInfoWindowContent, IODPEMarkerItem, isDPEAllowed, isGESAllowed, isTimePeriodAllowed, } from './DPEOldLayer';
import { getBbox } from './POILayers';
const fetchDPETertiary = (townId, townScot, selectedTown, bbox) => () => get(iots.array(IODPEMarkerItem), `${townId}/DPE-tertiary/${bbox}`);
const fetchDPETertiaryInfo = (item) => {
    return post(iots.array(IODPEInfo), `DPE-tertiary-info`, { ids: item.id });
};
const LEGENDS = {
    dpe_tertiary: {
        label: 'dpe_tertiary',
        layerKey: filtersKey.DPE_TERTIARY,
        needsTranslate: true,
        image: DPETertiaryImg,
    },
};
const useDPETertiaryLayer = () => {
    const computedBounds = useComputedBounds();
    const extraFilters = useExtraFilters();
    const [markerInfo, setMarkerInfo] = useState({});
    const allowMarker = useCallback((marker) => {
        const date = extraFilters?.[filtersKey.DPE]?.timePeriod ?? null;
        const labelGES = extraFilters?.[filtersKey.DPE]?.labelGES ?? null;
        const labelDPE = extraFilters?.[filtersKey.DPE]?.labelDPE ?? null;
        return (isTimePeriodAllowed(marker, date) &&
            isGESAllowed(marker, labelGES) &&
            isDPEAllowed(marker, labelDPE));
    }, [extraFilters]);
    const getInfoWindowContent = async (marker) => {
        if (markerInfo[marker.id]) {
            return markerInfo[marker.id];
        }
        const DPEInfo = await fetchDPETertiaryInfo(marker);
        const content = formatInfoWindowContent(DPEInfo);
        setMarkerInfo({
            ...markerInfo,
            [marker.id]: content,
        });
        return content;
    };
    const bbox = useMemo(() => getBbox(computedBounds), [computedBounds]);
    createLayersHook({
        fetch: fetchDPETertiary,
        markerImg: DPETertiaryImg,
        legends: LEGENDS,
        getInfoWindowContent,
        allowLayer: allowMarker,
        extraParams: [bbox],
        disableQuery: !bbox,
    })(filtersKey.DPE_TERTIARY);
};
const DPETertiaryLayer = () => {
    useDPETertiaryLayer();
    return null;
};
export default DPETertiaryLayer;
