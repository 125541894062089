import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Button, HStack, VStack } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_IMPORT_CONTACT } from 'analytics/useAnalytics';
import { t } from 'i18next';
import TownSelect from 'components/inputs/TownSelect';
import useImportContacts from 'hooks/import/useImportContacts';
const resolver = async (values) => {
    const errors = {};
    if (!values.townId) {
        errors.townId = {
            type: 'required',
            message: t('contact.errors.town_required'),
        };
    }
    return {
        values: values,
        errors: errors,
    };
};
const FourthStep = ({ children, importData, handleLastStep, trackPrefix = '', textLastStep = 'contact.actions.finish', }) => {
    const { trackEvent } = useAnalytics();
    const { finishImportContacts, isLoading } = useImportContacts();
    const onSubmit = (formData) => {
        const data = {
            ...importData,
            townId: formData.townId.toString(),
        };
        finishImportContacts(data, (response) => {
            handleLastStep(response);
            trackEvent({
                category: ANALYTICS_IMPORT_CONTACT,
                action: trackPrefix + "Terminer l'import",
            });
        });
    };
    const methods = useForm({
        mode: 'onChange',
        resolver,
    });
    const { handleSubmit, formState: { isValid }, } = methods;
    return (<FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack width="full" spacing={1} alignItems="flex-start">
          <TownSelect label="contact.fourth_step_title" name="townId" placeholder="contact.fourth_step.select"/>
        </VStack>
        <HStack marginTop={2} justifyContent="flex-end">
          <Box> {children} </Box>
          <Box>
            <Button type="submit" size="sm" variant="primary" isDisabled={isLoading || !isValid} loadingText={t('global.status.importing')} data-cy="fourth-step-import-contact">
              {t(textLastStep)}
            </Button>
          </Box>
        </HStack>
      </form>
    </FormProvider>);
};
export default FourthStep;
