import * as React from 'react';
import { useMemo } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { getTabItem } from 'components/tabs/TabsNavigation/TabsItem/TabItemWrapper';
import { PLOT_TAB_TYPE } from 'context/TabsContext';
import { useToggleFilter } from 'hooks/contexts/useFiltersContext';
import { useResetPlotSelectionMode } from 'hooks/contexts/useLocationContext';
import { useSetFeasibilityParentId } from 'hooks/contexts/useSecondaryPlotsContext';
import { useChangeSelectedTab, useSelectedTab, useTabs, } from 'hooks/contexts/useTabsContext';
import useSyncNavigation from 'hooks/navigation/useSyncNavigation';
import { filtersKey } from 'utils/filters';
import TabsScrollButton from './TabsScrollButton';
const OneTabScroll = () => {
    const tabs = useTabs();
    const syncNavigation = useSyncNavigation();
    const selectedTab = useSelectedTab();
    const changeSelectedTab = useChangeSelectedTab();
    const selectedTabIndex = useMemo(() => tabs.findIndex((tab) => {
        return tab?.id === selectedTab;
    }) ?? 0, [selectedTab, tabs]);
    const currentTabIndex = selectedTabIndex > -1 ? selectedTabIndex : 0;
    const canScroll = tabs?.length > 1;
    const resetPlotSelectionMode = useResetPlotSelectionMode();
    const setFeasibilityParentId = useSetFeasibilityParentId();
    const toggleFilter = useToggleFilter();
    const handleTabChange = (newTab) => {
        resetPlotSelectionMode();
        toggleFilter(filtersKey.MAP_PLOTS, false);
        if (newTab.type === PLOT_TAB_TYPE) {
            setFeasibilityParentId(newTab.id);
        }
        changeSelectedTab(newTab.id);
        syncNavigation(newTab);
    };
    const getCurrentTab = () => {
        const currentTab = tabs?.length && tabs[currentTabIndex];
        if (currentTab)
            return getTabItem(currentTab);
        return null;
    };
    return (<HStack flex={1} alignItems="stretch" spacing={0}>
      {canScroll && (<TabsScrollButton type="left" disabled={currentTabIndex === 0} handleClick={() => {
                const newTabIndex = currentTabIndex - 1;
                if (newTabIndex >= 0) {
                    const newTab = tabs[newTabIndex];
                    handleTabChange(newTab);
                }
            }}/>)}

      <Box flex={1}>{getCurrentTab()}</Box>

      {canScroll && (<TabsScrollButton type="right" disabled={currentTabIndex === tabs?.length - 1} handleClick={() => {
                const newTabIndex = currentTabIndex + 1;
                if (newTabIndex <= tabs?.length - 1) {
                    const newTab = tabs[newTabIndex];
                    handleTabChange(newTab);
                }
            }}/>)}
    </HStack>);
};
export default OneTabScroll;
