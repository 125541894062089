import * as React from 'react';
import { useMemo, useState } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { Box, Heading, HStack, Link, Stack, Switch, Text, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_COURIER, ANALYTICS_TABLE, } from 'analytics/useAnalytics';
import i18n from 'i18n';
import { snakeCase } from 'lodash';
import ContactButton from 'components/buttons/ContactButton';
import DeleteButton from 'components/buttons/DeleteButton';
import EditButton from 'components/buttons/EditButton';
import SendCourierButton from 'components/buttons/SendCourierButton';
import CourierHeaderButtons from 'components/couriers/CourierHeaderButtons';
import Table from 'components/table/Table';
import CourierDrawer from 'components/tabs/couriers/courierForm/CourierDrawer';
import SendCouriersDrawer from 'components/tabs/couriers/courierForm/SendCouriersDrawer';
import SelectRows from 'components/tabs/utils/SelectRows';
import { useTabsView } from 'hooks/contexts/useLayoutContext';
import useCourier from 'hooks/courier/useCourier';
import useContact from 'hooks/crm/useContact';
import { FROM_CONTACT, FROM_COURIER } from 'utils/constants';
import { getRecipient, getSender } from 'utils/couriers';
import { formatDate } from 'utils/formatDate';
import { inDateRange } from 'utils/search';
import CourierCredits from './CourierCredits';
const getColumnHeader = (column) => (<Translation>
    {(t) => (<Text as="span" align="center">
        {t(`tabs.couriers.columns.${column}`)}
      </Text>)}
  </Translation>);
export const getMailevaStatusFromCode = (code) => i18n.t(`courier.status.` + code.toLowerCase());
const EXPORTED_FIELDS = [
    'createDate',
    'firstname',
    'firstnameSender',
    'documentName',
    'mailevaStatus',
    'executionDate',
    'mailevaProcessedDate',
];
const formatExportHeader = (t) => (header) => t(`tabs.couriers.columns.${snakeCase(header)}`);
const formatExportValue = (value, field) => {
    if (field === 'mailevaStatus') {
        return getMailevaStatusFromCode(value);
    }
    return value;
};
const STATUS_IMPORTED = 'IMPORTED';
const STATUS_NONE = 'NONE';
const CourierList = ({ title, couriers, isLoading, courierInfoDefault = null, analyticsDetail, withExport, fromPanel = FROM_COURIER, landPointId, isUserOwner = true, }) => {
    const tabsView = useTabsView();
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const { deleteCourier, isDeleteCourier, getCourierFiles } = useCourier({
        enabledList: true,
    });
    const { patchContact } = useContact();
    const [courierInfo, setCourierInfo] = useState();
    const [couriersInfo, setCouriersInfo] = useState();
    const [couriersCredits, setCouriersCredits] = useState();
    const [couriersIds, setCouriersIds] = useState([]);
    const [initialSelect, setInitialSelect] = useState(false);
    const [noCouriersSelected, setNoCouriersSelected] = useState(true);
    const handleRowSelection = (rows, isAllSelected, isNoneSelected) => {
        setNoCouriersSelected(isNoneSelected);
        setInitialSelect(false);
        setCouriersInfo(rows.filter((row) => row.mailevaStatus === STATUS_NONE));
        setCouriersIds(rows.map((row) => row.id));
        if (isAllSelected) {
            setNoCouriersSelected(!isAllSelected);
            trackEvent({
                category: ANALYTICS_TABLE,
                action: 'Mes Couriers - Tout sélectionner/désélectionner ',
            });
        }
    };
    const columns = useMemo(() => {
        return [
            {
                id: 'columns',
                columns: [
                    {
                        id: 'create_date',
                        accessorKey: 'createDate',
                        header: ({ table }) => {
                            return (<>
                  <Stack direction="row">
                    {isUserOwner && (<SelectRows {...{
                                    checked: table
                                        .getFilteredRowModel()
                                        .rows.every((row) => row.getIsSelected()),
                                    toggleRow: table.getToggleAllRowsSelectedHandler(),
                                    setNoItemIsSelected: setNoCouriersSelected,
                                }}/>)}
                    <Text variant="primary">
                      {getColumnHeader('create_date')}
                    </Text>
                  </Stack>
                </>);
                        },
                        cell: (info) => {
                            return (<HStack>
                  {isUserOwner && (<SelectRows id="delete_couriers_select" {...{
                                    checked: info.row.getIsSelected(),
                                    disabled: !info.row.getCanSelect(),
                                    toggleRow: info.row.getToggleSelectedHandler(),
                                }}/>)}
                  <Text>
                    {` ${formatDate(info.row.original.createDate, 'extended')}`}
                  </Text>
                </HStack>);
                        },
                        enableGlobalFilter: false,
                        filterFn: inDateRange,
                    },
                    {
                        accessorKey: 'firstname',
                        accessorFn: (row) => {
                            return getRecipient(row);
                        },
                        header: () => getColumnHeader('firstname'),
                        cell: (info) => {
                            if (!landPointId &&
                                info.row.original.contactId &&
                                fromPanel !== FROM_CONTACT) {
                                return (<ContactButton contactId={info.row.original.contactId}>
                    <span data-cy="recipient_detail">
                      {getRecipient(info.row.original)}
                    </span>
                  </ContactButton>);
                            }
                            else {
                                return getRecipient(info.row.original);
                            }
                        },
                        meta: {
                            minW: '250px',
                        },
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'firstnameSender',
                        accessorFn: (row) => {
                            return getSender(row);
                        },
                        header: () => getColumnHeader('firstname_sender'),
                        cell: (info) => {
                            return getSender(info.row.original);
                        },
                        meta: {
                            minW: '250px',
                        },
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'documentName',
                        header: () => getColumnHeader('document_name'),
                        cell: (info) => {
                            return (<Link onClick={() => getCourierFiles({ courierIds: [info.row.original.id] })} variant="secondary" isExternal>
                  {info.row.original.documentName}
                </Link>);
                        },
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'mailevaStatus',
                        accessorFn: (row) => {
                            return getMailevaStatusFromCode(row.mailevaStatus);
                        },
                        header: () => getColumnHeader('maileva_status'),
                        cell: (info) => info.getValue(),
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'executionDate',
                        header: () => getColumnHeader('execution_date'),
                        cell: (info) => formatDate(info.getValue(), info.row.original.mailevaStatus !== STATUS_IMPORTED
                            ? 'extended'
                            : 'short'),
                        enableGlobalFilter: false,
                        filterFn: inDateRange,
                    },
                    {
                        accessorKey: 'mailevaProcessedDate',
                        header: () => getColumnHeader('maileva_processed_date'),
                        cell: (info) => formatDate(info.getValue(), 'extended'),
                        enableGlobalFilter: false,
                        filterFn: inDateRange,
                    },
                    {
                        accessorKey: 'npai',
                        header: () => getColumnHeader('npai'),
                        cell: ({ row }) => {
                            if (!row.original.contactId) {
                                return null;
                            }
                            const npai = !!row.original.npai;
                            return (<Box textAlign="center">
                  <Switch size="sm" isChecked={npai} onChange={() => {
                                    patchContact({
                                        id: row.original.contactId,
                                        npai,
                                    });
                                }}/>
                </Box>);
                        },
                        enableGlobalFilter: false,
                        enableColumnFilter: false,
                        enableSorting: false,
                    },
                    {
                        accessorKey: 'id',
                        header: '',
                        cell: (info) => {
                            if (info.row.original.mailevaIdentifier === '') {
                                return (<HStack spacing={1}>
                    {info.row.original.mailevaStatus !== STATUS_IMPORTED && (<SendCourierButton size="sm" courierInfo={info.row.original} fileName={info.row.original.documentName}/>)}
                    <EditButton size="sm" mode="icon" handleEdit={() => setCourierInfo(info.row.original)} disabled={info.row.original.mailevaStatus !== STATUS_NONE}/>
                    <DeleteButton size="sm" mode="icon" confirmText={t('tabs.couriers.delete_confirm_text')} handleDelete={() => deleteCourier({
                                        courierId: info.row.original.id,
                                    }, {
                                        onSuccess: () => {
                                            trackEvent({
                                                category: ANALYTICS_COURIER,
                                                action: 'Supprimer un courrier',
                                            });
                                        },
                                    })} disabled={isDeleteCourier} data-cy="delete_courier" id="delete_courier"/>
                  </HStack>);
                            }
                            else {
                                return;
                            }
                        },
                        enableSorting: false,
                        enableColumnFilter: false,
                        enableGlobalFilter: false,
                    },
                ],
            },
        ];
    }, []);
    return (<Stack width="full" spacing={3} height="100%">
      <HStack flexWrap="wrap" spacing={tabsView === 'half_vertical' ? 0 : 3} justifyContent="space-start">
        <Heading as="h4" variant="h4" marginRight={2}>
          {t(title)}
        </Heading>
        <CourierCredits />
      </HStack>
      <Table handleRowSelection={handleRowSelection} initialSelect={initialSelect} initialColumnVisibility={{
            npai: fromPanel === FROM_COURIER && isUserOwner,
            id: isUserOwner,
        }} headerTitle={isUserOwner && (<CourierHeaderButtons courierInfoDefault={courierInfoDefault} analyticsDetail={analyticsDetail} setCourierInfo={setCourierInfo} couriersInfo={couriersInfo} setCouriersCredits={setCouriersCredits} couriersIds={couriersIds} setCouriersIds={setCouriersIds} setInitialSelect={setInitialSelect} noCouriersSelected={noCouriersSelected} fromPanel={fromPanel}/>)} id={`couriers`} columns={columns} data={couriers || []} isLoading={isLoading} withExport={withExport} exportedFields={EXPORTED_FIELDS} formatExportHeader={formatExportHeader(t)} formatExportValue={formatExportValue} exportTitle={t('tabs.courier.title')}/>
      <CourierDrawer courierInfo={courierInfo} handleClose={() => setCourierInfo(undefined)} fromPanel={fromPanel}/>
      <SendCouriersDrawer couriersInfo={couriersInfo} couriersCredits={couriersCredits} handleClose={() => setCouriersCredits(undefined)}/>
    </Stack>);
};
export default CourierList;
