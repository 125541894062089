import * as React from 'react';
import { memo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, HStack, Stack } from '@chakra-ui/react';
import moment from 'moment';
import DateTimePickerInput from 'components/inputs/DateTimePickerInput';
import UserStatisticsActivitiesPanel from './userStatisticsCRM/UserStatisticsActivitiesPanel';
import UserStatisticsContactsPanel from './userStatisticsCRM/UserStatisticsContactsPanel';
import UserStatisticsGlobalPanel from './userStatisticsCRM/UserStatisticsGlobalPanel';
import UserStatisticsPlotsPanel from './userStatisticsCRM/UserStatisticsPlotsPanel';
import UserStatisticsProjectsPanel from './userStatisticsCRM/UserStatisticsProjectsPanel';
const UserStatisticsCRMPanel = () => {
    const { t } = useTranslation();
    const [dataProject, setDataProject] = useState({
        turnover: 0,
        lots: 0,
        surface: 0,
    });
    const currentDate = moment();
    const pastDate = currentDate.subtract(90, 'days');
    const [filters, setFilters] = useState({
        dateStart: new Date(pastDate.format('YYYY-MM-DD')),
        dateEnd: null,
    });
    const formRef = useRef(null);
    const methods = useForm({
        defaultValues: {
            dateStart: new Date(pastDate.format('YYYY-MM-DD')),
            dateEnd: null,
        },
    });
    const { handleSubmit, getValues } = methods;
    const handleChange = () => {
        onSubmit();
    };
    const onSubmit = () => {
        const formData = getValues();
        const currentFilters = {
            dateStart: formData.dateStart ?? null,
            dateEnd: formData.dateEnd ?? null,
        };
        setFilters(currentFilters);
    };
    return (<Stack paddingX={5} height="100%">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
          <HStack>
            <Box>
              <DateTimePickerInput name="dateStart" label={t('tabs.my_statistics_crm.date_start')} placeholder={t('global.beginning')} onChange={handleChange} required withTime={false} isClearable={true} direction="row" minDate={null}/>
            </Box>
            <Box>
              <DateTimePickerInput name="dateEnd" label={t('tabs.my_statistics_crm.date_end')} placeholder={t('global.today')} onChange={handleChange} required withTime={false} isClearable={true} direction="row" minDate={null}/>
            </Box>
          </HStack>
        </form>
      </FormProvider>
      <HStack width="100%" alignItems="flex-start">
        <UserStatisticsProjectsPanel setDataProject={setDataProject} filters={filters}/>
        <UserStatisticsPlotsPanel filters={filters}/>
      </HStack>
      <UserStatisticsGlobalPanel dataProject={dataProject}/>
      <HStack width="100%" alignItems="flex-start" paddingBottom={2}>
        <UserStatisticsContactsPanel filters={filters}/>
        <UserStatisticsActivitiesPanel filters={filters}/>
      </HStack>
    </Stack>);
};
export default memo(UserStatisticsCRMPanel);
