import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IORegionalPlanGreenAreaLayerItem = IOLayerItem;
const fetchRegionalPlanGreenArea = (townId) => () => get(iots.array(IORegionalPlanGreenAreaLayerItem), `legacy/${townId}/regional-plan-green-area`);
const useRegionalPlanGreenAreaLayer = () => {
    createLayersHook({
        fetch: fetchRegionalPlanGreenArea,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.REGIONAL_PLAN_GREEN_AREA);
};
const RegionalPlanGreenAreaLayer = () => {
    useRegionalPlanGreenAreaLayer();
    return null;
};
export default RegionalPlanGreenAreaLayer;
