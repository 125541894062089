import * as React from 'react';
import { useCallback, useEffect, useRef, useState, } from 'react';
import useAnalytics, { ANALYTICS_MAP_BUTTONS } from 'analytics/useAnalytics';
import { createContext } from 'use-context-selector';
import { useSetStreetViewMode, useStreetViewMode, } from 'hooks/contexts/useLayoutContext';
import { useDrawingMode } from 'hooks/contexts/useLocationContext';
import usePLUInfo from 'hooks/usePLUInfo';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import darkModeMapStyle from 'utils/darkModeMapStyle';
import { filtersKey } from 'utils/filters';
import { addInfoWindowListener, addSatelliteIgnMapType, BASE_PRECISION, cleanAllMarkerFromMapAndPanorama, createMap, createPanorama, getBaseCenter, getBaseZoom, } from 'utils/map';
import { legendsToKeep } from './LegendContext';
import usePLUGPUInfo from 'hooks/usePLUGPUInfo';
export const SATELLITE = 'satellite';
export const ROADMAP = 'roadmap';
export const SAT_IGN = 'satign';
export const SAT_IGN_TOPOGRAPHY = 'satignTopography';
export const OBLIQUE = 'oblique';
export const HIGH_DEFINITION = 'highDefinition';
export const HISTORY_MAP = 'historyMap';
export const MAP_DISPLAYS = [
    ROADMAP,
    SATELLITE,
    SAT_IGN,
    SAT_IGN_TOPOGRAPHY,
    OBLIQUE,
    HIGH_DEFINITION,
    HISTORY_MAP,
];
export const MAP_DISPLAYS_SATELLITE = [
    SATELLITE,
    HIGH_DEFINITION,
    SAT_IGN,
    SAT_IGN_TOPOGRAPHY,
    OBLIQUE,
];
export const HISTORY_MAP_YEARS = [
    '1950-1965',
    '2000-2005',
    '2006-2010',
    '2011-2015',
    '2016',
    '2017',
    '2018',
    '2019',
    '2020',
];
export const MapContext = createContext(null);
export const MapProvider = ({ children }) => {
    const refMap = useRef(null);
    const refPanorama = useRef(null);
    const { defaultMapDisplay } = useSiteConfiguration();
    const [map, setMap] = useState(null);
    const [panorama, setPanorama] = useState(null);
    const streetViewMode = useStreetViewMode();
    const setStreetViewMode = useSetStreetViewMode();
    const { trackEvent } = useAnalytics();
    const mapCenter = React.useRef();
    const [layers, setLayers] = useState({});
    const infoWindow = useRef(null);
    const [coordsPLUInfo, setCoordsPLUInfo] = useState({ coords: null, townId: null });
    const [coordsPLUGPUInfo, setCoordsPLUGPUInfo] = useState({ coords: null });
    const [mapDisplay, setMapDisplay] = useState(ROADMAP);
    const [mapDisplaySatellite, setMapDisplaySatellite] = useState(SATELLITE);
    const [historyMapYear, setHistoryMapYear] = useState(null);
    const infoWindowListenersRef = useRef({});
    const [precision, setPrecision] = useState(BASE_PRECISION);
    const [computedMapType, setComputedMapType] = useState(null);
    const drawingMode = useDrawingMode();
    const [clickableVectors, setClickableVectors] = useState([]);
    useEffect(() => {
        // When drawing mode is enabled, we want to make all the polygons on the map unclickable
        if (!drawingMode) {
            clickableVectors.forEach((vector) => {
                vector.setOptions({ clickable: true });
            });
            return;
        }
        const vectors = [];
        Object.keys(layers).forEach((layerkey) => {
            const layerData = layers[layerkey];
            Object.values(layerData).forEach((polygon) => {
                polygon.vectors?.forEach((vector) => {
                    if (layerkey === filtersKey.USER_DRAWINGS) {
                        return;
                    }
                    if (vector instanceof google.maps.Polygon && vector.clickable) {
                        vectors.push(vector);
                        vector.setOptions({ clickable: false });
                    }
                });
            });
        });
        setClickableVectors(vectors);
    }, [drawingMode]);
    const initMap = useCallback((ref, darkMode = false, rotateControl = true) => {
        const mapTypeId = defaultMapDisplay();
        const newMap = createMap(ref, darkMode, rotateControl, mapTypeId);
        addSatelliteIgnMapType(newMap);
        setMap(newMap);
    }, [setMap]);
    const initPanorama = useCallback((refPanorama, map, options) => {
        const newPanorama = createPanorama(refPanorama, map, setStreetViewMode, options);
        setPanorama(newPanorama);
    }, [setPanorama]);
    const { PLUInfo, isInitialLoading: isLoadingPLUInfo, error: pluInfoError, } = usePLUInfo(coordsPLUInfo);
    const { PLUGPUInfo, isInitialLoading: isLoadingPLUGPUInfo, error: pluGPUInfoError, } = usePLUGPUInfo(coordsPLUGPUInfo);
    useEffect(() => {
        if (pluInfoError) {
            setPLUInfoData(null, null);
        }
    }, [pluInfoError]);
    useEffect(() => {
        if (pluGPUInfoError) {
            setPLUGPUInfoData(null);
        }
    }, [pluGPUInfoError]);
    const setPLUInfoData = (coords, townId) => {
        setCoordsPLUInfo({ coords, townId: townId });
    };
    const setPLUGPUInfoData = (coords) => {
        setCoordsPLUGPUInfo({ coords });
    };
    const closeStreetView = useCallback(() => {
        if (panorama) {
            // will trigger a visible_changed event
            panorama.setVisible(false);
        }
    }, [panorama]);
    const setZoomStreetView = useCallback((value) => {
        panorama.setZoom(panorama.getZoom() + value);
    }, [panorama]);
    useEffect(() => {
        if (panorama && streetViewMode) {
            trackEvent({
                category: ANALYTICS_MAP_BUTTONS,
                action: `Pegman`,
            });
            google.maps.event.trigger(panorama, 'resize');
        }
    }, [panorama, streetViewMode]);
    const toggleMarker = useCallback((markerItem, visible) => {
        markerItem.marker.setMap(visible ? map : null);
        markerItem.marker.setVisible(visible);
        if (markerItem.polygon) {
            togglePolygon(markerItem.polygon, visible);
        }
    }, [map]);
    const togglePolygon = useCallback((polygonItem, visible) => {
        polygonItem.vectors.forEach((vector) => {
            vector.setVisible(visible);
        });
    }, [map]);
    const toggleLayer = useCallback((layer, visible) => {
        if ('marker' in layer) {
            toggleMarker(layer, visible);
        }
        else {
            togglePolygon(layer, visible);
        }
    }, [togglePolygon, toggleMarker]);
    const addLayers = useCallback((newLayers, layerKey, visible = true) => {
        Object.keys(newLayers).forEach((key) => {
            toggleLayer(newLayers[key], visible);
        });
        setLayers((prevLayers) => ({
            ...prevLayers,
            [layerKey]: {
                ...prevLayers[layerKey],
                ...newLayers,
            },
        }));
    }, [setLayers, map, togglePolygon, toggleMarker]);
    const removeLayers = useCallback((layerKey, ids = null) => {
        setLayers((prevLayers) => {
            const newLayers = { ...prevLayers };
            if (newLayers[layerKey]) {
                Object.keys(newLayers[layerKey]).forEach((id) => {
                    if (!ids || ids.includes(id)) {
                        toggleLayer(newLayers[layerKey][id], false);
                        delete newLayers[layerKey][id];
                    }
                });
                if (!ids || Object.keys(newLayers[layerKey]).length === 0) {
                    delete newLayers[layerKey];
                }
            }
            return newLayers;
        });
    }, [setLayers, toggleLayer]);
    const resetLayers = () => {
        for (const layerKey in layers) {
            Object.keys(layers[layerKey]).forEach((id) => {
                if (!legendsToKeep?.includes(layerKey)) {
                    toggleLayer(layers[layerKey][id], false);
                    delete layers[layerKey][id];
                }
            });
        }
    };
    const addInfoWindow = useCallback((vector, content, listenerKey) => {
        return addInfoWindowListener(vector, content, map, infoWindow, infoWindowListenersRef, listenerKey);
    }, [map]);
    const closeInfoWindow = useCallback(() => {
        if (infoWindow.current) {
            infoWindow.current.close();
        }
        infoWindow.current = null;
    }, []);
    const setMapOptions = useCallback((options) => {
        if (map) {
            map.setOptions(options);
        }
    }, [map]);
    const setDarkMode = (darkMode) => {
        setMapOptions({ styles: darkMode ? darkModeMapStyle : undefined });
    };
    const setRotateControl = (rotateControl) => {
        setMapOptions({ rotateControl });
    };
    const resetMap = () => {
        closeStreetView();
        map.setCenter(getBaseCenter());
        map.setZoom(getBaseZoom());
        cleanAllMarkerFromMapAndPanorama();
    };
    const changeMapDisplay = useCallback((display, historyMapYear) => {
        switch (display) {
            case SATELLITE:
            case ROADMAP:
            case SAT_IGN:
            case HIGH_DEFINITION:
                map.setMapTypeId(display);
                map.setTilt(0);
                break;
            case OBLIQUE:
                map.setMapTypeId(google.maps.MapTypeId.SATELLITE);
                if (map.getZoom() < 18)
                    map.setZoom(18);
                map.setTilt(45);
                break;
        }
        setMapDisplay(display);
        setMapDisplaySatellite(display !== ROADMAP ? display : SATELLITE);
        setHistoryMapYear(display === HISTORY_MAP ? historyMapYear : null);
    }, [map, setMapDisplay, setMapDisplaySatellite, setHistoryMapYear]);
    return (<MapContext.Provider value={{
            refMap,
            refPanorama,
            map,
            panorama,
            initMap,
            initPanorama,
            streetViewMode,
            closeStreetView,
            setZoomStreetView,
            infoWindow,
            addInfoWindow,
            closeInfoWindow,
            precision,
            setPrecision,
            computedMapType,
            setComputedMapType,
            setDarkMode,
            setRotateControl,
            layers,
            addLayers,
            removeLayers,
            infoWindowListenersRef,
            resetMap,
            setPLUInfoData,
            setPLUGPUInfoData,
            PLUInfo,
            isLoadingPLUInfo,
            PLUGPUInfo,
            isLoadingPLUGPUInfo,
            resetLayers,
            mapCenter,
            mapDisplay,
            mapDisplaySatellite,
            historyMapYear,
            changeMapDisplay,
        }}>
      {children}
    </MapContext.Provider>);
};
export default MapContext;
