import { useContextSelector } from 'use-context-selector';
import LegendContext from 'context/LegendContext';
export const useLegends = () => {
    return useContextSelector(LegendContext, (state) => state.legends);
};
export const useAddLegend = () => {
    return useContextSelector(LegendContext, (state) => state.addLegend);
};
export const useRemoveLegend = () => {
    return useContextSelector(LegendContext, (state) => state.removeLegend);
};
export const useResetLegends = () => {
    return useContextSelector(LegendContext, (state) => state.resetLegends);
};
export const usePlotSummary = () => {
    return useContextSelector(LegendContext, (state) => state.plotSummary);
};
export const useClearPlotSummary = () => {
    return useContextSelector(LegendContext, (state) => state.clearPlotSummary);
};
export const useFetchPlotSummary = () => {
    return useContextSelector(LegendContext, (state) => state.fetchPlotSummary);
};
export const useIsLoadingPlotSummary = () => {
    return useContextSelector(LegendContext, (state) => state.isLoadingPlotSummary);
};
