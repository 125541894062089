import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
const IOPresumedArcheologicalAreaLayerItem = IOLayerItem;
const getInfoWindowContent = (layerInfo) => {
    const content = (<>
      {layerInfo.infoTitle}
      {layerInfo.infoLine1 && layerInfo.infoTitle != layerInfo.infoLine1 && (<>
          <br />
          {layerInfo.infoLine1}
        </>)}
    </>);
    return ReactDOMServer.renderToString(content);
};
const fetchPresumedArcheologicalArea = (townId) => () => get(iots.array(IOPresumedArcheologicalAreaLayerItem), `legacy/${townId}/presumed-archeological-area`);
const usePresumedArcheologicalAreaLayer = () => {
    createLayersHook({
        fetch: fetchPresumedArcheologicalArea,
        getInfoWindowContent,
    })(filtersKey.PRESUMED_ARCHEOLOGICAL_AREA);
};
const PresumedArcheologicalAreaLayer = () => {
    usePresumedArcheologicalAreaLayer();
    return null;
};
export default PresumedArcheologicalAreaLayer;
