import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOStoneQuarryLayerItem = IOLayerItem;
const fetchStoneQuarry = (townId) => () => get(iots.array(IOStoneQuarryLayerItem), `legacy/${townId}/stone-quarry`);
const useStoneQuarryLayer = () => {
    createLayersHook({
        fetch: fetchStoneQuarry,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.STONE_QUARRY);
};
const StoneQuarryLayer = () => {
    useStoneQuarryLayer();
    return null;
};
export default StoneQuarryLayer;
