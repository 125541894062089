import { useMutation } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { post } from 'api/client';
import useOnError from 'hooks/useOnError';
import { IOEmpty } from 'types/api';
const IOLocale = iots.type({
    lang: iots.string,
});
const updateLocaleRequest = ({ lang: string }) => post(IOEmpty, `user/locale/${string}`);
const useLocale = () => {
    const onErrorCallback = useOnError();
    const { mutate: updateLocale, isPending: isUpdating } = useMutation({
        mutationFn: updateLocaleRequest,
        onError: (err) => onErrorCallback(err),
    });
    return {
        updateLocale,
        isUpdating,
    };
};
export default useLocale;
