import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { post } from 'api/client';
import useCurrentSite from 'hooks/useCurrentSite';
import { IOPlotFeasibilityInfo, } from './usePlotFeasibility';
const fetchPlotsFeasibility = (townshipId, plotIds, siteId) => () => post(iots.array(IOPlotFeasibilityInfo), `${townshipId}/plots-feasibility`, {
    plotIds: plotIds.join(','),
    siteId,
});
export const getQueryKey = (townshipId, plotIds) => [
    'plots-feasibility',
    townshipId,
    plotIds,
];
const usePlotsFeasibility = (townshipId, plotIds) => {
    const { currentSite } = useCurrentSite();
    return useQuery({
        queryKey: getQueryKey(townshipId, plotIds),
        queryFn: fetchPlotsFeasibility(townshipId, plotIds, currentSite?.legacyId),
        enabled: !!townshipId && !!plotIds && plotIds.length > 0,
    });
};
export default usePlotsFeasibility;
