import * as React from 'react';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Heading, Spinner, VStack } from '@chakra-ui/react';
import { isAvailable, } from 'components/tabs/crmTabs/TeamStatisticsCRMPanel';
import useTeamStatisticsCRM from 'hooks/crm/useTeamStatisticsCRM';
import { getPlotStatusOption } from 'utils/personalization';
import UserStatisticsStatusItem from '../userStatisticsCRM/UserStatisticsStatusItem';
const TeamStatisticsPlotsPanel = ({ filters, }) => {
    const { t } = useTranslation();
    const [dataFormatted, setDataFormatted] = useState({
        status: {},
        percent: {},
    });
    const { statsPlots, isLoadingStatsPlots, refetchPlots } = useTeamStatisticsCRM({ toLoad: 'plots' });
    useEffect(() => {
        refetchPlots();
    }, []);
    useEffect(() => {
        if (statsPlots) {
            const data = { status: {}, percent: {} };
            getPlotStatusOption().map((option) => {
                data.status[option.value] = 0;
                data.percent[option.value] = 0;
            });
            let nbPlots = 0;
            for (const userEmail in statsPlots) {
                nbPlots += statsPlots[userEmail].length;
            }
            for (const userEmail in statsPlots) {
                if (!isAvailable({ filters, email: userEmail })) {
                    continue;
                }
                const userData = statsPlots[userEmail];
                userData.forEach((plot) => {
                    const date = new Date(plot.createdAt);
                    if (!isAvailable({
                        filters,
                        date,
                        status: plot.status,
                        group: plot.group,
                    })) {
                        return;
                    }
                    data.status[plot.status] += 1;
                    const percent = (data.status[plot.status] / nbPlots) * 100;
                    data.percent[plot.status] = percent.toFixed(2);
                });
            }
            setDataFormatted(data);
        }
    }, [statsPlots, filters]);
    return (<Box flex={1}>
      <Heading as="h3" variant="h3" marginBottom={2}>
        {t('tabs.team_statistics_crm.plots')}
      </Heading>
      <VStack spacing={1} alignItems="flex-start" position="relative">
        {getPlotStatusOption().map((option) => {
            return (<UserStatisticsStatusItem key={option.value} option={option} data={dataFormatted}/>);
        })}
        {isLoadingStatsPlots && (<Box display="flex" alignItems="center" justifyContent="center" position="absolute" top={0} left={0} width="100%" height="100%" backgroundColor="rgba(251, 250, 249, 0.6)">
            <Spinner />
          </Box>)}
      </VStack>
    </Box>);
};
export default memo(TeamStatisticsPlotsPanel);
