import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOWoodlandAreaLayerItem = IOLayerItem;
const fetchWoodlandArea = (townId) => () => get(iots.array(IOWoodlandAreaLayerItem), `legacy/${townId}/woodland-area`);
const useWoodlandAreaLayer = () => {
    createLayersHook({
        fetch: fetchWoodlandArea,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.WOODLAND_AREA);
};
const WoodlandAreaLayer = () => {
    useWoodlandAreaLayer();
    return null;
};
export default WoodlandAreaLayer;
