import * as React from 'react';
import { memo, useRef, useState } from 'react';
import { Box, Center, HStack, Spinner, VStack } from '@chakra-ui/react';
import { ANALYTICS_CATEGORY_DOWNLOAD, ANALYTICS_CONTACT_TAB, } from 'analytics/useAnalytics';
import ExportButton, { EXPORT_TYPE_CONTACT, } from 'components/buttons/ExportButton';
import PanelNavigation from 'components/tabs/TabsNavigation/PanelNavigation';
import useContact from 'hooks/crm/useContact';
import { CONTACT_SECTION } from 'types/api';
import { SECTIONS, TOOLBAR_HEIGHT } from 'utils/contact';
const ContactPanel = ({ tabContact }) => {
    const scrollRef = useRef();
    const [selectedSection, setSelectedSection] = useState(CONTACT_SECTION);
    const { contact, isFetchingContact: isFetching } = useContact(tabContact.id);
    const handleChangeSection = (section) => {
        setSelectedSection(section);
        if (scrollRef.current) {
            scrollRef.current.scrollTop = 0;
        }
    };
    const Component = SECTIONS[selectedSection] || undefined;
    const exportButton = (<ExportButton analyticsEvent={{
            category: ANALYTICS_CATEGORY_DOWNLOAD,
            action: 'Télécharger contact',
        }} exportType={EXPORT_TYPE_CONTACT} exportParams={{ contactId: contact?.id }}/>);
    if (isFetching)
        return (<Center width="full" height="full">
        <Spinner size="lg"/>
      </Center>);
    return (<Box height="100%" width="100%">
      <VStack height="100%" spacing={0}>
        <HStack width="100%" bg="white" display="flex" justify="space-between">
          <PanelNavigation isLoading={false} handleChangeSection={handleChangeSection} allowedSections={Object.keys(SECTIONS)} panelTitle="contact" trackEventCategory={ANALYTICS_CONTACT_TAB} section={selectedSection}/>
          <Box>{exportButton}</Box>
        </HStack>
        <Box ref={scrollRef} height={`calc(100% - ${TOOLBAR_HEIGHT})`} overflowY="auto" width="100%" id="contact-panel-scrollable" opacity={1} padding={5}>
          <>
            {Component && (<Component contact={contact} contactId={contact?.id} isFetching={isFetching}/>)}
          </>
        </Box>
      </VStack>
    </Box>);
};
export default memo(ContactPanel);
