import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Link, Table, TableContainer, Tbody, Td, Text, Tr, } from '@chakra-ui/react';
import DownloadLinkWrapper from 'components/links/DownloadLinkWrapper';
const ScotSidebarContent = ({ item }) => {
    const { t } = useTranslation();
    return (<Box>
      <TableContainer width="100%">
        <Table size="sm" width="100%" variant="faded">
          <Tbody whiteSpace="normal">
            <Tr>
              <Td padding={1}>
                <Text as="span" textStyle="small">
                  {t('sidebar_content.scot.name')}
                </Text>
              </Td>
              <Td padding={1}>
                <Text as="span" textStyle="small">
                  {item.name}
                </Text>
              </Td>
            </Tr>
            <Tr>
              <Td padding={1}>
                <Text as="span" textStyle="small">
                  {t('sidebar_content.scot.state')}
                </Text>
              </Td>
              <Td padding={1}>
                <Text as="span" textStyle="small">
                  {item.state}
                </Text>
              </Td>
            </Tr>
            <Tr>
              <Td padding={1}>
                <Text as="span" textStyle="small">
                  {t('sidebar_content.scot.date')}
                </Text>
              </Td>
              <Td padding={1}>
                <Text as="span" textStyle="small">
                  {item.procedureDate}
                </Text>
              </Td>
            </Tr>
            <Tr>
              <Td padding={1}>
                <Text as="span" textStyle="small">
                  {t('sidebar_content.scot.epci_name')}
                </Text>
              </Td>
              <Td padding={1}>
                <Text as="span" textStyle="small">
                  {item.epciName}
                </Text>
              </Td>
            </Tr>
            <Tr>
              <Td padding={1}>
                <Text as="span" textStyle="small">
                  {t('sidebar_content.scot.epci_type')}
                </Text>
              </Td>
              <Td padding={1}>
                <Text as="span" textStyle="small">
                  {item.epciType}
                </Text>
              </Td>
            </Tr>
            <Tr>
              <Td padding={1}>
                <Text as="span" textStyle="small">
                  {t('sidebar_content.scot.epci_siren')}
                </Text>
              </Td>
              <Td padding={1}>
                <Text as="span" textStyle="small">
                  {item.epciSiren}
                </Text>
              </Td>
            </Tr>
            <Tr>
              <Td padding={1}>
                <Text as="span" textStyle="small">
                  {t('sidebar_content.scot.interdepartemental')}
                </Text>
              </Td>
              <Td padding={1}>
                <Text as="span" textStyle="small">
                  {item.interdepartemental}
                </Text>
              </Td>
            </Tr>
            {item.grenelle ? (<Tr>
                <Td padding={1}>
                  <Text as="span" textStyle="small">
                    {t('sidebar_content.scot.grenelle')}
                  </Text>
                </Td>
                <Td padding={1}>
                  <Text as="span" textStyle="small">
                    {item.grenelle}
                  </Text>
                </Td>
              </Tr>) : null}
          </Tbody>
        </Table>
      </TableContainer>
      {item.docUrl && (<Box paddingTop={2}>
          <DownloadLinkWrapper>
            <Link target="_blank" href={item.docUrl}>
              {t('sidebar_content.scot.download')}
            </Link>
          </DownloadLinkWrapper>
        </Box>)}
    </Box>);
};
export default ScotSidebarContent;
