import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, HStack } from '@chakra-ui/react';
import { CONTACT_TAB_TYPE, CRM_TABS, PROJECT_TAB_TYPE, } from 'context/TabsContext';
import { useContacts, useProjects } from 'hooks/contexts/useTabsContext';
import CalendarIcon from 'images/icons/Calendar';
import ContactsIcon from 'images/icons/Contacts';
import EnvelopeIcon from 'images/icons/Envelope';
import PlotIcon from 'images/icons/Plot';
import ProjectIcon from 'images/icons/Project';
import ProtectionIcon from 'images/icons/Protection';
import StatisticIcon from 'images/icons/Statistic';
import TabItemWrapper, { TabItemTitle, } from './TabItemWrapper';
const TAB_ICON = {
    [CRM_TABS.CONTACT_LIST]: <ContactsIcon fill="currentColor" width="15px"/>,
    [CRM_TABS.USER_PLOTS]: <PlotIcon fill="currentColor" width="15px"/>,
    [CRM_TABS.USER_PROJECTS]: (<ProjectIcon fill="currentColor" width="15px" height="16px"/>),
    [CRM_TABS.TEAM_PLOTS]: <PlotIcon fill="currentColor" width="15px"/>,
    [CRM_TABS.TEAM_PROJECTS]: (<ProjectIcon fill="currentColor" width="15px" height="16px"/>),
    [CRM_TABS.TEAM_STATISTICS]: (<StatisticIcon fill="currentColor" width="15px"/>),
    [CRM_TABS.TEAM_STATISTICS_CRM]: (<StatisticIcon fill="currentColor" width="15px"/>),
    [CRM_TABS.MY_STATISTICS]: <StatisticIcon fill="currentColor" width="15px"/>,
    [CRM_TABS.MY_STATISTICS_CRM]: (<StatisticIcon fill="currentColor" width="15px"/>),
    [CRM_TABS.TEAM_ACCESS_RIGHTS]: (<ProtectionIcon fill="currentColor" width="15px"/>),
    [CRM_TABS.ACTIVITY]: <CalendarIcon fill="currentColor" width="15px"/>,
    [CRM_TABS.COURIER]: <EnvelopeIcon fill="currentColor" width="17px"/>,
};
const getTabIcon = (tab) => {
    if (tab.type === CONTACT_TAB_TYPE) {
        return (<Box color="blue.500" marginRight={1}>
        {TAB_ICON[CRM_TABS.CONTACT_LIST]}
      </Box>);
    }
    if (TAB_ICON[tab.id]) {
        return (<Box color="blue.500" marginRight={1}>
        {TAB_ICON[tab.id]}
      </Box>);
    }
    return null;
};
const CRMTabItem = (props) => {
    const { t } = useTranslation();
    const { tab } = props;
    const projects = useProjects();
    const contacts = useContacts();
    const getTabTitle = () => {
        if (tab.type === PROJECT_TAB_TYPE) {
            return projects[tab.id].label;
        }
        if (tab.type === CONTACT_TAB_TYPE) {
            return `${contacts[tab.id].firstname} ${contacts[tab.id].lastname}`;
        }
        return t(`tabs.extra_tabs.${tab.id}.tab_title`);
    };
    const title = getTabTitle();
    return (<TabItemWrapper {...props}>
      <HStack marginRight={1}>
        <Box>{getTabIcon(tab)}</Box>
        <TabItemTitle title={title}/>
      </HStack>
    </TabItemWrapper>);
};
export default CRMTabItem;
