import * as React from 'react';
import { HStack, Stack } from '@chakra-ui/react';
import { TABS_VIEW_HALF_VERTICAL } from 'context/LayoutContext';
import { useTabsView } from 'hooks/contexts/useLayoutContext';
const Header = ({ titleElement, headerExtend = null, }) => {
    const tabsView = useTabsView();
    return (<Stack spacing={2} justifyContent="space-between" direction={tabsView === TABS_VIEW_HALF_VERTICAL
            ? 'column'
            : { base: 'column', md: 'row' }}>
      <HStack flexWrap="wrap">{titleElement && titleElement}</HStack>
      <HStack>{headerExtend && headerExtend}</HStack>
    </Stack>);
};
export default Header;
