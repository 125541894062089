import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Link, Text } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_OPEN } from 'analytics/useAnalytics';
const MAP_LINK = 'http://carteprix.kelfoncier.com/';
const ThematicMapsRealEstateSidebarContent = ({ item, }) => {
    const { trackEvent } = useAnalytics();
    const { t } = useTranslation();
    return (<Box>
      <Text textStyle="paragraph" fontWeight={500}>
        {t(`sidebar_content.thematic_maps_real_estate.${item}.title`)}
      </Text>
      <Link href={MAP_LINK} variant="tertiary" isExternal onClick={() => {
            trackEvent({
                category: ANALYTICS_OPEN,
                action: 'Ouvrir la carte des prix dans l’ancien',
            });
        }}>
        {t(`sidebar_content.thematic_maps_real_estate.${item}.link`)}
      </Link>
    </Box>);
};
export default ThematicMapsRealEstateSidebarContent;
