import { useMemo } from 'react';
import { usePlotsHighlights } from 'hooks/contexts/useSecondaryPlotsContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import useHighlightedPlots from 'hooks/useHighlightedPlots';
import { filtersKey } from 'utils/filters';
const useHighlightedPlotsLayer = () => {
    const plotsHighlights = usePlotsHighlights();
    const { data: highlightedPlots } = useHighlightedPlots(plotsHighlights);
    const providedData = useMemo(() => {
        return highlightedPlots ? Object.values(highlightedPlots) : null;
    }, [highlightedPlots]);
    createLayersHook({
        providedData,
    })([filtersKey.HIGHLIGHTED_PLOTS]);
};
const HighlightedPlotsLayer = () => {
    useHighlightedPlotsLayer();
    return null;
};
export default HighlightedPlotsLayer;
