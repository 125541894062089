import * as React from 'react';
import { Box, Spinner, Text } from '@chakra-ui/react';
export const PlotInfoText = ({ value, label, id, isLoading = false, }) => {
    return (<Box data-cy={id}>
      <Text textStyle="paragraph" as="span" fontWeight={600}>
        {label}
        {' : '}
      </Text>
      {isLoading ? (<Spinner size="sm"/>) : (<Text textStyle="paragraph" as="span">
          {value}
        </Text>)}
    </Box>);
};
export default PlotInfoText;
