import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@chakra-ui/react';
import useCourierSender from 'hooks/courier/useCourierSender';
import AdvancedSelectInput from './AdvancedSelectInput';
const CourierSenderSelect = ({ name, disabled, setSender, selectRef, senderId, }) => {
    const { t } = useTranslation();
    const { courierSenders, isLoadingCourierSenders } = useCourierSender({});
    const sendersOptions = courierSenders?.map((sender) => ({
        value: sender.id,
        label: `${sender.civility} ${sender.firstname} ${sender.lastname}${sender.company ? ', ' + sender.company : ''} - ${sender.address} ${sender.postalCode} ${sender.town}`,
    }));
    React.useEffect(() => {
        if (!isLoadingCourierSenders && courierSenders?.length > 0) {
            const sender = courierSenders.find((courierSender) => courierSender.id === senderId);
            if (sender) {
                setSender(sender);
            }
            else {
                setSender(courierSenders[0]);
            }
        }
    }, [isLoadingCourierSenders]);
    if (isLoadingCourierSenders)
        return <Skeleton height="40px"/>;
    if (!isLoadingCourierSenders && !sendersOptions)
        return null;
    const getSenderInfo = (value) => {
        if (!value)
            return null;
        return courierSenders.filter((sender) => sender.id === value)[0];
    };
    return (<AdvancedSelectInput options={sendersOptions} name={name} placeholder={t('courier.sender.label')} isLoading={isLoadingCourierSenders} isDisabled={disabled} isClearable blurInputOnSelect onChange={(value) => {
            setSender(getSenderInfo(value));
        }} selectRef={selectRef} isDefaultSelected={true}/>);
};
export default CourierSenderSelect;
