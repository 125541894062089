import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Link } from '@chakra-ui/react';
import * as iots from 'io-ts';
import { formatDate } from 'utils/formatDate';
export const IOMRAEInfo = iots.type({
    id: iots.string,
    noticeDate: iots.string,
    power: iots.string,
    company: iots.string,
    type: iots.string,
    noticeCdpenaf: iots.string,
    sources: iots.string,
});
const MRAEInfoWindow = ({ info }) => {
    const { t } = useTranslation();
    if (!info) {
        return null;
    }
    return (<Box maxHeight="400px">
      {info.noticeDate && (<Box>
          {t('mrae.noticeDate')} : {formatDate(info.noticeDate)}
        </Box>)}
      {info.type && (<Box>
          {t('mrae.type.label')} : {info.type}
        </Box>)}
      {info.power && (<Box>
          {t('mrae.power')} : <b>{info.power} MWc</b>
        </Box>)}
      {info.company && (<Box>
          {t('mrae.company')} : <b>{info.company}</b>
        </Box>)}
      {info.noticeCdpenaf && (<Box>
          {t('mrae.noticeCdpenaf')} : {info.noticeCdpenaf}
        </Box>)}
      {info.sources && (<Box>
          <Link isExternal href={info.sources}>
            {t('mrae.source')}
          </Link>
        </Box>)}
    </Box>);
};
export default MRAEInfoWindow;
