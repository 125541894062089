import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getLayerCoordTileUrl } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = () => (coord, zoom) => {
    return getLayerCoordTileUrl('DELAISSES.AUTOROUTIERS', 'DELAISSES.AUTOROUTIERS', zoom, coord);
};
const getLegends = () => {
    const legends = {};
    legends.photovoltaic_not_prone_area_abandoned_motorways = {
        layerKey: filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_ABANDONED_MOTORWAYS,
        label: 'photovoltaic_not_prone_area_abandoned_motorways',
        needsTranslate: true,
        color: '#91522D',
        opacity: 1,
    };
    return legends;
};
const usePhotovoltaicProneAreaAbandonedMotorwaysLayer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_ABANDONED_MOTORWAYS],
        getLegends,
    })(filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_ABANDONED_MOTORWAYS);
};
const PhotovoltaicProneAreaAbandonedMotorwaysLayer = () => {
    usePhotovoltaicProneAreaAbandonedMotorwaysLayer();
    return null;
};
export default PhotovoltaicProneAreaAbandonedMotorwaysLayer;
