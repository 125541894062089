import * as React from 'react';
import PhotoList from 'components/document/PhotoList';
import useProjectFiles from 'hooks/crm/useProjectFiles';
import useProjectImages from 'hooks/useProjectImages';
const ProjectPhotoSection = ({ projectId, landPointId, isUserOwner = true, }) => {
    const { photos, deleteFile, addPhoto } = useProjectFiles(projectId, landPointId);
    const { data: photosFiles, isLoading: isLoadingPhoto } = useProjectImages(projectId, photos);
    return (<PhotoList projectId={projectId} photos={photos} deletePhoto={deleteFile} addPhoto={addPhoto} isLoadingPhoto={isLoadingPhoto} photosFiles={photosFiles} isUserOwner={isUserOwner}/>);
};
export default ProjectPhotoSection;
