import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@chakra-ui/react';
import FilterCategoryDocument from 'components/FilterCategoryDocument';
import RealEstateTaxesDrawer from 'components/modals/realEstateTaxesModal/RealEstateTaxesDrawer';
import { FROM_FILTER } from 'utils/constants';
import { formatNumberToLocale } from 'utils/formatNumber';
const RealEstateTaxesSidebarContent = ({ item, selectedTown, }) => {
    const { t, i18n } = useTranslation();
    return (<Box width="full">
      {item.towns.length === 1 && item.towns[0]?.townTax ? (<Text as="span" textStyle="paragraph">
          {`${t('sidebar_content.real_estate_taxes.town_tax')} ${formatNumberToLocale(parseFloat(item.towns[0].townTax), i18n.language)}`}
          &nbsp;%
        </Text>) : (<>
          <FilterCategoryDocument documentKeys={['realEstateTax']} from={FROM_FILTER}/>
          <Text as="span" textStyle="paragraph">
            {`${t('sidebar_content.real_estate_taxes.town_tax')} : `}
          </Text>
          {item.towns.map((town, index) => town.townTax !== 'ND' ? (<Text textStyle="paragraph" key={index}>
                {` - ${town.sector} : ${formatNumberToLocale(parseFloat(town.townTax), i18n.language)}`}
                &nbsp;%
              </Text>) : null)}
        </>)}
      <Box mb={2}>
        {item.departmentTax && item.departmentTax !== 'ND' && (<Text as="span" textStyle="paragraph">
            {`${t('sidebar_content.real_estate_taxes.department_tax')} ${formatNumberToLocale(parseFloat(item.departmentTax), i18n.language)}`}
            &nbsp;%
          </Text>)}
        {item.regionTax && item.regionTax !== 'ND' && (<Text as="span" textStyle="paragraph">
            &nbsp;
            {`${t('sidebar_content.real_estate_taxes.region_tax')} ${formatNumberToLocale(parseFloat(item.regionTax), i18n.language)}`}
            &nbsp;%
          </Text>)}
        {item.officeCreationTax && (<Text as="span" textStyle="paragraph">
            {`${t('sidebar_content.real_estate_taxes.office_creation_tax')} ${formatNumberToLocale(parseFloat(item.officeCreationTax), i18n.language)}`}
            &nbsp;%
          </Text>)}
      </Box>
      <RealEstateTaxesDrawer townName={selectedTown?.name}/>
    </Box>);
};
export default RealEstateTaxesSidebarContent;
