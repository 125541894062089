import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Text } from '@chakra-ui/react';
import ContactModal from 'components/modals/ContactModal';
import { HELPDESK_MESSAGE_TYPES } from 'utils/helpdesk';
const CreditHelp = () => {
    const { t } = useTranslation();
    return (<Text fontSize={'sm'} marginTop={1}>
      {t('courier.add_credit.no_credits')}
      <ContactModal title={t('courier.add_credit.modal_title')} type={HELPDESK_MESSAGE_TYPES.courier} triggerItem={(onOpen) => (<Link color="red.500" size="sm" onClick={onOpen}>
            {t(`info_window.help.write_us`)}
          </Link>)}/>
    </Text>);
};
export default CreditHelp;
