import * as React from 'react';
import { memo } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { IOLayerItem } from 'types/api';
import CompanyOwnedPlotsTable, { IOCompanyOwnedPlotsTableData, } from './CompanyOwnedPlotsTable';
const IOBankruptCompaniesPlotsData = iots.type({
    table: iots.array(IOCompanyOwnedPlotsTableData),
    layers: iots.record(iots.string, IOLayerItem),
});
export const fetchBankruptCompaniesPlots = (department) => () => get(IOBankruptCompaniesPlotsData, `${department}/bankrupt-companies-plots`);
const BankruptCompaniesPlotsPanel = () => {
    const selectedTown = useSelectedTown();
    const department = selectedTown?.department;
    const { data, isInitialLoading: isLoading } = useQuery({
        queryKey: ['bankrupt-companies-plots', department],
        queryFn: fetchBankruptCompaniesPlots(department),
        enabled: !!department,
    });
    return (<CompanyOwnedPlotsTable id="bankrupt-companies-plots" data={data?.table || []} isLoading={isLoading} category="bankrupt_companies_plots"/>);
};
export default memo(BankruptCompaniesPlotsPanel);
