import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOPrelocationLayerItem = IOLayerItem;
const fetchPrelocation = (townId) => () => get(iots.array(IOPrelocationLayerItem), `legacy/${townId}/prelocation`);
const usePrelocationLayer = () => {
    createLayersHook({
        fetch: fetchPrelocation,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.PRELOCATION);
};
const PrelocationLayer = () => {
    usePrelocationLayer();
    return null;
};
export default PrelocationLayer;
