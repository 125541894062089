import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getLayerCoordTileUrl } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = () => (coord, zoom) => {
    return getLayerCoordTileUrl('FORETS.PUBLIQUES', 'FORETS PUBLIQUES ONF', zoom, coord);
};
const getLegends = () => {
    const legends = {};
    legends.public_forest_no_domanial = {
        layerKey: filtersKey.PUBLIC_FOREST,
        label: 'public_forest_no_domanial',
        needsTranslate: true,
        color: '#94812b',
        borderColor: '#093604',
        borderWidth: 1,
        opacity: 1,
    };
    legends.public_forest_domanial = {
        layerKey: filtersKey.PUBLIC_FOREST,
        label: 'public_forest_domanial',
        needsTranslate: true,
        color: '#007F00',
        borderColor: '#093F09',
        borderWidth: 1,
        opacity: 1,
    };
    return legends;
};
const usePublicForestLayer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.PUBLIC_FOREST],
        getLegends,
    })(filtersKey.PUBLIC_FOREST);
};
const PublicForestLayer = () => {
    usePublicForestLayer();
    return null;
};
export default PublicForestLayer;
