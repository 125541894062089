import * as React from 'react';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CreatableSelect from 'react-select/creatable';
import { FormControl, FormErrorMessage, FormLabel, Input, } from '@chakra-ui/react';
import useTown from 'hooks/useTown';
const PostalCodeTownFields = ({ id, required, noLabel = false, townByContact = null, }) => {
    const { t } = useTranslation();
    const { setValue, watch, register, getValues, getFieldState } = useFormContext();
    const selectedTownValue = watch('town');
    const selectedCountryValue = watch('country');
    const watchPostalCodeValue = watch('postalCode');
    const [townsByPostalCode, setTownsByPostalCode] = useState([]);
    const [openMenuTown, setOpenMenuTown] = useState(false);
    const { error: postalCodeError } = getFieldState('postalCode');
    const { error: townError } = getFieldState('town');
    const { townsPostalCodes } = useTown();
    const searchByPostalCode = (postalCodeValue) => {
        const towns = townsPostalCodes[postalCodeValue]?.map((city) => {
            return {
                label: city,
                value: city,
            };
        });
        setTownsByPostalCode(towns);
    };
    useEffect(() => {
        if (townByContact) {
            setValue('town', townByContact);
        }
    }, [townByContact]);
    useEffect(() => {
        if (!townsPostalCodes) {
            return;
        }
        if (selectedTownValue) {
            setValue('town', selectedTownValue);
        }
        else {
            searchByPostalCode(getValues('postalCode'));
        }
        if (selectedCountryValue) {
            setValue('country', selectedCountryValue);
        }
    }, [townsPostalCodes]);
    return (<>
      <FormControl width="29%" isInvalid={Boolean(postalCodeError)}>
        {!noLabel && (<FormLabel marginInlineEnd={2}>
            {`${t('contact.fields.postalCode.label')} ${required ? '*' : ''}`}
          </FormLabel>)}
        <Input type="text" width="full" placeholder={`${t('contact.fields.postalCode.placeholder')} ${required ? '*' : ''}`} data-cy={`${id}_form_postalCode`} {...register('postalCode', {
        onChange: (e) => {
            const postaleCodeValue = e.target.value;
            if (postaleCodeValue.length == 5) {
                searchByPostalCode(postaleCodeValue);
                setOpenMenuTown(true);
            }
        },
    })} min={0} value={watchPostalCodeValue || ''}/>
        {Boolean(postalCodeError) && (<FormErrorMessage>{t(postalCodeError?.message)}</FormErrorMessage>)}
      </FormControl>

      <FormControl width="39%" fontSize={13} isInvalid={Boolean(townError)}>
        {!noLabel && (<FormLabel>
            {`${t('contact.fields.town.label')} ${required ? '*' : ''}`}
          </FormLabel>)}
        <CreatableSelect formatCreateLabel={(inputText) => `${inputText}`} name="town" loadingMessage={() => t('global.loading')} noOptionsMessage={() => t('global.no_options')} onChange={(selectedOption) => {
            if (selectedOption) {
                setValue('town', selectedOption.value);
            }
            else {
                setValue('town', '');
            }
            setOpenMenuTown(false);
        }} menuIsOpen={openMenuTown} onMenuOpen={() => setOpenMenuTown(true)} onMenuClose={() => setOpenMenuTown(false)} value={selectedTownValue
            ? {
                value: selectedTownValue,
                label: selectedTownValue,
            }
            : null} options={townsByPostalCode} placeholder={`${t('contact.fields.town.label')}  ${required ? '*' : ''}`} id="contact_form_town" styles={{
            control: (baseStyles) => ({
                ...baseStyles,
                borderColor: townError?.message && 'red',
            }),
        }} isClearable/>
        {Boolean(townError) && (<FormErrorMessage>{t(townError?.message)}</FormErrorMessage>)}
      </FormControl>
    </>);
};
export default PostalCodeTownFields;
