import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOEasementLayerItem = IOLayerItem;
const fetchEasement = (townId) => () => get(iots.array(IOEasementLayerItem), `legacy/${townId}/easement`);
const useEasementLayer = () => {
    createLayersHook({
        fetch: fetchEasement,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.EASEMENT);
};
const EasementLayer = () => {
    useEasementLayer();
    return null;
};
export default EasementLayer;
