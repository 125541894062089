import { useEffect, useRef } from 'react';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { useIsSecondarySelectionMode, useSelectedTown, } from 'hooks/contexts/useLocationContext';
import { useOpenPlotModal } from 'hooks/contexts/useModalContext';
import { useSelectedProject } from 'hooks/contexts/useTabsContext';
import useProject from 'hooks/crm/useProject';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOPolygonLayerItem, } from 'types/api';
import { filtersKey } from 'utils/filters';
const IOMapPlotItem = iots.intersection([
    IOPolygonLayerItem,
    iots.type({
        townId: iots.string,
    }),
], 'IOMapPlotItem');
// On passe currentTownId pour remplacer le townId car dans createLayersHook on récupère le townId courant mais
// dans les points foncier le town id du projet sélectionné peut-êter différent du town id courant */
export const fetchPlotsMapRadius = (townId, townScot, selectedTown, currentTownId, lat, lng, radius, forLandPoint) => () => get(iots.array(IOMapPlotItem), `plot/map-layers/${currentTownId}/${lat}/${lng}/${radius}`, { forLandPoint });
const useMapPlotsLayer = ({ map = null, preSelectedProject = null, }) => {
    const selectedProject = preSelectedProject
        ? preSelectedProject
        : useSelectedProject();
    const isSecondarySelectionMode = useIsSecondarySelectionMode();
    const { togglePlotInProject } = useProject();
    const selectedTown = useSelectedTown();
    const openPlotModal = useOpenPlotModal();
    const forLandPoint = !!preSelectedProject;
    const lat = selectedProject?.lat;
    const lng = selectedProject?.lng;
    const radius = 500;
    const townId = preSelectedProject
        ? preSelectedProject.townId
        : selectedTown?.id;
    const isSecondarySelectionModeRef = useRef(isSecondarySelectionMode);
    useEffect(() => {
        isSecondarySelectionModeRef.current = isSecondarySelectionMode;
    }, [isSecondarySelectionMode]);
    createLayersHook({
        fetch: fetchPlotsMapRadius,
        onClick: (item) => {
            if (isSecondarySelectionModeRef.current) {
                togglePlotInProject({
                    townshipId: item.townId,
                    projectId: selectedProject.id,
                    plotId: item.id,
                });
            }
            else if (preSelectedProject) {
                // Dans le cas de la modal point foncier on définie les info de la parcelel choisie pour la modal de PlotModal
                openPlotModal({
                    townshipId: item.townId,
                    plotId: item.id,
                });
            }
        },
        extraParams: [townId, lat, lng, radius, forLandPoint],
        disableQuery: (!selectedProject && !isSecondarySelectionMode) || !lat || !lng,
        currentMap: map,
        currentTownId: townId,
    })(filtersKey.MAP_PLOTS);
};
const MapPlotsLayer = ({ map = null, selectedProject = null, }) => {
    useMapPlotsLayer({ map, preSelectedProject: selectedProject });
    return null;
};
export default MapPlotsLayer;
