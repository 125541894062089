import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
const IOPlotCoOwnersInfo = iots.type({
    id: iots.number,
    address: iots.string,
    date: iots.string,
    AFULCount: iots.number,
    decreeCommonEquipmentCount: iots.number, // arrêté équipement en commun
    decreeDangerCount: iots.number, // arrêté péril
    decreePublicHealthCount: iots.number, // arrêté santé public
    constructionPeriod: iots.string,
    ASLCount: iots.number,
    lots: iots.number,
    housingLots: iots.number,
    housingCommercialOfficeLots: iots.number,
    parkingLots: iots.number,
    syndicateUnionCount: iots.number,
    legalRepresentative: iots.string,
    serviceResidence: iots.string,
    siret: iots.string,
});
const fetchPlotCoOwners = (plotId) => () => get(iots.array(IOPlotCoOwnersInfo), `legacy/plot-co-owner/${plotId}`);
export const QUERY_KEY = 'plot-co-owner';
export const getQueryKey = (plotId) => [QUERY_KEY, plotId];
const usePlotCoOwners = (plotId) => {
    const { data: coOwners, isInitialLoading } = useQuery({
        queryKey: getQueryKey(plotId),
        queryFn: fetchPlotCoOwners(plotId),
        enabled: !!plotId,
    });
    return { coOwners, isInitialLoading };
};
export default usePlotCoOwners;
