import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IORoadProjectCrossingLayerItem = IOLayerItem;
const fetchRoadProjectCrossing = (townId) => () => get(iots.array(IORoadProjectCrossingLayerItem), `legacy/${townId}/road-project-crossing`);
const useRoadProjectCrossingLayer = () => {
    createLayersHook({
        fetch: fetchRoadProjectCrossing,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.ROAD_PROJECT_CROSSING);
};
const RoadProjectCrossingLayer = () => {
    useRoadProjectCrossingLayer();
    return null;
};
export default RoadProjectCrossingLayer;
