import * as React from 'react';
import { Box, HStack } from '@chakra-ui/react';
import CarouselHome from 'components/CarouselHome';
import ForgottenPasswordForm from 'components/security/ForgottenPasswordForm';
const ForgottenPasswordPage = () => {
    return (<Box as="main" width="100%">
      <HStack spacing={0}>
        <Box width="50%" height="100vh">
          <CarouselHome />
        </Box>
        <Box width="50%">
          <ForgottenPasswordForm />
        </Box>
      </HStack>
    </Box>);
};
export default ForgottenPasswordPage;
