import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Link } from '@chakra-ui/react';
import * as iots from 'io-ts';
import { URL_SIREN } from 'components/links/SirenLink';
import { filtersKey } from 'utils/filters';
const IOBuildingPermitNewMarketCategory = iots.union([
    iots.literal(filtersKey.BUILDING_PERMIT_HOUSING),
    iots.literal(filtersKey.BUILDING_PERMIT_OFFICES),
    iots.literal(filtersKey.BUILDING_PERMIT_PLANNING_PERMIT),
]);
const IOBuildingPermitNewMarketStatus = iots.union([
    iots.literal('allowed_permit'),
    iots.literal('work_started'),
    iots.literal('work_done'),
    iots.literal('canceled_permit'),
]);
const IOBuildingPermitNewMarketOperatingArea = iots.union([
    iots.literal('housing'),
    iots.literal('zac'),
    iots.literal('afu'),
    iots.literal('not_defined'),
]);
const IOBuildingPermitNewMarketProjectType = iots.union([
    iots.literal('new_building'),
    iots.literal('existing_building'),
]);
const IOBuildingPermitNewMarketPublicServiceType = iots.union([
    iots.literal('transportation'),
    iots.literal('education'),
    iots.literal('health'),
    iots.literal('social_work'),
    iots.literal('special_work'),
    iots.literal('culture'),
    iots.literal('not_defined'),
    iots.literal('multiple'),
]);
const IOBuildingPermitNewMarketServicedApartmentsType = iots.union([
    iots.literal('senior'),
    iots.literal('student'),
    iots.literal('tourism'),
    iots.literal('social_tourism'),
    iots.literal('social'),
    iots.literal('disabled'),
    iots.literal('other'),
    iots.literal('mix'),
    iots.literal('not_defined'),
]);
const IOBuildingPermitNewMarketRequesterCategory = iots.union([
    iots.literal('private_individual_sai'),
    iots.literal('private_individual'),
    iots.literal('private_individual_sci'),
    iots.literal('social_landlord_sai'),
    iots.literal('hlm_body'),
    iots.literal('epl'),
    iots.literal('other_body'),
    iots.literal('property_developer_sai'),
    iots.literal('acknowledged_property_developer'),
    iots.literal('sci'),
    iots.literal('other_construction_company'),
    iots.literal('public_administration_sai'),
    iots.literal('state_odac'),
    iots.literal('departments_and_regions'),
    iots.literal('towns_epic_and_towns_syndicates'),
    iots.literal('other_company'),
    iots.literal('not_applicable'),
    iots.literal('not_defined'),
]);
const IOBuildingPermitNewMarketUseType = iots.union([
    iots.literal('not_defined'),
    iots.literal('personal_usage'),
    iots.literal('sale'),
    iots.literal('sale_and_rent'),
    iots.literal('rent'),
]);
export const IOBuildingPermitNewMarketInfo = iots.type({
    parentCategory: IOBuildingPermitNewMarketCategory,
    status: iots.union([IOBuildingPermitNewMarketStatus, iots.null]),
    maxLevel: iots.union([iots.number, iots.null]),
    realGrantDate: iots.union([iots.string, iots.null]),
    workStartedDate: iots.union([iots.string, iots.null]),
    workDoneDate: iots.union([iots.string, iots.null]),
    requesterName: iots.union([iots.string, iots.null]),
    requesterSiret: iots.union([iots.string, iots.null]),
    requesterAddress: iots.union([iots.string, iots.null]),
    requesterCategory: iots.union([
        IOBuildingPermitNewMarketRequesterCategory,
        iots.null,
    ]),
    lotAddress: iots.union([iots.string, iots.null]),
    landRegister: iots.array(iots.type({
        plotId: iots.number,
        lat: iots.number,
        lng: iots.number,
        townId: iots.number,
        cadastreId: iots.string,
    }, 'IOBuildingPermitNewMarketInfoPlot')),
    lotSurface: iots.union([iots.number, iots.null]),
    operatingArea: iots.union([
        IOBuildingPermitNewMarketOperatingArea,
        iots.null,
    ]),
    projectType: iots.union([IOBuildingPermitNewMarketProjectType, iots.null]),
    extension: iots.union([iots.boolean, iots.null]),
    elevation: iots.union([iots.boolean, iots.null]),
    additionalLevel: iots.union([iots.boolean, iots.null]),
    hotelSurface: iots.union([iots.number, iots.null]),
    hotelSurfaceBefore: iots.union([iots.number, iots.null]),
    hotelSurfaceCreatedByUseChange: iots.union([iots.number, iots.null]),
    hotelSurfaceDemolished: iots.union([iots.number, iots.null]),
    hotelSurfaceRemovedByUseChange: iots.union([iots.number, iots.null]),
    housingSurface: iots.union([iots.number, iots.null]),
    housingSurfaceBefore: iots.union([iots.number, iots.null]),
    housingSurfaceCreatedByUseChange: iots.union([iots.number, iots.null]),
    housingSurfaceDemolished: iots.union([iots.number, iots.null]),
    housingSurfaceRemovedByUseChange: iots.union([iots.number, iots.null]),
    officesSurface: iots.union([iots.number, iots.null]),
    officesSurfaceBefore: iots.union([iots.number, iots.null]),
    officesSurfaceCreatedByUseChange: iots.union([iots.number, iots.null]),
    officesSurfaceDemolished: iots.union([iots.number, iots.null]),
    officesSurfaceRemovedByUseChange: iots.union([iots.number, iots.null]),
    storesSurface: iots.union([iots.number, iots.null]),
    storesSurfaceBefore: iots.union([iots.number, iots.null]),
    storesSurfaceCreatedByUseChange: iots.union([iots.number, iots.null]),
    storesSurfaceDemolished: iots.union([iots.number, iots.null]),
    storesSurfaceRemovedByUseChange: iots.union([iots.number, iots.null]),
    craftworkSurface: iots.union([iots.number, iots.null]),
    craftworkSurfaceBefore: iots.union([iots.number, iots.null]),
    craftworkSurfaceCreatedByUseChange: iots.union([iots.number, iots.null]),
    craftworkSurfaceDemolished: iots.union([iots.number, iots.null]),
    craftworkSurfaceRemovedByUseChange: iots.union([iots.number, iots.null]),
    industrySurface: iots.union([iots.number, iots.null]),
    industrySurfaceBefore: iots.union([iots.number, iots.null]),
    industrySurfaceCreatedByUseChange: iots.union([iots.number, iots.null]),
    industrySurfaceDemolished: iots.union([iots.number, iots.null]),
    industrySurfaceRemovedByUseChange: iots.union([iots.number, iots.null]),
    farmingSurface: iots.union([iots.number, iots.null]),
    farmingSurfaceBefore: iots.union([iots.number, iots.null]),
    farmingSurfaceCreatedByUseChange: iots.union([iots.number, iots.null]),
    farmingSurfaceDemolished: iots.union([iots.number, iots.null]),
    farmingSurfaceRemovedByUseChange: iots.union([iots.number, iots.null]),
    storageSurface: iots.union([iots.number, iots.null]),
    storageSurfaceBefore: iots.union([iots.number, iots.null]),
    storageSurfaceCreatedByUseChange: iots.union([iots.number, iots.null]),
    storageSurfaceDemolished: iots.union([iots.number, iots.null]),
    storageSurfaceRemovedByUseChange: iots.union([iots.number, iots.null]),
    publicServiceSurface: iots.union([iots.number, iots.null]),
    publicServiceSurfaceBefore: iots.union([iots.number, iots.null]),
    publicServiceSurfaceCreatedByUseChange: iots.union([iots.number, iots.null]),
    publicServiceSurfaceDemolished: iots.union([iots.number, iots.null]),
    publicServiceSurfaceRemovedByUseChange: iots.union([iots.number, iots.null]),
    publicServiceType: iots.union([
        IOBuildingPermitNewMarketPublicServiceType,
        iots.null,
    ]),
    nbRooms: iots.union([iots.number, iots.null]),
    totalCreatedHousing: iots.union([iots.number, iots.null]),
    totalCreatedCollectiveHousing: iots.union([iots.number, iots.null]),
    totalCreatedIndividualHousing: iots.union([iots.number, iots.null]),
    totalCreatedSocialRentalHousing: iots.union([iots.number, iots.null]),
    totalCreatedSupportiveHousing: iots.union([iots.number, iots.null]),
    totalCreatedPTZHousing: iots.union([iots.number, iots.null]),
    servicedApartmentsType: iots.union([
        IOBuildingPermitNewMarketServicedApartmentsType,
        iots.null,
    ]),
    oneRoomHousing: iots.union([iots.number, iots.null]),
    twoRoomsHousing: iots.union([iots.number, iots.null]),
    threeRoomsHousing: iots.union([iots.number, iots.null]),
    fourRoomsHousing: iots.union([iots.number, iots.null]),
    fiveRoomsHousing: iots.union([iots.number, iots.null]),
    sixRoomsAndMoreHousing: iots.union([iots.number, iots.null]),
    demolishedHousing: iots.union([iots.number, iots.null]),
    useType: iots.union([IOBuildingPermitNewMarketUseType, iots.null]),
    buildingPermitId: iots.string,
    median: iots.union([iots.number, iots.null]),
    propertyDeveloper: iots.union([iots.string, iots.null]),
    date: iots.union([iots.string, iots.null]),
    exitPrice: iots.union([iots.number, iots.null]),
    salePrice: iots.union([iots.number, iots.null]),
    saleDate: iots.union([iots.string, iots.null]),
    computedPropertyTaxes: iots.union([iots.number, iots.null]),
});
const PropertyTaxesForm = ({ info, }) => {
    const { t } = useTranslation();
    const salePrice = info.salePrice?.toString() || '0';
    const median = info.median?.toString() || '0';
    const surface = info.housingSurface?.toString() || '0';
    const exitPrice = info.exitPrice?.toString() || '0';
    const computedPropertyTaxes = info.computedPropertyTaxes?.toString() || '0';
    if (info.parentCategory !== filtersKey.BUILDING_PERMIT_HOUSING) {
        return null;
    }
    return (<>
      <tr>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <td colSpan={2}>
          {t('info_window.building_permit_new_market.estimated_financial_statement')}
        </td>
      </tr>
      <tr>
        <td>
          <b>{t('info_window.building_permit_new_market.median')}</b>
        </td>
        <td style={{ textAlign: 'left' }}>
          <input id="median-input" type="text" style={{
            fontWeight: 'bold',
            border: '1px solid gray',
            color: 'black',
        }} className="info-window-field" defaultValue={median}/>
          <label style={{ display: 'inline-block', paddingLeft: '3px' }}>
            <b> € par m²</b>
          </label>
        </td>
      </tr>
      <tr>
        <td>
          {t('info_window.building_permit_new_market.property_developer')}
        </td>
        <td>{info.propertyDeveloper}</td>
      </tr>
      <tr>
        <td>{t('info_window.building_permit_new_market.date')}</td>
        <td>{info.date}</td>
      </tr>
      <tr>
        <td>
          <b>{t('info_window.building_permit_new_market.housing_surface')}</b>
        </td>
        <td style={{ textAlign: 'left' }}>
          <input id="surface-input" type="text" style={{ fontWeight: 'bold' }} className="info-window-field" defaultValue={surface} disabled/>
          <label style={{ display: 'inline-block', paddingLeft: '3px' }}>
            <b>m²</b>
          </label>
        </td>
      </tr>
      <tr>
        <td>
          <b>{t('info_window.building_permit_new_market.exit_price')}</b>
        </td>
        <td style={{ verticalAlign: 'top' }}>
          <input id="exit-price-input" type="text" style={{ fontWeight: 'bold' }} className="info-window-field" defaultValue={exitPrice} disabled/>
          <label style={{ display: 'inline-block', paddingLeft: '3px' }}>
            <b>€</b>
          </label>
        </td>
      </tr>
      <tr>
        <td>
          {t('info_window.building_permit_new_market.price_time_surface')}
        </td>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <td>
          <b>{t('info_window.building_permit_new_market.sale_price')}</b>
        </td>
        <td>
          <input id="sale-price-input" type="text" style={{
            fontWeight: 'bold',
            border: '1px solid gray',
            color: 'black',
        }} className="info-window-field" defaultValue={salePrice}/>
          <label style={{ display: 'inline-block', paddingLeft: '3px' }}>
            <b>€</b>
          </label>
        </td>
      </tr>
      <tr>
        <td>{t('info_window.building_permit_new_market.sale_date')}</td>
        <td>{info.saleDate}</td>
      </tr>
      <tr>
        <td>
          <b>Charge foncière</b>
        </td>
        <td>
          <input id="computed-property-taxes-input" type="text" style={{ fontWeight: 'bold' }} className="info-window-field" defaultValue={computedPropertyTaxes} disabled/>
          <label style={{ display: 'inline-block' }}>
            <b>%</b>
          </label>
        </td>
      </tr>
      <tr>
        <td>
          {t('info_window.building_permit_new_market.sale_price_on_exit_price')}
        </td>
        <td>&nbsp;</td>
      </tr>
    </>);
};
const BuildingPermitNewMarketInfoWindow = ({ info }) => {
    const { t } = useTranslation();
    const residenceType = [
        'disabled',
        'mix',
        'other',
        'senior',
        'social',
        'social_tourism',
        'student',
        'tourism',
    ];
    const getTr = (translateKey, showIfEmpty, value, type, unit, style) => {
        if (!showIfEmpty && !value) {
            return;
        }
        return (<tr>
        <td>
          {style === 'bold' ? (<b>{t(`info_window.building_permit_new_market.${translateKey}`)}</b>) : (t(`info_window.building_permit_new_market.${translateKey}`))}
        </td>
        <td style={{ textAlign: 'left' }}>
          {style === 'bold' ? (<b>{getValue(value, type, unit)}</b>) : (getValue(value, type, unit))}
        </td>
      </tr>);
    };
    const getValue = (value, type, unit) => {
        if (type === 'number') {
            if (value === null) {
                return 0;
            }
            else {
                return value + ' ' + unit;
            }
        }
        if (type === 'date' || type === 'string') {
            if (value === null) {
                return t('global.unknown');
            }
            else {
                return value;
            }
        }
        if (value === null) {
            return t('global.no');
        }
        else {
            return t('global.yes');
        }
    };
    if (!info) {
        return null;
    }
    return (<Box maxHeight="400px">
      <table>
        <tr>
          <td>
            {t('info_window.building_permit_new_market.permit_type.title')}
          </td>
          <td>
            {t(`info_window.building_permit_new_market.permit_type.${info.parentCategory}`)}
          </td>
        </tr>
        {info.status && (<tr>
            <td>{t('info_window.building_permit_new_market.status.title')}</td>
            <td>
              {t(`info_window.building_permit_new_market.status.${info.status}`)}
            </td>
          </tr>)}
        {info.parentCategory === filtersKey.BUILDING_PERMIT_OFFICES && (<>
            {getTr('hotel_surface', false, info.hotelSurface, 'number', 'm²', '')}
            {getTr('offices_surface', false, info.officesSurface, 'number', 'm²', 'bold')}
            {getTr('stores_surface', false, info.storesSurface, 'number', 'm²', 'bold')}
            {getTr('craftwork_surface', false, info.craftworkSurface, 'number', 'm²', '')}
            {getTr('industry_surface', false, info.industrySurface, 'number', 'm²', '')}
            {getTr('farming_surface', false, info.farmingSurface, 'number', 'm²', '')}
            {getTr('storage_surface', false, info.storageSurface, 'number', 'm²', '')}
            {getTr('public_service_surface', false, info.publicServiceSurface, 'number', 'm²', '')}
          </>)}
        {info.parentCategory === filtersKey.BUILDING_PERMIT_HOUSING && (<>
            {getTr('total_created_housing', false, info.totalCreatedHousing, 'number', '', 'bold')}
            {getTr('total_created_collective_housing', false, info.totalCreatedCollectiveHousing, 'number', '', '')}
            {getTr('total_created_individual_housing', false, info.totalCreatedIndividualHousing, 'number', '', '')}
            {getTr('total_created_social_rental_housing', false, info.totalCreatedSocialRentalHousing, 'number', '', '')}
            {getTr('total_created_supportive_housing', false, info.totalCreatedSupportiveHousing, 'number', '', '')}
            {getTr('total_created_ptz_housing', false, info.totalCreatedPTZHousing, 'number', '', '')}
            {getTr('housing_surface', false, info.housingSurface, 'number', 'm²', 'bold')}
          </>)}
        {info.parentCategory !== filtersKey.BUILDING_PERMIT_PLANNING_PERMIT &&
            getTr('max_level', false, info.maxLevel, 'number', '', '')}
        {getTr(info.status === 'canceled_permit'
            ? 'cancellation_date'
            : 'initial_grant_date', false, info.realGrantDate, 'date', '', '')}
        {getTr('work_started_date', false, info.workStartedDate, 'date', '', '')}
        {getTr('work_done_date', false, info.workDoneDate, 'date', '', '')}
        {getTr('requester_name', false, info.requesterName, 'string', '', 'bold')}
        {info.parentCategory === filtersKey.BUILDING_PERMIT_HOUSING &&
            info.requesterCategory && (<tr>
              <td>
                {t('info_window.building_permit_new_market.requester_category.title')}
              </td>
              <td>
                {t(`info_window.building_permit_new_market.requester_category.${info.requesterCategory}`)}
              </td>
            </tr>)}
        {info.requesterSiret && (<tr>
            <td>
              {t('info_window.building_permit_new_market.requester_siret')}
            </td>
            <td>
              <Link href={URL_SIREN + info.requesterSiret.substring(0, 9)} target="_blank" rel="noopener noreferrer">
                {info.requesterSiret}
              </Link>
            </td>
          </tr>)}
        {getTr('requester_address', false, info.requesterAddress, 'string', '', '')}
        {info.landRegister.length > 0 && (<tr>
            <td>{t('info_window.building_permit_new_market.land_register')}</td>
            <td>
              {info.landRegister.map((plot) => {
                return (<div key={plot.plotId}>
                    <a rel="noopener noreferrer" target="_blank" href={`/parcelle/${plot.townId}/${plot.lat}/${plot.lng}/${plot.plotId}`}>
                      {plot.cadastreId}
                    </a>
                  </div>);
            })}
            </td>
          </tr>)}
        {getTr('lot_address', false, info.lotAddress, 'string', '', '')}
        {getTr('lot_surface', false, info.lotSurface, 'number', 'm²', '')}
        {info.operatingArea && (<tr>
            <td>
              {t('info_window.building_permit_new_market.operating_area.title')}
            </td>
            <td>
              {t(`info_window.building_permit_new_market.operating_area.${info.operatingArea}`)}
            </td>
          </tr>)}
        {info.parentCategory !== filtersKey.BUILDING_PERMIT_PLANNING_PERMIT && (<>
            {info.projectType && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.project_type.title')}
                </td>
                <td>
                  {t(`info_window.building_permit_new_market.project_type.${info.projectType}`)}
                </td>
              </tr>)}
          </>)}
        {info.parentCategory === filtersKey.BUILDING_PERMIT_OFFICES && (<>
            {getTr('hotel_surface_before', false, info.hotelSurfaceBefore, 'number', 'm²', '')}
            {getTr('hotel_surface_created_by_use_change', false, info.hotelSurfaceCreatedByUseChange, 'number', 'm²', '')}
            {getTr('hotel_surface_demolished', false, info.hotelSurfaceDemolished, 'number', 'm²', '')}
            {getTr('hotel_surface_removed_by_use_change', false, info.hotelSurfaceRemovedByUseChange, 'number', 'm²', '')}
            {getTr('offices_surface_before', false, info.officesSurfaceBefore, 'number', 'm²', '')}
            {getTr('offices_surface_created_by_use_change', false, info.officesSurfaceCreatedByUseChange, 'number', 'm²', '')}
            {getTr('offices_surface_demolished', false, info.officesSurfaceDemolished, 'number', 'm²', '')}
            {getTr('offices_surface_removed_by_use_change', false, info.officesSurfaceRemovedByUseChange, 'number', 'm²', '')}
            {getTr('stores_surface_before', false, info.storesSurfaceBefore, 'number', 'm²', '')}
            {getTr('stores_surface_created_by_use_change', false, info.storesSurfaceCreatedByUseChange, 'number', 'm²', '')}
            {getTr('stores_surface_demolished', false, info.storesSurfaceDemolished, 'number', 'm²', '')}
            {getTr('stores_surface_removed_by_use_change', false, info.storesSurfaceRemovedByUseChange, 'number', 'm²', '')}
            {getTr('craftwork_surface_before', false, info.craftworkSurfaceBefore, 'number', 'm²', '')}
            {getTr('craftwork_surface_created_by_use_change', false, info.craftworkSurfaceCreatedByUseChange, 'number', 'm²', '')}
            {getTr('craftwork_surface_demolished', false, info.craftworkSurfaceDemolished, 'number', 'm²', '')}
            {getTr('craftwork_surface_removed_by_use_change', false, info.craftworkSurfaceRemovedByUseChange, 'number', 'm²', '')}
            {getTr('industry_surface_before', false, info.industrySurfaceBefore, 'number', 'm²', '')}
            {getTr('industry_surface_created_by_use_change', false, info.industrySurfaceCreatedByUseChange, 'number', 'm²', '')}
            {getTr('industry_surface_demolished', false, info.industrySurfaceDemolished, 'number', 'm²', '')}
            {getTr('industry_surface_removed_by_use_change', false, info.industrySurfaceRemovedByUseChange, 'number', 'm²', '')}
            {getTr('farming_surface_before', false, info.farmingSurfaceBefore, 'number', 'm²', '')}
            {getTr('farming_surface_created_by_use_change', false, info.farmingSurfaceCreatedByUseChange, 'number', 'm²', '')}
            {getTr('farming_surface_demolished', false, info.farmingSurfaceDemolished, 'number', 'm²', '')}
            {getTr('farming_surface_removed_by_use_change', false, info.farmingSurfaceRemovedByUseChange, 'number', 'm²', '')}
            {getTr('storage_surface_before', false, info.storageSurfaceBefore, 'number', 'm²', '')}
            {getTr('storage_surface_created_by_use_change', false, info.storageSurfaceCreatedByUseChange, 'number', 'm²', '')}
            {getTr('storage_surface_demolished', false, info.storageSurfaceDemolished, 'number', 'm²', '')}
            {getTr('storage_surface_removed_by_use_change', false, info.storageSurfaceRemovedByUseChange, 'number', 'm²', '')}
            {getTr('public_service_surface_before', false, info.publicServiceSurfaceBefore, 'number', 'm²', '')}
            {getTr('public_service_surface_created_by_use_change', false, info.publicServiceSurfaceCreatedByUseChange, 'number', 'm²', '')}
            {getTr('public_service_surface_demolished', false, info.publicServiceSurfaceDemolished, 'number', 'm²', '')}
            {getTr('public_service_surface_removed_by_use_change', false, info.publicServiceSurfaceRemovedByUseChange, 'number', 'm²', '')}
            <tr>
              <td>
                {t('info_window.building_permit_new_market.public_service_type.title')}
              </td>
              <td>
                {t(`info_window.building_permit_new_market.public_service_type.${info.publicServiceType}`)}
              </td>
            </tr>
            {getTr('nb_rooms', false, info.nbRooms, 'number', '', '')}
          </>)}
        {info.parentCategory === filtersKey.BUILDING_PERMIT_HOUSING && (<>
            {getTr('one_room_housing', true, info.oneRoomHousing, 'number', '', '')}
            {getTr('two_rooms_housing', true, info.twoRoomsHousing, 'number', '', '')}
            {getTr('three_rooms_housing', true, info.threeRoomsHousing, 'number', '', '')}
            {getTr('four_rooms_housing', true, info.fourRoomsHousing, 'number', '', '')}
            {getTr('five_rooms_housing', true, info.fiveRoomsHousing, 'number', '', '')}
            {getTr('six_rooms_and_more_housing', true, info.sixRoomsAndMoreHousing, 'number', '', '')}
            {getTr('demolished_housing', false, info.demolishedHousing, 'number', '', '')}
            {getTr('housing_surface_before', false, info.housingSurfaceBefore, 'number', 'm²', '')}
            {getTr('housing_surface_created_by_use_change', false, info.housingSurfaceCreatedByUseChange, 'number', 'm²', '')}
            {getTr('housing_surface_demolished', false, info.housingSurfaceDemolished, 'number', 'm²', '')}
            {getTr('housing_surface_removed_by_use_change', false, info.housingSurfaceRemovedByUseChange, 'number', 'm²', '')}
            {info.parentCategory === filtersKey.BUILDING_PERMIT_HOUSING &&
                residenceType.map((type) => {
                    return (<tr key={type}>
                    <td>
                      {t(`info_window.building_permit_new_market.serviced_apartments_type.${type}`)}
                    </td>
                    <td>
                      {type === info.servicedApartmentsType
                            ? t('global.yes')
                            : t('global.no')}
                    </td>
                  </tr>);
                })}
            {info.useType && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.use_type.title')}
                </td>
                <td>
                  {t(`info_window.building_permit_new_market.use_type.${info.useType}`)}
                </td>
              </tr>)}
          </>)}
        {getTr('extension', false, info.extension, 'boolean', '', '')}
        {getTr('elevation', false, info.elevation, 'boolean', '', '')}
        {getTr('additional_level', false, info.additionalLevel, 'boolean', '', '')}
        <tr>
          <td>
            {t('info_window.building_permit_new_market.building_permit_id')}
          </td>
          <td>{info.buildingPermitId}</td>
        </tr>
        {info.parentCategory === filtersKey.BUILDING_PERMIT_HOUSING && (<PropertyTaxesForm info={info}/>)}
      </table>
    </Box>);
};
export default BuildingPermitNewMarketInfoWindow;
