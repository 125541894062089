import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { post } from 'api/client';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { HELPDESK_MESSAGE_TYPES } from 'utils/helpdesk';
import useCustomToast from './useCustomToast';
const useSendEmail = ({ onSuccess, onError }) => {
    const { t } = useTranslation();
    const selectedTown = useSelectedTown();
    const { getSuccessToast } = useCustomToast();
    const extraData = {
        townshipId: selectedTown?.id,
        township: selectedTown?.name,
    };
    const { mutate: sendMessage, isPending: isSendingMessage } = useMutation({
        mutationFn: (data) => {
            const messageData = {
                ...data,
                ...(data?.type === HELPDESK_MESSAGE_TYPES.plu ||
                    data?.type === HELPDESK_MESSAGE_TYPES.ask_plu
                    ? extraData
                    : {}),
            };
            return post(null, `legacy/mail-contact`, messageData);
        },
        onError: () => {
            !!onError && onError();
        },
        onSuccess: () => {
            getSuccessToast({
                title: t('contact_modal.success'),
                id: 'contact_modal_success',
            });
            !!onSuccess && onSuccess();
        },
    });
    return {
        sendMessage,
        isSendingMessage,
    };
};
export default useSendEmail;
