import * as React from 'react';
import { useCallback, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as iots from 'io-ts';
import { get } from 'api/client';
import DREALInfoWindow, { IODREALInfo, } from 'components/infoWindow/DREALInfoWindow';
import { useExtraFilters } from 'hooks/contexts/useFiltersContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import DREALImg from 'images/POIImages/Pin_DREAL.png';
import { IOMarkerLayerItem } from 'types/api';
import { filtersKey, LAST_SIX_MONTHS } from 'utils/filters';
export const IODREALMarkerItem = iots.intersection([
    IOMarkerLayerItem,
    iots.type({
        id: iots.string,
        date: iots.string,
    }),
], 'IODREALMarkerItem');
const fetchDREAL = () => () => get(iots.array(IODREALMarkerItem), `dreal`);
const fetchDREALInfo = (item) => {
    return get(IODREALInfo, `dreal-info/${item.id}`);
};
export const formatInfoWindowContent = (info) => {
    const content = <DREALInfoWindow info={info}/>;
    return ReactDOMServer.renderToStaticMarkup(content);
};
export const isTimePeriodAllowed = (marker, timePeriod) => {
    if (!timePeriod) {
        return true;
    }
    const date = new Date(marker.date);
    if (timePeriod === LAST_SIX_MONTHS) {
        const now = new Date();
        const diff = now.getTime() - date.getTime();
        const monthDiff = diff / (1000 * 3600 * 24 * 30);
        return monthDiff <= 6;
    }
    return date.getFullYear() === timePeriod;
};
const useDREALLayer = () => {
    const extraFilters = useExtraFilters();
    const [markerInfo, setMarkerInfo] = useState({});
    const allowMarker = useCallback((marker) => {
        const date = extraFilters?.[filtersKey.DREAL]?.timePeriod ?? null;
        return isTimePeriodAllowed(marker, date);
    }, [extraFilters]);
    const getInfoWindowContent = async (marker) => {
        if (markerInfo[marker.id]) {
            return markerInfo[marker.id];
        }
        const drealInfo = await fetchDREALInfo(marker);
        const content = formatInfoWindowContent(drealInfo);
        setMarkerInfo({
            ...markerInfo,
            [marker.id]: content,
        });
        return content;
    };
    createLayersHook({
        fetch: fetchDREAL,
        markerImg: DREALImg,
        getInfoWindowContent,
        allowLayer: allowMarker,
    })(filtersKey.DREAL);
};
const DREALLayer = () => {
    useDREALLayer();
    return null;
};
export default DREALLayer;
