import { useCallback, useMemo } from 'react';
import { filtersKey } from 'utils/filters';
import { getLayerCoordTileUrl, getNormalizedCoordinates } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
import { useFilters } from 'hooks/contexts/useFiltersContext';
import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
const getLayerTileUrl = (map, tileWidth, tileHeight, year) => (coord, zoom) => {
    const normCoord = getNormalizedCoordinates(coord, zoom);
    if (!normCoord) {
        return null;
    }
    return getLayerCoordTileUrl('LANDUSE.AGRICULTURE' + year, 'normal', zoom, coord);
};
const getLegends = (enabledFilters) => {
    const legends = {};
    if (enabledFilters.length === 0) {
        return legends;
    }
    const colors = [
        '#FFFFFF',
        '#fdfe95',
        '#7df632',
        '#f2fd6d',
        '#dde930',
        '#faecb5',
        '#fefd35',
        '#f1c328',
        '#e4b524',
        '#b3931b',
        '#FFFFFF',
        '#f0f0f0',
        '#FFFFFF',
        '#FFFFFF',
        '#9ab5fc',
        '#eea57f',
        '#a9c463',
        '#c0e274',
        '#cefb6b',
        '#e7fdb6',
        '#e3390d',
        '#c83ae1',
        '#3c7d14',
        '#a0a51f',
        '#3e7e81',
        '#eea7cd',
        '#1c21fb',
        '#FFFFFF',
        '#731d7d',
    ];
    const length = colors.length;
    for (let i = 1; i < length; i++) {
        if (colors[i] !== '#FFFFFF') {
            legends[`farming_${i}`] = {
                layerKey: enabledFilters[0],
                label: `farming_${i}`,
                needsTranslate: true,
                color: colors[i],
                opacity: 0.8,
            };
        }
    }
    return legends;
};
const usePhotovoltaicNotProneAreaRpgTiles = (year) => {
    const filterKey = `${filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG}_${year}`;
    const filters = useFilters();
    const enabledMap = useMemo(() => filters[filterKey] || null, [filters]);
    const getTileUrl = useCallback((map, tileWidth, tileHeight) => getLayerTileUrl(map, tileWidth, tileHeight, year), [enabledMap]);
    createTilesLayerHook({
        getLayerTileUrl: getTileUrl,
        position: CST_POSITIONS[filterKey],
        refreshParam: enabledMap,
        getLegends,
        opacity: 0.42,
    })(filterKey);
};
const PhotovoltaicNotProneAreaRpgTiles = ({ year }) => {
    usePhotovoltaicNotProneAreaRpgTiles(year);
    return null;
};
export default PhotovoltaicNotProneAreaRpgTiles;
