import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@chakra-ui/react';
import { ANALYTICS_MORE_INFO } from 'analytics/useAnalytics';
import SeeMoreAccordion from 'components/SeeMoreAccordion';
import { FILTER_PADDING_X, FULL_WIDTH_SECTIONS } from './FiltersTree';
export const CATEGORIES_TO_TRACK = [
    'airborne_noise',
    'environmental_protection',
];
const FilterCategoryDisclosure = ({ title, value, parentCategory }) => {
    const { t } = useTranslation();
    const text = t(value);
    return (<Box paddingX={FULL_WIDTH_SECTIONS?.includes(parentCategory)
            ? FILTER_PADDING_X
            : undefined}>
      <SeeMoreAccordion title={title} analyticDetail={CATEGORIES_TO_TRACK?.includes(parentCategory)
            ? `${t('sidebar.categories.' + parentCategory)} ${t(title)}`
            : undefined} analyticOrigin={ANALYTICS_MORE_INFO}>
        {text.split('\n\n').map((part, key) => {
            return (<Text textStyle="paragraph" marginBottom={2} key={key}>
              {part.split('\n').map((i, index) => (<React.Fragment key={index}>
                  {i}
                  <br />
                </React.Fragment>))}
            </Text>);
        })}
      </SeeMoreAccordion>
    </Box>);
};
export default FilterCategoryDisclosure;
