import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
const IONews = iots.intersection([
    iots.type({
        id: iots.string,
        title: iots.string,
        content: iots.string,
        url: iots.string,
        date: iots.string,
    }),
    iots.partial({
        unread: iots.boolean,
        image: iots.string,
        urlText: iots.string,
    }),
], 'IONews');
export const QUERY_KEY = ['news'];
const fetchNews = () => () => get(iots.array(IONews), 'news');
const useNews = () => {
    return useQuery({ queryKey: QUERY_KEY, queryFn: fetchNews() });
};
export default useNews;
