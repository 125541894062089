import * as React from 'react';
import { memo } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { post } from 'api/client';
import { useExtraFilters } from 'hooks/contexts/useFiltersContext';
import { filtersKey } from 'utils/filters';
import CompaniesOwnedByManagerTable from './CompaniesOwnedByManagerTable';
const IOCompaniesOwnedByManagerData = iots.type({
    id: iots.string,
    siren: iots.string,
    name: iots.string,
    department: iots.string,
    town: iots.string,
    role: iots.union([iots.string, iots.null]),
    countOwnedPlots: iots.number,
});
export const fetchCompaniesOwnedByManager = (managerId) => () => post(iots.array(IOCompaniesOwnedByManagerData), `companies-owned-by-manager`, { managerId });
const CompaniesOwnedByManagerPanel = () => {
    const extraFilters = useExtraFilters();
    const managerId = extraFilters[filtersKey.COMPANY_OWNED_MANAGER]?.manager?.id ?? null;
    const { data, isInitialLoading: isLoading } = useQuery({
        queryKey: ['companies-owned-by-manager', { managerId }],
        queryFn: fetchCompaniesOwnedByManager(managerId),
        enabled: !!managerId,
    });
    return (<CompaniesOwnedByManagerTable data={data || []} name={extraFilters[filtersKey.COMPANY_OWNED_MANAGER]?.manager?.name} firstname={extraFilters[filtersKey.COMPANY_OWNED_MANAGER]?.manager?.firstname} isLoading={isLoading}/>);
};
export default memo(CompaniesOwnedByManagerPanel);
