import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import fireHydrantsImg from 'images/fire_hydrants.png';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
const IOPPRFireHydrantsLayerItem = IOLayerItem;
const fetchPPRFireHydrants = (townId) => () => get(iots.array(IOPPRFireHydrantsLayerItem), `legacy/${townId}/ppr-fire-hydrants`);
const usePPRFireHydrantsLayer = () => {
    createLayersHook({
        fetch: fetchPPRFireHydrants,
        markerImg: fireHydrantsImg,
    })(filtersKey.PPR_FIRE_HYDRANTS);
};
const PPRFireHydrantsLayer = () => {
    usePPRFireHydrantsLayer();
    return null;
};
export default PPRFireHydrantsLayer;
