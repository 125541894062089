import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOUrbanScotLayerItem = IOLayerItem;
const fetchUrbanScotLayer = (townId, townScot) => () => get(iots.array(IOUrbanScotLayerItem), `legacy/${townId}/urban-scot/${townScot}`);
const useUrbanScotLayer = () => {
    createLayersHook({
        fetch: fetchUrbanScotLayer,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.URBAN_SCOT);
};
const UrbanScotLayer = () => {
    useUrbanScotLayer();
    return null;
};
export default UrbanScotLayer;
