import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AsyncSelect from 'react-select/async';
import { Box, FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import * as iots from 'io-ts';
import debounce from 'lodash/debounce';
import { get } from 'api/client';
const IOTownResult = iots.type({
    id: iots.number,
    name: iots.string,
    lat: iots.number,
    lng: iots.number,
    postCode: iots.number,
});
const fetchTowns = debounce((inputValue, callback) => {
    get(iots.array(IOTownResult), `towns`, {
        search: inputValue,
    }).then((response) => {
        callback(response.map((town) => ({
            value: town.id,
            label: `${town.name} (${town.postCode})`,
        })));
    });
    return;
}, 500);
const getOptions = (inputValue, callback) => {
    if (!inputValue || inputValue === '') {
        return Promise.resolve([]);
    }
    fetchTowns(inputValue, callback);
};
const TownSelect = ({ name, label, placeholder, disabled, }) => {
    const { t } = useTranslation();
    const { getValues, setValue, getFieldState } = useFormContext();
    const { error } = getFieldState(name);
    return (<Box width="full">
      {label && <FormLabel>{t(label)}</FormLabel>}
      <FormControl isInvalid={Boolean(error)} fontSize={13}>
        <AsyncSelect cacheOptions defaultOptions loadOptions={getOptions} defaultValue={getValues(name)} placeholder={t(placeholder || 'global.select')} noOptionsMessage={() => t('global.no_result')} onChange={(town) => {
            setValue(name, town?.value, { shouldValidate: true });
        }} blurInputOnSelect isDisabled={disabled}/>
        {Boolean(error) && (<FormErrorMessage>{error?.message}</FormErrorMessage>)}
      </FormControl>
    </Box>);
};
export default TownSelect;
