import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getLayerCoordTileUrl } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = () => (coord, zoom) => {
    return getLayerCoordTileUrl('ENR.PERIMETRE.PENTE', 'PERIMETRE.PENTE', zoom, coord);
};
const getLegends = () => {
    const legends = {};
    legends.wind_constraint_incline = {
        layerKey: filtersKey.WIND_CONSTRAINT_INCLINE,
        label: 'wind_constraint_incline',
        needsTranslate: true,
        color: '#F37E34',
    };
    return legends;
};
const useWindConstraintInclineLayer = () => {
    const options = {
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.WIND_CONSTRAINT_INCLINE],
        getLegends,
        opacity: 0.42,
    };
    createTilesLayerHook(options)(filtersKey.WIND_CONSTRAINT_INCLINE);
};
const WindConstraintInclineLayer = () => {
    useWindConstraintInclineLayer();
    return null;
};
export default WindConstraintInclineLayer;
