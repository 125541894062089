import * as React from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, HStack, VStack } from '@chakra-ui/react';
import { numberWithSpaces } from 'utils/text';
const UserStatisticsGlobalPanel = ({ dataProject, }) => {
    const { t } = useTranslation();
    return (<VStack alignItems="flex-start" color="colorMode.font800" marginY={3}>
      <HStack>
        <Box width="160px">{t('tabs.my_statistics_crm.turnover')} :</Box>
        <Box fontWeight="bold">{numberWithSpaces(dataProject.turnover)} €</Box>
      </HStack>
      <HStack>
        <Box width="160px">{t('tabs.my_statistics_crm.lots')} :</Box>
        <Box fontWeight="bold">{numberWithSpaces(dataProject.lots)}</Box>
      </HStack>
      <HStack>
        <Box width="160px">{t('tabs.my_statistics_crm.floor_area')} :</Box>
        <Box fontWeight="bold">{numberWithSpaces(dataProject.surface)} m²</Box>
      </HStack>
    </VStack>);
};
export default memo(UserStatisticsGlobalPanel);
