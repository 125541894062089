import * as React from 'react';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Button, Checkbox, FormControl, FormLabel, HStack, Text, VStack, } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import useAnalytics, { ANALYTICS_IMPORT_PUBLIPOSTAGE, } from 'analytics/useAnalytics';
import { t } from 'i18next';
import * as yup from 'yup';
import TextInput from 'components/inputs/TextInput';
import UploadInput from 'components/inputs/UploadInput';
import { NB_TEMPLATE_MAX } from 'components/modals/publipostageModal/importPublipostageSteps/TemplateFilePublipostage';
import useImportPublipostage from 'hooks/import/useImportPublipostage';
import useCustomToast from 'hooks/useCustomToast';
import { checkFileSize } from 'utils/files';
const ACCEPTED_FILE_FORMATS = '.doc,.docx';
const UploadFilePublipostage = ({ children, handleFileData, nbTemplate, }) => {
    const { trackEvent } = useAnalytics();
    const { getErrorToast } = useCustomToast();
    const [saveFile, setSaveFile] = useState(false);
    const getDefaultValues = () => {
        return {
            file: null,
            title: '',
            saveFile: '',
        };
    };
    const getValidationSchema = (t) => {
        const properties = {
            file: yup
                .mixed()
                .required(t('global.fields.error.required'))
                .test('is-valid-size', t('global.fields.error.file_size'), (fileList) => checkFileSize({ fileList, maxSize: 10000000 })),
        };
        return yup.object().shape(properties);
    };
    const defaultValues = getDefaultValues();
    const validationSchema = getValidationSchema(t);
    const methods = useForm({
        defaultValues,
        resolver: yupResolver(validationSchema),
        mode: 'onSubmit',
    });
    const { handleSubmit, watch, setValue, register } = methods;
    const files = watch('file');
    React.useEffect(() => {
        setValue('title', files ? files[0]?.name : '');
    }, [files]);
    const { importPublipostage, isLoadingImport, createTemplateAndImportPublipostage, isCreatingTemplateAndImport, } = useImportPublipostage();
    const [fileError, setFileError] = useState(null);
    const importation = (formData) => {
        if (formData.saveFile === '1') {
            createTemplateAndImportMailing(formData);
        }
        else {
            importMailing(formData);
        }
    };
    const importMailing = (formData) => {
        importPublipostage(formData, {
            onSuccess: (response) => handleFileData(response),
            onError: (error) => setFileError(error),
        });
        trackEvent({
            category: ANALYTICS_IMPORT_PUBLIPOSTAGE,
            action: 'Charger un fichier',
        });
    };
    const createTemplateAndImportMailing = (formData) => {
        if (formData.title === '') {
            getErrorToast({
                title: t('publipostage.error_no_title'),
                id: 'toast_publipostage_template_title_error',
            });
            return;
        }
        createTemplateAndImportPublipostage(formData, {
            onSuccess: (response) => handleFileData(response),
            onError: (error) => setFileError(error),
        });
        trackEvent({
            category: ANALYTICS_IMPORT_PUBLIPOSTAGE,
            action: 'Sauvegarder template et charger un fichier',
        });
    };
    return (<FormProvider {...methods}>
      <Box fontWeight="bold">{t('publipostage.upload_file')}</Box>
      <form>
        <VStack spacing={2} alignItems="flex-end" justifyContent="flex-start">
          <Text textStyle="paragraph" size="sm">
            {t('publipostage.info_file')} <br />
            {t('publipostage.info_file2')}
          </Text>
          <UploadInput name="file" acceptedFileFormat={ACCEPTED_FILE_FORMATS} fileError={fileError}/>
          <FormControl display="flex">
            <Checkbox {...register('saveFile')} onChange={(e) => {
            setSaveFile(e.target.checked);
        }} value="1" disabled={nbTemplate >= NB_TEMPLATE_MAX}/>
            <FormLabel margin="0" paddingLeft={2} cursor="pointer" opacity={nbTemplate >= NB_TEMPLATE_MAX ? '0.3' : '1'}>
              {t('publipostage.save_file')}
            </FormLabel>
          </FormControl>
          {nbTemplate >= NB_TEMPLATE_MAX && (<Text width="full" color="red.400" fontSize="11px">
              {t('publipostage.template_max', { count: NB_TEMPLATE_MAX })}
            </Text>)}
          {saveFile && (<TextInput name="title" label={t('publipostage.fields.title.label')} maxLength={100}/>)}
          <HStack justifyContent="flex-end">
            {children}
            <Button type="button" size="sm" variant="primary" isLoading={isLoadingImport || isCreatingTemplateAndImport} loadingText={t('global.status.importing')} onClick={handleSubmit((data) => {
            importation(data);
        })} data-cy="first-step-import-mailing">
              {t('publipostage.actions.import')}
            </Button>
          </HStack>
        </VStack>
      </form>
    </FormProvider>);
};
export default UploadFilePublipostage;
