import { useTranslation } from 'react-i18next';
import { MarkerWithLabel } from '@googlemaps/markerwithlabel';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { del, get, patch, post } from 'api/client';
import { IOUserDrawingLayerItem, } from 'components/layers/UserDrawingsLayer';
import useCustomToast from 'hooks/useCustomToast';
import useOnError from 'hooks/useOnError';
import { IOEmpty } from 'types/api';
import { filtersKey } from 'utils/filters';
import { drawingToSwkt } from 'utils/map';
const USER_DRAWINGS_QUERY_KEY = ['layer', filtersKey.USER_DRAWINGS];
export const fetchUserDrawings = () => () => get(iots.array(IOUserDrawingLayerItem), `user-drawings`);
export const fetchCountUserDrawings = () => () => get(iots.number, `user-drawings/count`);
const createUserDrawingRequest = ({ drawing }) => post(IOUserDrawingLayerItem, 'user-drawings', {
    geom: drawingToSwkt(drawing),
    note: drawing instanceof MarkerWithLabel ? drawing.labelContent : '',
});
const updateUserDrawingRequest = ({ id, drawing, }) => patch(IOUserDrawingLayerItem, `user-drawings/${id}`, {
    id: id,
    geom: drawingToSwkt(drawing),
    note: drawing instanceof MarkerWithLabel ? drawing.labelContent : '',
});
const deleteUserDrawingRequest = (id) => del(IOEmpty, `user-drawings/${id}`);
const useUserDrawing = (onCreateOrUpdateDrawing, onRemoveDrawing) => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const { getSuccessToast } = useCustomToast();
    const onErrorCallback = useOnError();
    const onSuccessCallback = () => {
        queryClient.invalidateQueries({
            queryKey: [USER_DRAWINGS_QUERY_KEY],
        });
    };
    const { data: userDrawings, isInitialLoading } = useQuery({
        queryKey: [USER_DRAWINGS_QUERY_KEY],
        queryFn: fetchUserDrawings(),
    });
    const { mutate: createUserDrawing } = useMutation({
        mutationFn: createUserDrawingRequest,
        onError: (err) => onErrorCallback(err),
        onSuccess: (data) => {
            onCreateOrUpdateDrawing && onCreateOrUpdateDrawing(data);
            queryClient.setQueriesData({ queryKey: USER_DRAWINGS_QUERY_KEY }, (oldData) => {
                return [...oldData, data];
            });
            onSuccessCallback();
            getSuccessToast({
                title: t('toolbar.free_draw.created'),
                id: 'toolbar_free_draw_saved',
            });
        },
    });
    const { mutate: updateUserDrawing } = useMutation({
        mutationFn: updateUserDrawingRequest,
        onError: (err) => onErrorCallback(err),
        onSuccess: (data) => {
            onCreateOrUpdateDrawing && onCreateOrUpdateDrawing(data);
            queryClient.setQueriesData({ queryKey: USER_DRAWINGS_QUERY_KEY }, (oldData) => {
                const drawingIndex = oldData.findIndex((d) => d.id === data.id);
                if (drawingIndex === -1) {
                    return undefined;
                }
                const newData = [...oldData];
                newData[drawingIndex] = data;
                return newData;
            });
            onSuccessCallback();
            getSuccessToast({
                title: t('toolbar.free_draw.updated'),
                id: 'toolbar_free_draw_saved',
            });
        },
    });
    const { mutate: removeUserDrawing } = useMutation({
        mutationFn: deleteUserDrawingRequest,
        onError: (err) => onErrorCallback(err),
        onSuccess: (_data, id) => {
            onRemoveDrawing && onRemoveDrawing();
            queryClient.setQueriesData({ queryKey: USER_DRAWINGS_QUERY_KEY }, (oldData) => {
                return [...oldData].filter((d) => d.id !== id);
            });
            onSuccessCallback();
            getSuccessToast({
                title: t('toolbar.free_draw.deleted'),
                id: 'toolbar_free_draw_deleted',
            });
        },
    });
    return {
        userDrawings,
        isInitialLoading,
        updateUserDrawing,
        createUserDrawing,
        removeUserDrawing,
    };
};
export default useUserDrawing;
