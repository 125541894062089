import { useContextSelector } from 'use-context-selector';
import MapContext from 'context/MapContext';
export const useRefMap = () => {
    return useContextSelector(MapContext, (state) => state.refMap);
};
export const useRefPanorama = () => {
    return useContextSelector(MapContext, (state) => state.refPanorama);
};
export const useMap = () => {
    return useContextSelector(MapContext, (state) => state.map);
};
export const usePanorama = () => {
    return useContextSelector(MapContext, (state) => state.panorama);
};
export const useInitMap = () => {
    return useContextSelector(MapContext, (state) => state.initMap);
};
export const useInitPanorama = () => {
    return useContextSelector(MapContext, (state) => state.initPanorama);
};
export const useInfoWindowListenersRef = () => {
    return useContextSelector(MapContext, (state) => state.infoWindowListenersRef);
};
export const useStreetViewMode = () => {
    return useContextSelector(MapContext, (state) => state.streetViewMode);
};
export const useCloseStreetView = () => {
    return useContextSelector(MapContext, (state) => state.closeStreetView);
};
export const useInfoWindow = () => {
    return useContextSelector(MapContext, (state) => state.infoWindow);
};
export const useAddInfoWindow = () => {
    return useContextSelector(MapContext, (state) => state.addInfoWindow);
};
export const useCloseInfoWindow = () => {
    return useContextSelector(MapContext, (state) => state.closeInfoWindow);
};
export const useResetMap = () => {
    return useContextSelector(MapContext, (state) => state.resetMap);
};
export const useGetLayers = () => {
    return useContextSelector(MapContext, (state) => state.layers);
};
export const useAddLayers = () => {
    return useContextSelector(MapContext, (state) => state.addLayers);
};
export const useRemoveLayers = () => {
    return useContextSelector(MapContext, (state) => state.removeLayers);
};
export const useResetLayers = () => {
    return useContextSelector(MapContext, (state) => state.resetLayers);
};
export const useSetZoomStreetView = () => {
    return useContextSelector(MapContext, (state) => state.setZoomStreetView);
};
export const useSetPLUInfoData = () => {
    return useContextSelector(MapContext, (state) => state.setPLUInfoData);
};
export const useSetPLUGPUInfoData = () => {
    return useContextSelector(MapContext, (state) => state.setPLUGPUInfoData);
};
export const usePLUInfo = () => {
    return useContextSelector(MapContext, (state) => state.PLUInfo);
};
export const useIsLoadingPLUInfo = () => {
    return useContextSelector(MapContext, (state) => state.isLoadingPLUInfo);
};
export const usePLUGPUInfo = () => {
    return useContextSelector(MapContext, (state) => state.PLUGPUInfo);
};
export const useIsLoadingPLUGPUInfo = () => {
    return useContextSelector(MapContext, (state) => state.isLoadingPLUGPUInfo);
};
export const usePrecision = () => {
    return useContextSelector(MapContext, (state) => state.precision);
};
export const useSetPrecision = () => {
    return useContextSelector(MapContext, (state) => state.setPrecision);
};
export const useMapCenter = () => {
    return useContextSelector(MapContext, (state) => state.mapCenter);
};
export const useSetRotateControl = () => {
    return useContextSelector(MapContext, (state) => state.setRotateControl);
};
export const useSetComputedMapType = () => {
    return useContextSelector(MapContext, (state) => state.setComputedMapType);
};
export const useSetDarkMode = () => {
    return useContextSelector(MapContext, (state) => state.setDarkMode);
};
export const useMapDisplay = () => {
    return useContextSelector(MapContext, (state) => ({
        mapDisplay: state.mapDisplay,
        mapDisplaySatellite: state.mapDisplaySatellite,
        historyMapYear: state.historyMapYear,
    }));
};
export const useChangeMapDisplay = () => {
    return useContextSelector(MapContext, (state) => state.changeMapDisplay);
};
