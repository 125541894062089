import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import fiberBlack from 'images/fiber_black.png';
import fiberBlue from 'images/fiber_blue.png';
import fiberGray from 'images/fiber_gray.png';
import fiberGreen from 'images/fiber_green.png';
import fiberOrange from 'images/fiber_orange.png';
import { IOMarkerLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
const IOFiberDeployment2020MarkerItem = iots.intersection([
    IOMarkerLayerItem,
    iots.partial({
        imageType: iots.string,
        legacyId: iots.string,
    }),
]);
const fetchFiberDeployment2020 = (townId) => () => get(iots.array(IOFiberDeployment2020MarkerItem), `legacy/${townId}/fiber-deployment-2020`);
const LEGENDS = {
    deployement_in_progress: {
        layerKey: filtersKey.FIBER_DEPLOYMENT_2020,
        label: 'deployement_in_progress',
        needsTranslate: true,
        color: '#464646',
        opacity: 1,
    },
    studies_in_progress: {
        layerKey: filtersKey.FIBER_DEPLOYMENT_2020,
        label: 'studies_in_progress',
        needsTranslate: true,
        color: '#9b9b9b',
        opacity: 1,
    },
    planned: {
        layerKey: filtersKey.FIBER_DEPLOYMENT_2020,
        label: 'planned',
        needsTranslate: true,
        color: '#dbad23',
        opacity: 1,
    },
    deployed: {
        layerKey: filtersKey.FIBER_DEPLOYMENT_2020,
        label: 'deployed',
        needsTranslate: true,
        color: '#41981a',
        opacity: 1,
    },
    connectable: {
        layerKey: filtersKey.FIBER_DEPLOYMENT_2020,
        label: 'connectable',
        needsTranslate: true,
        color: '#3c7cf4',
        opacity: 1,
    },
};
const fiberImagesForTypes = {
    black: fiberBlack,
    orange: fiberOrange,
    green: fiberGreen,
    blue: fiberBlue,
    gray: fiberGray,
};
const getMarkerImg = (marker) => {
    return fiberImagesForTypes[marker.imageType];
};
const useFiberDeployment2020Layer = () => {
    createLayersHook({
        fetch: fetchFiberDeployment2020,
        markerImg: getMarkerImg,
        legends: LEGENDS,
    })(filtersKey.FIBER_DEPLOYMENT_2020);
};
const FiberDeployment2020Layer = () => {
    useFiberDeployment2020Layer();
    return null;
};
export default FiberDeployment2020Layer;
