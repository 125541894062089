import { useQuery } from '@tanstack/react-query';
import { get } from 'api/client';
export const QUERY_KEY = ['team-users'];
const fetchTeamUsersList = () => () => get(null, 'legacy/team-users');
const useTeamUsersList = () => {
    return useQuery({
        queryKey: QUERY_KEY,
        queryFn: fetchTeamUsersList(),
    });
};
export default useTeamUsersList;
