import * as React from 'react';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Heading, Spinner, VStack } from '@chakra-ui/react';
import { isAvailable, } from 'components/tabs/crmTabs/TeamStatisticsCRMPanel';
import UserStatisticsStatusItem from 'components/tabs/crmTabs/userStatisticsCRM/UserStatisticsStatusItem';
import useTeamStatisticsCRM from 'hooks/crm/useTeamStatisticsCRM';
import { PROJECT_STATUS_OPTIONS } from 'utils/constants';
const TeamStatisticsProjectsPanel = ({ filters, }) => {
    const { t } = useTranslation();
    const [dataFormatted, setDataFormatted] = useState({
        status: {},
        percent: {},
    });
    const { statsProjects, isLoadingStatsProjects, refetchProjects } = useTeamStatisticsCRM({ toLoad: 'projects' });
    useEffect(() => {
        refetchProjects(); // Relancer les projets à chaque montage du composant
    }, []);
    useEffect(() => {
        if (statsProjects) {
            const data = { status: {}, percent: {} };
            PROJECT_STATUS_OPTIONS.map((option) => {
                data.status[option.value] = 0;
                data.percent[option.value] = 0;
            });
            let nbProjects = 0;
            for (const userEmail in statsProjects) {
                nbProjects += statsProjects[userEmail].length;
            }
            for (const userEmail in statsProjects) {
                if (!isAvailable({ filters, email: userEmail })) {
                    continue;
                }
                const userData = statsProjects[userEmail];
                userData.forEach((project) => {
                    const date = new Date(project.updatedAt);
                    if (!isAvailable({ filters, date, group: project.group })) {
                        return;
                    }
                    data.status[project.status] += 1;
                    const percent = (data.status[project.status] / nbProjects) * 100;
                    data.percent[project.status] = percent.toFixed(2);
                });
            }
            setDataFormatted(data);
        }
    }, [statsProjects, filters]);
    return (<Box flex={1}>
      <Heading as="h3" variant="h3" marginBottom={2}>
        {t('tabs.team_statistics_crm.projects')}
      </Heading>
      <VStack spacing={1} alignItems="flex-start" position="relative">
        {PROJECT_STATUS_OPTIONS.map((option) => {
            return (<UserStatisticsStatusItem key={option.value} option={option} data={dataFormatted}/>);
        })}
        {isLoadingStatsProjects && (<Box display="flex" alignItems="center" justifyContent="center" position="absolute" top={0} left={0} width="100%" height="100%" backgroundColor="rgba(251, 250, 249, 0.6)">
            <Spinner />
          </Box>)}
      </VStack>
    </Box>);
};
export default memo(TeamStatisticsProjectsPanel);
