import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Translation } from 'react-i18next';
import { Box, Checkbox, Heading, Text, VStack } from '@chakra-ui/react';
import { PlotSourcePostsHighlightsSwitch } from 'components/buttons/PlotSourcePostsHighlightsSwitch';
import ElectricNetworkCapareseauIsodistanceSidebarContent from 'components/sidebarContent/ElectricNetworkCapareseauIsodistanceSidebarContent';
import Table from 'components/table/Table';
import { useIsFilterActive, useToggleFilter, } from 'hooks/contexts/useFiltersContext';
import { useTogglePlotSourcePostsHighlights } from 'hooks/contexts/useSecondaryPlotsContext';
import usePlotSourcePost from 'hooks/plot/usePlotSourcePost';
import { filtersKey } from 'utils/filters';
import { formatNumberToLocale } from 'utils/formatNumber';
const getColumnHeader = (column, suffix = null) => (<Text align="center">
    <Translation>
      {(t) => t(`tabs.plots.section.source_post.columns.${column}`)}
    </Translation>
    {suffix}
  </Text>);
const initialSort = [
    {
        id: 'distance',
        desc: false,
    },
];
const PlotSourcePost = ({ plot }) => {
    const { t, i18n } = useTranslation();
    const toggleFilter = useToggleFilter();
    const isFilterActive = useIsFilterActive();
    const { sourcePosts, isLoading } = usePlotSourcePost(plot?.id, true);
    const { clearPlotSourcePostsHighlights } = useTogglePlotSourcePostsHighlights();
    const handleChange = (filterKey) => {
        toggleFilter(filterKey);
    };
    useEffect(() => {
        clearPlotSourcePostsHighlights();
    }, [sourcePosts]);
    const columns = [
        {
            id: 'columns',
            columns: [
                {
                    accessorKey: 'name',
                    header: () => getColumnHeader('name'),
                    cell: (info) => info.getValue(),
                    filterFn: 'arrIncludesSome',
                },
                {
                    accessorKey: 'distance',
                    header: () => getColumnHeader('distance'),
                    cell: (info) => formatNumberToLocale(info.getValue(), i18n.language),
                    filterFn: 'inNumberRange',
                },
                {
                    accessorKey: 'map',
                    header: () => getColumnHeader('map'),
                    cell: (info) => (<PlotSourcePostsHighlightsSwitch plotSourcePostInfo={info.row.original}/>),
                    enableGlobalFilter: false,
                    enableColumnFilter: false,
                    enableSorting: false,
                },
            ],
        },
    ];
    return (<VStack alignItems="flex-start" padding={4} color="var(--colorModeFont-800)">
      <Heading as="h4" variant="h4">
        {t('tabs.plots.section.source_post.title_panel')} :
      </Heading>
      <Box>
        <Checkbox key={filtersKey.ELECTRICAL_NETWORKS_SOURCE_POSTS} data-id={filtersKey.ELECTRICAL_NETWORKS_SOURCE_POSTS} isChecked={isFilterActive(filtersKey.ELECTRICAL_NETWORKS_SOURCE_POSTS)} onChange={() => handleChange(filtersKey.ELECTRICAL_NETWORKS_SOURCE_POSTS)} lineHeight="1" display="flex" alignItems="center" cursor="pointer">
          <Text fontSize="14px">
            {t('sidebar.layers.photovoltaic_prone_area_posts')}
          </Text>
        </Checkbox>
      </Box>
      <Box>
        <Checkbox key={filtersKey.ELECTRICAL_NETWORKS_CAPARESEAU} data-id={filtersKey.ELECTRICAL_NETWORKS_CAPARESEAU} isChecked={isFilterActive(filtersKey.ELECTRICAL_NETWORKS_CAPARESEAU)} onChange={() => handleChange(filtersKey.ELECTRICAL_NETWORKS_CAPARESEAU)} lineHeight="1" display="flex" alignItems="center" cursor="pointer">
          <Text fontSize="14px">
            {t('sidebar.layers.electrical_networks_capareseau')}
          </Text>
        </Checkbox>
      </Box>
      <ElectricNetworkCapareseauIsodistanceSidebarContent />
      <Box width="full">
        <Heading as="h4" variant="h4" marginBottom={1}>
          {t('tabs.plots.section.source_post.source_post_nearest')} :
        </Heading>
        <Table variant="simple" id="plots-source-posts" columns={columns} data={sourcePosts || []} initialSort={initialSort} analyticsAction="Résultat de recherche" isLoading={isLoading}/>
      </Box>
    </VStack>);
};
export default PlotSourcePost;
