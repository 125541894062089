import * as React from 'react';
import { VStack } from '@chakra-ui/react';
import { ANALYTICS_CRM } from 'analytics/useAnalytics';
import ActivityList from 'components/activities/ActivityList';
import CourierList from 'components/couriers/CourierList';
import HistoryList from 'components/tabs/crmTabs/project/HistoryList';
import useCourier from 'hooks/courier/useCourier';
import useProjectActivities from 'hooks/crm/useProjectActivities';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { SPACING_PANEL } from 'utils/constants';
const ProjectCRMSection = ({ projectId, landPointId, isUserOwner = true, }) => {
    const { projectActivities, isInitialLoading: isLoadingActivity } = useProjectActivities(projectId, landPointId);
    const { projectCouriers, isLoadingProjectCouriers } = useCourier({
        enabledList: true,
        projectId,
        landPointId,
    });
    const { hasDashboardItem } = useSiteConfiguration();
    return (<VStack spacing={SPACING_PANEL}>
      {hasDashboardItem('courier') && (<CourierList title="tabs.project.courier" couriers={projectCouriers} isLoading={isLoadingProjectCouriers} analyticsDetail="Ouvrir un courrier dans projet" landPointId={landPointId} isUserOwner={isUserOwner}/>)}
      {hasDashboardItem('agenda') && (<ActivityList title="tabs.project.activities" activities={projectActivities} isLoading={isLoadingActivity} activityInfoDefault={{ projectId: projectId }} analyticsCategory={ANALYTICS_CRM} analyticsDetail="Ouvrir fiche parcelle mes activités dans projet" landPointId={landPointId} isUserOwner={isUserOwner}/>)}
      <HistoryList projectId={projectId} landPointId={landPointId}/>
    </VStack>);
};
export default ProjectCRMSection;
