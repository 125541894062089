import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getLayerCoordTileUrl } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = () => (coord, zoom) => {
    return getLayerCoordTileUrl('PROTECTEDAREAS.APB', 'PROTECTEDAREAS.APB', zoom, coord);
};
const getLegends = () => {
    const legends = {};
    legends.biotop_protection = {
        layerKey: filtersKey.BIOTOP_PROTECTION,
        label: 'biotop_protection',
        needsTranslate: true,
        color: '#F96611',
        opacity: 0.6,
    };
    return legends;
};
const useBiotopProtectionLayer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.BIOTOP_PROTECTION],
        getLegends,
        opacity: 0.5,
    })(filtersKey.BIOTOP_PROTECTION);
};
const BiotopProtectionLayer = () => {
    useBiotopProtectionLayer();
    return null;
};
export default BiotopProtectionLayer;
