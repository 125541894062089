import * as React from 'react';
import { Skeleton, VStack } from '@chakra-ui/react';
import { useIsFetching } from '@tanstack/react-query';
import { usePlots } from 'hooks/contexts/useTabsContext';
import { getPlotByCoordinatesQueryKey } from 'hooks/plot/usePlotByCoordinates';
import { getRefinedPlotNumber } from 'utils/plots';
import TabItemWrapper, { TabItemTitle, } from './TabItemWrapper';
const PlotTabItemSkeleton = () => {
    return (<VStack alignItems="stretch" width="100%">
      <Skeleton height="15px" width="60%" maxW="100px"/>
      <Skeleton height="10px" width="30%" maxW="60px"/>
    </VStack>);
};
const PlotTabItem = (props) => {
    const { tab } = props;
    const plots = usePlots();
    const currentPlot = plots[tab.id];
    const detail = React.useMemo(() => {
        if (currentPlot?.cadastreId)
            return getRefinedPlotNumber(currentPlot?.cadastreId).toString();
        return '';
    }, [currentPlot, plots]);
    const title = React.useMemo(() => {
        if (currentPlot) {
            return currentPlot?.town;
        }
        return '';
    }, [currentPlot, plots]);
    const isFetchingPlot = useIsFetching({
        queryKey: [
            getPlotByCoordinatesQueryKey(currentPlot?.lat, currentPlot?.lng, currentPlot?.townId),
        ],
    });
    const isPlotTabLoading = !!isFetchingPlot;
    return (<TabItemWrapper {...props} isLoading={isPlotTabLoading}>
      {isPlotTabLoading || !detail || !title ? (<PlotTabItemSkeleton />) : (<TabItemTitle title={title} detail={detail}/>)}
    </TabItemWrapper>);
};
export default PlotTabItem;
