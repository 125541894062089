import { useCallback } from 'react';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { useExtraFilters } from 'hooks/contexts/useFiltersContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem, IOPolygonLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IORPGPolygonItem = iots.intersection([
    IOPolygonLayerItem,
    iots.type({
        farmingId: iots.string,
    }),
], 'IORPGPolygonItem');
const IOPhotovoltaicNotProneAreaRpgLayerItem = IOLayerItem;
const fetchPhotovoltaicNotProneAreaRpg = (townId) => () => get(iots.array(IOPhotovoltaicNotProneAreaRpgLayerItem), `${townId}/photovoltaic-not-prone-area-rpg/last`);
const usePhotovoltaicNotProneAreaRpgLayer = () => {
    const extraFilters = useExtraFilters();
    const allowPolygon = useCallback((polygon) => {
        const farmingIds = extraFilters?.[filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_LAST]
            ?.farmingIds ?? [];
        return isFarmingAllowed(polygon, farmingIds);
    }, [extraFilters]);
    const isFarmingAllowed = (polygon, farmingIds) => {
        if (farmingIds.length === 0) {
            return true;
        }
        return farmingIds.includes(polygon.farmingId);
    };
    createLayersHook({
        fetch: fetchPhotovoltaicNotProneAreaRpg,
        getInfoWindowContent: defaultGetInfoWindowContent,
        allowLayer: allowPolygon,
    })(filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_LAST);
};
const PhotovoltaicNotProneAreaRpgLayer = () => {
    usePhotovoltaicNotProneAreaRpgLayer();
    return null;
};
export default PhotovoltaicNotProneAreaRpgLayer;
