import * as XLSX from 'xlsx';
import { NO_IMPACT_RULE } from 'components/tabs/plots/PlotSections/rules/PlotLayers';
import { isNumericValue, isPercentageValue } from './utils';
const autoFitColumns = ({ data, headers, mode, }) => {
    if (mode === 'vertical') {
        const labelsWidths = data[0].map((line) => `${line?.label}`?.length ?? 0);
        const valuesWidths = data[0].map((line) => `${line?.value}`?.length
            ? `${line?.value}`?.length < 70
                ? `${line?.value}`?.length
                : 70
            : 0);
        return [
            { wch: Math.max(...labelsWidths) },
            { wch: Math.max(...valuesWidths) },
        ];
    }
    else {
        const widths = {};
        headers.map((header) => {
            widths[header] = [header?.length];
        });
        data.map((line) => {
            headers.map((header) => {
                widths[header].push(`${line[header]}`?.length ?? 0);
            });
        });
        return headers?.map((header) => ({ wch: Math.max(...widths[header]) + 2 }));
    }
};
const formatCellValue = ({ item, field, formatValue }) => {
    // Pass proper field value to ensure right alignment for numeric cells
    let value = item[field]?.value ? item[field]?.value : item[field];
    // Is numeric
    if (typeof value === 'string' && isNumericValue(value)) {
        value = value.replace(',', '.')?.split(' ').join('');
        value = +value;
    }
    // Is percentage
    else if (typeof value === 'string' && isPercentageValue(value)) {
        value = value.replace('%', '');
        value = value.replace(',', '.') / 100;
        return {
            t: 'n',
            v: formatValue(item[field]?.value ? { ...item[field], value } : value, field, item),
            z: '0%',
        };
    }
    return formatValue(item[field]?.value ? { ...item[field], value } : value, field, item);
};
export const getXLSXWorksheetFromData = (data, formatHeader, formatValue, exportedFields, mode = 'horizontal') => {
    if (!data || !data.length) {
        return null;
    }
    const headers = exportedFields || Object.keys(data[0]);
    let worksheet = null;
    let tableData = [];
    if (mode === 'horizontal') {
        tableData = data.map((item) => {
            const formattedItem = {};
            Object.keys(item).forEach((field) => {
                if (exportedFields && !exportedFields.includes(field)) {
                    return;
                }
                formattedItem[formatHeader(field)] = formatCellValue({
                    item,
                    field,
                    formatValue,
                });
            });
            return formattedItem;
        });
        const headers = (exportedFields || Object.keys(data[0])).map((field) => formatHeader(field));
        worksheet = XLSX.utils.json_to_sheet(tableData, {
            header: headers,
        });
        worksheet['!cols'] = autoFitColumns({
            data: tableData,
            headers,
            mode: 'horizontal',
        });
    }
    if (mode === 'vertical') {
        const regexNoImpactRule = new RegExp(`.?${NO_IMPACT_RULE}.?`, 'gi');
        tableData = data.map((item) => {
            const formattedItem = [];
            headers.forEach((field) => {
                if (exportedFields && !exportedFields.includes(field)) {
                    return;
                }
                const label = formatHeader(field);
                let value = formatCellValue({
                    item,
                    field,
                    formatValue,
                });
                // Only for plotRules
                if (typeof value === 'string') {
                    // EPF, TVA, SCOT
                    if (value.includes(';')) {
                        const rules = value.split(';').filter((rule) => {
                            return !rule.match(regexNoImpactRule);
                        });
                        value = rules.join(';');
                    }
                    else if (value.match(regexNoImpactRule)) {
                        return;
                    }
                }
                if (!value) {
                    return;
                }
                const newItem = {
                    label,
                };
                if (Array.isArray(value)) {
                    value?.map((val, index) => {
                        newItem['value-' + index] = val;
                    });
                }
                else {
                    newItem['value'] = value;
                }
                formattedItem.push(newItem);
            });
            return formattedItem;
        });
        const firstElement = tableData[0];
        // for now vertical mode only supports 1 element
        if (!firstElement) {
            return;
        }
        worksheet = XLSX.utils.json_to_sheet(firstElement, {
            skipHeader: true,
        });
        worksheet['!cols'] = autoFitColumns({
            data: tableData,
            mode: 'vertical',
        });
    }
    return worksheet;
};
export const exportDataToXLSX = (fileName, sheets) => {
    if (!sheets || !sheets.length) {
        return false;
    }
    const workbook = XLSX?.utils?.book_new();
    sheets.forEach((sheet) => {
        const worksheet = getXLSXWorksheetFromData(sheet.getData(), sheet.formatHeader, sheet.formatValue, sheet.exportedFields, sheet.mode);
        if (!worksheet) {
            return false;
        }
        XLSX.utils.book_append_sheet(workbook, worksheet, sheet.sheetTitle.slice(0, 31));
    });
    XLSX.writeFile(workbook, fileName + '.xlsx');
};
