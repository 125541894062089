import * as React from 'react';
import { useMemo } from 'react';
import { useFiltersSearchRegex } from 'hooks/contexts/useFiltersContext';
import { highlightTitle, titleMatch } from 'utils/filters';
const FilterTitle = ({ title }) => {
    const filtersSearchRegex = useFiltersSearchRegex();
    const hasHighlight = useMemo(() => filtersSearchRegex && titleMatch(title, filtersSearchRegex), [title, filtersSearchRegex]);
    const highlightedTitle = useMemo(() => {
        if (!hasHighlight) {
            return title;
        }
        return highlightTitle(title, filtersSearchRegex);
    }, [hasHighlight, title, filtersSearchRegex]);
    if (!hasHighlight) {
        return <>{title}</>;
    }
    return (<span dangerouslySetInnerHTML={{
            __html: highlightedTitle,
        }}/>);
};
export default FilterTitle;
