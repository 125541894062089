import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IONpnruLayerItem = IOLayerItem;
const fetchNpnru = (townId) => () => get(iots.array(IONpnruLayerItem), `legacy/${townId}/npnru`);
const useNPNRULayer = () => {
    createLayersHook({
        fetch: fetchNpnru,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.NPNRU);
};
const NPNRULayer = () => {
    useNPNRULayer();
    return null;
};
export default NPNRULayer;
