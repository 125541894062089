import * as React from 'react';
import { Box, useBreakpointValue } from '@chakra-ui/react';
import SearchByPlot from 'components/SearchByPlot';
import { SEARCH_MODE } from 'context/PlotSearchContext';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
const HeaderPlotSearch = () => {
    const selectedTown = useSelectedTown();
    const { hasFeature } = useSiteConfiguration();
    const submitText = useBreakpointValue({
        base: 'header.search_plot.see_plot_small',
        xl: 'header.search_plot.see_plot',
    });
    if (!selectedTown) {
        return null;
    }
    if (!hasFeature('header_search_plot')) {
        return null;
    }
    return (<Box marginTop="-15px">
      <SearchByPlot submitText={submitText} mode={SEARCH_MODE}/>
    </Box>);
};
export default HeaderPlotSearch;
